import { ChartReading } from 'types/ChartReading'

export const filterChartByType = (readings: ChartReading[], chartReadingType: string) => {
  let newReadings: ChartReading[] = []

  switch (chartReadingType) {
    case 'All Reading Types':
      newReadings = readings
      break
    case 'Before Meal':
      newReadings = readings.filter((x) => x.type === 'Before Meal')
      break
    case 'After Meal':
      newReadings = readings.filter((x) => x.type === 'After Meal')
      break
    default:
      break
  }
  return newReadings
}
