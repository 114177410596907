import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from 'reducers/tempSlice'
import { useTheme } from 'styled-components'
import { IntakeMedication, IntakeMedicationFrequency } from 'types/Medication'
import { useAppSelector } from '../../../hooks/hooks'
import usePatientIntake from '../../../hooks/usePatientIntake'
import { getAccessToken } from '../../../reducers/authSlice'
import BaseForm from '../BaseForm'
import { ButtonOption } from '../components/OptionCard'
import AddedMedication from './AddedMedication'
import MedicationCard from './MedicationCard'
import MedicationsChecklist from './MedicationsChecklist'

interface Props {
  backPressed: () => void
  onSubmit: (medications: IntakeMedication[], quit?: boolean, nextStep?: boolean) => void
  onSaveAndQuit: () => void

  error?: string
}

export enum MedicationFrequency {
  Daily,
  Weekly,
  BiWeekly,
  Monthly,
  AsNeeded,
}
export type MedicationDetails = {
  formId?: number
  medicationName?: string
  strength?: string
  quantity?: number
  frequency?: MedicationFrequency
  reasonForTaking?: string
}

export default function MedicationsForm({ onSubmit, backPressed, onSaveAndQuit, error }: Props) {
  const [addedMedications, setAddedMedications] = useState<IntakeMedication[]>([])
  const [addingMedication, setAddingMedication] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number | undefined>()
  const [medIndex, setMedIndex] = useState<number>(0)
  const [allMedications, setAllMedications] = useState<IntakeMedication[]>([])
  const [medicationsFromFillHistory, setMedicationsFromFillHistory] = useState<IntakeMedication[]>([])
  const [medicationsExist, setMedicationsExist] = useState(true)
  const [openNewMedForm, setOpenNewMedForm] = useState(false)
  const [added, setAdded] = useState(new Array(medicationsFromFillHistory.length).fill(false))
  const [frequency, setFrequency] = useState<IntakeMedicationFrequency | undefined>(undefined)
  const [currentMed, setCurrentMed] = useState<number | undefined>()
  const [medNumberArr, setMedNumberArr] = useState<number[]>([])

  const dispatch = useDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const { getMedicationInfo } = usePatientIntake({ token: accessToken })
  const theme = useTheme()

  useEffect(() => {
    dispatch(setLoading(true))
    async function getPhysicianState() {
      const response = await getMedicationInfo()
      const data = await response.data
      if (data.medicationsFromFillHistory.length !== 0) {
        setMedicationsFromFillHistory(data.medicationsFromFillHistory)
        setMedicationsExist(true)
        setAllMedications(data.medications)
        dispatch(setLoading(false))
        setAdded(new Array(data.medicationsFromFillHistory.length).fill(false))
      } else {
        setAllMedications(data.medications)
        dispatch(setLoading(false))
      }
    }
    getPhysicianState()
  }, [])

  const openMedicationForm = (option: ButtonOption) => {
    setSelectedOption(option.id)
    setOpenNewMedForm(true)
    setAddingMedication(true)
  }

  const cancelMedicationForm = () => {
    window.scrollTo(0, 0)
    setSelectedOption(undefined)
    setAddingMedication(false)
    setOpenNewMedForm(false)
  }

  const addMedication = (medDetails: IntakeMedication) => {
    medDetails.medicationType = selectedOption
    medDetails.frequency = 7
    window.scrollTo(0, 0)
    setMedIndex(medIndex + 1)
    medDetails.formId = medIndex + 1
    setAddedMedications([...addedMedications, medDetails])
    setAllMedications([...allMedications, medDetails])
    setAddingMedication(false)
    setSelectedOption(undefined)
    setOpenNewMedForm(false)
  }

  const removeMedication = (name: string | undefined, strength: string | undefined) => {
    window.scrollTo(0, 0)
    setAddedMedications(addedMedications.filter((x) => x.name !== name && x.strength !== strength))
    setAllMedications(allMedications.filter((x) => x.name !== name && x.strength !== strength))
  }
  const emptyMed = {
    name: '',
    strength: '',
    quantity: '',
  }

  const medSelect = (position: number, update: boolean) => {
    if (update) {
      const updatedAdded = added.map((item, index) => (index === position ? !item : item))
      setAdded(updatedAdded)
      if (added[position] === false) {
        setCurrentMed(position)
        if (medNumberArr.includes(position)) {
          const index = medNumberArr.indexOf(position)
          medNumberArr.splice(index, 1)

          setMedNumberArr(medNumberArr)
        } else {
          setMedNumberArr([...medNumberArr, position])
        }
      } else {
        setCurrentMed(undefined)
        const index = medNumberArr.indexOf(position)
        medNumberArr.splice(index, 1)

        setMedNumberArr(medNumberArr)
      }
    } else {
      if (currentMed === position) {
        setCurrentMed(undefined)
      } else {
        setCurrentMed(position)
      }
    }
  }
  const onSubmit1 = () => {
    medicationsFromFillHistory.map((med, index) => {
      if (medNumberArr.includes(index)) {
        allMedications.push(med)
      }
    })
    onSubmit(allMedications, false, true)
  }

  const backPress = () => {
    medicationsFromFillHistory.map((med, index) => {
      if (medNumberArr.includes(index)) {
        allMedications.push(med)
      }
    })
    onSubmit(allMedications, false, false)

    backPressed()
  }

  return (
    <BaseForm
      title="Let’s review your medications"
      subtitle="Please list all your current medications (prescription,
        over-the-counter, vitamins and supplements)"
      onSubmit={() => onSubmit1()}
      nextDisabled={addingMedication}
      onSaveAndQuit={() => onSubmit(allMedications, true)}
      error={error}
      content={
        <>
          <h4>Medications List:</h4>

          {allMedications.map((medication) => (
            <AddedMedication
              name={medication.name}
              strength={medication.strength}
              medicationName={medication.name}
              onRemoveMedication={(name, strength) => removeMedication(name, strength)}
            />
          ))}
          {medicationsFromFillHistory.length > 0 ? (
            <Row
              styles={{
                backgroundColor: theme.colors.primary1,
                padding: 10,
                border: '1px solid black',
              }}
            >
              <Typography color="white">Do you currently take any of the following medications?</Typography>
            </Row>
          ) : (
            <></>
          )}

          {medicationsFromFillHistory.length > 0 &&
            medicationsFromFillHistory.map((medication, index) => (
              <MedicationsChecklist
                med={medication}
                index={index}
                added={added}
                medSelect={(index: number, update: boolean) => medSelect(index, update)}
                currentMed={currentMed}
                medicationsExist={medicationsExist}
                onSubmit1={onSubmit1}
              />
            ))}

          <h5>Add Medications to Your List:</h5>
          {openNewMedForm && (
            <MedicationCard
              medication={emptyMed}
              medicationsExist={medicationsExist}
              onAddMedication={(medication) => addMedication(medication)}
              cancelInput={cancelMedicationForm}
            />
          )}

          {!addingMedication && (
            <Row
              styles={{ border: '1px solid #828282', width: '300px', margin: '20px auto', cursor: 'pointer' }}
              onClick={() => openMedicationForm({ title: 'Prescription', id: 1 })}
            >
              <Icon
                name="plus"
                size="2x"
                styles={{
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: 10,
                  backgroundColor: theme.colors.primary1,
                  padding: 10,
                  color: 'white',
                }}
              />
              <Typography styles={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 20 }} size={20} font="bold">
                Add New Medication
              </Typography>
            </Row>
          )}
        </>
      }
      backPressed={() => backPress()}
    />
  )
}
