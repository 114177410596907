import { CustomTheme, createStyles, makeStyles } from '@material-ui/core'
import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import { ChartReading } from 'types/ChartReading'
import { PatientGlucoseReading } from 'types/Dashboards'
import * as Victory from 'victory'

type Props = {
  readings: PatientGlucoseReading[]
  chartReadings: ChartReading[] | undefined
  isMobile: boolean
  minDate: string
  maxDate: string
  onChartReadingTypeChange: (type: string) => void
  chartReadingType: string
  chartHeight: number
  readingChartRef
}

export default function LineChart({
  readings,
  chartReadings,
  chartHeight,
  isMobile,
  onChartReadingTypeChange,
  chartReadingType,
  minDate,
  maxDate,
  readingChartRef,
}: Props) {
  const styles = useStyles()
  const theme = useTheme()

  const yAxisMax = readings && readings.length > 0 ? Math.max(...readings.map((reading) => reading.value)) + 10 : 250
  const yAxisMin = readings && readings.length > 0 ? Math.min(...readings.map((reading) => reading.value)) - 10 : 0

  return (
    <div className="line-chart-card" ref={readingChartRef}>
      {readings && readings.length <= 0 && (
        <Typography font="semi-bold" styles={{ position: 'absolute', top: '50%' }}>
          No Data
        </Typography>
      )}
      <div className={styles.cardBanner} style={{ position: 'absolute' }}>
        <Typography color={theme.colors.white} font="semi-bold">
          Blood Glucose
        </Typography>
      </div>
      <div className={styles.cardDropdown} style={{ position: 'absolute' }}>
        <Dropdown
          style={{
            width: isMobile ? '100%' : 'auto',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.toolbarButton}>
            {chartReadingType}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onChartReadingTypeChange('All Reading Types')}>All Reading Types</Dropdown.Item>
            <Dropdown.Item onClick={() => onChartReadingTypeChange('Before Meal')}>Before Meal</Dropdown.Item>
            <Dropdown.Item onClick={() => onChartReadingTypeChange('After Meal')}>After Meal</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ backgroundColor: 'white', marginLeft: 20 }}>
        <Victory.VictoryChart
          padding={{
            top: isMobile ? 250 : 20,
            bottom: isMobile ? 50 : 0,
            right: 30,
            left: 60,
          }}
          width={700}
          style={{
            parent: { touchAction: 'manipulation' },
          }}
          height={chartHeight}
          maxDomain={readings ? { y: yAxisMax } : { y: 350 }}
          minDomain={readings ? { y: yAxisMin } : { y: 0 }}
          containerComponent={
            <Victory.VictoryVoronoiContainer
              labels={(d) => {
                return `${d.datum.value}\n${d.datum.date}`
              }}
              labelComponent={
                <Victory.VictoryTooltip
                  cornerRadius={4}
                  flyoutStyle={{
                    fill: 'white',
                    stroke: theme.colors.primary1,
                    strokeWidth: 1.5,
                  }}
                />
              }
            />
          }
        >
          <Victory.VictoryAxis
            dependentAxis
            label="mg/dL"
            tickCount={6}
            crossAxis={false}
            style={{
              grid: {
                stroke: ({ tick }) => (tick === -10 ? 'transparent' : '#ECECEC'),
                strokeWidth: 1,
              },
              axis: { stroke: '#ECECEC', strokeWidth: 0 },
              axisLabel: { fontSize: 20, padding: 40, fill: '#000', fontFamily: 'Raleway', fontWeight: 500 },
              ticks: { strokeWidth: 0 },
              tickLabels: {
                fill: '#000',
                fontWeight: 500,
                fontSize: 16,
              },
            }}
          />
          <Victory.VictoryLine
            data={chartReadings}
            style={{
              data: { stroke: theme.colors.primary1, strokeWidth: 1.5 },
            }}
            x="date"
            y="value"
            interpolation="monotoneX"
          />

          <Victory.VictoryLine
            data={[
              { value: 70, date: minDate },
              {
                value: 70,
                date: maxDate,
              },
            ]}
            x="date"
            y="value"
            style={{
              data: {
                stroke: theme.colors.charts.inRange,
                strokeWidth: 1.5,
              },
            }}
            standalone={false}
            interpolation="monotoneX"
          />

          <Victory.VictoryLine
            data={[
              { value: 180, date: minDate },
              {
                value: 180,
                date: maxDate,
              },
            ]}
            x="date"
            y="value"
            style={{
              data: {
                stroke: theme.colors.charts.inRange,
                strokeWidth: 1.5,
              },
            }}
            standalone={false}
            interpolation="monotoneX"
          />

          <Victory.VictoryAxis
            tickCount={5}
            width={20}
            tickFormat={(tick) => {
              if (90 <= 1) {
                return dayjs(tick).format('h:mm a')
              } else {
                return dayjs(tick).format('M/DD')
              }
            }}
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: {
                fill: '#000',
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Victory.VictoryChart>
      </div>
    </div>
  )
}

const useStyles = makeStyles<CustomTheme>((theme) =>
  createStyles({
    cardBanner: {
      height: 40,
      width: '100%',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      backgroundColor: '#00677F',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    cardDropdown: {
      height: 40,
      top: 45,
      minWidth: '100%',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      backgroundColor: 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      zIndex: 5,
    },

    noDataContainer: {
      minHeight: 300,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    toolbarButton: {
      minWidth: 200,
      backgroundColor: '#F2F2F2',
      color: 'black',
      border: '1px solid #BDBDBD',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        marginBottom: 5,
      },
    },
  }),
)
