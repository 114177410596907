import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function CalendarFileDownload() {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const [searchParams] = useSearchParams()

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  function formatDate(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '')
  }

  useEffect(() => {
    setMobileSetting()
    downloadIcsFile()
  }, [])

  const downloadIcsFile = () => {
    const startDate = searchParams.get('startDate') ?? 0
    const endDate = searchParams.get('endDate') ?? 0
    //formatting must include no whitespace for ics file to download properly
    if (startDate && endDate && dayjs(startDate).format('MM/DD/YYYY') !== 'Invalid Date' && dayjs(endDate).format('MM/DD/YYYY') !== 'Invalid Date') {
      window.open(
        URL.createObjectURL(
          new Blob(
            [
              `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTAMP:${formatDate(new Date())}
UID:${Math.random().toString(36).substring(2) + new Date().getTime().toString(36)}
SUMMARY:Tria Health Appointment (phone call)
DESCRIPTION:We will give you a call at the time and date listed. Please add our number to your contacts: 913-322-8456.
DTSTART:${formatDate(new Date(startDate))}
DTEND:${formatDate(new Date(endDate))}
END:VEVENT
END:VCALENDAR
`,
            ],
            { type: 'text/calendar' },
          ),
        ),
      )
    }
  }

  return (
    <>
      <Column styles={{ textAlign: 'center' }}>
        <img
          src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Add%20to%20Calendar.svg"
          alt="calendar"
          style={{ margin: '10px auto', width: 300 }}
        />
        <Typography size={54} styles={{ fontWeight: 700 }}>
          Add to Your Calendar
        </Typography>
        <Typography size={22} styles={{ width: isMobile ? '95%' : 600, margin: '20px auto' }}>
          If the download doesn't start, please review your browser settings and make sure that pop-up blockers are turned off. Once you've disabled
          them, click the button below to save your appointment to your calendar.
        </Typography>
        <Button
          text="Add to Your Calendar"
          onClick={downloadIcsFile}
          backgroundColor="#7A9A01"
          styles={{ borderRadius: 0, width: 250, margin: '15px auto', fontSize: 19 }}
        ></Button>
      </Column>
    </>
  )
}
