import Checkbox from 'Components/Base/Checkbox'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { Form } from 'react-bootstrap'
import './SurveyRow.scss'

interface Props {
  onSelectOption: (option: number) => void
  isMobile: boolean
  question?: string
  selectedOption?: number | string
}
export default function SurveyRow({ onSelectOption, isMobile, question, selectedOption = 0 }: Props) {
  return (
    <Row className="survey-row" styles={{ justifyContent: 'space-between' }}>
      <div className="survey-question-column">
        <Typography font="semi-bold">{question ?? ''}</Typography>
      </div>
      <Row
        styles={{
          width: '68%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isMobile ? (
          <div style={{ width: '100%' }}>
            <Form.Control value={+selectedOption} as="select" className="contact-dropdown-input">
              <option value={0}>Not at All</option>
              <option value={1}>Several Days</option>
              <option value={2}>More than Half the Days</option>
              <option value={3}>Nearly Every Day</option>
            </Form.Control>
          </div>
        ) : (
          <>
            <div className="survey-row-cell">
              <Checkbox checked={selectedOption === 0} onClick={() => onSelectOption(0)} />
            </div>
            <div className="survey-row-cell">
              <Checkbox checked={selectedOption === 1} onClick={() => onSelectOption(1)} />
            </div>
            <div className="survey-row-cell">
              <Checkbox checked={selectedOption === 2} onClick={() => onSelectOption(2)} />
            </div>
            <div className="survey-row-cell">
              <Checkbox checked={selectedOption === 3} onClick={() => onSelectOption(3)} />
            </div>
          </>
        )}
      </Row>
    </Row>
  )
}
