import FooterLogo from 'Assets/Images/TriaLogo_Primary_Large_White.png'
import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { CAREERS_URL, FAQ_URL, KNOWLEDGE_CENTER_URL, MEDIA_URL } from 'constants/index'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import React, { useEffect, useState } from 'react'
import { getAccessToken } from 'reducers/authSlice'
import styled, { useTheme } from 'styled-components'
import { AddressLine1, AddressLine2 } from 'tria-ui-react-lib/constants/ContactInfo.json'
import {
  ABOUT_URL,
  APPSTORE_URL,
  BLOG_URL,
  EMPLOYER_SOLUTIONS_URL,
  FACEBOOK_URL,
  HOME_URL,
  HOW_IT_WORKS_URL,
  INDIVIDUAL_SOLUTIONS_URL,
  LINKEDIN_URL,
  OUTCOMES_SOLUTIONS_URL,
  PLAYSTORE_URL,
  SUCCESS_STORIES_URL,
  TWITTER_URL,
} from '../../../../constants'
import './Footer.scss'
import FooterSection from './FooterSection'

export default function Footer() {
  const token = useAppSelector(getAccessToken)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const theme = useTheme()

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  return (
    <div className="footer-container">
      <div className="footer-section-wrapper">
        <div className="footer-section-container" style={{ paddingTop: 30, minWidth: 250 }}>
          <Row styles={{ marginLeft: -9, marginBottom: 15 }}>
            <StyledLogo src={FooterLogo} alt="Tria Health Logo" />
          </Row>
          <Row>
            <Typography size={15}>{AddressLine1}</Typography>
          </Row>
          <Row styles={{ marginBottom: 20 }}>
            <Typography size={15}>{AddressLine2}</Typography>
          </Row>
          <Row styles={{ marginTop: 10 }}>
            <a href="https://triahealth.com/contact" target={'_blank'} rel="noreferrer" className="footer-contact-button" style={{ width: '100%' }}>
              <StyledContactButton pageName="Footer" text="Contact Us" styles={{ width: '70%' }} />
            </a>
          </Row>
          <Row styles={{ marginLeft: -20, marginTop: 25, justifyContent: 'space-evenly' }}>
            <Icon name={'facebook'} link={FACEBOOK_URL} className="social-icon" styles={{ width: 23, height: 23 }} />
            <Icon
              name={'x'}
              link={TWITTER_URL}
              className="social-icon"
              styles={{ backgroundColor: 'white', color: theme.colors.primary1, borderRadius: 100, padding: 5, width: 15, height: 15 }}
            />
            <Icon
              name={'linkedin'}
              link={LINKEDIN_URL}
              className="social-icon"
              styles={{ backgroundColor: 'white', color: theme.colors.primary1, borderRadius: 100, padding: 5, width: 15, height: 15 }}
            />
            <Icon
              name={'youtube'}
              link={LINKEDIN_URL}
              className="social-icon"
              styles={{ backgroundColor: 'white', color: theme.colors.primary1, borderRadius: 100, padding: 5, width: 15, height: 15 }}
            />
            <Icon
              name={'play'}
              link={PLAYSTORE_URL}
              className="social-icon"
              styles={{ backgroundColor: 'white', color: theme.colors.primary1, borderRadius: 100, padding: 5, width: 15, height: 15 }}
            />
            <Icon
              name={'ios'}
              link={APPSTORE_URL}
              className="social-icon"
              styles={{ backgroundColor: 'white', color: theme.colors.primary1, borderRadius: 100, padding: 5, width: 15, height: 15 }}
            />
          </Row>
        </div>
        <div className="footer-links-wrapper">
          <StyledFooterSection
            title={'My Tria Portal'}
            links={[
              {
                url: '/',
                title: 'Home',
                relative: true,
              },
              {
                url: '/profile',
                title: 'Profile',
                relative: true,
                hidden: token ? false : true,
              },
              {
                url: '/careplan/medications',
                title: 'Medications',
                relative: true,
                hidden: token ? false : true,
              },
              {
                url: '/careplan',
                title: 'Care Plan',
                relative: true,
                hidden: token ? false : true,
              },
              {
                url: '/appointment',
                title: 'Schedule Appointment',
                relative: true,
              },
              {
                url: '/resources',
                title: 'Resources',
                relative: true,
              },
            ]}
          />
          <StyledFooterSection
            title={'Company'}
            links={[
              {
                url: HOME_URL,
                title: 'Home',
              },
              {
                url: ABOUT_URL,
                title: 'About Us',
              },
              {
                url: CAREERS_URL,
                title: 'Careers',
              },
              {
                url: MEDIA_URL,
                title: 'Media',
              },
              {
                url: SUCCESS_STORIES_URL,
                title: 'Patient Stories',
              },
            ]}
          />
          <StyledFooterSection
            title={'Solutions'}
            links={[
              {
                url: HOW_IT_WORKS_URL,
                title: 'How It Works',
              },
              {
                url: INDIVIDUAL_SOLUTIONS_URL,
                title: 'Individuals',
              },
              {
                url: EMPLOYER_SOLUTIONS_URL,
                title: 'Employers',
              },
              {
                url: OUTCOMES_SOLUTIONS_URL,
                title: 'Outcomes',
              },
            ]}
          />
          <StyledFooterSection
            title={'Resources'}
            links={[
              {
                url: KNOWLEDGE_CENTER_URL,
                title: 'Knowledge Center',
              },
              {
                url: BLOG_URL,
                title: 'Blog',
              },
              {
                url: FAQ_URL,
                title: 'FAQ',
              },
              {
                url: '/reimbursement',
                title: 'Reimbursement',
                relative: true,
                hidden: false,
              },
            ]}
          />
        </div>
      </div>
      <Row center>
        <StyledSpacer />
      </Row>
      {isMobile ? (
        <Column styles={{ marginTop: 20, marginBottom: 20 }} center>
          <a href={'https://www.triahealth.com/communication'} target={'_blank'} rel="noreferrer">
            <div style={{ marginLeft: 25 }}>
              <p
                style={{
                  color: 'white',
                  fontFamily: 'Raleway-Bold',
                  fontWeight: 400,
                  marginLeft: 'auto',
                }}
              >
                Communication Policy
              </p>
            </div>
          </a>
          <a href={'https://www.triahealth.com/privacy'} target={'_blank'} rel="noreferrer">
            <div style={{ marginLeft: 25 }}>
              <p
                style={{
                  color: 'white',
                  fontFamily: 'Raleway-Bold',
                  fontWeight: 400,
                }}
              >
                Privacy Policy
              </p>
            </div>
          </a>
        </Column>
      ) : (
        <Row styles={{ marginTop: 20 }}>
          <a href={'https://www.triahealth.com/communication'} target={'_blank'} rel="noreferrer">
            <div style={{ marginLeft: 130 }}>
              <p
                style={{
                  color: 'white',
                  fontFamily: 'Raleway-Bold',
                  fontWeight: 400,
                }}
              >
                Communication Policy
              </p>
            </div>
          </a>
          <a href={'https://www.triahealth.com/privacy'} target={'_blank'} rel="noreferrer">
            <div style={{ marginLeft: 30 }}>
              <p
                style={{
                  color: 'white',
                  fontFamily: 'Raleway-Bold',
                  fontWeight: 400,
                }}
              >
                Privacy Policy
              </p>
            </div>
          </a>
        </Row>
      )}

      <Row className="copyright-container" center>
        <div className="copyright-text-container">
          <text style={{ color: '#bcc1c5', fontSize: '14px' }}>
            © Copyright {dayjs().format('YYYY')} by{' '}
            <span
              style={{
                color: 'white',
                fontFamily: 'Raleway-Bold',
                fontWeight: 900,
              }}
            >
              Tria Health, LLC.{' '}
            </span>
            All Rights Reserved.
          </text>
        </div>
      </Row>
    </div>
  )
}

const StyledLogo = styled.img`
  flex: 1;
  object-fit: contain;
  width: 200px;
`

const StyledFooterSection = styled(FooterSection)`
  margin-right: 20px;
  min-width: 20%;
`

const StyledSpacer = styled.div`
  height: 2px;
  width: 90%;
  background-color: ${({ theme }) => theme.colors.white};
`
const StyledContactButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary1};
  border-radius: 0px;
`
