import Phone from 'Assets/Images/9.jpg'
import Survey from 'Assets/Images/survey.png'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import ContentCard from './ContentCard'

type Props = {
  onToggleModal?: (isOpen: boolean) => void
  type: 'Diabetes' | 'Blood Pressure' | 'Digital Scale'
}
export default function ContentCards({ onToggleModal, type }: Props) {
  const theme = useTheme()
  const navigate = useNavigate()

  const openSurveySite = () => {
    window.open('https://www.surveymonkey.com/r/KDPKK92', '_blank')
  }

  const openAppStore = () => {
    window.open('https://tosto.re/triahealth', '_blank', 'noreferrer')
  }

  const navigateToResources = () => {
    window.scrollTo(0, 0)
    if (type === 'Diabetes') {
      navigate('/resources/diabetes')
    } else if (type === 'Blood Pressure') {
      navigate('/resources/bloodpressure')
    } else if (type === 'Digital Scale') {
      navigate('/weight-loss')
    }
  }

  const navigateToMessagingCenter = () => {
    window.scrollTo(0, 0)
    navigate('/messaging/inbox')
  }

  return (
    <ResponsiveContainer styles={{ justifyContent: 'space-between' }}>
      {type === 'Digital Scale' && (
        <ContentCard
          backgroundImage="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Health%20Coach_2.png"
          buttonText={'Message My Health Coach'}
          buttonOnClick={() => navigateToMessagingCenter()}
        />
      )}
      {!(type === 'Digital Scale') && <ContentCard backgroundImage={Phone} buttonText={'Download Mobile App'} buttonOnClick={openAppStore} />}
      <ContentCard buttonText={'Explore'} buttonOnClick={navigateToResources}>
        <StyledCardContent>
          <div />
          <div>
            <Row center>
              <Icon
                name={type === 'Digital Scale' ? 'weight' : 'water-drop'}
                color={theme.colors.primary1}
                size={type === 'Digital Scale' ? '8x' : '4x'}
              />
            </Row>
            <Row center>
              {type === 'Digital Scale' ? (
                <Column>
                  <Typography
                    font="bold"
                    color={theme.colors.primary1}
                    size={26}
                    styles={{
                      lineHeight: '40px',
                      textAlign: 'center',
                    }}
                  >
                    Other
                  </Typography>
                  <Typography
                    font="bold"
                    color={theme.colors.primary1}
                    size={26}
                    styles={{
                      lineHeight: '30px',
                      textAlign: 'center',
                    }}
                  >
                    Resources
                  </Typography>
                </Column>
              ) : (
                <Typography
                  font="bold"
                  color={theme.colors.primary1}
                  size={22}
                  styles={{
                    lineHeight: '30px',
                    textAlign: 'center',
                  }}
                >
                  {`${type} Resources`}
                </Typography>
              )}
            </Row>
          </div>
          <div></div>
        </StyledCardContent>
      </ContentCard>
      {!(type === 'Digital Scale') && onToggleModal && (
        <ContentCard buttonOnClick={() => onToggleModal(true)} buttonText={`What's New`}>
          <StyledCardContent>
            <div />
            <div>
              <Row center>
                <Icon name="sparkles" color={theme.colors.primary1} size="4x" />
              </Row>
              <Row center>
                <Typography
                  font="bold"
                  color={theme.colors.primary1}
                  size={22}
                  styles={{
                    lineHeight: '30px',
                    textAlign: 'center',
                  }}
                >
                  Dashboard Updates
                </Typography>
              </Row>
            </div>
            <div></div>
          </StyledCardContent>
        </ContentCard>
      )}
      {!(type === 'Digital Scale') && (
        <ContentCard
          backgroundImage={Survey}
          buttonText={'Provide Feedback'}
          buttonOnClick={openSurveySite}
          buttonLink={'https://www.surveymonkey.com/r/KDPKK92'}
        />
      )}
      {type === 'Digital Scale' && <ContentCard backgroundImage={Phone} buttonText={'Download Mobile App'} buttonOnClick={openAppStore} />}
    </ResponsiveContainer>
  )
}

const StyledCardContent = styled(Column)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  justify-content: space-between;
`
