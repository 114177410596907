import { DataGrid } from '@mui/x-data-grid'
import logo from 'Assets/Images/Tria Logo_Primary.png'
import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import CustomModal from 'Components/Modal'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useDashboard from 'hooks/useDashboard'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePatientReadings from 'hooks/usePatientReadings'
import useProfile from 'hooks/useProfile'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useSearchParams } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { getBloodPressureReadings, getBloodPressureReadingsSummary, loadBloodPressureSummary } from 'reducers/dashboardSlice'
import { getProfile } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import { useTheme } from 'styled-components'
import { PhoneNumberDashDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import {
  BloodPressureChartReading,
  BloodPressureReadingMetadata,
  DashboardType,
  DateSetting,
  HeartRateReading,
  PatientBloodPressureReading,
  PieChartData,
} from 'types/Dashboards'
import { useScreenshot } from 'use-react-screenshot'
import { getReadingLevelDescription } from 'utilities/getReadingLevelDescription'
import * as Victory from 'victory'
import { VictoryPie, VictoryTooltip } from 'victory'
import '../DashboardGlucosePage/DashboardPage.scss'
import BPWhatsNewModal from '../DashboardGlucosePage/components/BPWhatsNewModal'
import ContentCards from '../DashboardGlucosePage/components/ContentCards'
import GridCard from '../DashboardGlucosePage/components/GridCard'
import TextSettingsCard from '../DashboardGlucosePage/components/TextSettingsCard'
import { CustomPagination } from '../components/CustomPagination'
import MobileDataGrid from '../components/MobileDataGrid'
import { CustomLabel } from '../components/PieChart/CustomLabel'
import PieChartKeyRange from '../components/PieChartKeyRange'
import { filterOptions } from '../constants'
import BloodPressureEditModal from './BloodPressureEditModal'
import { CustomBloodPressureToolbar, ReadingKeys, ReadingTypeKeys, ShowKeys } from './CustomBloodPressureToolbar'
import { columns } from './constants'

type ItemRow = {
  id: number
  readingDate: string
  readingTime: string
  systolicValue: string
  diastolicValue: string
  heartRate: string
  notes: string
  patientReadingLevel: string
}

type EditModalSettings = {
  isOpen: boolean
  reading?: PatientBloodPressureReading
}

export default function DashboardBloodPressurePage() {
  const theme = useTheme()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Blood Pressure')
  const profile = useAppSelector(getProfile)
  const { getBloodPressureDeviceStatus } = useDashboard()

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [deviceStatusModalOpen, setDeviceStatusModalOpen] = useState(false)
  const [deviceStatusModalBody, setDeviceStatusModalBody] = useState<string[] | undefined>(undefined)
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const [searchParams, setSearchParams] = useSearchParams()
  const { getWelltrakFromGuid } = useProfile(accessToken!)

  useEffect(() => {
    const id = searchParams.get('id')
    const ID = searchParams.get('ID')

    if (id !== null) {
      getWelltrakFromGuid(id).then((res: any) => {
        setAdminWelltrak(res.data)
      })
    }
    if (ID !== null) {
      getWelltrakFromGuid(ID).then((res: any) => {
        setAdminWelltrak(res.data)
      })
    }

    async function getDeviceStatus() {
      dispatch(setLoading(true))
      await getBloodPressureDeviceStatus()
        .then((response) => {
          if (!response.data) {
          }
          response.data.value.deviceStatus && response.data.value.deviceStatus[0] && setDeviceStatusModalOpen(true)
          response.data.value.deviceStatus && response.data.value.deviceStatus[0] && setDeviceStatusModalBody(response.data.value.deviceStatus)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
    getDeviceStatus()
  }, [])

  const tableRef = useRef<null | HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  const bloodPressureReadingsSummary = useAppSelector(getBloodPressureReadingsSummary)
  const bloodPressureReadings = useAppSelector(getBloodPressureReadings)
  const { getBloodPressureConfig } = usePatientReadings()
  const [chartHeight, setChartHeight] = useState(300)
  const [minDate, setMinDate] = useState<string>('')
  const [maxDate, setMaxDate] = useState<string>('')
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([])
  const [readings, setReadings] = useState<PatientBloodPressureReading[]>()
  const [itemRows, setItemRows] = useState<ItemRow[] | undefined>([])
  const [show, setShow] = useState<ShowKeys>('All')
  const [range, setRange] = useState<ReadingKeys>('All')
  const [type, setType] = useState<ReadingTypeKeys>('All Types')

  const [rangeNumber, setRangeNumber] = useState<number | undefined>()
  const [tableRows, setTableRows] = useState<ItemRow[] | undefined>([])

  const [editModalSettings, setEditModalSettings] = useState<EditModalSettings>({
    isOpen: false,
  })
  const [readingMetadata, setReadingMetadata] = useState<BloodPressureReadingMetadata>()
  const [heartRateReadings, setHeartRateReadings] = useState<HeartRateReading[]>([])
  const [bloodPressureChartReadings, setBloodPressureChartReadings] = useState<BloodPressureChartReading[]>([])

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [maximumReading, setMaximumReading] = useState<number | undefined>()
  const [highSystolicLimit, setHighSystolicLimit] = useState<number | undefined>()
  const [highDiastolicLimit, setHighDiastolicLimit] = useState<number | undefined>()

  const [adminWelltrak, setAdminWelltrak] = useState<string>()

  const [readingFilter, setReadingFilter] = useState<DateSetting>({
    label: 'Last 90 Days',
    value: 90,
  })

  const [showCustomFields, setShowCustomFields] = useState<boolean>()

  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()

  const [startTime, setStartTime] = useState<string | undefined>()
  const [endTime, setEndTime] = useState<string | undefined>()
  const [updateReading, setUpdateReading] = useState<boolean>(false)

  const [image1, takeScreenshot] = useScreenshot()
  const [image2, takeScreenshot2] = useScreenshot()
  const [image3, takeScreenshot3] = useScreenshot()
  const [image4, takeScreenshot4] = useScreenshot()

  const readingsChartRef = useRef<HTMLDivElement | null>(null)
  const pieChartRef = useRef<HTMLDivElement | null>(null)
  const heartRateReadingsChartRef = useRef<HTMLDivElement | null>(null)
  const gridCardsRef = useRef<HTMLDivElement | null>(null)

  const executeTabeScroll = () => tableRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  useEffect(() => {
    dispatch(
      loadBloodPressureSummary({
        token: accessToken,
        overrideWelltrak: adminWelltrak,
      }),
    )
  }, [adminWelltrak, updateReading])
  useEffect(() => {
    getReadings(readingFilter)
    if (readingFilter.label === 'All Readings') {
      analytics.postAnalyticsEvent({
        category: `reading_filter_dashboardBloodPressure_allReadings`,
        action: `reading_filter_dashboardBloodPressure_allReadings`,
        label: `Button`,
        screenName: 'BloodPressureDashboard',
      })
    } else if (readingFilter.label === 'Custom') {
      analytics.postAnalyticsEvent({
        category: `reading_filter_dashboardBloodPressure_customReadings`,
        action: `reading_filter_dashboardBloodPressure_customReadings`,
        label: `Button`,
        screenName: 'BloodPressureDashboard',
      })
    }
  }, [readingFilter, updateReading])

  const getReadings = (filter?: DateSetting) => {
    if (filter && filter.value === 0) {
      setShowCustomFields(true)
    } else {
      setShowCustomFields(false)
    }

    if (bloodPressureReadings?.length > 0) {
      setReadingMetadata({
        averageReading: `${bloodPressureReadingsSummary.averageSystolicReading.toFixed()}/${bloodPressureReadingsSummary.averageDiastolicReading.toFixed()}`,
        averageBPM: bloodPressureReadingsSummary.averageHeartRate,
        aboveRangeReadingCount: bloodPressureReadingsSummary.aboveRangeReadings ? bloodPressureReadingsSummary.aboveRangeReadings.length : 0,
        inRangeReadingCount: bloodPressureReadingsSummary.inRangeReadings ? bloodPressureReadingsSummary.inRangeReadings.length : 0,
        highReadingCount: bloodPressureReadingsSummary.highReadings ? bloodPressureReadingsSummary.highReadings.length : 0,
        lowReadingCount: bloodPressureReadingsSummary.lowReadings ? bloodPressureReadingsSummary.lowReadings.length : 0,
        totalReadingCount: bloodPressureReadings.length ?? 0,
        firstReadingDate: bloodPressureReadings.length ? dayjs(bloodPressureReadings[0]?.readingDate) : undefined,
        lastReadingDate: bloodPressureReadings ? dayjs(bloodPressureReadings[bloodPressureReadings.length - 1]?.readingDate) : undefined,
      })

      const readingsResponse: PatientBloodPressureReading[] = bloodPressureReadings

      if (!filter?.value && filter?.value !== 0) {
        setReadings(readingsResponse)
        setStartDate(undefined)
        setEndDate(undefined)
      } else if (filter?.value === 0 && endDate === undefined) {
        return
      } else if (filter?.label === 'Custom' && startDate && endDate) {
        setReadings(
          readingsResponse.filter((reading) => {
            const date1 = new Date(dayjs(endDate).format('YYYY-MM-DD')).getTime()
            const date2 = new Date(dayjs(startDate).format('YYYY-MM-DD')).getTime()
            const date3 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
            if (date3 >= date2 && date3 <= date1) {
              return reading
            }
          }),
        )
      } else {
        setStartDate(undefined)
        setEndDate(undefined)
        setReadings(
          readingsResponse.filter((reading) => {
            const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
            const date2 = new Date(dayjs().subtract(filter.value!, 'day').format('YYYY-MM-DD')).getTime()
            if (date1 >= date2) {
              return reading
            }
          }),
        )
      }

      setHeartRateReadings(heartRateReadings)
      setBloodPressureChartReadings(bloodPressureChartReadings)
      if (readingFilter.label === 'All Readings') {
        setMinDate(dayjs(readingsResponse[0]?.readingDate).format('M/DD/YYYY'))
        setMaxDate(dayjs(readingsResponse[readingsResponse.length - 1]?.readingDate).format('M/DD/YYYY'))
      } else {
        setMinDate(dayjs(readingsResponse[0]?.readingDate).format('M/DD'))
        setMaxDate(dayjs(readingsResponse[readingsResponse.length - 1]?.readingDate).format('M/DD'))
      }
      setItemRows(tableRows)
      setTableRows(tableRows)

      const pieChartCounts: PieChartData[] = []
      const highReadings = bloodPressureReadingsSummary.highBloodPressureReadings?.length ?? 0
      const aboveReadings = bloodPressureReadingsSummary.aboveRangeBloodPressureReadings?.length ?? 0
      const rangeReadings = bloodPressureReadingsSummary.inRangeBloodPressureReadings?.length ?? 0
      const lowReadings = bloodPressureReadingsSummary.lowBloodPressureReadings?.length ?? 0
      const totalReadingCount = highReadings + aboveReadings + rangeReadings + lowReadings
      if (highReadings > 0) {
        pieChartCounts.push({
          x: highReadings.toString(),
          y: highReadings,
          type: 'High',
          color: 'rgb(155,39,67)',
          percentage: Math.round((highReadings / totalReadingCount) * 100),
        })
      }
      if (aboveReadings > 0) {
        pieChartCounts.push({
          x: aboveReadings.toString(),
          y: aboveReadings,
          type: 'Above Range',
          color: 'rgb(234,170,0)',
          percentage: Math.round((aboveReadings / totalReadingCount) * 100),
        })
      }
      if (rangeReadings > 0) {
        pieChartCounts.push({
          x: rangeReadings.toString(),
          y: rangeReadings,
          type: 'In Range',
          color: 'rgba(122,154,1,1)',
          percentage: Math.round((rangeReadings / totalReadingCount) * 100),
        })
      }
      if (lowReadings > 0) {
        pieChartCounts.push({
          x: lowReadings.toString(),
          y: lowReadings,
          type: 'Low',
          color: 'rgba(71,10,104,1)',
          percentage: Math.round((lowReadings / totalReadingCount) * 100),
        })
      }
      setPieChartData(pieChartCounts)
    } else {
      setPieChartData([])
      setHeartRateReadings([])
      setBloodPressureChartReadings([])
      setReadings([])
      setItemRows([])
      setTableRows([])
    }
  }

  useEffect(() => {
    if (readings) {
      const readingsCopy = [...readings!]
      const heartRateReadings: HeartRateReading[] = readingsCopy.map((reading) => {
        return {
          x: dayjs(reading.readingDate).format('M/DD'),
          y: reading.heartRateValue,
          date: `${dayjs(reading.readingDate).format('M/DD/YYYY')}`,
          time: `${dayjs(reading.readingDate).format('h:mm:ss a')}`,
        }
      })
      const bloodPressureChartReadings: BloodPressureChartReading[] = readingsCopy.map((reading) => {
        return {
          x: readingFilter.label === 'All Readings' ? dayjs(reading.readingDate).format('M/DD/YYYY') : dayjs(reading.readingDate).format('M/DD'),
          y: reading.systolicValue,
          y0: reading.diastolicValue,
          level: reading.patientReadingLevel,
          label: `${reading.systolicValue}/${reading.diastolicValue}\n${dayjs(reading.readingDate).format('M/DD/YYYY')}\n${dayjs(
            reading.readingDate,
          ).format('h:mm:ss a')}`,
        }
      })

      const tableRows = readings?.map((reading, index) => {
        return {
          id: index,
          readingId: `${reading.patientReadingPId}`,
          readingDate: `${dayjs(reading.readingDate).format('M/DD/YYYY')}`,
          readingTime: `${dayjs(reading.readingDate).format('h:mm a')}`,
          systolicValue: `${reading.systolicValue}`,
          diastolicValue: `${reading.diastolicValue}`,
          heartRate: `${reading.heartRateValue}`,
          notes: `${reading.notes ? reading.notes : ''}`,
          patientReadingLevel: `${reading.patientReadingLevel ? getReadingLevelDescription(reading.patientReadingLevel) : ''}`,
        }
      })

      const highReadings: PatientBloodPressureReading[] = []
      const aboveReadings: PatientBloodPressureReading[] = []
      const rangeReadings: PatientBloodPressureReading[] = []
      const lowReadings: PatientBloodPressureReading[] = []
      readings.map((reading) => {
        if (reading.patientReadingLevel === 4) {
          highReadings.push(reading)
        } else if (reading.patientReadingLevel === 3) {
          aboveReadings.push(reading)
        } else if (reading.patientReadingLevel === 2) {
          rangeReadings.push(reading)
        } else {
          lowReadings.push(reading)
        }
      })
      setReadingMetadata({
        averageReading: `${(readings.reduce((a, v) => (a = a + v.systolicValue), 0) / readings.length).toFixed()}/${(
          readings.reduce((a, v) => (a = a + v.diastolicValue), 0) / readings.length
        ).toFixed()}`,
        averageBPM: readings.reduce((a, v) => (a = a + v.heartRateValue), 0) / readings.length,
        aboveRangeReadingCount: aboveReadings ? aboveReadings.length : 0,
        inRangeReadingCount: rangeReadings ? rangeReadings.length : 0,
        highReadingCount: highReadings.length ?? 0,
        lowReadingCount: lowReadings.length ?? 0,
        totalReadingCount: readings.length ?? 0,
        firstReadingDate: readings ? dayjs(readings[0]?.readingDate) : undefined,
        lastReadingDate: readings ? dayjs(readings[readings.length - 1]?.readingDate) : undefined,
      })
      setHeartRateReadings(heartRateReadings)
      setBloodPressureChartReadings(bloodPressureChartReadings)
      if (readingFilter.label === 'All Readings') {
        setMinDate(dayjs(readings[0]?.readingDate).format('M/DD/YYYY'))
        setMaxDate(dayjs(readings[readings.length - 1]?.readingDate).format('M/DD/YYYY'))
      } else {
        setMinDate(dayjs(readings[0]?.readingDate).format('M/DD'))
        setMaxDate(dayjs(readings[readings.length - 1]?.readingDate).format('M/DD'))
      }
      setItemRows(tableRows)
      setTableRows(tableRows.reverse())
      const totalReadingCount = highReadings.length + aboveReadings.length + rangeReadings.length + lowReadings.length
      const pieChartCounts: PieChartData[] = []
      if (highReadings.length > 0) {
        pieChartCounts.push({
          x: highReadings.length.toString(),
          y: highReadings.length,
          type: 'High',
          color: 'rgb(155,39,67)',
          percentage: Math.round((highReadings.length / totalReadingCount) * 100),
        })
      }
      if (aboveReadings.length > 0) {
        pieChartCounts.push({
          x: aboveReadings.length.toString(),
          y: aboveReadings.length,
          type: 'Above Range',
          color: 'rgb(234,170,0)',
          percentage: Math.round((aboveReadings.length / totalReadingCount) * 100),
        })
      }
      if (rangeReadings.length > 0) {
        pieChartCounts.push({
          x: rangeReadings.length.toString(),
          y: rangeReadings.length,
          type: 'In Range',
          color: 'rgba(122,154,1,1)',
          percentage: Math.round((rangeReadings.length / totalReadingCount) * 100),
        })
      }
      if (lowReadings.length > 0) {
        pieChartCounts.push({
          x: lowReadings.length.toString(),
          y: lowReadings.length,
          type: 'Low',
          color: 'rgba(71,10,104,1)',
          percentage: Math.round((lowReadings.length / totalReadingCount) * 100),
        })
      }
      setPieChartData(pieChartCounts)
    }
  }, [readings])

  useEffect(() => {
    const copy = filterByType(type, itemRows)
    if (startTime && endTime) {
      setTableRows(
        copy
          .filter(
            (row) =>
              dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00') >= startTime &&
              dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00') <= endTime,
          )
          .sort((a, b) => {
            if (dayjs('2019-01-25 '.concat(a.readingTime)).unix() < dayjs('2019-01-25 '.concat(b.readingTime)).unix()) {
              //added random date to get unix timestamp
              return -1
            }
            if (dayjs('2019-01-25 '.concat(a.readingTime)).unix() > dayjs('2019-01-25 '.concat(b.readingTime)).unix()) {
              return 1
            }
            return 0
          }),
      )
    }
  }, [type, show, range, rangeNumber, startTime, endTime])

  useEffect(() => {
    if (accessToken) {
      getBloodPressureConfig(accessToken).then((res: any) => {
        setHighSystolicLimit(res.data.highSystolicLimit)
        setHighDiastolicLimit(res.data.highDiastolicLimit)
      })
    }
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const filterByType = (type: ReadingTypeKeys, rows?: ItemRow[]): ItemRow[] => {
    if (rows) {
      let copy = rows

      switch (type) {
        case 'All Types':
          break
        case 'Highs':
          copy = copy?.filter((x) => x.patientReadingLevel === 'High')
          break
        case 'Above Range':
          copy = copy?.filter((x) => x.patientReadingLevel === 'Above Range')
          break
        case 'In Range':
          copy = copy?.filter((x) => x.patientReadingLevel === 'In Range')
          break
        case 'Lows':
          copy = copy?.filter((x) => x.patientReadingLevel === 'Low')
          break
        default:
          break
      }

      return copy
    } else {
      return []
    }
  }
  const filterTableRows = (rows: ItemRow[], show: ShowKeys, range: ReadingKeys, rangeNumber: number) => {
    let copy = rows
    switch (show) {
      case 'All':
        break
      case 'Systolic':
        copy = range === 'Below' ? copy.filter((x) => +x.systolicValue < rangeNumber) : copy.filter((x) => +x.systolicValue > rangeNumber)
        break
      case 'Diastolic':
        copy = range === 'Below' ? copy.filter((x) => +x.diastolicValue < rangeNumber) : copy.filter((x) => +x.diastolicValue > rangeNumber)
        break
      case 'Pulse':
        copy = range === 'Below' ? copy.filter((x) => +x.heartRate < rangeNumber) : copy.filter((x) => +x.heartRate > rangeNumber)
        break
      default:
        break
    }
    setTableRows(copy)
    if (startTime && endTime) {
      setTableRows(
        copy
          .filter(
            (row) =>
              dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00') >= startTime &&
              dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00') <= endTime,
          )
          .sort((a, b) => {
            if (a.readingTime < b.readingTime) {
              return -1
            }
            if (a.readingTime > b.readingTime) {
              return 1
            }
            return 0
          }),
      )
    }
  }

  useEffect(() => {
    const copy = filterByType(type, itemRows)
    if (show !== 'All' && range !== 'All' && rangeNumber && rangeNumber > 0) {
      filterTableRows(copy, show, range, rangeNumber)
    } else {
      setTableRows(copy)
      if (startTime && endTime) {
        setTableRows(
          copy
            .filter(
              (row) =>
                dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00') >= startTime &&
                dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00') <= endTime,
            )
            .sort((a, b) => {
              if (a.readingTime < b.readingTime) {
                return -1
              }
              if (a.readingTime > b.readingTime) {
                return 1
              }
              return 0
            }),
        )
      }
    }
  }, [type, show, range, rangeNumber, startTime, endTime])

  useEffect(() => {
    if (startDate && endDate) {
      getReadings(readingFilter)
    }
  }, [startDate, endDate])

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setChartHeight(600)
      setIsMobile(true)
    } else {
      setChartHeight(400)
      setIsMobile(false)
    }
  }

  const getColorFromLevel = (level: number) => {
    switch (level) {
      case 0:
        return 'rgba(71,10,104,1)'
      case 1:
        return 'rgba(71,10,104,1)'
      case 2:
        return 'rgba(122,154,1,1)'
      case 3:
        return 'rgb(234,170,0)'
      case 4:
        return 'rgb(155,39,67)'
      default:
        break
    }
  }

  const customToolbar = () => (
    <CustomBloodPressureToolbar
      setReadingType={(type: ReadingTypeKeys) => setType(type)}
      readingType={type}
      show={show}
      range={range}
      rangeNumber={rangeNumber}
      setShow={(show: ShowKeys) => setShow(show)}
      setRange={(range: ReadingKeys) => setRange(range)}
      setRangeNumber={(rangeNumber: number) => setRangeNumber(rangeNumber)}
      isMobile={isMobile}
      onFilterRangeValue={(show: ShowKeys, range: ReadingKeys, rangeNumber: number) => console.log('show, range, rangeNumber')}
      exportToPdf={exportToPdf}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      startTime={startTime}
      endTime={endTime}
    />
  )

  function editClick(id) {
    analytics.postAnalyticsEvent({
      category: `click_button_dashboardBloodPressure_edit_reading`,
      action: `click_button_dashboardBloodPressure_edit_reading`,
      label: `Button`,
      screenName: 'BloodPressureDashboard',
    })
    setEditModalSettings({
      ...editModalSettings,
      isOpen: true,
      reading: readings?.find((x) => x.patientReadingPId.toString() === id),
    })
  }
  function exportToPdf() {
    const doc = new jsPDF('landscape')
    autoTable(doc, {
      styles: { cellWidth: 'auto', valign: 'middle', halign: 'center' },
      headStyles: { fillColor: theme.colors.primary1, minCellHeight: 15, fontSize: 13 },
      columnStyles: { heartRate: { minCellHeight: 12 } },
      columns: [
        { header: 'Date', dataKey: 'readingDate' },
        { header: 'Time', dataKey: 'readingTime' },
        { header: 'Systolic', dataKey: 'systolicValue' },
        { header: 'Diastolic', dataKey: 'diastolicValue' },
        { header: 'Type', dataKey: 'patientReadingLevel' },
        { header: 'Heart Rate (bpm)', dataKey: 'heartRate' },
      ],
      body: tableRows,

      didDrawPage: function (data) {
        // Header
        doc.setFontSize(15)
        doc.setTextColor(theme.colors.primary1)
        doc.text(`Type: ${show}`, data.settings.margin.left, 10)

        range === 'Above' && doc.text(`${range}: ${rangeNumber}`, 105, 10)
        range === 'Below' && doc.text(`${range}: ${rangeNumber}`, 105, 10)
        doc.text(`Range: ${type}`, 180, 10)
        const pageSize = doc.internal.pageSize
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.setFontSize(12)
        doc.setTextColor('#000000')
        doc.addImage(logo, 'JPEG', 246, 3, 38, 10)
        doc.text('Have questions about the information above or would like more detail?', 85, pageHeight - 10)
        doc.text(` Contact a Tria Pharmacist today at ${PhoneNumberDashDelimited} or email us at info@triahealth.com.`, 69, pageHeight - 5)
      },
    })

    doc.save('Blood Pressure Readings.pdf')
  }

  const getImage = () => {
    analytics.postAnalyticsEvent({ category: 'buttonclick', action: 'export charts to pdf', screenName: 'BloodPressureDashboard' })
    takeScreenshot(readingsChartRef.current)
    takeScreenshot2(pieChartRef.current)
    takeScreenshot3(heartRateReadingsChartRef.current)
  }
  useEffect(() => {
    exportCharts()
  }, [image3])

  const exportCharts = () => {
    if (image3) {
      const doc = new jsPDF('p', 'mm', 'A4')
      doc.setFont('Raleway')
      const pageSize = doc.internal.pageSize
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
      doc.setFontSize(15)
      doc.addImage(logo, 'JPEG', 88, 6, 38, 10)
      //bp chart key range
      doc.setFontSize(11)
      doc.setFillColor('#9B2743')
      doc.roundedRect(14, 192, 3, 3, 0.5, 0.5, 'F')
      doc.text(`High (>180/110)`, 19, 194.5)
      doc.setFillColor('#EAAA00')
      doc.roundedRect(48, 192, 3, 3, 0.5, 0.5, 'F')
      doc.text(`Above Range (140-180/90-110)`, 53, 194.5)
      doc.setFillColor('#7A9A01')
      doc.roundedRect(107, 192, 3, 3, 0.5, 0.5, 'F')
      doc.text(`In Range (90-139/50-89)`, 112, 194.5)
      doc.setFillColor('#470a68')
      doc.roundedRect(155, 192, 3, 3, 0.5, 0.5, 'F')
      doc.text(`Low Readings (<90/50)`, 160, 194.5)
      // key metrics
      doc.setFontSize(11)
      readingMetadata?.averageBPM && doc.text(`Average Heart Rate: ${Math.round(readingMetadata?.averageBPM)} bpm`, 127, 40)
      doc.text(`Average Blood Pressure: ${readingMetadata?.averageReading} mmHg`, 117, 50)
      doc.text(`Number of High Readings: ${readingMetadata?.highReadingCount}`, 127, 60)
      doc.text(`Number of Low Readings: ${readingMetadata?.lowReadingCount}`, 127, 70)
      doc.text(`Total Number of Readings: ${readingMetadata?.totalReadingCount}`, 127, 80)
      doc.setDrawColor(theme.colors.lightGray)
      doc.roundedRect(100, 26, 100, 70, 3, 3)

      //pie chart
      image2 && doc.addImage(image2, 'JPEG', 15, 23, 50, 50)
      doc.roundedRect(10, 26, 60, 70, 3, 3)
      //page title
      doc.setFontSize(15)
      doc.setTextColor('black')
      doc.text(
        `Blood Pressure Summary: ${dayjs(readingMetadata?.firstReadingDate).format('MMMM DD, YYYY')} - ${dayjs(
          readingMetadata?.lastReadingDate,
        ).format('MMMM DD, YYYY')}`,
        45,
        22,
      )
      //bp bar graph
      image1 && doc.addImage(image1, 'JPEG', 10, 100, 190, 90)
      doc.setFontSize(12)
      doc.text(`The top and bottom of each bar represents the systolic and diastolic value respectively.`, 32, 113)
      doc.setDrawColor(theme.colors.lightGray)
      doc.setFillColor('#9B2743')
      //pie chart key
      doc.roundedRect(12, 70, 3, 3, 0.5, 0.5, 'F')
      doc.setFontSize(8)
      doc.text(
        `High Readings(>180/110): ${pieChartData.find((x) => x.type === 'High')?.y ?? 0} (${
          pieChartData.find((x) => x.type === 'High')?.percentage ?? 0
        }%)`,
        17,
        73,
      )
      doc.setFillColor('#EAAA00')
      doc.roundedRect(12, 76, 3, 3, 0.5, 0.5, 'F')
      doc.text(
        `Above Readings(140-180/90-110): ${pieChartData.find((x) => x.type === 'Above Range')?.y ?? 0} (${
          pieChartData.find((x) => x.type === 'Above Range')?.percentage ?? 0
        }%)`,
        17,
        79,
      )
      doc.setFillColor('#7A9A01')
      doc.roundedRect(12, 82, 3, 3, 0.5, 0.5, 'F')
      doc.text(
        `In Range Readings(90-139/50-89): ${pieChartData.find((x) => x.type === 'In Range')?.y ?? 0} (${
          pieChartData.find((x) => x.type === 'In Range')?.percentage ?? 0
        }%)`,
        17,
        85,
      )
      doc.setFillColor('#470a68')
      doc.roundedRect(12, 88, 3, 3, 0.5, 0.5, 'F')
      doc.text(
        `Low Readings(<90/50): ${pieChartData.find((x) => x.type === 'Low')?.y ?? 0} (${
          pieChartData.find((x) => x.type === 'Low')?.percentage ?? 0
        }%)`,
        17,
        91,
      )
      //heart rate chart
      image3 && doc.addImage(image3, 'JPEG', 10, 198, 190, 70)
      //footer
      doc.setFontSize(12)
      doc.text('Have questions about the information above or would like more detail?', 45, pageHeight - 20)
      doc.text(' Contact a Tria Pharmacist today at 1-888-799-8742 or email us at info@triahealth.com.', 29, pageHeight - 15)
      doc.save(`Blood Pressure Summary_${dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]')}.pdf`)
      dispatch(setLoading(false))
    }
  }

  const createPDF = () => {
    dispatch(setLoading(true))
    getImage()
  }

  return (
    <div className="dashboard-page-container">
      {isMobile && <div style={{ minHeight: 20 }} />}

      <div
        className="dashboard-filter-row"
        style={{
          alignItems: 'center',
          width: isMobile ? '100%' : 'auto',
          height: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Dropdown className="date-filter-select-container">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="date-filter-select">
            {readingFilter.label}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {filterOptions.map((option) => (
              <Dropdown.Item onClick={() => setReadingFilter(option)}>{option.label}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          text="Export Summary"
          onClick={createPDF}
          styles={isMobile ? { marginTop: 10, marginBottom: 10, width: '100%' } : { marginLeft: 10, marginRight: showCustomFields ? 0 : 'auto' }}
        ></Button>
        {showCustomFields && (
          <>
            <div style={{ marginLeft: isMobile ? 0 : 5, minWidth: isMobile ? '100%' : 'none' }}>
              <ReactDatePicker
                selected={startDate}
                className="start-date-picker"
                onChange={(date: Date) => setStartDate(date)}
                showYearDropdown
                scrollableYearDropdown
                placeholderText="Start Date"
                maxDate={new Date()}
                isClearable={false}
              />
            </div>
            <div style={{ minWidth: 10 }} />
            <div style={{ marginRight: isMobile ? 0 : 'auto', minWidth: isMobile ? '100%' : 'none' }}>
              <ReactDatePicker
                selected={endDate}
                className="start-date-picker"
                onChange={(date: Date) => setEndDate(date)}
                showYearDropdown
                scrollableYearDropdown
                placeholderText="End Date"
                maxDate={new Date()}
              />
            </div>
            <div style={{ minWidth: 10 }} />
          </>
        )}
        {profile?.hasDashboardOverridePermission && !isMobile && (
          <Row styles={{ alignItems: 'center', marginLeft: 10 }}>
            <Typography>Admin Welltrak: </Typography>
            <Form.Control
              onChange={(e) => setAdminWelltrak(e.target.value)}
              value={adminWelltrak}
              style={{
                width: 200,
                marginRight: 5,
                marginLeft: 10,
                marginBottom: 0,
              }}
              placeholder=""
            />
            <div className="dashboard-reset-button" style={{ height: 38 }} onClick={() => getReadings(readingFilter)}>
              <Icon name="arrow-right" color="white" />
            </div>
          </Row>
        )}
      </div>
      <div className="charts-container">
        <div className="pie-chart-card">
          {readingMetadata ? (
            <>
              <div ref={pieChartRef}>
                <VictoryPie
                  data={pieChartData}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onMouseOver: () => {
                          return [
                            {
                              target: 'data',
                              mutation: () => {
                                return {
                                  style: { fill: '#00677F', width: 30 },
                                }
                              },
                            },
                            {
                              target: 'labels',
                              mutation: () => ({ active: true, labelRadius: 50 }),
                            },
                          ]
                        },
                        onMouseOut: () => {
                          return [
                            {
                              target: 'data',
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              mutation: () => {},
                            },
                            {
                              target: 'labels',
                              mutation: () => ({ active: false }),
                            },
                          ]
                        },
                      },
                    },
                  ]}
                  labelRadius={100}
                  innerRadius={70}
                  style={{
                    parent: { height: '100%', touchAction: 'manipulation' },
                    labels: { fontSize: 20, fill: 'white' },
                    data: {
                      fill: ({ datum }) => {
                        return datum.color
                      },
                    },
                  }}
                  labelComponent={<CustomLabel />}
                  /* colorScale={[
                  'rgb(155,39,67)',
                  'rgb(234,170,0)',
                  'rgba(122,154,1,1)',
                  'rgba(71,10,104,1)',
                ]} */
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  overflow: 'hidden',
                  paddingTop: '25px',
                  paddingLeft: '15px',
                  backgroundColor: 'white',
                }}
              >
                <Row styles={{ backgroundColor: 'white' }}>
                  <Typography variant="h4" color={theme.colors.black}>
                    {`Blood Pressure Ranges:`}
                  </Typography>
                </Row>
                <PieChartKeyRange title="High" range=">180/110" color="rgb(155,39,67)" rotation={0} />
                <PieChartKeyRange title="Above-Range" range="140-180/90-110" color="rgb(234,170,0)" rotation={45} />
                <PieChartKeyRange title="In-Range" range="90-139/50-89" color="rgba(122,154,1,1)" rotation={90} />
                <PieChartKeyRange title="Low" range="<90/50" color="rgba(71,10,104,1)" rotation={135} />
              </div>
            </>
          ) : (
            <div
              style={{
                minHeight: 300,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Row center>
                <Typography font="semi-bold">No Data</Typography>
              </Row>
            </div>
          )}
        </div>
        <div className="line-chart-card" ref={readingsChartRef}>
          {bloodPressureChartReadings.length <= 0 && (
            <Typography font="semi-bold" styles={{ position: 'absolute', top: '45%' }}>
              No Data
            </Typography>
          )}
          <div
            style={{
              height: 40,
              width: '100%',
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              backgroundColor: '#00677F',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Typography color={theme.colors.white} font="semi-bold">
              Blood Pressure
            </Typography>
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <Victory.VictoryChart
              padding={{ top: 80, bottom: 40, right: 30, left: 60 }}
              width={700}
              height={chartHeight}
              style={{ parent: { touchAction: 'manipulation' } }}
              maxDomain={{ y: 210 }}
              minDomain={{ y: 20 }}
            >
              <Victory.VictoryAxis
                label="mmHg"
                dependentAxis
                tickValues={[50, 75, 100, 125, 150, 175, 200]}
                crossAxis={false}
                style={{
                  grid: {
                    stroke: ({ tick }) => (tick === -10 ? 'transparent' : '#ECECEC'),
                    strokeWidth: 1,
                  },
                  axis: { stroke: '#ECECEC', strokeWidth: 0 },
                  axisLabel: { fontSize: 20, padding: 40, fill: '#000', fontFamily: 'Raleway', fontWeight: 500 },
                  ticks: { strokeWidth: 0 },
                  tickLabels: {
                    fill: '#000',
                    fontWeight: 500,
                    fontSize: 16,
                  },
                }}
              />
              <Victory.VictoryBar
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: (data) => {
                              return {
                                style: { fill: '#9EA9AE', width: 6 },
                              }
                            },
                          },
                          {
                            target: 'labels',
                            mutation: () => ({ active: true }),
                          },
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            mutation: () => {},
                          },
                          {
                            target: 'labels',
                            mutation: () => ({ active: false }),
                          },
                        ]
                      },
                    },
                  },
                ]}
                labelComponent={
                  <VictoryTooltip
                    flyoutStyle={{
                      stroke: 'black',
                      fill: 'white',
                    }}
                    flyoutPadding={10}
                    pointerLength={0}
                  />
                }
                data={bloodPressureChartReadings}
                style={{
                  data: {
                    fill: ({ datum }) => getColorFromLevel(datum.level) ?? '#00677F',
                    stroke: ({ datum }) => getColorFromLevel(datum.level) ?? '#00677F',
                    strokeWidth: 2,
                    width: 4,
                  },
                }}
                cornerRadius={{
                  topLeft: 2,
                  topRight: 2,
                  bottomLeft: 1,
                  bottomRight: 1,
                }}
              />
              <Victory.VictoryLine
                data={[
                  { value: 90, date: minDate },
                  {
                    value: 90,
                    date: maxDate,
                  },
                ]}
                x="date"
                y="value"
                style={{
                  data: { stroke: 'rgb(122, 154, 1)', strokeWidth: 1.5 },
                }}
                standalone={false}
                interpolation="monotoneX"
              />
              <Victory.VictoryLine
                data={[
                  { value: 140, date: minDate },
                  {
                    value: 140,
                    date: maxDate,
                  },
                ]}
                x="date"
                y="value"
                style={{
                  data: { stroke: 'rgb(122, 154, 1)', strokeWidth: 1.5 },
                }}
                standalone={false}
                interpolation="monotoneX"
              />
              <Victory.VictoryAxis
                tickCount={5}
                width={20}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fill: '#000',
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
              />
            </Victory.VictoryChart>
          </div>
        </div>
      </div>
      <Row styles={{ justifyContent: 'flex-end', marginBottom: 25 }}>
        <div className="line-chart-card" style={{ width: '100%' }} ref={heartRateReadingsChartRef}>
          {heartRateReadings && heartRateReadings.length <= 0 && (
            <Typography font="semi-bold" styles={{ position: 'absolute', top: '45%' }}>
              No Data
            </Typography>
          )}
          <div
            style={{
              height: 40,
              width: '100%',
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              backgroundColor: '#00677F',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Typography color={theme.colors.white} font="semi-bold">
              Heart Rate
            </Typography>
          </div>
          <div style={{ backgroundColor: 'white', width: '100%' }}>
            <Victory.VictoryChart
              padding={{
                top: isMobile ? 100 : 50,
                bottom: 40,
                right: 30,
                left: 63,
              }}
              width={700}
              height={isMobile ? 600 : 250}
              maxDomain={{ y: 200 }}
              minDomain={{ y: 0 }}
              containerComponent={
                <Victory.VictoryVoronoiContainer
                  labels={(d) => {
                    return `${d.datum._voronoiY} bpm\n${d.datum.date}\n${d.datum.time}`
                  }}
                />
              }
              style={{ parent: { touchAction: 'manipulation' } }}
            >
              <Victory.VictoryAxis
                dependentAxis
                tickCount={6}
                label="bpm"
                crossAxis={false}
                style={{
                  grid: {
                    stroke: ({ tick }) => (tick === -10 ? 'transparent' : '#ECECEC'),
                    strokeWidth: 1,
                  },
                  axis: { stroke: '#ECECEC', strokeWidth: 0 },
                  ticks: { strokeWidth: 0 },
                  axisLabel: { fontSize: 20, padding: 40, fill: '#000', fontFamily: 'Raleway', fontWeight: 500 },
                  tickLabels: {
                    fill: '#000',
                    fontWeight: 500,
                    fontSize: 16,
                  },
                }}
              />
              <Victory.VictoryLine
                data={heartRateReadings}
                interpolation="monotoneX"
                style={{
                  data: {
                    stroke: '#00677F',
                    strokeWidth: 3,
                  },
                  labels: {},
                }}
              />

              <Victory.VictoryAxis
                tickCount={5}
                width={20}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fill: '#000',
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
              />
              <Victory.VictoryLine
                data={[
                  { value: 60, date: minDate },
                  {
                    value: 60,
                    date: maxDate,
                  },
                ]}
                x="date"
                y="value"
                style={{
                  data: { stroke: 'rgb(122, 154, 1)', strokeWidth: 1.5 },
                }}
                standalone={false}
                interpolation="monotoneX"
              />
              <Victory.VictoryLine
                data={[
                  { value: 100, date: minDate },
                  {
                    value: 100,
                    date: maxDate,
                  },
                ]}
                x="date"
                y="value"
                style={{
                  data: { stroke: 'rgb(122, 154, 1)', strokeWidth: 1.5 },
                }}
                standalone={false}
                interpolation="monotoneX"
              />
              <Victory.VictoryAxis
                tickCount={5}
                width={20}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fill: '#000',
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
              />
            </Victory.VictoryChart>
          </div>
        </div>
      </Row>

      {readingMetadata && (
        <>
          <div
            style={{
              display: 'grid',
              columnGap: '2rem',
              gridTemplateColumns: 'auto auto auto auto',
            }}
          ></div>
          <div
            className="grid-template"
            style={{
              display: 'grid',
              marginBottom: 25,
              backgroundColor: 'white',
            }}
            ref={gridCardsRef}
          >
            <GridCard
              title={readingMetadata.averageBPM ? `${Math.round(readingMetadata.averageBPM).toString()} bpm` : '0'}
              subtitle={'Average Heart Rate'}
            />
            <GridCard title={readingMetadata.averageReading ? readingMetadata.averageReading.toString() : 'Error'} subtitle={'Average Reading'} />
            <GridCard
              title={readingMetadata.highReadingCount ? readingMetadata.highReadingCount.toString() : '0'}
              subtitle={'# of High Readings'}
              linkAction={() => /* cardLinkAction('Above', 70) */ {
                executeTabeScroll()
                setRange('All')
                setShow('All')
                setType('Highs')
              }}
            />
            <GridCard
              title={readingMetadata.lowReadingCount ? readingMetadata.lowReadingCount.toString() : '0'}
              subtitle={'# of Low Readings'}
              linkAction={() => /* cardLinkAction('Below', 70) */ {
                executeTabeScroll()
                setRange('All')
                setShow('All')
                setType('Lows')
              }}
            />
            <GridCard
              title={readingMetadata.totalReadingCount ? readingMetadata.totalReadingCount.toString() : '0'}
              subtitle={'Total # of Readings'}
            />

            <GridCard
              title={readingMetadata.firstReadingDate ? readingMetadata.firstReadingDate.format('M/DD/YYYY') : 'Error'}
              title2={readingMetadata.firstReadingDate ? readingMetadata.firstReadingDate.format('h:mm:ss') : ''}
              subtitle={'First Reading'}
            />
            {readingMetadata.lastReadingDate && (
              <GridCard
                title={readingMetadata.lastReadingDate.format('M/DD/YYYY')}
                title2={readingMetadata.lastReadingDate.format('h:mm:ss')}
                subtitle={'Last Reading'}
              />
            )}
          </div>
        </>
      )}
      {readings && readings.length > 0 && (
        <div style={isMobile ? { minHeight: 500, marginBottom: 50 } : { marginBottom: 50 }} className="dashboard-grid-container">
          {tableRows && (
            <div style={{ width: '100%' }}>
              <DataGrid
                ref={tableRef}
                rows={tableRows}
                columns={[...columns, { field: 'readingTime', sortable: false }]}
                autoHeight={tableRows.length < 15}
                style={isMobile ? { height: 10 } : { height: tableRows.length < 15 ? 'auto' : 800 }}
                disableColumnMenu
                sortingOrder={['asc', 'desc']}
                onCellClick={(cell) => {
                  if (cell.field === 'edit') {
                    setEditModalSettings({
                      ...editModalSettings,
                      isOpen: true,
                      reading: readings.find((x) => x.patientReadingPId.toString() === cell.row.readingId),
                    })
                  }
                }}
                components={{
                  Pagination: CustomPagination,
                  Toolbar: customToolbar,
                }}
              />
              {isMobile ? <MobileDataGrid tableRows={tableRows} editClick={(id) => editClick(id)} dashboard={'bloodPressure'} /> : <></>}
            </div>
          )}
        </div>
      )}
      <div className="dashboard-resources-section">
        <TextSettingsCard
          maximumReading={maximumReading}
          highSystolic={highSystolicLimit}
          highDiastolic={highDiastolicLimit}
          type={DashboardType.BloodPressure}
        />
        <ContentCards type="Blood Pressure" onToggleModal={(isOpen) => setModalOpen(isOpen)} />
      </div>
      {modalOpen && <BPWhatsNewModal modalOpen={modalOpen} toggleModal={(isOpen) => setModalOpen(isOpen)} />}
      {editModalSettings.isOpen && editModalSettings.reading && (
        <BloodPressureEditModal
          isMobile={isMobile}
          modalOpen={editModalSettings.isOpen}
          reading={editModalSettings.reading}
          onReadingUpdated={() => {
            dispatch(
              loadBloodPressureSummary({
                token: accessToken,
                overrideWelltrak: adminWelltrak,
              }),
            ).then(() => {
              setStartTime(undefined)
              setEndTime(undefined)
              setRangeNumber(undefined)
              setRange('All')
              setType('All Types')
              setUpdateReading(!updateReading)
            })
          }}
          toggleModal={(isOpen) =>
            setEditModalSettings({
              ...editModalSettings,
              isOpen: isOpen,
              reading: undefined,
            })
          }
        />
      )}
      <CustomModal
        modalOpen={deviceStatusModalOpen}
        toggleModal={(bool) => setDeviceStatusModalOpen(bool)}
        header="Important Device Information"
        body={deviceStatusModalBody}
        isMobile={isMobile}
      />
    </div>
  )
}
