import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { Formik, FormikProps, useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useMessagingCenter from 'hooks/useMessagingCenter'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { Category, HelpText, MessageType, NewMessageValues } from 'types/MessagingCenter'

interface Props {
  isMobile: boolean
  messageCategories: Category[]
  currentType: MessageType | undefined
  currentTypes: MessageType[]
  setCurrentTypes: (types: MessageType[]) => void
  currentCategory: Category | undefined
  setCurrentCategory: (cat: Category | undefined) => void
  currentHelpText: HelpText[]
  setCurrentHelpText: (helpText: HelpText[]) => void
  typeValue: number
  setTypeValue: (type: number) => void
  categoryValue: number | undefined
  defaultCategory: number | undefined
  defaultType: number | undefined
  setCategoryValue: (type: number) => void
  setCurrentType: (type: MessageType) => void
  setNewMessageBool: (newMessageBool: boolean) => void
  refreshMessagesUponSend: (number: number) => void
  newMessageBool: boolean
  setAfterSend: (afterSend: boolean) => void
  engagedBool: boolean
  enrollmentStatus: string | undefined
  postErrorBool: boolean
  setPostErrorBool: (bool: boolean) => void
  replyToTriaMessageId: number | undefined
}

type InputField = {
  type: string
  fieldName: string
  width: string
}
export default function NewMessage({
  messageCategories,
  isMobile,
  currentType,
  currentTypes,
  setCurrentTypes,
  currentCategory,
  defaultCategory,
  defaultType,
  setCurrentCategory,
  currentHelpText,
  setCurrentHelpText,
  setTypeValue,
  typeValue,
  categoryValue,
  setCategoryValue,
  setCurrentType,
  setNewMessageBool,
  refreshMessagesUponSend,
  newMessageBool,
  setAfterSend,
  engagedBool,
  enrollmentStatus,
  setPostErrorBool,
  postErrorBool,
  replyToTriaMessageId,
}: Props) {
  const [attachmentDropOpen, setAttachmentDropOpen] = useState(true)
  const [errorBool, setErrorBool] = useState<boolean>(false)
  const [comments, setComments] = useState<string>('')
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const { addPatientMessage } = useMessagingCenter(accessToken!)

  const formikRef = useRef<FormikProps<NewMessageValues>>(null)

  useEffect(() => {
    formik.setFieldValue('messageBody', '')
  }, [newMessageBool])
  const onDrop = useCallback((files: File[]) => {
    const tempFiles = formikRef.current?.values.documents
    files.map((file) => {
      tempFiles?.push(file)
    })
    formikRef.current?.setFieldValue('documents', tempFiles)
    formik.setFieldValue('documents', tempFiles)
  }, [])

  const removeFile = (file: File) => {
    const files = formikRef.current?.values.documents.filter((x) => x.name !== file.name)
    formikRef.current?.setFieldValue('documents', files)
    formik.setFieldValue('documents', files)
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const initialValues = {
    patientInitiatedMessageTypePId: currentType?.patientInitiatedMessageTypePId ?? 1,
    messageBody: '',
    enrollmentStatus: enrollmentStatus ?? 'NS',
    documents: [],
    triaInitiatedMessagePId: replyToTriaMessageId,
  }

  const onSubmit = () => {
    //
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const inputFields: InputField[] = [{ type: 'string', fieldName: 'messageBody', width: '90%' }]

  useEffect(() => {
    if (messageCategories.length > 0 && defaultCategory) {
      handleCategoryChange(defaultCategory)
      handleTypeChange(defaultType)
      formik.setFieldValue('messageBody', `I'd like to schedule a follow up tobacco cessation appointment.`)
      setCurrentHelpText([
        {
          sortOrder: 0,
          helpText: 'Click send to request a S.T.O.P appointment',
          hasBullet: false,
          url: undefined,
        },
      ])
    }
  }, [defaultCategory, defaultType, messageCategories])

  const handleCategoryChange = (index) => {
    if (index === '-1') {
      setCategoryValue(-1)
      setCurrentHelpText([])
    } else {
      setCategoryValue(index)
      setCurrentCategory(messageCategories[index])
      setCurrentTypes(messageCategories[index].messageTypes.filter((t) => t.visible))
      setCurrentHelpText(messageCategories[index].messageTypes[0].helpTextList)
      setTypeValue(0)
      setCurrentType(messageCategories[index].messageTypes[0])
      formik.setFieldValue('patientInitiatedMessageTypePId', messageCategories[index].messageTypes[0].patientInitiatedMessageTypePId)
    }
  }

  const handleTypeChange = (index) => {
    setCurrentType(currentTypes[index])
    setTypeValue(index)
    currentCategory && setCurrentHelpText(currentCategory.messageTypes[index].helpTextList)
    currentCategory && formik.setFieldValue('patientInitiatedMessageTypePId', currentCategory.messageTypes[index].patientInitiatedMessageTypePId)
  }

  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (inputRef.current?.value !== '') {
      setErrorBool(false)
    }
  }, [inputRef.current?.value])

  const handleMessageSend = () => {
    if (inputRef.current?.value === '') {
      setErrorBool(true)
    } else {
      setDisabledButton(true)
      addPatientMessage(formik.values).then((res) => {
        formikRef.current && formikRef.current?.setFieldValue('documents', [])
        if (res && res.data.isFailed) {
          setComments(res.data.reasons[0].message)
          setPostErrorBool(true)
          dispatch(setLoading(false))
        } else {
          setErrorBool(false)
          dispatch(setLoading(true))
          setNewMessageBool(false)
          if (engagedBool === false) {
            setAfterSend(true)
          }
          refreshMessagesUponSend(2)
        }
      })
      formik.values.messageBody = ''
    }
  }

  return (
    <StyledNewMessageCardContainer>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <div>
              <StyledNewMessageContainer>
                <Column>
                  <Row>
                    {messageCategories && (
                      <StyledDropdown
                        style={categoryValue !== -1 && currentCategory ? {} : { marginBottom: 80 }}
                        value={categoryValue}
                        onChange={(e) => handleCategoryChange(e.target.value)}
                        disabled={replyToTriaMessageId ? true : false}
                      >
                        <option value={-1}>Select a Category</option>
                        {messageCategories.map((cat, index) => {
                          return (
                            <option key={index} value={index}>
                              {cat.categoryName}
                            </option>
                          )
                        })}
                      </StyledDropdown>
                    )}
                    <div
                      style={{ marginLeft: 'auto', marginRight: 25, marginTop: 10, cursor: 'pointer' }}
                      onClick={() => setAttachmentDropOpen(!attachmentDropOpen)}
                    >
                      <Icon color={theme.colors.primary1} size="2xl" name="attachment" />
                    </div>
                  </Row>
                  {messageCategories && categoryValue !== -1 && currentCategory && (
                    <StyledDropdown
                      onChange={(e) => handleTypeChange(e.target.value)}
                      value={typeValue}
                      disabled={replyToTriaMessageId ? true : false}
                    >
                      {replyToTriaMessageId === null
                        ? currentTypes
                            .filter((t) => t.visible)
                            .map((type, index) => {
                              return (
                                <option key={index} value={index}>
                                  {type.messageType}
                                </option>
                              )
                            })
                        : currentTypes.map((type, index) => {
                            return (
                              <option key={index} value={index}>
                                {type.messageType}
                              </option>
                            )
                          })}
                    </StyledDropdown>
                  )}
                  {currentHelpText.length > 0 && currentHelpText[0].hasBullet ? (
                    <Column styles={{ marginLeft: 40 }}>
                      {currentHelpText.length > 0 && currentHelpText[0].url === null && <Typography>{currentHelpText[0].helpText}</Typography>}
                      {currentHelpText.length > 0 && currentHelpText[0].url && (
                        <a
                          href={currentHelpText[0].url.startsWith('http') ? currentHelpText[0].url : `https://${currentHelpText[0].url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Row>
                            <Typography>{currentHelpText[0].helpText}</Typography>
                            <Icon name="external-link" color="#828282" styles={{ cursor: 'pointer', marginLeft: 10 }} />
                          </Row>
                        </a>
                      )}

                      <Column styles={{ marginLeft: 10 }}>
                        {currentHelpText.length > 0 &&
                          currentHelpText[0].hasBullet &&
                          currentHelpText
                            ?.filter((helpText) => !helpText.hasBullet)
                            .map((helpText, index) => {
                              return helpText.url ? (
                                <a href={helpText.url.startsWith('http') ? helpText.url : `https://${helpText.url}`} target="_blank" rel="noreferrer">
                                  <Row>
                                    <li key={index}>{helpText.helpText}</li>
                                    <Icon name="external-link" color="#828282" styles={{ cursor: 'pointer', marginLeft: 10 }} />
                                  </Row>
                                </a>
                              ) : (
                                <li key={index}>{helpText.helpText}</li>
                              )
                            })}
                      </Column>
                    </Column>
                  ) : (
                    <Column styles={{ marginLeft: 25, marginBottom: 20, marginTop: 20 }}>
                      {currentHelpText.length > 0 &&
                        currentHelpText.map((text, index) => {
                          return text.url ? (
                            <a href={text.url && text.url.startsWith('http') ? text.url : `https://${text.url}`} target="_blank" rel="noreferrer">
                              <Row>
                                <Typography key={index}>{text.helpText}</Typography>
                                <Icon name="external-link" color="#828282" styles={{ cursor: 'pointer', marginLeft: 10 }} />
                              </Row>
                            </a>
                          ) : (
                            <Typography key={index}>{text.helpText}</Typography>
                          )
                        })}
                    </Column>
                  )}
                </Column>
                {errorBool && (
                  <Typography color="red" styles={{ marginLeft: 40, marginTop: 5 }}>
                    *Message Required
                  </Typography>
                )}
                {postErrorBool && (
                  <Typography color="red" styles={{ marginLeft: 40, marginTop: 10 }}>
                    {comments ? comments : 'There was an error. Please try again'}
                  </Typography>
                )}
                {inputFields.map((field, index) => {
                  return (
                    <Column key={index} styles={{ marginRight: 10, marginTop: errorBool ? 0 : 15 }}>
                      <Form onSubmit={() => handleMessageSend()}>
                        <StyledFormInput
                          ref={inputRef}
                          style={{ width: field.width, textAlign: 'left' }}
                          onChange={(value) => formik.setFieldValue(field.fieldName, value.currentTarget.value)}
                          value={formik.values[field.fieldName]}
                          placeholder={'Please type your message here:'}
                        />
                      </Form>
                    </Column>
                  )
                })}
              </StyledNewMessageContainer>
              <Row>
                {attachmentDropOpen && (
                  <div
                    style={{
                      marginRight: 'auto',
                      marginLeft: isMobile ? 0 : '-30%',
                    }}
                  >
                    <Column>
                      <div
                        style={{
                          display: 'flex',
                          marginRight: 'auto',
                          flexDirection: 'column',
                          border: '1px dashed black',
                          textAlign: 'center',
                          alignItems: 'center',
                          padding: '2% 10%',
                          cursor: 'pointer',
                        }}
                        {...getRootProps()}
                      >
                        <Icon name="cloud-upload" size={'3x'} color="#00677F" />
                        <input {...getInputProps()} />
                        <Typography styles={{ marginBottom: 5 }} font="semi-bold">
                          {` ${
                            isMobile
                              ? `Tap to select files`
                              : `Select
                            your file(s) or drag and drop here`
                          }`}
                        </Typography>
                        <Typography>JPEG, PNG, PDF or ZIP</Typography>
                      </div>
                      {values.documents.map((file, index) => (
                        <Row styles={{ marginTop: 10, alignItems: 'center' }} key={index}>
                          <Typography styles={{ marginRight: 5 }}>{file.name}</Typography>
                          <div onClick={() => removeFile(file)} style={{ cursor: 'pointer' }}>
                            <Icon name="times" />
                          </div>
                        </Row>
                      ))}
                    </Column>
                  </div>
                )}
                <Button
                  text="Send"
                  backgroundColor={theme.colors.primary1}
                  disabled={disabledButton}
                  rightIcon="send"
                  styles={{ marginTop: 30, marginLeft: 'auto', height: 40 }}
                  onClick={() => handleMessageSend()}
                />
              </Row>
            </div>
          )
        }}
      </Formik>
    </StyledNewMessageCardContainer>
  )
}

const StyledNewMessageCardContainer = styled.div`
  @media only screen and (min-width: 1000px) {
    flex-direction: column;
    margin-left: 51%;
    margin-top: -520px;
  }
`

const StyledNewMessageContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  padding-top: 5px;
  margin-top: -10%;
  overflow-y: scroll;
  @media only screen and (min-width: 1000px) {
    height: 520px;
    width: 130%;
    margin-left: -30%;
  }

  @media only screen and (max-width: 1000px) {
    height: 470px;
    width: 100%;
    margin-top: 5%;
  }
`
const StyledDropdown = styled.select`
  border-radius: 5px;
  text-align: left;
  box-shadow: inset 0px 1px 3px gray;
  min-height: 50px;
  margin-bottom: 10px;
  margin-left: 5px;
  width: 70%;
`

const StyledInput = styled.textarea`
  border-radius: 3px;
  display: block;
  outline: none !important;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`

const StyledFormInput = styled(StyledInput)`
  background-color: #b8dde1;
  box-shadow: inset 0px 1px 3px;
  min-height: 190px;
  width: 800px;
`
