import qs from 'querystring'
import { AxiosResponse } from 'axios'
import { ProfileFormValues } from 'types/Profile'
import { HabitEditRequest, HabitOptions } from '../types/HabitOptions'
import { useApi } from './useAPI'

export default function useHabitOptions(token: string) {
  const TriaApi = useApi(token)

  const getHabitOptions = async () => {
    const habitOptions: Promise<AxiosResponse<HabitOptions>> = TriaApi.get(`api/profiles/habits`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return habitOptions
  }

  const getUserProfile = async () => {
    const habitOptions: Promise<AxiosResponse<ProfileFormValues>> = TriaApi.get(`api/profiles`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return habitOptions
  }

  const updateProfile = async (request: HabitEditRequest) => {
    const { welltrakPid, activityLevel, alcoholUse, caffeineUse, tobaccoUse, heightFt, heightIn, weight } = request

    const data = qs.stringify({
      WellTrakPId: welltrakPid,
      ActivityLevel: activityLevel,
      AlcoholUsage: alcoholUse,
      CaffeineUsage: caffeineUse,
      TobaccoUsage: tobaccoUse,
      HeightFt: heightFt,
      HeightIn: heightIn,
      Weight: weight,
    })

    TriaApi.post(`api/profiles/`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }

  return { getHabitOptions, getUserProfile, updateProfile }
}
