import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import qs from 'querystring'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'

export interface AuthState {
  accessToken: string | undefined
  refreshToken: string | undefined
  loginExpiration: string | undefined
  tokenExpired: boolean
  status: 'idle' | 'loading' | 'failed'
}

const initialState: AuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  loginExpiration: undefined,
  tokenExpired: false,
  status: 'idle',
}

export type UserInfoRequest = {
  username: string
  password: string
  rememberMe: boolean
}

export const Login = createAsyncThunk('auth/login', async (user: UserInfoRequest) => {
  const username = process.env.REACT_APP_ENV_NAME === 'PRODUCTION' ? user.username : process.env.REACT_APP_DEV_USER
  const password = process.env.REACT_APP_ENV_NAME === 'PRODUCTION' ? user.password : process.env.REACT_APP_DEV_PASSWORD

  const data = qs.stringify({
    grant_type: 'password',
    username: username,
    password: password,
    client_id: 'tria-portal',
    scope: 'offline_access api openid',
    audience: 'portal-api',
  })

  const response = useApi(undefined, 'https://auth.triahealth.com').post('/connect/token', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return response
})

export const RefreshLogin = createAsyncThunk('auth/login', async (refreshToken: string) => {
  const data = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: '',
  })
  const response = useApi().post('token', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return response
})

export const validateToken = createAsyncThunk('auth/token/validate', async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(token).get('api/authorized', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const authLogout = createAsyncThunk('auth/logout', () => {
  return true
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reduxLogout: (state) => {
      state.accessToken = ''
    },
    restoreToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Login.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(validateToken.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(Login.fulfilled, (state, action: any) => {
        const token = action.payload.data.access_token
        const refreshToken = action.payload.data.refresh_token

        state.accessToken = token
        state.refreshToken = refreshToken
        state.tokenExpired = false
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.status = 'idle'
        if (action.payload.data === false) {
          state.accessToken = undefined
          state.tokenExpired = true
        }
      })
      .addCase(Login.rejected, (state) => {
        state.status = 'idle'
        state.accessToken = undefined
      })
      .addCase(validateToken.rejected, (state) => {
        state.status = 'idle'
        state.accessToken = undefined
      })
  },
})

export const { reduxLogout, restoreToken } = authSlice.actions

export const getAccessToken = (state: RootState) => state.root.auth.accessToken
export const getTokenExpired = (state: RootState) => state.root.auth.tokenExpired

export default authSlice.reducer
