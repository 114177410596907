import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Benefits of Quitting.png'

export default function HealthBenefits() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Introduction to Activity 1: Health Benefits of Quitting Tobacco</STOPHeader>
          <div style={{ marginTop: 25 }}>
            <ScaledText size={30}>
              During this activity you will learn the major health benefits to quitting tobacco and the different types of tobacco, including inhaled
              and smokeless tobacco. Time will also be spent going through how quickly different parts of the body improve after quitting tobacco.
              Lastly, health conditions that can be caused or worsened by tobacco will be reviewed.
            </ScaledText>
          </div>
        </>
      }
      image={BenefitsImage}
      altTag="Man at a computer"
    />
  )
}
