import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Errors } from '../types/Errors'

export interface ErrorState {
  errors: Errors
  status: 'idle' | 'loading' | 'failed'
}

const initialState: ErrorState = {
  errors: { login: undefined },
  status: 'idle',
}

export const errorSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setLoginError(state, action: PayloadAction<string>) {
      state.errors.login = action.payload
    },
  },
})

export const getErrors = (state: RootState) => state.error.errors
export const getLoginErrors = (state: RootState) => state.error.errors.login

export const { setLoginError } = errorSlice.actions

export default errorSlice.reducer
