import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import Column from '../Base/Column'
import Row from '../Base/Row'
import Typography from '../Base/Typography'

interface Props {
  modalOpen: boolean
  toggleModal: (isOpen: boolean) => void
}
export default function LoginModal({ modalOpen, toggleModal }: Props) {
  const customStyles = {
    content: {
      width: '25%',
      height: '200px',
      overflow: 'none',
      top: '50%',
      left: '50%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <StyledModalContainer>
      <StyledModalHeader>
        <Typography color="neutral1" font="bold" size={20}>
          Session Expiration
        </Typography>
      </StyledModalHeader>
      <Modal isOpen={modalOpen} onRequestClose={() => toggleModal(false)} style={customStyles}>
        <Column styles={{ marginBottom: 15 }}>
          <Typography>Your current session is about to expire. Please verify you would like to stay signed into the Tria Patient Portal.</Typography>
        </Column>
      </Modal>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  max-height: 40px;
  width: 25%;
  z-index: 1000;
  position: fixed;
  inset: 37% auto 40px 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

const StyledModalHeader = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary1};
  position: absolute;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 20px;
  top: 0;
  left: 0;
  justify-content: space-between;
`
