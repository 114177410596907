import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import usePatientIntake, { HistoryOption, IntakeHistoryState, IntakeHistoryType } from 'hooks/usePatientIntake'
import React, { useEffect } from 'react'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import { useTheme } from 'styled-components'
import BaseForm from '../BaseForm'
import './HistoryForm.scss'

interface Props {
  backPressed: () => void
  onSubmit: (values: IntakeHistoryState, nextStep: boolean) => void

  error?: string
  historyFormValues: IntakeHistoryState
}

export default function HistoryForm({ onSubmit, backPressed, error, historyFormValues }: Props) {
  const theme = useTheme()
  const medicalHistoryOptions: HistoryOption[] = [
    { selected: false, option: 'Blood clots' },
    { selected: false, option: 'Depression' },
    { selected: false, option: 'Irregular heartbeat' },
    { selected: false, option: 'Kidney failure/dialysis' },
    { selected: false, option: 'Stroke' },
    { selected: false, option: 'Cancer' },
    { selected: false, option: 'Heart attack' },
    { selected: false, option: 'Kidney or gall stones' },
    { selected: false, option: 'Liver failure' },
    { selected: false, option: 'Ulcer' },
  ]

  const surgicalHistoryOptions: HistoryOption[] = [
    { selected: false, option: 'Angioplasty/Bypass' },
    { selected: false, option: 'Gallbladder removed' },
    { selected: false, option: 'Hysterectomy' },
    { selected: false, option: 'Organ transplant' },
    { selected: false, option: 'Spleen removed' },
    { selected: false, option: 'Appendix removed' },
    { selected: false, option: 'Hernia repair' },
    { selected: false, option: 'Joint replacement' },
    { selected: false, option: 'Pacemaker/Defibrilator' },
    { selected: false, option: 'Thyroid removed' },
  ]

  const dispatch = useAppDispatch()

  const accessToken = useAppSelector(getAccessToken)
  const { getHistoryInfo } = usePatientIntake({
    token: accessToken,
  })

  useEffect(() => {
    dispatch(setLoading(true))
    async function getHistoryState() {
      const response = await getHistoryInfo()
      const data = await response.data

      if (data.surgical.historyOptions.length > 0) {
        data.surgical.historyOptions.map((s: HistoryOption) => s.selected === true && toggleHistoryOption(s, IntakeHistoryType.Surgical))
      }

      if (data.medical.historyOptions.length > 0) {
        data.medical.historyOptions.map((s: HistoryOption) => s.selected === true && toggleHistoryOption(s, IntakeHistoryType.Medical))
      }

      formik.setFieldValue('MedicalHistory', {
        HistoryType: IntakeHistoryType.Medical,
        HistoryOptions: formik.values.MedicalHistory.HistoryOptions,
        Notes: data.medical.notes,
      })

      formik.setFieldValue('SurgicalHistory', {
        HistoryType: IntakeHistoryType.Surgical,
        HistoryOptions: formik.values.SurgicalHistory.HistoryOptions,
        Notes: data.surgical.notes,
      })
    }
    getHistoryState().then(() => dispatch(setLoading(false)))
  }, [])

  const initialValues: IntakeHistoryState = {
    MedicalHistory: {
      HistoryType: IntakeHistoryType.Medical,
      HistoryOptions: [],
      Notes: '',
    },
    SurgicalHistory: {
      HistoryType: IntakeHistoryType.Surgical,
      HistoryOptions: [],
      Notes: '',
    },
  }

  const submitIntakeHistoryForm = (values: IntakeHistoryState, nextStep = true) => {
    onSubmit(values, nextStep)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: () => console.log('this'),
  })

  const toggleHistoryOption = (option: HistoryOption, historyType: IntakeHistoryType) => {
    let optionsCopy: HistoryOption[] = []

    if (historyType === IntakeHistoryType.Medical) {
      optionsCopy = formik.values.MedicalHistory.HistoryOptions
    } else {
      optionsCopy = formik.values.SurgicalHistory.HistoryOptions
    }

    const index = optionsCopy.findIndex((x) => x.option === option.option)
    if (index !== -1) {
      optionsCopy.splice(index, 1)
    } else {
      option.selected = true
      optionsCopy.push(option)
    }

    if (historyType === IntakeHistoryType.Medical) {
      formik.setFieldValue('MedicalHistory', {
        HistoryType: IntakeHistoryType.Medical,
        HistoryOptions: optionsCopy,
        Notes: formik.values.MedicalHistory.Notes,
      })
    } else {
      formik.setFieldValue('SurgicalHistory', {
        HistoryType: IntakeHistoryType.Surgical,
        HistoryOptions: optionsCopy,
        Notes: formik.values.SurgicalHistory.Notes,
      })
    }
  }

  const setNote = (value, medical) => {
    if (medical) {
      formik.setFieldValue('MedicalHistory', {
        HistoryType: IntakeHistoryType.Medical,
        HistoryOptions: formik.values.MedicalHistory.HistoryOptions,
        Notes: value,
      })
    } else {
      formik.setFieldValue('SurgicalHistory', {
        HistoryType: IntakeHistoryType.Surgical,
        HistoryOptions: formik.values.SurgicalHistory.HistoryOptions,
        Notes: value,
      })
    }
  }

  const backPress = () => {
    submitIntakeHistoryForm(formik.values, false)
    backPressed()
  }

  return (
    <BaseForm
      title="Let's talk medical history"
      subtitle="Anything you can remember helps."
      onSubmit={() => submitIntakeHistoryForm(formik.values, true)}
      backPressed={() => backPress()}
      last={false}
      error={error}
      content={
        <>
          <div className="history-card">
            <Row center className="history-card-title">
              <Typography font="semi-bold">Do you have (or have you had) any of the following medical conditions?</Typography>
            </Row>

            <div className="history-card-container">
              <Column className="history-column">
                {medicalHistoryOptions.slice(0, 6).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Medical)}
                      checked={formik.values.MedicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>

              <Column className="history-column">
                {medicalHistoryOptions.slice(6, 11).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Medical)}
                      checked={formik.values.MedicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
            </div>
          </div>
          <Row center className="history-card-title">
            <Typography font="semi-bold">Please enter any other medical conditions:</Typography>
          </Row>
          <textarea className="other-notes" value={formik.values.MedicalHistory.Notes} onChange={(e) => setNote(e.target.value, true)} />

          <div className="history-card">
            <Row center className="history-card-title">
              <Typography font="semi-bold">Have you had any of the following surgeries?</Typography>
            </Row>

            <div className="history-card-container">
              <Column className="history-column">
                {surgicalHistoryOptions.slice(0, 6).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Surgical)}
                      checked={formik.values.SurgicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
              <Column className="history-column">
                {surgicalHistoryOptions.slice(6, 11).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Surgical)}
                      checked={formik.values.SurgicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
            </div>
          </div>
          <Row center className="history-card-title">
            <Typography font="semi-bold">Please enter any other surgeries:</Typography>
          </Row>
          <textarea className="other-notes" value={formik.values.SurgicalHistory.Notes} onChange={(e) => setNote(e.target.value, false)} />
        </>
      }
    />
  )
}
