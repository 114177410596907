/* eslint-disable react-hooks/exhaustive-deps */
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import LoadingModal from 'Components/LoadingModal'
import MultiFactorModal from 'Components/MultiFactorModal'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Params, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getMultifactor, loadMultifactor } from 'reducers/multifactorSlice'
import getGuid from 'utilities/isGuid'
import trimPeriod from 'utilities/trimPeriod'
import { useAppSelector } from '../../hooks/hooks'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import useProfile from '../../hooks/useProfile'
import { getAccessToken } from '../../reducers/authSlice'
import { getProfile, getProfileStatus } from '../../reducers/profileSlice'
import { getLoading, setLoading } from '../../reducers/tempSlice'
import './Homepage.scss'
import LoginForm from './LoginForm'
import WelcomeView from './WelcomeView'

export default function Homepage() {
  const { provider }: Readonly<Params<string>> = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useAppSelector(getLoading)
  const token = useAppSelector(getAccessToken)
  const profileStatus = useAppSelector(getProfileStatus)
  const profile = useAppSelector(getProfile)
  const forceMultiFactor = useAppSelector(getMultifactor)

  const { setDocumentTitle } = useDocumentTitle()
  const { getWelltrakFromGuid } = useProfile(token!)

  const [welltrak, setWelltrak] = useState()
  const [loggedIn, setLoggedIn] = useState(false)
  const [idMatch, setIdMatch] = useState<string>('')
  const [welltrakLoading, setWelltrakLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [checkingModal, setCheckingModal] = useState(false)

  setDocumentTitle('Login')

  const tempUrl = window.localStorage.getItem('tempUrl')

  useEffect(() => {
    if (profileStatus === 'loading' || welltrakLoading) {
      dispatch(setLoading(true))
    } else {
      if (token) {
        dispatch(loadMultifactor(token))
      }
      dispatch(setLoading(false))
    }
  }, [profileStatus, welltrakLoading])

  useEffect(() => {
    if (forceMultiFactor === false) {
      setModalOpen(true)
    }
  }, [forceMultiFactor])

  useEffect(() => {
    if (token && loading === false && profileStatus !== 'failed') {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [token, loading, profileStatus])

  useEffect(() => {
    getGuidFromParams()
  }, [])

  useEffect(() => {
    if (forceMultiFactor === undefined) {
      setCheckingModal(false)
    }
  }, [forceMultiFactor])

  useEffect(() => {
    const interval = setInterval(
      () => (checkingModal && forceMultiFactor !== undefined ? dispatch(loadMultifactor(token!)) : clearInterval(interval)),
      2000,
    )

    return () => {
      clearInterval(interval)
    }
  }, [checkingModal])

  const getGuidFromParams = () => {
    const id = trimPeriod(searchParams.get('id'))
    const ID = trimPeriod(searchParams.get('ID'))

    const idGuid = getGuid(id)
    const providerGuid = provider ? getGuid(provider) : false

    if (idGuid) {
      getWelltrak(idGuid)
      setIdMatch(idGuid)
    }
    if (id !== null) getWelltrak(id)
    if (ID !== null) getWelltrak(ID)

    if (providerGuid) {
      getWelltrak(providerGuid)
    }
  }

  const setMobileSetting = () => {
    if (window.innerWidth < 1100) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const getWelltrak = (guid: string) => {
    setWelltrakLoading(true)
    getWelltrakFromGuid(guid).then((res: any) => {
      setWelltrak(res.data)
      setWelltrakLoading(false)
    })
  }

  const getComponent = () => {
    if (loggedIn) {
      if (tempUrl) {
        const url: { value: string; expiry: number } = JSON.parse(tempUrl)
        navigate(url.value)
        window.localStorage.removeItem('tempUrl')
      } else {
        return (
          <>
            {forceMultiFactor === false && (
              <MultiFactorModal
                modalOpen={modalOpen}
                toggleModal={(bool) => setModalOpen(bool)}
                isMobile={isMobile}
                setCheckingModal={() => setCheckingModal(true)}
              />
            )}
            <Row
              styles={{
                height: '30%',
                backgroundColor: '#CCE7F0',
                width: '100%',
                paddingTop: 50,
                paddingLeft: 50,
                paddingBottom: 100,
                justifyContent: 'center',
              }}
            >
              <div style={{ width: '100%', maxWidth: 1600 }}>
                {profile?.firstName && (
                  <Column>
                    <h1 className="header-text">{`Welcome`}</h1>
                    <h1 className="appointment-subheader">{profile.firstName}!</h1>
                  </Column>
                )}
              </div>
            </Row>
            <LoadingModal
              toggleModal={() => setCheckingModal(!checkingModal)}
              isMobile={isMobile}
              modalOpen={checkingModal}
              header="Refreshing Two Factor Authentication"
            />
            <WelcomeView forceMultiFactor={forceMultiFactor} isMobile={isMobile} />
          </>
        )
      }
    } else {
      return (
        <div className="App1" style={{ height: '100vh' }}>
          <>
            <LoginForm provider={provider} idMatch={idMatch} welltrak={welltrak} />
          </>
        </div>
      )
    }
  }

  return <div>{getComponent()}</div>
}
