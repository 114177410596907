/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useAppointments from 'hooks/useAppointments'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { AppointmentDate, AppointmentRequest, AppointmentResponse, AppointmentTime } from 'types/Appointment'
import './CalendarForm.scss'

interface Props {
  saveCalendarForm: (request: AppointmentRequest) => void
  onBackPressed: () => void
  onSetNoAppointment: () => void
  isInitial: boolean
  errorMessage?: string
  loading?: boolean
  token?: string
  setScheduledAppointment
  scheduleId?: number
  scheduledAppointment?: string
  consultLengthBool: boolean
}
export default function CalendarForm({
  saveCalendarForm,
  onBackPressed,
  onSetNoAppointment,
  isInitial = false,
  errorMessage,
  loading,
  token,
  setScheduledAppointment,
  scheduleId,
  scheduledAppointment,
  consultLengthBool,
}: Props) {
  const authToken = useAppSelector(getAccessToken)
  const theme = useTheme()
  const analytics = useAnalytics(authToken!)

  const [dateSelected, setDateSelected] = useState<string | undefined>()
  const [value, onChange] = useState(new Date())
  const { getInitialAppointments, getAppointments } = useAppointments(authToken!)
  const [availableAppointments, setAvailableAppointments] = useState<AppointmentDate[]>([])
  const [availableFilteredTimes, setAvailableFilteredTimes] = useState<AppointmentTime[]>([])
  const [availableTimes, setAvailableTimes] = useState<AppointmentTime[]>([])
  const [earlierAppointment, setEarlierAppointment] = useState<boolean>(false)
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentTime>()
  const [filterEvent, setFilterEvent] = useState({ target: { value: '6, 21' } })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setAvailableTimes(availableAppointments[0]?.appointmentTimes ?? [])
  }, [dateSelected])

  useEffect(() => {
    dispatch(setLoading(true))
    if (isInitial) {
      getInitialAppointments()
        .then((res: AxiosResponse<AppointmentResponse>) => {
          const dates = res.data.appointmentDates.map((date) =>
            Object.assign({}, date, {
              date: dayjs(date.date).format(`MM/DD/YYYY`),
            }),
          )

          setAvailableAppointments(dates)
          dispatch(setLoading(false))
        })
        .catch(() => {
          dispatch(setLoading(false))
          navigate('/error')
        })
    } else {
      getAppointments()
        .then((res: AxiosResponse<AppointmentResponse>) => {
          const dates = res.data.appointmentDates.map((date) =>
            Object.assign({}, date, {
              date: dayjs(date.date).format('MM/DD/YYYY'),
            }),
          )

          setAvailableAppointments(dates)
          dispatch(setLoading(false))
        })
        .catch(() => {
          dispatch(setLoading(false))
          navigate('/error')
        })
    }
  }, [])

  const checkAvailabilty = (date: any) => {
    const selected = availableAppointments.find((x) => {
      const splitApp = x.appointmentTimes[0].startTime.split('T')
      return dayjs(splitApp[0]).format('MM/DD/YYYY') === dayjs(date).format('MM/DD/YYYY')
    })
    const selected2 = selected?.appointmentTimes.find(
      (x) =>
        parseInt(dayjs(x.startTime).format('HH')) >= parseInt(filterEvent.target.value.split(',')[0]) &&
        parseInt(dayjs(x.startTime).format('HH')) < parseInt(filterEvent.target.value.split(',')[1]),
    )
    return selected2
  }
  const submitAppointment = () => {
    if (selectedAppointment && !scheduledAppointment) {
      const { startTime, endTime, pharmacistGuid } = selectedAppointment
      const startTimeSplit = startTime.split('-')
      startTimeSplit.pop()
      const endTimeSplit = endTime.split('-')
      endTimeSplit.pop()
      const appointment: AppointmentRequest = {
        pharmacist: pharmacistGuid,
        startTime: startTimeSplit.join('-'),
        endTime: endTimeSplit.join('-'),
        immediateAppointment: earlierAppointment,
      }
      saveCalendarForm(appointment)
      setScheduledAppointment(`${dayjs(startTimeSplit.join('-')).format('MMMM D, YYYY h:mm A')} CST`)
    } else if (selectedAppointment && scheduledAppointment) {
      const { startTime, endTime, pharmacistGuid } = selectedAppointment
      const startTimeSplit = startTime.split('-')
      startTimeSplit.pop()
      const endTimeSplit = endTime.split('-')
      endTimeSplit.pop()
      const appointment: AppointmentRequest = {
        pharmacist: pharmacistGuid,
        startTime: startTimeSplit.join('-'),
        endTime: endTimeSplit.join('-'),
        scheduleId: scheduleId,
        immediateAppointment: earlierAppointment,
      }
      saveCalendarForm(appointment)
      setScheduledAppointment(`${dayjs(startTimeSplit.join('-')).format('MMMM D, YYYY h:mm A')} CST`)
    }
  }
  useEffect(() => {
    const filteredDates =
      availableAppointments.find((x) => {
        const splitApp = x.appointmentTimes[0].startTime.split('T')
        return dayjs(splitApp[0]).format('MM/DD/YYYY') === dateSelected
      })?.appointmentTimes ?? []
    setAvailableTimes(filteredDates)
    setAvailableFilteredTimes(
      filteredDates.filter(
        (x) =>
          parseInt(dayjs(x.startTime).format('HH')) >= parseInt(filterEvent.target.value.split(',')[0]) &&
          parseInt(dayjs(x.startTime).format('HH')) < parseInt(filterEvent.target.value.split(',')[1]),
      ),
    )
  }, [dateSelected])

  function handleTimeFilter(e) {
    setFilterEvent(e)
    setAvailableFilteredTimes(
      availableTimes.filter(
        (x) =>
          parseInt(dayjs(x.startTime).format('HH')) >= parseInt(e.target.value.split(',')[0]) &&
          parseInt(dayjs(x.startTime).format('HH')) < parseInt(e.target.value.split(',')[1]),
      ),
    )
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  const selectSetEarlierAppointment = () => {
    setEarlierAppointment(!earlierAppointment)
    analytics.postAnalyticsEvent({
      category: `click_checkbox_appointment_contact_for_earlier_appointment`,
      action: `click_checkbox_appointment_contact_for_earlier_appointment`,
      label: `Checkbox`,
      screenName: 'Appointment',
    })
  }

  return (
    <>
      <div className="calendar-form-container">
        <div className="calendar-content-container">
          <div className="mobile-title" style={{ textAlign: 'center' }}>
            When are you available?
          </div>
          <div className="calendar-menu">
            <div
              style={{
                minWidth: '100%',
                maxHeight: '100%',
              }}
            >
              <StyledDropdown onChange={handleTimeFilter}>
                <option value={['00', '99']}>All Day</option>
                <option value={'0, 10'}>Early Morning (Before 10am)</option>
                <option value={['10', '12']}>Late Morning (10am - 12pm)</option>
                <option value={['12', '14']}>Early Afternoon (12pm - 2pm)</option>
                <option value={['14', '17']}>Late Afternoon (2pm - 5pm)</option>
                <option value={['17', '20']}>Evening (After 5pm)</option>
              </StyledDropdown>
              <Calendar
                onChange={(date: any) => {
                  setDateSelected(dayjs(date).format('MM/DD/YYYY'))
                }}
                calendarType={'US'}
                tileContent={(date: any) => {
                  const tileDate = dayjs(date.date)
                  const diff = dayjs().diff(tileDate)
                  const hasTimes = checkAvailabilty(tileDate)
                  return (
                    <div
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {diff < 0 && <>{hasTimes ? <Icon name="check-circle" color="green" /> : <Icon name="circleX" color="red" />}</>}
                    </div>
                  )
                }}
                value={value}
                className={'appointment-calendar'}
                minDate={new Date()}
                maxDate={new Date(dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD'))}
              />

              <div className="times-container" style={{}}>
                <h2
                  style={{
                    backgroundColor: theme.colors.primary1,
                    marginBottom: 1,
                    textAlign: 'center',
                  }}
                >
                  {!dateSelected ? 'Please Select a Day to See Available Appointment Times' : `Appointment Times`}
                </h2>
                <h3
                  style={{
                    backgroundColor: theme.colors.primary1,
                    marginBottom: 1,
                    textAlign: 'center',
                  }}
                >
                  {!dateSelected ? '' : `${dayjs(dateSelected).format('dddd, MMMM D, YYYY')}`}
                </h3>
                <p
                  style={{
                    backgroundColor: theme.colors.primary1,
                    marginBottom: 15,
                    textAlign: 'center',
                  }}
                >
                  {!dateSelected ? '' : `(Time Zone: ${timeZone})`}
                </p>
                {availableFilteredTimes.length === 0 && dateSelected
                  ? 'No appointments are available on this date at this time. Please select a different date or time.'
                  : availableFilteredTimes.map((x) => (
                      <div
                        onClick={() => setSelectedAppointment(x)}
                        style={{
                          height: 75,
                          borderRadius: 15,
                          backgroundColor: 'white',
                          marginBottom: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingLeft: 20,
                          paddingRight: 20,
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          style={{
                            color: 'black',
                            fontFamily: 'Raleway-Bold',
                            fontSize: 22,
                          }}
                        >
                          {`${dayjs(x.startTime).format(`h:mm a`)} - ${dayjs(x.endTime).format(`h:mm a`)} `}
                        </div>
                        {selectedAppointment && x.startTime === selectedAppointment.startTime ? (
                          <Icon name="check-circle" color={theme.colors.primary1} />
                        ) : (
                          <Icon name="circle-light" color="black" />
                        )}
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div className="appointment-text-container">
            <div className="web-title" style={{ marginBottom: '12%', marginTop: '5%' }}>
              When are you available?
            </div>
            <div style={{ flex: 1 }}>
              <div className="subtitle" style={{ marginBottom: '12%', textAlign: 'center' }}>
                {`Initial consultations typically last ${consultLengthBool ? 'around 30' : 'between 20-50'} minutes. Follow-up consultations
                are usually shorter.`}
              </div>
              <div className="paragraph" style={{ textAlign: 'center' }}>
                At the time of your selected appointment, your Tria Health pharmacist will be calling you from 913.322.8456. They’ll review all your
                medications, lifestyle and preventative services. You’ll receive a customized care plan at the end of your appointment, which will be
                accessible within your patient portal.
              </div>
            </div>
            <StyledLinkRow>
              <Checkbox
                checked={earlierAppointment}
                styles={{
                  marginRight: 10,
                }}
                onClick={() => selectSetEarlierAppointment()}
              />
              <div className="earlier-text">
                {`I’d like to be contacted if an earlier appointment becomes
                available`}
              </div>
            </StyledLinkRow>
            <StyledLinkRow>
              <Row styles={{ color: theme.colors.primary1, cursor: 'pointer', marginBottom: 15 }} onClick={onSetNoAppointment}>
                <div className="earlier-text" style={{ marginRight: 10 }}>
                  {`Can't find an appointment time that works with your schedule?`}
                </div>
                <Icon name="external-link" />
              </Row>
            </StyledLinkRow>
            {errorMessage && (
              <Row
                styles={{
                  justifyContent: 'center',
                  marginTop: 10,
                  textAlign: 'center',
                }}
              >
                <StyledErrorText>{errorMessage}</StyledErrorText>
              </Row>
            )}
            <StyledButtonContainer>
              {token === undefined && (
                <>
                  <StyledButton
                    pageName="appointment_calendar"
                    text="Back"
                    onClick={onBackPressed}
                    styles={{ backgroundColor: theme.colors.darkGray }}
                  />
                  <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
                </>
              )}
              <StyledButton
                pageName="appointment_calendar"
                text="Next"
                loading={loading}
                onClick={submitAppointment}
                disabled={selectedAppointment ? false : true}
              />
            </StyledButtonContainer>
          </div>
        </div>
      </div>
    </>
  )
}

const StyledLinkRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
  @media ${sizes.tablet} {
    flex-direction: row;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary1};
  @media ${sizes.tablet} {
    width: 25%;
  }
`

const StyledDropdown = styled.select`
  border-radius: 5px;
  text-align: left;
  box-shadow: inset 0px 1px 3px gray;
  min-height: 50px;
  margin-bottom: 10px;
  width: 100%;
`
