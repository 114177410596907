import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledErrorText } from 'Styles/global'
import { Formik, FormikErrors, getIn } from 'formik'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import { IntakeMedication, IntakeMedicationFrequency } from 'types/Medication'
import * as Yup from 'yup'
import './MedicationCard.scss'

interface Props {
  cancelInput: () => void
  onAddMedication: (medication: IntakeMedication) => void
  medication: IntakeMedication
  medicationsExist: boolean
}

export default function MedicationCard({ cancelInput, onAddMedication, medication, medicationsExist }: Props) {
  const theme = useTheme()

  const [frequency, setFrequency] = useState<IntakeMedicationFrequency | undefined>(undefined)
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [formHide, setFormHide] = useState(false)

  function getStyles(fieldName: string, errors: FormikErrors<IntakeMedication>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  const initialValues: IntakeMedication = {
    gpi: medication.gpi,
    medicationId: medication.medicationId,
    frequency: 7,
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    reason: Yup.string(),
  })

  const onSubmit = (values: IntakeMedication) => {
    onAddMedication(values)
    setFormHide(true)
  }

  function onCancel() {
    cancelInput()
    setFormHide(true)
  }

  return (
    <Formik
      initialValues={
        medicationsExist
          ? {
              name: medication.name,
              strength: medication.strength,
              quantity: medication.quantity,
            }
          : initialValues
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values, setFieldValue, isValid, errors }) => {
        return (
          <div className="intake-medication-card" style={formHide ? { display: 'none' } : {}}>
            <div onClick={onCancel} className="intake-cancel-form">
              <Icon name="times" color="white" styles={{ height: '100%', width: '40%' }} />
            </div>
            <Row styles={{ marginBottom: '20px' }}>
              <Typography font="semi-bold" color={theme.colors.primary1}>
                Other Medication:
              </Typography>
            </Row>
            <div className="intake-med-field-row">
              <Form.Control
                type="text"
                onChange={(e) => setFieldValue('name', e.currentTarget.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('name', errors),
                }}
                value={values.name}
                placeholder="Medication Name *"
                className="login-input physician-input intake-med-input"
              />
              <div className="med-input-spacer" />
              <Form.Control
                type="text"
                required
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('strength', errors),
                }}
                onChange={(e) => setFieldValue('strength', e.currentTarget.value)}
                value={values.strength}
                placeholder="Strength"
                className="login-input physician-input intake-med-input"
              />
              <div className="med-input-spacer" />
            </div>

            <Row styles={{ justifyContent: 'space-between' }}>
              <Form.Control
                type="text"
                onChange={(e) => setFieldValue('otherFrequency', e.currentTarget.value)}
                style={{
                  width: '100%',
                  height: 80,
                  marginRight: 5,
                  marginTop: 20,
                  border: '2px solid transparent',
                  borderRadius: 4,
                }}
                value={values.otherFrequency}
                placeholder="Prescription Directions- i.e. One tablet daily"
                className="login-input physician-input"
              />
            </Row>
            <Row styles={{ justifyContent: 'space-between' }}>
              <Form.Control
                type="text"
                onChange={(e) => setFieldValue('reason', e.currentTarget.value)}
                style={{
                  width: '100%',
                  height: 80,
                  marginRight: 5,
                  marginTop: 20,
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('reason', errors),
                }}
                value={values.reason}
                placeholder="Reason for Taking"
                className="login-input physician-input"
              />
            </Row>
            {attemptedSubmit && isValid === false && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <StyledErrorText>Please fix errors above</StyledErrorText>
              </Row>
            )}
            <Row center styles={{ marginTop: 20 }}>
              <Button pageName={`intake_Medication`} text="Cancel" className="back-button" onClick={onCancel} />
              <Button
                pageName={`intake_Medication`}
                text="Save"
                className="next-button"
                onClick={() => {
                  handleSubmit()
                  setAttemptedSubmit(true)
                }}
              />
            </Row>
          </div>
        )
      }}
    </Formik>
  )
}
