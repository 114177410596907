import { useStopContext } from 'Pages/ProgramScreen'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import usePrograms from 'hooks/usePrograms'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import { STOPActivity, STOPActivityStep } from 'types/STOP'
import BenefitsChart from './BenefitsChart'
import CompleteScreen from './CompleteScreen'
import BodyImpacts from './HealthBenefits/BodyImpacts'
import ChooseToQuit from './HealthBenefits/ChooseToQuit'
import HealthBenefits from './HealthBenefits/HealthBenefits'
import IntroScreen from './HealthBenefits/IntroScreen'
import Introduction from './HealthBenefits/Introduction'
import MedicationOptions from './HealthBenefits/MedicationOptions'
import Obstacle1 from './HealthBenefits/Obstacle1'
import Obstacle2 from './HealthBenefits/Obstacle2'
import Obstacle3 from './HealthBenefits/Obstacle3'
import Obstacle4 from './HealthBenefits/Obstacle4'
import OvercomingObstacles from './HealthBenefits/OvercomingObstacles'
import QuittingBenefits from './HealthBenefits/QuittingBenefits'
import QuittingWithoutNRT from './HealthBenefits/QuittingWithoutNRT'
import Quotes from './HealthBenefits/Quotes'
import HealthConditions1 from './HealthConditions/HealthConditions1'
import HealthConditions2 from './HealthConditions/HealthConditions2'
import HealthConditions3 from './HealthConditions/HealthConditions3'
import MedicationHelp from './MedicationHelp'
import Medications1 from './ProCons/Medications1'
import Medications2 from './ProCons/Medications2'
import NRTGum from './ProCons/NRTGum'
import NRTInhaler from './ProCons/NRTInhaler'
import NRTLozenge from './ProCons/NRTLozenge'
import NRTNasalSpray from './ProCons/NRTNasalSpray'
import NRTPatch from './ProCons/NRTPatch'
import ReflectionScreen from './ReflectionScreen'
import Activity from './components/Activity'
import TobaccoProducts from './components/TobaccoProducts'

export default function STOPProgram() {
  const token = useAppSelector(getAccessToken)
  const dispatch = useDispatch()
  const analytics = useAnalytics(token!)

  const [started, setStarted] = useState(false)
  const [completionDate, setCompletionDate] = useState(dayjs().add(30, 'day').format('MM/DD/YYYY'))

  const { getSTOPStatus, postSTOPStatus } = usePrograms(token!)
  const { currentActivity, currentActivityStep, setCurrentActivity, setCurrentActivityStep, setActivityStatus } = useStopContext()

  useEffect(() => {
    dispatch(setLoading(true))

    getSTOPStatus()
      .then((res) => {
        setActivityStatus(res)
        const activityEndDate = res.find((x) => x.activityPId === 1)?.availableDate
        activityEndDate && setCompletionDate(dayjs(activityEndDate).add(90, 'day').format('MM/DD/YYYY'))

        const newActivity = res.sort((a, b) => a.activityPId - b.activityPId).find((x) => x.completedDate === null)
        newActivity && setCurrentActivity(newActivity.activityPId - 1)

        if (newActivity && newActivity.lastActivityStepPId) {
          const activityDetails = STOPActivitySteps.find((activity) => activity.key === newActivity.activityPId - 1)
          if (activityDetails) {
            const stepIndex = activityDetails.steps.findIndex((step) => step.value === newActivity.lastActivityStepPId)
            setCurrentActivityStep(stepIndex)
          }
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  const startActivity = (activityId?: STOPActivity, summary = false) => {
    analytics.postAnalyticsEvent({
      category: `click_button_stop`,
      action: `click_button_start_activity_${activityId}`,
      label: `Button`,
      screenName: 'StopProgram',
    })

    if (activityId !== undefined) {
      setCurrentActivity(activityId)
      !summary && postSTOPStatus(activityId, undefined, true)
    }
    setStarted(true)
    window.scrollTo(0, 0)
  }

  if (!started) {
    return <IntroScreen onSetStarted={(activityId, summary) => startActivity(activityId, summary)} completionDate={completionDate} />
  } else {
    switch (currentActivity) {
      case STOPActivity.HealthBenefits:
        return <Activity onSaveAndQuit={() => setStarted(false)} activityId={STOPActivity.HealthBenefits} isComplete={false} />
      case STOPActivity.OvercomingObstacles:
        return <Activity onSaveAndQuit={() => setStarted(false)} activityId={STOPActivity.OvercomingObstacles} isComplete={false} />
      case STOPActivity.MedicationOptions:
        return <Activity onSaveAndQuit={() => setStarted(false)} activityId={STOPActivity.MedicationOptions} isComplete={false} />

      default:
        return <Activity onSaveAndQuit={() => setStarted(false)} activityId={STOPActivity.HealthBenefits} isComplete={false} />
    }
  }
}

export const STOPActivitySteps: STOPActivityStep[] = [
  {
    key: STOPActivity.HealthBenefits,
    title: 'Health Benefits of Quitting Tobacco',
    steps: [
      { component: <Introduction />, value: 1 },
      { component: <HealthBenefits />, value: 2 },
      { component: <TobaccoProducts />, value: 3 },
      { component: <QuittingBenefits />, value: 4 },
      { component: <BenefitsChart />, value: 5 },
      { component: <BodyImpacts />, value: 6 },
      { component: <HealthConditions1 />, value: 7 },
      { component: <HealthConditions2 />, value: 8 },
      { component: <HealthConditions3 />, value: 9 },
      { component: <ReflectionScreen />, value: 10 },
      { component: <CompleteScreen activityId={STOPActivity.HealthBenefits} />, value: 11 },
    ],
  },
  {
    key: STOPActivity.OvercomingObstacles,
    title: 'Overcoming the Obstacles',
    steps: [
      { component: <OvercomingObstacles />, value: 12 },
      { component: <ChooseToQuit />, value: 13 },
      { component: <Obstacle1 />, value: 14 },
      { component: <Obstacle2 />, value: 15 },
      { component: <Obstacle3 />, value: 16 },
      { component: <Obstacle4 />, value: 17 },
      { component: <Quotes />, value: 18 },
      { component: <ReflectionScreen />, value: 19 },
      { component: <CompleteScreen activityId={STOPActivity.OvercomingObstacles} />, value: 20 },
    ],
  },
  {
    key: STOPActivity.MedicationOptions,
    title: 'Medication Options & Benefits',
    steps: [
      { component: <MedicationOptions />, value: 21 },
      { component: <MedicationHelp />, value: 22 },
      { component: <QuittingWithoutNRT />, value: 23 },
      { component: <NRTGum />, value: 24 },
      { component: <NRTLozenge />, value: 25 },
      { component: <NRTPatch />, value: 26 },
      { component: <NRTNasalSpray />, value: 27 },
      { component: <NRTInhaler />, value: 28 },
      { component: <Medications1 />, value: 29 },
      { component: <Medications2 />, value: 30 },
      { component: <ReflectionScreen />, value: 31 },
      { component: <CompleteScreen activityId={STOPActivity.MedicationOptions} />, value: 32 },
    ],
  },
]
