import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import React, { ReactNode } from 'react'

interface Props {
  isMobile: boolean | undefined
  textSection: ReactNode
  imgSrc: string
}
export default function Template4({ isMobile, textSection, imgSrc }: Props) {
  return isMobile ? (
    <ResponsiveContainer>
      <Column styles={{ width: '100%', margin: 'auto 0px' }}>
        <img src={imgSrc} alt="scale" style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto' }} />
      </Column>
      <Column styles={{ margin: '20px auto' }}>{textSection}</Column>
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer>
      <Column styles={{ margin: '0px 120px 0px 0px' }}>{textSection}</Column>
      <Column styles={{ width: '100%', margin: 'auto 0px' }}>
        <img src={imgSrc} alt="scale" style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto' }} />
      </Column>
    </ResponsiveContainer>
  )
}
