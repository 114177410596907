import React, { HTMLAttributes } from 'react'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  styles?: React.CSSProperties
  className?: string
  center?: boolean
} & HTMLAttributes<HTMLDivElement>
export default function Column({ children, styles, className, center = false, ...props }: Props) {
  if (center) styles = { ...styles, justifyContent: 'center' }

  return (
    <div style={{ ...styles, display: 'flex', flexDirection: 'column' }} className={className} {...props}>
      {children}
    </div>
  )
}
