/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from 'hooks/hooks'
import React, { useEffect } from 'react'
import { setLoading } from 'reducers/tempSlice'

export default function ClientPage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    window.open(`https://www.triahealth.com${window.location.pathname}`, '_self')
  }, [])
  return <></>
}
