import Checkbox from 'Components/Base/Checkbox'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import { AllergyOption } from '..'
import '../../PatientIntake.scss'

interface Props {
  allergyOptions: AllergyOption[]
  otherAllergies: AllergyOption[]
  setOtherAllergies: (other: AllergyOption[]) => void
  toggleSelection: (option: AllergyOption) => void
  updateDescription: (option: AllergyOption, description: string) => void
  setOtherClicked: (bool: boolean) => void
  otherClicked: boolean
}

export default function AllergiesCard({
  allergyOptions,
  toggleSelection,
  updateDescription,
  otherAllergies,
  setOtherAllergies,
  otherClicked,
  setOtherClicked,
}: Props) {
  const theme = useTheme()

  const update = (option, value, IsAllergy) => {
    if (IsAllergy) {
      updateDescription(option, `${value} - ${option.reaction.split('- ')[1] ?? ''}`)
    } else {
      updateDescription(option, `${option.reaction.split(' -')[0] ?? ''} - ${value}`)
    }
  }
  return (
    <div className="intake-allergy-card">
      <Row styles={{ marginBottom: '20px' }}>
        <Typography font="semi-bold" color={theme.colors.black}>
          Are you allergic to any of the following drugs?
        </Typography>
      </Row>
      {allergyOptions.map((option: AllergyOption) => {
        return (
          <>
            <Row styles={{ marginBottom: 10 }}>
              <Checkbox onClick={() => toggleSelection(option)} checked={option.allergic} label={option.medication} />
            </Row>
            {option.allergic && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <Form.Control
                  type="text"
                  onChange={(e) => updateDescription(option, e.currentTarget.value)}
                  style={{
                    width: '100%',
                    marginRight: 5,
                    border: '2px solid transparent',
                    borderRadius: 4,
                  }}
                  value={option.reaction}
                  placeholder="Explain reaction"
                  className="login-input physician-input"
                />
              </Row>
            )}
          </>
        )
      })}
      <Row styles={{ marginBottom: 10 }}>
        <Checkbox onClick={() => setOtherClicked(!otherClicked)} checked={otherClicked} label={'Other'} />
      </Row>
      {otherAllergies.map((option: AllergyOption) => {
        return (
          <>
            {otherClicked && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <Form.Control
                  type="text"
                  onChange={(e) => update(option, e.currentTarget.value, true)}
                  style={{
                    width: '50%',
                    marginRight: 5,
                    border: '2px solid transparent',
                    borderRadius: 4,
                  }}
                  value={option.reaction.split(' -')[0]}
                  placeholder="Allergy"
                  className="login-input physician-input"
                />
                <Form.Control
                  type="text"
                  onChange={(e) => update(option, e.currentTarget.value, false)}
                  style={{
                    width: '100%',
                    marginRight: 5,
                    border: '2px solid transparent',
                    borderRadius: 4,
                  }}
                  value={option.reaction.split('- ')[1]}
                  placeholder="Explain reaction"
                  className="login-input physician-input"
                />
                <div onClick={() => setOtherAllergies(otherAllergies.filter((allergy) => allergy.id !== option.id))} style={{ cursor: 'pointer' }}>
                  Remove <Icon name="circleX" />
                </div>
              </Row>
            )}
          </>
        )
      })}
      {otherClicked && (
        <Row
          styles={{ border: '1px solid #828282', width: '300px', margin: '20px auto', cursor: 'pointer' }}
          onClick={() =>
            otherAllergies && otherAllergies.length > 0
              ? setOtherAllergies([
                  ...otherAllergies,
                  { id: otherAllergies[otherAllergies.length - 1].id + 1, medication: 'Other', allergic: true, reaction: '' },
                ])
              : setOtherAllergies([...otherAllergies, { id: 6, medication: 'Other', allergic: true, reaction: '' }])
          }
        >
          <Icon
            name="plus"
            size="2x"
            styles={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              backgroundColor: theme.colors.primary1,
              padding: 10,
              color: 'white',
            }}
          />
          <Typography styles={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 20 }} size={20} font="bold">
            Add Another Allergy
          </Typography>
        </Row>
      )}
    </div>
  )
}
