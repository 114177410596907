import Icon from 'Components/Base/Icon'
import Typography from 'Components/Base/Typography'
import PreRegistration from 'Pages/Homepage/Pre-Registration'
import ProgramScreen from 'Pages/ProgramScreen'
import STOPCertificate from 'Pages/STOPProgram/STOPCertificate'
import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { useAuth } from 'react-oauth2-pkce'
import { useDispatch } from 'react-redux'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { BannerMessage } from 'types/Profile'
import LoadingIndicator from '../Components/LoadingIndicator'
import AppointmentPage from '../Pages/AppointmentPage'
import CernerClientPage from '../Pages/CernerClientPage'
import ErrorScreen from '../Pages/ErrorScreen'
import ConsentForm from '../Pages/Forms/ConsentForm'
import Homepage from '../Pages/Homepage'
import NotFound from '../Pages/NotFound'
import { useAppSelector } from '../hooks/hooks'
import { getAccessToken, restoreToken } from '../reducers/authSlice'
import { getProfile, getProfileStatus } from '../reducers/profileSlice'
import { getLoading, setLoading } from '../reducers/tempSlice'
import AccountRoutes from './AccountRoutes'
import FormRoutes from './FormRoutes'
import InfoRoutes from './InfoRoutes'
import ProtectedRoute from './ProtectedRoute'
import ProtectedRoutes from './ProtectedRoutes'
import ResourceRoutes from './ResourceRoutes'

type Props = {
  isMobile: boolean
}
export default function RootRoutes({ isMobile }: Props) {
  const auth = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const profile = useAppSelector(getProfile)

  const loading = useAppSelector(getLoading)
  const accessToken = useAppSelector(getAccessToken)
  const profileStatus = useAppSelector(getProfileStatus)

  const [intervalCount, setIntervalCount] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [authenticated, setAuthenticated] = useState(false)

  const [displayMarquee, setDisplayMarquee] = useState<boolean>(false)
  const [bannerMessage, setBannerMessage] = useState<BannerMessage>({ message: '', route: '' })

  useEffect(() => {
    if (accessToken === undefined || accessToken === '') {
      setAuthenticated(false)
    } else {
      setAuthenticated(true)
    }
  }, [accessToken])

  useEffect(() => {
    if (profile && profile.bannerMessages?.length > 0) {
      setDisplayMarquee(true)
      setBannerMessage(profile.bannerMessages[0])
    } else {
      setDisplayMarquee(false)
    }
  }, [profile])

  useEffect(() => {
    const code = searchParams.get('code')

    if (code && authenticated === false) {
      dispatch(setLoading(true))
      if (intervalCount <= 10) {
        const interval = setInterval(() => {
          const authToken = auth.authService.getAuthTokens().access_token

          if (authToken && profileStatus === 'idle') {
            dispatch(restoreToken(authToken))
            dispatch(setLoading(false))
            const now = new Date()
            const item = {
              expiry: now.getTime() + 60 * 60 * 1000,
            }

            window.localStorage.setItem('tokenExp', JSON.stringify(item))
          }
          setIntervalCount(intervalCount + 1)
        }, 1000)
        return () => clearInterval(interval)
      }
    }
  }, [])

  return (
    <div
      className="content-container"
      style={{
        minHeight: 'inherit',
        position: 'relative',
      }}
    >
      {displayMarquee && (
        <div onClick={bannerMessage.route !== '' ? () => navigate(bannerMessage.route) : undefined}>
          <Marquee
            style={{
              backgroundColor: '#9B2743',
              color: 'white',
              height: 50,
              textAlign: 'center',
              marginTop: isMobile ? -4 : 0,
              cursor: bannerMessage.route !== '' ? 'pointer' : 'default',
            }}
            gradient={false}
            play={isMobile || window.innerWidth < 1900 ? true : false}
          >
            <Typography>
              <>
                {bannerMessage.message}
                <Icon name="external-link" styles={{ marginLeft: 10, marginRight: 5 }} />
              </>
            </Typography>
          </Marquee>
        </div>
      )}
      {loading && (
        <StyledLogoContainer>
          <StyledLogoInnerContainer>
            <LoadingIndicator />
          </StyledLogoInnerContainer>
        </StyledLogoContainer>
      )}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/account/login" element={<Homepage />} />
        <Route path="/account/login/:provider" element={<Homepage />} />

        <Route path="pre-registration" element={<PreRegistration />} />

        <Route path="/consent" element={<ConsentForm />} />

        <Route path="/Cerner" element={<CernerClientPage />} />
        <Route path="/Yavapai" element={<Homepage />} />

        {AccountRoutes.map((route) => (
          <Route path={route.path} element={route.component} />
        ))}
        {InfoRoutes.map((route) => (
          <Route path={route.path} element={route.component} />
        ))}
        {ResourceRoutes.map((route) => (
          <Route path={route.path} element={route.component} />
        ))}
        {FormRoutes.map((route) => (
          <Route path={route.path} element={route.component} />
        ))}

        {ProtectedRoutes.map((route) => {
          return (
            <Route
              path={route.path}
              element={
                <ProtectedRoute isAuthenticated={authenticated} requestedRoute={route.path}>
                  {route.component}
                </ProtectedRoute>
              }
            />
          )
        })}

        {profile?.hasSTOP && (
          <Route
            path={'/programs/stop'}
            element={
              <ProtectedRoute isAuthenticated={authenticated} requestedRoute={'/programs/stop'}>
                <ProgramScreen />
              </ProtectedRoute>
            }
          />
        )}
        <Route path="/program/stop/certificate/:guid" element={<STOPCertificate />} />

        <Route path="/appointment/" element={<AppointmentPage isMobile={isMobile} />} />

        <Route path="/error" element={<ErrorScreen />} />

        <Route path="/404" element={<NotFound isMobile={isMobile} />} />

        {/* Default redirect if no route found  */}
        <Route path="*" element={<NotFound isMobile={isMobile} />} />
      </Routes>
    </div>
  )
}

const StyledLogoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  z-index: 100;
`

const StyledLogoInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  top: 30vh;
`
