import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import StateDropdown from 'Components/Base/StateDropdown'
import Typography from 'Components/Base/Typography'
import { StyledErrorText } from 'Styles/global'
import { AxiosResponse } from 'axios'
import { ErrorMessage, Formik, FormikProps } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import useCommunication, { CommunicationFormValues } from 'hooks/useCommunication'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getAccessToken } from 'reducers/authSlice'
import { getCommunicationPreferences, loadCommunicationPreferences, loadProfile } from 'reducers/profileSlice'
import { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import formatPhoneNumberInput from 'utilities/formatPhoneNumberInput'
import * as Yup from 'yup'
import ContentHeader from '../ContentHeader'
import './CommunicationPreferences.scss'

interface Props {
  isMobile: boolean
  backPressed: () => void
  sideMenuToggled: () => void
}

export default function ProfileCommunicationPreferences({ isMobile, backPressed, sideMenuToggled }: Props) {
  const theme = useTheme()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Communication')
  const dispatch = useDispatch()

  const token = useAppSelector(getAccessToken)
  const communicationPreferences = useAppSelector(getCommunicationPreferences)
  const { getCommunication, postCommunication } = useCommunication(token!)

  const [appointmentReminders, setAppointmentReminders] = useState<boolean>(false)
  const [healthAlerts, setHealthAlerts] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [successMessage, setSuccessMessage] = useState<string>()
  const [pageLoading, setPageLoading] = useState(false)

  const [shipmentNotifications, setShipmentNotifications] = useState<boolean>(false)
  const formikRef = useRef<FormikProps<CommunicationFormValues>>(null)

  const initialValues: CommunicationFormValues = {
    ...communicationPreferences,
  }

  useEffect(() => {
    if (formikRef.current) {
      const ref = formikRef.current
      ref.setFieldValue('phone', formatPhoneNumberInput(communicationPreferences.phone))
      ref.setFieldValue('address1', communicationPreferences.address1)
      ref.setFieldValue('address2', communicationPreferences.address2)
      ref.setFieldValue('city', communicationPreferences.city)
      ref.setFieldValue('state', communicationPreferences.state)
      ref.setFieldValue('zip', communicationPreferences.zip)
    }
  }, [communicationPreferences])

  const submitCommunicationPreferences = (values: CommunicationFormValues) => {
    setPageLoading(true)
    values.textOptIns = []
    values.phone = values.phone?.replace(/[^\d]/g, '') ?? ''

    values.textOptIns = [
      ...values.textOptIns,
      { programId: 1, optIn: appointmentReminders },
      { programId: 22, optIn: healthAlerts },
      { programId: 26, optIn: shipmentNotifications },
    ]
    postCommunication(values)
      .then(() => {
        setSuccessMessage('Preferences Saved!')
      })
      .catch(() => {
        setErrorMessage('There was an error. Please try again')
      })
      .finally(() => {
        setPageLoading(false)
        token && dispatch(loadProfile(token))
      })
  }

  useEffect(() => {
    getCommunication().then((res: AxiosResponse<any>) => {
      const optIns = res.data.textOptIns
      const optInAll = res.data.textOptIns.find((x: any) => x.programId === 25 && x.optIn === true)
      const appointmentReminders = optIns.find((x: any) => x.programId === 1) ?? false
      const healthAlerts = optIns.find((x: any) => x.programId === 22) ?? false
      const shipmentNotifications = optIns.find((x: any) => x.programId === 26) ?? false

      setAppointmentReminders(optInAll ? true : appointmentReminders.optIn)
      setHealthAlerts(optInAll ? true : healthAlerts.optIn)
      setShipmentNotifications(optInAll ? true : shipmentNotifications.optIn)
      token && dispatch(loadCommunicationPreferences(token))
    })
  }, [])
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(14, 'Phone must be 10 digits')
      .required('Phone is required')
      .matches(new RegExp(/^(\([2-9]{1}[0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/), 'Phone is invalid'),
  })

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={(values) => submitCommunicationPreferences(values)}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <div style={{ paddingBottom: 40 }}>
            <Row
              styles={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onClick={backPressed}
            >
              {isMobile && (
                <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
                  <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
                </div>
              )}
              <ContentHeader title="Communication Preferences" icon="comment" color="#00677f" fontSize={isMobile ? '1rem' : undefined} />
              {isMobile && <div></div>}
            </Row>

            <div className="communication-row">
              <div>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Email:</Form.Label>
                <Form.Control
                  onChange={(value) => setFieldValue('email', value.currentTarget.value)}
                  value={values.email}
                  placeholder=""
                  disabled
                  style={{ backgroundColor: '#e9ecef' }}
                  className="login-input profile-home-input"
                />
              </div>
              <div className="input-spacer" />
              <div>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Phone Number:</Form.Label>
                <Form.Control
                  onChange={(value) => setFieldValue('phone', formatPhoneNumberInput(value.currentTarget.value))}
                  value={values.phone}
                  style={{ backgroundColor: '#F2F2F2' }}
                  placeholder=""
                  className="login-input communication-input"
                  type="text"
                />
                <Row center className="intake-error">
                  <ErrorMessage name="phone" />
                </Row>
              </div>
            </div>

            <div className="communication-row" style={{ flexWrap: 'wrap', marginBottom: 25 }}>
              <Column>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Address:</Form.Label>
                <Form.Control
                  onChange={(value) => setFieldValue('address1', value.currentTarget.value)}
                  value={values.address1}
                  style={{ backgroundColor: '#edfdff' }}
                  placeholder=""
                  className="login-input"
                />
              </Column>
              <div className="input-spacer" />
              <Column>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Address 2:</Form.Label>
                <Form.Control
                  onChange={(value) => setFieldValue('address2', value.currentTarget.value)}
                  value={values.address2}
                  style={{ backgroundColor: '#edfdff' }}
                  placeholder=""
                  className="login-input"
                />
              </Column>
              <div className="input-spacer" />
              <div className="communication-row">
                <Column>
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>City:</Form.Label>
                  <Form.Control
                    onChange={(value) => setFieldValue('city', value.currentTarget.value)}
                    value={values.city}
                    style={{ backgroundColor: '#edfdff' }}
                    placeholder=""
                    className="login-input"
                  />
                </Column>
                <div className="input-spacer" />
                <Row>
                  <Column>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>State:</Form.Label>
                    <StateDropdown value={values.state} onFieldUpdated={(e: any) => setFieldValue('state', e.currentTarget.value)} />
                  </Column>
                  <div style={{ width: 25 }} />
                  <Column>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Zip:</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(value) => setFieldValue('zip', value.currentTarget.value)}
                      value={values.zip}
                      style={{
                        backgroundColor: '#edfdff',
                      }}
                      placeholder=""
                      className="login-input"
                    />
                  </Column>
                </Row>
              </div>
            </div>
            <div
              style={{
                backgroundColor: '#00677f',
                height: '40px',
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: 15,
                paddingRight: 10,
                marginBottom: 20,
              }}
            >
              <Typography font="bold" color={theme.colors.white}>
                Email Opt-In:
              </Typography>
              <Checkbox checked={values.emailOptIn} onClick={() => setFieldValue('emailOptIn', !values.emailOptIn)} />
            </div>
            <Typography font="bold" color={theme.colors.primary1} size={25} styles={{ marginBottom: 20 }}>
              Text Messages:
            </Typography>
            <div
              style={{
                backgroundColor: '#F2F2F2',
                height: '40px',
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: 15,
                paddingRight: 10,
                marginTop: 20,
              }}
            >
              <Typography font="bold" color={theme.colors.black}>
                Appointment Reminders
              </Typography>
              <Checkbox checked={appointmentReminders} onClick={() => setAppointmentReminders(!appointmentReminders)} />
            </div>
            <div
              style={{
                backgroundColor: '#F2F2F2',
                height: '40px',
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: 15,
                paddingRight: 10,
                marginTop: 20,
              }}
            >
              <Typography font="bold" color={theme.colors.black}>
                Tria Health Alerts (I.E. Blood Glucose Meter, Blood Pressure Cuff, Etc.)
              </Typography>
              <Checkbox checked={healthAlerts} onClick={() => setHealthAlerts(!healthAlerts)} />
            </div>
            <div
              style={{
                backgroundColor: '#F2F2F2',
                height: '40px',
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: 15,
                paddingRight: 10,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Typography font="bold" color={theme.colors.black}>
                Shipment Notifications
              </Typography>
              <Checkbox checked={shipmentNotifications} onClick={() => setShipmentNotifications(!shipmentNotifications)} />
            </div>
            <Row>
              <Typography font="bold" color={theme.colors.black}>
                <>
                  View Tria Health’s Electronic Communication Consent Form{' '}
                  <a
                    href="https://triacentral.blob.core.windows.net/dev-storage/documents/TriaHealth_ElectronicCommunicationConsent.pdf"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Here</span>
                  </a>
                </>
              </Typography>
            </Row>
            <Row styles={{ marginBottom: 20 }}>
              <Typography font="bold" color={theme.colors.black}>
                <>
                  View Tria Health’s Privacy Policy{' '}
                  <a
                    href="https://triacentral.blob.core.windows.net/dev-storage/documents/Tria_Health_Privacy_Policy.pdf"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Here</span>
                  </a>
                </>
              </Typography>
            </Row>
            {values.hasSignedConsent === false && (
              <>
                <Row>
                  <Typography font="bold" color={theme.colors.black}>
                    Opt Into Tria Health Electronic Communications
                  </Typography>
                </Row>
                <div style={{ border: '1px solid black', padding: 10 }}>
                  <Typography color={theme.colors.black}>
                    {`I acknowledge receipt of the Patient Consent to Receive Electronic
                    Communications from Tria Health (“Consent”), and I understand that a copy may be
                    emailed to me and is also available upon request by calling Tria Health at
                    ${PhoneNumberPeriodDelimited}. I have read the Consent form and fully understand the
                    potential risks associated with Tria Health’s electronic communication program
                    and any electronic communications that I may receive thereunder, including those
                    that contain protected health information (PHI) and other individually
                    identifiable information about me. By signing below, I indicate I am the person
                    legally responsible for all use of the account(s) listed below, and that I am at
                    least 18 years of age. Without being obligated to do so, I voluntarily give Tria
                    Health, and those authorized by Tria Health, my consent to participate in the
                    electronic communication program and to receive electronic communications
                    thereunder, pursuant to the terms and conditions set forth in the Consent.`}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '25px',
                  }}
                >
                  <Form.Label
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      marginTop: 15,
                    }}
                  >
                    Signature:
                  </Form.Label>
                  <Form.Control
                    onChange={(value) => setFieldValue('signature', value.currentTarget.value)}
                    value={values.signature}
                    placeholder=""
                    style={{ width: '350px' }}
                    className="login-input"
                  />
                </div>
              </>
            )}
            {errorMessage && (
              <Row>
                <StyledErrorText>{errorMessage}</StyledErrorText>
              </Row>
            )}
            {successMessage && (
              <Row>
                <Typography color={theme.colors.primary1} font="semi-bold">
                  {successMessage}
                </Typography>
              </Row>
            )}
            <Button
              pageName="profile_communication_preferences"
              text="Save Changes"
              loading={pageLoading}
              onClick={submitForm}
              className="communication-save-button"
            />
          </div>
        )
      }}
    </Formik>
  )
}
