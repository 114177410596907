import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import Template5 from 'Pages/CTLChallenges/Components/Templates/Template5'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function SleepStep5({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])

  return (
    <Template5
      isMobile={isMobile}
      textSection={
        <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
          <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
            <Typography variant="h1">{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</Typography>
          </Row>
          <ol start={5}>
            <li style={{ fontSize: 20, marginLeft: -25 }}>
              <Row styles={{ margin: isMobile ? '20px 0px' : '0px 0px' }}>
                <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
                  {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 21)?.paragraph ?? ''}
                </p>
              </Row>
            </li>
            <li style={{ fontSize: 20, marginLeft: -25 }}>
              <Row styles={{ margin: isMobile ? '20px 0px' : '0px 0px' }}>
                <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
                  {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 22)?.paragraph ?? ''}
                </p>
              </Row>
            </li>
            <li style={{ fontSize: 20, marginLeft: -25 }}>
              <Row styles={{ margin: isMobile ? '20px 0px' : '0px 0px' }}>
                <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
                  {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 23)?.paragraph ?? ''}
                </p>
              </Row>
            </li>
            <li style={{ fontSize: 20, marginLeft: -25 }}>
              <Row styles={{ margin: isMobile ? '20px 0px' : '0px 0px' }}>
                <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
                  {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 24)?.paragraph ?? ''}
                </p>
              </Row>
            </li>
          </ol>
        </Column>
      }
      imgSection={
        <Column styles={{ width: '100%' }}>
          <ResponsiveContainer>
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 8)?.url}
              alt="scale"
              style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
            />
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 9)?.url}
              alt="scale"
              style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
            />
          </ResponsiveContainer>
          <ResponsiveContainer>
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 10)?.url}
              alt="scale"
              style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
            />
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 11)?.url}
              alt="scale"
              style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
            />
          </ResponsiveContainer>
        </Column>
      }
    />
  )
}
