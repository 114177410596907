import React from 'react'
import { useTheme } from 'styled-components'
import Image from '../../../Assets/Images/Portal_Thumbs_Up.svg'
import Row from '../../../Components/Base/Row'
import Typography from '../../../Components/Base/Typography'
import '../ReimbursementPage.scss'
import { PhoneNumber, PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'

export default function ReimbursementSuccess() {
  const theme = useTheme()

  return (
    <div className="confirm-page-container">
      <h1 style={{ fontFamily: 'Raleway-Bold' }}>Thank You!</h1>
      <Typography>We're working hard on getting you your reimbursement as quickly as possible.</Typography>
      <p>Please allow 2-4 weeks for processing</p>
      <p>If you have questions, please contact the Tria Help Desk: </p>
      <a href={`tel:${PhoneNumber}`}>
        <Typography color={theme.colors.primary1} font="bold">
          {PhoneNumberPeriodDelimited}
        </Typography>
      </a>
      <div style={{ position: 'relative' }} className="reimbursement-mobile-image">
        <img src={Image} className="success-image" alt="Woman putting two thumbs up" />
      </div>
      <Typography font="bold" styles={{ marginTop: 10 }}>
        Please note:
      </Typography>
      <Row className="disclaimer-row">
        <Typography size={14} className="disclaimer-row-text">
          The amount of reimbursement received may be less than the member paid at the pharmacy based on a number of variables including plan design,
          deductibles, co-payments, and discounted price of drug. Reimbursements cannot be processed if the prescription fill date is greater than 12
          months from the time the reimbursement is submitted.
        </Typography>
      </Row>
      <div style={{ position: 'relative' }} className="reimbursement-web-image">
        <img src={Image} className="success-image" alt="Woman putting two thumbs up" />
      </div>
    </div>
  )
}
