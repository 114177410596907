import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import ListItem from 'Pages/STOPProgram/components/ListItem'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import Row1Image from '../../../../Assets/Images/STOP/CVD.png'
import Row2Image from '../../../../Assets/Images/STOP/Diabetes.png'

export default function HealthConditions2() {
  return (
    <>
      <STOPHeader>What Health Conditions Benefit the Most from Quitting Tobacco?</STOPHeader>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row1Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Cardiovascular Disease (CVD) - Heart Attack and Stroke:
          </ScaledText>
          <ScaledText>
            Tobacco is a major cause of cardiovascular disease, including heart attack and stroke. Smoking is the cause of 1 in every 4 cardiovascular
            deaths.
          </ScaledText>

          <ScaledText>Tobacco can</ScaledText>
          <ul>
            <ListItem>
              <ScaledText>Raise triglycerides (a type of fat in your blood)</ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>Lower “good” cholesterol (HDL)</ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>Make blood sticky and more likely to clot, reducing blood flow to the heart and brain</ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>Damage cells in the blood and blood vessel wall</ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>Increase the buildup of plaque (fat, cholesterol, calcium, and other substances) in the blood vessels</ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>Cause thickening and narrowing of blood vessels</ScaledText>
            </ListItem>
          </ul>
        </Column>
      </ResponsiveContainer>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row2Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Diabetes:
          </ScaledText>
          <ScaledText>
            Tobacco use is one of the causes of type 2 diabetes. People who use tobacco are 30%–40% more likely to develop type 2 diabetes than people
            who do not use tobacco.
          </ScaledText>
          <div style={{ marginTop: 15 }}>
            <ScaledText>
              People with diabetes that use tobacco are more likely to have trouble with insulin dosing and managing their condition. The more tobacco
              used, the higher risk for developing type 2 diabetes.
            </ScaledText>
          </div>
        </Column>
      </ResponsiveContainer>
    </>
  )
}

const StyledImage = styled.img`
  object-fit: cover;
  height: 15vh;
  border: 1px solid black;
  margin-bottom: 15px;
  @media (${sizes.tablet}) {
    height: 25vh;
    margin-right: 25px;
  }
`
