import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'

export default function ErrorScreen() {
  const theme = useTheme()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  return (
    <div className="confirm-container" style={{ marginTop: 40, minHeight: '80vh' }}>
      <div className="confirm-text" style={{ textAlign: 'left' }}>
        <h1 className="forgot-header-text" style={{ marginBottom: '10px', textAlign: 'left' }}>
          Oops!
        </h1>
        <Typography size={35} styles={{ marginBottom: 20, fontWeight: 'bold' }} color={theme.colors.primary1}>
          Something went wrong
        </Typography>
        <Typography size={22} styles={{ marginBottom: 50, fontWeight: 'bold' }}>
          Don't worry, our team is here to help:
        </Typography>
        {!isMobile ? (
          <Row>
            <Column styles={{ marginRight: 40, textAlign: 'left' }}>
              <Icon name="comment" size="3x" styles={{ color: theme.colors.primary1, marginBottom: 20, marginRight: 'auto' }} />
              <Typography styles={{ fontWeight: 'bold', marginBottom: 10 }}>Chat</Typography>
              <Typography>Click the chat</Typography>
              <Typography> bubble in the</Typography>
              <Typography> bottom right-hand</Typography>
              <Typography> corner of the screen.</Typography>
            </Column>
            <Column styles={{ marginRight: 40 }}>
              <Icon name="envelope" size="3x" styles={{ color: theme.colors.primary1, marginBottom: 20, marginRight: 'auto' }} />
              <Typography styles={{ fontWeight: 'bold', marginBottom: 10 }}>Email</Typography>
              <Typography>info@triahealth.com</Typography>
            </Column>
            <Column>
              <Icon name="phoneVolume" size="3x" styles={{ color: theme.colors.primary1, marginBottom: 20, marginRight: 'auto' }} />
              <Typography styles={{ fontWeight: 'bold', marginBottom: 10 }}>Call</Typography>
              <Typography>{PhoneNumberPeriodDelimited}</Typography>
            </Column>
          </Row>
        ) : (
          <Column styles={{ marginRight: 35, textAlign: 'center' }}>
            <Column styles={{ marginBottom: 20 }}>
              <Icon name="comment" size="3x" styles={{ color: theme.colors.primary1, marginBottom: 20 }} />
              <Typography styles={{ fontWeight: 'bold', marginBottom: 10 }}>Chat</Typography>
              <Typography>Click the chat</Typography>
              <Typography> bubble in the</Typography>
              <Typography> bottom right-hand</Typography>
              <Typography> corner of the screen.</Typography>
            </Column>
            <Column styles={{ marginBottom: 20 }}>
              <Icon name="envelope" size="3x" styles={{ color: theme.colors.primary1, marginBottom: 20 }} />
              <Typography styles={{ fontWeight: 'bold', marginBottom: 10 }}>Email</Typography>
              <Typography>info@triahealth.com</Typography>
            </Column>
            <Column styles={{ marginBottom: 20 }}>
              <Icon name="phoneVolume" size="3x" styles={{ color: theme.colors.primary1, marginBottom: 20 }} />
              <Typography styles={{ fontWeight: 'bold', marginBottom: 10 }}>Call</Typography>
              <Typography>{PhoneNumberPeriodDelimited}</Typography>
            </Column>
          </Column>
        )}
      </div>

      <img
        src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Error%20%201.png"
        style={{ maxHeight: 700, objectFit: 'cover' }}
        className="register-banner-image"
        alt="Woman putting two thumbs up"
      />
    </div>
  )
}
