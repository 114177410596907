import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Why Quit.png'

export default function ChooseToQuit() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Why do People Choose Not to Quit Tobacco?</STOPHeader>
          <div style={{ marginTop: 25 }}>
            <ScaledText size={25}>
              Most tobacco users say they want to quit but continue because tobacco seems to relieve stress and anxiety when it can worsen these
              conditions.
            </ScaledText>
            <Spacer top>
              <ScaledText size={25}>
                In 2015, 68% of adult smokers (22.7 million) said that they wanted to quit smoking. More than half of adult smokers have made a quit
                attempt in the past year and more than 3 out of 5 adults who have ever smoked cigarettes have quit.
              </ScaledText>
            </Spacer>
            <Spacer top>
              <ScaledText size={25}>
                When it comes to smokeless tobacco, more than 2 in every 100 adults aged 18 or older reported actively using. People who used only
                smokeless tobacco were less likely to try to quit than people who only smoked cigarettes.
              </ScaledText>
            </Spacer>
          </div>
        </>
      }
      image={BenefitsImage}
      altTag="Man at a computer"
    />
  )
}
