import Button from 'Components/Base/Button'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled, { useTheme } from 'styled-components'

type Props = {
  onSubmit: () => void
  onReset: () => void
  gettingScore: boolean
  scoreRef: React.MutableRefObject<HTMLDivElement | null>
  score: number
}
export default function CalculationRow({ onSubmit, onReset, gettingScore, scoreRef, score }: Props) {
  const theme = useTheme()
  return (
    <StyledContainer>
      <StyledResponsiveContainer>
        <StyledButton text="Calculate" onClick={onSubmit} loading={gettingScore} />
        <StyledButton
          text="Reset"
          onClick={onReset}
          styles={{
            backgroundColor: theme.colors.darkGray,
          }}
        />
      </StyledResponsiveContainer>
      <StyledScoreContainer ref={scoreRef}>
        Your Score: <span style={{ fontSize: 60, marginLeft: 20 }}>{score}</span>
      </StyledScoreContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  margin-top: 25px;
  @media ${sizes.laptop} {
    flex-direction: row;
  }
`
const StyledScoreContainer = styled.div`
  color: ${(props) => props.theme.colors.primary1};
  font-size: 30px;
  align-items: center;
  display: flex;
  font-family: 'Raleway-Bold';
`
const StyledButton = styled(Button)`
  font-family: 'Raleway-Semi-Bold';
  background-color: ${(props) => props.theme.colors.primary1};
  width: 100%;
  padding-top: 10px;
  border: 0px;
  padding-bottom: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 4;
  @media ${sizes.laptop} {
    width: 120px;
  }
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  width: 100%;
  @media ${sizes.laptop} {
    width: auto;
  }
`
