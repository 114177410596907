import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import { StyledPageContainer } from 'Styles/global'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Params, useParams } from 'react-router-dom'
import { PHQ9FormValues } from 'types/Forms'
import Row from '../../../Components/Base/Row'
import colors from '../../../Styles/colors'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import usePatientAssessment from '../../../hooks/usePatientAssessment'
import ContentHeader from '../../PatientProfile/components/ContentHeader'
import CalculationRow from '../components/CalculationRow'
import ColumnHeader from '../components/ColumnHeader'
import SurveyRow from '../components/SurveyRow'
import { phqQuestions } from '../contants'
import './PHQ9.scss'

export default function PHQ9Form() {
  const { guid }: Readonly<Params<string>> = useParams()
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('PHQ-9')

  const [score, setScore] = useState<number>(0)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [gettingScore, setGettingScore] = useState(false)

  const scoreRef = useRef<null | HTMLDivElement>(null)

  const executeScoreScroll = () => scoreRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const { getPHQ9Score } = usePatientAssessment()

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const initialValues: PHQ9FormValues = {
    littleInterest: 0,
    feelingDown: 0,
    sleep: 0,
    energy: 0,
    appetite: 0,
    failure: 0,
    concentration: 0,
    movingSpeaking: 0,
    selfHarm: 0,
  }

  const submitPHQ9Form = (values: PHQ9FormValues) => {
    setGettingScore(true)

    values.guid = guid

    executeScoreScroll()
    getPHQ9Score(values, (response: any) => {
      setScore(response.total)
    }).then(() => setGettingScore(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => submitPHQ9Form(values)}>
      {({ values, setFieldValue, submitForm }) => {
        return (
          <StyledPageContainer>
            <ContentHeader title="PHQ-9" color={colors.primary.blue} />
            <p className="survey-title">Please check the ONE best answer for your abilities at this time.</p>
            <div className="survey-banner">Over the last 2 weeks, how often have you been bothered by the following problems?</div>
            {!isMobile && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <div></div>
                <Row styles={{ width: '68%', justifyContent: 'space-between' }}>
                  <ColumnHeader title="Not at All" />
                  <ColumnHeader title="Several Days" />
                  <ColumnHeader title="More than Half the Days" />
                  <ColumnHeader title="Nearly Every Day" />
                </Row>
              </Row>
            )}

            {phqQuestions.map((row, index) => (
              <SurveyRow
                question={row.question}
                selectedOption={values[row.fieldValue] ?? 0}
                isMobile={isMobile}
                onSelectOption={(value) => {
                  setFieldValue(row.fieldValue, value)
                }}
              />
            ))}
            <TableContainer component={Paper} style={{ boxShadow: 'none', marginBottom: 40 }}></TableContainer>
            <CalculationRow
              onSubmit={submitForm}
              gettingScore={gettingScore}
              scoreRef={scoreRef}
              score={score}
              onReset={() => {
                setFieldValue('littleInterest', 0)
                setFieldValue('feelingDown', 0)
                setFieldValue('sleep', 0)
                setFieldValue('energy', 0)
                setFieldValue('appetite', 0)
                setFieldValue('failure', 0)
                setFieldValue('concentration', 0)
                setFieldValue('movingSpeaking', 0)
                setFieldValue('selfHarm', 0)
                setScore(0)
              }}
            />
          </StyledPageContainer>
        )
      }}
    </Formik>
  )
}
