interface Props {
  phoneNumber: string | null | undefined
  obscure?: boolean
}
export default function formatPhoneNumber({ phoneNumber, obscure = false }: Props): string {
  if (!phoneNumber) return ''
  const phoneArray = phoneNumber.split('')
  const areaCode = phoneArray.splice(0, 3).join('')
  const segmentOne = phoneArray.splice(0, 3).join('')
  const segmentTwo = phoneArray.splice(0, 6).join('')
  return obscure ? `(***) ***-${segmentTwo}` : `(${areaCode}) ${segmentOne}-${segmentTwo}`
}
