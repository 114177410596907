import Icon from 'Components/Base/Icon'
import React from 'react'

export const columns: any[] = [
  {
    field: 'id',
    editable: false,
    hide: true,
  },
  {
    flex: 1,
    field: 'readingDate',
    headerName: 'Date',
    type: 'date',
  },
  {
    flex: 1,
    field: 'readingTime',
    headerName: 'Time',
    type: 'date',
  },
  {
    flex: 1,
    field: 'systolicValue',
    headerName: 'Systolic',
    type: 'number',
  },
  {
    flex: 1,
    field: 'diastolicValue',
    headerName: 'Diastolic',
    type: 'number',
  },
  {
    flex: 1,
    field: 'patientReadingLevel',
    headerName: 'Type',
    type: 'string',
  },
  {
    flex: 1,
    field: 'heartRate',
    headerName: 'Heart Rate (bpm)',
    type: 'number',
  },
  {
    flex: 1,
    field: 'edit',
    headerName: 'Edit',
    cellClassName: 'edit-cell',
    sortable: false,
    renderCell: () => <Icon name="edit" />,
  },
]
