import ConfirmImage from 'Assets/Images/confirm.png'
import Button from 'Components/Base/Button'
import Typography from 'Components/Base/Typography'
import ConsentModal from 'Components/ConsentModal'
import RegisterPage from 'Pages/RegisterPage'
import { AxiosResponse } from 'axios'
import { useAppSelector } from 'hooks/hooks'
import useCommunication from 'hooks/useCommunication'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { getProfile } from 'reducers/profileSlice'
import { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import { Steps } from 'types/Appointment'
import { IntakeStatus } from 'types/Profile'
import './ConfirmForm.scss'

interface Props {
  message?: string
  callText?: boolean
  ctlText?: boolean
  title?: string
  scheduledAppointment
  justScheduled
  isInitial: boolean
  helpText: string | undefined
  changeStep
}
export default function ConfirmForm({
  message,
  callText = false,
  ctlText = false,
  title,
  scheduledAppointment,
  justScheduled,
  isInitial,
  changeStep,
  helpText,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false)

  const token = useAppSelector(getAccessToken)
  const theme = useTheme()
  const profile = useAppSelector(getProfile)
  const navigate = useNavigate()
  const { getCommunication } = useCommunication(token!)

  useEffect(() => {
    getCommunication().then((res: AxiosResponse<any>) => {
      setModalOpen(!res.data.hasSignedConsent)
    })
  }, [])

  function handleReschedule() {
    changeStep(Steps.Calendar)
  }

  return (
    <>
      {isInitial ? (
        <RegisterPage fromAvailability={true} />
      ) : (
        <div>
          <div className="confirm-container">
            {justScheduled && <ConsentModal modalOpen={modalOpen} toggleModal={(bool) => setModalOpen(bool)} />}
            <div className="confirm-text" style={{ maxWidth: '90%' }}>
              {justScheduled || ctlText ? (
                <h1>Thank You!</h1>
              ) : (
                <div>
                  {title && (
                    <h1
                      className="forgot-header-text"
                      style={{ marginBottom: '10px', textAlign: 'center' }}
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></h1>
                  )}
                </div>
              )}
              {callText && !ctlText ? (
                <text style={{ marginBottom: 20 }}>
                  {message ??
                    `A member advocate will give you a quick call within the next 24-72 hours to provide you with available appointment times.`}
                </text>
              ) : (
                <div>
                  {justScheduled ? (
                    <text style={{ marginBottom: 20 }}>We have your appointment booked.</text>
                  ) : (
                    <Typography styles={{ marginTop: 20, marginBottom: 20, fontWeight: 600 }}>
                      {message ??
                        `${
                          ctlText
                            ? "Thank you for applying to Tria Health’s Choose to Lose Program! We'll notify you of the next steps via email within one business day."
                            : ''
                        }`}
                    </Typography>
                  )}
                  {!justScheduled && ctlText && (
                    <Typography styles={{ marginBottom: 20, fontWeight: 600 }}>
                      <b>If your application is accepted,</b> a representative will contact you within three business days to help schedule your
                      appointment.
                    </Typography>
                  )}
                </div>
              )}
              {profile && profile.intakeStatus === IntakeStatus.IntakeIncomplete && (
                <div>
                  <Button
                    pageName="appointment"
                    text="Fill Out Patient Intake Form"
                    onClick={() => navigate('/patientintake')}
                    styles={{
                      fontFamily: 'Raleway-Semi-Bold',
                      backgroundColor: theme.colors.primary1,
                      border: '0px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      borderRadius: '0px',
                    }}
                  />
                </div>
              )}

              <div
                style={
                  scheduledAppointment
                    ? {
                        border: '2px solid transparent',
                        borderRadius: 4,
                        backgroundColor: '#edfdff',
                        boxShadow: 'inset 0px 1px 3px',
                        minHeight: 40,
                        fontSize: 20,
                        maxWidth: 380,
                        margin: 'auto',
                        marginTop: 10,
                        marginBottom: '10%',
                        padding: '10px 10px',
                      }
                    : { display: 'none' }
                }
              >
                <text>{scheduledAppointment}</text>
              </div>
              {scheduledAppointment ? (
                <div style={{ fontWeight: 'bold', marginTop: '5%', marginBottom: '3%' }}>
                  <text style={{ fontWeight: 'bold', fontSize: 20 }}>Need to make a Change?</text>
                  <br></br>
                  <Button pageName="appointment" text="Change/Reschedule" onClick={handleReschedule}></Button>
                </div>
              ) : (
                <div style={{ display: 'block', textAlign: 'center', marginTop: '15%' }}>
                  {helpText ? <text>{helpText}</text> : <text style={{ fontWeight: 'bold', fontSize: 20 }}>Need to make a Change?</text>}
                  <br></br>
                  <text style={{ fontWeight: 'bold', fontSize: 20 }}>{PhoneNumberPeriodDelimited}</text>
                </div>
              )}
            </div>

            <img
              src={ConfirmImage}
              style={justScheduled || ctlText ? { maxHeight: 700, paddingRight: '10%', paddingLeft: '10%' } : { display: 'none' }}
              className="confirm-form-image"
              alt="Woman with thumbs up"
            />
          </div>
        </div>
      )}
    </>
  )
}
