import Typography from 'Components/Base/Typography'
import React from 'react'

type ScoreRow = {
  color: string
  range: string
  label: string
}

type Props = {
  rows: ScoreRow[]
}
export default function ScoreContainer({ rows }: Props) {
  return (
    <div className="rapid-key-row">
      {rows.map((row) => (
        <div className="rapid-score-container" style={{ backgroundColor: row.color }}>
          <Typography>{row.range}</Typography>
          <Typography>{row.label}</Typography>
        </div>
      ))}
    </div>
  )
}
