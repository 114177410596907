import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Typography from 'Components/Base/Typography'
import ContentModal from 'Components/ContentModal'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oauth2-pkce'
import { useNavigate } from 'react-router-dom'
import { authLogout, getAccessToken } from 'reducers/authSlice'
import { setLoginError } from 'reducers/errorSlice'
import { clearRequestedRoute, setLoading } from 'reducers/tempSlice'

const IdleTimer = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const INACTIVE_TIME_LIMIT = 600000 // 10 Minutes
  const AUTO_LOGOUT_TIME_LIMIT = 120000 // 2 Minutes

  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const [isUserIdle, setIsUserIdle] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)
  const [countdown, setCountdown] = useState(AUTO_LOGOUT_TIME_LIMIT)

  useEffect(() => {
    let idleTimer: number = window.setTimeout(() => {}, 0)
    clearTimeout(idleTimer)

    const resetIdleTimer = () => {
      clearTimeout(idleTimer)

      idleTimer = window.setTimeout(() => {
        setIsUserIdle(true)
      }, INACTIVE_TIME_LIMIT)
    }

    window.addEventListener('mousemove', resetIdleTimer)
    window.addEventListener('keypress', resetIdleTimer)
    window.addEventListener('touchstart', resetIdleTimer)

    resetIdleTimer()

    return () => {
      clearTimeout(idleTimer)
      window.removeEventListener('mousemove', resetIdleTimer)
      window.removeEventListener('keypress', resetIdleTimer)
      window.removeEventListener('touchstart', resetIdleTimer)
    }
  }, [])

  useEffect(() => {
    let logoutTimer
    let countdownTimer

    if (isUserIdle && accessToken) {
      analytics.postAnalyticsEvent({
        category: `idle_modal`,
        action: `view_idle_modal`,
        label: `View`,
      })

      setCountdown(AUTO_LOGOUT_TIME_LIMIT)
      logoutTimer = setTimeout(() => {
        handleLogOut()
        setSessionExpired(true)
      }, AUTO_LOGOUT_TIME_LIMIT)

      countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 0) {
            clearInterval(countdownTimer)
            return 0
          }
          return prevCountdown - 1000
        })
      }, 1000)
    }

    return () => {
      clearTimeout(logoutTimer)
      clearInterval(countdownTimer)
    }
  }, [isUserIdle, accessToken])

  const handleStaySignedIn = () => {
    analytics.postAnalyticsEvent({
      category: `idle_modal`,
      action: `idle_modal_click_stay_signed_in`,
      label: `Button`,
    })
    setIsUserIdle(false)
  }

  const handleLogOut = () => {
    dispatch(setLoading(true))
    window.sessionStorage.setItem('visitedRecs', 'false')
    if (process.env.REACT_APP_ENV_NAME !== 'DEVELOPMENT') {
      auth.authService.logout(true).then(() => {
        dispatch(setLoginError(''))
        dispatch(clearRequestedRoute())
        dispatch(authLogout())
        window.scrollTo(0, 0)
        dispatch(setLoading(false))
      })
    } else {
      dispatch(authLogout())
      window.scrollTo(0, 0)
      dispatch(setLoading(false))
    }
    navigate('/')
    window.localStorage.removeItem('pkce')
    window.localStorage.removeItem('auth')
    window.localStorage.removeItem('tempUrl')
  }

  return (
    <>
      <ContentModal isOpen={isUserIdle && accessToken !== undefined} onToggle={handleStaySignedIn}>
        <div style={{ padding: 15 }}>
          <Typography styles={{ marginBottom: 15 }}>
            We've noticed you've been inactive for the last 10 minutes. For your security, we will log you out automatically in
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              {Math.floor(countdown / 60000)}:{('0' + Math.floor((countdown % 60000) / 1000)).slice(-2)}
            </span>
            .
          </Typography>
          <ResponsiveContainer center>
            <Button text="Stay signed in" onClick={handleStaySignedIn} styles={{ margin: 5 }} />
            <Button
              text="Log out"
              onClick={() => {
                analytics.postAnalyticsEvent({
                  category: `idle_modal`,
                  action: `idle_modal_user_timeout`,
                  label: `Button`,
                })
                handleLogOut()
              }}
              styles={{ margin: 5 }}
            />
          </ResponsiveContainer>
        </div>
      </ContentModal>
      <ContentModal isOpen={sessionExpired} onToggle={() => setSessionExpired(false)}>
        <div style={{ padding: 15 }}>
          <Typography styles={{ marginBottom: 15, textAlign: 'center' }}>
            To maintain the safety of your data, your session has ended after a period of inactivity. Please log in again to access your account.
          </Typography>
          <div onClick={() => setSessionExpired(false)} style={{ cursor: 'pointer' }}>
            <Icon name="times" styles={{ position: 'absolute', top: 15, right: 15 }} />
          </div>
        </div>
      </ContentModal>
    </>
  )
}

export default IdleTimer
