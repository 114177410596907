import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import { Formik, FormikProps, useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useMessagingCenter from 'hooks/useMessagingCenter'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { Category, PatientMessage, ReplyMessageValues } from 'types/MessagingCenter'

interface Props {
  isMobile: boolean
  currentMessage: PatientMessage
  messageCategories: Category[]
  refreshMessagesUponSend: (number: number) => void
  handleArchiveClick: (PIMessageId: number) => void
  replyBool: boolean
  setReplyBool: (bool: boolean) => void
  attachmentDropOpen: boolean
  setAttachmentDropOpen: (bool: boolean) => void
  postErrorBool: boolean
  setPostErrorBool: (bool: boolean) => void
}

type InputField = {
  type: string
  fieldName: string
  width: string
}
export default function NewMessage({
  messageCategories,
  currentMessage,
  handleArchiveClick,
  refreshMessagesUponSend,
  replyBool,
  setReplyBool,
  isMobile,
  attachmentDropOpen,
  setAttachmentDropOpen,
  setPostErrorBool,
  postErrorBool,
}: Props) {
  const [errorBool, setErrorBool] = useState<boolean>(false)
  const [comments, setComments] = useState<string>('')

  const token = useAppSelector(getAccessToken)
  const analytics = useAnalytics(token!)

  const onDrop = useCallback((files: File[]) => {
    const tempFiles = formikRef.current?.values.documents
    files.map((file) => {
      tempFiles?.push(file)
    })
    formikRef.current?.setFieldValue('documents', tempFiles)
    formik.setFieldValue('documents', tempFiles)
  }, [])

  const removeFile = (file: File) => {
    const files = formikRef.current?.values.documents.filter((x) => x.name !== file.name)
    formikRef.current?.setFieldValue('documents', files)
    formik.setFieldValue('documents', files)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const { postPatientReply } = useMessagingCenter(accessToken!)

  const formikRef = useRef<FormikProps<ReplyMessageValues>>(null)

  const inputFields: InputField[] = [{ type: 'string', fieldName: 'messageBody', width: '90%' }]
  useEffect(() => {
    scrollDown()
    formik.setFieldValue('messageBody', '')
    formik.setFieldValue('patientInitiatedMessagePId', currentMessage?.patientInitiatedMessagePId)
    formikRef.current?.setFieldValue('documents', [])
    formik.setFieldValue('documents', [])
  }, [replyBool])

  const onSubmit = (links) => {
    //
  }

  const initialValues = {
    patientInitiatedMessagePId: currentMessage?.patientInitiatedMessagePId ?? 0,
    messageBody: '',
    documents: [],
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const handleReplySendClick = () => {
    if (inputRef.current?.value === '') {
      setErrorBool(true)
    } else {
      setErrorBool(false)
      dispatch(setLoading(true))
      setReplyBool(false)
      postPatientReply(formik.values).then((res) => {
        if (res && res.data.isFailed) {
          setComments(res.data.reasons[0].message)
          setReplyBool(true)
          setPostErrorBool(true)
          dispatch(setLoading(false))
        } else {
          refreshMessagesUponSend(1)
        }
      })
      formik.values.messageBody = ''
      setAttachmentDropOpen(false)
    }
  }
  useEffect(() => {
    if (inputRef.current?.value !== '') {
      setErrorBool(false)
    }
  }, [inputRef.current?.value])

  const conversation = useRef<null | HTMLDivElement>(null)

  const scrollDown = () => {
    conversation.current && (conversation.current.scrollTop = conversation.current.scrollHeight)
  }
  useEffect(() => {
    scrollDown()
  }, [currentMessage])

  const handleReplyOpen = () => {
    setReplyBool(true)
    analytics.postAnalyticsPageView(`/messaging/inbox/reply`)
  }

  return (
    <StyledCurrentMessageCardContainer>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={(values) => console.log(values)}>
        {({ values }) => {
          return (
            <div>
              <StyledConversationContainer ref={conversation}>
                <div
                  style={{
                    borderBottom: '1px solid #bdbdbd',
                    width: '98%',
                    height: 'fit-content',
                    padding: 10,
                    margin: 'auto',
                    backgroundColor: theme.colors.primary1,
                    position: 'sticky',
                    top: 0,
                    borderTop: '10px solid white',
                  }}
                >
                  <Row>
                    <Typography color="white" size={20} variant="h3">
                      {`Topic: ${
                        messageCategories.find((cat) => cat.patientInitiatedMessageCategoryPId === currentMessage?.patientInitiatedMessageCategoryPId)
                          ?.categoryName
                      }`}
                    </Typography>
                    <div style={{ marginLeft: 'auto', marginTop: 7 }}>
                      {replyBool ? (
                        <div onClick={() => setAttachmentDropOpen(!attachmentDropOpen)}>
                          <Icon name="attachment" color="white" styles={{ marginRight: 15, cursor: 'pointer' }} size="2xl" />
                        </div>
                      ) : (
                        <Row>
                          <div onClick={() => handleArchiveClick(currentMessage.patientInitiatedMessagePId)}>
                            <Icon name="archive" color="white" styles={{ marginRight: 15, cursor: 'pointer' }} size="2xl" />
                          </div>
                          <div onClick={handleReplyOpen}>
                            <Icon name="reply" color="white" styles={{ marginRight: 10, cursor: 'pointer' }} size="2xl" />
                          </div>
                        </Row>
                      )}
                    </div>
                  </Row>
                  {currentMessage && (
                    <Typography color="white" size={18} variant="h3">
                      {`${dayjs(currentMessage?.dateOpened).format('M/D/YYYY')}`}
                    </Typography>
                  )}
                </div>

                {currentMessage &&
                  currentMessage.conversations.map((message) => (
                    <div
                      style={{ borderBottom: '1px solid #bdbdbd', width: '98%', padding: 10, margin: 'auto' }}
                      className="careplan-detail-document-item"
                    >
                      <Row
                        styles={{
                          textAlign: 'left',
                          width: '100%',
                          color: theme.colors.primary1,
                          marginBottom: 10,
                        }}
                      >
                        <Typography variant="h3" size={18}>
                          {`${dayjs(message.messageDate).format('M/D/YYYY')}`}
                        </Typography>
                      </Row>
                      <Row
                        styles={{
                          width: '99%',
                          margin: 'auto',
                          cursor: 'pointer',
                          marginBottom: 10,
                        }}
                      >
                        <Row>
                          <Column>
                            <Row
                              styles={{
                                textAlign: 'left',
                                width: '100%',
                                marginBottom: 15,
                              }}
                            >
                              <Typography size={18}>{`${message.body}`}</Typography>
                            </Row>
                            <Row
                              styles={{
                                textAlign: 'left',
                                width: '100%',
                                marginBottom: message.userTitle ? 5 : 0,
                              }}
                            >
                              <Typography variant="h3" font="bold" size={20}>{`${message.username}`}</Typography>
                            </Row>
                            {message.userTitle && (
                              <Row
                                styles={{
                                  textAlign: 'left',
                                  width: '100%',
                                }}
                              >
                                <Typography size={22}>{`${message.userTitle}`}</Typography>
                              </Row>
                            )}
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  ))}

                <div>
                  {errorBool && (
                    <Typography color="red" styles={{ marginLeft: 40, marginTop: 10 }}>
                      *Message Required
                    </Typography>
                  )}
                  {postErrorBool && (
                    <Typography color="red" styles={{ marginLeft: 40, marginTop: 10 }}>
                      {comments ? comments : 'There was an error. Please try again'}
                    </Typography>
                  )}
                  {replyBool &&
                    inputFields.map((field, index) => {
                      return (
                        <Column key={index} styles={{ marginRight: 'auto' }}>
                          <Form onSubmit={() => handleReplySendClick()}>
                            <StyledFormInput
                              //type={field.type}
                              ref={inputRef}
                              style={{ width: field.width, textAlign: 'left' }}
                              onChange={(value) => formik.setFieldValue(field.fieldName, value.currentTarget.value)}
                              value={formik.values[field.fieldName]}
                              placeholder={'Please type your message here:'}
                            />
                          </Form>
                        </Column>
                      )
                    })}
                </div>
                <div style={{ display: 'flex', marginLeft: '2%', marginTop: 15 }}>
                  {currentMessage && currentMessage.attachments.length > 0 && (
                    <Column>
                      <Row>
                        <Icon name="attachment" size="2x" color={theme.colors.primary1} />
                        <Typography variant="h2" styles={{ marginLeft: 5 }} color={theme.colors.primary1}>
                          Attachments:
                        </Typography>
                      </Row>
                      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginLeft: 30, marginRight: 'auto' }}>
                        {currentMessage.attachments.map((attachment, index) => {
                          return (
                            <div key={index} style={{ marginRight: 15, marginBottom: 10 }}>
                              <a href={attachment.attachmentPath} target="_blank" rel="noreferrer">
                                {/* <img src={attachment.attachmentPath} alt='message attachment' style={{display: 'flex', width: 80, height: 60, marginLeft: 'auto', marginRight: 'auto'}}/> */}
                                <Row styles={{ display: 'flex', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
                                  <Typography styles={{ textDecoration: 'underline' }}>{attachment.filePath}</Typography>
                                </Row>
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    </Column>
                  )}
                </div>
              </StyledConversationContainer>
              <Row>
                {replyBool ? (
                  <Row styles={{ width: '100%' }}>
                    {attachmentDropOpen && (
                      <div
                        style={{
                          marginRight: 'auto',
                          marginLeft: isMobile ? 0 : '-30%',
                        }}
                      >
                        <Column>
                          <div
                            style={{
                              display: 'flex',
                              marginRight: 'auto',
                              flexDirection: 'column',
                              border: '1px dashed black',
                              textAlign: 'center',
                              alignItems: 'center',
                              padding: '2% 10%',
                              cursor: 'pointer',
                            }}
                            {...getRootProps()}
                          >
                            <Icon name="cloud-upload" size={'3x'} color="#00677F" />
                            <input {...getInputProps()} />
                            <Typography styles={{ marginBottom: 5 }} font="semi-bold">
                              {` ${
                                isMobile
                                  ? `Tap to select files`
                                  : `Select
                            your file(s) or drag and drop here`
                              }`}
                            </Typography>
                            <Typography>JPEG, PNG, PDF or ZIP</Typography>
                          </div>
                          {values.documents.map((file, index) => (
                            <Row styles={{ marginTop: 10, alignItems: 'center' }} key={index}>
                              <Typography styles={{ marginRight: 5 }}>{file.name}</Typography>
                              <div onClick={() => removeFile(file)} style={{ cursor: 'pointer' }}>
                                <Icon name="times" />
                              </div>
                            </Row>
                          ))}
                        </Column>
                      </div>
                    )}
                    <Button
                      text="Send"
                      backgroundColor={theme.colors.darkGray}
                      rightIcon="send"
                      styles={{ marginTop: 30, marginLeft: 'auto', height: 40, marginBottom: 40 }}
                      onClick={() => handleReplySendClick()}
                    />
                  </Row>
                ) : (
                  <Button
                    text="Reply"
                    backgroundColor={theme.colors.darkGray}
                    leftIcon="reply"
                    styles={{ marginTop: 30, marginLeft: 'auto', marginBottom: 40 }}
                    onClick={handleReplyOpen}
                  />
                )}
              </Row>
            </div>
          )
        }}
      </Formik>
    </StyledCurrentMessageCardContainer>
  )
}

const StyledCurrentMessageCardContainer = styled.div`
  @media only screen and (min-width: 1000px) {
    flex-direction: column;
    margin-left: 51%;
    margin-top: -520px;
  }
`

const StyledConversationContainer = styled.div`
  scroll-behavior: smooth;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  margin-top: -10%;
  overflow-y: scroll;
  @media only screen and (min-width: 1000px) {
    height: 520px;
    width: 130%;
    margin-left: -30%;
  }

  @media only screen and (max-width: 1000px) {
    height: 570px;
    width: 100%;
    margin-top: 5%;
  }
`
const StyledInput = styled.textarea`
  border-radius: 3px;
  display: block;
  outline: none !important;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`
const StyledFormInput = styled(StyledInput)`
  background-color: #b8dde1;
  box-shadow: inset 0px 1px 3px;
  min-height: 190px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
`
