import Button from 'Components/Base/Button'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledErrorText } from 'Styles/global'
import { FormikErrors, getIn, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactSlider from 'react-slider'
import styled from 'styled-components'
import { CTLLifestyleFormValues } from 'types/Appointment'
import * as Yup from 'yup'

interface Props {
  errorMessage?: string
  loading?: boolean
  tempFormValues?: CTLLifestyleFormValues
  setTempCTLLifestyleInfo: (demographics: CTLLifestyleFormValues) => void
  saveLifestyleForm: (demographics: CTLLifestyleFormValues) => void
  onBackPressed: () => void
}

export default function CTLLifestyleForm({
  errorMessage,
  saveLifestyleForm,
  onBackPressed,
  tempFormValues,
  setTempCTLLifestyleInfo,
  loading = false,
}: Props) {
  const [sliderValue, setSliderValue] = useState(0)

  function getStyles(fieldName: string, errors: FormikErrors<CTLLifestyleFormValues>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }
  useEffect(() => {
    if (tempFormValues?.readinessToChange) {
      switch (tempFormValues?.readinessToChange) {
        case 35:
          setSliderValue(1)
          break
        case 36:
          setSliderValue(2)
          break
        case 37:
          setSliderValue(3)
          break
        case 38:
          setSliderValue(4)
          break
        case 39:
          setSliderValue(5)
          break
        case 40:
          setSliderValue(6)
          break
        case 41:
          setSliderValue(7)
          break
        case 42:
          setSliderValue(8)
          break
        case 43:
          setSliderValue(9)
          break
        case 44:
          setSliderValue(10)
          break

        default:
          break
      }
      tempFormValues?.readinessToChange && formik.setFieldValue('readinessToChange', tempFormValues?.readinessToChange)
    }
  }, [])

  const validationSchema = Yup.object().shape({
    reasonForChange: Yup.number().min(1).required('Activity is required'),
    readinessToChange: Yup.number().min(0).required('Activity is required'),
    dietVariety: Yup.number().min(1).required('Activity is required'),
    mealsSameTime: Yup.number().min(1).required('Activity is required'),
    cookingSkills: Yup.number().min(1).required('Activity is required'),
    dietStrategy: Yup.string(),
    livingSituation: Yup.number().min(1).required('Activity is required'),
    householdMembersTryingToLoseWeight: Yup.number().min(1).required('Activity is required'),
    timeForYourself: Yup.number().min(1).required('Activity is required'),
    obligations: Yup.number().min(1).required('Activity is required'),
    activityLevel: Yup.number().min(1).required('Activity is required'),
    carrierPId: Yup.number(),
  })

  const initialValues: CTLLifestyleFormValues = {
    reasonForChange: tempFormValues?.reasonForChange ?? undefined,
    readinessToChange: tempFormValues?.readinessToChange ?? 35,
    dietVariety: tempFormValues?.dietVariety ?? undefined,
    mealsSameTime: tempFormValues?.mealsSameTime ?? 17,
    cookingSkills: tempFormValues?.cookingSkills ?? undefined,
    dietStrategy: tempFormValues?.dietStrategy ?? undefined,
    livingSituation: tempFormValues?.livingSituation ?? undefined,
    householdMembersTryingToLoseWeight: tempFormValues?.householdMembersTryingToLoseWeight ?? 18,
    timeForYourself: tempFormValues?.timeForYourself ?? undefined,
    obligations: tempFormValues?.obligations ?? undefined,
    obligationsNote: tempFormValues?.obligationsNote ?? undefined,
    activityLevel: tempFormValues?.activityLevel ?? undefined,
    reasonForChangeNote: tempFormValues?.reasonForChangeNote ?? undefined,
    livingSituationNote: tempFormValues?.livingSituationNote ?? undefined,
  }

  const onSubmit = (userInfo: CTLLifestyleFormValues) => {
    saveLifestyleForm(userInfo)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const changeSlider = (value: number) => {
    let answerId = 35
    setSliderValue(value)

    switch (value) {
      case 1:
        answerId = 35
        break
      case 2:
        answerId = 36
        break
      case 3:
        answerId = 37
        break
      case 4:
        answerId = 38
        break
      case 5:
        answerId = 39
        break
      case 6:
        answerId = 40
        break
      case 7:
        answerId = 41
        break
      case 8:
        answerId = 42
        break
      case 9:
        answerId = 43
        break
      case 10:
        answerId = 44
        break

      default:
        break
    }

    formik.setFieldValue('readinessToChange', answerId)
  }

  const backPress = () => {
    onBackPressed()
    setTempCTLLifestyleInfo(formik.values)
  }

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 85,
      }}
    >
      <h1 className="id-header">Tell us a little about your lifestyle</h1>
      <div style={{ fontSize: '25px', marginBottom: 25 }}>No judgments here.</div>
      <Form>
        <Typography>How would you describe your activity level?</Typography>
        <StyledFormSet>
          <StyledFormInput
            as="select"
            value={formik.values.activityLevel}
            onChange={(e) => formik.setFieldValue('activityLevel', e.target.value)}
            style={{
              justifyContent: 'center',
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('activityLevel', formik.errors),
            }}
          >
            <option value={0} hidden>
              Activity Level
            </option>
            <option value={55}>Sedentary (little or no exercise, desk job)</option>
            <option value={56}>Lightly active (light exercise / sports 1-3 days/week)</option>

            <option value={57}>Moderately active (moderate exercise / sports 6-7 days/week)</option>
            <option value={58}>Very active (hard exercise every day, or moderate exercise 2 times/day)</option>
            <option value={90}>Extra active (hard exercise 2 or more times per day, or training for marathon, or triathlon, etc.)</option>
          </StyledFormInput>
        </StyledFormSet>
        <Typography styles={{ marginTop: 20 }}>Reason for desire to change weight?</Typography>
        <StyledFormSet>
          <StyledFormInput
            as="select"
            value={formik.values.reasonForChange}
            onChange={(e) => formik.setFieldValue('reasonForChange', e.target.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('reasonForChange', formik.errors),
            }}
          >
            <option value={0} hidden>
              Reason for desire
            </option>
            <option value={64}>I’m feeling ready today</option>
            <option value={65}>I’m unhappy with my weight</option>
            <option value={66}>I have some fresh motivation</option>
            <option value={67}>Something else</option>
          </StyledFormInput>
          {formik.values.reasonForChange && formik.values.reasonForChange == 67 && (
            <>
              <StyledSpacer />
              <StyledFormInput
                type="text"
                onChange={(value) => formik.setFieldValue('reasonForChangeNote', value.currentTarget.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('reasonForChangeNote', formik.errors),
                }}
                value={formik.values.reasonForChangeNote}
                placeholder="Other description"
              />
            </>
          )}
        </StyledFormSet>

        <div
          style={{
            border: '.5px solid #828282',
            padding: '40px',
            marginBottom: 30,
            position: 'relative',
            marginTop: 20,
          }}
        >
          <Row styles={{ marginBottom: '20px' }}>
            <Typography font="semi-bold">Please indicate your readiness to change. (1 = Not Ready at All / 10 = Extremely Ready)</Typography>
          </Row>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            value={sliderValue}
            min={0}
            max={10}
            onChange={(value: number) => changeSlider(value)}
            renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
          />
        </div>
        <Typography styles={{ marginTop: 20 }}>How would you describe the variety in your weekly diet?</Typography>
        <StyledFormInput
          as="select"
          value={formik.values.dietVariety}
          onChange={(e) => formik.setFieldValue('dietVariety', e.target.value)}
          style={{
            border: '2px solid transparent',
            borderRadius: 4,
            ...getStyles('dietVariety', formik.errors),
          }}
        >
          <option value={0} hidden>
            Describe your diet
          </option>
          <option value={68}>I mostly eat the same foods every day</option>
          <option value={69}>I rotate between a few different foods for each meal</option>
          <option value={70}>I eat some of the same foods, but there is plenty of variety</option>
          <option value={71}>My diet varies a lot from day to day</option>
        </StyledFormInput>
        <Typography styles={{ marginTop: 20 }}>Do you typically eat your meals around the same times each day?</Typography>
        <StyledFormInput
          as="select"
          value={formik.values.mealsSameTime}
          onChange={(e) => formik.setFieldValue('mealsSameTime', e.target.value)}
          style={{
            border: '2px solid transparent',
            borderRadius: 4,
            ...getStyles('mealsSameTime', formik.errors),
          }}
        >
          <option value={17}>Yes</option>
          <option value={18}>No</option>
        </StyledFormInput>
        <Typography styles={{ marginTop: 20 }}>How would you describe your cooking skills?</Typography>
        <StyledFormInput
          as="select"
          value={formik.values.cookingSkills}
          onChange={(e) => formik.setFieldValue('cookingSkills', e.target.value)}
          style={{
            border: '2px solid transparent',
            borderRadius: 4,
            ...getStyles('cookingSkills', formik.errors),
          }}
        >
          <option value={0} hidden>
            Describe your cooking skills
          </option>
          <option value={72}>I am a pretty good cook</option>
          <option value={73}>I do well enough</option>
          <option value={74}>I am learning</option>
          <option value={75}>I am not much of a cook</option>
        </StyledFormInput>
        <Typography styles={{ marginTop: 20 }}>Is there a diet strategy you are currently using?</Typography>

        <StyledFormInput
          type="text"
          onChange={(value) => formik.setFieldValue('dietStrategy', value.currentTarget.value)}
          style={{
            border: '2px solid transparent',
            borderRadius: 4,
            marginLeft: 'auto',
            marginRight: 'auto',
            ...getStyles('dietStrategy', formik.errors),
          }}
          value={formik.values.dietStrategy}
          placeholder="Low Carb, Vegetarian, Mediterranean, etc"
        />

        <Typography styles={{ marginTop: 20 }}>What best describes your living situation?</Typography>
        <StyledFormSet>
          <StyledFormInput
            as="select"
            value={formik.values.livingSituation}
            onChange={(e) => formik.setFieldValue('livingSituation', e.target.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,

              ...getStyles('livingSituation', formik.errors),
            }}
          >
            <option value={0} hidden>
              Describe your Living situation
            </option>
            <option value={76}>I live on my own</option>
            <option value={77}>I live with my family</option>
            <option value={78}>I live with a partner</option>
            <option value={79}>I live with roommates</option>
            <option value={80}>I live in shared housing</option>
            <option value={67}>Something else</option>
          </StyledFormInput>
          {formik.values.livingSituation == 67 && (
            <>
              <StyledSpacer />
              <StyledFormInput
                type="text"
                onChange={(value) => formik.setFieldValue('livingSituationNote', value.currentTarget.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('livingSituationNote', formik.errors),
                }}
                value={formik.values.livingSituationNote}
                placeholder="Describe your living situation"
              />
            </>
          )}
        </StyledFormSet>
        <Typography styles={{ marginTop: 20 }}>Are others in your household trying to lose weight?</Typography>
        <StyledFormInput
          as="select"
          value={formik.values.householdMembersTryingToLoseWeight}
          onChange={(e) => formik.setFieldValue('householdMembersTryingToLoseWeight', e.target.value)}
          style={{
            border: '2px solid transparent',
            borderRadius: 4,
            ...getStyles('householdMembersTryingToLoseWeight', formik.errors),
          }}
        >
          <option value={18}>No</option>
          <option value={81}>I'm not sure</option>
          <option value={17}>Yes</option>
        </StyledFormInput>
        <Typography styles={{ marginTop: 20 }}>How much time do you have for yourself in a typical week?</Typography>

        <StyledFormInput
          as="select"
          value={formik.values.timeForYourself}
          onChange={(e) => formik.setFieldValue('timeForYourself', e.target.value)}
          style={{
            border: '2px solid transparent',
            borderRadius: 4,
            ...getStyles('timeForYourself', formik.errors),
          }}
        >
          <option value={0} hidden>
            How much time do you have for yourself in a typical week?
          </option>
          <option value={82}>Nearly none</option>
          <option value={83}>A little bit here and there</option>
          <option value={84}>A good amount</option>
          <option value={85}>As much as I want</option>
        </StyledFormInput>
        <Typography styles={{ marginTop: 20 }}>What are your day-to-day obligations?</Typography>
        <StyledFormSet>
          <StyledFormInput
            as="select"
            value={formik.values.obligations}
            onChange={(e) => formik.setFieldValue('obligations', e.target.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('obligations', formik.errors),
            }}
          >
            <option value={0} hidden>
              What are your day-to-day obligations?
            </option>
            <option value={86}>I am a caregiver</option>
            <option value={87}>I have a full-time job</option>
            <option value={88}>I have a part-time job</option>
            <option value={89}>I am in school</option>
            <option value={67}>Something else</option>
          </StyledFormInput>
          {formik.values.obligations == 67 && (
            <>
              <StyledSpacer />
              <StyledFormInput
                type="text"
                onChange={(value) => formik.setFieldValue('obligationsNote', value.currentTarget.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('obligationsNote', formik.errors),
                }}
                value={formik.values.obligationsNote}
                placeholder="Describe your obligations"
              />
            </>
          )}
        </StyledFormSet>

        {!formik.isValid && <StyledErrorText>Please fix errors above</StyledErrorText>}
        {errorMessage && <StyledErrorText>{errorMessage}</StyledErrorText>}
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <StyledButtonContainer>
            <StyledButton pageName="appointment_ctl_demographics" text="Back" styles={{ backgroundColor: '#425563' }} onClick={backPress} />
            <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
            <StyledButton pageName="appointment_ctl_demographics" text="Next" loading={loading} onClick={formik.handleSubmit} />
          </StyledButtonContainer>
        </div>
      </Form>
    </div>
  )
}

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const StyledButton = styled(Button)`
  width: 25%;
  height: 40px;
  border-radius: 4px;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.colors.primary1};
  @media (max-width: 600px) {
    width: 100%;
  }
`
const StyledSpacer = styled.div`
  display: block;
  width: 20px;
  @media (max-width: 600px) {
    display: none;
  }
`

const StyledFormSet = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const StyledFormInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 45%;
  @media (max-width: 600px) {
    width: 100%;
  }
`
