import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React from 'react'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import styled from 'styled-components'
import { IconNames } from 'types/IconNames'
import Icon from '../Icon'

interface Props {
  number: number | undefined
  title: string
  icon: IconNames
  titleLine2: string
  route: string
  styles?: React.CSSProperties
  badgeNumber?: number
}
export default function CarePlanCard({ number, styles, title, route, titleLine2, icon, badgeNumber }: Props) {
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const navigate = useNavigate()
  const handleClick = () => {
    navigate(route)
    analytics.postAnalyticsEvent({
      category: `click_button_careplan_${icon === 'external-link' ? 'home' : 'medication_list'}_card_${title}`,
      action: `click_button_careplan_${icon === 'external-link' ? 'home' : 'medication_list'}_card_${title}`,
      label: `Button`,
      screenName: 'CarePlan',
    })
  }

  const pathname = window.location.pathname

  return (
    <StyledCardContainer style={{ ...styles }} onClick={() => handleClick()}>
      {badgeNumber && badgeNumber > 0 ? (
        <StyledBadge>
          <div style={{ marginBottom: 2, marginTop: -3, marginLeft: 'auto' }}></div>
        </StyledBadge>
      ) : (
        <></>
      )}
      <StyledCardColumn>
        <Icon name={icon} color="#828282" size="xl" styles={{ right: '2%', top: '-8%', position: 'absolute' }} />
        <text style={{ fontSize: 30, fontFamily: 'Raleway-Bold' }}>{number ? number : 0}</text>
        <div style={{ fontSize: 15, fontFamily: 'Raleway' }}>{title}</div>
        <div style={{ fontSize: 15, fontFamily: 'Raleway' }}>{titleLine2}</div>
      </StyledCardColumn>
    </StyledCardContainer>
  )
}

const StyledCardContainer = styled.div`
  cursor: pointer;
  height: 140px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  margin: auto;
  margin-top: 15px;

  position: relative;
  @media only screen and (min-width: 600px) {
    min-width: 30%;
  }

  @media only screen and (max-width: 600px) {
    min-width: 95%;
  }
`
const StyledCardColumn = styled.div`
  top: 50%;
  left: 50%;
  margin-right: 20px;
  position: relative;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
`
const StyledBadge = styled.div`
  height: 15px;
  min-width: 15px;
  width: fit-content;
  border-radius: 10px;
  background-color: #e40046;
  margin-left: auto;
  margin-right: -10px;
  margin-top: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
