import { DropdownQuestion, GAD7FormValues, PHQ9FormValues, Rapid3FormValues } from 'types/Forms'

export const phqQuestions: { question: string; fieldValue: keyof PHQ9FormValues }[] = [
  {
    question: '1. Little interest or pleasure in doing things?',
    fieldValue: 'littleInterest',
  },
  {
    question: '2. Feeling down, depressed, or hopeless?',
    fieldValue: 'feelingDown',
  },
  {
    question: '3. Trouble falling or staying asleep, or sleeping too much?',
    fieldValue: 'sleep',
  },
  {
    question: '4. Feeling tired or having little energy?',
    fieldValue: 'energy',
  },
  {
    question: '5. Poor appetite or overeating?',
    fieldValue: 'appetite',
  },
  {
    question: '6. Feeling bad about yourself - or that you are a failure or have let yourself or your family down?',
    fieldValue: 'failure',
  },
  {
    question: '7. Trouble concentrating on things, such as reading the newspaper or watching television?',
    fieldValue: 'concentration',
  },
  {
    question:
      '8. Moving or speaking so slowly that people could have noticed - or the opposite, being so fidgety or restless that you have been moving around a lot more than usual?',
    fieldValue: 'movingSpeaking',
  },
  {
    question: '9. Thoughts that you would be better off dead, or of hurting yourself?',
    fieldValue: 'selfHarm',
  },
]

export const rapid3Questions: { question: string; fieldValue: keyof Rapid3FormValues }[] = [
  {
    question: '1. Dress yourself, including tying shoelaces and doing buttons?',
    fieldValue: 'dressYourself',
  },
  {
    question: '2. Get in and out of bed?',
    fieldValue: 'inOutBed',
  },
  {
    question: '3. Lift a full cup or glass to your mouth?',
    fieldValue: 'liftCup',
  },
  {
    question: '4. Walk outdoors on flat ground?',
    fieldValue: 'walk',
  },
  {
    question: '5. Wash and dry your entire body?',
    fieldValue: 'washDry',
  },
  {
    question: '6. Bend down to pick up clothing from the floor?',
    fieldValue: 'bendDown',
  },
  {
    question: '7. Turn regular faucets on and off?',
    fieldValue: 'faucet',
  },
  {
    question: '8. Get in and out of a car, bus, train, or airplane?',
    fieldValue: 'inOutCar',
  },
  {
    question: '9. Walk two miles or three kilometers, if you wish?',
    fieldValue: 'walkTwoMiles',
  },
  {
    question: '10. Participate in recreational activities and sports as you would like, if you wish?',
    fieldValue: 'sports',
  },
]

export const gad7Questions: { question: string; fieldValue: keyof GAD7FormValues }[] = [
  {
    question: '1. Feeling nervous, anxious, or on edge?',
    fieldValue: 'feelNervous',
  },
  {
    question: '2. Not being able to stop or control worrying?',
    fieldValue: 'controlWorry',
  },
  {
    question: '3. Worrying too much about different things?',
    fieldValue: 'worryTooMuch',
  },
  {
    question: '4. Trouble relaxing?',
    fieldValue: 'troubleRelaxing',
  },
  {
    question: '5. Being so restless that it is hard to sit still?',
    fieldValue: 'restless',
  },
  {
    question: '6. Becoming easily annoyed or irritable?',
    fieldValue: 'easilyAnnoyed',
  },
  {
    question: '7. Feeling afraid, as if something awful might happen?',
    fieldValue: 'feelAfraid',
  },
]

export const sccaiDropdownQuestions: DropdownQuestion[] = [
  {
    label: 'How many bowel movements have you been passing during the daytime?',
    fieldName: 'dayBM',
    answers: [
      { value: '', label: 'Choose option' },
      { value: 0, label: '1-3 times' },
      { value: 1, label: '4-6 times' },
      { value: 2, label: '7-9 times' },
      { value: 3, label: '> 9 times' },
    ],
  },
  {
    label: 'How many bowel movements have you been passing during the night?',
    fieldName: 'nightBM',
    answers: [
      { value: '', label: 'Choose option' },
      { value: 0, label: 'None' },
      { value: 1, label: '1-3 times' },
      { value: 2, label: '4-6 times' },
    ],
  },
  {
    label: `What degree of urgency of defecation do you experience, i.e. when you feel the
    need to open your bowel, how quickly do you need to go?`,
    fieldName: 'urgency',
    answers: [
      { value: '', label: 'Choose option' },
      { value: 0, label: 'I have no urgency at all (i.e. just as normal)' },
      { value: 1, label: 'I have to hurry to the toilet' },
      { value: 2, label: 'I have to go immediately' },
      { value: 3, label: 'I have incontinence (unable to control the urge and had an accident)' },
    ],
  },
  {
    label: `How much blood has been in your stool?`,
    fieldName: 'amtBlood',
    answers: [
      { value: '', label: 'Choose option' },
      { value: 0, label: 'None' },
      { value: 1, label: 'Trace (a hint or tiny amount)' },
      { value: 2, label: 'Moderate (occasional obvious/frank blood)' },
      { value: 3, label: 'Severe (Usually obvious/frank blood)' },
    ],
  },
  {
    label: `General well-being, how do you feel about your general health?`,
    fieldName: 'wellbeing',
    answers: [
      { value: '', label: 'Choose option' },
      { value: 0, label: 'Very well' },
      { value: 1, label: 'Slightly below par' },
      { value: 2, label: 'Poor' },
      { value: 3, label: 'Very Poor' },
      { value: 4, label: 'Terrible' },
    ],
  },
]
