import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'

export default function Introduction() {
  return (
    <div>
      <STOPHeader>Introduction to S.T.O.P.</STOPHeader>
      <p>
        <ScaledText size={30}>
          Quitting tobacco will have a positive impact on overall health for patients and overall health care cost. Quitting tobacco can be a
          challenging but rewarding process. Tria's S.T.O.P. (Stop Tobacco by Optimizing Pharmacists) offers support and education for quitting
          tobacco.
        </ScaledText>
      </p>
      <p>
        <ScaledText size={30}>
          This online educational module will consist of 3 separate activities that review the benefits of quitting tobacco and reducing the risk of
          chronic health conditions associated with tobacco use.
        </ScaledText>
      </p>
      <ol>
        <ScaledText size={30}>1. Health Benefits of Quitting Tobacco</ScaledText>
        <ScaledText size={30}>2. Overcoming the Obstacles</ScaledText>
        <ScaledText size={30}>3. Medication Options & Benefits</ScaledText>
      </ol>
    </div>
  )
}
