import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { ReactNode } from 'react'

interface Props {
  isMobile: boolean | undefined
  textSection: ReactNode
  imgSrc: string
  title: string
}
export default function Template2({ isMobile, textSection, imgSrc, title }: Props) {
  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : '0px 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h1">{title}</Typography>
        </Row>
        {textSection}
      </Column>
      <Column styles={{ width: '100%', margin: 'auto 0px' }}>
        <img src={imgSrc} alt="scale" style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto' }} />
      </Column>
    </ResponsiveContainer>
  )
}
