import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useMessagingCenter from 'hooks/useMessagingCenter'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { Category, HelpText, MessageType, TriaMessage } from 'types/MessagingCenter'
import { APPSTORE_URL, PLAYSTORE_URL } from '../../../constants'

interface Props {
  isMobile: boolean
  sideMenuToggled: () => void
  triaMessages: TriaMessage[]
  setNewMessageBool: (boolean) => void
  setCurrentType: (type: MessageType) => void
  messageCategories: Category[] | undefined
  setCurrentCategory: (type: Category) => void
  setTypeValue: (type: number) => void
  setCategoryValue: (type: number) => void
  setCurrentTypes: (types: MessageType[]) => void
  setCurrentHelpText: (helpText: HelpText[]) => void
  setTriaMessages: (messages: TriaMessage[]) => void
  setReplyToTriaMessageId: (number: number | undefined) => void
}
export default function MessagingHomeScreen({
  isMobile,
  sideMenuToggled,
  triaMessages,
  setNewMessageBool,
  setCurrentType,
  messageCategories,
  setTypeValue,
  setCurrentCategory,
  setCategoryValue,
  setCurrentTypes,
  setCurrentHelpText,
  setTriaMessages,
  setReplyToTriaMessageId,
}: Props) {
  const navigate = useNavigate()

  const [selectedMessageArray, setSelectedMessageArray] = useState<number[]>([])
  const [added, setAdded] = useState(new Array(triaMessages.filter((m) => m.archived === false).length).fill(false))
  const [messages, setMessages] = useState<TriaMessage[]>([])

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Messaging')

  const accessToken = useAppSelector(getAccessToken)
  const theme = useTheme()
  const dispatch = useDispatch()
  const { getTriaMessages, updateTriaMessage, viewTriaMessage } = useMessagingCenter(accessToken!)

  useEffect(() => {
    dispatch(setLoading(true))
    getTriaMessages().then((res) => {
      setMessages(res.data.triaInitiatedMessages.filter((m) => m.archivedDate === null))
      setAdded(new Array(res.data.triaInitiatedMessages.filter((m) => m.archived === false).length).fill(false))
      dispatch(setLoading(false))
    })
  }, [])

  const postArchivedMessage = (message: TriaMessage) => {
    updateTriaMessage(message.triaInitiatedMessagePId)
  }

  const onSelectMessage = (position) => {
    const updatedAdded = added.map((item, index) => (index === position ? !item : item))
    setAdded(updatedAdded)
    if (added[position] === false) {
      if (selectedMessageArray.includes(position)) {
        const index = selectedMessageArray.indexOf(position)
        selectedMessageArray.splice(index, 1)

        setSelectedMessageArray(selectedMessageArray)
      } else {
        setSelectedMessageArray([...selectedMessageArray, position])
      }
    } else {
      const index = selectedMessageArray.indexOf(position)
      selectedMessageArray.splice(index, 1)

      setSelectedMessageArray(selectedMessageArray)
    }
  }

  const handleArchiveClick = () => {
    messages.map((message, index) => {
      if (selectedMessageArray.includes(index)) {
        postArchivedMessage(message)
      }
    })
    setMessages(messages.filter((message, index) => !selectedMessageArray.includes(index)))
    setSelectedMessageArray([])
    setAdded(new Array(messages.filter((m) => m.archived === false).length).fill(false))
  }

  const handleReplyClick = (replyType, triaMessageId) => {
    setReplyToTriaMessageId(triaMessageId)
    setCategoryValue(messageCategories!.findIndex((cat) => cat.messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType))!)
    setTypeValue(
      messageCategories!
        .find((cat) => cat.messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType))!
        .messageTypes.findIndex((type) => type.patientInitiatedMessageTypePId === replyType)!,
    )
    navigate('/messaging/inbox')
    setNewMessageBool(true)
    setCurrentCategory(messageCategories!.find((cat) => cat.messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType))!)
    setCurrentType(
      messageCategories!
        .find((cat) => cat.messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType))!
        .messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType)!,
    )
    setCurrentTypes(
      messageCategories!.find((cat) => cat.messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType))!.messageTypes,
    )
    setCurrentHelpText(
      messageCategories!
        .find((cat) => cat.messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType))!
        .messageTypes.find((type) => type.patientInitiatedMessageTypePId === replyType)!.helpTextList!,
    )
  }

  const handleNewMessageClick = () => {
    setReplyToTriaMessageId(undefined)
    navigate('/messaging/inbox')
    setNewMessageBool(true)
    setCategoryValue(-1)
    setCurrentHelpText([])
  }

  const handleTriaMessageClick = (triaMessageId) => {
    const message = messages.find((message) => message.triaInitiatedMessagePId === triaMessageId)
    if (!message?.viewedDate) {
      dispatch(setLoading(true))
      viewTriaMessage(triaMessageId).then(() =>
        getTriaMessages().then((res) => {
          setMessages(res.data.triaInitiatedMessages.filter((m) => m.archivedDate === null))
          setTriaMessages(res.data.triaInitiatedMessages)
          !window.location.pathname.includes('/inbox') && navigate('')
          dispatch(setLoading(false))
        }),
      )
    }
  }

  const frequentlyUsedResources = [
    { name: 'Reimbursement Form', route: '/reimbursement/true' },
    { name: 'iGlucose Monitor Guides', route: '/resources/diabetes' },
    { name: 'iBloodPressure Monitor Guides', route: '/bpmp' },
    {
      name: 'Reward Cards FAQs',
      route: 'https://triacentral.blob.core.windows.net/dev-storage/resources/rewards/Tria%20Health%20Flyer_Rewards%20FAQ.pdf',
    },
  ]

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title="Messages Home" icon="home" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <StyledLeftSideOfPageContainer>
        <StyledTriaMessagesContainer>
          <Row
            styles={{
              borderBottom: '1px solid #bdbdbd',
              width: '95%',
              margin: 'auto',
              paddingTop: 15,
              position: 'sticky',
              top: 0,
              backgroundColor: '#FFFFFF',
            }}
          >
            <Typography color={theme.colors.primary1} size={25} variant="h3">
              New From Tria Health
            </Typography>
            {selectedMessageArray.length > 0 && (
              <div onClick={handleArchiveClick} style={{ marginLeft: 'auto' }}>
                <Icon name="archive" size="2x" styles={{ marginLeft: 'auto', cursor: 'pointer' }} color={theme.colors.primary1} />
              </div>
            )}
          </Row>
          <div>
            {messages.length > 0 ? (
              messages
                .filter((m) => m.archived === false)
                .map(
                  (row, index) =>
                    row && (
                      <div
                        key={index}
                        style={
                          selectedMessageArray.includes(index)
                            ? {
                                backgroundColor: 'rgba(0, 103, 127, 0.26)',
                                width: '93%',
                                margin: 'auto',
                              }
                            : index % 2 === 0
                            ? {
                                backgroundColor: '#ffffff',
                                width: '93%',
                                margin: 'auto',
                              }
                            : {
                                backgroundColor: '#f2f2f2',
                                width: '93%',
                                margin: 'auto',
                              }
                        }
                        className="careplan-detail-document-item"
                        onClick={() => handleTriaMessageClick(row.triaInitiatedMessagePId)}
                      >
                        <StyledRow>
                          <Checkbox
                            checked={added[index]}
                            onClick={() => onSelectMessage(index)}
                            styles={{ marginRight: 10, height: 20, width: 20, cursor: 'pointer' }}
                          ></Checkbox>
                          <Row>
                            <Column>
                              <Row
                                styles={{
                                  textAlign: 'left',
                                  width: '100%',
                                }}
                              >
                                <Typography variant="h3" size={15}>{`${row?.messageType}`}</Typography>
                              </Row>
                              <Row
                                styles={{
                                  textAlign: 'left',
                                  width: '100%',
                                  paddingRight: 20,
                                }}
                              >
                                <Column>
                                  {row.body.split('|').map((text) => {
                                    if (text.startsWith('-')) {
                                      return (
                                        <Row styles={{ marginLeft: 20 }}>
                                          <li style={{ fontFamily: 'Raleway' }}>{text.slice(1, text.length)}</li>
                                        </Row>
                                      )
                                    } else {
                                      return (
                                        <Row>
                                          <Typography>{text}</Typography>
                                        </Row>
                                      )
                                    }
                                  })}
                                </Column>
                              </Row>
                            </Column>
                          </Row>
                          <Column styles={{ marginLeft: 'auto', marginTop: -7 }}>
                            {!row.viewedDate && (
                              <StyledBadge>
                                <div style={{ marginBottom: 2 }}></div>
                              </StyledBadge>
                            )}
                            <div style={{ marginBottom: 4 }}>
                              <Typography>{`${dayjs(row?.messageDate).format('M/D/YYYY')}`}</Typography>
                            </div>
                            <Row>
                              {row.allowReply && (
                                <div
                                  onClick={() => handleReplyClick(row.replyMessageTypePId, row.triaInitiatedMessagePId)}
                                  style={{ marginLeft: 'auto' }}
                                >
                                  <Icon name="reply" color="#470A68" styles={{ cursor: 'pointer' }} />
                                </div>
                              )}
                              {row.portalUrl && (
                                <div onClick={() => navigate(row.portalUrl!)} style={{ marginLeft: 'auto' }}>
                                  <Icon name="external-link" color="#828282" styles={{ cursor: 'pointer' }} />
                                </div>
                              )}
                              {row.url && (
                                <a
                                  href={row.url.startsWith('http') ? row.url : `https://${row.url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ marginLeft: 'auto' }}
                                >
                                  <Icon name="external-link" color="#828282" styles={{ cursor: 'pointer' }} />
                                </a>
                              )}
                            </Row>
                          </Column>
                        </StyledRow>
                      </div>
                    ),
                )
            ) : (
              <div style={{ margin: 'auto', width: '90%' }}>
                <Typography font="bold" styles={{ marginTop: 20, marginBottom: 20 }}>
                  You have no new messages from Tria Health.{' '}
                </Typography>
                <Typography styles={{ marginTop: 20, marginBottom: 20 }}>
                  If you're looking for new content, we encourage you to check out our blog. Our blog is regularly updated with informative articles
                  on a wide range of health topics, including tips for managing chronic conditions, the latest health news, and more.{' '}
                </Typography>
                <Row>
                  <Typography styles={{ marginTop: 20, marginBottom: 20 }}>To visit our blog, </Typography>
                  <Typography styles={{ marginTop: 20, marginBottom: 20, marginLeft: 4, textDecoration: 'underline' }}>
                    <a href="https://www.triahealth.com/blog" target="_blank" rel="noreferrer">
                      click here.
                    </a>
                  </Typography>
                </Row>
              </div>
            )}
          </div>
        </StyledTriaMessagesContainer>
      </StyledLeftSideOfPageContainer>
      <StyledRightSideOfPageContainer>
        <Button
          text="New Message"
          backgroundColor={theme.colors.charts.inRange}
          rightIcon="plus"
          onClick={() => handleNewMessageClick()}
          styles={isMobile ? { marginTop: 20 } : {}}
        />
        <StyledFrequentResourcesContainer>
          <div style={{ borderBottom: '1px solid #bdbdbd', width: '90%', margin: 'auto' }}>
            <Typography color={theme.colors.primary1} size={25} variant="h3">
              Frequently Used Resources
            </Typography>
          </div>
          {frequentlyUsedResources.length > 0 &&
            frequentlyUsedResources.map(
              (row, index) =>
                row && (
                  <div
                    key={index}
                    style={
                      index % 2 === 0
                        ? {
                            backgroundColor: '#ffffff',
                            width: '93%',
                            margin: 'auto',
                            padding: '10px',
                          }
                        : {
                            backgroundColor: '#f2f2f2',
                            width: '93%',
                            margin: 'auto',
                            padding: '10px',
                          }
                    }
                    className="careplan-detail-document-item"
                  >
                    <Row
                      onClick={() => window.open(row.route, '_blank')}
                      styles={{
                        width: '99%',
                        margin: 'auto',
                        cursor: 'pointer',
                      }}
                    >
                      <Row>
                        <Column>
                          <Row
                            styles={{
                              textAlign: 'left',
                              width: '100%',
                            }}
                          >
                            <Typography variant="h3" size={18}>{`${row?.name}`}</Typography>
                          </Row>
                        </Column>
                      </Row>
                      <Column styles={{ marginLeft: 'auto', marginTop: 4 }}>
                        <Icon name="chevron-right" />
                      </Column>
                    </Row>
                  </div>
                ),
            )}
        </StyledFrequentResourcesContainer>
        <StyledDownloadMobileAppContainer>
          <Row>
            {!isMobile && (
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Hands%20Holding%20Phone.png"
                alt="profile"
                style={{
                  width: '140px',
                  height: '110px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  border: '1px black solid',
                }}
              />
            )}
            <Column styles={isMobile ? { marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' } : { marginLeft: '20px' }}>
              <Typography variant="h3" styles={{ marginTop: '6px' }}>
                Download Our Mobile App!
              </Typography>
              <Typography size={14}>Access Tria Health on-the-go!</Typography>
              <Typography size={14}>You’ll be able to view your care</Typography>
              <Typography size={14}>plan, medication list and more!</Typography>
              <Row>
                <Icon
                  name={'play'}
                  link={PLAYSTORE_URL}
                  className="social-icon"
                  color="#ffffff"
                  size="1x"
                  styles={{
                    cursor: 'pointer',
                    backgroundColor: '#000000',
                    borderRadius: 5,
                    padding: 3.5,
                    marginTop: 1,
                  }}
                />
                <Icon
                  name={'ios'}
                  link={APPSTORE_URL}
                  className="social-icon"
                  color="#000000"
                  size="xl"
                  styles={{
                    cursor: 'pointer',
                    marginLeft: 15,
                    backgroundColor: '#ffffff',
                  }}
                />
              </Row>
            </Column>
          </Row>
        </StyledDownloadMobileAppContainer>
      </StyledRightSideOfPageContainer>
    </>
  )
}
const StyledLeftSideOfPageContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    display: flex;
    flex-direction: row;
  }
`
const StyledTriaMessagesContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  overflow-y: scroll;

  @media only screen and (min-width: 1100px) {
    height: 520px;
    width: 47%;
  }

  @media only screen and (max-width: 1100px) {
    height: 370px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`
const StyledFrequentResourcesContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  padding-top: 15px;
  margin-top: 50px;
  @media only screen and (min-width: 1100px) {
    min-height: 250px;
    max-height: fit-content;
    width: 96.5%;
  }

  @media only screen and (max-width: 1100px) {
    min-height: 295px;
    width: 100%;
    max-height: fit-content;
    margin-top: 5%;
  }
`
const StyledDownloadMobileAppContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  @media only screen and (min-width: 1100px) {
    min-height: 133px;
    max-height: fit-content;
    width: 96.5%;
    margin-top: 43px;
  }

  @media only screen and (max-width: 1100px) {
    min-height: 200px;
    max-height: fit-content;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`
const StyledRightSideOfPageContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    flex-direction: column;
    margin-left: 51%;
    margin-top: -520px;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  height: 100%;
  &:hover,
  &:focus {
    background-color: rgba(0, 103, 127, 0.26);
  }
`

const StyledBadge = styled.div`
  height: 10px;
  min-width: 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: -5px;
  border-radius: 10px;
  background-color: #e40046;
  display: flex;
  align-items: center;
  justify-content: center;
`
