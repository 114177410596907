import AutoReimbursement from 'Pages/ReimbursementPage/AutoReimbursement'
import React from 'react'
import AUASIForm from '../Pages/Forms/AUASIForm'
import GAD7Form from '../Pages/Forms/GAD7Form'
import HBIForm from '../Pages/Forms/HBI'
import PHQ9Form from '../Pages/Forms/PHQ9'
import Rapid3Form from '../Pages/Forms/Rapid3Form'
import SCCAIForm from '../Pages/Forms/SCCAIForm'
import ReimbursementPage from '../Pages/ReimbursementPage'
import ReimbursementSuccess from '../Pages/ReimbursementPage/ReimbursementSuccess'

const formRoutes = [
  { path: '/phq9/', component: <PHQ9Form /> },
  { path: '/phq9/:guid', component: <PHQ9Form /> },
  { path: '/hbi/', component: <HBIForm /> },
  { path: '/hbi/:guid', component: <HBIForm /> },
  { path: '/gad7', component: <GAD7Form /> },
  { path: '/gad7/:guid', component: <GAD7Form /> },
  { path: '/rapid3', component: <Rapid3Form /> },
  { path: '/rapid3/:guid', component: <Rapid3Form /> },
  { path: '/sccai', component: <SCCAIForm /> },
  { path: '/sccai/:guid', component: <SCCAIForm /> },
  { path: '/auasi', component: <AUASIForm /> },
  { path: '/auasi/:guid', component: <AUASIForm /> },
  { path: '/reimbursement', component: <ReimbursementPage /> },
  { path: '/reimbursement/:viewed', component: <ReimbursementPage /> },
  { path: '/reimbursement/auto', component: <AutoReimbursement /> },
  { path: '/reimbursement/success', component: <ReimbursementSuccess /> },
]

export default formRoutes
