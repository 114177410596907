import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import ListItem from 'Pages/STOPProgram/components/ListItem'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Keep Trying.png'

export default function Obstacle2() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Obstacle #2:</STOPHeader>
          <STOPHeader>Tried in the Past and Was Unsuccessful</STOPHeader>
          <Spacer top>
            <ul>
              <ListItem>
                <ScaledText>
                  Tobacco use and dependence is a chronic, relapsing medical condition that often requires repeated intervention and long-term
                  support.
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>Sometimes it takes 2+ quit attempts to be successful.</ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>It is never too late to quit tobacco. Quitting is beneficial at any age.</ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  With guidance from a health care professional quitting tobacco can become more manageable and success can be achieved and
                  maintained.
                </ScaledText>
              </ListItem>
            </ul>
          </Spacer>
        </>
      }
      image={BenefitsImage}
      altTag="Street leading to sunset"
    />
  )
}
