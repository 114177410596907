import SidebarNav from 'Components/SidebarNav'
import { useAppSelector } from 'hooks/hooks'
import React, { useEffect, useState } from 'react'
import 'react-pro-sidebar/dist/css/styles.css'
import { useLocation, useNavigate } from 'react-router'
import { getProfile } from 'reducers/profileSlice'
import { SidebarMenuHeader, SidebarMenuItemComponents } from 'types/SidebarNav'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import './PatientProfile.scss'
import Allergies from './components/Allergies'
import CaregiverAccess from './components/CaregiverAccess'
import Challenges from './components/Challenges'
import ProfileCommunicationPreferences from './components/CommunicationPreferences'
import PasswordReset from './components/PasswordReset'
import Physicians from './components/Physicians'
import ProfileHome from './components/ProfileHome'

export default function PatientProfile() {
  const location = useLocation()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Profile')

  const route = location.pathname

  const [showMenu, setShowMenu] = useState(true)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showProfileContent, setShowProfileContent] = useState(false)
  const [toggled, setToggled] = useState(false)
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const navigate = useNavigate()
  const profile = useAppSelector(getProfile)

  const setMobileSetting = () => {
    if (window.innerWidth < 900) {
      setIsMobile(true)
      setShowMobileMenu(true)
      setShowMenu(false)
      setShowProfileContent(true)
    } else {
      setIsMobile(false)
      setShowMobileMenu(false)
      setShowMenu(true)
      setShowProfileContent(true)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const backClicked = () => {
    setShowMobileMenu(true)
    setShowMenu(false)
    setShowProfileContent(false)
  }

  const itemClicked = (link: string) => {
    setToggled(false)
    navigate(link)
  }

  const sidebarMenuItems: SidebarMenuItemComponents[] = [
    {
      title: 'Profile Home',
      icon: 'home',
      route: '/profile',
      displayText: 'Profile Home',
    },
    {
      title: 'Physicians',
      icon: 'doctor',
      route: '/profile/physicians',
      displayText: 'Physicians',
    },
    {
      title: 'Allergies',
      icon: 'allergy',
      route: '/profile/allergies',
      displayText: 'Allergies',
    },
    {
      title: 'Scheduled Appointments',
      icon: 'calendar',
      route: '/appointment',
      displayText: 'Scheduled Appointments',
    },
    {
      title: 'Challenge Achievements',
      icon: 'badge',
      route: '/profile/challenges',
      displayText: 'Challenge Achievements',
    },
    {
      title: 'Communication Preferences',
      icon: 'comment',
      route: '/profile/preferences',
      displayText: 'Communication Preferences',
    },
    {
      title: 'Caregiver Access',
      icon: 'hand-heart',
      route: '/profile/access',
      displayText: 'Caregiver Access',
    },
    {
      title: profile?.twoFactorEnabled ? 'Manage Multi-Factor Authentication' : 'Enable Multi-Factor Authentication',
      icon: 'user-lock',
      link: `${process.env.REACT_APP_AUTH_MFA_URL}`,
      displayText: profile?.twoFactorEnabled ? 'Manage Multi-Factor Authentication' : 'Enable Multi-Factor Authentication',
    },
    {
      title: 'Password Reset',
      icon: 'lock',
      link: `${process.env.REACT_APP_AUTH_RESET_URL}`,
      displayText: 'Password Reset',
    },
  ]

  const sidebarMenuHeader: SidebarMenuHeader = { title: 'My Profile ', route: '/profile' }

  return (
    <>
      <div className="profile-page-container">
        <div className="profile-content-container">
          <SidebarNav
            sideMenuCollapsed={sideMenuCollapsed}
            isMobile={isMobile}
            toggled={toggled}
            itemClicked={(link) => itemClicked(link)}
            onCollapseMenu={() => {
              isMobile ? setToggled(false) : setSideMenuCollapsed(!sideMenuCollapsed)
            }}
            sidebarMenuItems={
              profile?.hasChallenges
                ? sidebarMenuItems
                : sidebarMenuItems.filter((item, index) => {
                    return index !== 4
                  })
            }
            sidebarMenuHeader={sidebarMenuHeader}
            height={'800px'}
          />

          <div className="profile-content">
            {route === '/profile' && (
              <>
                <ProfileHome isMobile={isMobile} backPressed={() => backClicked()} sideMenuToggled={() => setToggled(true)} />
              </>
            )}
            {route === '/profile/physicians' && (
              <Physicians isMobile={isMobile} backPressed={() => backClicked()} sideMenuToggled={() => setToggled(true)} />
            )}
            {route === '/profile/allergies' && (
              <Allergies isMobile={isMobile} backPressed={() => backClicked()} sideMenuToggled={() => setToggled(true)} />
            )}
            {route === '/profile/challenges' && (
              <Challenges isMobile={isMobile} backPressed={() => backClicked()} sideMenuToggled={() => setToggled(true)} />
            )}
            {route === '/profile/preferences' && (
              <ProfileCommunicationPreferences isMobile={isMobile} backPressed={() => backClicked()} sideMenuToggled={() => setToggled(true)} />
            )}
            {route === '/profile/access' && (
              <CaregiverAccess isMobile={isMobile} backPressed={() => backClicked()} sideMenuToggled={() => setToggled(true)} />
            )}
            {route === '/profile/password' && <PasswordReset isMobile={isMobile} backPressed={() => backClicked()} />}
          </div>
        </div>
        <div style={{ height: 10, width: '100%' }}></div>
      </div>
    </>
  )
}
