import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import { useStopContext } from 'Pages/ProgramScreen'
import { sizes } from 'Styles/sizes'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import usePrograms from 'hooks/usePrograms'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getAccessToken } from 'reducers/authSlice'
import { getProfile } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { PatientActivityValues, STOPActivity } from 'types/STOP'
import PartyPopper from '../../../Assets/Images/STOP/Party Popper 1.png'
import ProgressIcon from '../components/ProgressIcon'
import STOPHeader from '../components/STOPHeader'
import ScaledText from '../components/ScaledText'
import PDFContainer, { PDFActivitiesRow, PDFLibrary } from './components/PDFContainer'

type Props = {
  activityId: number
  desireToQuit?: boolean
}

export default function CompleteScreen({ activityId, desireToQuit = false }: Props) {
  const theme = useTheme()
  const profile = useAppSelector(getProfile)
  const dispatch = useDispatch()
  const [nextActivity, setNextActivity] = useState<PatientActivityValues>()
  const [lastActivity, setLastActivity] = useState<PatientActivityValues>()
  const [allActivitiesComplete, setAllActivitiesComplete] = useState<boolean>()

  const token = useAppSelector(getAccessToken)
  const { readyToQuit } = useStopContext()

  const { getSTOPStatus } = usePrograms(token!)

  useEffect(() => {
    dispatch(setLoading(true))

    getSTOPStatus()
      .then((res) => {
        setNextActivity(res.sort((a, b) => a.activityPId - b.activityPId).find((x) => x.completedDate === null))
        const lastActivity = res.find((x) => x.activityPId === 3)
        setLastActivity(lastActivity)
        if (lastActivity?.completedDate) {
          setAllActivitiesComplete(true)
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  const displayActivityId = activityId + 1
  const isLastActivity = activityId === STOPActivity.MedicationOptions

  return (
    <Column>
      <ResponsiveContainer styles={{ alignItems: 'center' }} center>
        <StyledImage src={PartyPopper} alt={'altTag'} />
        <ProgressIcon icon={'check'} color={theme.colors.charts.inRange} iconColor={theme.colors.white} size={50} iconSize="2xl" />
        <STOPHeader size={50} styles={{ textAlign: 'center' }}>{`Activity #${displayActivityId} Complete`}</STOPHeader>
        <StyledImage src={PartyPopper} alt={'altTag'} style={{ transform: 'scaleX(-1)' }} />
      </ResponsiveContainer>
      <StyledRow styles={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }} center>
        <ScaledText>
          {`You have completed activity ${displayActivityId} of 3 for the tobacco cessation program. ${
            isLastActivity
              ? 'We’ll send you an email confirming your program completion.'
              : `As soon as the next activity is available, it will be sent to the
          email address you provided.`
          }`}
        </ScaledText>
      </StyledRow>
      {isLastActivity && (
        <Spacer top bottom>
          <Row center>
            <a href={`/program/stop/certificate/${profile?.patientGuid}`} rel="noreferrer">
              <Button text="View My Certificate" styles={{ backgroundColor: '#00A9CE' }} />
            </a>
          </Row>
        </Spacer>
      )}
      {allActivitiesComplete && (
        <Row center>
          <ScaledText bold size={30}>
            Activity Library:
          </ScaledText>
        </Row>
      )}

      <ResponsiveContainer styles={{ justifyContent: 'space-between', marginTop: 15, width: '100%' }}>
        {!isLastActivity && nextActivity && (
          <>
            <StyledColumn>
              <Spacer bottom={10}>
                <ScaledText bold>The Next Activity Can be Accessed:</ScaledText>
              </Spacer>
              <StyledFormInput disabled value={dayjs(nextActivity.availableDate).format('MMMM D, YYYY | h:mma')} placeholder="" />
              <Spacer bottom={10}>
                <ScaledText>Don’t worry! We’ll send you an email when the next activity can be accessed!</ScaledText>
              </Spacer>
              <ScaledText>
                You’ll need to complete this activity by {dayjs(nextActivity.availableDate).add(7, 'day').format('MM/DD/YYYY')} to receive your
                completion certificate.
              </ScaledText>
            </StyledColumn>
            <PDFContainer activityId={activityId} />
          </>
        )}
        {!readyToQuit && allActivitiesComplete && <PDFActivitiesRow />}
        {readyToQuit && allActivitiesComplete && <PDFLibrary />}
      </ResponsiveContainer>
    </Column>
  )
}

const StyledImage = styled.img`
  display: none;
  @media (${sizes.tablet}) {
    display: block;
  }
`
const StyledRow = styled(Row)`
  width: 100%;
  @media (${sizes.tablet}) {
    width: 50%;
  }
`

const StyledColumn = styled(Column)`
  width: 100%;
  @media (${sizes.tablet}) {
    width: 45%;
  }
`

const StyledFormInput = styled(Form.Control)`
  background-color: #edfdff;
  border-radius: 3px;
  text-align: center;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
  font-size: 1.5rem;
`
