export const formatPatientText = (patientText: string | undefined) => {
  const array = patientText?.split('\n')
  const conditionName = array?.[0].toLowerCase()
  const sentences = array?.filter((sentence, index) => sentence.trim() !== '' && index !== 0)
  const overview = sentences?.[0] && sentences?.[0].replace(/- /g, '').trim()
  const listItems = sentences && sentences.filter((_sentence, index) => index !== 0).map((sentence) => sentence.replace(/- /g, '').trim())

  return {
    overview,
    listItems,
    condition: conditionName?.trim(),
  }
}
