import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import ListItem from 'Pages/STOPProgram/components/ListItem'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Without Medication.png'

export default function QuittingWithoutNRT() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Quitting Without Using NRT or Medications</STOPHeader>
          <ScaledText>
            Quitting tobacco without the use of medications is commonly known as quitting “cold turkey.” Some people prefer to quit cold turkey, while
            others prefer to slowly reduce the amount of tobacco use over several weeks. No matter which way you decide to quit, the support of quit
            programs and health care professionals is strongly recommended.
          </ScaledText>
          <ul>
            <ListItem>
              <ScaledText bold>Pros</ScaledText>
              <ul>
                <ListItem marginBottom="10">
                  <ScaledText>Low Cost</ScaledText>
                </ListItem>
                <ListItem marginBottom="10">
                  <ScaledText>No risk of medication side effects</ScaledText>
                </ListItem>
              </ul>
            </ListItem>
            <ListItem>
              <ScaledText bold>Cons</ScaledText>
              <ul>
                <ListItem marginBottom="10">
                  <ScaledText>Withdrawal symptoms can be worse without the use of a quit aid</ScaledText>
                </ListItem>
                <ListItem marginBottom="10">
                  <ScaledText>Urges and cravings for tobacco can be stronger and more frequent</ScaledText>
                </ListItem>
                <ListItem marginBottom="10">
                  <ScaledText>Smokers that smoke ½ pack a day or more are less likely to quit smoking and successfully stay tobacco free</ScaledText>
                </ListItem>
              </ul>
            </ListItem>
          </ul>
        </>
      }
      image={BenefitsImage}
      altTag="Man at a computer"
    />
  )
}
