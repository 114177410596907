
import React from 'react'

interface Props {
    stepPercent: number
    backgroundColor?: string
}
export default function ProgressBar({stepPercent, backgroundColor = '#FFF'}: Props) {

  return (
            <div style={{height: 20, width: 250,background: `linear-gradient(to right, #7A9A01 ${stepPercent}%, ${backgroundColor} ${stepPercent}% 100%)`, margin: '10px auto', borderRadius: 20, border: '1px solid black'}}></div>

  )
}

