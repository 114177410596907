import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import ReactPlayer from 'react-player'
import styled, { useTheme } from 'styled-components'
import { AdditionalLink, AdditionalLinkType } from 'types/AdditionalLink'
import '../../ManagementPages.scss'

interface Props {
  title: string
  textDetail1: string
  textDetail2: string
  videoUrl: string
  additionalLinks?: AdditionalLink[]
  descriptionClassName?: string
}
export default function ProgramInfo({ title, videoUrl, textDetail1, textDetail2, descriptionClassName, additionalLinks = [] }: Props) {
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(false)
  const [modalUrl, setModalUrl] = useState('')

  function openLink(url: string, type: AdditionalLinkType) {
    if (type === AdditionalLinkType.File) {
      window.open(url, '_blank')
    } else {
      setModalUrl(url)
      setIsOpen(true)
    }
  }

  return (
    <>
      <StyledPlayer url={videoUrl} controls={true} />
      <div className={descriptionClassName}>
        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" size={35}>
            {title}
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography>{textDetail1}</Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography>{textDetail2}</Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography>
            <>
              <span style={{ fontFamily: 'Raleway-Semi-Bold' }}>Please Note:</span> Any data that you choose to share with us will remain completely
              confidential. We will not share your data with your employer or anyone else.
            </>
          </Typography>
        </Row>
        {additionalLinks.length > 0 && (
          <Typography color={theme.colors.primary1} font="semi-bold" size={25}>
            Additional Resources
          </Typography>
        )}
        {additionalLinks.map((link) => (
          <div
            style={{
              cursor: 'pointer',
              marginBottom: 5,
              width: 'fit-content',
              display: 'flex',
            }}
            onClick={() => openLink(link.url, link.type)}
          >
            <Icon styles={{ marginRight: 5 }} name={link.type === AdditionalLinkType.File ? 'file-pdf' : 'video'} />
            <Typography>{link.title}</Typography>
          </div>
        ))}
        <ModalVideo channel="custom" isOpen={isOpen} url={modalUrl} onClose={() => setIsOpen(false)} />
      </div>
    </>
  )
}

const StyledPlayer = styled(ReactPlayer)`
  height: 250px;
  width: 100% !important;
  @media ${sizes.laptop} {
    height: 400px;
  }
  @media ${sizes.desktopL} {
    height: 800px;
  }
`
