import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[]
  marginBottom?: string
}

export default function ListItem({ children, marginBottom }: Props) {
  return <StyledItem marginBottom={marginBottom}>{children}</StyledItem>
}

const StyledItem = styled.li<{ marginBottom?: string }>`
  margin-bottom: ${(props) => props.marginBottom || '15px'};
`
