import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import usePatientIntake from 'hooks/usePatientIntake'
import React, { useEffect, useState } from 'react'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import BaseForm from '../BaseForm'
import OptionCard, { ButtonOption } from '../components/OptionCard'
import AllergiesCard from './AllergiesCard'

interface Props {
  backPressed: () => void
  onSubmit: (values: AllergyOption[], nextStep: boolean) => void

  error?: string
}

export type AllergyOption = {
  id: number
  medication: string
  allergic: boolean
  reaction: string
}
export default function AllergiesForm({ onSubmit, backPressed, error }: Props) {
  const [selectedOption, setSelectedOption] = useState<number | undefined>()
  const [selectedAllergies, setSelectedAllergies] = useState<AllergyOption[]>([
    { id: 0, medication: 'Penicillin', allergic: false, reaction: '' },
    { id: 1, medication: 'Sulfa', allergic: false, reaction: '' },
    { id: 2, medication: 'Codeine', allergic: false, reaction: '' },
    { id: 3, medication: 'Lisinopril', allergic: false, reaction: '' },
    { id: 4, medication: 'Statins', allergic: false, reaction: '' },
    { id: 5, medication: 'Bactrim', allergic: false, reaction: '' },
  ])
  const [otherClicked, setOtherClicked] = useState<boolean>(false)
  const [otherAllergies, setOtherAllergies] = useState<AllergyOption[]>([])

  const dispatch = useAppDispatch()

  const accessToken = useAppSelector(getAccessToken)
  const { getAllergyInfo } = usePatientIntake({
    token: accessToken,
  })

  useEffect(() => {
    dispatch(setLoading(true))
    async function getAllergyState() {
      const response = await getAllergyInfo()
      const data = await response.data

      if (data.allergies.length > 0) {
        data.allergies.find((a) => a.allergic) && setSelectedOption(0)
        data.allergies.map(
          (s: AllergyOption) =>
            selectedAllergies.find((a) => a.medication === s.medication) &&
            s.allergic &&
            toggleSelection(selectedAllergies.find((a) => a.medication === s.medication)!),
        )
        data.allergies.map((s: AllergyOption) => s.reaction.length > 0 && updateDescription(s, s.reaction))
      }
      if (data.other.length > 0) {
        setOtherClicked(true)
        const otherAllergiesTemp: AllergyOption[] = []
        data.other.map((other, index) => {
          otherAllergiesTemp.push({ id: index + 6, medication: 'Other', allergic: true, reaction: other.reaction })
        })
        setOtherAllergies(otherAllergiesTemp)
      }
    }
    getAllergyState().then(() => dispatch(setLoading(false)))
  }, [])

  const toggleSelection = (option: AllergyOption) => {
    if (option.medication !== 'Other') {
      const allergiesCopy = [...selectedAllergies]
      const optionIndex = selectedAllergies.findIndex((x) => x.id === option.id)
      allergiesCopy[optionIndex].allergic = !option.allergic
      setSelectedAllergies(allergiesCopy)
    } else {
      setOtherClicked(true)
    }
  }

  const updateDescription = (option: AllergyOption, reaction: string) => {
    if (option.medication !== 'Other') {
      const allergiesCopy = [...selectedAllergies]
      const optionIndex = selectedAllergies.findIndex((x) => x.medication === option.medication)
      allergiesCopy[optionIndex].reaction = reaction
      setSelectedAllergies(allergiesCopy)
    } else {
      const otherCopy = [...otherAllergies]
      const optionIndex = otherAllergies.findIndex((x) => x.id === option.id)
      otherCopy[optionIndex].reaction = reaction
      setOtherAllergies(otherCopy)
    }
  }

  const backPress = () => {
    onSubmit(
      selectedAllergies.concat(otherAllergies).filter((x) => x.allergic),
      false,
    )

    backPressed()
  }

  return (
    <BaseForm
      title="Let's make sure you don't have any unwanted reactions."
      subtitle="We want you feeling your best."
      onSubmit={() =>
        onSubmit(
          selectedAllergies.concat(otherAllergies).filter((x) => x.allergic),
          true,
        )
      }
      backPressed={() => backPress()}
      onSaveAndQuit={() =>
        onSubmit(
          selectedAllergies.filter((x) => x.allergic),
          true,
        )
      }
      error={error}
      content={
        <>
          <OptionCard
            title={`Are you aware of any drug allergies?`}
            options={[
              { id: 0, title: 'YES' },
              { id: 1, title: 'NO' },
            ]}
            optionSelected={selectedOption}
            setOptionChanged={(option: ButtonOption) => {
              setSelectedOption(option.id)
            }}
          />
          {selectedOption === 0 && (
            <AllergiesCard
              allergyOptions={selectedAllergies}
              toggleSelection={toggleSelection}
              updateDescription={updateDescription}
              otherAllergies={otherAllergies}
              setOtherAllergies={(other) => setOtherAllergies(other)}
              setOtherClicked={(bool: boolean) => setOtherClicked(bool)}
              otherClicked={otherClicked}
            />
          )}
        </>
      }
    />
  )
}
