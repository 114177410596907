import LogImage from 'Assets/Images/DMP_Log.png'
import RangeImage from 'Assets/Images/bp-range.png'
import SettingsImage from 'Assets/Images/glucose-settings.png'
import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled, { useTheme } from 'styled-components'
import './ModalDialogStyle.scss'

interface Props {
  modalOpen: boolean
  toggleModal: (isOpen: boolean) => void
  isCGM: boolean
}
export default function GlucoseWhatsNewModal({ modalOpen, toggleModal, isCGM }: Props) {
  const theme = useTheme()

  return (
    <StyledModal show={modalOpen} onHide={() => toggleModal(false)} dialogClassName={'glucose-new-dialog'} fullscreen={false}>
      <StyledHeader>
        <Modal.Title>What's New?</Modal.Title>
        <div onClick={() => toggleModal(false)} style={{ cursor: 'pointer' }}>
          <Icon name="times" color="white" />
        </div>
      </StyledHeader>
      <Modal.Body>
        <div style={{ padding: 0 }}>
          <Column styles={{ marginBottom: 15 }}>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={20}>
                App Access
              </Typography>
            </Row>
            <Typography>
              You can now view your blood glucose logs on the go! Download Tria Health’s mobile app to view a summary of your readings, personalize
              carbs and meal types for your readings, and order your supplies! Log in using your Tria Health patient portal account.
            </Typography>
          </Column>
          {isCGM ? (
            <Column styles={{ marginBottom: 15 }}>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={20}>
                  My Dashboard Updates
                </Typography>
              </Row>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={15}>
                  Date Range/Custom Date Range
                </Typography>
              </Row>
              <Typography>
                You will be able to review Dexcom readings and reading trends for timeframes up to 14-days. Use the custom date range to look back to
                any historical period before the last 14-days or to isolate a timeframe that is not displayed in the date drop down. The Custom Date
                Range can only show up to 14-days of readings.
              </Typography>
            </Column>
          ) : (
            <Column styles={{ marginBottom: 15 }}>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={20}>
                  Did you know?
                </Typography>
              </Row>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={15}>
                  Setting the Reading Type
                </Typography>
              </Row>
              <Typography>
                Before testing you can set the 'Reading Type' on your iGlucose meter. Push the power button to change to "Before Meal" or "After Meal"
                - notice the apple icon will change as well. You can then set the "Meal Type" but editing each reading from the Log on your Tria
                Health portal dashboard page.
              </Typography>
            </Column>
          )}
          <Column styles={{ marginBottom: 15 }}>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={20}>
                My Log Updates
              </Typography>
            </Row>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={15}>
                Custom Date Range
              </Typography>
            </Row>
            <Typography>
              Need to review your logs from a certain date range? You can now select a custom date range. This can be beneficial is you need to
              isolate a change due to medications, insulin, holidays, or vacation.
            </Typography>
            <Row center styles={{ marginTop: 10, marginBottom: 10 }}>
              <StyledImage src={RangeImage} alt="Custom range input fields" />
            </Row>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={15}>
                New Fields
              </Typography>
            </Row>
            <Typography>
              Users can now input meal type, carb count, insulin dosage and patient notes. To view these new fields, click the View Details button.
            </Typography>
            <Row center styles={{ marginTop: 10, marginBottom: 10 }}>
              <StyledImage src={LogImage} alt="Log input filters" />
            </Row>
          </Column>
          {isCGM ? (
            <Column styles={{ marginBottom: 15 }}>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={20}>
                  New Fields
                </Typography>
              </Row>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={15}>
                  Reading Range Cards
                </Typography>
              </Row>
              <Typography>
                Once you select your timeframe, you will be able to see your percentage of readings for each range. The goal for those ranges is
                listed on each card below your percentage. Click on the magnifying glass icon to see just those readings below in the reading logs.
                You can also input your insulin dosage, carb count or any notes that you would like to make for a specific reading.
              </Typography>
            </Column>
          ) : (
            <Column styles={{ marginBottom: 15 }}>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={20}>
                  My Text Settings Updates
                </Typography>
              </Row>
              <Row>
                <Typography color={theme.colors.primary1} font="bold" size={15}>
                  High Reading Notifications
                </Typography>
              </Row>
              <Typography>
                For users opted into Tria Health's Text Alerts, users can personalize their high blood glucose notifications. Input the blood glucose
                level that is high for you and click Update. Tria Health will only send high blood glucose alerts when your blood glucose levels are
                higher than your selection.
              </Typography>
              <Typography styles={{ marginTop: 10 }}>Current default is set to 250 mg/dL.</Typography>
              <Row center styles={{ marginTop: 10, marginBottom: 10 }}>
                <StyledImage src={SettingsImage} alt="Customize your high reading alert" />
              </Row>
            </Column>
          )}
          <Column styles={{ marginBottom: 15 }}>
            <Row styles={{ marginTop: 10, marginBottom: 10 }}>
              <StyledButton pageName="GlucoseWhatsNewModal" text="Close" onClick={() => toggleModal(false)} />
            </Row>
          </Column>
        </div>
      </Modal.Body>
    </StyledModal>
  )
}

const StyledImage = styled.img`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 700px;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
  }
`
const StyledModal = styled(Modal)`
  padding-left: 0px !important;
`
const StyledHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.primary1};
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`
const StyledButton = styled(Button)`
  font-family: 'Raleway-Semi-Bold';
  width: 15vw;
  padding-top: 10px;
  border: 0px;
  padding-bottom: 10px;
  margin-top: 10px;
  display: none;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
    display: block;
  }
`
