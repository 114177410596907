import React from 'react'
import ScrollToTop from 'react-scroll-up'
import styled from 'styled-components'
import Icon from '../Base/Icon'
import './ScrollButton.scss'

interface Props {
  showUnder?: number
}
export default function ScrollButton({ showUnder = 160 }: Props) {
  return (
    <ScrollToTop showUnder={showUnder} style={{ zIndex: 500 }}>
      <StyledContainer>
        <Icon name="arrow-up" color="white" />
      </StyledContainer>
    </ScrollToTop>
  )
}

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary1};
  width: 40px !important;
  height: 40px !important;
  border-radius: 40px;
  z-index: 150 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`
