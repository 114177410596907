import Icon from 'Components/Base/Icon'
import { FormikProps, getIn } from 'formik'
import { PrimaryPhysicianState } from 'hooks/usePatientIntake'
import React from 'react'
import { Form } from 'react-bootstrap'
import '../PhysiciansForm.scss'

interface Props {
  cancelInput: () => void
  attemptedSubmit: boolean
  props: FormikProps<PrimaryPhysicianState>
}
export default function PhysicianCard({ cancelInput, attemptedSubmit, props }: Props) {
  function getStyles(fieldName: string) {
    if (getIn(props.errors, fieldName) && attemptedSubmit) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  return (
    <div className="physician-card">
      <div onClick={cancelInput} className="intake-cancel-box">
        <Icon name="times" color="white" styles={{ height: '100%', width: '40%' }} />
      </div>
      <div className="physician-card-group" style={{ marginTop: 15 }}>
        <Form.Control
          type="text"
          onChange={(e) => props.setFieldValue('firstName', e.currentTarget.value)}
          style={{
            ...getStyles('firstName'),
          }}
          value={props.values.firstName}
          placeholder="Physician First Name *"
          className="login-input physician-input"
        />
        <div className="input-spacer" />
        <Form.Control
          type="text"
          required
          style={{
            ...getStyles('lastName'),
          }}
          onChange={(e) => props.setFieldValue('lastName', e.currentTarget.value)}
          value={props.values.lastName}
          placeholder="Physician Last Name *"
          className="login-input physician-input"
        />
      </div>
      <div className="physician-card-group">
        <Form.Control
          type="text"
          onChange={(e) => props.setFieldValue('city', e.currentTarget.value)}
          style={{
            ...getStyles('city'),
          }}
          value={props.values.city}
          placeholder="City *"
          className="login-input physician-input"
        />
        <div className="input-spacer" />
        <Form.Control
          type="text"
          style={{
            marginBottom: 0,
            ...getStyles('state'),
          }}
          required
          onChange={(e) => props.setFieldValue('state', e.currentTarget.value)}
          value={props.values.state}
          placeholder="State *"
          className="login-input physician-input"
        />
      </div>
    </div>
  )
}
