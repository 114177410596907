import { Theme, createStyles, makeStyles } from '@material-ui/core'
import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import usePatientReadings from 'hooks/usePatientReadings'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import { PatientGlucoseReading } from 'types/Dashboards'
import './EditReadingModal.scss'
import { useTheme } from 'styled-components'

interface Props {
  modalOpen: boolean
  reading: PatientGlucoseReading
  isMobile: boolean
  toggleModal: (isOpen: boolean) => void
  onReadingUpdated: (updatedReading: PatientGlucoseReading) => void
}

type FormValues = {
  notes: string
  carbCount: number | undefined
  insulinDosage: number | undefined
  mealType: string
  eventFlag: number
}
export default function EditReadingModal({ modalOpen, reading, isMobile, toggleModal, onReadingUpdated }: Props) {
  const styles = useStyles()
  const theme = useTheme()
  const accessToken = useAppSelector(getAccessToken)
  const { updateGlucoseReading } = usePatientReadings()

  const carbOptions = () => {
    const options: JSX.Element[] = [<option value={0}>How many carbs did you ingest?</option>]

    for (let i = 0; i <= 95; i += 5) {
      options.push(<option value={i}>{i}</option>)
    }

    return options
  }

  const onSubmit = (values: FormValues) => {
    const readingEdit = { ...reading }
    readingEdit.carbCount = values.carbCount
    readingEdit.notes = values.notes
    readingEdit.insulinDosage = values.insulinDosage
    readingEdit.mealType = values.mealType
    readingEdit.eventFlag = values.eventFlag

    accessToken &&
      updateGlucoseReading(accessToken, readingEdit).then(() => {
        onReadingUpdated(readingEdit)
        toggleModal(false)
      })
  }

  const initialValues: FormValues = {
    notes: reading.notes,
    carbCount: reading.carbCount,
    insulinDosage: reading.insulinDosage,
    mealType: reading.mealType,
    eventFlag: reading.eventFlag,
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  // useEffect(() => {}, [])

  const getOptions = () => {
    if (formik.values.eventFlag === 0) {
      return (
        <>
          <option>Select Meal Type</option>
          <option value="BB">Before Breakfast</option>
          <option value="BL">Before Lunch</option>
          <option value="BD">Before Dinner</option>
          <option value="BS">Before Snack</option>
          <option value="BT">Before Bedtime</option>
          <option value="MM">Missed Medications</option>
          <option value="NM">No Meal</option>
        </>
      )
    } else {
      return (
        <>
          <option>Select Meal Type</option>
          <option value="AB">After Breakfast</option>
          <option value="AL">After Lunch</option>
          <option value="AD">After Dinner</option>
          <option value="BT">Before Bedtime</option>
          <option value="MM">Missed Medications</option>
          <option value="NM">No Meal</option>
        </>
      )
    }
  }

  return (
    <div>
      <Modal show={modalOpen} onHide={() => toggleModal(false)} className={styles.custom} dialogClassName={styles.content1} fullscreen={false}>
        <Modal.Header className={styles.header}>
          <Modal.Title>{isMobile ? `Edit Reading` : `Edit Blood Sugar Reading Details`}</Modal.Title>
          <div onClick={() => toggleModal(false)} style={{ cursor: 'pointer' }}>
            <Icon name="times" color="white" />
          </div>
        </Modal.Header>
        {reading && (
          <Modal.Body className={styles.modalBody}>
            <div style={{ padding: 0 }}>
              <div>
                <Row>
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ marginRight: 10 }}>
                    Reading Date:
                  </Typography>
                  <Typography color={theme.colors.black} size={20}>
                    {dayjs(reading.readingDate).format('M/DD/YYYY h:mm A')}
                  </Typography>
                </Row>
                <Row>
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ marginRight: 10 }}>
                    Blood Glucose (mg/dl):
                  </Typography>
                  <Typography color={theme.colors.black} size={20}>
                    {reading.value}
                  </Typography>
                </Row>

                <div className="edit-reading-row">
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ minWidth: 150 }}>
                    {`Reading Type:`}
                  </Typography>

                  <Form.Control
                    as="select"
                    style={{
                      minWidth: 200,
                      backgroundColor: 'white',
                      color: 'black',
                      border: '1px solid #425563',
                      width: 270,
                    }}
                    value={formik.values.eventFlag}
                    onChange={(e) => {
                      formik.setFieldValue('eventFlag', +e.currentTarget.value)
                    }}
                  >
                    <option value={0}>Before Meal</option>
                    <option value={1}>After Meal</option>
                  </Form.Control>
                </div>
                <div className="edit-reading-row">
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ minWidth: 150 }}>
                    {`Meal Type:`}
                  </Typography>
                  <Form.Control
                    as="select"
                    style={{
                      minWidth: 200,
                      backgroundColor: 'white',
                      color: 'black',
                      border: '1px solid #425563',
                      width: 270,
                    }}
                    value={formik.values.mealType}
                    onChange={(e) => formik.setFieldValue('mealType', e.currentTarget.value)}
                  >
                    {getOptions()}
                  </Form.Control>
                </div>
                <div className="edit-reading-row">
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ minWidth: 150 }}>
                    Carb Count:
                  </Typography>
                  <Form.Control
                    as="select"
                    style={{
                      minWidth: 200,
                      backgroundColor: 'white',
                      color: 'black',
                      border: '1px solid #425563',
                      width: 270,
                    }}
                    onChange={(e) => formik.setFieldValue('carbCount', e.currentTarget.value)}
                  >
                    {carbOptions()}
                  </Form.Control>
                </div>
                <div className="edit-reading-row">
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ minWidth: 150 }}>
                    Insulin Dosage:
                  </Typography>
                  <Form.Control
                    type="text"
                    style={{
                      marginRight: 5,
                      border: '1px solid rgb(66, 85, 99)',
                      borderRadius: 4,
                      backgroundColor: 'white',
                      width: 150,
                    }}
                    onChange={(e) => formik.setFieldValue('insulinDosage', e.currentTarget.value)}
                    value={formik.values.insulinDosage}
                    placeholder=""
                  />
                </div>
                <Row className="edit-reading-row">
                  <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ minWidth: 150 }}>
                    Notes:
                  </Typography>
                </Row>
                <Row styles={{ alignItems: 'center', marginBottom: 10 }}>
                  <textarea
                    style={{
                      border: '1px solid rgb(66, 85, 99)',
                      resize: 'none',
                    }}
                    onChange={(e) => formik.setFieldValue('notes', e.currentTarget.value)}
                    value={formik.values.notes}
                    className={'form-control'}
                    rows={3}
                  ></textarea>
                </Row>
                <div>
                  <Typography font="bold" styles={{ marginRight: 5 }}>
                    Note:
                  </Typography>
                  <Typography>
                    Before testing you can set the 'Reading Type' on your meter. Push the power button to change to "Before Meal" or "After Meal" -
                    notice the apple icon will change as well. Then you can set the 'Meal Type' from the list above.
                  </Typography>
                </div>
              </div>
              <Row styles={{ justifyContent: 'space-between', marginTop: 10 }}>
                <Button
                  pageName="dashboardGlucoseEditModal"
                  styles={{
                    border: '0px',
                    backgroundColor: '#00677F',
                    borderRadius: 4,
                    padding: 10,
                    width: '49%',
                  }}
                  onClick={() => formik.handleSubmit()}
                  text={'Save'}
                />
                <Button
                  pageName="dashboardGlucoseEditModal"
                  styles={{
                    border: '0px',
                    backgroundColor: '#425563',
                    borderRadius: 4,
                    padding: 10,
                    width: '49%',
                  }}
                  onClick={() => toggleModal(false)}
                  text={'Cancel'}
                />
              </Row>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    custom: {
      paddingLeft: '0px !important',
    },
    content1: {
      minWidth: '50%',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 0,
      },
    },
    header: {
      backgroundColor: '#00677F',
      alignItems: 'center',
      color: 'white',
    },
    modalBody: {
      minHeight: 'min-content',
      [theme.breakpoints.down('sm')]: {
        minHeight: '100vh',
      },
    },
  }),
)
