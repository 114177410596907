import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import dayjs from 'dayjs'
import { Formik, FormikProps } from 'formik'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { LegacyRef, useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDropzone } from 'react-dropzone'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { getProfile } from 'reducers/profileSlice'
import styled from 'styled-components'
import * as Yup from 'yup'
import Image from '../../Assets/Images/Portal_Savings.svg'
import Button from '../../Components/Base/Button'
import Checkbox from '../../Components/Base/Checkbox'
import Icon from '../../Components/Base/Icon'
import Row from '../../Components/Base/Row'
import StateDropdown from '../../Components/Base/StateDropdown'
import Typography from '../../Components/Base/Typography'
import ScrollButton from '../../Components/ScrollButton'
import { useAppSelector } from '../../hooks/hooks'
import useReimbursement from '../../hooks/useReimbursement'
import { getAccessToken } from '../../reducers/authSlice'
import { setLoading } from '../../reducers/tempSlice'
import { ReimbursementFormValues, ReimbursementResponse } from '../../types/Reimbursement'
import formatPhoneNumberInput from '../../utilities/formatPhoneNumberInput'
import { getErrorStyles } from '../../utilities/getErrorStyle'
import InstructionsAccordion from './InstructionsAccordion'
import PDFLinks from './PDFLinks'
import './ReimbursementPage.scss'

export default function ReimbursementPage() {
  const { viewed }: Readonly<Params<string>> = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Reimbursement')

  const token = useAppSelector(getAccessToken) ?? ''
  const profile = useAppSelector(getProfile)

  const [pageLoading, setPageLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [reasonSelected, setReasonSelected] = useState(false)
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)

  const formikRef = useRef<FormikProps<ReimbursementFormValues>>(null)
  const recaptchaRef: LegacyRef<ReCAPTCHA> | undefined = React.createRef()

  const onDrop = useCallback((files: File[]) => {
    const tempFiles = formikRef.current?.values.documents
    files.map((file) => {
      tempFiles?.push(file)
    })
    formikRef.current?.setFieldValue('documents', tempFiles)
  }, [])

  const removeFile = (file: File) => {
    const files = formikRef.current?.values.documents.filter((x) => x.name !== file.name)
    formikRef.current?.setFieldValue('documents', files)
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { getReimbursement, postReimbursement } = useReimbursement(token)

  const setExistingData = (data: ReimbursementResponse) => {
    const { wellTrakPId, firstName, lastName, birthDate, address1, address2, city, state, zip, phoneNumber, emailAddress } = data
    if (formikRef.current) {
      const ref = formikRef.current
      ref.setFieldValue('welltrakId', wellTrakPId)
      ref.setFieldValue('firstName', firstName)
      ref.setFieldValue('lastName', lastName)
      ref.setFieldValue('birthDate', birthDate)
      ref.setFieldValue('address1', address1)
      ref.setFieldValue('address2', address2)
      ref.setFieldValue('city', city)
      ref.setFieldValue('state', state)
      ref.setFieldValue('zip', zip)
      ref.setFieldValue('phoneNumber', formatPhoneNumberInput(phoneNumber))
      ref.setFieldValue('emailAddress', emailAddress)
    }
  }

  useEffect(() => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    if (profile?.hasAutoReimbursement && viewed !== 'true') {
      navigate('/reimbursement/auto')
    }
    getReimbursement()
      .then((res) => {
        setExistingData(res.data)
      })

      .finally(() => {
        dispatch(setLoading(false))
      })
    if (window.innerWidth < 1000) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const instructionsRef = useRef<null | HTMLDivElement>(null)
  const executeScoreScroll = () =>
    instructionsRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

  const initialValues: ReimbursementFormValues = {
    firstName: '',
    lastName: '',
    birthDate: '',
    welltrakId: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneNumber: '',
    emailAddress: '',
    numberClaims: 0,
    documents: [],
    eSignature: '',
    eSignatureDate: new Date(),
    groupId: 0,
    reimbursementPId: 0,
    error: '',
    pharmacyNotSubmitted: false,
    didNotHaveCard: false,
    other: false,
    otherReason: '',
    recaptcha: undefined,
    updateProfile: false,
  }

  const validationSchema = () => {
    const emailSchemaObject = Yup.object({
      recaptcha: Yup.string().typeError('Verification expired').required('Please complete ReCAPTCHA'),
    })

    const validationShape = Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      birthDate: Yup.string().required('Birth date is required'),
      welltrakId: Yup.string().required('ID is required'),
      address1: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Zip is required'),
      phoneNumber: Yup.string()
        .min(14, 'Phone must be 10 digits')
        .required('Phone is required')
        .matches(new RegExp(/^(\([2-9]{1}[0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/), 'Phone is invalid'),
      emailAddress: Yup.string().required('Email is required'),
      eSignature: Yup.string().required('Signature is required'),
      documents: Yup.array().required().min(1),
      numberClaims: Yup.number().required('Claim count is required').min(1, 'Must be greater than 0'),
    })
    if (process.env.REACT_APP_ENV_NAME !== 'DEVELOPMENT') {
      validationShape.concat(emailSchemaObject)
    }
    return validationShape
  }

  const submitReimbursementForm = (values: ReimbursementFormValues) => {
    values.phoneNumber = values.phoneNumber?.replace(/[^\d]/g, '') ?? ''

    if (reasonSelected) {
      setPageLoading(true)
      values.birthDate = dayjs(values.birthDate).format('MM/DD/YYYY')
      postReimbursement(values)
        .then((res: any) => {
          window.scrollTo(0, 0)
          navigate('/reimbursement/success')
        })
        .catch(() => {
          setError('There was an error. Please try again.')
        })
        .finally(() => {
          setPageLoading(false)
        })
    }
  }

  return token === '' ? (
    <PDFLinks />
  ) : (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={attemptedSubmit ? true : false}
      validateOnChange={attemptedSubmit ? true : false}
      onSubmit={(values) => submitReimbursementForm(values)}
    >
      {({ values, errors, isValid, setFieldValue, submitForm }) => {
        return (
          <div className="form-container" style={{ marginTop: isMobile ? 25 : 55 }}>
            <Typography font="bold" className="reimbursement-title">
              Reimbursement Form
            </Typography>
            <p>
              If you had to pay out of pocket for a claim you feel should have been covered by the Tria Health Incentive, please submit a
              Reimbursement Form for approval and processing. All information must be provided in order to accurately process your claim(s).
              Incomplete information will result in form being returned or payments delays.
            </p>
            <div className="reimbursement-form-container">
              <StyledFormFieldContainer>
                <Row center className="instructions-text">
                  <div
                    style={{
                      marginRight: 5,
                      cursor: 'pointer',
                      display: 'flex',
                    }}
                    onClick={() => executeScoreScroll()}
                  >
                    <Typography font="bold" styles={{ marginRight: 5 }}>
                      Instructions
                    </Typography>
                    <Icon name="arrow-square-down" />
                  </div>
                </Row>
                <Form>
                  <Form.Group>
                    <div className="reimbursement-form-row">
                      <StyledNameField
                        type="text"
                        onChange={(value) => setFieldValue('firstName', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('firstName', errors, attemptedSubmit),
                        }}
                        value={values.firstName}
                        placeholder="First Name"
                      />
                      <StyledNameField
                        type="text"
                        onChange={(value) => setFieldValue('lastName', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('firstName', errors, attemptedSubmit),
                        }}
                        value={values.lastName}
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="reimbursement-form-row">
                      <DatePicker
                        placeholderText="Date of birth"
                        selected={values.birthDate ? new Date(values.birthDate) : undefined}
                        className={`${errors.birthDate ? 'datepicker-error' : 'datepicker-input'} reimbursement-date-picker`}
                        onChange={(date: Date) => setFieldValue('birthDate', date)}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        maxDate={new Date()}
                      />

                      <StyledFormField
                        type="number"
                        onChange={(value) => setFieldValue('welltrakId', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('welltrakId', errors, attemptedSubmit),
                        }}
                        value={values.welltrakId}
                        placeholder="Tria ID Number"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <StyledFormField
                        type="text"
                        onChange={(value) => setFieldValue('address1', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          maxWidth: '100%',
                          ...getErrorStyles('address1', errors, attemptedSubmit),
                        }}
                        value={values.address1}
                        placeholder="Address 1"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <StyledFormField
                        type="text"
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          maxWidth: '100%',
                          ...getErrorStyles('address2', errors, attemptedSubmit),
                        }}
                        onChange={(value) => setFieldValue('address2', value.currentTarget.value)}
                        value={values.address2}
                        placeholder="Address 2"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <StyledFormField
                        type="text"
                        onChange={(value) => setFieldValue('city', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          width: '58%',
                          ...getErrorStyles('city', errors, attemptedSubmit),
                        }}
                        value={values.city}
                        placeholder="City"
                      />
                      <StyledStateDropdown
                        value={values.state}
                        onFieldUpdated={(e: any) => setFieldValue('state', e.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          width: '40%',
                          borderRadius: 4,
                          ...getErrorStyles('state', errors, attemptedSubmit),
                        }}
                        label={'State'}
                      />

                      <StyledFormField
                        type="number"
                        maxLength={5}
                        onChange={(value) => setFieldValue('zip', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('zip', errors, attemptedSubmit),
                        }}
                        value={values.zip}
                        placeholder="Zip"
                      />
                      <StyledFormField
                        type="text"
                        onChange={(value) => {
                          const formatted = formatPhoneNumberInput(value.currentTarget.value)
                          setFieldValue('phoneNumber', formatted)
                        }}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('phoneNumber', errors, attemptedSubmit),
                        }}
                        value={values.phoneNumber}
                        placeholder="Phone Number (###-###-####)"
                      />
                      <StyledFormField
                        type="text"
                        onChange={(value) => setFieldValue('emailAddress', value.currentTarget.value)}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('emailAddress', errors, attemptedSubmit),
                        }}
                        value={values.emailAddress}
                        placeholder="Email"
                      />
                    </div>
                    <Typography font="bold" styles={{ marginTop: 10 }}>
                      Want to update your profile?
                    </Typography>
                    <Typography font="regular" styles={{ marginTop: 10 }}>
                      If you have recently changed your address, we can update your Tria Health profile based on what you have listed above.
                    </Typography>
                    <Row
                      className="reimbursement-checkbox-row"
                      styles={{ marginTop: 10 }}
                      onClick={() => {
                        setReasonSelected(true)
                        setFieldValue('updateProfile', !values.updateProfile)
                      }}
                    >
                      <Checkbox checked={values.updateProfile} styles={{ marginRight: 5 }} />
                      <Typography font="semi-bold">Yes, update my profile with this contact information</Typography>
                    </Row>
                    <div className="reimbursement-count-row" style={{ marginTop: 20 }}>
                      <div className="reimbursement-count-text">
                        Total number of individual prescription claims you are submitting for reimbursement:
                      </div>
                      <StyledFormField
                        type="number"
                        min={0}
                        style={{
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getErrorStyles('numberClaims', errors, attemptedSubmit),
                        }}
                        onChange={(value) => setFieldValue('numberClaims', value.currentTarget.value.toString())}
                        value={values.numberClaims}
                      />
                    </div>
                    <div style={{ marginTop: '25px', marginBottom: 10 }}>
                      Provide the reason(s) your pharmacy did not submit the claims directly to Tria Health:
                    </div>
                    {reasonSelected === false && attemptedSubmit && (
                      <Row
                        styles={{
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        }}
                      >
                        <StyledErrorText>Please select a reason</StyledErrorText>
                      </Row>
                    )}
                    <Row
                      className="reimbursement-checkbox-row"
                      onClick={() => {
                        setReasonSelected(true)
                        setFieldValue('didNotHaveCard', !values.didNotHaveCard)
                        setFieldValue('pharmacyNotSubmitted', false)
                        setFieldValue('otherSelected', false)
                        setFieldValue('other', '')
                      }}
                    >
                      <Checkbox checked={values.didNotHaveCard} styles={{ marginRight: 5 }} />
                      <Typography font="semi-bold">I did not have my Tria Health Incentive Card.</Typography>
                    </Row>
                    <Row
                      className="reimbursement-checkbox-row"
                      onClick={() => {
                        setReasonSelected(true)
                        setFieldValue('pharmacyNotSubmitted', !values.pharmacyNotSubmitted)
                        setFieldValue('didNotHaveCard', false)
                        setFieldValue('otherSelected', false)
                        setFieldValue('other', '')
                      }}
                    >
                      <Checkbox checked={values.pharmacyNotSubmitted} styles={{ marginRight: 5 }} />
                      <Typography font="semi-bold">The pharmacy could/would not submit the claim directly to Tria.</Typography>
                    </Row>
                    <Row
                      className="reimbursement-checkbox-row"
                      onClick={() => {
                        setReasonSelected(true)
                        setFieldValue('other', !values.other)
                        setFieldValue('didNotHaveCard', false)
                        setFieldValue('pharmacyNotSubmitted', false)
                      }}
                    >
                      <Checkbox checked={values.other} styles={{ marginRight: 5 }} />
                      <Typography font="semi-bold">{`Other ${values.other ? '(please explain)' : ''}`}</Typography>
                    </Row>

                    {values.other && (
                      <StyledFormField type="text" onChange={(e) => setFieldValue('otherReason', e.target.value)} value={values.otherReason} />
                    )}

                    {attemptedSubmit && errors.documents && (
                      <Row
                        styles={{
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        }}
                      >
                        <StyledErrorText>Please attach image(s)</StyledErrorText>
                      </Row>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px dashed black',
                        textAlign: 'center',
                        alignItems: 'center',
                        padding: '2% 10%',
                        cursor: 'pointer',
                      }}
                      {...getRootProps()}
                    >
                      <Icon name="cloud-upload" size={'3x'} color="#00677F" />
                      <input {...getInputProps()} />
                      <Typography styles={{ marginBottom: 5 }} font="semi-bold">
                        {`Pharmacy Receipt or Pharmacy Printout Attachment. ${
                          isMobile
                            ? `Tap to select files`
                            : `Select
                        your file(s) or drag and drop here`
                        }`}
                      </Typography>
                      <Typography>JPEG, PNG, PDF or ZIP</Typography>
                    </div>
                    {values.documents.map((file) => (
                      <Row styles={{ marginTop: 10, alignItems: 'center' }}>
                        <Typography styles={{ marginRight: 5 }}>{file.name}</Typography>
                        <div onClick={() => removeFile(file)} style={{ cursor: 'pointer' }}>
                          <Icon name="times" />
                        </div>
                      </Row>
                    ))}
                    <StyledNameField
                      type="text"
                      placeholder="Member Signature"
                      style={{
                        border: '2px solid transparent',
                        borderRadius: 4,
                        marginTop: 15,
                        ...getErrorStyles('eSignature', errors, attemptedSubmit),
                      }}
                      onChange={(value) => setFieldValue('eSignature', value.currentTarget.value)}
                      value={values.eSignature}
                    />
                    {attemptedSubmit && (isValid === false || reasonSelected === false) && (
                      <Row
                        styles={{
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        }}
                      >
                        <StyledErrorText>Please fix errors above</StyledErrorText>
                      </Row>
                    )}
                    {error && (
                      <Row
                        styles={{
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        }}
                      >
                        <StyledErrorText>{error}</StyledErrorText>
                      </Row>
                    )}
                    {process.env.REACT_APP_ENV_NAME !== 'DEVELOPMENT' && process.env.REACT_APP_RECAPTCHA_SITE_KEY !== undefined && (
                      <>
                        <Row>{errors.recaptcha && <StyledErrorText>{errors.recaptcha}</StyledErrorText>}</Row>
                        <Row styles={{ marginBottom: 15 }}>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                            onChange={(value) => {
                              setFieldValue('recaptcha', value)
                            }}
                          />
                        </Row>
                      </>
                    )}
                    <StyledReimbursementButton
                      pageName="reimbursement_form"
                      loading={pageLoading}
                      onClick={() => {
                        setAttemptedSubmit(true)
                        submitForm()
                      }}
                      text={'Submit'}
                    />
                  </Form.Group>
                </Form>
              </StyledFormFieldContainer>
              <div style={{ flex: '40%', position: 'relative' }} ref={instructionsRef}>
                <img
                  src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Reimbursement%20Status.png"
                  alt="You can now submit and view the status of your medication reimbursements via Tria Health's mobile app"
                  style={{
                    width: '100%',
                    paddingTop: 10,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                  }}
                />
                <div
                  style={{
                    fontFamily: 'Raleway-Semi-Bold',
                    fontSize: 25,
                    position: 'relative',
                  }}
                >
                  Instructions:
                </div>
                <InstructionsAccordion />
                <div style={{ minHeight: '100%' }}>
                  <img
                    src={Image}
                    className="reimbursement-image"
                    alt="Woman sitting with laptop surrounded by floating dollar bills"
                    style={{
                      position: 'absolute',
                      paddingTop: '45%',
                      width: '100%',
                      zIndex: -10,
                    }}
                  />
                </div>
              </div>
            </div>
            <ScrollButton showUnder={600} />
          </div>
        )
      }}
    </Formik>
  )
}

const StyledFormField = styled(Form.Control)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  padding-left: 10px;
  @media ${sizes.tablet} {
    width: 100%;
  }
`

const StyledNameField = styled(StyledFormField)`
  width: 100%;
  @media ${sizes.tablet} {
    width: 49%;
  }
`

const StyledFormFieldContainer = styled.div`
  flex: 60%;
  padding-right: 0px;
  @media ${sizes.tablet} {
    padding-top: 10px;
    padding-right: 20px;
  }
`

const StyledStateDropdown = styled(StateDropdown)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
  @media (max-width: ${sizes.tablet}) {
    min-width: 100%;
  }
`
const StyledReimbursementButton = styled(Button)`
  width: 100%;
  margin-right: 1rem;
  border: 0px;
  background-color: ${({ theme }) => theme.colors.primary1};
  border-radius: 4px;
  padding: 10px 50px 10px 50px;
  @media ${sizes.tablet} {
    width: 250px;
  }
`
