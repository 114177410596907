
import React, { useEffect, useState } from 'react'
import { Paragraph, SectionInfo } from 'types/Challenge'
import Typography from 'Components/Base/Typography'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function TrafficStep2({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {

    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2])))
    setStepParagraphs(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]))?.paragraphs)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])


  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : '0px 60px 0px 0px', width: '80%' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant='h1'>{sectionInfo?.titles.find((title) => title.titleId === 2)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 4)?.paragraph ?? ''}</p>
        </Row>
        <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 5)?.paragraph ?? ''} <i>{sectionInfo?.titles.find((title) => title.titleId === 3)?.titleText ?? ''}</i> {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 6)?.paragraph ?? ''}</p>
      </Column>
      <Column styles={{ width: '100%', marginTop: isMobile ? '0px' : '40px' }}>
        <Column styles={isMobile ? { margin: 'auto' } : { marginLeft: 'auto' }}>
          <Row styles={{ margin: isMobile ? '20px 0px' : '20px 0px' }}>
            <Typography variant='h3'>{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</Typography>
          </Row>
          <Row>
            <Column>
              <li style={{ fontFamily: 'Raleway-bold', fontSize: 20 }}>{sectionInfo?.titles.find((title) => title.titleId === 5)?.titleText ?? ''}</li>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 7)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 8)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 9)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 10)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 11)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 12)?.paragraph ?? ''}</li>
              </Row>
              <li style={{ fontFamily: 'Raleway-bold', fontSize: 20 }}>{sectionInfo?.titles.find((title) => title.titleId === 7)?.titleText ?? ''}</li>
            </Column>
            <Column styles={{ marginLeft: 40 }}>
              <li style={{ fontFamily: 'Raleway-bold', fontSize: 20 }}>{sectionInfo?.titles.find((title) => title.titleId === 6)?.titleText ?? ''}</li>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 13)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 14)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 15)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 16)?.paragraph ?? ''}</li>
              </Row>
              <Row styles={{ margin: isMobile ? '20px 10px' : '10px 40px' }}>
                <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 17)?.paragraph ?? ''}</li>
              </Row>
            </Column>
          </Row>
        </Column>
      </Column>
    </ResponsiveContainer>
  )
}

