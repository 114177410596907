import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import ProgressIcon from 'Pages/STOPProgram/components/ProgressIcon'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import dayjs from 'dayjs'
import React from 'react'
import { useTheme } from 'styled-components'
import { PatientActivityValues, STOPActivity } from 'types/STOP'

type Props = {
  activityId: number
  disabled: boolean
  text: string
  activityStatus?: PatientActivityValues
  onStartActivity: (activityId: STOPActivity) => void
}

export default function ActivityIcon({ activityId, disabled, text, activityStatus, onStartActivity }: Props) {
  const theme = useTheme()
  const activityComplete = activityStatus && activityStatus.completedDate !== null
  const available = activityStatus && dayjs(activityStatus.availableDate).startOf('day') <= dayjs().startOf('day')

  return (
    <Column
      onClick={!activityComplete && !disabled && available ? () => onStartActivity(activityId) : undefined}
      style={{
        cursor: activityComplete || disabled || !available ? 'auto' : 'pointer',

        textAlign: 'center',
        width: 200,
        marginTop: 78,
      }}
    >
      <Row styles={{ position: 'relative' }}>
        <ProgressIcon
          icon={activityComplete ? 'check' : 'play-start'}
          color={activityComplete ? theme.colors.charts.inRange : theme.colors.white}
          styles={{ paddingLeft: 5, marginLeft: 'auto', marginRight: 'auto' }}
          iconColor={disabled || !available ? theme.colors.lightGray : activityComplete ? theme.colors.white : theme.colors.primary1}
          size={80}
          iconSize="3x"
        />
      </Row>

      <ScaledText size={25} bold styles={{ marginTop: 10, marginBottom: 10 }}>
        {`Activity #${activityId + 1}`}
      </ScaledText>
      <ScaledText size={18}>{text}</ScaledText>
      {!available && activityStatus?.availableDate && (
        <Column style={{ color: 'red', position: 'absolute', textAlign: 'center', width: '100%' }}>
          <ScaledText>Available on</ScaledText>
          <ScaledText>{`${dayjs(activityStatus?.availableDate).format('MM/DD/YYYY')}`}</ScaledText>
        </Column>
      )}
      {activityComplete && activityStatus?.availableDate && (
        <Column style={{ position: 'absolute', textAlign: 'center', width: '100%', marginTop: 15, fontWeight: 'bold' }}>
          <ScaledText size={15}>Completed on</ScaledText>
          <ScaledText size={15}>{`${dayjs(activityStatus?.completedDate).format('MM/DD/YYYY')}`}</ScaledText>
        </Column>
      )}
    </Column>
  )
}
