import Icon from 'Components/Base/Icon'
import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import styled, { useTheme } from 'styled-components'

type Props = {
  expandedSection: number | undefined
  onToggle: (value: number) => void
}
export default function CTLAccordion({ expandedSection, onToggle }: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const handleClick = (value) => {
    onToggle(value)
    analytics.postAnalyticsEvent({
      category: `click_button_ctl_accordion_${value + 1}`,
      action: `click_button_ctl_accordion_${value + 1}`,
      label: `Button`,
      screenName: 'WeightManagementCarePath',
    })
  }

  return (
    <Accordion
      style={{
        borderRadius: 10,
        width: '100%',
      }}
    >
      <Card>
        <Accordion.Toggle as={Card.Header} className={expandedSection === 0 ? 'info-header' : ''} eventKey="0" onClick={() => handleClick(0)}>
          Designated Health Coach
          <Icon name={expandedSection === 0 ? 'chevron-up' : 'chevron-down'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              Once enrolled in Weight Management Care Path, you will be able to setup your first appointment over the phone with your designated
              health coach. Your coach is trained in all matters of food, nutrition, and their impacts on human health. During your first
              consultation, your coach will provide you with an overview of your health and fitness app's coaching feature, provide clarification for
              all patient requirements, determine the amount of coaching you'll require, and educate you on food diaries, weekly check-in and
              additional tools available. Your health coach will also discuss whether or not medication is the right option for you. If it's
              determined to be beneficial, you'll be able to schedule a medication review with a clinical pharmacist.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} className={expandedSection === 1 ? 'info-header' : ''} eventKey="1" onClick={() => handleClick(1)}>
          Industry-leading Health and Fitness App
          <Icon name={expandedSection === 1 ? 'chevron-up' : 'chevron-down'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              Weight Management Care Path has partnered with Lose It! to provide you with access to the industry-leading digital health and fitness
              platform that is centered on the proven principles of calorie tracking and community support for healthy sustainable weight loss.
              Members track their daily food intake and fitness activity, and can create goals, start or join community activities and competitive
              challenges, connect activity trackers, access coaches, and more. Lose It! seamlessly integrates with top health and fitness brands, such
              as Jawbone, FitBit, Nike, Misfit, Nokia, Stravia, MapMyFitness, Apple Health, and RunKeeper. The Lose It! experience gives members the
              ability to share data through wearable activity trackers, Bluetooth devices, and a variety of connected mobile apps to help them achieve
              powerful, personalized results.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} className={expandedSection === 2 ? 'info-header' : ''} eventKey="2" onClick={() => handleClick(2)}>
          Clinical Pharmacist
          <Icon name={expandedSection === 2 ? 'chevron-up' : 'chevron-down'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              Once enrolled in Weight Management Care Path, you'll have the opportunity to speak with a Tria Health pharmacist. Tria's pharmacists
              have all received their Pharm D, have postdoctoral residency training, board certified, certified in diabetes education and are trained
              in motivational interviewing techniques. During your initial consultation, your pharmacist will review all your current medications and
              provide a medication assessment to determine if weight loss medication will be safe, affordable, and effective. If the need for
              additional follow-ups is identified, your pharmacist will work with you to create an on-going consultation schedule. If your pharmacist
              determines weight loss medication to be appropriate for you, they will coordinate directly with your doctor.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} className={expandedSection === 3 ? 'info-header' : ''} eventKey="3" onClick={() => handleClick(3)}>
          Additional Tools & Resources
          <Icon name={expandedSection === 3 ? 'chevron-up' : 'chevron-down'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>
              If you're interested in additional tools to aid you in your weight loss journey, we have compiled a list of fantastic resources. These
              include recipes, exercise tutorials, and motivational tips to keep you going. You can find all of our weight loss resources{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: theme.colors.primary1,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate('/weight-loss')
                }}
              >
                here
              </span>
              .
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}
const StyledColumn = styled.div`
  color: ${({ theme }) => theme.colors.primary1} !important;
  @media (min-width: ${sizes.tablet}) {
    width: 48%;
  }
`
