import Button from 'Components/Base/Button'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { StyledPageContainer } from 'Styles/global'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Column from '../../../Components/Base/Column'
import { useAppSelector } from '../../../hooks/hooks'
import useProfile from '../../../hooks/useProfile'
import { getAccessToken } from '../../../reducers/authSlice'
import '../ManagementPages.scss'
import ProgramInfo from '../components/ProgramInfo'
import SignupForm, { ImageType } from '../components/SignupForm'

export default function DiabetesManagementPage() {
  const token = useAppSelector(getAccessToken)

  const [searchParams, setSearchParams] = useSearchParams()
  const [welltrak, setWelltrak] = useState()
  const { getWelltrakFromGuid } = useProfile(token!)

  const navigate = useNavigate()

  useEffect(() => {
    const id = searchParams.get('id')
    const ID = searchParams.get('ID')

    if (id !== null) {
      getWelltrakFromGuid(id).then((res: any) => {
        setWelltrak(res.data)
      })
    }
    if (ID !== null) {
      getWelltrakFromGuid(ID).then((res: any) => {
        setWelltrak(res.data)
      })
    }
  }, [])

  const navigateToDiabetesResources = () => {
    const path = `/resources/diabetes`
    navigate(path)
  }

  return (
    <StyledPageContainer>
      <ResponsiveContainer>
        <Column className="program-info">
          <ProgramInfo
            descriptionClassName="program-info-description"
            title="Ready, Set, GLUCOSE!"
            videoUrl="https://www.youtube.com/watch?v=QK6UFKY1YBA"
            textDetail1="Thank you for choosing Tria Health’s Diabetes Monitoring Program. You and your pharmacist have determined you need to regularly monitor your blood glucose levels. For individuals with diabetes, understanding your blood glucose can effectively reduce complications."
            textDetail2="Your new Tria Health meter allows for easy setup, testing and sharing with your designated providers. By observing trends, your pharmacist will be able to provide better recommendations because they’ll know how your current medications are impacting your health."
          />
          <Button
            pageName="DMP"
            text="Diabetes Resources"
            onClick={navigateToDiabetesResources}
            rightIcon={'external-link'}
            styles={{ width: 250 }}
          ></Button>
        </Column>
        <Column className="management-page-signup">
          <SignupForm welltrak={welltrak} imageType={ImageType.DIABETES} />
        </Column>
      </ResponsiveContainer>
    </StyledPageContainer>
  )
}
