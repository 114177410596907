import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import Row1Image from '../../../../Assets/Images/STOP/Cancer.png'
import Row2Image from '../../../../Assets/Images/STOP/Mouth Disease.png'

export default function HealthConditions3() {
  return (
    <>
      <STOPHeader>What Health Conditions Benefit the Most from Quitting Tobacco?</STOPHeader>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row1Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Cancer:
          </ScaledText>
          <ScaledText>
            Toxins in tobacco weaken the body’s immune system making it harder to fight off cancer cells. Nearly 9 out of 10 lung cancer deaths are
            related to smoking tobacco or secondhand smoke exposure.
          </ScaledText>
          <ScaledText>
            Use of smokeless tobacco increases the risk of mouth cancer and oral leukoplakia (white or gray patches inside the mouth).
          </ScaledText>
        </Column>
      </ResponsiveContainer>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row2Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Mouth Disease:
          </ScaledText>
          <ScaledText>Cigarettes, cigars, vaping, and smokeless tobacco increase risk of gum disease, and other oral health conditions.</ScaledText>
          <div style={{ marginTop: 15 }}>
            <ScaledText>
              The most common oral problems from tobacco are tooth decay, tooth loss, dry sockets after tooth removal, decreased taste, and bad
              breath.
            </ScaledText>
          </div>
          <div style={{ marginTop: 15 }}>
            <ScaledText>About 43% of adults aged 65 or older who currently smoke cigarettes have lost all their teeth.</ScaledText>
          </div>
        </Column>
      </ResponsiveContainer>
    </>
  )
}

const StyledImage = styled.img`
  object-fit: cover;
  height: 15vh;
  border: 1px solid black;
  margin-bottom: 15px;
  @media (${sizes.tablet}) {
    height: 25vh;
    margin-right: 25px;
  }
`
