import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import { useStopContext } from 'Pages/ProgramScreen'
import { sizes } from 'Styles/sizes'
import { Formik } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import usePrograms from 'hooks/usePrograms'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ReactSlider from 'react-slider'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { STOPActivity } from 'types/STOP'
import * as yup from 'yup'
import STOPHeader from '../components/STOPHeader'
import ScaledText from '../components/ScaledText'
import './ReflectionScreen.scss'

export interface STOPFormValues {
  1: string
  2: string
  3: string
  4: number
  5: string
  6: string
  7: number
  8: string
  9: string
  10: number
}

export default function ReflectionScreen() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const token = useAppSelector(getAccessToken)
  const [error, setError] = useState<boolean>(false)
  const analytics = useAnalytics(token!)

  const { postReflectionResponses } = usePrograms(token!)
  const { currentActivity, currentActivityStep, setCurrentActivityStep, setReadyToQuit } = useStopContext()

  const initialValues: STOPFormValues = {
    1: '',
    2: '',
    3: '',
    4: 0,
    5: '',
    6: '',
    7: 0,
    8: '',
    9: '',
    10: 0,
  }

  const validationSchema = yup.object().shape({
    1: currentActivity === STOPActivity.HealthBenefits ? yup.string().required('This field is required') : yup.string(),
    2: currentActivity === STOPActivity.HealthBenefits ? yup.string().required('This field is required') : yup.string(),
    3: currentActivity === STOPActivity.HealthBenefits ? yup.string().required('This field is required') : yup.string(),
    5: currentActivity === STOPActivity.OvercomingObstacles ? yup.string().required('This field is required') : yup.string(),
    6: currentActivity === STOPActivity.OvercomingObstacles ? yup.string().required('This field is required') : yup.string(),
    8: currentActivity === STOPActivity.MedicationOptions ? yup.string().required('This field is required') : yup.string(),
    9: currentActivity === STOPActivity.MedicationOptions ? yup.string().required('This field is required') : yup.string(),
  })

  const submitReflectionForm = (values: STOPFormValues) => {
    let filteredValues: Partial<STOPFormValues> = {}

    if (currentActivity === STOPActivity.HealthBenefits) {
      filteredValues = {
        1: values[1],
        2: values[2],
        3: values[3],
      }
    } else if (currentActivity === STOPActivity.OvercomingObstacles) {
      filteredValues = {
        5: values[5],
        6: values[6],
        7: values[7],
      }
    } else if (currentActivity === STOPActivity.MedicationOptions) {
      if (values[10] > 6) setReadyToQuit(true)

      filteredValues = {
        8: values[8],
        9: values[9],
        10: values[10],
      }
    }

    dispatch(setLoading(true))
    postReflectionResponses(currentActivity, filteredValues)
      .then(() => {
        setCurrentActivityStep(currentActivityStep + 1)
        window.scrollTo(0, 0)
        setError(false)
        analytics.postAnalyticsEvent({
          category: `click_button_stop`,
          action: `click_button_post_reflection_activity_${currentActivity}`,
          label: `Button`,
          screenName: 'StopProgram',
        })
      })
      .catch(() => {
        analytics.postAnalyticsEvent({
          category: `click_button_stop`,
          action: `click_button_post_reflection_error`,
          label: `Button`,
          screenName: 'StopProgram',
        })
        setError(true)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const updatePipeDelimitedValue = (current: string, value: string): string => {
    let valuesArray = current ? current.split('|') : []

    if (valuesArray.includes(value)) {
      valuesArray = valuesArray.filter((item) => item !== value)
    } else {
      valuesArray.push(value)
    }
    return valuesArray.join('|')
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => submitReflectionForm(values)}>
      {({ values, errors, touched, setFieldValue, submitForm, handleSubmit }) => {
        if (currentActivity === STOPActivity.HealthBenefits) {
          return (
            <Column styles={{ justifyContent: 'space-between', height: '100%' }}>
              <StyledReflectionHeader>
                <STOPHeader>Activity Reflection</STOPHeader>
                <ScaledText>
                  Taking the time to reflect on quitting tobacco helps you to gain insight into your progress, identify areas for improvement, and to
                  inspire you to continue making progress towards your quitting goal.
                </ScaledText>
              </StyledReflectionHeader>
              <div style={{ marginBottom: 15 }}>
                <ScaledText>1. Have you tried to quit tobacco in the past?</ScaledText>
                {errors[1] && <ScaledText styles={{ color: 'red' }}>{`Please select an answer`}</ScaledText>}
                <Row styles={{ alignItems: 'center' }}>
                  <Checkbox checked={values[1] === 'yes'} onClick={() => setFieldValue('1', 'yes')} styles={{ marginRight: 10 }} />
                  <ScaledText styles={{ marginRight: 20 }}>Yes</ScaledText>
                  <Checkbox checked={values[1] === 'no'} onClick={() => setFieldValue('1', 'no')} styles={{ marginRight: 10 }} />
                  <ScaledText>No</ScaledText>
                </Row>
              </div>

              <ScaledText>2. Why do you choose to use tobacco?</ScaledText>
              {errors[2] && touched[2] && <ScaledText styles={{ color: 'red' }}>{`Please provide an answer`}</ScaledText>}
              <StyledFormControl
                placeholder="Comments:"
                value={values[2]}
                onChange={(e) => setFieldValue('2', e.target.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  maxWidth: '100%',
                  marginBottom: 15,
                }}
                as="textarea"
                rows={6}
                className="contact-textarea-input"
              />

              <ScaledText>3. Where do you use tobacco? (Select all that apply)</ScaledText>
              <Column style={{ marginBottom: 15 }}>
                <Row styles={{ marginTop: 10 }}>
                  <Checkbox
                    checked={values[3].includes('Home')}
                    onClick={() => setFieldValue('3', updatePipeDelimitedValue(values[3], 'Home'))}
                    styles={{ marginRight: 10 }}
                  />
                  <ScaledText styles={{ marginRight: 20 }}>Home</ScaledText>
                </Row>
                <Row styles={{ marginTop: 10 }}>
                  <Checkbox
                    checked={values[3].includes('Work')}
                    onClick={() => setFieldValue('3', updatePipeDelimitedValue(values[3], 'Work'))}
                    styles={{ marginRight: 10 }}
                  />
                  <ScaledText styles={{ marginRight: 20 }}>Work</ScaledText>
                </Row>
                <Row styles={{ marginTop: 10 }}>
                  <Checkbox
                    checked={values[3].includes('In the car')}
                    onClick={() => setFieldValue('3', updatePipeDelimitedValue(values[3], 'In the car'))}
                    styles={{ marginRight: 10 }}
                  />
                  <ScaledText styles={{ marginRight: 20 }}>In the car</ScaledText>
                </Row>
                <Row styles={{ marginTop: 10 }}>
                  <Checkbox
                    checked={values[3].includes('Social settings')}
                    onClick={() => setFieldValue('3', updatePipeDelimitedValue(values[3], 'Social settings'))}
                    styles={{ marginRight: 10 }}
                  />
                  <ScaledText styles={{ marginRight: 20 }}>Social settings</ScaledText>
                </Row>
                <Row styles={{ marginTop: 10 }}>
                  <Checkbox
                    checked={values[3].includes('Other')}
                    onClick={() => setFieldValue('3', updatePipeDelimitedValue(values[3], 'Other'))}
                    styles={{ marginRight: 10 }}
                  />
                  <ScaledText styles={{ marginRight: 20 }}>Other</ScaledText>
                </Row>
                {errors[3] && <ScaledText styles={{ color: 'red' }}>{`Please select an answer`}</ScaledText>}
              </Column>

              <ScaledText styles={{ marginBottom: 20 }}>4. What is your motivation to quit using tobacco?</ScaledText>

              <div style={{ width: '100%' }}>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="slider-thumb"
                  trackClassName="slider-track"
                  marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  renderMark={(props: any) => {
                    if (values[4] >= props.key) {
                      return <></>
                    } else {
                      return <span {...props}>{`${props.key}`}</span>
                    }
                  }}
                  min={0}
                  max={10}
                  value={values[4]}
                  onChange={(value: number) => setFieldValue('4', value)}
                  renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
                />
              </div>
              <Row styles={{ justifyContent: 'space-between', marginTop: 25 }}>
                <ScaledText>Low</ScaledText>
                <ScaledText>High</ScaledText>
              </Row>
              <Spacer top={50}>
                <Row center>{error && <ScaledText styles={{ color: 'red' }}>{`There was an error. Please try again`}</ScaledText>}</Row>
                <Row center>
                  <Button text="Submit" styles={{ width: 170, backgroundColor: theme.colors.primary1 }} onClick={submitForm} />
                </Row>
              </Spacer>
            </Column>
          )
        }
        if (currentActivity === STOPActivity.OvercomingObstacles) {
          return (
            <Column styles={{ justifyContent: 'space-between', height: '100%' }}>
              <StyledReflectionHeader>
                <STOPHeader>Activity Reflection</STOPHeader>
                <ScaledText styles={{ textAlign: 'center' }}>
                  Taking the time to reflect on quitting tobacco helps you to gain insight into your progress, identify areas for improvement, and to
                  inspire you to continue making progress towards your quitting goal.
                </ScaledText>
              </StyledReflectionHeader>
              <ScaledText>1. What would your life look like if you quit tobacco?</ScaledText>
              {errors[5] && touched[5] && <ScaledText styles={{ color: 'red' }}>{`Please provide an answer`}</ScaledText>}
              <StyledFormControl
                placeholder="Comments:"
                value={values[5]}
                onChange={(e) => setFieldValue('5', e.target.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  maxWidth: '100%',
                  marginBottom: 15,
                }}
                as="textarea"
                rows={6}
                className="contact-textarea-input"
              />
              <ScaledText>2. What is keeping you from quitting tobacco?</ScaledText>
              {errors[6] && touched[6] && <ScaledText styles={{ color: 'red' }}>{`Please provide an answer`}</ScaledText>}
              <StyledFormControl
                placeholder="Comments:"
                value={values[6]}
                onChange={(e) => setFieldValue('6', e.target.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  maxWidth: '100%',
                  marginBottom: 15,
                }}
                as="textarea"
                rows={6}
                className="contact-textarea-input"
              />

              <ScaledText styles={{ marginBottom: 20 }}>3. What is your motivation to quit today?</ScaledText>
              <div style={{ width: '100%' }}>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="slider-thumb"
                  trackClassName="slider-track"
                  marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  renderMark={(props: any) => {
                    if (values[7] >= props.key) {
                      return <></>
                    } else {
                      return <span {...props}>{`${props.key}`}</span>
                    }
                  }}
                  min={0}
                  max={10}
                  value={values[7]}
                  onChange={(value: number) => setFieldValue('7', value)}
                  renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
                />
              </div>
              <Row styles={{ justifyContent: 'space-between', marginTop: 25 }}>
                <ScaledText>Low</ScaledText>
                <ScaledText>High</ScaledText>
              </Row>
              <Spacer top={50}>
                <Row center>{error && <ScaledText styles={{ color: 'red' }}>{`There was an error. Please try again`}</ScaledText>}</Row>
                <Row center>
                  <Button text="Submit" styles={{ width: 170, backgroundColor: theme.colors.primary1 }} onClick={submitForm} />
                </Row>
              </Spacer>
            </Column>
          )
        }
        if (currentActivity === STOPActivity.MedicationOptions) {
          return (
            <Column styles={{ justifyContent: 'space-between', height: '100%' }}>
              <StyledReflectionHeader>
                <STOPHeader>Activity Reflection</STOPHeader>
                <ScaledText styles={{ textAlign: 'center' }}>
                  Taking the time to reflect on quitting tobacco helps you to gain insight into your progress, identify areas for improvement, and to
                  inspire you to continue making progress towards your quitting goal.
                </ScaledText>
              </StyledReflectionHeader>
              <ScaledText>1. What methods, if any, have you used in the past to help you quit tobacco?</ScaledText>
              {errors[8] && touched[8] && <ScaledText styles={{ color: 'red' }}>{`Please provide an answer`}</ScaledText>}
              <StyledFormControl
                placeholder="Comments:"
                value={values[8]}
                onChange={(e) => setFieldValue('8', e.target.value)}
                style={{
                  border: '2px solid transparent',
                  borderRadius: 4,
                  maxWidth: '100%',
                  marginBottom: 15,
                }}
                as="textarea"
                rows={6}
                className="contact-textarea-input"
              />
              <div style={{ marginBottom: 15 }}>
                <ScaledText>2. Would you try NRT or medication to help during your next quit attempt?</ScaledText>
                {errors[9] && <ScaledText styles={{ color: 'red' }}>{`Please select an answer`}</ScaledText>}
                <Row styles={{ alignItems: 'center' }}>
                  <Checkbox checked={values[9] === 'yes'} onClick={() => setFieldValue('9', 'yes')} styles={{ marginRight: 10 }} />
                  <ScaledText styles={{ marginRight: 20 }}>Yes</ScaledText>
                  <Checkbox checked={values[9] === 'no'} onClick={() => setFieldValue('9', 'no')} styles={{ marginRight: 10 }} />
                  <ScaledText>No</ScaledText>
                </Row>
              </div>

              <ScaledText styles={{ marginBottom: 20 }}>3. What is your motivation to quit today?</ScaledText>
              <div style={{ width: '100%' }}>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="slider-thumb"
                  trackClassName="slider-track"
                  marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  renderMark={(props: any) => {
                    if (values[10] >= props.key) {
                      return <></>
                    } else {
                      return <span {...props}>{`${props.key}`}</span>
                    }
                  }}
                  min={0}
                  max={10}
                  value={values[10]}
                  onChange={(value: number) => setFieldValue('10', value)}
                  renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
                />
              </div>
              <Row styles={{ justifyContent: 'space-between', marginTop: 25 }}>
                <ScaledText>Low</ScaledText>
                <ScaledText>High</ScaledText>
              </Row>
              <Spacer top={50}>
                <Row center>{error && <ScaledText styles={{ color: 'red' }}>{`There was an error. Please try again`}</ScaledText>}</Row>
                <Row center>
                  <Button text="Submit" styles={{ width: 170, backgroundColor: theme.colors.primary1 }} onClick={submitForm} />
                </Row>
              </Spacer>
            </Column>
          )
        }
      }}
    </Formik>
  )
}

const StyledFormControl = styled(Form.Control)`
  background-color: #edfdff;
  border-radius: 5px;
  text-align: left;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
  border: 0px;
  resize: none;
  &:focus {
    outline: none;
    border-color: #yourColor;
  }
`

const StyledReflectionHeader = styled(Column)`
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  @media (${sizes.tablet}) {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
`
