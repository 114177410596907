export enum IntakeStep {
  Introduction = 5,
  Physicians,
  Medications,
  Allergies,
  LifeStyle,
  History,
  Progress,
  Confirmation,
}
