import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import { Physician } from '../types/Physician'

export interface PhysicianState {
  patientPhysicians: Physician[] | undefined
  status: 'idle' | 'loading' | 'failed'
}

const initialState: PhysicianState = {
  patientPhysicians: [],
  status: 'idle',
}

export const loadPhysicians = createAsyncThunk('physicians/load', async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(token).get('api/physicians', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const physicianSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPhysicians.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadPhysicians.fulfilled, (state, action) => {
        state.status = 'idle'
        state.patientPhysicians = action.payload.data
      })
      .addCase(loadPhysicians.rejected, (state) => {
        state.status = 'idle'
      })
  },
})

export const getPhysicians = (state: RootState) => state.root.physicians.patientPhysicians
export const getPhysiciansStatus = (state: RootState) => state.root.physicians.status

export default physicianSlice.reducer
