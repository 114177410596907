import React from 'react'
import { useNavigate } from 'react-router'
import { useTheme } from 'styled-components'
import Icon from '../../../Components/Base/Icon'
import { IconNames } from '../../../types/IconNames'
import './HelpCard.scss'

interface Props {
  title: string
  link: string
  icon: IconNames
  relative?: boolean
  subtitle?: string
}
export default function HelpCard({ title, icon, link, relative, subtitle }: Props) {
  const theme = useTheme()
  const navigate = useNavigate()

  const cardClick = (link: string) => {
    if (relative) {
      navigate(link)
      window.scrollTo(0, 0)
    } else {
      window.open(link, '_blank')
    }
  }

  return (
    <div onClick={() => cardClick(link)} className={'resource-card'}>
      <Icon name={icon} color={theme.colors.primary1} size={'3x'} styles={{ marginBottom: 10 }} />
      <h4 style={{ color: theme.colors.primary1, fontFamily: 'Raleway-Bold' }}>{title}</h4>
      {subtitle && (
        <h6 style={{ fontFamily: 'Raleway' }}>{subtitle}</h6>
      )}
    </div>
  )
}
