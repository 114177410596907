
import React, { useEffect, useState } from 'react'
import { Paragraph, SectionInfo } from 'types/Challenge'
import Typography from 'Components/Base/Typography'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function MicroStep2({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {

    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2])))
    setStepParagraphs(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]))?.paragraphs)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])


  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px', width: isMobile ? '100%' : '50%' }}>
          <Typography variant='h1'>{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>

        </Row>

        <ResponsiveContainer styles={{ width: isMobile ? '100%' : '110%', justifyContent: 'space-between', textAlign: 'center' }}>
          <Column>
            <img src={sectionInfo?.images.find((image) => image.imageId === 2)?.url} alt='scale' style={{ width: '228px', height: '228px', margin: isMobile ? '0px auto 20px' : '0px 0xp 20px', border: '1px solid black' }} />
            <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 4)?.paragraph ?? ''}</Typography>
          </Column>
          <Column>
            <img src={sectionInfo?.images.find((image) => image.imageId === 3)?.url} alt='scale' style={{ width: '228px', height: '228px', margin: isMobile ? '0px auto 20px' : '0px 0xp 20px', border: '1px solid black' }} />
            <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 5)?.paragraph ?? ''}</Typography>
          </Column>
          <Column>
            <img src={sectionInfo?.images.find((image) => image.imageId === 4)?.url} alt='scale' style={{ width: '228px', height: '228px', margin: isMobile ? '0px auto 20px' : '0px 0xp 20px', border: '1px solid black' }} />
            <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 6)?.paragraph ?? ''}</Typography>
          </Column>
          <Column>
            <img src={sectionInfo?.images.find((image) => image.imageId === 5)?.url} alt='scale' style={{ width: '228px', height: '228px', margin: isMobile ? '0px auto 20px' : '0px 0xp 20px', border: '1px solid black' }} />
            <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 7)?.paragraph ?? ''}</Typography>
          </Column>
        </ResponsiveContainer>
      </Column>
    </ResponsiveContainer>
  )
}

