import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import usePrograms from 'hooks/usePrograms'
import jsPDF from 'jspdf'
import React, { useEffect, useRef, useState } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import styled, { useTheme } from 'styled-components'
import { ProgramActivity } from 'types/STOP'
import { useScreenshot } from 'use-react-screenshot'
import TriaLogo from '../../../Assets/Images/Tria Logo_Primary.png'
import STOPPageContainer from '../components/STOPPageContainer'
import ScaledText from '../components/ScaledText'

export default function STOPCertificate() {
  const theme = useTheme()
  const token = useAppSelector(getAccessToken)
  const navigate = useNavigate()
  const { guid }: Readonly<Params<string>> = useParams()
  const analytics = useAnalytics(token!)

  const [screenshot, takeScreenshot] = useScreenshot()
  const { getCertificate } = usePrograms(token!)

  const [lastActivity, setLastActivity] = useState<ProgramActivity>()

  const certificateRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    analytics.postAnalyticsEvent({
      category: `view_page_stop`,
      action: `view_page_view_certificate`,
      label: `View`,
      screenName: 'StopCertificate',
    })
  }, [])

  useEffect(() => {
    screenshot && exportCertificate()
  }, [screenshot])

  useEffect(() => {
    guid &&
      getCertificate(guid, (res) => {
        if (res.data) {
          setLastActivity(res.data)
        } else {
          navigate('404')
        }
      })
  }, [guid])

  const captureImage = () => {
    analytics.postAnalyticsEvent({
      category: `click_button_stop`,
      action: `click_button_download_certificate`,
      label: `Button`,
      screenName: 'StopCertificate',
    })
    certificateRef.current && takeScreenshot(certificateRef.current)
  }

  const exportCertificate = () => {
    const doc = new jsPDF('landscape', 'mm', 'A4')
    doc.addImage(screenshot, 'JPEG', 0, 0, 298, 210)
    doc.save(`CompletionCertificate_${dayjs(lastActivity?.completedDate).format('YYYY-MM-DD')}.pdf`)
  }

  return (
    <>
      {lastActivity && (
        <>
          <Row styles={{ justifyContent: 'flex-end', width: '95%', marginTop: '2%' }}>
            <Button text="Download" styles={{ width: 170, backgroundColor: '#00A9CE' }} onClick={captureImage} />
          </Row>
          <STOPPageContainer styles={{ padding: '25px', position: 'relative' }} containerStyles={{ minHeight: 900 }}>
            <div style={{ height: 100, width: 70, backgroundColor: theme.colors.primary1, position: 'absolute', left: 0 }} />
            <div style={{ height: 100, width: 70, backgroundColor: theme.colors.primary1, position: 'absolute', right: 0 }} />
            <OuterBorderDiv ref={certificateRef}>
              <InnerBorderDiv>
                <Spacer top style={{ height: '95%' }}>
                  <Column styles={{ width: '80%', height: '100%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-between' }}>
                    <Row center>
                      <img src={TriaLogo} style={{ height: 90 }} />
                    </Row>
                    <div>
                      <Row center styles={{ marginBottom: 0 }}>
                        <ScaledText size={100} bold styles={{ color: theme.colors.primary1, letterSpacing: 15, height: 125 }}>
                          CERTIFICATE
                        </ScaledText>
                      </Row>
                      <Row center>
                        <ScaledText size={40} bold styles={{ color: theme.colors.black, letterSpacing: 10 }}>
                          OF COMPLETION
                        </ScaledText>
                      </Row>
                    </div>
                    <Row center>
                      <div style={{ backgroundColor: theme.colors.primary1, padding: '10px 50px' }}>
                        <ScaledText bold styles={{ color: 'white' }} size={15}>
                          PRESENTED TO:
                        </ScaledText>
                      </div>
                    </Row>
                    <div>
                      <Row center>
                        <ScaledText size={40} bold styles={{ color: theme.colors.black }}>
                          {`${lastActivity?.firstName} ${lastActivity?.lastName}`}
                        </ScaledText>
                      </Row>
                      <div style={{ width: '100%', borderBottom: '1px solid black' }} />
                      <Row center styles={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Spacer top>
                          <ScaledText styles={{ color: theme.colors.black, textAlign: 'center' }}>
                            This certificate attests that the individual listed above has fulfilled the requirements of Tria Health’s Tobacco
                            Cessation Program
                          </ScaledText>
                        </Spacer>
                      </Row>
                    </div>
                    <Row center>
                      <ScaledText styles={{ color: theme.colors.black, textAlign: 'center' }}>{`DATE OF COMPLETION: ${dayjs(
                        lastActivity?.completedDate,
                      ).format('MM/DD/YYYY')}`}</ScaledText>
                    </Row>
                  </Column>
                </Spacer>
              </InnerBorderDiv>
            </OuterBorderDiv>
          </STOPPageContainer>
        </>
      )}
    </>
  )
}

const OuterBorderDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 3px solid ${({ theme }) => theme.colors.primary1};
  border-radius: 90px;
`

const InnerBorderDiv = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 3px solid ${({ theme }) => theme.colors.primary1};
  border-radius: 10px;
`
