import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import PageContainer from 'Components/PageContainer'
import ProgramScreen from 'Components/ProgramScreen'
import { sizes } from 'Styles/sizes'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Challenge, ChallengeSection } from 'types/Challenge'
import ProgressBar from '../Components/ProgressBar/ProgressBar'
import { reflectionQuestionList } from '../Components/reflectionQuestion'

interface Props {
  isMobile: boolean
  currentChallenge?: Challenge
  setCurrentChallenge: (challenge: Challenge) => void
  step: number
  setStep: (step: number) => void
}
export default function ReflectionPage({ isMobile, setCurrentChallenge, currentChallenge, step, setStep }: Props) {
  const navigate = useNavigate()
  const token = useAppSelector(getAccessToken) ?? ''
  const { getChallenges, addPatientReflection, changeSectionStep, challengeCompletion } = useChallenges(token)
  const [section, setSection] = useState<ChallengeSection>()
  const [reflectionQuestionArray, setReflectionQuestionArray] = useState<{ section: number; question: string; tip?: string }[]>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    getChallenges()
      .then((res) => {
        setCurrentChallenge(
          res.data.challenges.find((challenge) =>
            challenge.challengeSections.find(
              (section) =>
                section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
            ),
          ),
        )
        setSection(
          res.data.challenges
            .find((challenge) =>
              challenge.challengeSections.find(
                (section) =>
                  section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
              ),
            )
            .challengeSections.find(
              (section) =>
                section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
            ),
        )
        setReflectionQuestionArray(
          reflectionQuestionList.filter(
            (q) => q.section === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
          ) ?? '',
        )
        formik.setFieldValue(
          'challengeSectionPId',
          res.data.challenges
            .find((challenge) =>
              challenge.challengeSections.find(
                (section) =>
                  section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
              ),
            )
            .challengeSections.find(
              (section) =>
                section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
            ).challengeSectionPId,
        )
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  const handleBackClick = () => {
    setStep(step - 1)
    navigate(`/challenge/section/${section?.challengeSectionPId}/${step - 1}`)
  }

  const handleNextStep = () => {
    changeSectionStep(section?.challengeSectionPId, step + 1).then(() =>
      getChallenges()
        .then((res) => {
          dispatch(setLoading(true))
          const challenge = res.data.challenges.find((challenge) =>
            challenge.challengeSections.find(
              (section) =>
                section?.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
            ),
          )
          setCurrentChallenge(challenge)
          if (challenge.numberOfCompletedSections === challenge.numberOfSections && !challenge.badgeEarned) {
            challenge && challengeCompletion(challenge.challengePId)
          }
        })
        .finally(() => {
          dispatch(setLoading(false))
        }),
    )
    reflectionQuestionArray && addPatientReflection(section?.challengeSectionPId, reflectionQuestionArray[0].question, formik.values.reflectionBody)
    reflectionQuestionArray &&
      reflectionQuestionArray.length > 1 &&
      addPatientReflection(section?.challengeSectionPId, reflectionQuestionArray[1].question, formik.values.reflectionBody2)
    navigate(`/challenge/complete/${section?.challengeSectionPId}`)
  }

  const initialValues = {
    challengeSectionPId: 0,
    reflectionBody: '',
    reflectionBody2: '',
  }

  const onSubmit = (links) => {
    //
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <div>
      <PageContainer>
        <Row>
          <Column styles={{ margin: '10px auto' }}>
            <ProgressBar stepPercent={100} />
            <Typography styles={{ margin: '10px auto' }} font="bold">
              {section?.name ?? ''}
            </Typography>
          </Column>
        </Row>
        <ProgramScreen
          content={
            <Column>
              <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
                <Icon name="sparkles" styles={{ transform: 'rotate(180deg)', marginRight: 10 }} size="2x" />
                <Typography variant="h1">Reflection</Typography>
              </Row>
              {reflectionQuestionArray && (
                <>
                  <Typography font="bold" size={20}>
                    {reflectionQuestionArray[0].question ?? ''}
                  </Typography>
                  {reflectionQuestionArray[0].tip && (
                    <p style={{ color: 'black', fontFamily: 'Raleway', marginTop: 20, fontSize: 20 }}>
                      <b>TIP:</b> {reflectionQuestionArray[0].tip ?? ''}
                    </p>
                  )}
                  <StyledFormInput
                    onChange={(value) => formik.setFieldValue('reflectionBody', value.currentTarget.value)}
                    value={formik.values['reflectionBody']}
                    style={{ minHeight: reflectionQuestionArray.length > 1 ? '200px' : '327px', marginTop: 30 }}
                  />
                </>
              )}
              {reflectionQuestionArray && reflectionQuestionArray.length > 1 && (
                <>
                  <Typography font="bold" size={20}>
                    {reflectionQuestionArray[1].question ?? ''}
                  </Typography>
                  {reflectionQuestionArray[1].tip && (
                    <p style={{ color: 'black', fontFamily: 'Raleway', marginTop: 20, fontSize: 20 }}>
                      <b>TIP:</b> {reflectionQuestionArray[1].tip ?? ''}
                    </p>
                  )}
                  <StyledFormInput
                    onChange={(value) => formik.setFieldValue('reflectionBody2', value.currentTarget.value)}
                    value={formik.values['reflectionBody2']}
                    style={{ minHeight: '200px', marginTop: 30 }}
                  />
                </>
              )}
            </Column>
          }
          imageWithBorder={!isMobile ? 'https://triacentral.blob.core.windows.net/dev-storage/portal/Challenges/Reflection_Thinking.png' : ''}
          altTag="man thinking"
        />
        <ResponsiveContainer>
          {!isMobile && (
            <StyledButton style={{ backgroundColor: '#425563' }} onClick={() => handleBackClick()}>
              Back
            </StyledButton>
          )}
          <StyledButton style={{ backgroundColor: '#00677F', marginTop: isMobile ? 30 : 10 }} onClick={() => handleNextStep()}>
            Submit
          </StyledButton>
        </ResponsiveContainer>
      </PageContainer>
    </div>
  )
}

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-right: 20px;
    margin-bottom: 0px;
  }
`
const StyledInput = styled.textarea`
  border-radius: 3px;
  display: block;
  outline: none !important;
  margin-top: 10px;
  margin-bottom: 10px;
  resize: none;
`

const StyledFormInput = styled(StyledInput)`
  background-color: #b8dde1;
  box-shadow: inset 0px 1px 3px;
`
