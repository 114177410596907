import { CustomTheme, createStyles, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import './TextSettingsCard.scss'
import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import useDashboard from 'hooks/useDashboard'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { DashboardType } from 'types/Dashboards'
import * as Yup from 'yup'

interface Props {
  className?: string
  maximumReading?: number
  highSystolic?: number
  highDiastolic?: number
  type: DashboardType
}

interface FormValues {
  maximumValue: number | undefined
  highSystolic: number | undefined
  highDiastolic: number | undefined
  type: number
}

export default function TextSettingsCard({ className, maximumReading, highSystolic, highDiastolic, type }: Props) {
  const navigate = useNavigate()
  const { updateGlucoseConfig, updateBloodPressureConfig } = useDashboard()
  const [attemptedUpdate, setAttemptedUpdate] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean | undefined>()
  const [updating, setUpdating] = useState(false)
  const styles = useStyles()
  const theme = useTheme()

  const updateConfigValues = (values: FormValues) => {
    setUpdating(true)
    switch (type) {
      case DashboardType.Glucose:
        values.maximumValue &&
          updateGlucoseConfig(values.maximumValue)
            .then((res) => {
              if (res.status === 200) {
                setSuccess(true)
              }
            })
            .finally(() => setUpdating(false))
        break

      case DashboardType.BloodPressure:
        values.highSystolic &&
          values.highDiastolic &&
          updateBloodPressureConfig(values.highSystolic, values.highDiastolic)
            .then((res) => {
              if (res.status === 200) {
                setSuccess(true)
              }
            })
            .finally(() => setUpdating(false))
        break
    }
  }

  const initialValues: FormValues = {
    maximumValue: maximumReading,
    highSystolic: highSystolic,
    highDiastolic: highDiastolic,
    type: type,
  }

  const validationSchema = Yup.object().shape({
    maximumValue: Yup.number()
      .typeError('Must be a number')
      .when('type', {
        is: DashboardType.Glucose,
        then: Yup.number().required('Limit is required'),
      }),
    highSystolic: Yup.number().when('type', {
      is: DashboardType.BloodPressure,
      then: Yup.number().typeError('Must be a number').required('Systolic is required').max(180, 'Systolic max is 180'),
    }),
    highDiastolic: Yup.number().when('type', {
      is: DashboardType.BloodPressure,
      then: Yup.number().typeError('Must be a number').required('Diastolic is required').max(110, 'Diastolic max is 110'),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => updateConfigValues(values),
  })

  useEffect(() => {
    formik.setFieldValue('maximumValue', maximumReading)
    formik.setFieldValue('highSystolic', highSystolic)
    formik.setFieldValue('highDiastolic', highDiastolic)
    formik.setFieldValue('type', type)
  }, [maximumReading, highSystolic, highDiastolic, type])

  return (
    <StyledContainer className="settings-card">
      <StyledColumn>
        <StyledHeaderRow>
          <Typography font="bold" size={20}>
            {`My Blood ${type === DashboardType.Glucose ? 'Glucose' : 'Pressure'} Text Settings`}
          </Typography>
        </StyledHeaderRow>
        {type === DashboardType.Glucose ? (
          <>
            <Row center>
              <Typography font="bold" color={theme.colors.black} size={20}>
                Critical Low Reading Alert:
              </Typography>
            </Row>
            <Row center>
              <Typography font="regular" color={theme.colors.black} size={20}>
                {`< 55 (mg/dL)`}
              </Typography>
            </Row>
            <hr style={{ height: 2, width: '85%', backgroundColor: 'black' }} />
            <Row center>
              <Typography font="bold" color={theme.colors.black} size={20}>
                Low Reading Alert:
              </Typography>
            </Row>
            <Row center>
              <Typography font="regular" color={theme.colors.black} size={20}>
                {`55-70 (mg/dL)`}
              </Typography>
            </Row>
            <hr style={{ height: 2, width: '85%', backgroundColor: 'black' }} />
            <Row center>
              <Typography font="bold" color={theme.colors.black} size={20}>
                High Reading Alert:
              </Typography>
            </Row>
            <Row center styles={{ marginBottom: 25 }}>
              <Typography font="regular" color="neutral3" size={20}>
                {`> 250 (mg/dL)`}
              </Typography>
            </Row>
          </>
        ) : (
          <>
            <Row center>
              <Typography font="bold" color={theme.colors.black} size={20}>
                Low Reading Alert:
              </Typography>
            </Row>
            <Row center>
              <Typography font="regular" color={theme.colors.black} size={20}>
                {`< 90 Systolic or < 50 Diastolic`}
              </Typography>
            </Row>
            <hr style={{ height: 2, width: '85%', backgroundColor: 'black' }} />
            <Row center>
              <Typography font="bold" color={theme.colors.black} size={20}>
                High Reading Alert:
              </Typography>
            </Row>
            <Row center styles={{ marginBottom: 25 }}>
              <Typography font="regular" color="neutral3" size={20}>
                {`> 180 Systolic or > 110 Diastolic`}
              </Typography>
            </Row>
          </>
        )}
      </StyledColumn>
      <StyledColumn
        styles={{
          paddingBottom: 20,
          paddingTop: 20,
          justifyContent: 'space-between',
        }}
      >
        <Column center>
          <Typography font="bold" color={theme.colors.primary1} size={20} styles={{ textAlign: 'center' }}>
            Customize Your High Reading Alert:
          </Typography>
          <hr className={styles.divider} />
        </Column>
        {type === DashboardType.Glucose ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
            }}
          >
            <Form.Control
              onChange={(e) => formik.setFieldValue('maximumValue', e.currentTarget.value)}
              value={formik.values.maximumValue}
              style={{ width: '125px', marginRight: 10, marginBottom: 0 }}
              placeholder=""
              className="login-input"
            />
            <Column center>
              <Typography font="semi-bold" color={theme.colors.black} size={20}>
                (mg/dL)
              </Typography>
            </Column>
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <Form.Control
                type={'number'}
                onChange={(e) => formik.setFieldValue('highSystolic', e.currentTarget.value)}
                value={formik.values.highSystolic}
                style={{ width: '125px', marginRight: 10, marginBottom: 0 }}
                placeholder=""
                className="login-input"
              />
              <Column center>
                <Typography font="semi-bold" color={theme.colors.black} size={20}>
                  /
                </Typography>
              </Column>
              <Form.Control
                type={'number'}
                onChange={(e) => formik.setFieldValue('highDiastolic', e.currentTarget.value)}
                value={formik.values.highDiastolic}
                style={{ width: '125px', marginLeft: 10, marginBottom: 0 }}
                placeholder=""
                className="login-input"
              />
            </div>
            <Row center>
              <Typography font="bold" color={theme.colors.black} size={15}>
                Maximum High Setting: 180/110
              </Typography>
            </Row>
          </>
        )}
        <div>
          {success === true && attemptedUpdate && (
            <Row center>
              <Typography font="bold" color={theme.colors.primary1} size={15}>
                Settings Updated!
              </Typography>
            </Row>
          )}
          {success === false && attemptedUpdate && (
            <Row center>
              <Typography font="bold" color="tertiary1" size={15}>
                Error updating settings
              </Typography>
            </Row>
          )}
          <Row center>
            {formik.errors.maximumValue && attemptedUpdate && (
              <StyledErrorText styles={{ textAlign: 'center', marginBottom: 10 }}>{formik.errors.maximumValue}</StyledErrorText>
            )}
          </Row>
          <Row center>
            {formik.errors.highSystolic && attemptedUpdate && (
              <StyledErrorText styles={{ textAlign: 'center', marginBottom: 10 }}>{formik.errors.highSystolic}</StyledErrorText>
            )}
          </Row>
          <Row center>
            {formik.errors.highDiastolic && attemptedUpdate && (
              <StyledErrorText styles={{ textAlign: 'center', marginBottom: 10 }}>{formik.errors.highDiastolic}</StyledErrorText>
            )}
          </Row>
        </div>
        <div className={styles.buttonRow}>
          <Button
            pageName="dashboardGlucose"
            className={styles.button}
            styles={{
              backgroundColor: '#00677F',
            }}
            onClick={() => {
              setAttemptedUpdate(true)
              formik.handleSubmit()
            }}
            loading={updating}
            text={'Update'}
          />
          <Button
            pageName="dashboardGlucose"
            className={styles.button}
            styles={{
              backgroundColor: theme.colors.darkGray,
            }}
            onClick={() => {
              window.scrollTo(0, 0)
              navigate('/profile/access')
            }}
            text={'Add a Caregiver'}
          />
        </div>
      </StyledColumn>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-direction: column;
  @media ${sizes.tablet} {
    flex-direction: row;
  }
`

const StyledColumn = styled(Column)`
  border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
  width: 100%;
  @media ${sizes.laptop} {
    width: 49%;
  }
`

const StyledHeaderRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
`

const useStyles = makeStyles<CustomTheme>((theme) =>
  createStyles({
    divider: {
      height: 2,
      width: '85%',
      backgroundColor: '#00677F',
    },
    column: {
      border: `1px solid #828282`,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      paddingBottom: 20,
      marginBottom: 20,
    },
    buttonRow: {
      justifyContent: 'space-evenly',
      display: 'flex',
      marginTop: 10,
    },
    button: {
      border: '0px',
      minWidth: '45%',
      borderRadius: 4,
      maxWidth: '40%',
      paddingTop: 10,
      paddingBottom: 10,
    },
  }),
)
