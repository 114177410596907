import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function Tooltip({ text }: { text: string }) {
  return (
    <>
      <ReactTooltip />
    </>
  )
}
