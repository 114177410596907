import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import styled, { useTheme } from 'styled-components'

type Props = {
  title: string
  range: string
  color: string
  rotation: number
}

export default function PieChartKeyRange({ title, range, color, rotation }: Props) {
  const theme = useTheme()

  return (
    <StyledContainer>
      <Row styles={{ zIndex: 1, backgroundColor: theme.colors.white, alignItems: 'start' }}>
        <StyledIcon style={{ backgroundColor: color }}>
          <Icon name="arrow-up-solid" color={'white'} styles={{ transform: `rotate(${rotation}deg)` }} />
        </StyledIcon>
        <Typography>{title}</Typography>
      </Row>
      <Typography
        styles={{
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        ..............................................................................................................................................................................................................................................................
      </Typography>
      <StyledRangeText>{range}</StyledRangeText>
    </StyledContainer>
  )
}

const StyledContainer = styled(Row)`
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: white;
`

const StyledIcon = styled.div`
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  border-radius: 3px;
  margin-right: 5px;
`

const StyledRangeText = styled(Typography)`
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding-right: 15px;
`
