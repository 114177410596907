import { SelectButtonProps } from 'Components/Base/SelectionButton'

export const subtitleText = `After providing your appointment preferences, a member advocate will give you a quick call within the next 24-72 hours to provide you with available appointment times.`

export const times: SelectButtonProps[] = [
  { title: 'No Preference', selected: false, value: 0 },
  {
    title: 'Early Morning',
    subtitle: '8am - 10am',
    selected: false,
    value: 1,
  },
  {
    title: 'Late Morning',
    subtitle: '8am - 10am',
    selected: false,
    value: 2,
  },
  {
    title: 'Early Afternoon',
    subtitle: '8am - 10am',
    selected: false,
    value: 3,
  },
  {
    title: 'Late Afternoon',
    subtitle: '8am - 10am',
    selected: false,
    value: 4,
  },
  { title: 'Evening', subtitle: '8am - 10am', selected: false, value: 5 },
]
