import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'

export default function PreRegistration() {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const navigate = useNavigate()
  const navigateToUrl = (url: string) => {
    window.location.href = url
  }
  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  function backPress() {
    navigate('/')
    window.scrollTo(0, 0)
  }

  return (
    <div
      className="profile-page-container"
      style={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border: '1px solid #e0e0e0',
        width: '95%',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <Typography size={isMobile ? 30 : 50} styles={{ fontWeight: 'bold' }}>
          We are Dedicated to Protecting
        </Typography>
        <Typography size={isMobile ? 30 : 50} styles={{ fontWeight: 'bold' }}>
          Your Health Information
        </Typography>
        {isMobile ? (
          <Column styles={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
            <Typography styles={{ marginLeft: 'auto' }}>In order to register on Tria Health’s patient portal, you’ll need to provide your</Typography>
            <Typography styles={{ fontWeight: 'bold' }}>10 digit Tria ID.</Typography>
          </Column>
        ) : (
          <Row styles={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
            <Typography styles={{ marginLeft: 'auto' }}>In order to register on Tria Health’s patient portal, you’ll need to provide your</Typography>
            <Typography styles={{ marginLeft: 5, fontWeight: 'bold', marginRight: 'auto' }}>10 digit Tria ID.</Typography>
          </Row>
        )}
        <Typography>Your Tria ID is a safe and secure method that allows us to confirm your identity.</Typography>
        <h1
          style={isMobile ? { marginTop: 20, fontSize: 20, fontFamily: 'Raleway-bold' } : { marginTop: 20, fontSize: 28, fontFamily: 'Raleway-bold' }}
        >
          Where Can You Find{' '}
          <span style={{ color: '#00677F', textDecoration: 'underline' }}>
            <a href="https://youtu.be/jcCssf4OWyA" target="_blank" rel="noreferrer">
              Your Tria ID?
            </a>
          </span>
        </h1>
        {!isMobile && (
          <Row>
            <Column styles={{ marginTop: 20, marginRight: 20, marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Pre-reg1.png"
                alt="broschure"
                style={{ width: 230, marginBottom: 20 }}
              />
              <Typography size={20} variant="h1">
                Letter
              </Typography>
              <Typography>Check to see if you’ve</Typography>
              <Typography>received any letters</Typography>
              <Typography>from Tria Health. Your ID</Typography>
              <Typography> is listed within the letter.</Typography>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 20 }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Pre-reg2.png"
                alt="broschure"
                style={{ width: 230, marginBottom: 20 }}
              />
              <Typography size={20} variant="h1">
                Phone Call
              </Typography>
              <Typography>Call the Tria</Typography>
              <Typography> Health Help Desk:</Typography>
              <Typography>{PhoneNumberPeriodDelimited}</Typography>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Pre-reg3.png"
                alt="broschure"
                style={{ width: 230, marginBottom: 20 }}
              />
              <Typography size={20} variant="h1">
                Chat
              </Typography>
              <Typography>Click on the chat</Typography>
              <Typography>bubble in the bottom</Typography>
              <Typography>right-hand corner.</Typography>
            </Column>
          </Row>
        )}
        {isMobile && (
          <Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Pre-reg1.png"
                alt="broschure"
                style={{ width: 230, marginBottom: 10 }}
              />
              <Typography size={20} variant="h1">
                Letter
              </Typography>
              <Typography>Check to see if you’ve</Typography>
              <Typography>received any letters</Typography>
              <Typography>from Tria Health. Your ID</Typography>
              <Typography> is listed within the letter.</Typography>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Pre-reg2.png"
                alt="broschure"
                style={{ width: 230, marginBottom: 10 }}
              />
              <Typography size={20} variant="h1">
                Phone Call
              </Typography>
              <Typography>Call the Tria</Typography>
              <Typography> Health Help Desk:</Typography>
              <Typography>{PhoneNumberPeriodDelimited}</Typography>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Pre-reg3.png"
                alt="broschure"
                style={{ width: 230, marginBottom: 10 }}
              />
              <Typography size={20} variant="h1">
                Chat
              </Typography>
              <Typography>Click on the chat</Typography>
              <Typography>bubble in the bottom</Typography>
              <Typography>right-hand corner.</Typography>
            </Column>
          </Column>
        )}
        <Row styles={{ marginBottom: 40, marginTop: 30 }}>
          <Button
            pageName="pre-registration"
            text="Back"
            backgroundColor="grey"
            width={150}
            onClick={() => backPress()}
            styles={{ marginLeft: 'auto', marginRight: 20 }}
          ></Button>
          <Button
            pageName="pre-registration"
            text="Next"
            width={150}
            styles={{ marginRight: 'auto' }}
            onClick={() => navigateToUrl(`${process.env.REACT_APP_AUTH_REGISTER_URL}?${''}returnUrl=${window.location.origin}`)}
          ></Button>
        </Row>
      </div>
    </div>
  )
}
