import React from 'react'
import styled, { useTheme } from 'styled-components'
import Row from '../Row'
import Typography from '../Typography'

interface Props {
  onClick?: () => void
  label?: string
  checked: boolean
  styles?: React.CSSProperties
  containerStyles?: React.CSSProperties
  checkStyles?: React.CSSProperties
}
export default function Checkbox({ onClick, label, styles, containerStyles, checked = false, checkStyles }: Props) {
  const theme = useTheme()

  return (
    <Row styles={{ ...containerStyles }}>
      <StyledCheckbox style={{ ...styles }} onClick={onClick}>
        {checked && <StyledCheck style={{ ...checkStyles }} />}
      </StyledCheckbox>
      {label && (
        <Typography color={theme.colors.black} styles={{ marginLeft: 5, marginRight: 5 }}>
          {label}
        </Typography>
      )}
    </Row>
  )
}

const StyledCheckbox = styled.div`
  background-color: #edfdff;
  border-radius: 3px;
  margin-left: 2;
  box-shadow: inset 0px 1px 3px black;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledCheck = styled.div`
  background-color: ${({ theme }) => theme.colors.primary1};
  height: 60%;
  width: 60%;
  margin-left: 0 auto;
  margin-right: 0 auto;
`
