import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'

type Props = {
  content: JSX.Element
  image?: string
  altTag?: string
  imageWithBorder?: string
}

export default function ProgramScreen({ content, image, altTag, imageWithBorder }: Props) {
  return (
    <StyledResponsiveContainer>
      <StyledColumn>{content}</StyledColumn>
      {(image || imageWithBorder) &&
        <ResponsiveContainer styles={{ width: '10%' }} />
      }
      {image &&
      <StyledColumn>
        <StyledImage src={image} alt={altTag} />
      </StyledColumn>
      }
      {imageWithBorder &&
      <StyledColumn>
        <StyledSectionImage src={imageWithBorder} alt={altTag} />
      </StyledColumn>
      }
    </StyledResponsiveContainer>
  )
}

const StyledColumn = styled(Column)`
  flex: 1;
  width: 100%;
  @media (${sizes.tablet}) {
    width: 45%;
    overflow: none;
  }
`
const StyledResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (${sizes.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 800px;
`

const StyledSectionImage = styled.img`
  object-fit: cover;
  border: 1px solid black;
  height: 454px;
  width: 454px;
  margin-left: auto;
  margin-top: 60px;
  max-height: 800px;
`
