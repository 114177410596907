import { useApi } from './useAPI'

export default function usePharmacistPage() {
  const TriaApi = useApi()

  const getPharmacistPageData = async (pharmacistId: string) => {
    const pharmacistPageData = TriaApi.get(`api/pharmacistpages/${pharmacistId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return pharmacistPageData
  }

  return { getPharmacistPageData }
}
