import Column from 'Components/Base/Column'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactChild | React.ReactChild[]
  styles?: React.CSSProperties
}
export default function PageContainer({ children, styles }: Props) {
  return (
    <StyledOuterContainer>
      <StyledInnerContainer styles={{ ...styles }}>{children}</StyledInnerContainer>
    </StyledOuterContainer>
  )
}

const StyledOuterContainer = styled.div`
  min-height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
`
const StyledInnerContainer = styled(Column)`
  width: 90%;
  margin-bottom: 50;
  margin: 10px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #e0e0e0;
  padding: 5px 5px;
  max-width: 1500px;
  @media (${sizes.tablet}) {
    justify-content: center;
    padding: 25px 50px;
    margin: 50px 0px;
  }
`
