import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  styles?: React.CSSProperties
  className?: string
  color?: string
  size?: number
  mobileOnly?: boolean
  bold?: boolean
}

export default function PageTitle({ title, styles, className, color, size, mobileOnly, bold }: Props) {
  return (
    <StyledTitle className={className} mobileOnly={mobileOnly} bold={bold} style={{ ...styles, color: color, fontSize: size }}>
      {title}
    </StyledTitle>
  )
}
const StyledTitle = styled.h1`
  font-size: 8vw;
  font-family: ${(props: Partial<Props>) => (props.bold ? 'Raleway-Bold' : 'Raleway-Semi-Bold')};
  @media ${sizes.tablet} {
    font-size: 3vw;
    display: ${(props: Partial<Props>) => (props.mobileOnly ? 'none' : 'block')};
  }
`
