import Button from 'Components/Base/Button'
import Row from 'Components/Base/Row'
import SelectionButton, { SelectButtonProps } from 'Components/Base/SelectionButton'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import './ConditionsForm.scss'
import { subtitleText, times } from './constants'
import { StyledErrorText } from 'Styles/global'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  saveAvailabilityForm: (availabilityInfo: AvailabilityFormValues) => void
  onBackPressed: () => void
  loading?: boolean
  errorMessage?: string
}

export interface AvailabilityFormValues {
  enrollmentFormGuid?: string
  days: number[]
  time: number
}

export default function AvailabilityForm({ saveAvailabilityForm, onBackPressed, loading, errorMessage }: Props) {
  const theme = useTheme()

  const [selectedTime, setSelectedTime] = useState<number>(0)
  const [selectedDays, setSelectedDays] = useState<SelectButtonProps[]>([
    { title: 'Monday', selected: false, value: 1 },
    { title: 'Tuesday', selected: false, value: 2 },
    { title: 'Wednesday', selected: false, value: 3 },
    { title: 'Thursday', selected: false, value: 4 },
    { title: 'Friday', selected: false, value: 5 },
  ])

  const toggleDays = (time: SelectButtonProps) => {
    const copy = selectedDays.map((x) => {
      if (x.value === time.value) {
        return Object.assign({}, x, {
          selected: !x.selected,
        })
      } else {
        return x
      }
    })
    setSelectedDays(copy)
  }

  const submitForm = () => {
    const days: number[] = []
    selectedDays.map((time) => {
      if (time.selected) {
        days.push(time.value)
      }
    })
    saveAvailabilityForm({ days: days, time: selectedTime })
  }

  return (
    <>
      <div
        className="container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '80vh',
          marginTop: 20,
          minWidth: '90% !important',
        }}
      >
        <h1 className="id-header">What is your availability?</h1>
        <div className="conditions-subtitle">{subtitleText}</div>
        <div className="medcount-text">Preferred days of the week:</div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {selectedDays.map((x) => {
            return <SelectionButton title={x.title} subtitle={x.subtitle} icon={x.icon} selected={x.selected} onClick={() => toggleDays(x)} />
          })}
        </div>
        <div className="medcount-text">Preferred time of day (CST):</div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {times.map((x) => {
            return <SelectionButton title={x.title} icon={x.icon} selected={x.value === selectedTime} onClick={() => setSelectedTime(x.value)} />
          })}
        </div>
        <Row
          styles={{
            justifyContent: 'space-between',
            marginBottom: 20,
            marginTop: 10,
          }}
          center
        >
          <Typography>
            Initial consultations typically last between 20-50 minutes. Follow-up consultations are usually shorter. Because Tria Health is a benefit
            through your health insurance, we need to call and verifiy your identity before booking your appointment. Once verified, your follow-up
            consultations can be booked exclusively online.
          </Typography>
        </Row>
        {errorMessage && (
          <Row
            styles={{
              justifyContent: 'center',
              marginTop: 10,
              textAlign: 'center',
            }}
          >
            <StyledErrorText>{errorMessage}</StyledErrorText>
          </Row>
        )}
        <ResponsiveContainer styles={{ marginBottom: 25, justifyContent: 'center' }}>
          <StyledButton pageName="appointment_availability" text="Back" styles={{ backgroundColor: theme.colors.darkGray }} onClick={onBackPressed} />
          <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
          <StyledButton pageName="appointment_availability" text="Submit" loading={loading} onClick={submitForm} />
        </ResponsiveContainer>
      </div>
    </>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary1};
  @media ${sizes.tablet} {
    width: 25%;
  }
`
