import axios from 'axios'
import { useLogger } from '../hooks/useLogger'

export const useApi = (token?: string, override?: string) => {
  const { logError } = useLogger()
  axios.defaults.baseURL = override ?? process.env.REACT_APP_API_BASE_URL
  axios.defaults.headers['Content-Type'] = 'application/json'
  if (token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`
  }

  const TriaApi = axios.create()

  TriaApi.interceptors.request.use(
    async (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  TriaApi.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      const method: string = err.config.method
      const url = err.request.responseURL
      const status = err.response

      const error = url && method ? `Unhandled Request Error:  ${method} ${url}` : `Unhandled Request Error`

      if (status !== 401) {
        logError({ url, error, method })
      }

      return Promise.reject(error)
    },
  )
  return TriaApi
  //return { TriaApi, CancelToken }
}
