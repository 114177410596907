import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import React from 'react'
import { useTheme } from 'styled-components'
import { ChartReading } from 'types/ChartReading'
import { ScaleReading } from 'types/Dashboards'
import * as Victory from 'victory'
import '../../DashboardScalePage.scss'

type Props = {
  readings: ScaleReading[]
  chartReadings: ChartReading[] | undefined
  isMobile: boolean
  chartHeight: number
}

export default function LineChart({ readings, chartReadings, chartHeight, isMobile }: Props) {
  const theme = useTheme()
  const yAxisMax = readings && readings.length > 0 ? Math.max(...readings.map((reading) => reading.value)) + 10 : 250
  const yAxisMin = readings && readings.length > 0 ? Math.min(...readings.map((reading) => reading.value)) - 10 : 0

  return (
    <div className="scale-line-chart-card">
      {readings && readings.length <= 0 && (
        <Typography font="semi-bold" styles={{ position: 'absolute', top: '50%' }}>
          No Data
        </Typography>
      )}
      <div style={{ backgroundColor: 'white', marginLeft: 20 }}>
        <Victory.VictoryChart
          padding={{
            top: isMobile ? 50 : 50,
            bottom: isMobile ? 50 : 40,
            right: 30,
            left: 60,
          }}
          width={0.9 * window.innerWidth}
          style={{
            parent: { touchAction: 'manipulation' },
          }}
          height={chartHeight}
          maxDomain={readings ? { y: yAxisMax } : { y: 350 }}
          minDomain={readings ? { y: yAxisMin } : { y: 0 }}
          containerComponent={<Victory.VictoryVoronoiContainer />}
        >
          <Victory.VictoryAxis
            tickCount={5}
            width={20}
            tickFormat={(tick) => {
              if (90 <= 1) {
                return dayjs(tick).format('h:mm a')
              } else {
                return dayjs(tick).format('M/DD')
              }
            }}
            style={{
              grid: {
                stroke: ({ tick }) => (tick === -10 ? 'transparent' : '#ECECEC'),
                strokeWidth: 1,
              },
              axis: { stroke: 'transparent' },
              tickLabels: {
                fill: '#000',
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
          <Victory.VictoryAxis
            dependentAxis
            label="Weight (lbs)"
            tickCount={6}
            crossAxis={false}
            style={{
              grid: {
                stroke: ({ tick }) => (tick === -10 ? 'transparent' : '#ECECEC'),
                strokeWidth: 1,
              },
              axis: { stroke: '#ECECEC', strokeWidth: 0 },
              axisLabel: { fontSize: 20, padding: 40, fill: '#000', fontFamily: 'Raleway', fontWeight: 500 },
              ticks: { strokeWidth: 0 },
              tickLabels: {
                fill: '#000',
                fontWeight: 500,
                fontSize: 16,
              },
            }}
          />

          <Victory.VictoryLine
            data={chartReadings}
            style={{
              data: { stroke: theme.colors.primary1, strokeWidth: 1.5 },
            }}
            x="date"
            y="value"
            interpolation="linear"
          />
          <Victory.VictoryScatter
            data={chartReadings}
            style={{
              data: { stroke: theme.colors.primary1, strokeWidth: 1.5, fill: theme.colors.primary1 },
            }}
            size={5}
            x="date"
            y="value"
            labels={(d) => {
              return `${d.datum.value}\n${d.datum.date}`
            }}
            labelComponent={
              <Victory.VictoryTooltip
                cornerRadius={4}
                flyoutStyle={{
                  fill: 'white',
                  stroke: theme.colors.primary1,
                  strokeWidth: 1.5,
                }}
              />
            }
          />
        </Victory.VictoryChart>
      </div>
    </div>
  )
}
