import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import styled, { useTheme } from 'styled-components'
import { ScaleRow } from 'types/Dashboards'
import './pagination.scss'

interface Props {
  tableRows: ScaleRow[] | undefined
}
export default function MobileScaleDataGrid({ tableRows }: Props) {
  const [expandedSection, setExpandedSection] = useState<number | undefined>()

  const theme = useTheme()

  const toggleSection = (sectionId: number) => {
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }
  const [itemOffset, setItemOffset] = useState(0)
  const rowsPerPage = 5

  const endOffset = itemOffset + rowsPerPage

  const currentRows = tableRows?.slice(itemOffset, endOffset)

  const pageCount = (tableRows && Math.ceil(tableRows.length / rowsPerPage)) ?? 1
  const handlePageClick = (event) => {
    const newOffset = (tableRows && (event.selected * rowsPerPage) % tableRows.length) ?? 0
    setItemOffset(newOffset)
  }

  return (
    <>
      <StyledAccordion style={{ marginTop: -70 }}>
        {currentRows &&
          currentRows.map((row, index) => (
            <Card key={index} style={index % 2 !== 0 ? { display: 'block' } : { backgroundColor: '#f2f2f2' }}>
              <Accordion.Toggle
                as={Card.Header}
                style={
                  expandedSection === index
                    ? { backgroundColor: theme.colors.primary1, color: 'white', marginLeft: '30' }
                    : { justifyContent: 'space-between' }
                }
                eventKey={`${index}`}
                onClick={() => toggleSection(index)}
              >
                <div>
                  <Typography styles={{ marginLeft: 0, marginRight: '5px' }} size={20}>
                    {row.readingDate}
                  </Typography>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 20, textAlign: 'right' }}>
                  <Typography size={20}>{row.weight} lbs</Typography>
                </div>

                {expandedSection === index ? <Icon name="chevron-down" /> : <Icon name="chevron-right" />}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${index}`}>
                <Card.Body>
                  <div>
                    <Row>
                      <Typography font="bold">{`Weight: `}</Typography>
                      <Typography styles={{ marginLeft: 5 }}>{` ${row.weight}`}</Typography>
                    </Row>
                    <Row>
                      <Typography font="bold">{`Weight Change: `}</Typography>
                      <Typography styles={{ marginLeft: 5 }}>{` ${row.change}`}</Typography>
                    </Row>
                    <Row>
                      <Typography font="bold">{`Reading Time: `}</Typography>
                      <Typography styles={{ marginLeft: 5 }}>{`${row.readingTime}`}</Typography>
                    </Row>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
      </StyledAccordion>
      <ReactPaginate
        activeClassName={'item active '}
        breakClassName={'item break-me '}
        containerClassName={'pagination'}
        disabledClassName={'disabled-page'}
        pageClassName={'page'}
        marginPagesDisplayed={1}
        nextLinkClassName={'item next '}
        disabledLinkClassName={'disabled-arrow'}
        previousLinkClassName={'item previous'}
        breakLabel="..."
        nextLabel={<Icon name="chevron-right" size="1x" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<Icon name="chevron-left" size="1x" />}
      />
    </>
  )
}

const StyledAccordion = styled(Accordion)`
  border-radius: 10px;
  z-index: 50px;
  position: relative;
`
