import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import BloodPressureLinks from '../../../Assets/Data/BloodPressureLinks'
import Column from '../../../Components/Base/Column'
import { useAppSelector } from '../../../hooks/hooks'
import useProfile from '../../../hooks/useProfile'
import { getAccessToken } from '../../../reducers/authSlice'
import ProgramInfo from '../components/ProgramInfo'
import SignupForm, { ImageType } from '../components/SignupForm'
import '../ManagementPages.scss'

export default function BloodPressureManagementPage() {
  const token = useAppSelector(getAccessToken)

  const [searchParams, setSearchParams] = useSearchParams()
  const [welltrak, setWelltrak] = useState()
  const { getWelltrakFromGuid } = useProfile(token!)

  useEffect(() => {
    const id = searchParams.get('id')
    const ID = searchParams.get('ID')

    if (id !== null) {
      getWelltrakFromGuid(id).then((res: any) => {
        setWelltrak(res.data)
      })
    }
    if (ID !== null) {
      getWelltrakFromGuid(ID).then((res: any) => {
        setWelltrak(res.data)
      })
    }
  }, [])

  return (
    <div className="management-page-container">
      <Column className="program-info">
        <ProgramInfo
          descriptionClassName="program-info-description"
          title="Blood Pressure Management"
          videoUrl="https://www.youtube.com/watch?v=Ae_Jlnjfyao"
          textDetail1="Thank you for choosing Tria Health’s Blood Pressure Management Program. You and your pharmacist have determined you need to regularly monitor your blood pressure. For individuals with high blood pressure, understanding your blood pressure can effectively reduce complications."
          textDetail2="Your new Tria Health cuff allows for easy setup, testing and sharing with your designated providers. By observing trends, your pharmacist will be able to provide better recommendations because they’ll know how your current medications are impacting your health."
          additionalLinks={BloodPressureLinks}
        />
      </Column>
      <Column className="management-page-signup">
        <SignupForm welltrak={welltrak} imageType={ImageType.BPMP} />
      </Column>
    </div>
  )
}
