/* eslint-disable react-hooks/exhaustive-deps */
import { sizes } from 'Styles/sizes'
import React, { useEffect, useState } from 'react'
import { Step as BarStep, ProgressBar as ProgressStepBar } from 'react-step-progress-bar'
import 'react-step-progress-bar/styles.css'
import styled, { useTheme } from 'styled-components'
import { Step } from 'types/ProgressBar'
import Typography from '../Base/Typography'

interface Props {
  steps: Step[]
  currentStep: number
  showSteps?: boolean
  height?: number
  color?: string
}

export default function ProgressBar({ steps, currentStep, height = 5, showSteps = true, color }: Props) {
  const theme = useTheme()
  const [progress, setProgress] = useState<number>(20)

  useEffect(() => {
    setPercentComplete(steps)
  }, [])

  useEffect(() => {
    setPercentComplete(steps)
  }, [currentStep])

  const setPercentComplete = (steps: Step[]) => {
    const stepsComplete = steps.filter((x, index) => index <= steps.findIndex((step) => step.id === currentStep)).length - 1
    return setProgress((stepsComplete / steps.length - 1) * 100 === -20 ? 20 : (stepsComplete / (steps.length - 1)) * 100)
  }

  return (
    <StyledContainer>
      <ProgressStepBar percent={progress} filledBackground={color ?? theme.colors.primary1} height={height} style={{ border: '1px solid black' }}>
        {showSteps ? (
          steps.map((step, index) => (
            <BarStep transitionDuration={500000}>
              {() => (
                <div>
                  <div className={`indexedStep ${index <= steps.findIndex((step) => step.id === currentStep) ? 'accomplished' : ''}`} />
                </div>
              )}
            </BarStep>
          ))
        ) : (
          <div></div>
        )}
      </ProgressStepBar>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  margin-left: 20px;
  margin-bottom: 50px;
  margin-right: 20px;
  @media ${sizes.tablet} {
    width: 85%;
    margin: auto;
  }
`

const StyledStepTitle = styled(Typography)`
  margin-top: 10px !important;
  font-family: Raleway-Semi-Bold !important;
  position: absolute;
  left: -65px;
  width: 150px;
  display: none;
  text-align: center;
  @media ${sizes.tablet} {
    margin-top: 10px !important;
    font-family: Raleway-Semi-Bold !important;
    position: absolute;
    left: -65px;
    width: 150px;
    display: inline;
    text-align: center;
  }
`
