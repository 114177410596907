import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import styled from 'styled-components'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Answer, Image, Question } from 'types/Challenge'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
  setSelectedCorrectAnswer: (infoPage: boolean | undefined) => void
  answerClicked: number | undefined
  setAnswerClicked: (a: number | undefined) => void
  selectedCorrectAnswer: boolean | undefined
}

export default function SingleSelect({
  isMobile,
  step,
  setInfoPage,
  setSelectedCorrectAnswer,
  answerClicked,
  setAnswerClicked,
  selectedCorrectAnswer,
}: Props) {
  const [stepQuestions, setStepQuestions] = useState<Question[]>()
  const [stepImage, setStepImage] = useState<Image[]>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(false)
    setAnswerClicked(undefined)
    setStepQuestions(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.questions,
    )
    setStepImage(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.images,
    )
  }, [step])

  const handleSelectAnswer = (answer: Answer) => {
    setSelectedCorrectAnswer(answer.correctAnswer ? true : false)
    setAnswerClicked(answer.answerId)
  }
  const resetAnswers = () => {
    setAnswerClicked(undefined)
  }

  return (
    <div style={{ height: 300, marginBottom: stepImage && stepImage.find((image) => image.portalStep === step)?.url ? 180 : 50 }}>
      {stepQuestions?.find((question) => question.portalStep === step)?.italicized ? (
        <Row styles={{ margin: '10px auto', width: 350, textAlign: 'center' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepQuestions?.find((question) => question.portalStep === step)?.question ?? ''}
            <i> {stepQuestions?.find((question) => question.portalStep === step)?.italicized ?? ''}</i>
          </p>
        </Row>
      ) : (
        <Typography styles={{ margin: '10px auto', width: 300, textAlign: 'center', fontSize: 20 }}>
          {stepQuestions?.find((question) => question.portalStep === step)?.question ?? ''}
        </Typography>
      )}
      {stepImage && stepImage.find((image) => image.portalStep === step)?.url && (
        <div style={{ margin: '10px auto', width: 300 }}>
          <img src={stepImage.find((image) => image.portalStep === step)?.url} alt="plate" style={{ width: 300 }} />
        </div>
      )}
      {stepQuestions
        ?.find((question) => question.portalStep === step)
        ?.answers.map((answer, answerId) => (
          <StyledAnswer
            key={answerId}
            onClick={() => handleSelectAnswer(answer)}
            style={
              answer.answerId === answerClicked
                ? { backgroundColor: answer.correctAnswer ? '#7A9A01' : '#E40046', color: '#FFF', pointerEvents: 'none', marginTop: 10 }
                : answerClicked
                ? { pointerEvents: 'none', marginTop: 10 }
                : { marginTop: 10 }
            }
          >
            <Typography>{answer.text}</Typography>
          </StyledAnswer>
        ))}
      {selectedCorrectAnswer === true && (
        <>
          <Typography color="#7A9A01" font="bold" styles={{ margin: '50px auto 0px', textAlign: 'center' }}>
            That's Right!
          </Typography>
        </>
      )}
      {selectedCorrectAnswer === false && (
        <div style={{ margin: '0px auto', justifyContent: 'space-around' }}>
          {stepQuestions?.find((question) => question.portalStep === step)?.hint && (
            <Column>
              <Row styles={{ margin: '10px auto' }}>
                <Icon name="info-circle-solid" styles={{ marginRight: 10, marginLeft: -10 }} />
                <Typography font="bold">Remember:</Typography>
              </Row>
              <Typography styles={{ width: 300, margin: '0px auto 10px', textAlign: 'center' }}>
                {stepQuestions?.find((question) => question.portalStep === step)?.hint ?? ''}
              </Typography>
            </Column>
          )}
          <div onClick={() => setSelectedCorrectAnswer(undefined)} style={{ width: 158, margin: '10px auto' }}>
            <StyledButton style={{ backgroundColor: '#00677F', margin: '0px auto' }} onClick={() => resetAnswers()}>
              Try Again?
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  )
}

const StyledAnswer = styled.div`
  padding: 10px 40px;
  border: 1px solid #828282;
  border-radius: 8px;
  margin: 5px auto;
  width: 300px;
  text-align: center;
  cursor: pointer;
`
const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-bottom: 0px;
  }
`
