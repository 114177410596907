import Checkbox from 'Components/Base/Checkbox'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import colors from 'Styles/colors'
import { StyledPageContainer } from 'Styles/global'
import { Formik } from 'formik'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePatientAssessment from 'hooks/usePatientAssessment'
import React, { useRef, useState } from 'react'
import { Params, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { SCCAIFormValues } from 'types/Forms'
import ContentHeader from '../../PatientProfile/components/ContentHeader'
import CalculationRow from '../components/CalculationRow'
import DropdownQuestion from '../components/DropdownQuestion'
import ScoreContainer from '../components/ScoreContainer'
import { sccaiDropdownQuestions } from '../contants'
import './SCCAIForm.scss'

export default function SCCAIForm() {
  const { guid }: Readonly<Params<string>> = useParams()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('SCCAI')

  const theme = useTheme()

  const [gettingScore, setGettingScore] = useState(false)

  const [score, setScore] = useState<number>(0)
  const scoreRef = useRef<null | HTMLDivElement>(null)

  const { getSCCAIScore } = usePatientAssessment()

  // eslint-disable-next-line prettier/prettier
  const executeScoreScroll = () => scoreRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const initialValues: SCCAIFormValues = {
    dayBM: '',
    nightBM: '',
    urgency: '',
    amtBlood: '',
    wellbeing: '',
    none: true,
    jointProblems: false,
    eyeProblems: false,
    erythemaNodosum: false,
    pyodermaGrangrenosum: false,
  }

  const submitSCCAIForm = (values: SCCAIFormValues) => {
    setGettingScore(true)

    values.guid = guid
    executeScoreScroll()
    getSCCAIScore(values, (response: any) => {
      setScore(response.total)
    }).then(() => setGettingScore(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => submitSCCAIForm(values)}>
      {({ values, setFieldValue, submitForm }) => {
        return (
          <StyledPageContainer>
            <ContentHeader title="Simple Clinical Colitis Activity Index (SCCAI)" color={colors.primary.blue} />
            <div className="survey-banner">Answer according to the symptoms you have had over the past 3 days.</div>
            {sccaiDropdownQuestions.map((question) => (
              <DropdownQuestion
                question={question.label}
                fieldName={question.fieldName}
                answers={question.answers}
                onChangeValue={(field, value) => setFieldValue(field, value)}
                value={values[question.fieldName]}
              />
            ))}

            <Row
              className="survey-row"
              styles={{
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 25,
              }}
            >
              <div className="survey-question-column" style={{ width: '100%', marginBottom: 10 }}>
                <Typography font="semi-bold">{`Do you have any of the following symptoms apart from your bowels?`}</Typography>
              </div>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.none}
                onClick={() => {
                  setFieldValue('none', !values.none)
                  if (values.none === false) {
                    setFieldValue('jointProblems', false)
                    setFieldValue('eyeProblems', false)
                    setFieldValue('erythemaNodosum', false)
                    setFieldValue('pyodermaGrangrenosum', false)
                  }
                }}
              />
              <Typography font="semi-bold">{`None`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.jointProblems}
                onClick={() => {
                  setFieldValue('jointProblems', !values.jointProblems)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Joint Problems (painful, red or swollen joints; the most commonly affected joints are knees, ankles, or toes)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row" styles={{ marginBottom: 0 }}>
              <Checkbox
                checked={values.eyeProblems}
                styles={{ marginRight: 10 }}
                onClick={() => {
                  setFieldValue('eyeProblems', !values.eyeProblems)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Eye Problems (sore, red or swollen, blurry vision, sensitivity to light, floaters or increased tear production)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <div style={{ minWidth: 37 }} />
              <Typography font="bold">
                NOTE: If you have a painful red eye or if you have been diagnosed with uveitis, episcleritis or scleritis and recognize a flare of
                your symptoms, seek medical help urgently.
              </Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                checked={values.erythemaNodosum}
                styles={{ marginRight: 10 }}
                onClick={() => {
                  setFieldValue('erythemaNodosum', !values.erythemaNodosum)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Erythema Nodosum (tender, hot and red bumps which most often aften the skin on the shins, arms, and legs)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                checked={values.pyodermaGrangrenosum}
                onClick={() => {
                  setFieldValue('pyodermaGrangrenosum', !values.pyodermaGrangrenosum)
                  setFieldValue('none', false)
                }}
                styles={{ marginRight: 10 }}
              />
              <Typography font="semi-bold">{`Pyoderma gangrenosum (deep, purple ulcers, often painful, which usually develop suddenly`}</Typography>
            </Row>

            <CalculationRow
              onSubmit={submitForm}
              gettingScore={gettingScore}
              scoreRef={scoreRef}
              score={score}
              onReset={() => {
                setFieldValue('dayBM', '')
                setFieldValue('nightBM', '')
                setFieldValue('urgency', '')
                setFieldValue('amtBlood', '')
                setFieldValue('wellbeing', '')
                setFieldValue('jointProblems', false)
                setFieldValue('eyeProblems', false)
                setFieldValue('erythemaNodosum', false)
                setFieldValue('pyodermaGrangrenosum', false)
                setScore(0)
              }}
            />

            {score !== 0 && (
              <ScoreContainer
                rows={[
                  {
                    color: theme.colors.forms.mild,
                    range: '<= 2',
                    label: 'Inactive',
                  },
                  {
                    color: theme.colors.forms.severe,
                    range: '`>= 3.0',
                    label: 'Active',
                  },
                ]}
              />
            )}
          </StyledPageContainer>
        )
      }}
    </Formik>
  )
}
