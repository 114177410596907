import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import '../../PatientIntake.scss'

interface Props {
  name: string | undefined
  strength: string | undefined
  medicationName?: string
  onRemoveMedication: (name: string | undefined, strength: string | undefined) => void
}
export default function AddedMedication({ name, strength, medicationName, onRemoveMedication }: Props) {
  return (
    <div
      style={{
        border: '.5px solid #828282',
        padding: '15px 40px',
        marginBottom: 30,
        position: 'relative',
        borderRadius: '5px',
      }}
    >
      {medicationName && (
        <Row styles={{ textAlign: 'left' }}>
          <Typography font="semi-bold" styles={{ marginRight: 5 }}>
            {medicationName}
          </Typography>
          {strength && <Typography font="semi-bold">{strength}</Typography>}
        </Row>
      )}
      {name && (
        <div className="intake-cancel-form" onClick={() => onRemoveMedication(name, strength)}>
          <Icon name="times" color="white" styles={{ height: '100%', width: '40%' }} />
        </div>
      )}
    </div>
  )
}
