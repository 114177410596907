
import React, { useEffect, useState } from 'react'
import { Paragraph, SectionInfo } from 'types/Challenge'
import Typography from 'Components/Base/Typography'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function MicroStep7({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {

    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2])))
    setStepParagraphs(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]))?.paragraphs)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])


  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px', width: isMobile ? '100%' : '50%' }}>
          <Typography variant='h1'>{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>

        </Row>
        <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 20)?.paragraph ?? ''}</p>
        <ResponsiveContainer styles={{ width: '100%', justifyContent: 'space-between' }}>
          <Column styles={{ marginRight: 10 }}>
            <Column styles={{ backgroundColor: '#EAAA00', border: '1px solid black', height: 220, width: 260, justifyContent: 'center', padding: '0px 55px', margin: isMobile ? '0px auto' : 0 }}>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 21)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 22)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 23)?.paragraph ?? ''}</li>
            </Column>
            <Typography size={20} styles={{ textAlign: 'center' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 4)?.paragraph ?? ''}</Typography>
          </Column>
          <Column styles={{ marginRight: 10 }}>
            <Column styles={{ backgroundColor: '#7A9A01', border: '1px solid black', height: 220, width: 260, justifyContent: 'center', padding: '0px 55px', color: '#FFF', margin: isMobile ? '0px auto' : 0 }}>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 26)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 27)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 28)?.paragraph ?? ''}</li>
            </Column>
            <Typography size={20} styles={{ textAlign: 'center' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 5)?.paragraph ?? ''}</Typography>
          </Column>
          <Column styles={{ marginRight: 10 }}>
            <Column styles={{ backgroundColor: '#98D3DD', border: '1px solid black', height: 220, width: 260, justifyContent: 'center', padding: '0px 45px', margin: isMobile ? '0px auto' : 0 }}>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 30)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 31)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 32)?.paragraph ?? ''}</li>
            </Column>
            <Typography size={20} styles={{ textAlign: 'center' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 6)?.paragraph ?? ''}</Typography>
          </Column>
          <Column styles={{ marginRight: 10 }}>
            <Column styles={{ backgroundColor: '#FED141', border: '1px solid black', height: 220, width: 260, justifyContent: 'center', padding: '0px 70px', margin: isMobile ? '0px auto' : 0 }}>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 34)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 35)?.paragraph ?? ''}</li>
              <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 36)?.paragraph ?? ''}</li>
            </Column>
            <Typography size={20} styles={{ textAlign: 'center' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 7)?.paragraph ?? ''}</Typography>
          </Column>
        </ResponsiveContainer>
      </Column>
    </ResponsiveContainer>
  )
}
