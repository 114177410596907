import { ReadingLevel } from '../types/Dashboards'

export const getReadingLevelDescription = (level: ReadingLevel) => {
  switch (level) {
    case ReadingLevel.CriticalLow:
      return 'Critical Low'
    case ReadingLevel.Low:
      return 'Low'
    case ReadingLevel.InRange:
      return 'In Range'
    case ReadingLevel.AboveRange:
      return 'Above Range'
    case ReadingLevel.High:
      return 'High'
    default:
      return
  }
}
