import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import styled, { useTheme } from 'styled-components'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { formatDescription } from 'utilities/formatDescription'
import ReactTooltip from 'react-tooltip'
import { Medication } from 'types/Medication'
import ReactDOMServer from 'react-dom/server'

interface Props {
  activeMedications: Medication[]
  order: string
  isMobile: boolean
}
export default function MedicationsAccordion({ activeMedications, order, isMobile }: Props) {
  const [expandedSection, setExpandedSection] = useState<number | undefined>()

  const theme = useTheme()

  const toggleSection = (sectionId: number) => {
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }

  return (
    <>
      <StyledAccordion>
        {activeMedications
          .sort((a, b) => {
            const nameA = a.medicationName.toUpperCase() // ignore upper and lowercase
            const nameB = b.medicationName.toUpperCase() // ignore upper and lowercase
            if (order === 'ZToA') {
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              // names must be equal
              return 0
            } else if (order === 'AToZ') {
              if (nameA > nameB) {
                return -1
              }
              if (nameA < nameB) {
                return 1
              }
              // names must be equal
              return 0
            } else {
              return 0
            }
          })
          .map((med, index) => (
            <Card style={index % 2 !== 0 ? { display: 'block' } : { backgroundColor: '#f2f2f2' }}>
              <Accordion.Toggle
                as={Card.Header}
                style={
                  expandedSection === index
                    ? { backgroundColor: theme.colors.primary1, color: 'white', marginLeft: '30' }
                    : { justifyContent: 'space-between' }
                }
                eventKey={`${index}`}
                onClick={() => toggleSection(index)}
              >
                <div>
                  <Typography styles={{ marginLeft: 0, marginRight: 'auto' }} size={20}>
                    {med.medicationName}
                  </Typography>
                  <Typography size={12}>{formatDescription(med.directions) ?? ''}</Typography>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 20, textAlign: 'right' }}>
                  {med.medicationStatus === 'Active' && med.alternatives.length > 0 ? (
                    <div>
                      <Typography
                        color={expandedSection === index ? '#FFFFFF' : '#7A9A01'}
                        variant="h3"
                      >
                        Recommended Savings
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  {med.medicationStatus === 'Recommended' && med.alternatives.length === 0 ? (
                    <div>
                      <Typography
                        color={expandedSection === index ? '#FFFFFF' : '#00A9CE'}
                        variant="h3"
                      >
                        Recommended Start
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  {med.medicationStatus === 'Recommended Stop' ? (
                    <div>
                      <div
                        data-for="discontinue"
                        data-html="true"
                        data-tip={ReactDOMServer.renderToStaticMarkup(
                          <div style={{ textAlign: 'left' }}>
                            <Typography size={15} variant="h3" color="#E40046">
                              Recommended Discontinuation
                            </Typography>

                            <Typography size={13}>Please ask your doctor to review</Typography>
                            <Typography size={13}>our suggestions and allow them to</Typography>
                            <Typography size={13}>make the final decision about your</Typography>
                            <Typography size={13}>prescription medications.</Typography>
                            <br></br>
                            <Typography size={13}>For more information regarding this</Typography>
                            <Typography size={13}>medication change, view your care</Typography>
                            <Typography size={13}>plan recommendations.</Typography>
                          </div>,
                        )}
                      >
                        {isMobile ? (
                          <Row>
                            <Typography
                              color={expandedSection === index ? '#FFFFFF' : '#E40046'}
                              variant="h3"
                              size={12}
                              styles={{ marginRight: 4 }}
                            >
                              Discontinue
                            </Typography>
                            <Icon
                              name="info-circle"
                              color={expandedSection === index ? '#FFFFFF' : '#E40046'}
                              styles={{ marginTop: 2 }}
                            ></Icon>
                          </Row>
                        ) : (
                          <Row>
                            <Typography
                              color={expandedSection === index ? '#FFFFFF' : '#E40046'}
                              variant="h3"
                              styles={{ marginRight: 4 }}
                            >
                              Discontinue
                            </Typography>
                            <Icon
                              name="info-circle"
                              color={expandedSection === index ? '#FFFFFF' : '#E40046'}
                              styles={{ marginTop: 2 }}
                            ></Icon>
                          </Row>
                        )}
                      </div>
                      <ReactTooltip
                        id="discontinue"
                        place="top"
                        textColor="#000000"
                        border
                        backgroundColor="#FFFFFF"
                        effect="solid"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {med.alternatives.length > 0 ? (
                  expandedSection === index ? (
                    <Icon name="chevron-down" />
                  ) : (
                    <Icon name="chevron-right" />
                  )
                ) : (
                  <></>
                )}
              </Accordion.Toggle>
              {med.alternatives.length > 0 && (
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    {med.alternatives.length > 0 && (
                      <>
                        <div>
                          <div
                            data-for="savings"
                            data-html="true"
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                              <div style={{ textAlign: 'left' }}>
                                <Typography size={15} variant="h3">
                                  Recommended Savings
                                </Typography>

                                <Typography size={13}>Please ask your doctor to review</Typography>
                                <Typography size={13}>our suggestions and allow them to</Typography>
                                <Typography size={13}>
                                  make the final decision about your
                                </Typography>
                                <Typography size={13}>prescription medications.</Typography>
                              </div>,
                            )}
                          >
                            <Row>
                              <Typography variant="h3" styles={{ marginRight: 4 }}>
                                Alternatives
                              </Typography>
                              <Icon name="info-circle" styles={{ marginTop: 2 }}></Icon>
                              <ReactTooltip
                                id="savings"
                                place="left"
                                textColor="#000000"
                                border
                                backgroundColor="#FFFFFF"
                                effect="solid"
                              />
                            </Row>
                          </div>
                        </div>
                      </>
                    )}
                    <ul>
                      {med.alternatives.map((alternatives) => (
                        <li>
                          <Typography>{alternatives.tradeName}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              )}
            </Card>
          ))}
      </StyledAccordion>
    </>
  )
}

const StyledAccordion = styled(Accordion)`
  border-radius: 10px;
  z-index: 50px;
  position: relative;
`
