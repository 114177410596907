import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React from 'react'
import { getAccessToken } from 'reducers/authSlice'
import styled, { useTheme } from 'styled-components'
import { IconNames } from 'types/IconNames'
import Icon from '../Icon'
import Typography from '../Typography'

export interface SelectButtonProps {
  title: string
  subtitle?: string
  icon?: IconNames
  value: number
  selected: boolean
}

interface Props {
  title: string
  subtitle?: string
  icon?: IconNames
  selected: boolean
  onClick: (title: string) => void
}

export default function SelectionButton({ title, icon, selected, subtitle, onClick }: Props) {
  const theme = useTheme()
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const handleClick = () => {
    onClick(title)
    analytics.postAnalyticsEvent({ category: `click_button_appointment_${title}`, action: `click_button_appointment_${title}`, label: `Button` })
  }

  return (
    <StyledButton
      onClick={() => handleClick()}
      style={selected ? { color: theme.colors.white, backgroundColor: theme.colors.primary1 } : {}}
      className={`condition-button`}
    >
      {icon && <Icon name={icon} styles={{ marginRight: 10 }} />}
      {title}
      {subtitle && <Typography>{subtitle}</Typography>}
    </StyledButton>
  )
}

const StyledButton = styled.div`
  border-color: transparent;
  color: ${({ theme }) => theme.colors.primary1};
  background-color: ${({ theme }) => theme.colors.secondary1};
  height: 65px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media ${sizes.tablet} {
    max-width: none;
    max-width: 100px;
  }
`
