import React from 'react'
import { RouteProps } from 'react-router'
import { Navigate } from 'react-router-dom'

export interface ProtectedRouteProps extends RouteProps {
  children: JSX.Element
  requestedRoute: string
  isAuthenticated: boolean
}

export default function ProtectedRoute({ children, requestedRoute, isAuthenticated }: ProtectedRouteProps) {
  const now = new Date()

  /* 10 minute expiration */
  const item = {
    value: window.location.pathname + window.location.search,
    expiry: now.getTime() + 1000 * 60 * 10,
  }

  if (!isAuthenticated) {
    window.localStorage.setItem('tempUrl', JSON.stringify(item))
  }

  return isAuthenticated ? children : <Navigate to="/" />
}
