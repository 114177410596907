import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

export default function VideoResources() {
  const navigate = useNavigate()

  return (
    <>
      <img
        src={'https://triacentral.blob.core.windows.net/dev-storage/images/CareGiverAddOn.png'}
        onClick={() => navigate('/profile/access')}
        alt="Keep your loved ones in the loop"
        className="add-caregiver-image"
      />
      <ReactPlayer url={'https://www.youtube.com/watch?v=vZWgzfbHv_g'} width={'100%'} controls={true} style={{ marginBottom: 15 }} />
      <Row styles={{ marginBottom: 10 }}>
        <Typography font="semi-bold" size={15}>
          Examples of 15g of Fast Acting Carbs
        </Typography>
      </Row>
      <Column>
        <Typography size={15}>4 oz juice</Typography>
        <Typography size={15}>6-7 hard candies</Typography>
        <Typography size={15}>Small box of raisins</Typography>
        <Typography size={15}>Glucose tabs</Typography>
        <Typography size={15}>1 Tbsp sugar, honey or maple syrup</Typography>
        <Typography size={15} styles={{ marginBottom: 15 }}>
          4 oz of regular soda
        </Typography>
      </Column>

      <ReactPlayer url={'https://www.youtube.com/watch?v=bu2osoNxvF0'} width={'100%'} controls={true} style={{ marginBottom: 15 }} />
    </>
  )
}
