import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import { StyledErrorText } from 'Styles/global'
import { FormikErrors, getIn, useFormik } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { InfoFormValues } from 'types/Appointment'
import * as Yup from 'yup'

interface Props {
  errorMessage?: string
  loading?: boolean
  tempFormValues?: InfoFormValues
  setUserInfo: (demographics: InfoFormValues) => void
  onBackPressed: () => void
}

export default function CTLDemographicsForm({ errorMessage, setUserInfo, onBackPressed, tempFormValues, loading = false }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  function getStyles(fieldName: string, errors: FormikErrors<InfoFormValues>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  const validationSchema = Yup.object().shape({
    heightFt: Yup.number().min(0, 'Please enter a positive number').required('Height (ft) is required'),
    heightIn: Yup.number().min(0, 'Please enter a positive number').required('Height (in) is required'),
    weight: Yup.number().min(50, 'Please enter a number greater than 50').required('Weight is required'),
    goalWeight: Yup.number().min(50, 'Please enter a number greater than 50').required('Weight is required'),
    gender: Yup.string().required('Gender is required'),
    carrierPId: Yup.number(),
  })

  const initialValues: InfoFormValues = {
    heightFt: tempFormValues?.heightFt ?? undefined,
    heightIn: tempFormValues?.heightIn ?? undefined,
    weight: tempFormValues?.weight ?? undefined,
    gender: tempFormValues?.gender ?? undefined,
    goalWeight: tempFormValues?.goalWeight ?? undefined,
    carrierPId: parseInt(searchParams.get('cid') ?? '0'),
  }

  const onSubmit = (userInfo: InfoFormValues) => {
    setUserInfo(userInfo)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 85,
      }}
    >
      <h1 className="id-header">Tell us a little about yourself</h1>
      <div style={{ fontSize: '25px', marginBottom: 25 }}>No judgments here.</div>
      <Form>
        <StyledFormSet>
          <StyledFormInput
            type="number"
            onChange={(value) => formik.setFieldValue('heightFt', value.currentTarget.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('heightFt', formik.errors),
            }}
            value={formik.values.heightFt}
            placeholder="Height (ft)"
          />
          <StyledSpacer />
          <StyledFormInput
            type="number"
            onChange={(value) => formik.setFieldValue('heightIn', value.currentTarget.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('heightIn', formik.errors),
            }}
            value={formik.values.heightIn}
            placeholder="Height (in)"
          />
        </StyledFormSet>
        <StyledFormSet>
          <StyledFormInput
            type="number"
            onChange={(value) => formik.setFieldValue('weight', value.currentTarget.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('weight', formik.errors),
            }}
            value={formik.values.weight}
            placeholder="Weight"
          />
          <StyledSpacer />

          <StyledFormInput
            type="number"
            onChange={(value) => formik.setFieldValue('goalWeight', value.currentTarget.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('goalWeight', formik.errors),
            }}
            value={formik.values.goalWeight}
            placeholder="Goal weight"
          />
        </StyledFormSet>
        <StyledFormSet>
          <StyledFormInput
            as="select"
            value={formik.values.gender}
            onChange={(e) => formik.setFieldValue('gender', e.target.value)}
            style={{
              border: '2px solid transparent',
              borderRadius: 4,
              ...getStyles('gender', formik.errors),
            }}
          >
            <option value={''} hidden>
              Sex
            </option>
            <option value={'M'}>Male</option>
            <option value={'F'}>Female</option>
          </StyledFormInput>

          <StyledSpacer />
          <div data-for="discontinue" data-html="true" data-tip="Sex assigned at birth">
            <div>
              <Icon name="info-circle-solid" size="1x" styles={{ marginLeft: 3, marginBottom: 10 }}></Icon>
            </div>
          </div>
          <ReactTooltip id="discontinue" place="top" textColor="#000000" backgroundColor="#FFFFFF" border />
        </StyledFormSet>

        {!formik.isValid && <StyledErrorText>Please fix errors above</StyledErrorText>}
        {errorMessage && <StyledErrorText>{errorMessage}</StyledErrorText>}
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <StyledButtonContainer>
            <StyledButton pageName="appointment_ctl_demographics" text="Back" styles={{ backgroundColor: '#425563' }} onClick={onBackPressed} />
            <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
            <StyledButton pageName="appointment_ctl_demographics" text="Next" loading={loading} onClick={formik.handleSubmit} />
          </StyledButtonContainer>
        </div>
      </Form>
    </div>
  )
}

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const StyledButton = styled(Button)`
  width: 25%;
  height: 40px;
  border-radius: 4px;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.colors.primary1};
  @media (max-width: 600px) {
    width: 100%;
  }
`
const StyledSpacer = styled.div`
  display: block;
  width: 20px;
  @media (max-width: 600px) {
    display: none;
  }
`

const StyledFormSet = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const StyledFormInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 45%;
  @media (max-width: 600px) {
    width: 100%;
  }
`
