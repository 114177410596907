import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Medication Options.png'

export default function MedicationOptions() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Introduction to Activity 3:</STOPHeader>
          <STOPHeader>Medication Options & Benefits</STOPHeader>
          <div style={{ marginTop: 25 }}>
            <ScaledText size={25}>
              During this activity we will review the different products available to help aid in quitting tobacco. The proper uses, pros, and cons
              will be reviewed for nicotine replacement products, bupropion, and varenicline.
            </ScaledText>
            <Spacer top>
              <ScaledText size={25}>
                At the end of the activity, if you desire to speak with one of Tria's pharmacists about quitting tobacco, please reach out to Tria
                Health to schedule a time with one of our pharmacists.
              </ScaledText>
            </Spacer>
          </div>
        </>
      }
      image={BenefitsImage}
      altTag="Man at a computer"
    />
  )
}
