import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import styled from 'styled-components'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Answer, Question } from 'types/Challenge'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
  setSelectedCorrectAnswer: (infoPage: boolean | undefined) => void
  answerClicked: number | undefined
  setAnswerClicked: (a: number | undefined) => void
  answerClickedArray: number[]
  setAnswerClickedArray: (a: number[]) => void
  setMultipleCorrectAnswers: (a: boolean) => void
  setAnswersSubmitted: (a: boolean) => void
  answersSubmitted: boolean
  selectedCorrectAnswer: boolean | undefined
}
export default function MultiSelect({
  isMobile,
  step,
  setInfoPage,
  setSelectedCorrectAnswer,
  answerClicked,
  setAnswerClicked,
  answerClickedArray,
  setAnswerClickedArray,
  setMultipleCorrectAnswers,
  answersSubmitted,
  selectedCorrectAnswer,
  setAnswersSubmitted,
}: Props) {
  const [stepQuestion, setStepQuestion] = useState<Question[]>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(false)
    setAnswerClicked(undefined)
    setStepQuestion(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.questions,
    )
    setMultipleCorrectAnswers(true)
  }, [step])

  const handleSelectAnswer = (answer: Answer) => {
    setAnswerClicked((answerClicked ?? 1) + 10)
    if (answerClickedArray.includes(answer.answerId)) {
      answerClickedArray.splice(
        answerClickedArray.findIndex((a) => a === answer.answerId),
        1,
      )
    } else {
      answerClickedArray.push(answer.answerId)
    }
    setAnswerClickedArray(answerClickedArray)
  }

  const resetAnswers = () => {
    setAnswerClickedArray([])
    setAnswersSubmitted(false)
  }
  return (
    <div>
      {stepQuestion?.find((question) => question.portalStep === step)?.italicized ? (
        <Row styles={{ margin: '10px auto', width: 300, textAlign: 'center' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepQuestion?.find((question) => question.portalStep === step)?.question ?? ''}
            <i> {stepQuestion?.find((question) => question.portalStep === step)?.italicized ?? ''}</i>
          </p>
        </Row>
      ) : (
        <Typography styles={{ margin: '10px auto', width: 300, textAlign: 'center', fontSize: 20 }}>
          {stepQuestion?.find((question) => question.portalStep === step)?.question ?? ''}
        </Typography>
      )}
      <Typography styles={{ margin: '10px auto', width: 300, textAlign: 'center' }}>Select All That Apply</Typography>
      {stepQuestion
        ?.find((question) => question.portalStep === step)
        ?.answers.map((answer, answerId) => (
          <StyledAnswer
            key={answerId}
            onClick={() => handleSelectAnswer(answer)}
            style={
              answerClickedArray.includes(answer.answerId)
                ? {
                    pointerEvents: answersSubmitted ? 'none' : undefined,
                    backgroundColor: answersSubmitted ? (answer.correctAnswer ? '#7A9A01' : '#E40046') : '#BDBDBD',
                    color: '#FFF',
                  }
                : { pointerEvents: answersSubmitted ? 'none' : undefined }
            }
          >
            {!answerClickedArray.includes(answer.answerId) && answersSubmitted && answer.correctAnswer && (
              <Icon name="check-circle" color="#7A9A01" />
            )}
            <Typography>{answer.text}</Typography>
          </StyledAnswer>
        ))}
      {selectedCorrectAnswer === false && (
        <div style={{ margin: '0px auto', justifyContent: 'space-around' }}>
          {stepQuestion?.find((question) => question.portalStep === step)?.hint && (
            <Column>
              <Row styles={{ margin: '10px auto' }}>
                <Icon name="info-circle-solid" styles={{ marginRight: 10, marginLeft: -10 }} />
                <Typography font="bold">Remember:</Typography>
              </Row>
              <Typography styles={{ width: 300, margin: '10px auto' }}>
                {stepQuestion?.find((question) => question.portalStep === step)?.hint ?? ''}
              </Typography>
            </Column>
          )}
          <div onClick={() => setSelectedCorrectAnswer(undefined)} style={{ width: 158, margin: '10px auto' }}>
            <StyledButton style={{ backgroundColor: '#00677F', margin: '0px auto' }} onClick={() => resetAnswers()}>
              Try Again?
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  )
}

const StyledAnswer = styled.div`
  padding: 10px 40px;
  border: 1px solid #828282;
  border-radius: 8px;
  margin: 5px auto;
  width: 300px;
  text-align: center;
  cursor: pointer;
`

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-bottom: 0px;
  }
`
