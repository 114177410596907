import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { Field, Formik, FormikErrors, FormikProps, getIn } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useHabitOptions from 'hooks/useHabitOptions'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { getHabitOptions, getProfile, loadProfile } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import { useTheme } from 'styled-components'
import { HabitEditRequest, HabitOptions } from 'types/HabitOptions'
import { ProfileFormValues } from 'types/Profile'
import * as Yup from 'yup'
import '../../PatientProfile.scss'
import ContentHeader from '../ContentHeader'
import './ProfileHome.scss'

interface Props {
  isMobile: boolean
  backPressed: () => void
  sideMenuToggled: () => void
}

export default function ProfileHome({ isMobile, backPressed, sideMenuToggled }: Props) {
  const theme = useTheme()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Profile')
  const navigate = useNavigate()

  const profile = useAppSelector(getProfile)
  const options = useAppSelector(getHabitOptions)
  const [habitOptions, setHabitOptions] = useState<HabitOptions | undefined>(options)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [pageLoading, setPageLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const token = useAppSelector(getAccessToken)
  const { getUserProfile, updateProfile } = useHabitOptions(token!)
  const formikRef = useRef<FormikProps<ProfileFormValues>>(null)

  function getStyles(fieldName: string, errors: FormikErrors<ProfileFormValues>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  useEffect(() => {
    dispatch(setLoading(true))

    async function getProfileData() {
      getUserProfile().then((res: any) => {
        if (formikRef.current) {
          const ref = formikRef.current
          ref.setFieldValue('name', res.data.name)
          ref.setFieldValue('dateOfBirth', res.data.dateOfBirth)
          ref.setFieldValue('heightFt', res.data.heightFeet)
          ref.setFieldValue('heightIn', res.data.heightInches)
          ref.setFieldValue('weight', res.data.weight)
          ref.setFieldValue('tobaccoUsage', res.data.habits.tobaccoUsage)
          ref.setFieldValue('caffeineUsage', res.data.habits.caffeineUsage)
          ref.setFieldValue('alcoholUsage', res.data.habits.alcoholUsage)
          ref.setFieldValue('activityLevel', res.data.habits.activityLevel)
        }
        dispatch(setLoading(false))
      })
    }

    getProfileData()
  }, [])

  useEffect(() => {
    if (!profile) {
      navigate('/error')
    }
    window.scrollTo(0, 0)
  }, [profile])

  const saveProfile = (values: ProfileFormValues) => {
    setPageLoading(true)
    setSuccessMessage(undefined)

    if (profile) {
      const request: HabitEditRequest = {
        welltrakPid: profile?.welltrakPID,
        tobaccoUse: values.tobaccoUsage,
        caffeineUse: values.caffeineUsage,
        alcoholUse: values.alcoholUsage,
        activityLevel: values.activityLevel,
        heightFt: values.heightFt,
        heightIn: values.heightIn,
        weight: values.weight,
      }

      updateProfile(request).then(() => {
        if (token) {
          setPageLoading(false)

          dispatch(loadProfile(token)).then(() => {
            setSuccessMessage('Profile Saved!')
          })
        } else {
          setPageLoading(false)
        }
      })
    }
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    dateOfBirth: Yup.string(),
    heightFt: Yup.number().min(0, 'Please enter a positive number').required('Height (ft) is required'),
    heightIn: Yup.number().min(0, 'Please enter a positive number').required('Height (in) is required'),
    weight: Yup.number().min(50, 'Please enter a number greater than 50').required('Weight is required'),
    tobaccoUsage: Yup.string(),
    caffeineUsage: Yup.string(),
    alcoholUsage: Yup.string(),
    activityLevel: Yup.string(),
  })

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: `${profile?.firstName} ${profile?.lastName}`,
        dateOfBirth: profile?.dob ?? '',
        heightFt: profile?.heightFeet.toString() ?? '',
        heightIn: profile?.heightInches.toString() ?? '',
        weight: profile?.weight.toString() ?? '',
        tobaccoUsage: '',
        caffeineUsage: '',
        alcoholUsage: '',
        activityLevel: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values: ProfileFormValues) => saveProfile(values)}
    >
      {({ values, handleSubmit, setFieldValue, errors, touched }) => {
        return (
          <>
            <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
              {isMobile && (
                <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
                  <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
                </div>
              )}
              <ContentHeader title="Profile Home" icon="home" color="#00677f" />
              {isMobile && <div></div>}
            </Row>
            <Form>
              <Form.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="profile-home-input-container">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Name:</Form.Label>
                  <Form.Control
                    disabled
                    onChange={(value) => setFieldValue('name', value.currentTarget.value)}
                    value={values.name}
                    placeholder=""
                    className="login-input profile-home-input"
                  />
                </div>
                <div className="input-spacer" />
                <div className="profile-home-input-container">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Date of Birth:</Form.Label>
                  <Form.Control
                    disabled
                    onChange={(value) => setFieldValue('dateOfBirth', value.currentTarget.value)}
                    value={values.dateOfBirth}
                    placeholder=""
                    className="login-input"
                  />
                </div>
                <div className="input-spacer" />
                <div className="height-weight-row">
                  <div className="profile-home-input-container">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Height (ft):</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(value) => setFieldValue('heightFt', value.currentTarget.value)}
                      value={values.heightFt}
                      style={{ width: '150px' }}
                      placeholder=""
                      className="login-input"
                    />
                    {errors.heightFt && touched.heightFt ? <Typography color={theme.colors.error}>{errors.heightFt}</Typography> : null}
                  </div>
                  <div className="height-weight-spacer" />
                  <div className="profile-home-input-container">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Height (in):</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(value) => setFieldValue('heightIn', value.currentTarget.value)}
                      value={values.heightIn}
                      style={{ width: '150px' }}
                      placeholder=""
                      className="login-input"
                    />
                    {errors.heightIn && touched.heightIn ? <Typography color={theme.colors.error}>{errors.heightIn}</Typography> : null}
                  </div>
                  <div className="height-weight-spacer" />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Weight:</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(value) => setFieldValue('weight', value.currentTarget.value)}
                      style={{ width: '150px' }}
                      value={values.weight}
                      placeholder=""
                      className="login-input"
                    />
                    {errors.weight && touched.weight ? <Typography color={theme.colors.error}>{errors.weight}</Typography> : null}
                  </div>
                </div>
              </Form.Group>

              <text
                style={{
                  fontFamily: 'Raleway-Bold',
                  fontSize: 25,
                  color: '#00677f',
                  fontWeight: 600,
                }}
              >
                Lifestyle Preferences:
              </text>
              <div>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Tobacco Usage:</Form.Label>
                <Row>
                  <Field as="select" name="tobaccoUsage" class="profile-home-dropdown">
                    {habitOptions?.tobaccoUse.map((option) => {
                      return <option value={option.displayValue}>{option.displayText}</option>
                    })}
                  </Field>
                </Row>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Alcohol Usage:</Form.Label>
                <Row>
                  <Field as="select" name="alcoholUsage" class="profile-home-dropdown">
                    {habitOptions?.alcoholUse.map((option) => {
                      return <option value={option.displayValue}>{option.displayText}</option>
                    })}
                  </Field>
                </Row>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Caffeine Usage:</Form.Label>
                <Row>
                  <Field as="select" name="caffeineUsage" class="profile-home-dropdown">
                    {habitOptions?.caffeineUse.map((option) => {
                      return <option value={option.displayValue}>{option.displayText}</option>
                    })}
                  </Field>
                </Row>
                <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Activity Level:</Form.Label>
                <Row>
                  <Field as="select" name="activityLevel" class="profile-home-dropdown">
                    {habitOptions?.activityLevel.map((option) => {
                      return <option value={option.displayValue}>{option.displayText}</option>
                    })}
                  </Field>
                </Row>
              </div>

              <Button text="Save Changes" loading={pageLoading} onClick={handleSubmit} className="profile-save-button" pageName="Profile_Home" />
              {successMessage && (
                <Row>
                  <Typography color={theme.colors.primary1} font="semi-bold">
                    {successMessage}
                  </Typography>
                </Row>
              )}
            </Form>
          </>
        )
      }}
    </Formik>
  )
}
