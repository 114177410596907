export interface Caregiver {
  patientCaregiverPId?: number
  name?: string
  email?: string
  cellPhone?: string
  birthDate?: string
  address?: string
  relationship?: string
  canReceiveHealthAlerts: boolean
  caregiverStatus?: CaregiverStatus
  disclosurePurpose?: string
  appointmentReminders: boolean
  hasAccepted: boolean
  signature?: string
  revokedAccessDate?: string | null
  caregiverAccessPermissions: CaregiverAccessPermissions[]
}

export interface CaregiverAccessPermissions {
  displayText: string
  permissionGranted: boolean
  optInProgramPId: number
  communicationTypePId: number
}

export enum CaregiverStatus {
  Awaiting,
  Accepted,
  Declined,
}
