import React from 'react'

type Props = {
  children: React.ReactNode | React.ReactNode[]
  styles?: React.CSSProperties
  center?: boolean
  className?: string
  onClick?: () => void
}

export default function Row({ children, styles, center = false, className, onClick }: Props) {
  if (center) styles = { ...styles, justifyContent: 'center' }

  return (
    <div className={className} onClick={onClick} style={{ ...styles, display: 'flex', flexDirection: 'row' }}>
      {children}
    </div>
  )
}
