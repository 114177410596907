import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import Row1Image from '../../../Assets/Images/STOP/NRT 1.png'
import Row2Image from '../../../Assets/Images/STOP/Quit Smoking Pills.png'
import ListItem from '../components/ListItem'

export default function MedicationHelp() {
  return (
    <>
      <STOPHeader>How Can Medications Help with Quitting Tobacco and What Options are Out there?</STOPHeader>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row1Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Nicotine Replacement Therapy (NRT)
          </ScaledText>
          <ul>
            <ListItem>
              <ScaledText>
                NRT works by replacing the nicotine, at lower amounts, that was previously from tobacco product of choice. NRT is meant to ease
                withdrawal symptoms and aid in the quit process. NRT does not contain the harmful chemicals that are in tobacco products.
              </ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>NRT comes as multiple products: gum, lozenge, patch, inhaler, and nasal spray.</ScaledText>
            </ListItem>
          </ul>
        </Column>
      </ResponsiveContainer>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row2Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Prescription Medications
          </ScaledText>
          <ul>
            <ListItem>
              <ScaledText>
                Bupropion and varenicline are medications approved by the Food and Drug Administration (FDA) to assist in quitting smoking.
              </ScaledText>
            </ListItem>
            <ListItem>
              <ScaledText>These medications assist in quitting smoking by reducing the pleasure and enjoyment of smoking.</ScaledText>
            </ListItem>
          </ul>
        </Column>
      </ResponsiveContainer>
    </>
  )
}

const StyledImage = styled.img`
  object-fit: cover;
  height: 15vh;
  border: 1px solid black;
  margin-bottom: 15px;
  @media (${sizes.tablet}) {
    height: 25vh;
    margin-right: 25px;
  }
`
