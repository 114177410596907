import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React from 'react'
import ReactPlayer from 'react-player'
import styled, { useTheme } from 'styled-components'
import SignupForm from '../ManagementPages/components/SignupForm'

export default function CernerClientPage() {
  const theme = useTheme()
  const CERNER_LOGO = 'https://triacentral.blob.core.windows.net/dev-storage/clients/CERNER/logo'
  const VIDEO_LINK = 'https://www.youtube.com/watch?v=6AZ7aQw1rOo'

  return (
    <StyledContainer>
      <StyledLeftColumn>
        <Row center>
          <div style={{ maxWidth: 650 }}>
            <StyledImage src={CERNER_LOGO} alt="Cerner logo" />
          </div>
        </Row>

        <ReactPlayer url={VIDEO_LINK} width={'100%'} controls={true} height={'25vw'} style={{ marginBottom: 15 }} />
        <StyledParagraph>
          Healthcare and insurance is confusing and difficult, especially if you take a lot of medications and have chronic conditions. Tria Health is
          a confidential benefit, available to you at no cost, that will support you in managing your health, medications and healthcare budget. Talk
          to a pharmacist over the phone and receive the personalized care you deserve.
        </StyledParagraph>
        <StyledParagraph>Tria Health is recommended for members who have the following conditions and take multiple medications:</StyledParagraph>
        <StyledList>
          <li>
            <Typography>Asthma</Typography>
          </li>
          <li>
            <Typography>Asthma/COPD</Typography>
          </li>
          <li>
            <Typography>Diabetes</Typography>
          </li>
          <li>
            <Typography>Heart Disease</Typography>
          </li>
          <li>
            <Typography>High Blood Pressure</Typography>
          </li>
          <li>
            <Typography>High Cholesterol</Typography>
          </li>
          <li>
            <Typography>Mental Health</Typography>
          </li>
          <li>
            <Typography>Migraines</Typography>
          </li>
          <li>
            <Typography>Osteoporosis</Typography>
          </li>
          <li>
            <Typography>Or any other "specialty" conditions</Typography>
          </li>
        </StyledList>
        <Typography font="bold">
          Active participants in Tria Health’s program will receive reduced copays for qualified high blood pressure, asthma and diabetes medications
          after the first fill.
        </Typography>
      </StyledLeftColumn>
      <StyledRightColumn>
        <SignupForm
          customHeader={
            <StyledHeader>
              <Typography color={theme.colors.primary1} font="semi-bold" size={30}>
                Register for Tria Health's NEW Patient Portal
              </Typography>
              <Typography>
                Already have a portal account? Help us ensure your information is up to date by re-registering for the new website.
              </Typography>
            </StyledHeader>
          }
        />
      </StyledRightColumn>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  margin-top: 0px;
  @media (max-width: ${sizes.mobileM}) {
    flex-direction: column;
  }
  @media (max-width: ${sizes.mobileS}) {
    margin-top: 80px;
  }
`

const StyledImage = styled.img`
  margin-bottom: 25px;
  width: 100%;
  margin-left: 0 auto;
  margin-right: 0 auto;
`

const StyledLeftColumn = styled(Column)`
  width: 58%;
  margin: 5%;
  @media (max-width: ${sizes.mobileM}) {
    width: 100%;
    margin: 0px;
    padding: 5% 2%;
  }
`

const StyledRightColumn = styled(Column)`
  margin-right: 5%;
  margin-top: 4%;
  @media (max-width: ${sizes.mobileM}) {
    width: 100%;
    margin-top: 0px;
    padding: 0% 2% 15% 2%;
  }
`
const StyledParagraph = styled(Typography)`
  margin-bottom: 10px;
`
const StyledList = styled.li`
  list-style-type: none;
  padding-left: 10px;
`
const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 22%;
`
