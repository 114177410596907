import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledContainer } from 'Pages/AppointmentPage'
import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { useFormik } from 'formik'
import useEnrollment from 'hooks/useEnrollment'
import React, { useState } from 'react'
import { Accordion, Card, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import { PolicyFormValues } from 'types/Appointment'
import * as Yup from 'yup'

interface Props {
  onSetSignature: (signature: string) => void
  onBackPressed: () => void
  onError: () => void
  onSuccess: () => void
  guid?: string
  signature?: string
  loading?: boolean
}

export default function ConsentForm({ onSetSignature, onBackPressed, onError, onSuccess, guid, loading, signature }: Props) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { postPolicy } = useEnrollment()

  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [expandedSection, setExpandedSection] = useState<number | undefined>()

  const toggleSection = (sectionId: number) => {
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }

  const postPolicyForm = (policyInfo: PolicyFormValues) => {
    dispatch(setLoading(true))

    onSetSignature(policyInfo.fullName)

    policyInfo.enrollmentFormGuid = guid
    postPolicy(policyInfo)
      .then(() => {
        onSuccess()
      })
      .catch(() => {
        onError()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(),
    viewedPrivacyPolicy: Yup.boolean().oneOf([true], 'Please view privacy policy'),
    viewedCommunicationPolicy: Yup.boolean().oneOf([true], 'Please view communication policy'),
    viewedTermsAndConditions: Yup.boolean().oneOf([true], 'Please view communication policy'),
  })

  const initialValues: PolicyFormValues = {
    fullName: signature ?? '',
    viewedPrivacyPolicy: false,
    viewedCommunicationPolicy: false,
    viewedTermsAndConditions: false,
    textOptIn: false,
  }

  const onSubmit = (policyInfo: PolicyFormValues) => {
    postPolicyForm(policyInfo)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  const policies = [
    {
      title: 'privacy policy',
      paragraph: `I acknowledge receipt of the Tria Health Notice of Privacy Practices, and I understand that a copy may be emailed to me and is also available
        upon request by calling Tria Health at
        ${PhoneNumberPeriodDelimited}. I authorize Tria Health and its pharmacist(s) and designated employees to release to my health care providers
        (as identified above or otherwise learned by Tria Health from time to time) any medication or other information about me which is related to
        my medical treatment and/or continuity of care, unless I state otherwise in writing. I further understand that all information contained on
        this enrollment form (e.g., phone numbers, email addresses, medical information) and otherwise revealed through my encounters with Tria Health
        will remain strictly confidential, and will only be used or disclosed for purposes of payment, treatment or health care operations, including
        without limitation for the purpose of communicating with my health care providers and me as a part of the services offered through the Tria
        Health program.`,
      valueName: 'viewedPrivacyPolicy',
      value: formik.values.viewedPrivacyPolicy,
      link: 'https://www.triahealth.com/privacy',
    },
    {
      title: 'communication policy',
      paragraph: `I acknowledge receipt of the Patient Consent to Receive Electronic Communications from Tria Health (“Consent”), and I understand that a copy
        may be emailed to me and is also available upon request by calling Tria Health at ${PhoneNumberPeriodDelimited}. I have read the Consent form
        and fully understand the potential risks associated with Tria Health’s electronic communication program and any electronic communications that
        I may receive thereunder, including those that contain protected health information (PHI) and other individually identifiable information
        about me. By signing below, I indicate I am the person legally responsible for all use of the account(s) listed below, and that I am at least
        18 years of age. Without being obligated to do so, I voluntarily give Tria Health, and those authorized by Tria Health, my consent to
        participate in the electronic communication program and to receive electronic communications thereunder, pursuant to the terms and conditions
        set forth in the Consent.`,
      valueName: 'viewedCommunicationPolicy',
      value: formik.values.viewedCommunicationPolicy,
      link: 'https://www.triahealth.com/communication',
    },
    {
      title: 'terms and conditions',
      paragraph: `I acknowledge receipt of the Tria Health Terms and Conditions and I understand that these terms govern my use of Tria Health's services, including the Mobile App and Patient Portal. By signing below, I authorize Tria Health, its pharmacist(s), and designated employees to release any medication or other information related to my medical treatment and continuity of care to my health care providers, as identified above or otherwise learned by Tria Health from time to time, unless I state otherwise in writing. I further understand that all information contained in this agreement, including phone numbers, email addresses, and medical information, as well as any information disclosed during my interactions with Tria Health, will remain strictly confidential. This information will only be used or disclosed for purposes of payment, treatment, or health care operations, including communication with my health care providers and me as part of the services offered through the Tria Health program. If you have any questions or concerns regarding these Terms and Conditions, please contact Tria Health at ${PhoneNumberPeriodDelimited}.`,
      valueName: 'viewedTermsAndConditions',
      value: formik.values.viewedTermsAndConditions,
      link: 'https://77a061e4-6236-4f1a-acc8-15b886d599a3.usrfiles.com/ugd/77a061_56cb546c29ec4414b3e42ca9d9cbb357.pdf',
    },
  ]

  return (
    <StyledContainer>
      <h1 className="id-header">Please review & sign our policies</h1>
      <div style={{ fontSize: '25px', marginBottom: 20 }}>We take the privacy and security of your information seriously.</div>
      <Accordion style={{ border: '1px solid black' }}>
        {policies.map((policy, index) => (
          <Card style={{ border: '1px solid black', borderRadius: 0 }}>
            <Accordion.Toggle
              as={Card.Header}
              style={
                policy.value
                  ? { borderBottom: '1px solid black', backgroundColor: theme.colors.primary1, color: 'white', marginLeft: '30' }
                  : { borderBottom: '1px solid black', backgroundColor: theme.colors.darkGray, justifyContent: 'space-between', color: 'white' }
              }
              eventKey={`${index}`}
              onClick={() => toggleSection(index)}
            >
              <StyledCheckboxRow onClick={(e) => formik.setFieldValue(policy.valueName, !policy.value)}>
                <Checkbox
                  checked={policy.value}
                  styles={{
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: theme.colors.white,
                    border: '1px solid black',
                  }}
                  checkStyles={{ backgroundColor: theme.colors.black }}
                />
                <StyledCheckBoxRow>
                  <Typography variant="h3">Have you reviewed our</Typography>
                  <a href={policy.link} target="_blank" rel="noreferrer">
                    <Typography variant="h3" styles={{ textDecoration: 'underline', marginLeft: 5 }}>
                      {policy.title}
                    </Typography>
                  </a>
                  <Typography variant="h3">?</Typography>
                </StyledCheckBoxRow>
              </StyledCheckboxRow>
              <Icon
                name={index === expandedSection ? 'chevron-down' : 'chevron-right'}
                styles={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${index}`} style={{ padding: '20px 40px', textAlign: 'left' }}>
              <Typography size={14}>{policy.paragraph}</Typography>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
      <StyledCheckboxRow onClick={(e) => formik.setFieldValue('textOptIn', !formik.values.textOptIn)} style={{ margin: '60px auto 10px auto' }}>
        <Checkbox
          checked={formik.values.textOptIn}
          styles={{
            marginRight: 20,
            borderRadius: 0,
            backgroundColor: theme.colors.white,
            border: '1px solid black',
          }}
          checkStyles={{ backgroundColor: theme.colors.black }}
        />
        <StyledCheckBoxRow>
          <Typography styles={{ fontWeight: 'bold', marginRight: 10 }}>Tria Health Text Alerts: </Typography>
          <Typography>Opt-in for appointment reminders, medication savings and more!</Typography>
        </StyledCheckBoxRow>
      </StyledCheckboxRow>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',

          marginBottom: '10px',
          alignItems: 'center',
        }}
      >
        <StyledInput
          type="text"
          onChange={(value) => formik.setFieldValue('fullName', value.currentTarget.value)}
          value={formik.values.fullName}
          placeholder="Enter Your Full Name"
          style={{ textAlign: 'center' }}
        />
      </div>
      {!formik.isValid && attemptedSubmit && (
        <Row
          styles={{
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
          center
        >
          <StyledErrorText>Please accept all policies and sign</StyledErrorText>
        </Row>
      )}
      <StyledButtonContainer>
        <StyledButton pageName="appointment_consent" text="Back" styles={{ backgroundColor: theme.colors.darkGray }} onClick={onBackPressed} />
        <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
        <StyledButton
          pageName="appointment_consent"
          text="Next"
          loading={loading}
          onClick={() => {
            formik.handleSubmit()
            setAttemptedSubmit(true)
          }}
        />
      </StyledButtonContainer>
    </StyledContainer>
  )
}

const StyledCheckboxRow = styled.div`
  align-content: center;
  margin-top: 1px;
  margin-bottom: 1px;
  display: flex;
  justify-content: center;
  @media ${sizes.tablet} {
    justify-content: left;
    align-content: left;
  }
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  flex-direction: column;
  @media ${sizes.tablet} {
    flex-direction: row;
  }
`

const StyledCheckBoxRow = styled.div`
  display: flex;
  text-align: left;
  cursor: pointer;
`
const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary1};
  @media ${sizes.tablet} {
    width: 25%;
  }
`
const StyledInput = styled(Form.Control)`
  background-color: '#edfdff';
  text-align: center;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 15px;
  width: 100%;
  @media ${sizes.tablet} {
    width: 45%;
  }
`
