import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faAppStore, faFacebook, faGooglePlay, faLinkedinIn, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faAllergies, faFile, faHome, faPlayCircle, faUserMd } from '@fortawesome/free-solid-svg-icons'
import { faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons'
import {
  faArrowCircleUp,
  faArrowDown,
  faArrowDownWideShort,
  faArrowUp,
  faArrowUpWideShort,
  faBanSmoking,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCloudArrowDown,
  faEdit,
  faExclamationCircle,
  faFilePdf,
  faGaugeSimple,
  faGaugeSimpleMin,
  faHeadSideCough,
  faHeartCircleCheck,
  faHeartHalfStroke,
  faHistory,
  faInfoCircle,
  faLeftToLine,
  faLink,
  faLungsVirus,
  faMagnifyingGlassPlus,
  faNewspaper,
  faRedo,
  faTimes,
  faVial,
  faViruses,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAlarmClock,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faArrowRight,
  faArrowSquareDown,
  faArrowUp as faArrowUpSolid,
  faBadgeCheck,
  faBan,
  faBars,
  faBolt,
  faBookSpells,
  faBoxArchive,
  faBrain,
  faCalendar,
  faCapsules,
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlay,
  faCircleQuestion,
  faCircleXmark,
  faCloudUploadAlt,
  faCommentsAlt,
  faDollarSign,
  faDumbbell,
  faEnvelope,
  faExternalLinkAlt,
  faEyeDropper,
  faFileAlt,
  faFileCircleCheck,
  faFileMedical,
  faFilter,
  faHandHeart,
  faHeadSideBrain,
  faHeart,
  faHeartbeat,
  faInhaler,
  faLock,
  faLungs,
  faMapMarkerAlt,
  faMinus,
  faMinusCircle,
  faMobileAndroidAlt,
  faPaperPlane,
  faPaperclip,
  faPhoneRotary,
  faPhoneVolume,
  faPlateUtensils,
  faPlay,
  faPlus,
  faPrescriptionBottleAlt,
  faPrescriptionBottlePill,
  faReply,
  faSalad,
  faSearch,
  faSkeleton,
  faSparkles,
  faStethoscope,
  faSyringe,
  faTablets,
  faTint,
  faTrashAlt,
  faTrophyStar,
  faUser,
  faUserLock,
  faWater,
  faWeight,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { IconNames } from 'types/IconNames'

interface Props {
  name: IconNames
  size?: SizeProp
  link?: string
  color?: string
  styles?: React.CSSProperties
  className?: string
}
export const iconNames = {
  allergy: faAllergies,
  'alarm-clock': faAlarmClock,
  'angle-left': faAngleDoubleLeft,
  'angle-right': faAngleDoubleRight,
  'arrow-square-down': faArrowSquareDown,
  'arrow-up-circle': faArrowCircleUp,
  'arrow-up': faArrowUp,
  'arrow-down': faArrowDown,
  'arrow-up-solid': faArrowUpSolid,
  'arrow-right': faArrowRight,
  'arrow-left': faArrowLeft,
  archive: faBoxArchive,
  attachment: faPaperclip,
  badge: faBadgeCheck,
  back: faLeftToLine,
  ban: faBan,
  'ban-smoking': faBanSmoking,
  bars: faBars,
  bottle: faPrescriptionBottleAlt,
  brain: faBrain,
  calendar: faCalendar,
  capsules: faCapsules,
  check: faCheck,
  'check-circle': faCheckCircle,
  'chevron-left': faChevronLeft,
  'chevron-right': faChevronRight,
  'chevron-down': faChevronDown,
  'chevron-up': faChevronUp,
  circle: faCircle,
  circleX: faCircleXmark,
  'circle-light': faCircleLight,
  'cloud-arrow-down': faCloudArrowDown,
  'cloud-upload': faCloudUploadAlt,
  comment: faCommentsAlt,
  cough: faHeadSideCough,
  circleExclamation: faCircleExclamation,
  doctor: faUserMd,
  dollar: faDollarSign,
  dumbbell: faDumbbell,
  edit: faEdit,
  envelope: faEnvelope,
  exclamation: faExclamationCircle,
  'external-link': faExternalLinkAlt,
  'eye-dropper': faEyeDropper,
  facebook: faFacebook,
  file: faFile,
  fileLines: faFileAlt,
  recommendations: faFileMedical,
  'file-pdf': faFilePdf,
  fileCircleCheck: faFileCircleCheck,
  filter: faFilter,
  'gauge-easy': faGaugeSimpleMin,
  'gauge-average': faGaugeSimple,
  'hand-heart': faHandHeart,
  heart: faHeart,
  'heart-circle-check': faHeartCircleCheck,
  'heart-half': faHeartHalfStroke,
  heartbeat: faHeartbeat,
  history: faHistory,
  home: faHome,
  inhaler: faInhaler,
  ios: faAppStore,
  'info-circle': faInfoCircle,
  'info-circle-solid': faCircleInfo,
  lightning: faBolt,
  linkedin: faLinkedinIn,
  link: faLink,
  lock: faLock,
  lung: faLungs,
  'lung-virus': faLungsVirus,
  'magnifying-glass-plus': faMagnifyingGlassPlus,
  'map-marker-alt': faMapMarkerAlt,
  minus: faMinus,
  'minus-circle': faMinusCircle,
  'mobile-phone': faMobileAndroidAlt,
  newspaper: faNewspaper,
  play: faGooglePlay,
  'play-start': faPlay,
  phone: faPhoneRotary,
  phoneVolume: faPhoneVolume,
  pillBottle: faPrescriptionBottlePill,
  plate: faPlateUtensils,
  plus: faPlus,
  question: faCircleQuestion,
  redo: faRedo,
  reply: faReply,
  salad: faSalad,
  search: faSearch,
  'side-brain': faHeadSideBrain,
  skeleton: faSkeleton,
  sparkles: faSparkles,
  'spell-book': faBookSpells,
  'sort-up': faArrowUpWideShort,
  'sort-down': faArrowDownWideShort,
  send: faPaperPlane,
  start: faPlayCircle,
  stethoscope: faStethoscope,
  syringe: faSyringe,
  tablets: faTablets,
  times: faTimes,
  trash: faTrashAlt,
  trophy: faTrophyStar,
  user: faUser,
  vial: faVial,
  video: faCirclePlay,
  viruses: faViruses,
  water: faWater,
  'water-drop': faTint,
  weight: faWeight,
  'user-lock': faUserLock,
  youtube: faYoutube,
  x: faXTwitter,
}

function Icon({ name, link, color, size = 'lg', styles, className }: Props) {
  return link ? (
    <a href={link} style={{ color: 'black' }} target="_blank" rel="noreferrer">
      <FontAwesomeIcon style={{ ...styles }} className={className} size={size} color={color} icon={name ? iconNames[name] : iconNames['file']} />
    </a>
  ) : (
    <FontAwesomeIcon style={{ ...styles }} className={className} size={size} color={color} icon={name ? iconNames[name] : iconNames['file']} />
  )
}

export default React.memo(Icon)
