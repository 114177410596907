import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import styled, { useTheme } from 'styled-components'
import './pagination.scss'

interface Props {
  tableRows
  editClick
  dashboard: string
}
export default function MobileDataGrid({ tableRows, editClick, dashboard }: Props) {
  const [expandedSection, setExpandedSection] = useState<number | undefined>()

  const theme = useTheme()

  const toggleSection = (sectionId: number) => {
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }
  const [itemOffset, setItemOffset] = useState(0)
  const rowsPerPage = 5

  const endOffset = itemOffset + rowsPerPage

  const currentRows = tableRows?.slice(itemOffset, endOffset)

  const pageCount = Math.ceil(tableRows!.length / rowsPerPage)
  const handlePageClick = (event) => {
    const newOffset = (event.selected * rowsPerPage) % tableRows!.length
    setItemOffset(newOffset)
  }

  return (
    <>
      <StyledAccordion style={dashboard === 'glucose' ? { marginTop: 150 } : { marginTop: 310 }}>
        {currentRows &&
          currentRows.map((row, index) => (
            <Card key={index} style={index % 2 !== 0 ? { display: 'block' } : { backgroundColor: '#f2f2f2' }}>
              <Accordion.Toggle
                as={Card.Header}
                style={
                  expandedSection === index
                    ? { backgroundColor: theme.colors.primary1, color: 'white', marginLeft: '30' }
                    : { justifyContent: 'space-between' }
                }
                eventKey={`${index}`}
                onClick={() => toggleSection(index)}
              >
                <div>
                  <Typography styles={{ marginLeft: 0, marginRight: '5px' }} size={20}>
                    {row.readingDate}
                  </Typography>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 20, textAlign: 'right' }}>
                  {dashboard === 'glucose' ? (
                    <Typography size={20}>{row.bloodGlucose}</Typography>
                  ) : (
                    <Typography size={20}>{`${row.systolicValue}/${row.diastolicValue}`}</Typography>
                  )}
                </div>

                {expandedSection === index ? <Icon name="chevron-down" /> : <Icon name="chevron-right" />}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${index}`}>
                <Card.Body>
                  {dashboard === 'glucose' ? (
                    <div>
                      <Row>
                        <Typography font="bold">{`Reading Type: `}</Typography>
                        <Typography styles={{ marginLeft: 5 }}>{` ${row.readingType}`}</Typography>
                      </Row>
                      <Row>
                        <Typography font="bold">{`Meal Type: `}</Typography>
                        <Typography styles={{ marginLeft: 5 }}>{`${row.mealType}`}</Typography>
                      </Row>
                      <Row>
                        <Typography font="bold">{`Carb Count: `}</Typography>
                        <Typography styles={{ marginLeft: 5 }}>{`${row.carbCount}`}</Typography>
                      </Row>
                      <Row>
                        <Typography font="bold">{`Insulin Dosage: `}</Typography>
                        <Typography styles={{ marginLeft: 5 }}>{`${row.insulinDosage}`}</Typography>
                      </Row>
                      <Row>
                        <Typography font="bold" styles={{ width: 280 }}>{`Notes: `}</Typography>
                      </Row>
                      <Row>
                        <Typography styles={{ marginLeft: 5, width: 280 }}>{`${row.notes}`}</Typography>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Typography font="bold">{`Type: `}</Typography>
                        <Typography styles={{ marginLeft: 5 }}>{` ${row.patientReadingLevel}`}</Typography>
                      </Row>
                      <Row>
                        <Typography font="bold">{`Heart Rate (bpm): `}</Typography>
                        <Typography styles={{ marginLeft: 5 }}>{`${row.heartRate}`}</Typography>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Button
                      pageName="dashboard"
                      text="Edit"
                      styles={{ marginLeft: 'auto', marginRight: 10 }}
                      onClick={() => editClick(row.readingId)}
                    ></Button>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
      </StyledAccordion>
      <ReactPaginate
        activeClassName={'item active '}
        breakClassName={'item break-me '}
        containerClassName={'pagination'}
        disabledClassName={'disabled-page'}
        pageClassName={'page'}
        marginPagesDisplayed={1}
        nextLinkClassName={'item next '}
        disabledLinkClassName={'disabled-arrow'}
        previousLinkClassName={'item previous'}
        breakLabel="..."
        nextLabel={<Icon name="chevron-right" size="1x" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<Icon name="chevron-left" size="1x" />}
      />
    </>
  )
}

const StyledAccordion = styled(Accordion)`
  border-radius: 10px;
  z-index: 50px;
  position: relative;
`
