import { useApi } from './useAPI'

export default function useWelltrak() {
  const getGuidFromWelltrak = async (welltrak: number) => {
    const response = TriaApi.get(`/api/guid?wellTrakPId=${welltrak}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch(function (error) {
      // handle error
    })

    return response
  }

  const TriaApi = useApi()
  return {
    getGuidFromWelltrak,
  }
}
