import Button from 'Components/Base/Button'
import CarePlanCard from 'Components/Base/CarePlanCard'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useState } from 'react'
import { getCareplan } from 'reducers/recommendationsSlice'
import styled, { useTheme } from 'styled-components'
import { Medication } from 'types/Medication'
import MedicationsAccordion from './MedicationsAccordion'

interface Props {
  isMobile: boolean
  sideMenuToggled: () => void
  onGetDocument: (fileName: string, documentId: string, documentLink: string) => void
  filterMeds
  dropValue: string
  filteredMedications: Medication[]
  medicationRecommendationsLength: number
  medicationSavings: number
  medicationDiscontinueLength: number
}

export default function MedicationListPage({
  isMobile,
  sideMenuToggled,
  onGetDocument,
  filterMeds,
  dropValue,
  filteredMedications,
  medicationRecommendationsLength,
  medicationDiscontinueLength,
  medicationSavings,
}: Props) {
  const theme = useTheme()

  const [order, setOrder] = useState<string>('')

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Medications')

  const careplan = useAppSelector(getCareplan)

  const medCardPdf = careplan?.pharmacistResources.find((rec) => rec.documentName.toLowerCase().includes('med card'))

  function sort() {
    if (order === 'ZToA') {
      setOrder('AToZ')
    } else {
      setOrder('ZToA')
    }
  }

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title="Medication List" icon="pillBottle" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <StyledCardContainer>
        <StyledQuickAccess>
          <Row>
            {!isMobile ? (
              <div style={{ margin: '0px 2px 0px 10px' }}>
                {order === 'ZToA' ? (
                  <Button pageName="careplan_medications" onClick={() => sort()} text="Sort Z to A" rightIcon="sort-up" backgroundColor="grey" />
                ) : (
                  <Button pageName="careplan_medications" onClick={() => sort()} text="Sort A to Z" rightIcon="sort-down" backgroundColor="grey" />
                )}
              </div>
            ) : (
              <></>
            )}
            <StyledDropdown as="select" placeholder="Sort" value={dropValue} onChange={(e) => filterMeds(e.target.value)}>
              <option value={''} selected disabled>
                Choose a Filter
              </option>
              <option value={'all'}>All Medications</option>
              <option value={'Recommended'}>Recommended Start</option>
              <option value={'Recommended Stop'}>Recommended Discontinuations</option>
              <option value={'savings'}>Recommended Savings</option>
            </StyledDropdown>
          </Row>
          <StyledAccordionContainer>
            <MedicationsAccordion activeMedications={filteredMedications} order={order} isMobile={isMobile} />
          </StyledAccordionContainer>
        </StyledQuickAccess>
      </StyledCardContainer>
      <StyledProfileCardContainer>
        {medCardPdf && (
          <StyledCard>
            <div
              className="careplan-detail-document-item"
              onClick={() =>
                medCardPdf &&
                onGetDocument(
                  medCardPdf.documentName ?? 'Care Plan Document',
                  medCardPdf.patientWebDocumentPId.toString(),
                  medCardPdf.documentLink ?? null,
                )
              }
            >
              <Row styles={{ width: '90%', margin: 'auto', cursor: 'pointer' }}>
                <Row>
                  <Column>
                    <Row
                      styles={{
                        textAlign: 'left',
                        width: '100%',
                      }}
                    >
                      <Typography variant="h5" size={15}>
                        {medCardPdf.documentName}
                      </Typography>
                    </Row>
                    <Row
                      styles={{
                        textAlign: 'left',
                        width: '100%',
                      }}
                    >
                      <Typography>{`Updated ${dayjs(medCardPdf?.documentDate).format('M/D/YYYY')}`}</Typography>
                    </Row>
                  </Column>
                </Row>
                <Column styles={{ marginLeft: 'auto' }}>
                  <Icon name={'file-pdf'} color={theme.colors.primary1} size={'2x'} styles={{ marginTop: 8 }} />
                </Column>
              </Row>
            </div>
          </StyledCard>
        )}
        <div style={medCardPdf || isMobile ? {} : { marginTop: '45px' }}>
          <div onClick={() => filterMeds('Recommended')} style={medCardPdf || isMobile ? {} : { height: '175px' }}>
            <CarePlanCard
              icon="search"
              number={medicationRecommendationsLength}
              title={'New Medication'}
              titleLine2={'Recommendations'}
              route={'/careplan/medications'}
            />
          </div>
          <div onClick={() => filterMeds('Recommended Stop')}>
            <CarePlanCard
              icon="search"
              number={medicationDiscontinueLength}
              title={'Recommended'}
              titleLine2={'Discontinuations'}
              route={'/careplan/medications'}
            />
          </div>
          <div onClick={() => filterMeds('savings')} style={medCardPdf || isMobile ? {} : { height: '175px', marginTop: '50px' }}>
            <CarePlanCard
              icon="search"
              number={medicationSavings}
              title={'Medication Savings'}
              titleLine2={'Opportunities'}
              route={'/careplan/medications'}
            />
          </div>
        </div>
      </StyledProfileCardContainer>
    </>
  )
}
const StyledCardContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    display: flex;
    flex-direction: row;
  }
`
const StyledProfileCardContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    width: 30%;
    margin-left: 73.5%;
    margin-top: -565px;
  }
`
const StyledAccordionContainer = styled.div`
  overflow-y: scroll;
  margin: auto;
  @media only screen and (min-width: 1100px) {
    height: 430px;
    width: 98%;
  }

  @media only screen and (max-width: 1100px) {
    height: 240px;
    width: 95%;
    margin: auto;
  }
`
const StyledQuickAccess = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  margin-top: 0%;
  position: relative;

  padding: 13px;
  @media only screen and (min-width: 1100px) {
    height: 520px;
    width: 70%;
  }

  @media only screen and (max-width: 1100px) {
    height: 340px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
    margin-left: 0px;
    margin-right: 0px;
  }
`
const StyledCard = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  padding-top: 10px;
  @media only screen and (min-width: 1100px) {
    height: 70px;
    margin-top: 32px;
  }

  @media only screen and (max-width: 1100px) {
    height: 70px;
    margin: auto;
    margin-top: 5%;
  }
`

const StyledDropdown = styled.select`
  text-align: left;
  padding: 5px;
  box-shadow: inset 0px 1px 3px gray;
  min-height: 44px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 280px;
  background: #edfdff;
`
