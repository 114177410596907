import Pagination from '@mui/material/Pagination'
import { useGridSlotComponentProps } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'

interface Props {
  cgmLogo?: string
}

export function CustomPagination({ cgmLogo }: Props) {
  const { state, apiRef } = useGridSlotComponentProps()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  return (
    <>
      {cgmLogo && <Image src={cgmLogo} height={15} style={{ objectFit: 'contain' }} />}

      <StyledPagination
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        hideNextButton={false}
        style={isMobile ? { display: 'none' } : {}}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </>
  )
}

const StyledPagination = styled(Pagination)`
  display: flex;
`
