import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function OVPStep1({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])

  return (
    <Row styles={{ width: '100%' }}>
      <Column styles={{ margin: isMobile ? 'auto 0px' : '0px 0px 0px 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h1" size={22}>{sectionInfo?.titles.find((title) => title.titleId === 1)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 1)?.paragraph ?? ''}</Typography>
        </Row>
        <Typography variant="h1" styles={{ margin: isMobile ? '20px auto' : '20px 0px' }} size={22}>
          {sectionInfo?.titles.find((title) => title.titleId === 2)?.titleText ?? ''}
        </Typography>
        <Typography styles={{ margin: isMobile ? '20px auto' : '20px 0px' }} size={20}>
          {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 2)?.paragraph ?? ''}
        </Typography>
        <Typography styles={{ margin: isMobile ? '20px auto' : '20px 0px' }} size={20}>
          {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 3)?.paragraph ?? ''}
        </Typography>
      </Column>
      {!isMobile && (
        <Column styles={{ width: '100%', marginTop: -90 }}>
          <div style={{ marginLeft: 'auto' }}>
            <Column styles={{ position: 'relative', top: '47%', left: '20%', color: '#FFF', textAlign: 'center', width: 300 }}>
              <Typography styles={{ fontWeight: 'bold' }} size={24}>
                {sectionInfo?.titles.find((title) => title.titleId === 3)?.titleText ?? ''}
              </Typography>
              <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 4)?.paragraph ?? ''}</Typography>
            </Column>
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 1)?.url}
              alt="scale"
              style={{ width: 507, height: 213, margin: '0px auto' }}
            />
          </div>
          <div style={{ marginLeft: 'auto', marginTop: -80 }}>
            <Column styles={{ position: 'relative', top: '47%', left: '20%', color: '#FFF', textAlign: 'center', width: 300 }}>
              <Typography variant="h1" styles={{ fontWeight: 'bold' }} size={24}>
                {sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}
              </Typography>
              <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 5)?.paragraph ?? ''}</Typography>
            </Column>
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 2)?.url}
              alt="scale"
              style={{ width: 507, height: 213, margin: '0px auto' }}
            />
          </div>
        </Column>
      )}
    </Row>
  )
}
