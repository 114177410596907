import dayjs from 'dayjs'
import jsPDF from 'jspdf'

export const generatePDF = (readingMetadata, readingCounts, readingsChartImage, pieChartImage, logo, theme) => {
  const doc = new jsPDF('p', 'mm', 'A4')
  doc.setFont('Raleway')
  doc.setFontSize(15)
  doc.setTextColor('black')
  doc.addImage(logo, 'JPEG', 88, 2, 38, 10)
  doc.text(
    `Blood Glucose Summary: ${dayjs(readingMetadata?.firstReadingDate).format('MMMM DD, YYYY')} - ${dayjs(readingMetadata?.lastReadingDate).format(
      'MMMM DD, YYYY',
    )}`,
    48,
    17,
  )
  doc.setFontSize(15)
  doc.setDrawColor(theme.colors.lightGray)
  doc.roundedRect(100, 20, 100, 105, 3, 3)
  readingMetadata?.averageReading && doc.text(`Average Reading: ${Math.round(readingMetadata?.averageReading)}`, 110, 40)
  readingMetadata?.estimatedA1C &&
    doc.text(`Estimated A1C: ${readingMetadata.estimatedA1C ? readingMetadata.estimatedA1C?.toFixed(1) : '0'}`, 110, 50)
  readingCounts && doc.text(`Readings Below 70: ${readingCounts?.low + readingCounts?.criticalLow}`, 110, 60)
  readingCounts && doc.text(`Readings Above 250: ${readingCounts?.high}`, 110, 70)
  readingMetadata?.averageTestsPerDay &&
    doc.text(`Average # of Readings Per Day: ${readingMetadata.averageTestsPerDay ? readingMetadata.averageTestsPerDay.toFixed(1) : '0'}`, 110, 80)
  readingCounts && doc.text(`Readings Above 250: ${readingCounts?.total}`, 110, 90)
  doc.setDrawColor(theme.colors.lightGray)
  doc.roundedRect(10, 20, 80, 105, 3, 3)
  readingsChartImage && doc.addImage(readingsChartImage, 'JPEG', 10, 130, 190, 105)
  pieChartImage && doc.addImage(pieChartImage, 'JPEG', 14, 24, 70, 70)

  doc.setFontSize(11)
  doc.setDrawColor(theme.colors.lightGray)
  doc.setFillColor('#EAAA00')
  doc.roundedRect(12, 95, 3, 3, 0.5, 0.5, 'F')
  doc.text(`High (>250): ${readingCounts.high ?? 0} (${Math.round((readingCounts.high / readingCounts.total) * 100) ?? 0}%)`, 17, 98)
  doc.setFillColor('#FED141')
  doc.roundedRect(12, 101, 3, 3, 0.5, 0.5, 'F')
  doc.text(
    `Above-Range (250-180): ${readingCounts.aboveRange ?? 0} (${Math.round((readingCounts.aboveRange / readingCounts.total) * 100) ?? 0}%)`,
    17,
    104,
  )
  doc.setFillColor('#7A9A01')
  doc.roundedRect(12, 107, 3, 3, 0.5, 0.5, 'F')
  doc.text(`In-Range (180-70): ${readingCounts.inRange ?? 0} (${Math.round((readingCounts.inRange / readingCounts.total) * 100) ?? 0}%)`, 17, 110)
  doc.setFillColor('#E40046')
  doc.roundedRect(12, 113, 3, 3, 0.5, 0.5, 'F')
  doc.text(`Low Readings (<70-55): ${readingCounts.low ?? 0} (${Math.round((readingCounts.low / readingCounts.total) * 100) ?? 0}%)`, 17, 116)
  doc.setFillColor('#9B2743')
  doc.roundedRect(12, 119, 3, 3, 0.5, 0.5, 'F')
  doc.text(
    `Dangerously Low (<54): ${readingCounts.criticalLow ?? 0} (${Math.round((readingCounts.criticalLow / readingCounts.total) * 100) ?? 0}%)`,
    17,
    122,
  )
  const pageSize = doc.internal.pageSize
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
  doc.setFontSize(12)
  doc.setTextColor('#000000')
  doc.text('Have questions about the information above or would like more detail?', 45, pageHeight - 25)
  doc.text(' Contact a Tria Pharmacist today at 1-888-799-8742 or email us at info@triahealth.com.', 29, pageHeight - 20)
  doc.save(`Blood Glucose Summary_${dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]')}.pdf`)
}
