import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import { addCaregiver, deleteCaregiver, getCaregivers, loadCaregivers, updateCaregiverPermissions } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import { Caregiver } from 'types/Caregiver'
import ContentHeader from '../ContentHeader'
import './CaregiverAccess.scss'
import styled, { useTheme } from 'styled-components'
import AddCaregiverModal from './AddCaregiverModal'
import CaregiverDetail from './CaregiverDetail'

interface Props {
  isMobile: boolean
  backPressed: () => void
  sideMenuToggled: () => void
}

export default function CaregiverAccess({ isMobile, backPressed, sideMenuToggled }: Props) {
  const theme = useTheme()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Caregivers')

  const dispatch = useAppDispatch()
  const token = useAppSelector(getAccessToken)

  const caregiversGet = useAppSelector(getCaregivers)
  const [modalOpen, setModalOpen] = useState(false)
  const [addCaregiverError, setAddCaregiverError] = useState<string | undefined>(undefined)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [caregiverToDelete, setCaregiverToDelete] = useState<Caregiver | undefined>()
  const [caregiverChangeBool, setCaregiverChangeBool] = useState(false)
  const [caregivers, setCaregivers] = useState(caregiversGet)
  const [caregiverChangesSaved, setCaregiverChangesSaved] = useState<boolean>(false)
  const [caregiverChangesSavedMessage, setCaregiverChangesSavedMessage] = useState<string>('')

  useEffect(() => {
    dispatch(setLoading(true))
    setCaregivers(caregiversGet)
    dispatch(setLoading(false))
  }, [caregiversGet])

  const submitCaregiver = (values: Caregiver) => {
    dispatch(setLoading(true))

    values.cellPhone = values.cellPhone?.replace(/[^\d]/g, '')

    token &&
      dispatch(addCaregiver({ token: token, caregiver: values }))
        .then((res: any) => {
          if (res.error) {
            setAddCaregiverError('There was an error. Please try again.')
            dispatch(setLoading(false))
          } else {
            setModalOpen(false)
            dispatch(loadCaregivers(token))
            dispatch(setLoading(false))
          }
        })
        .catch((res) => {
          dispatch(setLoading(false))
        })
  }

  const toggleDeleteModal = (caregiver: Caregiver) => {
    if (caregiver.patientCaregiverPId) {
      setCaregiverToDelete(caregiver)
      setDeleteModalOpen(true)
    }
  }
  const removeCaregiver = (caregiverId: number | undefined) => {
    if (token) {
      dispatch(deleteCaregiver({ token: token, patientCaregiverPId: caregiverId }))
      dispatch(loadCaregivers(token))
      setCaregivers(caregivers?.filter((caregiver) => caregiver.patientCaregiverPId !== caregiverId))
      setDeleteModalOpen(false)
    }
  }

  const handleCaregiversSave = (caregivers: Caregiver[] | undefined) => {
    dispatch(setLoading(true))
    token &&
      dispatch(updateCaregiverPermissions({ token: token, caregivers: caregivers }))
        .then((res: any) => {
          if (res.error) {
            setCaregiverChangesSavedMessage('There was an error. Please try again.')
            setCaregiverChangesSaved(true)
            dispatch(setLoading(false))
          } else {
            setCaregiverChangesSavedMessage('Your Caregiver Access Changes have been Saved!')
            setCaregiverChangesSaved(true)
            setModalOpen(false)
            dispatch(loadCaregivers(token))
            dispatch(setLoading(false))
          }
        })
        .catch((res) => {
          dispatch(setLoading(false))
        })
    setCaregiverChangeBool(false)
  }

  return (
    <>
      <Row
        styles={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color={theme.colors.primary1} styles={{ marginBottom: 20 }} />
          </div>
        )}
        <ContentHeader title="Caregiver Access" icon="hand-heart" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <Typography color={theme.colors.black} styles={{ marginBottom: 10 }}>
        Tria Health understands that each patient’s health care journey and goals are unique, and we want to provide options that set our patients up
        for success. Adding a spouse, loved one, or caregiver to your Tria Health communication, can be a great option to help manage a condition and
        achieve health goals. Patients can integrate their caregivers in the following ways:
      </Typography>
      <div>
        <Typography color={theme.colors.primary1}>
          <div style={{ alignItems: 'center', fontWeight: 'bold' }}>
            <ul>
              <li>Allow your caregiver to receive text messages of your health condition and appointment reminders.</li>
              <li>Invite a caregiver to attend your pharmacist consultations.</li>
            </ul>
          </div>
        </Typography>
        <Typography color={theme.colors.black}>
          <div style={{ marginBottom: 5, fontWeight: 'bold' }}>If you would like to add someone, then follow the steps listed below:</div>
        </Typography>
        <Typography color={theme.colors.black}>
          <div style={{ marginBottom: 5 }}>STEP 1: Fill out the HIPAA Authorization Form.</div>
        </Typography>
        <Typography color={theme.colors.black}>
          <div style={{ marginBottom: 5 }}>
            STEP 2: Add the caregiver(s) whom you would like to receive your Tria Health communication, and the types of messages.
          </div>
        </Typography>
        <Typography color={theme.colors.black}>
          <div style={{ marginBottom: 5 }}>
            STEP 3: Once these forms are completed, Tria Health will then text (or email) your caregiver(s) an invitation to receive these messages.
            After your caregiver(s) accepts, they will begin to receive the same Tria Health communications you authorized.
          </div>
        </Typography>
      </div>
      <div
        style={{
          marginBottom: 15,
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
        }}
        onClick={() => setModalOpen(true)}
      >
        <Typography color={theme.colors.primary1} font="bold" size={20} styles={{ marginRight: 5 }}>
          <>Add a New Caregiver </>
        </Typography>
        <Icon size="lg" color={theme.colors.primary1} name="plus" />
      </div>

      {caregivers && (
        <>
          {caregivers.map((caregiver) => (
            <CaregiverDetail
              caregiver={caregiver}
              caregivers={caregivers}
              onToggleDeleteModal={(caregiver) => toggleDeleteModal(caregiver)}
              setCaregiverChangeBool={setCaregiverChangeBool}
              setCaregiverChangesSaved={setCaregiverChangesSaved}
              setCaregivers={setCaregivers}
            />
          ))}
        </>
      )}

      <AddCaregiverModal
        modalOpen={modalOpen}
        onSubmitCaregiver={(values) => submitCaregiver(values)}
        setModalOpen={(value) => setModalOpen(value)}
        addCaregiverError={addCaregiverError}
      />
      <StyledModal show={deleteModalOpen} onRequestClose={() => setDeleteModalOpen(!modalOpen)} onHide={() => setDeleteModalOpen(false)}>
        <StyledCustomModalHeader>
          <Typography color={theme.colors.white} font="bold" size={20}>
            Delete Caregiver
          </Typography>
          <div onClick={() => setDeleteModalOpen(false)}>
            <Icon name="times" color="white" />
          </div>
        </StyledCustomModalHeader>

        <StyledModalBody>
          <Row styles={{ marginTop: 20 }}>
            <Typography font="regular" size={20} styles={{ marginBottom: 20, marginTop: 20 }}>
              {caregiverToDelete ? `Delete ${caregiverToDelete.name}?` : 'Delete?'}
            </Typography>
          </Row>

          <Row styles={{ justifyContent: 'space-between' }}>
            <Button
              pageName={`profile_caregiver_delete`}
              text="Confirm"
              className="caregiver-add-button"
              onClick={() => {
                removeCaregiver(caregiverToDelete?.patientCaregiverPId)
              }}
            />
            <Button
              pageName={`profile_caregiver_delete`}
              text="Cancel"
              className="caregiver-cancel-button"
              onClick={() => setDeleteModalOpen(false)}
            />
          </Row>
        </StyledModalBody>
      </StyledModal>
      {caregiverChangesSaved ? (
        <Typography font="bold" color={theme.colors.primary1} size={20} styles={{ marginBottom: 20, marginTop: 20 }}>
          {caregiverChangesSavedMessage}
        </Typography>
      ) : (
        <div style={{ display: 'none' }}></div>
      )}
      {caregiverChangeBool && (
        <Button
          pageName={`profile_caregiver`}
          text="Save Changes"
          className="profile-save-button"
          onClick={() => handleCaregiversSave(caregivers)} //setcaregiverchangebool to false
        />
      )}
    </>
  )
}

const StyledModal = styled(Modal)`
  position: absolute;
  top: 40%;
`
const StyledCustomModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.primary1};
  align-items: center;
  color: white;
`
const StyledModalBody = styled(Modal.Body)`
  min-height: min-content;
  @media ${sizes.tablet} {
    min-height: 10vh;
  }
`
