import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import Image from '../../Assets/Images/Portal_Thumbs_Up.svg'
import Button from '../../Components/Base/Button'
import './RegisterConfirmationPage.scss'

export default function RegisterConfirmationPage() {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="confirm-page-container">
      <h1 style={{ fontFamily: 'Raleway-Bold' }}>Get Ready for a Healthier you!</h1>
      <p>
        Thank you for registering for Tria Health’s Patient Portal. You’ll now have access to a variety or resources that can help you both manage and
        improve your health.
      </p>
      <div style={{ position: 'relative' }}>
        <img src={Image} className="success-image" alt="Woman putting two thumbs up" />

        <Button
          pageName="register_confirmation"
          onClick={() => navigate('/')}
          styles={{
            border: '0px',
            backgroundColor: '#425563',
            borderRadius: 0,
            padding: '10px 50px 10px 50px',
            fontFamily: 'Raleway-Semi-Bold',
            left: '50%',
            position: 'absolute',
            bottom: 50,
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          }}
          text={'Continue to Patient Portal'}
        />
      </div>
    </div>
  )
}
