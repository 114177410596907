import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledPageContainer } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import React, { useEffect } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Challenge, ChallengeSection } from 'types/Challenge'
import ProgressBar from '../Components/ProgressBar/ProgressBar'
import HelpCard from './HelpCard'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'

interface Props {
  challenges: Challenge[] | undefined
  setSections: (sections: ChallengeSection[]) => void
  setChallenges: (challenges: Challenge[]) => void
  setCurrentChallenge: (challenge: Challenge) => void
  isMobile: boolean
}
export default function ChallengeDashboard({ challenges, setSections, setChallenges, setCurrentChallenge, isMobile }: Props) {
  const navigate = useNavigate()
  const token = useAppSelector(getAccessToken) ?? ''
  const { challengeCompletion, getChallenges } = useChallenges(token)
  const dispatch = useAppDispatch()


  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(setLoading(true))
    getChallenges()
      .then((res) => {
        setChallenges(res.data.challenges)
      }).finally(() => dispatch(setLoading(false)))
  }, [])

  return (
    <div>
      <StyledPageContainer
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Column styles={{ width: 1600 }}>
          {isMobile ? (
            <Row styles={{ margin: '-10px auto 10px 0px' }} onClick={() => navigate('/resources')}>
              <Icon name="back" styles={{ marginRight: 10 }} size="xl" />
              <Typography font="bold" styles={{ marginRight: 5 }}>
                Exit
              </Typography>
            </Row>
          ) : (
            <StyledButton style={{ backgroundColor: '#425563', height: 45 }} onClick={() => navigate('/resources')}>
              Back
            </StyledButton>
          )}
          <Row center styles={{ width: '30%', margin: '-5% auto 0px' }}>
            <StyledTitle>Challenges</StyledTitle>
          </Row>
          <Row center styles={{ textAlign: 'center' }}>
            <Typography size={24}>By completing challenges, you have the opportunity to learn</Typography>
          </Row>
          <Row center styles={{ textAlign: 'center' }}>
            <Typography size={24}>more about how to keep yourself healthy and active.</Typography>
          </Row>
          <StyledGrid>
            {challenges &&
              challenges.map((challenge) => (
                <Column>
                  <HelpCard
                    key={challenge.patientChallengePId}
                    title={challenge.name}
                    relative={false}
                    icon={challenge.icon}
                    sections={challenge.challengeSections}
                    link={`/challenges/sections`}
                    patientChallengePId={challenge.patientChallengePId}
                    challengePId={challenge.challengePId}
                    challenges={challenges}
                    setSections={(sections: ChallengeSection[]) => setSections(sections)}
                    setChallenges={(challenges: Challenge[]) => setChallenges(challenges)}
                    setCurrentChallenge={(challenge: Challenge) => setCurrentChallenge(challenge)}
                    isMobile={isMobile}
                  />
                  <ProgressBar
                    stepPercent={(challenge.challengeSections.filter((section) => section.completed).length / challenge.numberOfSections) * 100}
                  />
                </Column>
              ))}
          </StyledGrid>
        </Column>
      </StyledPageContainer>
    </div>
  )
}

const StyledTitle = styled.span`
  font-family: 'Raleway-Bold';
  text-align: center;
  margin-bottom: 15px;
  @media ${sizes.mobileL} {
    font-size: 8vw;
  }
  @media ${sizes.tablet} {
    font-size: 4vw;
  }
  @media ${sizes.desktop} {
    font-size: 3vw;
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
    font-size: 8vw;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  justify-items: center;
  margin-top: 40px;
  }
`
const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  @media ${sizes.mobileL} {
    width: 150px;
  }
`
