import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Inhaler 1.png'
import Image2 from '../../../../Assets/Images/STOP/Inhaler 2.png'
import Image3 from '../../../../Assets/Images/STOP/Inhaler 3.png'

export default function NRTInhaler() {
  return (
    <ProConScreen
      title="NRT Products: Inhaler"
      subtitle="A device that provides a small dose of nicotine when puffed on the inhaler. Nicotine vapor is released from a cartridge inside the device and it is absorbed through the lining of mouth and throat."
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Controls sudden cravings and withdrawal symptoms ',
        'Dose can be adjusted throughout the day',
        'Keeps hands busy - mimics hand-to-mouth ritual of smoking acting as a substitute for a cigarette',
        'Can be used in combination with other NRT products and medications',
      ]}
      cons={[
        'Available by prescription only',
        'Must be used repeatedly throughout the day to control cravings or withdrawal symptoms',
        'Visible by others',
        'Can cause mouth or throat irritation',
        'Not recommended for use with a breathing condition such as asthma',
        'Cartridges might be less effective in cold environments (≤60°F)',
        'Food and drink should be avoid 15 minutes before using',
      ]}
    />
  )
}
