import Typography from 'Components/Base/Typography'
import colors from 'Styles/colors'
import { StyledPageContainer } from 'Styles/global'
import { Formik } from 'formik'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePatientAssessment from 'hooks/usePatientAssessment'
import React, { useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Params, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { AUASIFormValues } from 'types/Forms'
import ContentHeader from '../../PatientProfile/components/ContentHeader'
import CalculationRow from '../components/CalculationRow'
import ScoreContainer from '../components/ScoreContainer'

export default function AUASIForm() {
  const { guid }: Readonly<Params<string>> = useParams()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('SCCAI')

  const theme = useTheme()

  const [score, setScore] = useState<number>(0)
  const scoreRef = useRef<null | HTMLDivElement>(null)

  const { getAUASIScore } = usePatientAssessment()
  const [gettingScore, setGettingScore] = useState(false)

  const executeScoreScroll = () => scoreRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const initialValues: AUASIFormValues = {
    notEmpty: '',
    less2Hours: '',
    stopStart: '',
    postpone: '',
    weakStream: '',
    strain: '',
    nightTimes: '',
  }

  const submitAUASIForm = (values: AUASIFormValues) => {
    setGettingScore(true)

    values.guid = guid

    executeScoreScroll()
    getAUASIScore(values, (response: any) => {
      setScore(response.total)
    }).then(() => setGettingScore(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => submitAUASIForm(values)}>
      {({ values, setFieldValue, submitForm }) => {
        return (
          <StyledPageContainer>
            <ContentHeader title="AUASI – American Urological Association Symptom Index" color={colors.primary.blue} />
            <div className="survey-banner">Answer according to the symptoms you have had over the past month.</div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">
                  How often have you had a sensation of not emptying your bladder completely after you finished urinating? (Incomplete emptying)
                </Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.notEmpty}
                  onChange={(value) => setFieldValue('notEmpty', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">
                  How often have you had to urinate again less than 2 hours after you finished urinating? (frequency)
                </Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.less2Hours}
                  onChange={(value) => setFieldValue('less2Hours', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">
                  How often have you found that you stopped and started again several time when you urinated? (intermittency)
                </Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.stopStart}
                  onChange={(value) => setFieldValue('stopStart', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">How often have you found it difficult to postpone urination? (urgency)</Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.postpone}
                  onChange={(value) => setFieldValue('postpone', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">How often have you had a weak urinary stream? (Weak-stream)</Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.weakStream}
                  onChange={(value) => setFieldValue('weakStream', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">How often have you had to push or strain to begin urination? (straining)</Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.strain}
                  onChange={(value) => setFieldValue('strain', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">
                  How many times did you most typically get up to urinate from the time you weny to bed at night until the time you got up in the
                  morning (nocturia)
                </Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.nightTimes}
                  onChange={(value) => setFieldValue('nightTimes', +value.currentTarget.value)}
                >
                  <option value={''} selected>
                    Choose option
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Less than 1 time in 5</option>
                  <option value={2}>Less than half the time</option>
                  <option value={3}>Half the time</option>
                  <option value={4}>More than half the time</option>
                  <option value={5}>Almost always</option>
                </Form.Control>
              </div>
            </div>
            <CalculationRow
              onSubmit={submitForm}
              gettingScore={gettingScore}
              scoreRef={scoreRef}
              score={score}
              onReset={() => {
                setFieldValue('notEmpty', '')
                setFieldValue('less2Hours', '')
                setFieldValue('stopStart', '')
                setFieldValue('postpone', '')
                setFieldValue('weakStream', '')
                setFieldValue('strain', false)
                setFieldValue('nightTimes', false)
                setScore(0)
              }}
            />

            {score !== 0 && (
              <ScoreContainer
                rows={[
                  {
                    color: theme.colors.forms.mild,
                    range: '0 - 7',
                    label: 'Mild',
                  },
                  {
                    color: theme.colors.forms.moderate,
                    range: '8 - 29',
                    label: 'Moderate',
                  },
                  {
                    color: theme.colors.forms.severe,
                    range: '20 - 35',
                    label: 'Severe',
                  },
                ]}
              />
            )}
          </StyledPageContainer>
        )
      }}
    </Formik>
  )
}
