import { GridColDef } from '@mui/x-data-grid'
import Icon from 'Components/Base/Icon'
import React from 'react'
import { DateSetting } from 'types/Dashboards'

export const filterOptions: DateSetting[] = [
  {
    label: 'All Readings',
  },
  {
    label: 'Last 90 Days',
    value: 90,
  },
  {
    label: 'Last 60 Days',
    value: 60,
  },
  {
    label: 'Last 30 Days',
    value: 30,
  },
  {
    label: 'Last 7 Days',
    value: 7,
  },
  {
    label: 'Custom',
    value: 0,
  },
]

export const cgmFilterOptions: DateSetting[] = [
  {
    label: 'Last 7 Days',
    value: 7,
  },
  {
    label: 'Last 14 Days',
    value: 30,
  },
  {
    label: 'Custom (14 day max)',
    value: 0,
  },
]

export const glucoseColumns: GridColDef[] = [
  {
    field: 'id',
    editable: false,
    hide: true,
  },
  {
    field: 'readingId',
    editable: false,
    hide: true,
  },
  {
    flex: 1,
    field: 'readingDate',
    headerName: 'Date',
    type: 'date',
  },
  {
    flex: 1,
    field: 'readingTime',
    headerName: 'Time',
    type: 'date',
  },
  {
    flex: 1,
    field: 'bloodGlucose',
    headerName: 'Blood Glucose(mg/dl)',
    type: 'number',
  },
  {
    flex: 1,
    field: 'readingType',
    headerName: 'Reading Type',
    type: 'string',
  },
  {
    flex: 1,
    field: 'mealType',
    headerName: 'Meal Type',
  },
  {
    flex: 1,
    field: 'carbCount',
    headerName: 'Carb Count',
    type: 'number',
  },
  {
    flex: 1,
    field: 'insulinDosage',
    headerName: 'Insulin Dosage',
    type: 'number',
  },
  {
    flex: 1,
    field: 'notes',
    headerName: 'Notes',
    sortable: false,
  },
  {
    flex: 1,
    field: 'edit',
    headerName: 'Edit',
    cellClassName: 'edit-cell',
    sortable: false,
    renderCell: () => <Icon name="edit" />,
  },
]

export const scaleColumns: GridColDef[] = [
  {
    field: 'id',
    editable: false,
    hide: true,
  },
  {
    field: 'readingId',
    editable: false,
    hide: true,
  },
  {
    flex: 1,
    field: 'readingDate',
    headerName: 'Date',
    type: 'date',
  },
  {
    flex: 1,
    field: 'readingTime',
    headerName: 'Time',
    type: 'date',
  },
  {
    flex: 1,
    field: 'weight',
    headerName: 'Weight',
    type: 'number',
  },
  {
    flex: 1,
    field: 'change',
    headerName: 'Weight Change',
    type: 'number',
  },
]

export const glucoseMobileColumns: GridColDef[] = [
  {
    field: 'id',
    editable: false,
    hide: true,
  },
  {
    flex: 1,
    field: 'bloodGlucose',
    headerName: 'mg/dl',
    type: 'number',
  },
  {
    flex: 1,
    field: 'readingDate',
    headerName: 'Date',
    type: 'date',
  },
]
