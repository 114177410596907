import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { IconNames } from 'types/IconNames'

interface Props {
  title: string
  subtitle: string
  title2?: string
  styles?: React.CSSProperties
  linkAction?: () => void
  icon?: IconNames
}
export default function GridCard({ title, subtitle, title2, styles, linkAction, icon }: Props) {
  const theme = useTheme()

  return (
    <StyledCardContainer style={{ ...styles }}>
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <StyledCardColumn>
          <Row styles={{ marginLeft: 'auto', marginRight: 'auto' }}>
            {icon && <Icon name={icon} size="1x" styles={{ margin: 'auto 5px 10px 0px' }} />}
            <text style={{ fontSize: 30, fontFamily: 'Raleway-Bold' }}>{title}</text>
          </Row>
          {title2 && <text style={{ fontSize: 15, fontFamily: 'Raleway-Bold' }}>{title2}</text>}
          <div style={{ fontSize: 15, fontFamily: 'Raleway' }}>{subtitle}</div>
          {linkAction && (
            <div onClick={linkAction} style={{ cursor: 'pointer' }}>
              <Typography color={theme.colors.primary1} font="bold">
                View Readings
              </Typography>
            </div>
          )}
        </StyledCardColumn>
      </div>
    </StyledCardContainer>
  )
}

const StyledCardContainer = styled.div`
  height: 100px;
  min-width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  margin-bottom: 25px;
  position: relative;
  background-color: white;
`
const StyledCardColumn = styled.div`
  top: 50%;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: ;
`
