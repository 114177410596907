import BackRow from 'Components/Base/BackRow'
import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { ErrorMessage, Formik, getIn } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import useConsent, { ConsentFormValues } from 'hooks/useConsent'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import { getProfile } from 'reducers/profileSlice'
import { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import trimPeriod from 'utilities/trimPeriod'
import * as Yup from 'yup'
import './ConsentForm.scss'

interface Props {
  readonly?: boolean
}
export default function ConsentForm({ readonly = false }: Props) {
  const theme = useTheme()
  const { postConsentForm } = useConsent()

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [success, setSuccess] = useState<boolean>(false)

  const profile = useAppSelector(getProfile)

  function getStyles(fieldName: string, errors: any) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  const initialValues: ConsentFormValues = {
    welltrak: undefined,
    privacyPolicy: false,
    communicationPolicy: false,
    textOptIn: true,
    signature: '',
  }

  const validationSchema = Yup.object().shape({
    privacyPolicy: Yup.boolean().isTrue('Please review privacy policy'),
    communicationPolicy: Yup.boolean().isTrue('Please review communication policy'),
    signature: Yup.string().required('Please provide signature').min(1),
  })

  const submitConsentForm = (values: ConsentFormValues) => {
    const id = trimPeriod(searchParams.get('id'))
    const ID = trimPeriod(searchParams.get('ID'))

    if (id || ID || profile) {
      if (id) values.guid = id
      if (ID) values.guid = ID
      if (profile) values.welltrak = profile.welltrakPID
      setLoading(true)
      postConsentForm(values)
        .then(() => {
          setSuccess(true)
        })
        .catch(() => {
          setError('There was an error. Please try again')
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setError('Unable to locate patient information')
    }
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => submitConsentForm(values)}>
        {({ values, setFieldValue, errors, submitForm }) => {
          return (
            <div className="consent-container">
              <BackRow />
              <div style={{ marginBottom: 35 }}>
                <div className="survey-banner">PRIVACY POLICY</div>
                <Typography>
                  {`I acknowledge receipt of the Tria Health Notice of Privacy Practices, and I understand that a copy may be
                  emailed to me and is also available upon request by calling Tria Health at ${PhoneNumberPeriodDelimited}. I
                  authorize Tria Health and its pharmacist(s) and designated employees to release to my health care providers
                  (as identified above or otherwise learned by Tria Health from time to time) any medication or other
                  information about me which is related to my medical treatment and/or continuity of care, unless I state
                  otherwise in writing. I further understand that all information contained on this enrollment form (e.g.,
                  phone numbers, email addresses, medical information) and otherwise revealed through my encounters with Tria
                  Health will remain strictly confidential, and will only be used or disclosed for purposes of payment,
                  treatment or health care operations, including without limitation for the purpose of communicating with my
                  health care providers and me as a part of the services offered through the Tria Health program.`}
                </Typography>
                {!readonly && (
                  <Row center styles={{ marginTop: 20 }}>
                    <Checkbox
                      checked={values.privacyPolicy}
                      onClick={() => setFieldValue('privacyPolicy', !values.privacyPolicy)}
                      styles={{ marginRight: 10 }}
                    />
                    <Typography font="bold">Have you reviewed our privacy policy?</Typography>
                  </Row>
                )}
                <Row center className="consent-error">
                  <ErrorMessage name="privacyPolicy" />
                </Row>
              </div>

              <div style={{ marginBottom: 35 }}>
                <div className="survey-banner">COMMUNICATION POLICY</div>
                <Typography>
                  {`I acknowledge receipt of the Patient Consent to Receive Electronic Communications from Tria Health
                  (“Consent”), and I understand that a copy may be emailed to me and is also available upon request by
                  calling Tria Health at ${PhoneNumberPeriodDelimited}. I have read the Consent form and fully understand the
                  potential risks associated with Tria Health’s electronic communication program and any electronic
                  communications that I may receive thereunder, including those that contain protected health information
                  (PHI) and other individually identifiable information about me. By signing below, I indicate I am the
                  person legally responsible for all use of the account(s) listed below, and that I am at least 18 years of
                  age. Without being obligated to do so, I voluntarily give Tria Health, and those authorized by Tria Health,
                  my consent to participate in the electronic communication program and to receive electronic communications
                  thereunder, pursuant to the terms and conditions set forth in the Consent.`}
                </Typography>
                {!readonly && (
                  <Row center styles={{ marginTop: 20 }}>
                    <Checkbox
                      checked={values.communicationPolicy}
                      onClick={() => setFieldValue('communicationPolicy', !values.communicationPolicy)}
                      styles={{ marginRight: 10 }}
                    />
                    <Typography font="bold">Have you reviewed our communication policy?</Typography>
                  </Row>
                )}
                <Row center className="consent-error">
                  <ErrorMessage name="communicationPolicy" />
                </Row>
              </div>
              {!readonly && (
                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox checked={values.textOptIn} styles={{ marginRight: 10 }} onClick={() => setFieldValue('textOptIn', !values.textOptIn)} />
                  <Typography font="semi-bold">
                    <>
                      <span style={{ fontFamily: 'Raleway-Bold', marginRight: 5 }}>Tria Health Text Alerts</span>
                      {`Opt-in for appointment reminders, medication savings and more!`}
                    </>
                  </Typography>
                </div>
              )}
              {!readonly && (
                <>
                  <Row center styles={{ marginTop: 25 }}>
                    <Form.Control
                      type="text"
                      onChange={(value) => setFieldValue('signature', value.currentTarget.value)}
                      className="signature-input"
                      style={{
                        ...getStyles('signature', errors),
                      }}
                      value={values.signature}
                      placeholder="First and Last name"
                    />
                  </Row>
                  <Row center className="consent-error">
                    <ErrorMessage name="signature" />
                  </Row>
                  {error && (
                    <Row center className="consent-error">
                      {error}
                    </Row>
                  )}
                  {success === true && (
                    <Row center>
                      <Typography font="bold" color={theme.colors.primary1} size={15}>
                        Form Submitted!
                      </Typography>
                    </Row>
                  )}
                  <Row center styles={{ marginTop: 20 }}>
                    <Button
                      pageName="consentForm"
                      text={success ? 'Completed' : 'Submit'}
                      disabled={success}
                      loading={loading}
                      styles={{ borderRadius: 4 }}
                      onClick={submitForm}
                      className="consent-button"
                    />
                  </Row>
                </>
              )}
            </div>
          )
        }}
      </Formik>
    </>
  )
}
