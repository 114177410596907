import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import styled, { useTheme } from 'styled-components'
import ListItem from '../ListItem'
import ProgressIcon from '../ProgressIcon'
import STOPHeader from '../STOPHeader'
import ScaledText from '../ScaledText'

type Props = {
  title: string
  subtitle: string
  subtitle2?: string
  images: string[]
  mobileImage: string
  pros: string[]
  cons: string[]
}
export default function ProConScreen({ title, subtitle, subtitle2, images, mobileImage, pros, cons }: Props) {
  const theme = useTheme()
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const [expandedSection, setExpandedSection] = useState<number | undefined>()

  const toggleSection = (sectionId: number) => {
    analytics.postAnalyticsEvent({
      category: `click_button_stop_accordion_${sectionId + 1}`,
      action: `click_button_stop_accordion_${sectionId + 1}`,
      label: `Button`,
      screenName: 'StopProgram',
    })
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }

  return (
    <>
      <StyledMobileImageRow>
        <StyledMobileImage src={mobileImage} alt="alt" />
      </StyledMobileImageRow>
      <STOPHeader>{title}</STOPHeader>
      <ScaledText>{subtitle}</ScaledText>
      {subtitle2 && (
        <Spacer top>
          <ScaledText>{subtitle2}</ScaledText>
        </Spacer>
      )}
      <StyledImagesRow>
        {images.map((image) => (
          <StyledImage src={image} alt={'altTag'} />
        ))}
      </StyledImagesRow>
      <StyledProConRow>
        <Column styles={{ width: '45%' }}>
          <Row styles={{ alignItems: 'center' }}>
            <ProgressIcon
              icon="check"
              color={theme.colors.charts.inRange}
              iconColor={theme.colors.white}
              iconSize="lg"
              size={35}
              styles={{ marginRight: 10 }}
            />
            <ScaledText bold>Pros</ScaledText>
          </Row>
          <ul>
            {pros.map((pro) => (
              <ListItem>
                <ScaledText>{pro}</ScaledText>
              </ListItem>
            ))}
          </ul>
        </Column>
        <Column styles={{ width: '45%' }}>
          <Row>
            <ProgressIcon
              icon="times"
              color={theme.colors.charts.low}
              iconColor={theme.colors.white}
              iconSize="lg"
              size={35}
              styles={{ marginRight: 10 }}
            />
            <ScaledText bold>Cons</ScaledText>
          </Row>
          <ul>
            {cons.map((con) => (
              <ListItem>
                <ScaledText>{con}</ScaledText>
              </ListItem>
            ))}
          </ul>
        </Column>
      </StyledProConRow>
      <StyledAccordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toggleSection(0)} style={{ alignItems: 'center' }}>
            <Row styles={{ alignItems: 'center' }}>
              <ProgressIcon
                icon="check"
                iconColor={theme.colors.white}
                color={theme.colors.charts.inRange}
                iconSize="lg"
                size={35}
                styles={{ marginRight: 10 }}
              />
              Pros
            </Row>
            <Icon name={expandedSection === 0 ? 'chevron-up' : 'chevron-down'} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {pros.map((pro) => (
                <li>
                  <ScaledText styles={{ display: 'inline' }}>{pro}</ScaledText>
                </li>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toggleSection(1)} style={{ alignItems: 'center' }}>
            <Row styles={{ alignItems: 'center' }}>
              <ProgressIcon
                icon="times"
                iconColor={theme.colors.white}
                color={theme.colors.charts.low}
                iconSize="lg"
                size={35}
                styles={{ marginRight: 10 }}
              />
              Cons
            </Row>
            <Icon name={expandedSection === 1 ? 'chevron-up' : 'chevron-down'} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {cons.map((con) => (
                <li>
                  <ScaledText styles={{ display: 'inline' }}>{con}</ScaledText>
                </li>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </StyledAccordion>
    </>
  )
}

const StyledAccordion = styled(Accordion)`
  margin-top: 15px;
  border-radius: 10px;
  z-index: 50px;
  position: relative;
  width: 100%;
  @media (${sizes.tablet}) {
    display: none;
  }
`

const StyledImagesRow = styled.div`
  justify-content: space-between;
  margin: 25px 0px;
  display: none;
  @media (${sizes.tablet}) {
    display: flex;
    flex-direction: row;
  }
`
const StyledMobileImageRow = styled.div`
  display: flex;
  @media (${sizes.tablet}) {
    display: none;
  }
`

const StyledMobileImage = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  margin-bottom: 10px;
  border: 1px solid black;
`

const StyledImage = styled.img`
  object-fit: cover;
  width: 30%;
  border: 1px solid black;
`
const StyledProConRow = styled(ResponsiveContainer)`
  justify-content: space-between;
  display: none;
  @media (${sizes.tablet}) {
    display: flex;
  }
`
