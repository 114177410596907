import CTLChallenges from 'Pages/CTLChallenges'
import DashboardDigitalScalePage from 'Pages/DashboardPage/DashboardDigitalScalePage'
import Reimbursements from 'Pages/Reimbursements'
import React from 'react'
import CarePlanScreen from '../Pages/CarePlanScreen'
import DashboardBloodPressurePage from '../Pages/DashboardPage/DashboardBloodPressurePage'
import DashboardGlucosePage from '../Pages/DashboardPage/DashboardGlucosePage'
import MessagingCenter from '../Pages/MessagingCenter'
import PatientIntake from '../Pages/PatientIntake'
import PatientProfile from '../Pages/PatientProfile'

const protectedRoutes = [
  { path: '/profile/', component: <PatientProfile /> },
  { path: '/profile/:route', component: <PatientProfile /> },
  { path: '/dashboard/bloodglucose', component: <DashboardGlucosePage /> },
  {
    path: '/dashboard/bloodpressure',
    component: <DashboardBloodPressurePage />,
  },
  { path: '/dashboard/digitalscale', component: <DashboardDigitalScalePage /> },
  { path: '/careplan', component: <CarePlanScreen /> },
  { path: '/careplan/:route', component: <CarePlanScreen /> },
  { path: '/reimbursements', component: <Reimbursements /> },
  { path: '/messaging', component: <MessagingCenter /> },
  { path: '/messaging/:route', component: <MessagingCenter /> },
  { path: '/patientintake', component: <PatientIntake /> },
  { path: '/patientintake/:formId', component: <PatientIntake /> },
  { path: '/challenges', component: <CTLChallenges /> },
  { path: '/challenges/:route', component: <CTLChallenges /> },
  { path: '/challenge/:route/:route', component: <CTLChallenges /> },
  { path: '/challenge/section/:route/:route', component: <CTLChallenges /> },
  { path: '/reflection/:route', component: <CTLChallenges /> },
]

export default protectedRoutes
