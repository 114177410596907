import { IconNames } from './IconNames'
import { Recommendation } from './Recommendation'

export interface Careplan {
  allRecommendations: Recommendation[]
  recommendationsVaccine: Recommendation[]
  recommendationsExams: Recommendation[]
  recommendationsMedications: Recommendation[]
  recommendationsLifestyle: Recommendation[]
  recommendationsOther: Recommendation[]
  pharmacistResources: WebDocument[]
  documentsArchive: WebDocument[]
}

export interface WebDocument {
  patientWebDocumentPId: number
  patientEducationResourcePid: number
  documentDate: string
  viewed: boolean
  documentType: string
  documentName: string
  documentLink: string
  title: string
  titleSpanish: string
  condition: string
  filePath: string
  filePathSpanish: string
  webAddress: string
  icon: IconNames
}

export enum SectionType {
  Recommendation,
  Link,
}
