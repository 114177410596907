import { Dayjs } from 'dayjs'

export type Device = {
  label: string
  value: number
  cgm: boolean
}
export enum DashboardType {
  Glucose,
  BloodPressure,
}
interface Reading {
  index: number
  patientReadingPId: number
  readingId: string
  wellTrakPId: number
  readingDate: Date
  notes: string
  patientReadingLevel: ReadingLevel
}

export interface PatientGlucoseReading extends Reading {
  deviceId: string
  value: number
  beforeMeal: boolean
  mealType: string
  meal: string
  readingType: string
  vendorPId: number
  rawResponse: string
  eventFlag: number
  eventType: string
  insulinDosage?: number
  carbCount?: number
}

export interface PatientBloodPressureReading extends Reading {
  systolicValue: number
  diastolicValue: number
  heartRateValue: number
}

export enum ReadingLevel {
  CriticalLow,
  Low,
  InRange,
  AboveRange,
  High,
}

export interface BloodGlucoseReadingSummary {
  readings: PatientGlucoseReading[]
  bloodGlucoseAverageReadings: PatientGlucoseReading[]
  averageTestsPerDay: number
  daysTested: number
  averageReading: number
  highReadings: PatientGlucoseReading[]
  aboveRangeReadings: PatientGlucoseReading[]
  inRangeReadings: PatientGlucoseReading[]
  lowReadings: PatientGlucoseReading[]
  criticalLowReadings: PatientGlucoseReading[]
  estimatedA1C: number
  glucoseVariability: string
}

export interface BloodGlucoseReadingCGMSummary {
  hasDevice: boolean
  readings: PatientGlucoseReading[]
  highReadings: PatientGlucoseReading[]
  aboveRangeReadings: PatientGlucoseReading[]
  inRangeReadings: PatientGlucoseReading[]
  lowReadings: PatientGlucoseReading[]
  criticalLowReadings: PatientGlucoseReading[]
  averageReading: number
  numReadings: number
  numHighReadings: number
  percentHighReadings: number
  numAboveRangeReadings: number
  percentAboveRangeReadings: number

  numInRangeReadings: number

  percentInRangeReadings: number

  numLowReadings: number
  percentLowReadings: number

  numCriticalLowReadings: number

  percentCriticalLowReadings: number
  glucoseManagementIndicator: number
  glucoseVariability: number
}

export interface BloodPressureReadingSummary {
  readings: PatientBloodPressureReading[]
  bloodPressureAverageReadings: PatientBloodPressureReading[]
  aboveRangeReadings: PatientBloodPressureReading[]
  inRangeReadings: PatientBloodPressureReading[]
  averageHeartRate: number
  averageSystolicReading: number
  averageDiastolicReading: number
  highReadings: PatientBloodPressureReading[]
  lowReadings: PatientBloodPressureReading[]
}

export type CGMMetadata = {
  averageReading: number
  percentHighReadings: number
  percentAboveRangeReadings: number
  percentInRangeReadings: number
  percentLowReadings: number
  percentCriticalLowReadings: number
  glucoseManagementIndicator: number
  glucoseVariability: number
}

export type DateSetting = {
  label: string
  value?: number
}

export type EditModalSettings = {
  isOpen: boolean
  reading?: PatientGlucoseReading
  scaleReading?: ScaleReading
}

export type ReadingCounts = {
  high: number
  aboveRange: number
  inRange: number
  low: number
  criticalLow: number
  total: number
}
export type GlucoseReadingMetadata = {
  averageReading: number | undefined
  estimatedA1C: number | undefined
  firstReadingDate: Dayjs | undefined
  lastReadingDate: Dayjs | undefined
  averageTestsPerDay: number
}

export type BloodPressureReadingMetadata = {
  averageReading?: string
  averageBPM?: number
  highReadingCount: number
  aboveRangeReadingCount: number
  inRangeReadingCount: number
  lowReadingCount: number
  totalReadingCount?: number
  firstReadingDate?: Dayjs
  lastReadingDate?: Dayjs
}

export type ItemRow = {
  id: number
  readingDate: string
  readingTime: string
  bloodGlucose: string
  readingType: string
  mealType: string
  carbCount: string
  insulinDosage: string
  notes: string
}

export type RangeSetting = {
  high: number | undefined
  low: number | undefined
  label: RangeDescription
}

export type RangeDescription = 'All' | 'Above' | 'Below' | 'High' | 'Above-Range' | 'In-Range' | 'Low' | 'Dangerously Low'

export type HeartRateReading = {
  x: string
  y: number
}

export type BloodPressureChartReading = {
  x: string
  y: number
  y0: number
  label: string
}

export type PieChartData = {
  x: string
  y: number
  percentage: number
  type: string
  color?: string
}

export type ScaleRow = {
  id: number
  readingDate: string
  readingTime: string
  weight: string
  change: string
}

export interface ScaleReadingSummary {
  hasDevice?: boolean
  allReadings: ScaleReading[]
  bmi: number
  goalWeight: string
}

export type ScaleReading = {
  index: number
  patientReadingPId: number
  value: number
  readingDate: string
  bmi: number
  change: number
}
