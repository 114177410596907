import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledPageContainer } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Challenge, ChallengeSection } from 'types/Challenge'
import ChallengeCompleteImage from '../../../Assets/Images/ChallengeCompleteColor.png'

interface Props {
  setSectionId?: (section: number) => void
  isMobile: boolean
}
export default function SectionDashboard({ setSectionId, isMobile }: Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const token = useAppSelector(getAccessToken) ?? ''
  const { getChallenges } = useChallenges(token)

  const [challenge, setChallenge] = useState<Challenge>()
  const [sections, setSections] = useState<ChallengeSection[]>()
  const [lightColor, setLightColor] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    getChallenges()
      .then((res) => {
        setChallenge(
          res.data.challenges.find(
            (challenge) => challenge.challengePId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
          ),
        )
        setSections(
          res.data.challenges.find(
            (challenge) => challenge.challengePId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
          ).challengeSections,
        )
        hexIsLight(
          res.data.challenges.find(
            (challenge) => challenge.challengePId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
          ).challengeHexColor,
        )
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  const cardClick = (link, challengeSectionPId) => {
    navigate(link)
    window.scrollTo(0, 0)
    setSectionId && setSectionId(challengeSectionPId)
  }

  function hexIsLight(color) {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substring(0, 0 + 2), 16)
    const c_g = parseInt(hex.substring(2, 2 + 2), 16)
    const c_b = parseInt(hex.substring(4, 4 + 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    return setLightColor(brightness > 155)
  }

  return (
    <div>
      <StyledPageContainer
        style={{
          justifyContent: 'left',
          display: 'flex',
        }}
      >
        <Column styles={{ width: 1600 }}>
          {isMobile && (
            <Row styles={{ margin: '-10px auto 10px 0px' }} onClick={() => navigate('/challenges')}>
              <Icon name="back" styles={{ marginRight: 10 }} size="xl" />
              <Typography font="bold" styles={{ marginRight: 5 }}>
                Exit
              </Typography>
            </Row>
          )}
          <StyledTitle>{challenge?.name}</StyledTitle>
          {challenge && challenge?.badgeEarned && (
            <Row styles={{ marginBottom: 20 }}>
              <img src={challenge.badgeIconUrl} alt="section" style={{ height: 100 }} />
              <Column styles={{ margin: 'auto 15px' }}>
                <p style={{ color: 'black', fontFamily: 'Raleway-Bold', marginBottom: -1, marginTop: 15 }}>{challenge.badgeName}</p>
                <p style={{ color: 'black', fontFamily: 'Raleway' }}>{`You have completed all ${challenge.name.toLocaleLowerCase()} challenges.`}</p>
              </Column>
            </Row>
          )}
          <StyledGrid>
            {sections &&
              sections.map((section) => (
                <StyledCard
                  key={section.challengeSectionPId}
                  style={
                    section.completed ? { backgroundImage: `url(${ChallengeCompleteImage})` } : { backgroundColor: challenge!.challengeHexColor }
                  }
                  onClick={() => cardClick(`/challenge/intro/${section.challengeSectionPId}`, section.challengeSectionPId)}
                >
                  {section.completed && (
                    <Icon name="badge" styles={{ marginRight: 'auto', marginLeft: -10, marginBottom: -20 }} size="2x" color="black" />
                  )}
                  <img src={section.imageUrl} alt="section" style={{ height: 120 }} />
                  <h4 style={{ color: section.completed || lightColor ? '#000' : '#FFF', fontFamily: 'Raleway-Bold', marginTop: 10 }}>
                    {section.name}
                  </h4>
                  <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: 20, padding: '5px 20px', marginTop: 5 }}>
                    <p style={{ color: 'black', fontFamily: 'Raleway', margin: 'auto' }}>{`${
                      section.completed
                        ? 'Complete'
                        : section.estimatedTime === 1
                        ? `${section?.estimatedTime} minute`
                        : `${section?.estimatedTime} minutes`
                    }`}</p>
                  </div>
                </StyledCard>
              ))}
          </StyledGrid>
          {sections && (
            <ResponsiveContainer styles={{ marginTop: isMobile ? '10px' : '15%', marginLeft: '10px' }}>
              {!isMobile && (
                <StyledButton style={{ backgroundColor: '#425563' }} onClick={() => navigate('/challenges')}>
                  Back
                </StyledButton>
              )}
              <StyledButton style={{ backgroundColor: '#7A9A01' }} onClick={() => navigate('/profile/challenges')}>
                View My Badges
              </StyledButton>
            </ResponsiveContainer>
          )}
        </Column>
      </StyledPageContainer>
    </div>
  )
}

const StyledTitle = styled.span`
  font-family: 'Raleway-Bold';
  text-align: left;
  margin-bottom: 15px;
  margin-left: 5px;
  @media ${sizes.mobileL} {
    font-size: 8vw;
  }
  @media ${sizes.tablet} {
    font-size: 4vw;
  }
  @media ${sizes.desktop} {
    font-size: 3vw;
  }
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
    text-align: center;
    font-size: 8vw;
  }
`

const StyledCard = styled.div`
  height: 270px;
  width: 270px;
  margin: 11px;
  border: 1px solid #828282;
  padding: 0px 40px;
  align-items: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    width: 250px;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 0.1rem;
  grid-template-columns: repeat(auto-fit, minmax(290px, 0.3fr));
  justify-items: left;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-items: center;
  }
`

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-right: 20px;
    margin-bottom: 0px;
  }
`
