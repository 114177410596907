import React from 'react'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  styles?: React.CSSProperties
  center?: boolean
  className?: string
  onClick?: () => void
}

export default function Link({ children, styles, center = false, className, onClick }: Props) {
  if (center) styles = { justifyContent: 'center' }

  return (
    <div className={className} onClick={onClick} style={{ ...styles }}>
      {children}
    </div>
  )
}
