import React from 'react'
import RegisterConfirmationPage from '../Pages/RegisterConfirmationPage'
import RegisterPage from '../Pages/RegisterPage'

const accountRoutes = [
  { path: '/register', component: <RegisterPage fromAvailability={false} /> },
  { path: '/register/success', component: <RegisterConfirmationPage /> },
]

export default accountRoutes
