//import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { AuthProvider, AuthService } from 'react-oauth2-pkce'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { ThemeProvider } from 'styled-components'
import App from './App'
import './index.scss'

import { theme } from './Styles/styledTheme'
import reportWebVitals from './reportWebVitals'
import { persistor, store } from './store'

/* PKCE Auth Config */
const provider = process.env.REACT_APP_PROVIDER || 'https://auth.triahealth.com/connect'
const authLogoutUrl = `${process.env.REACT_APP_AUTH_LOGOUT_URL}` || 'https://auth.triahealth.com/connect/logout'

const authService = new AuthService({
  clientId: process.env.REACT_APP_CLIENT_ID || 'tria-portal',
  location: window.location,
  audience: 'portal-api',
  logoutEndpoint: authLogoutUrl,
  provider: provider,
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
  scopes: ['offline_access', 'portal_api', 'twofactor', 'roles', 'openid', 'welltrak', 'email'],
  autoRefresh: true,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SimpleReactLightbox>
          <AuthProvider authService={authService}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </AuthProvider>
        </SimpleReactLightbox>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
