import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { PrimaryPhysicianState } from 'hooks/usePatientIntake'
import React from 'react'

interface Props {
  phys
  index: number
  primaryPhysician: PrimaryPhysicianState | undefined
  setPrimaryPhysician
}
export default function PhysicianSelectCard({ phys, index, primaryPhysician, setPrimaryPhysician }: Props) {
  function physicianSelected() {
    if (primaryPhysician === phys) {
      setPrimaryPhysician()
    } else {
      setPrimaryPhysician(phys)
    }
  }
  const evenOrOdd = index % 2
  return (
    <Row styles={evenOrOdd === 0 ? { backgroundColor: 'lightGrey', padding: '2%' } : { padding: '2%' }}>
      <Checkbox onClick={physicianSelected} checked={primaryPhysician === phys ? true : false}></Checkbox>
      <Column styles={{ marginLeft: '2%' }}>
        <Typography>{`Dr. ${phys.firstName} ${phys.lastName}`}</Typography>
      </Column>
      <Column styles={{ margin: 'auto' }}>
        <Typography>{`${phys.city}, ${phys.state}`}</Typography>
      </Column>
      <Column>
        <Typography>{`(${phys.phone.slice(0, 3)})-${phys.phone.slice(3, 6)}-${phys.phone.slice(6, 10)}`}</Typography>
      </Column>
    </Row>
  )
}
