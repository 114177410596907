import dayjs from 'dayjs'
import React from 'react'
import { DashboardState } from 'reducers/dashboardSlice'
import { ChartReading } from 'types/ChartReading'
import { DateSetting, ScaleReading } from 'types/Dashboards'
import GridCard from '../../../DashboardGlucosePage/components/GridCard'

interface Props {
  scaleReadings: ScaleReading[]
  filteredReadings: ChartReading[] | undefined
  scaleReadingsSummary: DashboardState
  readingFilter: DateSetting
}
export default function GridCards({ scaleReadings, filteredReadings, scaleReadingsSummary, readingFilter }: Props) {
  return (
    <>
      <div
        className="grid-template"
        style={{
          display: 'grid',
          marginBottom: 25,
        }}
      >
        <GridCard title={`${scaleReadings[0].value} lbs`} subtitle="Starting Weight" />
        <GridCard title={`${scaleReadings[scaleReadings.length - 1].value} lbs`} subtitle="Current Weight" />
        {filteredReadings && (
          <GridCard
            icon={filteredReadings[filteredReadings.length - 1].value - filteredReadings[0].value >= 0 ? 'arrow-up' : 'arrow-down'}
            title={`${Math.abs(filteredReadings[0].value - filteredReadings[filteredReadings.length - 1].value)} lbs`}
            subtitle={`Weight Change - ${readingFilter.label}`}
          />
        )}
        <GridCard
          title={`${scaleReadingsSummary.goalWeight === '0' || scaleReadingsSummary.goalWeight === '' ? 'N/A' : scaleReadingsSummary.goalWeight}`}
          subtitle="Goal Weight"
        />
        <GridCard title={`${Math.round(scaleReadingsSummary.bmi * 10) / 10}`} subtitle="Current BMI" />
        {filteredReadings && <GridCard title={`${filteredReadings.length}`} subtitle={`Total Readings - ${readingFilter.label}`} />}
        <GridCard
          title={`${dayjs(scaleReadings[0].readingDate).format('MM/DD/YYYY')}`}
          title2={`${dayjs(scaleReadings[0].readingDate).format('hh:mm:ss A')}`}
          subtitle="First Reading"
        />
        <GridCard
          title={`${dayjs(scaleReadings[scaleReadings.length - 1].readingDate).format('MM/DD/YYYY')}`}
          title2={`${dayjs(scaleReadings[scaleReadings.length - 1].readingDate).format('hh:mm:ss A')}`}
          subtitle="Last Reading"
        />
      </div>
    </>
  )
}
