
import React, { useEffect, useState } from 'react'
import { Paragraph, SectionInfo } from 'types/Challenge'
import Typography from 'Components/Base/Typography'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function MicroStep1({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {

    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2])))
    setStepParagraphs(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]))?.paragraphs)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])


  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant='h1'>{sectionInfo?.titles.find((title) => title.titleId === 1)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}><i>{sectionInfo?.titles.find((title) => title.titleId === 2)?.titleText ?? ''}</i> {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 1)?.paragraph ?? ''}</p>
        </Row>
        <p style={{ fontFamily: 'Raleway', fontSize: 20 }}><i>{sectionInfo?.titles.find((title) => title.titleId === 3)?.titleText ?? ''}</i> {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 2)?.paragraph ?? ''}</p>
      </Column>
      <Column styles={{ width: '100%' }}>
        <img src={sectionInfo?.images.find((image) => image.imageId === 1)?.url} alt='scale' style={{ width: isMobile ? '100%' : '60%', marginLeft: 'auto' }} />
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <p style={{ marginLeft: 'auto', fontFamily: 'Raleway', width: isMobile ? '100%' : '60%', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 3)?.paragraph ?? ''}</p>
        </Row>
      </Column>
    </ResponsiveContainer>
  )
}

