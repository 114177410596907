import useTypeStyles, { ColorStyles, FontStyles } from 'hooks/useTypeStyles'
import React from 'react'
import styled from 'styled-components'

export type TypographyKeys = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' /*
  | 'large'
  | 'regular'
  | 'small'
  | 'caption' */

interface Props {
  children: React.ReactChild | React.ReactChild[]
  styles?: React.CSSProperties
  className?: string
  center?: boolean
  color?: string // ColorKeys
  font?: FontKeys
  size?: number
  variant?: TypographyKeys
}

export type ColorKeys = keyof ColorStyles
export type FontKeys = keyof FontStyles

export default function Typography(props: Props) {
  const { children, size, styles, color, variant } = props
  const { colors, fonts, headings } = useTypeStyles()

  const font = fonts[props.font || 'regular']
  //const fontColor = color && colors[color]
  const variantStyles = variant && headings[variant]
  const fontSize = size ? size : variantStyles?.fontSize

  return (
    <StyledTypography
      style={{
        ...styles,
        ...variantStyles,
        ...font,
        //...fontColor,
        color: color,
        fontSize: fontSize,
      }}
      className={props.className}
    >
      {children}
    </StyledTypography>
  )
}

const StyledTypography = styled.div<Props>`
  color: ${(props) => props.color};
`
