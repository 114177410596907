import { Theme, createStyles, makeStyles } from '@material-ui/core'
import { useGridSlotComponentProps } from '@mui/x-data-grid'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import TimePicker from 'react-time-picker'
import { getAccessToken } from 'reducers/authSlice'
import { RangeDescription, RangeSetting } from 'types/Dashboards'

interface Props {
  readingType: string
  rangeDescription: RangeDescription
  isMobile: boolean
  setReadingType: (type: string) => void
  setRange: (range: RangeSetting) => void
  rangeNumber?: number
  range
  exportToPdf
  setStartTime: (type: string) => void
  setEndTime: (type: string) => void
  startTime: string | undefined
  endTime: string | undefined
}
export function CustomToolbar({
  readingType,
  rangeDescription,
  setReadingType,
  setRange,
  rangeNumber,
  isMobile,
  range,
  exportToPdf,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
}: Props) {
  const { apiRef } = useGridSlotComponentProps()
  const [tempSetting, setTempSetting] = useState<RangeSetting | undefined>()
  const [tempValue, setTempValue] = useState<number>()

  const styles = useStyles()

  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const filterReadings = (label: RangeDescription, number: number) => {
    switch (label) {
      case 'Above':
        setTempSetting({ high: undefined, low: number, label: 'Above' })
        break
      case 'Below':
        setTempSetting({ high: number, low: undefined, label: 'Below' })
        break
      default:
        break
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      tempSetting && setRange(tempSetting)
      if (rangeDescription === 'Above') {
        setTempValue(range.low)
      } else if (rangeDescription === 'Below') {
        setTempValue(range.high)
      }
    }, 2000)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [tempValue])

  useEffect(() => {
    const delayDebounceFn1 = setTimeout(() => {
      if (rangeDescription === 'Above') {
        setTempValue(range.low)
      } else if (rangeDescription === 'Below') {
        setTempValue(range.high)
      }
    }, 10)

    return () => {
      clearTimeout(delayDebounceFn1)
    }
  }, [setRange])

  const handleExportClick = () => {
    analytics.postAnalyticsEvent({
      category: `click_button_dashboardGlucose_export`,
      action: `click_button_dashboardGlucose_export`,
      label: `Button`,
      screenName: 'GlucoseDashboard',
    })
  }

  return (
    <ResponsiveContainer className={styles.container}>
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.toolbarButton}>
          {readingType}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setReadingType('All Reading Types')}>All Reading Types</Dropdown.Item>
          <Dropdown.Item onClick={() => setReadingType('Before Meal')}>Before Meal</Dropdown.Item>
          <Dropdown.Item onClick={() => setReadingType('After Meal')}>After Meal</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ResponsiveContainer>
        <ResponsiveContainer styles={isMobile ? { marginTop: 10 } : { marginLeft: 30 }}>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.toolbarButton}>
              {rangeDescription}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setRange({ high: undefined, low: undefined, label: 'All' })}>All</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: undefined, low: undefined, label: 'Above' })}>Above (Custom)</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: undefined, low: undefined, label: 'Below' })}>Below (Custom)</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: undefined, low: 250, label: 'High' })}>High</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: 250, low: 180, label: 'Above-Range' })}>Above-Range</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: 180, low: 70, label: 'In-Range' })}>In-Range</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: 70, low: 55, label: 'Low' })}>Low</Dropdown.Item>
              <Dropdown.Item onClick={() => setRange({ high: 54, low: undefined, label: 'Dangerously Low' })}>Dangerously Low</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {(rangeDescription === 'Above' || rangeDescription === 'Below') && (
            <Form.Control
              className={styles.rangeInput}
              onChange={(e) => {
                setTempValue(+e.currentTarget.value)
                filterReadings(rangeDescription, +e.currentTarget.value)
              }}
              value={tempValue}
            />
          )}
          {isMobile ? (
            <>
              <Typography styles={{ marginTop: 5 }}>Start Time:</Typography>
              <TimePicker
                disableClock={true}
                format={'hh:mm a'}
                value={startTime}
                onChange={(value) => setStartTime(value ? value.concat('', ':00') : value)}
              />
              <Typography styles={{ marginTop: 5 }}>End Time:</Typography>
              <TimePicker
                disableClock={true}
                format={'hh:mm a'}
                value={endTime}
                onChange={(value) => setEndTime(value ? value.concat('', ':00') : value)}
              />
            </>
          ) : (
            <>
              <Row styles={rangeDescription === 'Above' || rangeDescription === 'Below' ? { marginLeft: 25, marginRight: 20 } : { marginLeft: 145 }}>
                <Typography styles={{ marginTop: 10, marginRight: 5 }}>Start Time:</Typography>
                <TimePicker
                  disableClock={true}
                  format={'hh:mm a'}
                  value={startTime}
                  onChange={(value) => setStartTime(value ? value.concat('', ':00') : value)}
                />
              </Row>
              <Row styles={{ marginLeft: 40 }}>
                <Typography styles={{ marginTop: 10, marginRight: 5, marginLeft: 10 }}>End Time:</Typography>
                <TimePicker
                  disableClock={true}
                  format={'hh:mm a'}
                  value={endTime}
                  onChange={(value) => setEndTime(value ? value.concat('', ':00') : value)}
                />
              </Row>
            </>
          )}
        </ResponsiveContainer>
      </ResponsiveContainer>
      <Dropdown style={isMobile ? { marginTop: 10 } : {}} onClick={() => handleExportClick()}>
        <Dropdown.Toggle
          variant="secondary"
          id="dropdown-basic"
          className={styles.toolbarButton}
          style={{
            backgroundColor: '#425563',
            color: 'white',
            border: '1px solid #425563',
          }}
        >
          Export
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              apiRef.current.exportDataAsPrint({
                hideToolbar: true,
                hideFooter: true,
                fields: ['readingDate', 'bloodGlucose', 'readingType', 'mealType', 'carbCount', 'insulinDosage', 'feeling', 'notes'],
              })
            }
          >
            Print
          </Dropdown.Item>
          <Dropdown.Item onClick={() => apiRef.current.exportDataAsCsv()}>Export as CSV</Dropdown.Item>
          <Dropdown.Item onClick={() => exportToPdf()}>Export as PDF</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ResponsiveContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: 15,
      justifyContent: 'space-between',
    },
    rangeContainer: {},
    nextButton: {
      cursor: 'pointer',
      fontFamily: 'Raleway',
    },
    rangeInput: {
      marginLeft: 5,
      minHeight: 0,
      maxHeight: 38,
      width: '125px',
      marginRight: 10,
      marginBottom: 0,
      backgroundColor: '#edfdff',
      borderRadius: 3,
      boxShadow: 'inset 0px 1px 3px',
      border: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
        marginBottom: 5,
      },
    },
    toolbarButton: {
      minWidth: 100,
      backgroundColor: '#F2F2F2',
      color: 'black',
      border: '1px solid #BDBDBD',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 5,
      },
    },
  }),
)
