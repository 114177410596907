import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Lozenge 1.png'
import Image2 from '../../../../Assets/Images/STOP/Lozenge 2.png'
import Image3 from '../../../../Assets/Images/STOP/Lozenge 3.png'

export default function NRTLozenge() {
  return (
    <ProConScreen
      title="NRT Products: Lozenge"
      subtitle="Lozenges contain a small amount of nicotine that is absorbed slowly as it dissolves in the mouth."
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Available OTC in multiple strengths and sizes',
        'Available as mini lozenges that deliver nicotine more rapidly',
        'Aids in control of sudden cravings/withdrawal symptoms',
        'Serves as an oral substitute for tobacco',
        'No chewing required',
        'Doesn’t stick to dental appliances',
        'Can be used in combination with other NRT products and medications',
      ]}
      cons={[
        'Must be used repeatedly throughout the day to control cravings or withdrawal symptoms',
        'May cause mouth irritation, hiccups, heartburn, or nausea',
        'Cannot eat 15 minutes before use',
      ]}
    />
  )
}
