import Row from 'Components/Base/Row'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import styled from 'styled-components'
import BodyBenefitsImage from '../../../../Assets/Images/STOP/Body Benefits.png'

export default function BodyImpacts() {
  return (
    <>
      <BenefitScreen
        altTag="Woman breathing in air"
        content={
          <>
            <p>
              <STOPHeader>Quitting Tobacco has Positive Impacts All Over the Body</STOPHeader>
            </p>
            <ul style={{ marginBottom: 0 }}>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Eyes:</span> Reduced risk of vision loss and cataracts
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Heart: </span> Blood pressure and heart rate improve
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Lungs:</span> Improved ability to breathe
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Mouth:</span> Food tastes better
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Nose:</span> Sense of smell improves
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Pancreas/Digestion:</span> Improves insulin function and digestion
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Reproductive:</span> Less likely to experience erectile dysfunction, infertility, or
                  premature birth
                </ScaledText>
              </StyledItem>
              <StyledItem>
                <ScaledText>
                  <span style={{ textDecoration: 'underline' }}>Teeth:</span> Less yellow in color and reduced tooth loss
                </ScaledText>
              </StyledItem>
            </ul>
          </>
        }
        image={BodyBenefitsImage}
      />
      <Row styles={{ marginTop: 25 }}>
        <ScaledText>
          Tobacco is a leading cause of cancer, stroke, heart and lung disease, and diabetes. More than 16 million Americans are living with a disease
          cause by tobacco.
        </ScaledText>
      </Row>
      <Row styles={{ marginTop: 15 }}>
        <ScaledText>
          Quitting tobacco leads to improved energy levels, increases life expectancy, and strengthens the immune system to fight off infection
          compared to those who continue to use tobacco.
        </ScaledText>
      </Row>
    </>
  )
}

const StyledItem = styled.li`
  margin-bottom: 10px;
`
