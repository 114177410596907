import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import { ResourceSection, ResourceSubSection } from '../types/Resource'
import { ResourceLink } from '../types/ResourceLink'

export interface ResourceState {
  resourceSections: ResourceSection[] | undefined
  resourceSubSections: ResourceSubSection[] | undefined
  resourceLinks: ResourceLink[] | undefined
  status: 'idle' | 'loading' | 'failed'
}

const initialState: ResourceState = {
  resourceSections: undefined,
  resourceSubSections: undefined,
  resourceLinks: undefined,
  status: 'idle',
}

export const loadSections = createAsyncThunk('resources/sections/load', async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi().get('api/resources/sections', {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      returnInactive: true,
    },
  })

  return response
})

export const loadSubSections = createAsyncThunk('resources/subsections/load', async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi().get('api/resources/subsections', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const loadResourceLinks = createAsyncThunk('resources/links/load', async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi().get('api/resources/', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadSections.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadSubSections.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadResourceLinks.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadSections.fulfilled, (state, action) => {
        state.status = 'idle'
        state.resourceSections = action.payload.data
      })
      .addCase(loadSubSections.fulfilled, (state, action) => {
        state.status = 'idle'
        state.resourceSubSections = action.payload.data
      })
      .addCase(loadResourceLinks.fulfilled, (state, action) => {
        state.status = 'idle'
        state.resourceLinks = action.payload.data
      })
      .addCase(loadSections.rejected, (state) => {
        state.status = 'idle'
      })
      .addCase(loadSubSections.rejected, (state) => {
        state.status = 'idle'
      })
      .addCase(loadResourceLinks.rejected, (state) => {
        state.status = 'idle'
      })
  },
})

export const getResources = (state: RootState) => state.root.resources.resourceLinks
export const getAllResources = (state: RootState) => state.root.resources.resourceLinks

export const getActiveResourceSections = (state: RootState) => state.root.resources.resourceSections?.filter((section) => section.active)
export const getAllResourceSections = (state: RootState) => state.root.resources.resourceSections

export const getResourceSubSections = (state: RootState) => state.root.resources.resourceSubSections

export const getResourceStatus = (state: RootState) => state.root.resources.status

export default resourceSlice.reducer
