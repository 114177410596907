import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

export default function ScheduleAFollowUp() {
  const navigate = useNavigate()

  return (
    <StyledRightSideOfPageContainer>
      <StyledFrequentResourcesContainer>
        <div style={{ display: 'grid', placeItems: 'center', marginTop: 60 }}>
          <Typography color="black" size={25} variant="h3">
            Message Submitted!
          </Typography>
          <Typography styles={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>You have successfully submitted your message.</Typography>
          <Typography styles={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
            A member of our team will respond as soon as possible.
          </Typography>
        </div>
      </StyledFrequentResourcesContainer>
      <StyledDownloadMobileAppContainer>
        <Row>
          <Column>
            <Icon name="circleExclamation" color="#E80036" styles={{ marginTop: 20, marginLeft: 25 }} size="4x" />
          </Column>
          <Column styles={{ marginLeft: 15, marginTop: 15 }}>
            <Typography color="black" size={18} variant="h3">
              It's Time for a Follow Up!
            </Typography>
            <Typography styles={{ marginTop: 10 }} size={15}>
              According to our records, you’re due for a follow up appointment with
            </Typography>
            <Typography size={15}>your Tria Health pharmacist. Click the button below to get scheduled.</Typography>
            <Button
              onClick={() => navigate('/appointment')}
              text="Get Scheduled"
              backgroundColor="#E80036"
              styles={{ width: 200, marginTop: 20, marginBottom: 20 }}
            />
          </Column>
        </Row>
      </StyledDownloadMobileAppContainer>
    </StyledRightSideOfPageContainer>
  )
}

const StyledFrequentResourcesContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  padding: 15px;
  text-align: center;
  margin-top: -10%;
  @media only screen and (min-width: 1100px) {
    height: 250px;
    width: 125.5%;
    margin-left: -25%;
  }

  @media only screen and (max-width: 1100px) {
    height: 295px;
    width: 100%;
    margin-top: 5%;
  }
`
const StyledDownloadMobileAppContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  @media only screen and (min-width: 1100px) {
    min-height: 183px;
    max-height: fit-content;
    width: 125.5%;
    margin-top: 20px;
    margin-left: -25%;
  }

  @media only screen and (max-width: 1100px) {
    min-height: 200px;
    max-height: fit-content;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`
const StyledRightSideOfPageContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    flex-direction: column;
    margin-left: 51%;
    margin-top: -520px;
  }
`
