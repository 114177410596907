import Row from 'Components/Base/Row'
import Template6 from 'Pages/CTLChallenges/Components/Templates/Template6'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function SleepStep7({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])

  return (
    <Template6
      isMobile={isMobile}
      title={sectionInfo?.titles.find((title) => title.titleId === 6)?.titleText ?? ''}
      textSection={
        <>
          <Row styles={{ margin: isMobile ? '20px 0px' : '5px 0px' }}>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20, fontWeight: 'bold' }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 32)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '-5px 0px', marginLeft: 25 }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 33)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '-5px 0px', marginLeft: 25 }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 34)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '-5px 0px', marginLeft: 25 }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 35)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '-5px 0px', marginLeft: 25 }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 36)?.paragraph ?? ''}
            </p>
          </Row>
        </>
      }
      textSection2={
        <>
          <Row styles={{ margin: isMobile ? '20px 0px' : '5px 0px' }}>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20, fontWeight: 'bold' }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 37)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '-5px 0px', marginLeft: 25 }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 38)?.paragraph ?? ''}
            </p>
          </Row>
        </>
      }
      img={sectionInfo?.images.find((image) => image.imageId === 14)?.url ?? ''}
      img2={sectionInfo?.images.find((image) => image.imageId === 15)?.url ?? ''}
    />
  )
}
