import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import Button from '../../../Components/Base/Button'
import BaseForm from '../BaseForm'

interface Props {
  onSubmit: () => void
}
export default function WelcomeForm({ onSubmit }: Props) {
  return (
    <BaseForm
      title="Patient Intake Form"
      subtitle={`In order for our pharmacists to provide you with a comprehensive
        evaluation, please take the time to fill out this questionnaire. All
        your information will be kept confidential. If you have any questions,
        please call the Tria Health Help Desk: ${PhoneNumberPeriodDelimited}`}
      buttonOverride={<StyledButton pageName="patient_intake_welcome" text="Get Started!" onClick={onSubmit} />}
      onSubmit={() => onSubmit()}
    />
  )
}

const StyledButton = styled(Button)`
  border-radius: 4px;
  padding: 11px 0px;
  width: 200px;
  @media (max-width: ${sizes.tablet}) {
    margin-right: 0px;
    width: 100%;
  }
`
