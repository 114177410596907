const trimPeriod = (value: string | null): string | null => {
  if (!value) return null

  if (value.endsWith('.')) {
    return value.slice(0, -1)
  } else {
    return value
  }
}

export default trimPeriod
