import { AdditionalLinkType } from 'types/AdditionalLink'

const links = [
  {
    title: 'Non-pharmacologic methods for reducing BP [English]',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/BloodPressure/Hypertension_Prevention_Nonpharm.pdf',
    type: AdditionalLinkType.File,
  },
  {
    title: 'Non-pharmacologic methods for reducing BP [Spanish]',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/BloodPressure/Hypertension%20Prevention_Nonpharmacologic_Spanish.pdf',
    type: AdditionalLinkType.File,
  },
  {
    title: 'Dash Diet',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/BloodPressure/The%20Dash%20Diet.pdf',
    type: AdditionalLinkType.File,
  },
]

export default links
