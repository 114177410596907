import Typography from 'Components/Base/Typography'
import LoadingModal from 'Components/LoadingModal'
import IdleTimer from 'Pages/AppointmentPage/components/IdleTimer'
import 'bootstrap/dist/css/bootstrap.min.css'
import dayjs from 'dayjs'
import useHealth from 'hooks/useHealth'
import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { Helmet } from 'react-helmet'
import { useAuth } from 'react-oauth2-pkce'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { BrowserRouter as Router } from 'react-router-dom'
import SmartBanner from 'react-smartbanner'
import { getMultifactor, getMultifactorReleaseDate, loadMultifactor } from 'reducers/multifactorSlice'
import '../node_modules/react-smartbanner/dist/main.css'
import './App.scss'
import LoginModal from './Components/LoginModal'
import Navbar from './Components/Navbar'
import Footer from './Pages/PatientProfile/components/Footer'
import { useAppDispatch, useAppSelector } from './hooks/hooks'
import { loadAllergies } from './reducers/allergiesSlice'
import { authLogout, getAccessToken, restoreToken } from './reducers/authSlice'
import { loadBloodPressureSummary, loadGlucoseReadingCGMSummary, loadGlucoseReadingSummary } from './reducers/dashboardSlice'
import { setLoginError } from './reducers/errorSlice'
import { loadMedications } from './reducers/medicationsSlice'
import { loadPhysicians } from './reducers/physiciansSlice'
import { loadCaregivers, loadCommunicationPreferences, loadHabitOptions, loadProfile } from './reducers/profileSlice'
import { loadCareplan } from './reducers/recommendationsSlice'
import { loadResourceLinks, loadSections, loadSubSections } from './reducers/resourceSlice'
import { clearRequestedRoute, setLoading } from './reducers/tempSlice'
import RootRoutes from './routes'

declare global {
  interface Window {
    medchatapp: MedChatApp
  }
}

interface MedChatApp {
  onExpand: Function
}

function App() {
  const auth = useAuth()
  const { getHealth } = useHealth()

  const token = useAppSelector(getAccessToken)

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)
  const [medicationsLoaded, setMedicationsLoaded] = useState(false)
  const [profileLoaded, setProfileLoaded] = useState(false)
  const [health, setHealth] = useState(true)
  const [checkingModal, setCheckingModal] = useState(false)

  const authToken = auth.authService.getAuthTokens().access_token
  const forceMultiFactor = useAppSelector(getMultifactor)
  const multiFactorReleaseDate = useAppSelector(getMultifactorReleaseDate)

  const dispatch = useAppDispatch()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })

    const authenticated = auth.authService.isAuthenticated()
    const exp = window.localStorage.getItem('tokenExp')
    const now = new Date()

    if (exp) {
      const item: { expiry: number } = JSON.parse(exp)
      if (now.getTime() > item.expiry) {
        auth.authService.logout(true).then(() => {
          dispatch(setLoginError(''))
          dispatch(clearRequestedRoute())
          dispatch(authLogout())
          dispatch(setLoading(false))
          localStorage.removeItem('tokenExp')
        })
      } else {
        if (authenticated) {
          dispatch(restoreToken(authToken))
        }
      }
    }
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(setLoading(true))
      dispatch(loadProfile(token))
        .then((res) => {
          dispatch(setLoading(false))
          setProfileLoaded(true)
          dispatch(loadHabitOptions(token))
          dispatch(loadCaregivers(token))
          dispatch(loadCommunicationPreferences(token))
          dispatch(loadPhysicians(token))
          dispatch(loadAllergies(token))
          dispatch(loadCareplan(token))
          dispatch(loadMultifactor(token))
          dispatch(loadMedications(token)).then(() => {
            setMedicationsLoaded(true)
          })
        })
        .catch((err) => {
          dispatch(setLoginError('There was an error. Please try again'))
          console.log(err)
          dispatch(setLoading(false))
        })
      dispatch(loadGlucoseReadingSummary({ token: token }))
      dispatch(loadGlucoseReadingCGMSummary({ token: token, daysBack: 14 }))
      dispatch(loadBloodPressureSummary({ token: token }))
      console.log(`Site Version: ${process.env.REACT_APP_SITE_VERSION}`)
      console.log(`Site Release: ${process.env.REACT_APP_RELEASE_DATE}`)
    }
  }, [token])

  useEffect(() => {
    dispatch(loadResourceLinks())
    dispatch(loadSections())
    dispatch(loadSubSections())
  }, [])
  useEffect(() => {
    if (forceMultiFactor === undefined) {
      setCheckingModal(false)
    }
  }, [forceMultiFactor])

  useEffect(() => {
    const interval = setInterval(
      () => (checkingModal && forceMultiFactor !== undefined ? dispatch(loadMultifactor(token!)) : clearInterval(interval)),
      5000,
    )
    return () => {
      clearInterval(interval)
    }
  }, [checkingModal])

  useEffect(() => {
    getHealth()
      .then((resp) => {
        if (resp.data !== 'alive') {
          setHealth(false)
        }
      })
      .catch(() => setHealth(false))
  }, [])

  const cancelWidget = () => {
    document.getElementById('medchat-launcher-frame')?.classList.add('medchat-cancel-hidden')

    document.getElementById('medchat-chat-frame')?.classList.add('medchat-cancel-hidden')

    document.getElementById('medchat-cancel')?.classList.add('medchat-cancel-hidden')
  }

  const clickExternalLink = (link) => {
    setCheckingModal(true)
    const width = isMobile ? window.outerWidth - 100 : window.outerWidth - 600
    const height = isMobile ? window.outerHeight - 100 : window.outerHeight - 200
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const title = `Multi-Factor Authentication`
    window.open(link, title, `width=${width},height=${height},left=${left},top=${top}`)
  }

  return (
    <>
      <Helmet>
        <meta name="google-play-app" content="app-id=com.tria.health" />
        <meta name="apple-itunes-app" content="app-id=1541412621" />
        <meta name="description" content="Access your care plan, device logs, and more!" />

        <link rel="apple-touch-icon" href="icon.png" />
        <link rel="android-touch-icon" href="icon.png" />
        <link rel="windows-touch-icon" href="icon.png" />
        <script type="text/javascript" async src="https://medchatapp.com/widget/widget.js?api-key=60qB-Pr0gEeAOYuH7bhHNw" />
        <link rel="preload" href={`${process.env.REACT_APP_AZURE_BASE_URL}/images/Portal_Exam.svg`} as="image" />
        {process.env.REACT_APP_ENV_NAME === 'PRODUCTION' && (
          <script>
            {`(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#00728f', triggerColor : '#00728f', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'left', triggerPositionY : 'top', triggerOffsetX : 10, triggerOffsetY : 0, triggerRadius : '50%' } }); }; h.appendChild(s); })();`}
          </script>
        )}
      </Helmet>
      <SmartBanner title={'Tria Health'} />
      <Router>
        <div>
          <div className="root-content-container" style={{ minHeight: '100vh' }}>
            <Navbar isMobile={isMobile} medicationsLoaded={medicationsLoaded} profileLoaded={profileLoaded} />
            {!health && (
              <div>
                <Marquee
                  style={{
                    backgroundColor: '#9B2743',
                    color: 'white',
                    height: 50,
                    textAlign: 'center',
                    marginTop: isMobile ? -4 : 0,
                  }}
                  gradient={false}
                  play={isMobile || window.innerWidth < 1900 ? true : false}
                >
                  <Typography>
                    <>
                      Apologies for the inconvenience! We're working diligently to restore our services and get things back to normal as quickly as
                      possible. Thank you for your patience.
                    </>
                  </Typography>
                </Marquee>
              </div>
            )}
            {token && health && forceMultiFactor === false && (
              <div>
                <Marquee
                  style={{
                    backgroundColor: '#9B2743',
                    color: 'white',
                    height: 50,
                    textAlign: 'center',
                    marginTop: 0,
                  }}
                  gradient={false}
                  play={true}
                >
                  <Typography styles={{ fontWeight: 'bold', marginRight: 5 }}>Action required:</Typography>
                  <Typography>{`Enable multi-factor authentication on your account by ${dayjs(multiFactorReleaseDate)
                    .add(2, 'month')
                    .format('MMMM D, YYYY')}.`}</Typography>
                  <div onClick={() => clickExternalLink(`https://medchatapp.com/widget/bLZCrHZAnkSVkQoS3dvulQ/chat`)}>
                    <Typography styles={{ fontWeight: 'bold', textDecoration: 'underline', marginLeft: 5, cursor: 'pointer' }}>
                      Get Started
                    </Typography>
                  </div>
                </Marquee>
              </div>
            )}
            <LoadingModal
              toggleModal={() => setCheckingModal(!checkingModal)}
              isMobile={isMobile}
              modalOpen={checkingModal}
              header="Refreshing Two Factor Authentication"
            />
            <RootRoutes isMobile={isMobile} />
            <Footer />
          </div>
        </div>
        <IdleTimer />
      </Router>

      {showLoginModal && <LoginModal modalOpen={showLoginModal} toggleModal={(isOpen) => setShowLoginModal(isOpen)} />}
    </>
  )
}

export default App
