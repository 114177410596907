import { sizes } from 'Styles/sizes'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { CGMMetadata, RangeSetting } from 'types/Dashboards'
import GMICard from '../GMICard'
import GoalCard from '../GoalCard'

type Props = {
  setTableRange: (range: RangeSetting) => void
  cgmMetadata: CGMMetadata
}
export default function CGMCards({ setTableRange, cgmMetadata }: Props) {
  const theme = useTheme()

  return (
    <StyledCards>
      <StyledGoalCard
        title={`${cgmMetadata.percentHighReadings}%`}
        subtitle={'Goal: <5%'}
        color={theme.colors.charts.dangerousHigh}
        bannerTitle={'High'}
        linkAction={() => setTableRange({ high: undefined, low: 250, label: 'High' })}
      />
      <StyledGoalCard
        title={`${cgmMetadata.percentAboveRangeReadings}%`}
        subtitle={'Goal: <25%'}
        color={theme.colors.charts.high}
        bannerTitle={'Above-Range'}
        linkAction={() => setTableRange({ high: 250, low: 180, label: 'Above-Range' })}
      />
      <StyledGoalCard
        title={`${cgmMetadata.percentInRangeReadings}%`}
        subtitle={'Goal: >70%'}
        color={theme.colors.charts.inRange}
        bannerTitle={'Time In-Range'}
        bannerTitleColor={theme.colors.white}
        linkAction={() => setTableRange({ high: 180, low: 70, label: 'In-Range' })}
      />
      <StyledGoalCard
        title={`${cgmMetadata.percentLowReadings}%`}
        subtitle={'Goal: <4%'}
        color={theme.colors.charts.low}
        bannerTitle={'Low'}
        bannerTitleColor={theme.colors.white}
        linkAction={() => setTableRange({ high: 70, low: 55, label: 'Low' })}
      />
      <StyledGoalCard
        title={`${cgmMetadata.percentCriticalLowReadings}%`}
        subtitle={'Goal: <1%'}
        color={theme.colors.charts.dangerousLow}
        bannerTitle={'Dangerously Low'}
        bannerTitleColor={theme.colors.white}
        linkAction={() => setTableRange({ high: 54, low: undefined, label: 'Dangerously Low' })}
      />
      <StyledGoalCard title={`${cgmMetadata.averageReading}`} subtitle={'Average Reading'} color={theme.colors.charts.dangerousHigh} />
      <StyledVariabilityCard
        title={`Glucose Management Indicator (GMI)`}
        text={`GMI is calculated using the formula GMI (%) = 3.31 + 0.02392 × mean glucose in mg/dL`}
        value={`${cgmMetadata.glucoseManagementIndicator}%`}
        goal={'<7%'}
        span={2}
      />
      <StyledVariabilityCard
        title={`Glucose Variability Indicator (GVI)`}
        text={`The measurement of deviation from the steady blood glucose state`}
        value={`${cgmMetadata.glucoseVariability}%`}
        goal={'<36%'}
        span={1}
      />
    </StyledCards>
  )
}

const StyledCards = styled.div`
  display: grid;
  column-gap: 2%;
  width: 70%;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(min(100%/3, max(80px, 94%/3)), 1fr));
  @media (max-width: ${sizes.tablet}) {
    row-gap: 15px;
    width: 100%;
  }
`
const StyledGoalCard = styled(GoalCard)`
  min-height: 175px;
  max-width: 100%;
  min-width: 0px;
  margin-bottom: 0px;
  @media (max-width: ${sizes.tablet}) {
    min-height: 150;
  }
`

const StyledVariabilityCard = styled(GMICard)`
  max-width: 100%;
  min-width: 0px;
  margin-bottom: 0px;
  @media (max-width: ${sizes.tablet}) {
    min-height: 150px;
  }
  @media (max-width: ${sizes.mobileM}) {
    grid-column: span 2;
  }
`
