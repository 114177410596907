import BPLogImage from 'Assets/Images/BP_logFilter.png'
import RangeImage from 'Assets/Images/bp-range.png'
import SettingsImage from 'Assets/Images/bp-settings.png'
import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled, { useTheme } from 'styled-components'
import './ModalDialogStyle.scss'

interface Props {
  modalOpen: boolean
  toggleModal: (isOpen: boolean) => void
}
export default function BPWhatsNewModal({ modalOpen, toggleModal }: Props) {
  const theme = useTheme()

  return (
    <StyledModal show={modalOpen} onHide={() => toggleModal(false)} dialogClassName={'bp-new-dialog'} fullscreen={false}>
      <StyledHeader>
        <Modal.Title>What's New?</Modal.Title>
        <div onClick={() => toggleModal(false)} style={{ cursor: 'pointer' }}>
          <Icon name="times" color="white" />
        </div>
      </StyledHeader>
      <Modal.Body>
        <div style={{ padding: 0 }}>
          <Column styles={{ marginBottom: 15 }}>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={20}>
                App Access
              </Typography>
            </Row>
            <Typography>
              You can now view your blood glucose logs on the go! Download Tria Health’s mobile app to view a summary of your readings, personalize
              carbs and meal types for your readings, and order your supplies! Log in using your Tria Health patient portal account.
            </Typography>
          </Column>

          <Column styles={{ marginBottom: 15 }}>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={20}>
                My Log Updates
              </Typography>
            </Row>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={15}>
                Custom Date Range
              </Typography>
            </Row>
            <Typography>
              Need to review your logs from a certain date range? You can now select a custom date range. This can be beneficial is you need to
              isolate a change due to medications, insulin, holidays, or vacation.
            </Typography>
            <Row center styles={{ marginTop: 0 }}>
              <StyledImage src={RangeImage} alt="Custom range input fields" />
            </Row>
            <Row styles={{ marginTop: 20 }}>
              <Typography color={theme.colors.primary1} font="bold" size={15}>
                Log Filters
              </Typography>
            </Row>
            <Typography styles={{ marginBottom: 10 }}>
              You can filter the readings in the log based on ranges for the systolic, diastolic or pulse values. You can also filter based on BP
              ranges: Highs, Lows, etc.
            </Typography>
            <Row center styles={{ marginTop: 10, marginBottom: 10 }}>
              <StyledImage src={BPLogImage} alt="Log input filters" />
            </Row>
          </Column>
          <Column styles={{ marginBottom: 15 }}>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={20}>
                My Text Settings Updates
              </Typography>
            </Row>
            <Row>
              <Typography color={theme.colors.primary1} font="bold" size={15}>
                High Reading Notifications
              </Typography>
            </Row>
            <Typography>
              For users opted into Tria Health's Text Alerts, users can personalize their high blood glucose notifications. Input the blood glucose
              level that is high for you and click Update. Tria Health will only send high blood glucose alerts when your blood glucose levels are
              higher than your selection.
            </Typography>
            <Row center styles={{ marginTop: 10, marginBottom: 10 }}>
              <StyledImage src={SettingsImage} alt="Customize your high reading alert" />
            </Row>
            <Row styles={{ marginTop: 10, marginBottom: 10 }}>
              <StyledButton pageName="bpWhatsNewModal" text="Close" onClick={() => toggleModal(false)} />
            </Row>
          </Column>
        </div>
      </Modal.Body>
    </StyledModal>
  )
}

const StyledImage = styled.img`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 700px;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
  }
`
const StyledModal = styled(Modal)`
  padding-left: 0px !important;
`
const StyledHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.primary1};
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`

const StyledButton = styled(Button)`
  font-family: 'Raleway-Semi-Bold';
  width: 15vw;
  padding-top: 10px;
  border: 0px;
  padding-bottom: 10px;
  margin-top: 10px;
  display: none;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
    display: block;
  }
`
