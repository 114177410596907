import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import { useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React from 'react'
import { getAllergies } from 'reducers/allergiesSlice'
import { TableData } from 'types/TableData'
import ContentHeader from '../ContentHeader'
import DataTable, { ColumnDefinitionType } from '../DataTable'
import HelpInfo from '../HelpInfo'

const columns: ColumnDefinitionType<keyof TableData>[] = [
  {
    key: 'allergy',
    header: 'Allergy',
  },
  {
    key: 'reaction',
    header: 'Reaction',
  },
]
interface Props {
  isMobile: boolean
  backPressed: () => void
  sideMenuToggled: () => void
}

export default function Allergies({ isMobile, backPressed, sideMenuToggled }: Props) {
  const allergies = useAppSelector(getAllergies)
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Allergies')

  const data = allergies
    ? allergies.map((allergy) => {
        const allergyText = allergy.allergies ? allergy.allergies?.charAt(0).toUpperCase() + allergy.allergies?.slice(1) : ''

        const reactionText = `${allergy.asthma ? 'Asthma, ' : ''}${allergy.rash ? 'Rash, ' : ''}${allergy.shock ? 'Shock, ' : ''}${
          allergy.nausea ? 'Nausea, ' : ''
        }${allergy.anemia ? 'Anemia, ' : ''}${allergy.itching ? 'Itching, ' : ''}${allergy.throatSwelling ? 'Throat swelling, ' : ''}${
          allergy.vomiting ? 'Vomiting, ' : ''
        }${allergy.hives ? 'Hives, ' : ''}${allergy.swelling ? 'Swelling, ' : ''}${allergy.tachycardia ? 'Tachycardia, ' : ''}${
          allergy.other ? allergy.otherDesc + ', ' : ''
        }`

        return {
          allergy: allergyText,
          reaction: reactionText.trim().slice(0, -1),
        }
      })
    : []

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }} onClick={backPressed}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}
        <ContentHeader title="Allergies" icon="allergy" color="#00677f" />
        {isMobile && <div></div>}
      </Row>
      <div style={{ marginBottom: 50 }}>
        <DataTable columns={columns} data={data} centerData />
      </div>
      <HelpInfo />
    </>
  )
}
