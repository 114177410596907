export default function formatDateInput(value: any): string {
  if (!value) return value

  let date = value.replace(/[^\d]/g, '')

  date = date.substr(0, 8)

  const size = date.length

  if (size > 4) date = insertString(date, '/', 4)

  if (size > 2) date = insertString(date, '/', 2)

  return date
}

function insertString(originalString: string, newString: string, index: number) {
  return originalString.substr(0, index) + newString + originalString.substr(index)
}
