import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { PhoneNumberDashDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'

export function generateTablePDF(theme, tableRows, readingType, range, logo) {
  const doc = new jsPDF('landscape')
  autoTable(doc, {
    styles: { cellWidth: 'auto', valign: 'middle', halign: 'center' },
    headStyles: { fillColor: theme.colors.primary1, minCellHeight: 15 },
    columnStyles: { notes: { cellWidth: 60, minCellHeight: 12 } },
    columns: [
      { header: 'Date/Time', dataKey: 'readingDate' },
      { header: 'Time', dataKey: 'readingTime' },
      { header: 'Blood Glucose (mg/dl)', dataKey: 'bloodGlucose' },
      { header: 'Reading Type', dataKey: 'readingType' },
      { header: 'Meal Type', dataKey: 'mealType' },
      { header: 'Carb Count', dataKey: 'carbCount' },
      { header: 'Insulin Dosage', dataKey: 'insulinDosage' },
      { header: 'Notes', dataKey: 'notes' },
    ],
    body: tableRows,

    didDrawPage: function (data) {
      doc.setFontSize(15)
      doc.setTextColor(theme.colors.primary1)
      doc.text(`Type: ${readingType}`, data.settings.margin.left, 10)
      range.label === 'Above' && doc.text(`${range.label}: ${range.low} mg/dl`, 135, 10)
      range.label === 'Below' && doc.text(`${range.label}: ${range.high} mg/dl`, 135, 10)
      range.label !== 'Above' && range.label !== 'Below' && doc.text(`Range: ${range.label}`, 135, 10)
      doc.addImage(logo, 'JPEG', 246, 2, 38, 10)
      const pageSize = doc.internal.pageSize
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
      doc.setFontSize(12)
      doc.setTextColor('#000000')
      doc.text('Have questions about the information above or would like more detail?', 85, pageHeight - 10)
      doc.text(` Contact a Tria Pharmacist today at ${PhoneNumberDashDelimited} or email us at info@triahealth.com.`, 69, pageHeight - 5)
    },
  })

  doc.save('Blood Glucose Readings.pdf')
}
