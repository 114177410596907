import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useAuth } from 'react-oauth2-pkce'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useNavigate } from 'react-router'
import { getErrorMessage } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import * as Yup from 'yup'
import ImageWide from '../../../Assets/Images/Portal_LogIn.svg'
import Button from '../../../Components/Base/Button'
import Row from '../../../Components/Base/Row'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getTokenExpired } from '../../../reducers/authSlice'
import { getErrors } from '../../../reducers/errorSlice'
import '../Homepage.scss'

interface Props {
  provider?: string
  welltrak?: string
  idMatch?: string
}

interface FormValues {
  email: string
  password: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
}

export default function LoginForm({ idMatch, provider, welltrak }: Props) {
  const auth = useAuth()
  const theme = useTheme()

  const dispatch = useAppDispatch()

  const errors = useAppSelector(getErrors)
  const tokenExpired = useAppSelector(getTokenExpired)
  const errorMessage = useAppSelector(getErrorMessage)
  const navigate = useNavigate()

  const [signInLoading, setSignInLoading] = useState(false)
  const [requestedRoute, setRequestedRoute] = useState<string | undefined>()

  useEffect(() => {
    const now = new Date()
    const tempUrl = window.localStorage.getItem('tempUrl')

    if (tempUrl) {
      const url: { value: string; expiry: number } = JSON.parse(tempUrl)

      if (now.getTime() > url.expiry) {
        window.localStorage.removeItem('tempUrl')
      } else {
        setRequestedRoute(url.value)
      }
    }

    if (provider !== undefined && provider !== '') {
      dispatch(setLoading(true))
      const providerName = provider.toLocaleLowerCase()
      auth.authService.login(providerName)
    }

    if (idMatch !== undefined && idMatch !== '') {
      dispatch(setLoading(true))
      const providerName = idMatch.toLocaleLowerCase()
      auth.authService.login(providerName)
    }
  }, [])

  const navigateToUrl = (url: string) => {
    window.location.href = url
  }

  const onSubmit = (/* { email, password }: FormValues */) => {
    //login(email, password)
  }

  const authLogin = () => {
    setSignInLoading(true)
    const devToken = process.env.REACT_APP_DEV_TOKEN
    /* if (process.env.REACT_APP_ENV_NAME === 'DEVELOPMENT') {
      devToken && dispatch(restoreToken(devToken))
    } else { */
    if (provider !== undefined && provider !== '') {
      const providerName = provider.toLocaleLowerCase()
      auth.authService.login(providerName)
    } else if (idMatch !== undefined && idMatch !== '') {
      dispatch(setLoading(true))
      const providerName = idMatch.toLocaleLowerCase()
      auth.authService.login(providerName)
    } else {
      auth.authService.login()
    }
    /* } */
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className="login-form-container">
      <div className="login-form" style={{ width: '95%' }}>
        <h1 className="header-text">Tria Health</h1>
        <h1
          className="header-text"
          style={{
            color: theme.colors.primary1,
          }}
        >
          Patient Portal
        </h1>
        <Form>
          <Form.Group controlId="formBasicEmail"></Form.Group>
          <div style={{ color: 'red' }}>{formik.errors.email}</div>
          <div style={{ color: 'red' }}>{formik.errors.password}</div>
          {tokenExpired && (
            <Row styles={{ marginBottom: 10 }}>
              <StyledErrorText font="semi-bold">
                Your login has expired. Please login again to continue using your Tria Patient Portal
              </StyledErrorText>
            </Row>
          )}
          {requestedRoute !== undefined && (
            <Row styles={{ marginBottom: 10 }}>
              <StyledErrorText font="semi-bold">Please log in to continue</StyledErrorText>
            </Row>
          )}
          {errors.login && (
            <Row styles={{ marginBottom: 10 }}>
              <StyledErrorText font="semi-bold">{errors.login}</StyledErrorText>
            </Row>
          )}
          {errorMessage && (
            <Row styles={{ marginBottom: 10 }}>
              <StyledErrorText font="semi-bold">{errorMessage}</StyledErrorText>
            </Row>
          )}
        </Form>
        <StyledButtonRow>
          <StyledLoginButton text="Login" loading={signInLoading} onClick={authLogin} className="welcome_login_button" />
          <div style={{ height: 15 }} />
          <StyledLoginButton
            text="Register"
            backgroundColor={theme.colors.darkGray}
            onClick={() =>
              !idMatch
                ? navigate('/pre-registration')
                : navigateToUrl(
                    `${process.env.REACT_APP_AUTH_REGISTER_URL}?${welltrak ? 'id=' + idMatch?.toLocaleLowerCase() + '&' : ''}returnUrl=${
                      window.location.origin
                    }`,
                  )
            }
            className="welcome_register_button"
          />
        </StyledButtonRow>
        <div className="login-options">
          <Row>
            <StyledForgotButton
              text="Forgot Password?"
              className="forgot-password-btn"
              onClick={() => navigateToUrl(`${process.env.REACT_APP_AUTH_URL}/Identity/Account/ForgotPassword`)}
            />
          </Row>
        </div>
      </div>
      <img src={ImageWide} id="banner-image" alt="Man at a desk with pill bottles" style={{ marginTop: -5, height: '100vh' }} />
    </div>
  )
}

const StyledButtonRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`

const StyledLoginButton = styled(Button)`
  font-family: 'Raleway-Semi-Bold';
  width: 40%;
  padding-top: 10px;
  border-radius: 4px;
  border: 0px;
  padding-bottom: 10px;
  margin-right: 15px;
  @media ${sizes.tablet} {
    width: 15vw;
  }
`
const StyledForgotButton = styled(Button)`
  background-color: transparent;
  font-family: 'Raleway-Semi-Bold';
  color: ${({ theme }) => theme.colors.error};
  width: auto;
  padding-left: 0px;
  :hover {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.error};
  }
  :focus {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.error};
  }
  :active {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.error};
  }
`
