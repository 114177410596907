import dayjs from 'dayjs'

const countReadings = (readings, level) => readings.filter((reading) => reading.patientReadingLevel === level).length

export const calculateReadingCounts = (readings) => ({
  high: countReadings(readings, 4),
  aboveRange: countReadings(readings, 3),
  inRange: countReadings(readings, 2),
  low: countReadings(readings, 1),
  criticalLow: countReadings(readings, 0),
  total: readings.length,
})

export const calculateReadingMetadata = (readings, dateRange, readingFilter) => {
  const chartReadings = readings
    .map((reading) => ({
      value: reading.value,
      date: `${dayjs(reading.readingDate).format('M/DD/YY')}\n${dayjs(reading.readingDate).format('h:mm:ss a')}`,
      type: reading.eventType,
    }))
    .reverse()

  const totalValue = chartReadings.reduce((a, v) => a + v.value, 0)
  const averageValue = totalValue / chartReadings.length
  const estimatedA1C = (averageValue + 46.7) / 28.7
  const firstReadingDate = dayjs(chartReadings[0]?.date)
  const lastReadingDate = dayjs(chartReadings[chartReadings.length - 1]?.date)
  const averageTestsPerDay =
    dateRange.startDate && dateRange.endDate
      ? chartReadings.length / dayjs(dateRange.endDate).diff(dayjs(dateRange.startDate), 'day')
      : readingFilter.value
      ? chartReadings.length / readingFilter.value
      : chartReadings.length / dayjs(chartReadings[chartReadings.length - 1]?.date).diff(dayjs(chartReadings[0]?.date), 'day')

  return {
    averageReading: Math.round(averageValue),
    estimatedA1C,
    firstReadingDate,
    lastReadingDate,
    averageTestsPerDay,
    chartReadings,
  }
}

export const calculateCgmMetadata = (readings) => {
  if (readings && readings.length > 0) {
    const totalValue = readings.reduce((a, v) => a + v.value, 0)
    const averageValue = totalValue / readings.length
    const glucoseManagementIndicator = Math.round((3.31 + 0.02392 * averageValue) * 10) / 10
    const glucoseVariability =
      Math.round(
        (Math.sqrt(readings.map((x) => Math.pow(x.value - averageValue, 2)).reduce((a, b) => a + b) / readings.length) / averageValue) * 100 * 10,
      ) / 10

    return {
      averageReading: Math.round(averageValue),
      percentHighReadings: Math.round((countReadings(readings, 4) / readings.length) * 100),
      percentAboveRangeReadings: Math.round((countReadings(readings, 3) / readings.length) * 100),
      percentInRangeReadings: Math.round((countReadings(readings, 2) / readings.length) * 100),
      percentLowReadings: Math.round((countReadings(readings, 1) / readings.length) * 100),
      percentCriticalLowReadings: Math.round((countReadings(readings, 0) / readings.length) * 100),
      glucoseManagementIndicator,
      glucoseVariability,
    }
  }
}
