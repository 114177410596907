import { AxiosResponse } from 'axios'
import { ReimbursementFormValues, ReimbursementResponse, ReimbursementSummaryResponse } from '../types/Reimbursement'
import { useApi } from './useAPI'

export default function useReimbursement(token: string) {
  const TriaApi = useApi(token)

  const getReimbursement = async () => {
    const reimbursementData: Promise<AxiosResponse<ReimbursementResponse>> = TriaApi.get(`api/reimbursement`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return reimbursementData
  }

  const getAllReimbursement = async () => {
    const reimbursementsData: Promise<AxiosResponse<ReimbursementSummaryResponse[]>> = TriaApi.get(`api/reimbursement/getall`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return reimbursementsData
  }

  const postReimbursement = async (values: ReimbursementFormValues, callback?: Function) => {
    const formData = new FormData()
    values.documents.map((doc) => {
      formData.append('documents', doc)
    })
    values.welltrakId && formData.append('WellTrakPId', values.welltrakId)
    values.firstName && formData.append('FirstName', values.firstName)
    values.lastName && formData.append('LastName', values.lastName)
    values.birthDate && formData.append('BirthDate', values.birthDate)
    values.address1 && formData.append('Address1', values.address1)
    values.address2 && formData.append('Address2', values.address2)
    values.city && formData.append('City', values.city)
    values.state && formData.append('State', values.state)
    values.zip && formData.append('Zip', values.zip)
    values.phoneNumber && formData.append('PhoneNumber', values.phoneNumber)
    values.emailAddress && formData.append('EmailAddress', values.emailAddress)
    values.numberClaims && formData.append('NumberClaims', values.numberClaims.toString())
    values.eSignature && formData.append('ESignature', values.eSignature)
    values.updateProfile && formData.append('UpdateProfile', values.updateProfile.toString())
    formData.append('PharmacyNotSubmitted', values.pharmacyNotSubmitted.toString())
    formData.append('DidNotHaveCard', values.pharmacyNotSubmitted.toString())
    formData.append('Other', values.other.toString())
    formData.append('OtherReason', values.otherReason)

    const response = TriaApi.post(`api/reimbursement/send`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return response
  }

  return {
    getReimbursement,
    postReimbursement,
    getAllReimbursement,
  }
}
