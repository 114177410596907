import Checkbox from 'Components/Base/Checkbox'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { Formik, FormikErrors, getIn, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { IntakeMedication } from 'types/Medication'
import * as Yup from 'yup'

interface Props {
  med: IntakeMedication
  index: number
  added
  medSelect: (position: number, update: boolean) => void

  currentMed
  medicationsExist
  onSubmit1
}

export default function MedicationsChecklist({ med, index, added, medSelect, currentMed, medicationsExist, onSubmit1 }: Props) {
  useEffect(() => {
    formik.setFieldValue('direction', med.otherFrequency)
  }, [])
  function getStyles(fieldName: string, errors: FormikErrors<IntakeMedication>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }
  const initialValues: IntakeMedication = {
    gpi: '1',
    medicationId: '1',
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    frequency: Yup.string(),
    reason: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => console.log(values),
  })

  const fieldChange = (field, e) => {
    if (field === 'reason') {
      med.reason = e
      formik.setFieldValue('reason', e)
    } else if (field === 'directions') {
      med.otherFrequency = e
      formik.setFieldValue('directions', e)
    }
  }
  return (
    <div>
      <div>
        <Row
          styles={
            index % 2 === 0 ? { padding: 10, border: '1px solid #f2f2f2' } : { padding: 10, border: '1px solid #f2f2f2', backgroundColor: '#f2f2f2' }
          }
        >
          <Checkbox checked={added[index]} onClick={() => medSelect(index, true)} styles={{ marginRight: 10 }}></Checkbox>
          <Typography>{med.name ? med.name : ''}</Typography>-<Typography>{med.strength ? med.strength : ''}</Typography>
          <div onClick={() => medSelect(index, false)} style={{ marginLeft: 'auto', cursor: 'pointer' }}>
            {currentMed === index ? <Icon name="minus" /> : <Icon name="plus" />}
          </div>
        </Row>
        {currentMed === index ? (
          <div>
            <Formik
              initialValues={
                medicationsExist
                  ? {
                      name: med.name,
                      strength: med.strength,
                      quantity: med.quantity,
                    }
                  : initialValues
              }
              validationSchema={validationSchema}
              onSubmit={onSubmit1}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ handleSubmit, values, setFieldValue, isValid, errors }) => {
                return (
                  <div>
                    <Row styles={{ justifyContent: 'space-between' }}>
                      <Form.Control
                        type="text"
                        onChange={(e) => fieldChange('directions', e.currentTarget.value)}
                        style={{
                          width: '100%',
                          height: 80,
                          marginRight: 5,
                          marginTop: 20,
                          border: '2px solid transparent',
                          borderRadius: 4,
                        }}
                        value={med.otherFrequency}
                        placeholder={'Prescription Directions- i.e. One tablet daily'}
                        className="login-input physician-input"
                      />
                    </Row>
                    <Row styles={{ justifyContent: 'space-between' }}>
                      <Form.Control
                        type="text"
                        onChange={(e) => fieldChange('reason', e.currentTarget.value)}
                        style={{
                          width: '100%',
                          height: 80,
                          marginRight: 5,
                          marginTop: 20,
                          border: '2px solid transparent',
                          borderRadius: 4,
                          ...getStyles('reason', errors),
                        }}
                        value={med.reason}
                        placeholder={med.reason ? med.reason : 'Reason for Taking'}
                        className="login-input physician-input"
                      />
                    </Row>
                  </div>
                )
              }}
            </Formik>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
