import React from 'react'
import { Device, GlucoseReadingMetadata, RangeSetting, ReadingCounts } from 'types/Dashboards'
import GridCard from '../GridCard'

interface Props {
  metaData: GlucoseReadingMetadata
  readingCounts: ReadingCounts | undefined
  setTableRange: (range: RangeSetting) => void
  selectedDevice: Device
}
export default function GridCards({ metaData, readingCounts, setTableRange, selectedDevice }: Props) {
  const { averageReading, averageTestsPerDay, estimatedA1C, firstReadingDate, lastReadingDate } = metaData
  const isCGM = selectedDevice.cgm

  return (
    <>
      <div
        className="grid-template"
        style={{
          display: 'grid',
          marginBottom: 25,
        }}
      >
        <GridCard title={averageReading ? Math.round(averageReading).toString() : '0'} subtitle={'Average Reading'} />
        {isCGM ? (
          <GridCard title={'45.5%'} subtitle={'Glucose Variability'} />
        ) : (
          <GridCard title={estimatedA1C ? estimatedA1C?.toFixed(1) : '0'} subtitle={'Estimated A1C'} />
        )}
        <GridCard
          title={readingCounts?.low || readingCounts?.criticalLow ? `${readingCounts?.low + readingCounts?.criticalLow}` : '0'}
          subtitle={'Readings below 70'}
          linkAction={() => setTableRange({ high: 70, low: undefined, label: 'Below' })}
        />
        <GridCard
          title={readingCounts?.high.toString() ?? '0'}
          subtitle={'Readings above 250'}
          linkAction={() => setTableRange({ high: undefined, low: 250, label: 'Above' })}
        />
        {isCGM ? (
          <GridCard title={estimatedA1C ? estimatedA1C?.toFixed(1) : '0'} subtitle={'Estimated A1C'} />
        ) : (
          <GridCard title={`${averageTestsPerDay ? averageTestsPerDay.toFixed(1) : '0'}`} subtitle={'Average # of Readings Per Day'} />
        )}

        <GridCard title={readingCounts ? readingCounts.total.toString() : '0'} subtitle={'Total # of Readings'} />

        <GridCard
          title={firstReadingDate ? firstReadingDate.format('M/DD/YYYY') : 'No data'}
          title2={firstReadingDate ? firstReadingDate.format('h:mm a') : 'No data'}
          subtitle={'First Reading'}
        />

        <GridCard
          title={lastReadingDate ? lastReadingDate.format('M/DD/YYYY') : 'No data'}
          title2={lastReadingDate ? lastReadingDate.format('h:mm a') : 'No data'}
          subtitle={'Last Reading'}
        />
      </div>
    </>
  )
}
