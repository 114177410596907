const getGuid = (value: string | null): string | null => {
  if (!value) return null

  const valueIsGuid = value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
  if (valueIsGuid) return valueIsGuid[0]

  return null
}

export default getGuid
