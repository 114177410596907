import { DataGrid } from '@mui/x-data-grid'
import logo from 'Assets/Images/Tria Logo_Primary.png'
import { CustomPagination } from 'Pages/DashboardPage/components/CustomPagination'
import MobileDataGrid from 'Pages/DashboardPage/components/MobileDataGrid'
import { glucoseColumns } from 'Pages/DashboardPage/constants'
import dayjs from 'dayjs'
import useAnalytics from 'hooks/useAnalytics'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { Device, EditModalSettings, ItemRow, PatientGlucoseReading, RangeSetting } from 'types/Dashboards'
import { mapReadingsToTableRows } from 'utilities/dashboardUtilities'
import { sortReadingsByDate } from '../../dateFunctions'
import { generateTablePDF } from '../../generateTablePDF'
import { CustomToolbar } from '../CustomToolbar'
import EditReadingModal from '../EditReadingModal'

type Props = {
  readings: PatientGlucoseReading[]
  isMobile: boolean
  tableRef: React.MutableRefObject<HTMLDivElement | null>
  selectedDevice: Device
  accessToken: string | undefined
  adminWelltrak: string | undefined
  setReadings: (readings: PatientGlucoseReading[]) => void
}

export default function ReadingDataGrid({ readings, isMobile, tableRef, selectedDevice, accessToken, adminWelltrak, setReadings }: Props) {
  const theme = useTheme()
  const analytics = useAnalytics(accessToken!)

  const [readingType, setReadingType] = useState<string>('All Reading Types')
  const [tableRows, setTableRows] = useState<ItemRow[] | undefined>([])
  const [itemRows, setItemRows] = useState<ItemRow[] | undefined>([])
  const [range, setRange] = useState<RangeSetting>({
    high: undefined,
    low: undefined,
    label: 'All',
  })
  const [rangeNumber, setRangeNumber] = useState<number | undefined>()
  const [editModalSettings, setEditModalSettings] = useState<EditModalSettings>({
    isOpen: false,
  })
  const [updateReading, setUpdateReading] = useState<boolean>(false)

  const [startTime, setStartTime] = useState<string | undefined>()
  const [endTime, setEndTime] = useState<string | undefined>()

  useEffect(() => {
    let readingsCopy = [...readings]
    readingsCopy = sortReadingsByDate(readingsCopy)

    const mappedRows = mapReadingsToTableRows(readingsCopy, false)
    setItemRows(mappedRows)
    setTableRows(mappedRows?.reverse())
  }, [readings])

  const getTimeStamp = (time) => dayjs('2019-01-25 '.concat(time)).unix()

  useEffect(() => {
    let filteredRows = itemRows ?? []

    const { low, high } = range

    filteredRows = filteredRows.filter(
      (row) =>
        (!low || parseInt(row.bloodGlucose) > low) &&
        (!high || parseInt(row.bloodGlucose) < high) &&
        (readingType === 'All Reading Types' || row.readingType === readingType),
    )

    if (startTime && endTime) {
      filteredRows = filteredRows
        .filter((row) => {
          const rowTime = dayjs(`${row.readingDate} ${row.readingTime}`, 'h:mm A').format('HH:mm:00')
          return rowTime >= startTime && rowTime <= endTime
        })
        .sort((a, b) => getTimeStamp(a.readingTime) - getTimeStamp(b.readingTime))
    }

    setTableRows(filteredRows)
  }, [readingType, rangeNumber, range, startTime, endTime, itemRows])

  const locallyUpdateReading = (updatedReading: PatientGlucoseReading) => {
    let readingsCopy = [...readings]
    readingsCopy = sortReadingsByDate(readingsCopy)
    const index = readingsCopy?.findIndex((reading) => reading.patientReadingPId === updatedReading.patientReadingPId)

    const updatedReadings = readingsCopy?.map((t, i) => {
      if (i === index) {
        return updatedReading
      } else {
        return t
      }
    })

    setReadings(updatedReadings)
    const mappedRows = mapReadingsToTableRows(updatedReadings, false)
    setItemRows(mappedRows)
    setTableRows(mappedRows?.reverse())
  }

  function editClick(id) {
    analytics.postAnalyticsEvent({
      category: `click_button_dashboardGlucose_edit_reading`,
      action: `click_button_dashboardGlucose_edit_reading`,
      label: `Button`,
      screenName: 'GlucoseDashboard',
    })
    setEditModalSettings({
      ...editModalSettings,
      isOpen: true,
      reading: readings.find((x) => x.patientReadingPId.toString() === id),
    })
  }

  const Toolbar = () => (
    <CustomToolbar
      readingType={readingType}
      rangeDescription={range.label}
      rangeNumber={rangeNumber}
      isMobile={isMobile}
      setReadingType={(type: string) => setReadingType(type)}
      setRange={(range: RangeSetting) => setRange(range)}
      range={range}
      exportToPdf={() => generateTablePDF(theme, tableRows, readingType, range, logo)}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      startTime={startTime}
      endTime={endTime}
    />
  )

  if (!readings || readings.length < 0) return <></>

  return (
    <>
      <div style={isMobile ? { minHeight: 500, marginBottom: 50 } : { marginBottom: 50 }} className="dashboard-grid-container">
        {tableRows && readings && (
          <div style={{ width: '100%' }}>
            <DataGrid
              ref={tableRef}
              rows={isMobile ? [] : tableRows}
              columns={[...glucoseColumns, { field: 'readingTime', sortable: false }]}
              style={isMobile ? { height: 150 } : { height: tableRows.length < 15 ? 'auto' : 800 }}
              disableColumnMenu
              disableSelectionOnClick
              sortingOrder={['asc', 'desc']}
              autoHeight={tableRows.length < 15}
              onCellClick={(cell) => {
                if (cell.field === 'edit') {
                  setEditModalSettings({
                    ...editModalSettings,
                    isOpen: true,
                    reading: readings.find((x) => x.patientReadingPId.toString() === cell.row.readingId),
                  })
                }
              }}
              components={{
                Pagination: () =>
                  isMobile ? (
                    <></>
                  ) : (
                    <CustomPagination
                      cgmLogo={selectedDevice.cgm ? 'https://triacentral.blob.core.windows.net/dev-storage/logos/dexcom/dexcom_logo.png' : undefined}
                    />
                  ),
                Toolbar: Toolbar,
              }}
            />
            {isMobile ? <MobileDataGrid tableRows={tableRows} editClick={(id) => editClick(id)} dashboard={'glucose'} /> : <></>}
          </div>
        )}
      </div>
      {editModalSettings.isOpen && editModalSettings.reading && (
        <EditReadingModal
          isMobile={isMobile}
          modalOpen={editModalSettings.isOpen}
          reading={editModalSettings.reading}
          onReadingUpdated={(updatedReading: PatientGlucoseReading) => {
            locallyUpdateReading(updatedReading)
          }}
          toggleModal={(isOpen) =>
            setEditModalSettings({
              ...editModalSettings,
              isOpen: isOpen,
              reading: undefined,
            })
          }
        />
      )}
    </>
  )
}
