import STOPProgram from 'Pages/STOPProgram'
import React, { createContext, useContext, useState } from 'react'
import { PatientActivityValues, STOPActivity } from 'types/STOP'

type ContinueButtonContextType = {
  currentActivity: STOPActivity
  activityStatus: PatientActivityValues[]
  currentActivityStep: number
  readyToQuit: boolean
  isContinueButtonEnabled: boolean
  setReadyToQuit: React.Dispatch<React.SetStateAction<boolean>>
  setIsContinueButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentActivity: React.Dispatch<React.SetStateAction<STOPActivity>>
  setCurrentActivityStep: React.Dispatch<React.SetStateAction<number>>
  setActivityStatus: React.Dispatch<React.SetStateAction<PatientActivityValues[]>>
}

export function useStopContext() {
  return useContext(ContinueButtonContext)
}

const ContinueButtonContext = createContext<ContinueButtonContextType>({
  currentActivity: STOPActivity.HealthBenefits,
  currentActivityStep: 0,
  activityStatus: [],
  readyToQuit: false,
  isContinueButtonEnabled: false,
  setReadyToQuit: () => {},
  setIsContinueButtonEnabled: () => {},
  setCurrentActivity: () => {},
  setCurrentActivityStep: () => {},
  setActivityStatus: () => {},
})

export default function ProgramScreen() {
  const [currentActivityStep, setCurrentActivityStep] = useState<number>(0)
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(true)
  const [currentActivity, setCurrentActivity] = useState<STOPActivity>(STOPActivity.HealthBenefits)
  const [activityStatus, setActivityStatus] = useState<PatientActivityValues[]>([])
  const [readyToQuit, setReadyToQuit] = useState<boolean>(false)

  return (
    <ContinueButtonContext.Provider
      value={{
        isContinueButtonEnabled,
        activityStatus,
        readyToQuit,
        setReadyToQuit,
        setActivityStatus,
        setIsContinueButtonEnabled,
        currentActivity,
        currentActivityStep,
        setCurrentActivity,
        setCurrentActivityStep,
      }}
    >
      <STOPProgram />
    </ContinueButtonContext.Provider>
  )
}
