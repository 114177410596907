import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ProgressBar from 'Components/ProgressBar'
import { STOPActivitySteps } from 'Pages/STOPProgram'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled, { useTheme } from 'styled-components'

type Props = {
  activityId: number
  activityStep: number
  onSetActivityStep: (step: number) => void
}
export default function ActivityNavRow({ activityId, activityStep, onSetActivityStep }: Props) {
  const theme = useTheme()
  const currentActivity = STOPActivitySteps[activityId]
  const isFirstStep = activityStep === 0
  const isCompleteScreen = activityStep === STOPActivitySteps[activityId].steps.length - 1

  const handleBackStep = () => {
    if (activityStep <= currentActivity.steps.length - 1) {
      onSetActivityStep(activityStep - 1)
    }
  }

  const steps = currentActivity.steps.map((step, index) => ({
    title: currentActivity.title,
    id: index + 1,
  }))

  return (
    <Row styles={{ minWidth: '100%' }}>
      {!isFirstStep && !isCompleteScreen && (
        <div onClick={handleBackStep} style={{ cursor: 'pointer' }}>
          <Column>
            <Icon name="arrow-left" color={theme.colors.black} size="2x" styles={{ marginRight: 5 }} />
            <Typography color={theme.colors.black} font="bold">
              Back
            </Typography>
          </Column>
        </div>
      )}
      {!isCompleteScreen && (
        <Column center styles={{ minWidth: '100%', alignItems: 'center' }}>
          <StyledProgressBarContainer>
            <ProgressBar color={theme.colors.charts.inRange} showSteps={false} currentStep={activityStep + 1} steps={steps} height={15} />
          </StyledProgressBarContainer>
          <Typography>{currentActivity.title}</Typography>
        </Column>
      )}
    </Row>
  )
}

const StyledProgressBarContainer = styled.div`
  display: none;
  width: 100%;
  @media (${sizes.tablet}) {
    display: flex;
  }
`
