import { Theme, createStyles, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import BackRow from '../../../Components/Base/BackRow'
import Button from '../../../Components/Base/Button'
import Column from '../../../Components/Base/Column'
import Icon from '../../../Components/Base/Icon'
import ResponsiveContainer from '../../../Components/Base/ResponsiveContainer'
import Row from '../../../Components/Base/Row'
import Typography from '../../../Components/Base/Typography'
import { callHelp } from '../../../utilities/callHelp'

export default function PDFLinks() {
  const styles = useStyles()
  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const theme = useTheme()

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  return (
    <div className={styles.container}>
      <Column className="program-info">
        <BackRow />
        <Row>
          <h1
            className={styles.mainTitle}
            style={{
              fontFamily: 'Raleway-Bold',
              marginBottom: 40,
            }}
          >
            Reimbursement Forms
          </h1>
        </Row>
        <Row>
          <a
            href={'https://triacentral.blob.core.windows.net/dev-storage/resources/reimbursement/Tria%20Health_Reimbursement%20Form.pdf'}
            target="_blank"
            style={{ fontFamily: 'Raleway', marginBottom: 10, display: 'flex' }}
            rel="noreferrer"
          >
            <Icon name="file-pdf" styles={{ marginRight: 10 }} color={theme.colors.primary1} />
            <Typography color={'primary'} font="semi-bold" size={16}>
              Reimbursement Form (PDF)
            </Typography>
          </a>
        </Row>
        <Row styles={{ marginBottom: 15 }}>
          <a
            href={'https://triacentral.blob.core.windows.net/dev-storage/resources/reimbursement/Tria%20Health_Reimbursement%20Form_Spanish.pdf'}
            target="_blank"
            style={{ fontFamily: 'Raleway', marginBottom: 10, display: 'flex' }}
            rel="noreferrer"
          >
            <Icon name="file-pdf" styles={{ marginRight: 10 }} color={theme.colors.primary1} />
            <Typography color={'primary'} font="semi-bold" size={16}>
              Reimbursement Form (PDF) - Spanish
            </Typography>
          </a>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" className={styles.header}>
            Looking to Save Time on the Reimbursement Process?
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography size={15}>
            Log in to the patient portal and submit your reimbursement request online. Your portal account allows us to verify your identity and
            pre-populate the majority of your form fields, making it quicker to fill out and easier to submit.
          </Typography>
        </Row>

        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" className={styles.header}>
            Questions?
          </Typography>
        </Row>
        <Row>
          <Typography>
            <>{`Use the live chat function below or give us a call ${isMobile ? 'by tapping the number below: ' : 'at: '}`}</>
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography>
            <span
              onClick={isMobile ? () => callHelp() : undefined}
              style={{
                fontWeight: 'bold',
                color: isMobile ? theme.colors.primary1 : 'black',
              }}
            >
              {PhoneNumberPeriodDelimited}
            </span>
          </Typography>
        </Row>
        <ResponsiveContainer>
          <Button pageName="Reimbursement" className={styles.button} text="Login/Register" onClick={() => navigate('/')} />
          <Button pageName="Reimbursement" className={styles.button} onClick={() => navigate(-1)} text={'Back'} />
        </ResponsiveContainer>
      </Column>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainTitle: {
      fontSize: 45,
      [theme.breakpoints.down('sm')]: { fontSize: 30 },
    },
    header: {
      fontSize: 25,
      [theme.breakpoints.down('sm')]: { fontSize: 20 },
    },
    container: {
      margin: '5% 5% 5% 5%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: { marginTop: 110 },
    },
    button: {
      fontFamily: 'Raleway-Semi-Bold',
      width: '200px',
      paddingTop: '10px',
      borderRadius: 4,
      border: '0px',
      paddingBottom: '10px',
      marginRight: '15px',
      marginBottom: '10px',
      marginTop: '10px',
      [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    title: {
      fontFamily: 'Raleway-Bold',
      textAlign: 'center',
      marginBottom: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: '8vw',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '5vw',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '3vw',
      },
    },
  }),
)
