import Button from 'Components/Base/Button'
import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { PhoneNumberDashDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import Column from '../Base/Column'
import Row from '../Base/Row'
import Typography from '../Base/Typography'

interface Props {
  modalOpen: boolean
  toggleModal: (isOpen: boolean) => void
}
export default function ConsentModal({ modalOpen, toggleModal }: Props) {
  const customStyles = {
    content: {
      width: 'fit-content',
      height: 'fit-content',
      overflow: 'none',
      top: '50%',
      left: '50%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }
  const navigate = useNavigate()

  return (
    <StyledModalContainer style={{ display: modalOpen ? 'block' : 'none' }}>
      <Modal isOpen={modalOpen} onRequestClose={() => toggleModal(false)} style={customStyles} ariaHideApp={false}>
        <StyledModalHeader>
          <Typography color="white" font="bold" size={16}>
            Sign Electronic Communication and Consent
          </Typography>
        </StyledModalHeader>
        <Column styles={{ marginBottom: 15, marginTop: 60, textAlign: 'center' }}>
          <Typography>Our records show that your Electronic Communication</Typography>
          <Typography>Policy Consent Form needs to be signed. This form</Typography>
          <Typography>allows us to communicate with you via email and text.</Typography>
          <Typography>Have questions?</Typography>
          <Typography>{`Please call ${PhoneNumberDashDelimited}.`}</Typography>
          <Row styles={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
            <Button text="Go to Form" onClick={() => navigate('/consent')} styles={{ height: 40 }} rightIcon="external-link" />
            {/* <Button text='Close' onClick={()=>toggleModal(false)} styles={{height: 40}} leftIcon='times'/> */}
          </Row>
        </Column>
      </Modal>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  height: 60px;
  width: 25%;
  z-index: 1000;
  position: fixed;
  inset: 37% auto 40px 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

const StyledModalHeader = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary1};
  position: absolute;
  margin-bottom: 40px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 20px;
  top: 0;
  left: 0;
  justify-content: space-between;
`
