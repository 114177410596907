import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import { Careplan } from '../types/Careplan'

export interface RecommendationsState {
  careplan: Careplan | undefined
  status: 'idle' | 'loading' | 'failed'
}

const initialState: RecommendationsState = {
  careplan: undefined,
  status: 'idle',
}

export type UpdateRequest = {
  token: string
  recommendation: {
    recommendationId: number | undefined
    completed: boolean
    ignored: boolean
  }
}

export const loadCareplan = createAsyncThunk('recommendations/careplan', async (token: string) => {
  const response: Promise<{ data: Careplan }> = useApi(token).get('api/careplans', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    updateExamRecommendation: (state, action: PayloadAction<UpdateRequest>) => {
      if (state.careplan) {
        state.careplan.recommendationsExams.map((x) => {
          if (action.payload.recommendation.recommendationId === x.recommendationPid) {
            x.isCompleted = action.payload.recommendation.completed
            x.isIgnored = action.payload.recommendation.ignored
            return action.payload
          } else {
            return x
          }
        })
      }
    },
    updateMedicationRecommendation: (state, action: PayloadAction<UpdateRequest>) => {
      if (state.careplan) {
        state.careplan.recommendationsMedications.map((x) => {
          if (action.payload.recommendation.recommendationId === x.recommendationPid) {
            x.isCompleted = action.payload.recommendation.completed
            x.isIgnored = action.payload.recommendation.ignored

            return action.payload
          } else {
            return x
          }
        })
      }
    },
    updateLifestyleRecommendation: (state, action: PayloadAction<UpdateRequest>) => {
      if (state.careplan) {
        state.careplan.recommendationsLifestyle.map((x) => {
          if (action.payload.recommendation.recommendationId === x.recommendationPid) {
            x.isCompleted = action.payload.recommendation.completed
            x.isIgnored = action.payload.recommendation.ignored

            return action.payload
          } else {
            return x
          }
        })
      }
    },
    updateVaccineRecommendation: (state, action: PayloadAction<UpdateRequest>) => {
      if (state.careplan) {
        state.careplan.recommendationsVaccine.map((x) => {
          if (action.payload.recommendation.recommendationId === x.recommendationPid) {
            x.isCompleted = action.payload.recommendation.completed
            x.isIgnored = action.payload.recommendation.ignored

            return action.payload
          } else {
            return x
          }
        })
      }
    },
    updatePharmacistRecommendation: (state, action: PayloadAction<UpdateRequest>) => {
      if (state.careplan) {
        state.careplan.recommendationsOther.map((x) => {
          if (action.payload.recommendation.recommendationId === x.recommendationPid) {
            x.isCompleted = action.payload.recommendation.completed
            x.isIgnored = action.payload.recommendation.ignored

            return action.payload
          } else {
            return x
          }
        })
      }
    },
    markArchiveResourceRead: (state, action: PayloadAction<string>) => {
      if (state.careplan) {
        state.careplan.documentsArchive.map((x) => {
          if (+action.payload === x.patientEducationResourcePid) {
            x.viewed = true
            return action.payload
          } else {
            return x
          }
        })
      }
    },
    markArchiveDocumentRead: (state, action: PayloadAction<string>) => {
      if (state.careplan) {
        state.careplan.documentsArchive.map((x) => {
          if (+action.payload === x.patientWebDocumentPId) {
            x.viewed = true
            return action.payload
          } else {
            return x
          }
        })
      }
    },
    markResourceRead: (state, action: PayloadAction<string>) => {
      if (state.careplan) {
        state.careplan.pharmacistResources.map((x) => {
          if (+action.payload === x.patientEducationResourcePid) {
            x.viewed = true
            return action.payload
          } else {
            return x
          }
        })
      }
    },
    markDocumentRead: (state, action: PayloadAction<string>) => {
      if (state.careplan) {
        state.careplan.pharmacistResources.map((x) => {
          if (+action.payload === x.patientWebDocumentPId) {
            x.viewed = true
            return action.payload
          } else {
            return x
          }
        })
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCareplan.fulfilled, (state, action) => {
      state.status = 'idle'
      state.careplan = action.payload.data
    })
  },
})

export const getMedRecommendations = (state: RootState) => state.root.recommendations.careplan?.recommendationsMedications

export const getRecommendationsStatus = (state: RootState) => state.root.resources.status
export const getCareplan = (state: RootState) => state.root.recommendations.careplan
export const {
  updateMedicationRecommendation,
  updateLifestyleRecommendation,
  updateExamRecommendation,
  updateVaccineRecommendation,
  updatePharmacistRecommendation,
  markDocumentRead,
  markResourceRead,
  markArchiveResourceRead,
  markArchiveDocumentRead,
} = recommendationsSlice.actions

export default recommendationsSlice.reducer
