import dayjs from 'dayjs'
import { ScaleReading } from '../../types/Dashboards'

export const mapScaleReadingsToTableRows = (readings: ScaleReading[], isMobile: boolean) => {
  if (readings) {
    return readings.map((reading, index) => ({
      id: index,
      readingId: `${reading.patientReadingPId}`,
      readingDate: `${dayjs(reading.readingDate).format('M/DD/YYYY')}`,
      readingTime: `${dayjs(reading.readingDate).format('h:mm a')}`,
      weight: `${reading.value}`,
      change: `${reading.change}`,
    }))
  }
}
