import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import Column from '../Base/Column'
import Row from '../Base/Row'
import Typography from '../Base/Typography'

interface Props {
  modalOpen: boolean
  toggleModal: (isOpen: boolean) => void
  header: string
  body: string[] | undefined
  isMobile: boolean
}
export default function CustomModal({ modalOpen, toggleModal, header, body, isMobile }: Props) {
  const customStyles = isMobile
    ? {
        content: {
          width: '85%',
          height: 'fit-content',
          overflow: 'none',
          top: '50%',
          left: '50%',
          right: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        overlay: { zIndex: 1000 },
      }
    : {
        content: {
          width: '25%',
          height: 'fit-content',
          overflow: 'none',
          top: '50%',
          left: '50%',
          right: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
        },
        overlay: { zIndex: 1000 },
      }

  return (
    <StyledModalContainer style={{ display: modalOpen ? 'block' : 'none' }}>
      <Modal isOpen={modalOpen} onRequestClose={() => toggleModal(false)} style={customStyles} ariaHideApp={false}>
        <StyledModalHeader>
          <Typography color="white" font="bold" size={16}>
            {header}
          </Typography>
        </StyledModalHeader>
        <Column styles={{ marginBottom: 15, marginTop: 40, textAlign: 'center' }}>
          {body &&
            body.map((text, index) => (
              <Typography styles={{ textAlign: 'center' }} key={`text_${index}`}>
                {text}
              </Typography>
            ))}
        </Column>
      </Modal>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  height: 60px;
  width: 25%;
  position: fixed;
  inset: 37% auto 40px 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

const StyledModalHeader = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary1};
  position: absolute;
  margin-bottom: 40px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 20px;
  top: 0;
  left: 0;
  justify-content: space-between;
`
