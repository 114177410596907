import { useApi } from './useAPI'

export default function useCommunication(token: string) {
  const TriaApi = useApi(token)

  const postCommunication = async (values: CommunicationFormValues, callback?: Function) => {
    const data = {
      Email: values.email,
      Phone: values.phone,
      Address1: values.address1,
      Address2: values.address2,
      City: values.city,
      State: values.state,
      Zip: values.zip,
      EmailOptIn: values.emailOptIn,
      TextOptIns: values.textOptIns,
      Signature: values.signature,
    }

    const response = TriaApi.post(`api/communication`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const getCommunication = async (callback?: Function) => {
    const response = TriaApi.get(`api/communication`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  return {
    getCommunication,
    postCommunication,
  }
}

export interface CommunicationFormValues {
  email: string | undefined
  phone: string | undefined
  address1: string | undefined
  address2: string | undefined
  city: string | undefined
  state: string | undefined
  zip: string | undefined
  emailOptIn: boolean
  textOptIns: TextOptIn[]
  hasSignedConsent: boolean
  signature: string | undefined
}

export interface TextOptIn {
  programId: number
  description?: string
  optIn: boolean
}
