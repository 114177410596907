import Button from 'Components/Base/Button'
import Typography from 'Components/Base/Typography'
import { AxiosResponse } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAppointments from 'hooks/useAppointments'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import { AppointmentResponse } from 'types/Appointment'
import ConfirmImage from '../../../Assets/Images/confirm.png'

interface Props {
  messageText: string
}
export default function ConfirmForm({ messageText }: Props) {
  const [scheduledAppointment, setScheduledAppointment] = useState<string | undefined>()
  const dispatch = useAppDispatch()
  const token = useAppSelector(getAccessToken)
  const { getAppointments } = useAppointments(token!)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setLoading(true))

    getAppointments()
      .then((res: AxiosResponse<AppointmentResponse>) => {
        const { scheduledAppointment } = res.data

        scheduledAppointment && setScheduledAppointment(scheduledAppointment)

        dispatch(setLoading(false))
      })
      .catch(() => {
        dispatch(setLoading(false))
      })
  }, [])
  return (
    <div className="confirm-container">
      <div className="confirm-text">
        <h1 className="forgot-header-text" style={{ marginBottom: '10px', textAlign: 'center' }}>
          Thank You!
        </h1>
        <text style={{ marginBottom: 20 }}>{messageText}</text>

        {scheduledAppointment && <Typography>Your appointment is on:</Typography>}
        <div
          style={
            scheduledAppointment
              ? {
                  border: '2px solid transparent',
                  borderRadius: 4,
                  backgroundColor: '#edfdff',
                  boxShadow: 'inset 0px 1px 3px',
                  minHeight: 40,
                  fontSize: 20,
                  maxWidth: 380,
                  margin: 'auto',
                  marginTop: 10,
                  marginBottom: '10%',
                  padding: '10px 10px',
                }
              : { display: 'none' }
          }
        >
          <text>{scheduledAppointment}</text>
        </div>
        <div style={{ fontWeight: 'bold', marginTop: '5%', marginBottom: '3%' }}>
          <text style={{ fontWeight: 'bold', fontSize: 20 }}>Need to make a Change?</text>
          <br></br>
          <Button pageName="appointment" text="Change/Reschedule" onClick={() => navigate('/appointment?reschedule=true')}></Button>
        </div>
      </div>
      <img src={ConfirmImage} style={{ maxHeight: 700 }} className="register-banner-image" alt="Woman putting two thumbs up" />
    </div>
  )
}
