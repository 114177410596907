import Typography from 'Components/Base/Typography'
import React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
}
export default function ColumnHeader({ title }: Props) {
  return (
    <StyledContainer>
      <Typography font="bold">{title}</Typography>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  text-align: center;
`
