import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  sizes: {
    small: 500,
    medium: 800,
    large: 1000,
  },
  margin: {
    default: 20,
  },
  colors: {
    primary1: '#00677f',
    secondary1: '#B8DDE1',
    lightBlue: '#00A9CE',
    white: '#FFFFFF',
    lightGray: '#B0B0B0',
    gray: '#828282',
    darkGray: '#425563',
    black: '#000000',
    error: '#B2062E',
    status: {
      good: '#7A9A01',
      bad: '#E40046',
    },
    charts: {
      high: '#fed141',
      dangerousHigh: '#eaaa00',
      inRange: '#7A9A01',
      low: '#e40046',
      dangerousLow: '#9b2743',
    },
    forms: {
      mild: '#d4edda',
      moderate: '#ffeeba',
      high: '#FADDB8',
      severe: '#F8D7DA',
    },
    carePlan: {
      vaccinations: '#00A9CE',
      exams: '#0092BC',
      medRecommendations: '#425563',
      lifestyle: '#78A5AD',
      pharmRecommendations: '#00677F',
      history: '#4F4F4F',
    },
  },
}
