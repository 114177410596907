import { CustomTheme, createStyles, makeStyles } from '@material-ui/core'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { SectionType } from 'types/Careplan'
import { IconNames } from 'types/IconNames'
import { ArchiveDocument, Recommendation } from 'types/Recommendation'
import './SectionHeader.scss'

interface Props {
  title: string
  type: SectionType
  image: string
  color: string
  icon: IconNames
  items?: Recommendation[] | ArchiveDocument[]
  onDetailClick?: (recommendation: Recommendation) => void
}

type StyleProps = {
  color: string
}

export default function SectionHeader({ title, image, color, icon, items = [], onDetailClick }: Props) {
  const styles = useStyles({ color })
  const [sectionItems, setSectionItems] = useState<Recommendation[] | ArchiveDocument[]>([])

  useEffect(() => {
    setSectionItems(items)
  }, [items])

  return (
    <div className="careplan-header-container">
      <Icon
        name={icon}
        size="2xl"
        styles={{
          color: color,
          backgroundColor: 'white',
          marginLeft: 10,
          marginRight: 15,
          marginTop: 7,
          padding: 10,
          borderRadius: 5,
          zIndex: -1,
          float: 'left',
        }}
      />
      {title.endsWith('mendations') ? (
        <div className={styles.sectionTitleLong}>
          <Column>
            <p
              style={{
                fontFamily: 'Raleway-Semi-Bold',
                fontSize: 20,
              }}
            >
              {title.split(' ')[0]}
            </p>
            <p
              style={{
                fontFamily: 'Raleway-Semi-Bold',
                fontSize: 20,
                marginTop: -20,
              }}
            >
              {title.split(' ')[1]}
            </p>
          </Column>
        </div>
      ) : (
        <div className={styles.sectionTitle}>
          <p
            style={{
              fontFamily: 'Raleway-Semi-Bold',
              fontSize: 20,
            }}
          >
            {title}
          </p>
        </div>
      )}
      <div className={styles.rowsContainer}>
        {sectionItems.length > 0 &&
          sectionItems.map((item: any, index) => (
            <Column center className={styles.sectionColumn}>
              <div
                key={index}
                className="careplan-detail-item"
                onClick={() => {
                  onDetailClick && onDetailClick(item)
                }}
              >
                <Row
                  styles={{
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10,
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                >
                  <Row>
                    <Column
                      styles={{
                        width: '40px',
                      }}
                    >
                      {item.isCompleted ? (
                        <Icon name="check-circle" className={styles.completeIcon} />
                      ) : item.isIgnored ? (
                        <Icon name="minus-circle" className={styles.ignoredIcon} />
                      ) : (
                        <Icon name="circle" color="#828282" styles={{ marginRight: 15, height: '100%' }} />
                      )}
                    </Column>
                    <Column>
                      <Row
                        styles={{
                          textAlign: 'left',
                          width: '100%',
                        }}
                      >
                        {`${item.recommendationTitle} - ${dayjs(item.recommendationDate).format('M/D/YYYY')}`}
                      </Row>
                      <Row
                        styles={{
                          textAlign: 'left',
                        }}
                      >
                        {item.recommendationCategory}
                      </Row>
                    </Column>
                  </Row>
                  <Column>
                    <Icon
                      name={item.showDetail ? 'chevron-down' : 'chevron-right'}
                      color="#828282"
                      size={'lg'}
                      styles={{ marginRight: 15, height: '100%' }}
                    />
                  </Column>
                </Row>
              </div>
            </Column>
          ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles<CustomTheme, StyleProps>((theme) =>
  createStyles({
    sectionTitle: {
      height: 70,
      backgroundColor: ({ color }) => color,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      alignText: 'center',
      paddingTop: 25,
      paddingRight: 50,
      color: 'white',
      // whiteSpace: 'nowrap',
    },
    sectionTitleLong: {
      height: 70,
      backgroundColor: ({ color }) => color,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      alignText: 'center',
      paddingTop: 10,
      paddingRight: 50,
      color: 'white',
      whiteSpace: 'normal',
    },
    sectionTitleText: {
      color: 'white',
      float: 'left',
      marginTop: 25,
      marginLeft: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rowsContainer: {
      maxHeight: '230px',
      minHeight: '230px',
      overflowY: 'auto',
      borderLeft: `1px solid gray`,
      borderRight: `1px solid gray`,
      borderBottom: `1px solid gray`,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    sectionColumn: {
      backgroundColor: 'white',
      height: 'fit-content',
      minHeight: 75,
      borderBottom: `1px solid gray`,
      display: 'flex',
      padding: '5px 15px',
      alignItems: 'left',
    },
    completeIcon: {
      marginRight: 15,
      height: '100%',
      color: 'green',
    },
    ignoredIcon: {
      marginRight: 15,
      height: '100%',
      color: 'red',
    },
  }),
)
