import Button from 'Components/Base/Button'
import ErrorText from 'Components/Base/ErrorText'
import { StyledContainer } from 'Pages/AppointmentPage'
import { sizes } from 'Styles/sizes'
import { useFormik } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import useEnrollment from 'hooks/useEnrollment'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getProfile } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import * as Yup from 'yup'
import './IdForm.scss'

interface FormValues {
  triaId: string
}

interface Props {
  loading?: boolean
  welltrak?: string
  guid: string | undefined
  onError: () => void
  onSuccess: () => void
  onSetWelltrak: (id: string) => void
  onSetSkipCalendar: (value: boolean) => void
  setHasStop: (value: boolean) => void
}

export default function IdForm({ onError, onSuccess, loading, welltrak, guid, onSetWelltrak, onSetSkipCalendar, setHasStop }: Props) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const profile = useAppSelector(getProfile)
  const { postWelltrak } = useEnrollment()

  const [idValidated, setIdValidated] = useState(false)

  const postIdForm = (id?: string) => {
    dispatch(setLoading(true))

    if (id !== undefined) {
      onSetWelltrak(id)
    }
    postWelltrak({ enrollmentFormGuid: guid, welltrakID: id })
      .then((res) => {
        if (res.data.wellTrakFound === false) {
          onSetSkipCalendar(true)
        } else {
          onSetWelltrak(res.data.wellTrakId.toString())
          setHasStop(res.data.hasStop)
        }
        onSuccess()
      })
      .catch(() => {
        onError()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const skipWelltrak = () => {
    onSetWelltrak('0')
    onSetSkipCalendar(true)
    onSuccess()
  }

  const tempId = welltrak !== '0' ? welltrak : undefined
  const initialValues: FormValues = {
    triaId: profile?.welltrakPID ? profile.welltrakPID.toString() : tempId ? tempId : '',
  }

  const validationSchema = Yup.object().shape({
    triaId: Yup.number().typeError('ID must be a number').required('Please enter an ID'),
  })

  const onSubmit = ({ triaId }: FormValues) => {
    postIdForm(triaId)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const validateId = () => {
    formik.values.triaId.length === 10 && setIdValidated(true)
    formik.values.triaId.length < 10 && setIdValidated(false)
  }

  useEffect(() => {
    if (welltrak !== undefined) {
      formik.setFieldValue('triaId', welltrak)
    }
    setIdValidated(true)
  }, [])

  useEffect(() => {
    validateId()
  }, [formik.values.triaId])

  return (
    <StyledContainer>
      <h1 className="id-header">Do you have your Tria ID?</h1>
      <div style={{ fontSize: '25px' }}>Your Tria ID is a safe and secure method that allows us to confirm your identity.</div>
      <Form>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
            marginBottom: '10px',
            alignItems: 'center',
          }}
        >
          <StyledInput
            type="text"
            maxLength={10}
            onChange={(value) => formik.setFieldValue('triaId', value.currentTarget.value)}
            value={formik.values.triaId}
            placeholder="10-Digit Tria ID"
          />
        </div>
        {formik.errors.triaId ? <ErrorText text={formik.errors.triaId} /> : <div style={{ minHeight: '25px' }}></div>}
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <StyledButtonContainer>
            <StyledButton
              pageName="appointment_id"
              text="No Tria ID? Click Here"
              onClick={skipWelltrak}
              disabled={idValidated}
              styles={{
                backgroundColor: `${theme.colors.darkGray} !important`,
              }}
            />
            <StyledButton
              pageName="appointment_id"
              text="Let's Get Started!"
              loading={loading}
              onClick={formik.handleSubmit}
              disabled={!idValidated}
              styles={{
                backgroundColor: theme.colors.primary1,
              }}
            />
          </StyledButtonContainer>
        </div>
      </Form>
    </StyledContainer>
  )
}

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
`

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 15px;
  @media ${sizes.tablet} {
    width: 25%;
  }
`

const StyledInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  width: 100%;
  text-align: center;
  @media ${sizes.tablet} {
    width: 45%;
  }
`
