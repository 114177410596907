export const recipeLinks = [
  {
    title: 'American Heart Association',
    url: 'https://recipes.heart.org',
  },
  {
    title: 'Academy of Nutrition and Dietetics',
    url: 'http://www.eatright.org/food',
  },
  {
    title: 'Grocery List Template',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/CTL_GroceryList.pdf',
  },
  {
    title: 'Basic Nutrition Guidelines',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/CTL_Basic_Nutrition_Guidelines.pdf',
  },
  {
    title: 'Healthy for the Holidays',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/HealthyfortheHolidays.pdf',
  },
]
export const exerciseLinks = [
  {
    title: 'American Council on Exercise',
    url: 'http://acefitness.org',
  },
  {
    title: 'Lose It! Blog',
    url: 'https://loseitblog.com/exercise/',
  },
]

export const motivationLinks = [
  {
    title: 'Lose It! Facebook',
    url: 'https://www.facebook.com/loseit',
  },
  {
    title: 'Meetup',
    url: 'https://www.meetup.com/topics/weightloss/',
  },
  {
    title: 'American Council on Exercise Instagram',
    url: 'https://www.instagram.com/acefitness/',
  },
]

export const ctlLinks = [
  {
    title: 'CTL Welcome Flyer',
    url: 'https://77a061e4-6236-4f1a-acc8-15b886d599a3.usrfiles.com/ugd/77a061_4f8afd4767b1496b8952183aeb513751.pdf',
  },
  {
    title: 'Setting up you Withings scale',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/Setting%20up%20Your%20Withings%20Scale.pdf',
  },
  {
    title: 'Connecting Lose It! to the Tria App',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/Connecting%20Lose%20It%20and%20Tria%20Health_Flyer%201.pdf',
  },
  {
    title: 'Fit Index Scale Set-up',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/FitIndex%20Set-Up%20Guide.pdf',
  },
  {
    title: 'Fit Index Scale Set-up Video | Android',
    url: 'https://youtu.be/pwBOAME9Ink',
  },
  {
    title: 'Fit Index Scale Set-up Video | Apple',
    url: 'https://youtu.be/begGRXzQ6lE',
  },
  {
    title: 'How to Use the Barcode Scanner',
    url: 'https://help.loseit.com/hc/en-us/articles/115006017087-How-to-Use-the-Barcode-Scanner',
  },
  {
    title: 'How to Set a New Weight Goal',
    url: 'https://help.loseit.com/hc/en-us/articles/115007404748-How-to-Set-a-New-Weight-Goal',
  },
  {
    title: 'How to Manage Recipes:',
    url: 'https://help.loseit.com/hc/en-us/articles/115007467748-How-to-Manage-Recipes',
  },
  {
    title: 'How to Delete Food or Exercise From Log',
    url: 'https://help.loseit.com/hc/en-us/articles/115007308667-How-to-Delete-Food-or-Exercise-From-Log',
  },
  {
    title: 'How to Use Quick-Add on iOS',
    url: 'https://help.loseit.com/hc/en-us/articles/360024806674-How-to-Use-Quick-Add-on-iOS',
  },
]

export const otherLinks = [
  {
    title: 'Assessing Your Weight',
    url: 'https://www.cdc.gov/healthyweight/assessing/index.html',
  },
]
