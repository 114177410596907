import { theme } from 'Styles/styledTheme'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { BeatLoader } from 'react-spinners'
import { getAccessToken } from 'reducers/authSlice'
import styled from 'styled-components'
import { IconNames } from 'types/IconNames'
import Icon from '../Icon'

interface Props {
  children?: JSX.Element
  styles?: React.CSSProperties
  text: string
  pageName?: string
  width?: number
  onClick?: () => void
  rightIcon?: IconNames
  leftIcon?: IconNames
  link?: string
  backgroundColor?: string
  className?: string
  disabled?: boolean
  loading?: boolean
  type?: string
}

export default function Button({
  children,
  styles,
  text,
  onClick,
  leftIcon,
  rightIcon,
  link,
  width,
  pageName,
  backgroundColor,
  className,
  disabled = false,
  loading = false,
  type,
}: Props) {
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const handleClick = () => {
    onClick && onClick()
    analytics.postAnalyticsEvent({
      category: `click_button${pageName ? `_${pageName}` : ''}_${text}`,
      action: `click_button${pageName ? `_${pageName}` : ''}_${text}`,
      label: `Button`,
    })
  }
  if (width) styles = { ...styles, width: width }
  return (
    <>
      <StyledButton
        disabled={disabled}
        onClick={handleClick}
        style={{ ...styles }}
        type={type}
        className={className}
        backgroundColor={backgroundColor}
      >
        {loading ? (
          <BeatLoader size={10} color="white" />
        ) : (
          <>
            {leftIcon && (
              <span style={{ marginRight: 15 }}>
                <Icon name={leftIcon} link={link} />
              </span>
            )}
            {text}
            {rightIcon && (
              <span style={{ marginLeft: 15 }}>
                <Icon name={rightIcon} link={link} />
              </span>
            )}
          </>
        )}
        {children}
      </StyledButton>
    </>
  )
}

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 20px;
  background-color: ${(props) => props.backgroundColor ?? theme.colors.primary1};
  border: 0px;
  border-radius: 4px;
  width: ${(props) => props.backgroundColor};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary1} !important;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primary1} !important;
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.primary1} !important;
  }
`
