import { useApi } from './useAPI'

export default function useHealth() {
  const TriaApi = useApi()

  const getHealth = async () => {
    const health = TriaApi.get(`health`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return health
  }

  return {
    getHealth,
  }
}
