import CarePlanCard from 'Components/Base/CarePlanCard'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/hooks'
import useAppointments from 'hooks/useAppointments'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAccessToken } from 'reducers/authSlice'
import { getProfile } from 'reducers/profileSlice'
import { getCareplan } from 'reducers/recommendationsSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { AppointmentResponse } from 'types/Appointment'
import { WebDocument } from 'types/Careplan'
import { APPSTORE_URL, PLAYSTORE_URL } from '../../../../constants'

interface Props {
  filterMeds
  setDropValue
  onGetDocument: (fileName: string, documentId: string, documentLink: string) => void
  isMobile: boolean
  sideMenuToggled: () => void
  totalMedRecommendations: number
}
export default function CareplanHomeScreen({ onGetDocument, isMobile, sideMenuToggled, filterMeds, setDropValue, totalMedRecommendations }: Props) {
  const [scheduledAppointment, setScheduledAppointment] = useState<string | undefined>()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Care Plan')

  const careplan = useAppSelector(getCareplan)
  const theme = useTheme()
  const token = useAppSelector(getAccessToken)
  const { getAppointments } = useAppointments(token!)
  const profile = useAppSelector(getProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    getAppointments()
      .then((res: AxiosResponse<AppointmentResponse>) => {
        const { canSchedule, scheduledAppointment } = res.data
        if (canSchedule === false) {
          scheduledAppointment && setScheduledAppointment(scheduledAppointment)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }, [])

  const unreadPharmacistResourcesCount = careplan?.pharmacistResources.filter((x) => x.viewed === false).length ?? 0

  const unreadMessageCount = careplan?.allRecommendations.filter((x) => x.isCompleted === false && x.isIgnored === false).length ?? 0

  const carePlanPdf = careplan?.pharmacistResources.find((rec) => rec.documentName.toLowerCase().includes('patient care plan'))

  const medCardPdf = careplan?.pharmacistResources.find((rec) => rec.documentName.toLowerCase().includes('med card'))

  const prescriptionSavingsCardPdf = careplan?.documentsArchive.find((rec) => rec.documentName.toLowerCase() === 'prescription savings card')
  const CTLSavingsCardPdf = careplan?.documentsArchive.find((rec) => rec.documentName.toLowerCase() === 'ctl prescription savings card')

  const quickAccessArray1 = [carePlanPdf, medCardPdf, prescriptionSavingsCardPdf, CTLSavingsCardPdf]
  const quickAccessArray: WebDocument[] = []

  quickAccessArray1.map((d) => {
    if (d) {
      return quickAccessArray.push(d)
    }
  })

  const quickAccessLength = quickAccessArray.filter((doc) => doc !== undefined).length

  function filterUponClick() {
    setDropValue('all')
    filterMeds('all')
  }

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title="Care Plan Home" icon="home" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <StyledCardContainer>
        <CarePlanCard
          icon="external-link"
          number={unreadMessageCount}
          title={'Open Care Plan'}
          titleLine2={'Recommendations'}
          route={'/careplan/recommendations'}
          badgeNumber={
            window.sessionStorage.getItem('visitedRecs') === 'true' ? 0 : careplan?.allRecommendations.filter((x) => x.viewed === false).length ?? 0
          }
        />
        <div onClick={filterUponClick} style={isMobile ? { width: '100%' } : { width: '30%' }}>
          <CarePlanCard
            icon="external-link"
            number={totalMedRecommendations}
            title={'Medication'}
            titleLine2={'Recommendations'}
            route={'/careplan/medications'}
          />
        </div>
        <CarePlanCard
          icon="external-link"
          number={unreadPharmacistResourcesCount}
          title={'New Resources'}
          titleLine2={'From Your Pharmacist'}
          route={'/careplan/resources'}
          badgeNumber={unreadPharmacistResourcesCount}
        />
      </StyledCardContainer>
      <StyledCardContainer>
        <StyledQuickAccess>
          <div style={{ borderBottom: '1px solid #bdbdbd', width: '90%', margin: 'auto' }}>
            <Typography color={theme.colors.primary1} size={25} variant="h3">
              Quick Access
            </Typography>
          </div>
          {(carePlanPdf || medCardPdf) &&
            quickAccessArray.map(
              (row, index) =>
                row && (
                  <div
                    style={
                      index % 2 === 0
                        ? {
                            backgroundColor: '#ffffff',
                            width: '93%',
                            margin: 'auto',
                            padding: '10px',
                          }
                        : {
                            backgroundColor: '#f2f2f2',
                            width: '93%',
                            margin: 'auto',
                            padding: '10px',
                          }
                    }
                    className="careplan-detail-document-item"
                    onClick={() =>
                      row && onGetDocument(row.documentName ?? 'Care Plan Document', row.patientWebDocumentPId.toString(), row.documentLink ?? null)
                    }
                  >
                    <Row
                      styles={{
                        width: '99%',
                        margin: 'auto',
                        cursor: 'pointer',
                      }}
                    >
                      <Row>
                        <Column>
                          <Row
                            styles={{
                              textAlign: 'left',
                              width: '100%',
                            }}
                          >
                            <Typography variant="h3" size={15}>{`${row?.documentName}`}</Typography>
                          </Row>
                          <Row
                            styles={{
                              textAlign: 'left',
                              width: '100%',
                            }}
                          >
                            <Typography>{`Updated ${dayjs(row?.documentDate).format('M/D/YYYY')}`}</Typography>
                          </Row>
                        </Column>
                      </Row>
                      <Column styles={{ marginLeft: 'auto', marginTop: 4 }}>
                        {!row?.documentLink ? (
                          <Icon name={'file-pdf'} color={theme.colors.primary1} size={'2xl'} />
                        ) : (
                          <Icon name={'external-link'} color="#828282" size={'2xl'} />
                        )}
                      </Column>
                    </Row>
                  </div>
                ),
            )}
          {profile?.incentives ? (
            <div
              style={
                quickAccessLength % 2 === 0
                  ? {
                      backgroundColor: '#ffffff',
                      width: '93%',
                      margin: 'auto',
                      padding: '10px',
                    }
                  : {
                      backgroundColor: '#f2f2f2',
                      width: '93%',
                      margin: 'auto',
                      padding: '10px',
                    }
              }
            >
              <a href="https://triahealth.myprepaidcenter.com/home" target={'_blank'} rel="noreferrer">
                <Row
                  styles={{
                    width: '99%',
                    margin: 'auto',
                    cursor: 'pointer',
                  }}
                >
                  <Row>
                    <Column>
                      <Row
                        styles={{
                          textAlign: 'left',
                          width: '100%',
                        }}
                      >
                        <Typography variant="h3" size={15}>
                          Gift Card Redemption
                        </Typography>
                      </Row>
                      <Row
                        styles={{
                          textAlign: 'left',
                          width: '100%',
                        }}
                      >
                        <Typography>{`Updated ${dayjs(profile?.incentives.insertDate).format('M/D/YYYY')}`}</Typography>
                      </Row>
                    </Column>
                  </Row>
                  <Column styles={{ marginLeft: 'auto', marginTop: 4 }}>
                    <Icon name={'external-link'} color={theme.colors.primary1} size={'2xl'} />
                  </Column>
                </Row>
              </a>
            </div>
          ) : (
            <></>
          )}
        </StyledQuickAccess>
      </StyledCardContainer>
      <StyledProfileCardContainer>
        <StyledProfileCard>
          <Row styles={{ margin: 'auto', paddingTop: 20, marginRight: '10px' }}>
            {!isMobile && (
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Profile.png"
                alt="profile"
                style={{
                  width: '80px',
                  height: '80px',
                  marginTop: '5px',
                  marginLeft: '12px',
                }}
              />
            )}
            <Column styles={{ marginLeft: '20px', marginRight: 'auto' }}>
              {profile?.firstName && <Typography variant="h3">{profile?.firstName}</Typography>}
              {profile?.lastName && <Typography variant="h3">{profile?.lastName}</Typography>}

              {profile?.welltrakPID && <Typography>{`Tria Id: ${profile?.welltrakPID}`}</Typography>}
            </Column>
            <Column>
              <div>
                <Typography variant="h5">Next Appointment:</Typography>
              </div>
              {scheduledAppointment && <Typography>{scheduledAppointment.split('|')[0]}</Typography>}
              {!scheduledAppointment && (
                <>
                  <Typography size={14}>We will contact you when</Typography>
                  <Typography size={14}>it's time to schedule your</Typography>
                  <Typography size={14}>next appointment.</Typography>
                </>
              )}
            </Column>
          </Row>
        </StyledProfileCard>
        <StyledProfileCard>
          <Row>
            {!isMobile && (
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Hands%20Holding%20Phone.png"
                alt="profile"
                style={{
                  width: '140px',
                  height: '110px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  border: '1px black solid',
                }}
              />
            )}
            <Column styles={isMobile ? { marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' } : { marginLeft: '20px' }}>
              <Typography variant="h3" styles={{ marginTop: '6px' }}>
                Download Our Mobile App!
              </Typography>
              <Typography size={14}>Access Tria Health on-the-go!</Typography>
              <Typography size={14}>You’ll be able to view your care</Typography>
              <Typography size={14}>plan, medication list and more!</Typography>
              <Row>
                <Icon
                  name={'play'}
                  link={PLAYSTORE_URL}
                  className="social-icon"
                  color="#ffffff"
                  size="1x"
                  styles={{
                    cursor: 'pointer',
                    backgroundColor: '#000000',
                    borderRadius: 5,
                    padding: 3.5,
                    marginTop: 1,
                  }}
                />
                <Icon
                  name={'ios'}
                  link={APPSTORE_URL}
                  className="social-icon"
                  color="#000000"
                  size="xl"
                  styles={{
                    cursor: 'pointer',
                    marginLeft: 15,
                    backgroundColor: '#ffffff',
                  }}
                />
              </Row>
            </Column>
          </Row>
        </StyledProfileCard>
      </StyledProfileCardContainer>
    </>
  )
}
const StyledCardContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    display: flex;
    flex-direction: row;
  }
`
const StyledQuickAccess = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  margin-top: 5%;
  position: relative;
  padding-top: 15px;
  overflow-y: scroll;
  @media only screen and (min-width: 1100px) {
    margin-left: 1.95%;
    height: 300px;
    width: 47%;
  }

  @media only screen and (max-width: 1100px) {
    height: 270px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`
const StyledProfileCard = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  @media only screen and (min-width: 1100px) {
    height: 133px;
    width: 96.5%;
    margin-top: 32px;
  }

  @media only screen and (max-width: 1100px) {
    height: 200px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`
const StyledProfileCardContainer = styled.div`
  @media only screen and (min-width: 1100px) {
    display: flex;
    flex-direction: column;
    margin-left: 51%;
    margin-top: -330px;
  }
`
