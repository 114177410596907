import { StyledPageContainer } from 'Styles/global'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Params, useParams } from 'react-router-dom'
import { GAD7FormValues } from 'types/Forms'
import Checkbox from '../../../Components/Base/Checkbox'
import Row from '../../../Components/Base/Row'
import Typography from '../../../Components/Base/Typography'
import colors from '../../../Styles/colors'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import usePatientAssessment from '../../../hooks/usePatientAssessment'
import ContentHeader from '../../PatientProfile/components/ContentHeader'
import CalculationRow from '../components/CalculationRow'
import ColumnHeader from '../components/ColumnHeader'
import SurveyRow from '../components/SurveyRow'
import { gad7Questions } from '../contants'

export default function GAD7Form() {
  const { guid }: Readonly<Params<string>> = useParams()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('GAD-7')

  const [score, setScore] = useState<number>(0)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [gettingScore, setGettingScore] = useState(false)

  const scoreRef = useRef<null | HTMLDivElement>(null)

  const executeScoreScroll = () => scoreRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const { getGAD7Score } = usePatientAssessment()

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const initialValues: GAD7FormValues = {
    feelNervous: 0,
    controlWorry: 0,
    worryTooMuch: 0,
    troubleRelaxing: 0,
    restless: 0,
    easilyAnnoyed: 0,
    feelAfraid: 0,
    difficulty: 0,
  }

  const submitGAD7Form = (values: GAD7FormValues) => {
    setGettingScore(true)

    values.guid = guid

    executeScoreScroll()
    getGAD7Score(values, (response: any) => {
      setScore(response.total)
    }).then(() => setGettingScore(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => submitGAD7Form(values)}>
      {({ values, setFieldValue, submitForm }) => {
        return (
          <StyledPageContainer>
            <ContentHeader title="GAD-7" color={colors.primary.blue} />
            <p className="survey-title">Please check the ONE best answer for your abilities at this time.</p>
            <div className="survey-banner">Over the last 2 weeks, how often have you been bothered by the following problems?</div>
            {!isMobile && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <div></div>
                <Row styles={{ width: '68%', justifyContent: 'space-between' }}>
                  <ColumnHeader title="Not at All" />
                  <ColumnHeader title="Several Days" />
                  <ColumnHeader title="More than Half the Days" />
                  <ColumnHeader title="Nearly Every Day" />
                </Row>
              </Row>
            )}

            {gad7Questions.map((row) => (
              <SurveyRow
                question={row.question}
                selectedOption={values[row.fieldValue] ?? 0}
                isMobile={isMobile}
                onSelectOption={(value) => {
                  setFieldValue(row.fieldValue, value)
                }}
              />
            ))}
            {!isMobile && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <div></div>
                <Row styles={{ width: '68%', justifyContent: 'space-between' }}>
                  <ColumnHeader title="Not difficult" />
                  <ColumnHeader title="Somewhat difficult" />
                  <ColumnHeader title="Very difficult" />
                  <ColumnHeader title="Extremely difficult" />
                </Row>
              </Row>
            )}
            <Row className="survey-row" styles={{ justifyContent: 'space-between' }}>
              <div className="survey-question-column">
                <Typography font="semi-bold">
                  {`If you ${isMobile ? 'selected' : 'checked'} any problems, how difficult have they made it for you
            to do your work, take care of things at home, or get along with
            other people?`}
                </Typography>
              </div>
              <Row
                styles={{
                  width: '68%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {isMobile ? (
                  <div style={{ width: '100%' }}>
                    <Form.Control
                      as="select"
                      className="contact-dropdown-input"
                      value={values.difficulty}
                      onChange={(value) => setFieldValue('difficulty', value)}
                    >
                      <option value={0}>Not difficult</option>
                      <option value={1}>Somewhat difficult</option>
                      <option value={2}>Very difficult</option>
                      <option value={3}>Extremely difficult</option>
                    </Form.Control>
                  </div>
                ) : (
                  <>
                    <div className="survey-row-cell">
                      <Checkbox checked={values.difficulty === 0} onClick={() => setFieldValue('difficulty', 0)} />
                    </div>
                    <div className="survey-row-cell">
                      <Checkbox checked={values.difficulty === 1} onClick={() => setFieldValue('difficulty', 1)} />
                    </div>
                    <div className="survey-row-cell">
                      <Checkbox checked={values.difficulty === 2} onClick={() => setFieldValue('difficulty', 2)} />
                    </div>
                    <div className="survey-row-cell">
                      <Checkbox checked={values.difficulty === 3} onClick={() => setFieldValue('difficulty', 3)} />
                    </div>
                  </>
                )}
              </Row>
            </Row>
            <CalculationRow
              onSubmit={submitForm}
              gettingScore={gettingScore}
              scoreRef={scoreRef}
              score={score}
              onReset={() => {
                setFieldValue('feelNervous', 0)
                setFieldValue('controlWorry', 0)
                setFieldValue('worryTooMuch', 0)
                setFieldValue('troubleRelaxing', 0)
                setFieldValue('restless', 0)
                setFieldValue('easilyAnnoyed', 0)
                setFieldValue('feelAfraid', 0)
                setFieldValue('difficulty', 0)
                setScore(0)
              }}
            />
          </StyledPageContainer>
        )
      }}
    </Formik>
  )
}
