import { theme } from 'Styles/styledTheme'
import React, { useState } from 'react'
import styled from 'styled-components'

interface Props {
  side1: React.ReactNode
  side2: React.ReactNode
  isMobile: boolean
}

export default function FlipCard({ side1, side2, isMobile }: Props) {
  const [flip, setFlip] = useState<boolean>(false)
  return isMobile ? (
    <StyledCard
      onTouchStart={() => setFlip(true)}
      onTouchEnd={() => setFlip(false)}
      style={flip ? { backgroundColor: theme.colors.primary1, padding: '20px 30px' } : {}}
    >
      {flip ? side1 : side2}
    </StyledCard>
  ) : (
    <StyledCard
      onMouseEnter={() => setFlip(true)}
      onMouseLeave={() => setFlip(false)}
      style={flip ? { backgroundColor: theme.colors.primary1, padding: '20px 30px' } : {}}
    >
      {flip ? side1 : side2}
    </StyledCard>
  )
}

export const StyledCard = styled.div`
  width: 240px;
  height: 188px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid #bdbdbd;
  margin: 20px auto;
  text-align: center;
`
