import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { sizes } from 'Styles/sizes'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Image1 from '../../../Assets/Images/Reimbursement_1.png'
import Image2 from '../../../Assets/Images/Reimbursement_2.png'
import Image3 from '../../../Assets/Images/Reimbursement_3.png'
import Image4 from '../../../Assets/Images/Reimbursement_4.png'
import Typography from '../../../Components/Base/Typography'
import '../ReimbursementPage.scss'

export default function AutoReimbursement() {
  const navigate = useNavigate()

  function navigateToReimbursement() {
    navigate('/reimbursement/true')
  }

  return (
    <div className="form-container" style={{ marginTop: 55 }}>
      <StyledTypography font="bold">Auto-Reimbursement</StyledTypography>
      <Typography styles={{ marginBottom: 20 }}>
        Your employer is currently a part of Tria Health’s auto-reimbursement program. Previously, if you or your pharmacy could not take advantage of
        Tria Health's discount, you had to fill out our reimbursement form. Tria Health is happy to introduce our improved reimbursement process.
      </Typography>
      <Typography font="bold" variant="h3">
        How it Works:
      </Typography>

      <StyledResponsiveContainer>
        <Column styles={{ alignItems: 'center' }}>
          <StyledImage src={Image1} alt="Woman handing receipt to pharmacist" />
          <Typography styles={{ width: 150 }}>1. You request your medication(s) from your pharmacy.</Typography>
        </Column>
        <Column styles={{ alignItems: 'center' }}>
          <StyledImage src={Image2} alt="Illustration of a receipt" />
          <Typography styles={{ width: 150 }}>
            2. This request produces a prescription claim, which is then securely shared with Tria Health.
          </Typography>
        </Column>
        <Column styles={{ alignItems: 'center' }}>
          <StyledImage src={Image3} alt="Illustration of a receipt with red X" />
          <Typography styles={{ width: 150 }}>3. Tria Health can see whether the pharmacy was able to apply our discount.</Typography>
        </Column>
        <Column styles={{ alignItems: 'center' }}>
          <StyledImage src={Image4} alt="Illustration of a check" />
          <Typography styles={{ width: 150 }}>4. If the discount was NOT applied, we’ll calculate your discount and mail you a check!</Typography>
        </Column>
      </StyledResponsiveContainer>
      <Typography font="bold" variant="h3">
        Reimbursement Timing:
      </Typography>
      <Typography styles={{ marginBottom: 15 }}>
        Please note, it can take 60-90 days for Tria Health to process your auto-reimbursement. The new process is automatic and will not require you
        to submit anything! For more immediate savings, you can proceed with submitting the reimbursement form and receive your savings within 14-30
        days.
      </Typography>
      <StyledReimbursementButton pageName="reimbursement" onClick={navigateToReimbursement} text={'Reimbursement Form'} />
    </div>
  )
}

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  justify-content: space-between;
  margin-bottom: 25px;
`

const StyledTypography = styled(Typography)`
  font-size: 30px;
  margin-bottom: 15px;
  @media ${sizes.tablet} {
    font-size: 50px;
  }
`

const StyledImage = styled.img`
  height: 250px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  @media ${sizes.tablet} {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledReimbursementButton = styled(Button)`
  width: 100%;
  margin-right: 1rem;
  border: 0px;
  background-color: ${({ theme }) => theme.colors.primary1};
  border-radius: 4px;
  @media ${sizes.tablet} {
    width: 250px;
  }
`
