import React from 'react'
import styled from 'styled-components'
import Typography from '../../../Components/Base/Typography'

interface Props {
  title: string
  titleSpanish?: string
  url: string
  spanishUrl?: string
}
export default function ResourceLinkItem({ title, url, spanishUrl, titleSpanish }: Props) {
  // if (spanishUrl && titleSpanish) {
  //   return (
  //     <>
  //       <Typography
  //         font="semi-bold"
  //         color="primary"
  //         styles={{ fontFamily: 'Raleway', marginBottom: 10 }}
  //       >
  //         <>
  //           {/* <a href={url} target="_blank" rel="noreferrer">{title}</a> -{' '}<a href={spanishUrl} target="_blank" rel="noreferrer">{titleSpanish}</a> */}
  //           {title} - {' '}
  //           <StyledLink>
  //             <a href={url} target="_blank" rel="noreferrer">
  //               English
  //             </a>
  //           </StyledLink>{' '}
  //           |{' '}
  //           <StyledLink>
  //             <a href={spanishUrl} target="_blank" rel="noreferrer">
  //               Spanish
  //             </a>
  //           </StyledLink>
  //         </>
  //       </Typography>
  //     </>
  //   )
  // } else {
  return (
    <a href={url} target="_blank" style={{ fontFamily: 'Raleway', marginBottom: 10 }} rel="noreferrer">
      <Typography color={'primary'} font="semi-bold" size={16}>
        {title}
      </Typography>
    </a>
  )
  //}
}

const StyledLink = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary1} !important;
`
