import { CustomTheme, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import './GridCard.scss'

interface Props {
  title: string
  text: string
  value: string
  goal: string
  span: number
  styles?: React.CSSProperties
  className?: string
}
export default function GMICard({ title, text, value, goal, span }: Props) {
  const classStyles = useStyles()

  return (
    <div className={classStyles.cardContainer} style={{ gridColumn: `span ${span}` }}>
      <div className={classStyles.cardColumn} style={{ width: '40%' }}>
        <text className={classStyles.title}>{value}</text>
        <text className={classStyles.subtitle}>Goal: {goal}</text>
      </div>
      <div className={classStyles.cardColumn} style={{ width: '60%', textAlign: 'right', paddingRight: 35 }}>
        <div style={{ borderBottom: '1px solid black', marginBottom: 15 }}>
          <text className={classStyles.definitionTitle}>{title}</text>
        </div>
        <text>{text}</text>
      </div>
    </div>
  )
}

const useStyles = makeStyles<CustomTheme>((theme) =>
  createStyles({
    cardContainer: {
      height: '100%',
      display: 'flex',
      gridColumn: 'span 1',
      padding: 10,
      minWidth: 200,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: '1px solid #bdbdbd',
      borderRadius: 10,
      marginBottom: 25,
      position: 'relative',
      [theme.breakpoints.down(900)]: {
        gridColumn: 'span 3 !important',
      },
    },
    cardColumn: {
      textAlign: 'center',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down(469)]: {
        top: '38%',
      },
    },
    title: {
      fontSize: '2.5vw',
      fontFamily: 'Raleway-Semi-Bold',
      [theme.breakpoints.down(900)]: {
        fontSize: '4vw',
      },
      [theme.breakpoints.down(469)]: {
        fontSize: '10vw',
      },
    },
    subtitle: {
      fontSize: '1vw',
      fontFamily: 'Raleway-Semi-Bold',
      [theme.breakpoints.down(900)]: {
        fontSize: '4vw',
      },
      [theme.breakpoints.down(469)]: {
        fontSize: '10vw',
      },
      [theme.breakpoints.down(469)]: {
        fontSize: '6vw',
      },
    },
    definitionTitle: {
      fontSize: '1vw',
      [theme.breakpoints.down(900)]: {
        fontSize: '3vw',
      },
    },
  }),
)
