import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import Row1Image from '../../../../Assets/Images/STOP/Asthma.png'
import Row2Image from '../../../../Assets/Images/STOP/COPD.png'

export default function HealthConditions1() {
  return (
    <>
      <STOPHeader>What Health Conditions Benefit the Most from Quitting Tobacco?</STOPHeader>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row1Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Asthma
          </ScaledText>
          <ScaledText>
            Tobacco smoke is a common trigger for asthma. Tobacco smoke, including secondhand smoke, is unhealthy for everyone, especially people with
            asthma.
          </ScaledText>
        </Column>
      </ResponsiveContainer>
      <ResponsiveContainer styles={{ marginBottom: 25, marginTop: 25 }}>
        <StyledImage src={Row2Image} alt={'altTag'} />
        <Column>
          <ScaledText size={30} styles={{ textDecoration: 'underline' }}>
            Chronic Obstructive Pulmonary Disease (COPD):
          </ScaledText>
          <ScaledText>
            COPD is most commonly caused by smoking tobacco. Long-term exposure to other lung irritants, like secondhand smoke, can also lead to COPD.
            Smoking accounts for nearly 8 out of 10 COPD-related deaths. Of the nearly 16 million American adults diagnosed with COPD, 38% are still
            currently smoking.
          </ScaledText>
          <div style={{ marginTop: 15 }}>
            <ScaledText>
              Smoking and secondhand smoke exposure during childhood and teenage years can slow lung growth and development. Smoke exposure at a young
              age increases the risk of developing COPD in adulthood.
            </ScaledText>
          </div>
        </Column>
      </ResponsiveContainer>
    </>
  )
}

const StyledImage = styled.img`
  object-fit: cover;
  height: 15vh;
  border: 1px solid black;
  margin-bottom: 15px;
  @media (${sizes.tablet}) {
    height: 25vh;
    margin-right: 25px;
  }
`
