import { StyledPageContainer } from 'Styles/global'
import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Params, useParams } from 'react-router-dom'
import { HBIFormValues } from 'types/Forms'
import Checkbox from '../../../Components/Base/Checkbox'
import Row from '../../../Components/Base/Row'
import Typography from '../../../Components/Base/Typography'
import colors from '../../../Styles/colors'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import usePatientAssessment from '../../../hooks/usePatientAssessment'
import ContentHeader from '../../PatientProfile/components/ContentHeader'
import CalculationRow from '../components/CalculationRow'
import './HBI.scss'

export default function HBIForm() {
  const { guid }: Readonly<Params<string>> = useParams()
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('HBI')

  const [score, setScore] = useState<number>(0)
  const scoreRef = useRef<null | HTMLDivElement>(null)
  const [gettingScore, setGettingScore] = useState(false)

  const { getHBIScore } = usePatientAssessment()

  // eslint-disable-next-line prettier/prettier
  const executeScoreScroll = () => scoreRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const initialValues: HBIFormValues = {
    wellbeing: '',
    abdominalPain: '',
    liquidBM: 0,
    abdominalMass: 0,
    arthralgia: false,
    uveitis: false,
    erythemaNodosum: false,
    aphthousUlcer: false,
    pyodermaGangrenosum: false,
    analFissure: false,
    newFistula: false,
    abscess: false,
    none: true,
  }

  const submitHBIForm = (values: HBIFormValues) => {
    setGettingScore(true)
    values.guid = guid

    executeScoreScroll()
    getHBIScore(values, (response: any) => {
      setScore(response.total)
    }).then(() => setGettingScore(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => submitHBIForm(values)}>
      {({ values, setFieldValue, submitForm }) => {
        return (
          <StyledPageContainer>
            <ContentHeader title="Harvey Bradshaw Index (HBI)" color={colors.neutral.black} />
            <p style={{ fontFamily: 'Raleway', fontSize: 25 }}>Base your answers on how you felt yesterday. </p>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">General Wellbeing</Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.wellbeing}
                  onChange={(value) => setFieldValue('wellbeing', +value.currentTarget.value)}
                >
                  <option value={''} disabled>
                    Choose One
                  </option>
                  <option value={0}>Very Well</option>
                  <option value={1}>Slightly Below Par</option>
                  <option value={2}>Poor</option>
                  <option value={3}>Very Poor</option>
                  <option value={4}>Terrible</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">Abdominal Pain</Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.abdominalPain}
                  onChange={(value) => setFieldValue('abdominalPain', +value.currentTarget.value)}
                >
                  <option value={''} disabled>
                    Choose One
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Mild</option>
                  <option value={2}>Moderate</option>
                  <option value={3}>Severe</option>
                </Form.Control>
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">Number of Liquid or Soft Stools</Typography>
              </div>
              <div className="sc-column">
                <input
                  value={values.liquidBM}
                  className="hbi-input"
                  min={0}
                  style={{
                    width: '100%',
                    paddingLeft: 15,
                    paddingRight: 15,
                    minHeight: 50,
                  }}
                  type="number"
                  onChange={(e) => {
                    setFieldValue('liquidBM', e.currentTarget.value)
                  }}
                />
              </div>
            </div>
            <div className="sc-form-row">
              <div className="sc-column">
                <Typography font="semi-bold">Abdominal Mass Present?</Typography>
              </div>
              <div className="sc-column">
                <Form.Control
                  as="select"
                  className="contact-dropdown-input"
                  value={values.abdominalMass}
                  onChange={(value) => setFieldValue('abdominalMass', +value.currentTarget.value)}
                >
                  <option value={''} disabled>
                    Choose One
                  </option>
                  <option value={0}>None</option>
                  <option value={1}>Dubious (suspect)</option>
                  <option value={2}>Definite</option>
                  <option value={3}>Definite and Tender</option>
                </Form.Control>
              </div>
            </div>
            <Row
              className="survey-row"
              styles={{
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 25,
              }}
            >
              <div className="survey-question-column" style={{ width: '100%', marginBottom: 10 }}>
                <Typography font="semi-bold">{`Do you have any of the following additional symptoms?`}</Typography>
              </div>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.none}
                onClick={() => {
                  setFieldValue('none', !values.none)
                  if (values.none === false) {
                    setFieldValue('arthralgia', false)
                    setFieldValue('uveitis', false)
                    setFieldValue('erythemaNodosum', false)
                    setFieldValue('pyodermaGangrenosum', false)
                    setFieldValue('aphthousUlcer', false)
                    setFieldValue('analFissure', false)
                    setFieldValue('abscess', false)
                    setFieldValue('newFistula', false)
                  }
                }}
              />
              <Typography font="semi-bold">{`None`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.arthralgia}
                onClick={() => {
                  setFieldValue('arthralgia', !values.arthralgia)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Arthralgia (painful, red or swollen joints; the most commonly
                affected joints are knees, ankles, or toes)`}</Typography>
            </Row>

            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.uveitis}
                onClick={() => {
                  setFieldValue('uveitis', !values.uveitis)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Uveitis (sore, red or swollen, blurry vision, sensitivity to
                light, floaters or increased tear production) *NOTE: If you have
                a painful red eye or if you have been diagnosed with uveitis,
                episcleritis or scleritis and recognize a flare of your
                symptoms, seek medical help urgently.`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.erythemaNodosum}
                onClick={() => {
                  setFieldValue('erythemaNodosum', !values.erythemaNodosum)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Erythema Nodosum (tender, hot and red bumps which most often
                affect the skin on the shins, arms, and legs)`}</Typography>
            </Row>

            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.pyodermaGangrenosum}
                onClick={() => {
                  setFieldValue('pyodermaGangrenosum', !values.pyodermaGangrenosum)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Pyoderma gangrenosum (deep, purple ulcers, often painful, which
                usually develop suddenly)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.aphthousUlcer}
                onClick={() => {
                  setFieldValue('aphthousUlcer', !values.aphthousUlcer)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Aphthous Ulcer (frequent canker sore, round or oval mouth ulcers
                which usually appear as round yellowish spots surrounded by a
                red halo)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.analFissure}
                onClick={() => {
                  setFieldValue('analFissure', !values.analFissure)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Anal Fissure (tears or breakdown (crack/cleft) in the skin of
                the anus)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.abscess}
                onClick={() => {
                  setFieldValue('abscess', !values.abscess)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`Abscess (tender lumps surrounding the anus)`}</Typography>
            </Row>
            <Row className="sc-checkbox-row">
              <Checkbox
                styles={{ marginRight: 10 }}
                checked={values.newFistula}
                onClick={() => {
                  setFieldValue('newFistula', !values.newFistula)
                  setFieldValue('none', false)
                }}
              />
              <Typography font="semi-bold">{`New Fistula (frequent abscess that does not heal, foul smelling
                drainage from an opening around the anus)`}</Typography>
            </Row>

            <CalculationRow
              onSubmit={submitForm}
              gettingScore={gettingScore}
              scoreRef={scoreRef}
              score={score}
              onReset={() => {
                setScore(0)
                setFieldValue('wellbeing', '')
                setFieldValue('abdominalPain', '')
                setFieldValue('liquidBM', 0)
                setFieldValue('abdominalMass', '')
                setFieldValue('arthralgia', false)
                setFieldValue('uveitis', false)
                setFieldValue('erythemaNodosum', false)
                setFieldValue('pyodermaGangrenosum', false)
                setFieldValue('aphthousUlcer', false)
                setFieldValue('analFissure', false)
                setFieldValue('abscess', false)
                setFieldValue('newFistula', false)
                setFieldValue('none', true)
              }}
            />
          </StyledPageContainer>
        )
      }}
    </Formik>
  )
}
