import dayjs from 'dayjs'
import { PatientGlucoseReading } from '../../types/Dashboards'

export const mapReadingsToTableRows = (readings: PatientGlucoseReading[], isMobile: boolean) => {
  if (readings) {
    return readings.map((reading, index) => ({
      id: index,
      readingId: `${reading.patientReadingPId}`,
      readingDate: `${dayjs(reading.readingDate).format('M/DD/YYYY')}`,
      readingTime: `${dayjs(reading.readingDate).format('h:mm a')}`,
      bloodGlucose: `${reading.value}`,
      readingType: `${reading.eventType ? reading.eventType : ''}`,
      mealType: `${reading.meal ? reading.meal : ''}`,
      carbCount: `${reading.carbCount ? reading.carbCount : ''}`,
      insulinDosage: `${reading.insulinDosage ? reading.insulinDosage : ''}`,
      notes: `${reading.notes ? reading.notes : ''}`,
      patientReadingLevel: `${reading.patientReadingLevel}`,
    }))
  }
}
