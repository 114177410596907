import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Gum 1.png'
import Image2 from '../../../../Assets/Images/STOP/Gum 2.png'
import Image3 from '../../../../Assets/Images/STOP/Gum 3.png'

export default function NRTGum() {
  return (
    <ProConScreen
      title="NRT Products: Gum"
      subtitle="Gum contains a small amount of nicotine that is absorbed through the lining of the mouth. It is not chewed like ordinary gum. It is chewed a few times then “parked” between cheek and gum to be absorbed."
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Available over the counter (OTC) in multiple strengths',
        'Quick acting',
        'Aids in control of sudden cravings/withdrawal symptoms',
        'Serves as an oral substitute for tobacco',
        'Can be used in combination with other NRT products and medications',
        'May help delay weight gain associated with quitting tobacco',
      ]}
      cons={[
        'Must be used repeatedly throughout the day to control cravings or withdrawal symptoms',
        'May cause mouth irritation, hiccups, heartburn, or nausea',
        'Cannot eat 15 minutes before use',
        'May be difficult to use with dentures or dental work',
      ]}
    />
  )
}
