import { useApi } from './useAPI'
import qs from 'querystring'

export default function useChallenges(token: string) {
  const TriaApi = useApi(token)

   const getChallenges = async () => {
    const challenges = TriaApi.get(`api/challenges`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return challenges
  }

  const addPatientSections = async (challengePid, patientChallengePid, callback?: Function) => {

    const data = qs.stringify({
      ChallengePid: challengePid,
      PatientChallengePid: patientChallengePid,
    })


    const score = TriaApi.post(`api/challenges/add`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((resp) => {
       return callback && callback(resp.data)
    })

    return score
  }

  const addPatientReflection = async (challengeSectionPId, question, reflection, callback?: Function) => {

    const data = qs.stringify({
      ChallengeSectionPid: challengeSectionPId,
      Question: question,
      Reflection: reflection
    })


    const score = TriaApi.post(`api/challenges/reflection`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((resp) => {
       return callback && callback(resp.data)
    })

    return score
  }

  const changeSectionStep = async (challengeSectionPId, currentStep, callback?: Function) => {

    const data = qs.stringify({
      ChallengeSectionPid: challengeSectionPId,
      CurrentStep: currentStep
    })


    const score = TriaApi.post(`api/challenges/updateSectionProgress`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((resp) => {
       return callback && callback(resp.data)
    })

    return score
  }

  const challengeCompletion = async (challengePid, callback?: Function) => {

    const data = qs.stringify({
      ChallengePid: challengePid
    })


    const score = TriaApi.post(`api/challenges/updateChallenge`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((resp) => {
       return callback && callback(resp.data)
    })

    return score
  }

  return { getChallenges, addPatientSections, addPatientReflection, changeSectionStep, challengeCompletion }
}
