import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import ListItem from 'Pages/STOPProgram/components/ListItem'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Cessation Tools.png'

export default function Obstacle3() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Obstacle #3:</STOPHeader>
          <STOPHeader>Fear of Withdrawal Symptoms</STOPHeader>
          <Spacer top>
            <ul>
              <ListItem>
                <ScaledText>
                  Nicotine is the main ingredient in tobacco, and the reason it makes quitting difficult. Nicotine changes how the brain works. It
                  convinces the brain it needs nicotine to feel fine and function normally.
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  When you stop using tobacco you may feel irritable, anxious, or upset. These are all withdrawal symptoms that will get better once
                  your brain adjusts to not having nicotine around
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  Medications that help us quit tobacco help with the first challenges, cravings and withdrawal symptoms. By reducing cravings and
                  withdrawal symptoms like irritability, poor concentration, and difficulty sleeping, quitting tobacco becomes easier and more
                  manageable.
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>These quit aids provide a safe and effective way for our body and brain to adjust to less nicotine exposure.</ScaledText>
              </ListItem>
            </ul>
          </Spacer>
        </>
      }
      image={BenefitsImage}
      altTag="Gum and pills on a table"
    />
  )
}
