import { combineReducers } from 'redux'
import allergies from './allergiesSlice'
import appointment from './appointmentSlice'
import auth from './authSlice'
import dashboards from './dashboardSlice'
import medications from './medicationsSlice'
import multifactor from './multifactorSlice'
import physicians from './physiciansSlice'
import profile from './profileSlice'
import recommendations from './recommendationsSlice'
import resources from './resourceSlice'

const appReducer = combineReducers({
  auth,
  profile,
  appointment,
  resources,
  physicians,
  allergies,
  recommendations,
  medications,
  multifactor,
  dashboards,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout/pending') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
