import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactChild | React.ReactChild[]
  size?: number
  bold?: boolean
  styles?: React.CSSProperties
}
export default function ScaledText({ children, size = 20, bold = false, styles }: Props) {
  return (
    <StyledText fontSize={size} bold={bold} style={styles}>
      {children}
    </StyledText>
  )
}

interface StyledTextProps {
  fontSize: number
  bold?: boolean
}

const StyledText = styled.div<StyledTextProps>`
  font-family: ${(props) => (props.bold ? 'Raleway-Bold' : 'Raleway')};
  font-size: ${(props) => props.fontSize * 0.7}px;
  @media (${sizes.tablet}) {
    font-size: ${(props) => props.fontSize}px;
  }
  @media (${sizes.desktop}) {
    font-size: ${(props) => props.fontSize * 1.25}px;
  }
`
