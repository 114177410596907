import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'

export interface MultifactorState {
  forceMultiFactor: boolean | undefined
  releaseDate: string | undefined
  status: 'idle' | 'loading' | 'failed'
}

const initialState: MultifactorState = {
  forceMultiFactor: undefined,
  releaseDate: undefined,
  status: 'idle',
}
export const loadMultifactor = createAsyncThunk('multifactor/load', async (token: string) => {
  const response: Promise<any> = useApi(token).get('api/multiFactor', {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response
})

export const multifactorSlice = createSlice({
  name: 'multifactor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadMultifactor.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadMultifactor.fulfilled, (state, action) => {
        state.status = 'idle'
        state.forceMultiFactor = action.payload.data.forceMultiFactor
        state.releaseDate = action.payload.data.releaseDate
      })
      .addCase(loadMultifactor.rejected, (state) => {
        state.status = 'idle'
      })
  },
})

export const getMultifactor = (state: RootState) => state.root.multifactor.forceMultiFactor
export const getMultifactorReleaseDate = (state: RootState) => state.root.multifactor.releaseDate
export const getMultifactorStatus = (state: RootState) => state.root.multifactor.status

export default multifactorSlice.reducer
