import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import { Challenge, ChallengeSection } from 'types/Challenge'
import ChallengeDashboard from './ChallengeDashboard'
import CompletionPage from './CompletionPage'
import InfoPage from './InfoPage'
import ReflectionPage from './ReflectionPage'
import SectionDashboard from './SectionDashboard'
import SectionIntroPage from './SectionIntroPage'

export default function CTLChallenges() {
  const [sections, setSections] = useState<ChallengeSection[]>()
  const [challenges, setChallenges] = useState<Challenge[]>()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [currentChallenge, setCurrentChallenge] = useState<Challenge>()
  const [step, setStep] = useState<number>(1)
  const [sectionId, setSectionId] = useState<number>()
  const location = useLocation()
  const route = location.pathname

  const token = useAppSelector(getAccessToken) ?? ''
  const { getChallenges } = useChallenges(token)
  const dispatch = useAppDispatch()
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('CTL Challenges')

  useEffect(() => {
    dispatch(setLoading(true))
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    getChallenges()
      .then((res) => {
        setChallenges(res.data.challenges)
        if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1])) {
          !window.location.pathname.includes('challenge/complete') &&
            setCurrentChallenge(
              res.data.challenges.find(
                (challenge) =>
                  challenge.challengePId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
              ),
            )
          window.location.pathname.includes('/challenges/') &&
            setSections(
              res.data.challenges.find(
                (challenge) =>
                  challenge.challengePId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
              ).challengeSections,
            )
          !window.location.pathname.includes('/challenges/') &&
            setSectionId(
              res.data.challenges
                .find((challenge) =>
                  challenge.challengeSections.find(
                    (section) =>
                      section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
                  ),
                )
                .challengeSections.find(
                  (section) =>
                    section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
                ).challengeSectionPId,
            )
          window.location.pathname.includes('/challenge/section') &&
            setSectionId(
              res.data.challenges
                .find((challenge) =>
                  challenge.challengeSections.find(
                    (section) =>
                      section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
                  ),
                )
                .challengeSections.find(
                  (section) =>
                    section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
                ).challengeSectionPId,
            )
          window.location.pathname.includes('/challenge/section') &&
            setStep(parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]))
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  return (
    <>
      {route === '/challenges' && (
        <ChallengeDashboard
          challenges={challenges}
          setSections={(sections: ChallengeSection[]) => setSections(sections)}
          setChallenges={(challenges: Challenge[]) => setChallenges(challenges)}
          setCurrentChallenge={(challenge: Challenge) => setCurrentChallenge(challenge)}
          isMobile={isMobile}
        />
      )}

      {route === `/challenges/${currentChallenge?.challengePId}` && (
        <SectionDashboard setSectionId={(sectionId: number) => setSectionId(sectionId)} isMobile={isMobile} />
      )}

      {route === `/challenge/intro/${sectionId}` && (
        <SectionIntroPage
          setCurrentChallenge={(challenge: Challenge) => setCurrentChallenge(challenge)}
          sectionId={sectionId!}
          challenge={currentChallenge}
          setSectionId={(sectionId: number) => setSectionId(sectionId)}
          isMobile={isMobile}
          setStep={(step: number) => setStep(step)}
        />
      )}
      {route === `/challenge/section/${sectionId}/${step}` && (
        <InfoPage
          isMobile={isMobile}
          setCurrentChallenge={(challenge: Challenge) => setCurrentChallenge(challenge)}
          currentChallenge={currentChallenge}
          step={step}
          setStep={(step: number) => setStep(step)}
        />
      )}
      {route === `/reflection/${sectionId}` && (
        <ReflectionPage
          isMobile={isMobile}
          setCurrentChallenge={(challenge: Challenge) => setCurrentChallenge(challenge)}
          currentChallenge={currentChallenge}
          step={step}
          setStep={(step: number) => setStep(step)}
        />
      )}
      {route === `/challenge/complete/${sectionId}` && (
        <CompletionPage
          isMobile={isMobile}
          setCurrentChallenge={(challenge: Challenge) => setCurrentChallenge(challenge)}
          currentChallenge={currentChallenge}
        />
      )}
    </>
  )
}
