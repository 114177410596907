import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import styled from 'styled-components'

export default function Symptoms() {
  return (
    <>
      <StyledRow>
        <Typography font="semi-bold" size={25}>
          Hypoglycemia Symptoms:
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography font="semi-bold" size={15}>
          Early warning signs and symptoms
        </Typography>
      </StyledRow>
      <Typography size={15}>- Shakiness</Typography>
      <Typography size={15}>- Dizziness</Typography>
      <Typography size={15}>- Sweating</Typography>
      <Typography size={15}>- Hunger</Typography>
      <Typography size={15}>- Fast heartbeat</Typography>
      <Typography size={15}>- Inability to concentrate</Typography>
      <Typography size={15}>- Confusion</Typography>
      <Typography size={15}>- Irritability or moodiness</Typography>
      <Typography size={15}>- Anxiety or nervousness</Typography>
      <Typography size={15}>- Headache</Typography>
      <Typography size={15}>- Damp sheets or nightclothes due to perspiration</Typography>
      <Typography size={15}>- Nightmares</Typography>
      <Typography size={15} styles={{ marginBottom: 10 }}>
        - Tiredness, irritability or confusion upon waking
      </Typography>
      <StyledRow>
        <Typography font="semi-bold" size={15}>
          Severe signs and symptoms
        </Typography>
      </StyledRow>
      <Typography size={15}>- Clumsiness or jerky movements</Typography>
      <Typography size={15}>- Inability to eat or drink</Typography>
      <Typography size={15}>- Muscle weakness</Typography>
      <Typography size={15}>- Difficulty speaking or slurred speech</Typography>
      <Typography size={15}>- Blurry or double vision</Typography>
      <Typography size={15}>- Drowsiness</Typography>
      <Typography size={15}>- Confusion</Typography>
      <Typography size={15}>- Convulsions or seizures</Typography>
      <Typography size={15}>- Unconsciousness</Typography>
      <Typography size={15}>- Death, rarely</Typography>
      <Row styles={{ marginTop: 20 }}>
        <Typography size={15}>
          Symptoms can differ from person to person or from episode to episode. Some people don't have any noticeable symptoms. It's also possible you
          won't have any symptoms of hypoglycemia, so it's important to monitor your blood sugar levels regularly and keep track of how you're feeling
          when your blood sugar is low.
        </Typography>
      </Row>
      <Row styles={{ marginTop: 10 }}>
        <Typography size={15}>
          <>
            Resources provided by:{' '}
            <a href="https://www.mayoclinic.org/" target={'_blank'} rel="noreferrer">
              <span style={{ color: '#00677F', fontWeight: 'bold' }}>https://www.mayoclinic.org/</span>
            </a>
          </>
        </Typography>
      </Row>
    </>
  )
}

const StyledRow = styled(Row)`
  margin-bottom: 10px;
`
