import { DataGrid } from '@mui/x-data-grid'
import { CustomPagination } from 'Pages/DashboardPage/components/CustomPagination'
import { scaleColumns } from 'Pages/DashboardPage/constants'
import React, { useEffect, useState } from 'react'
import { EditModalSettings, ScaleReading, ScaleRow } from 'types/Dashboards'
import { mapScaleReadingsToTableRows } from 'utilities/dashboardUtilities/mapScaleReadingsToTableRows'
import { sortReadingsByDate } from '../../../DashboardGlucosePage/dateFunctions'
import { CustomScaleToolbar } from '../CustomScaleToolbar'
import MobileScaleDataGrid from '../MobileScaleDataGrid'

type Props = {
  readings: ScaleReading[]
  isMobile: boolean
}

export default function ReadingDataGrid({ readings, isMobile }: Props) {
  const [tableRows, setTableRows] = useState<ScaleRow[] | undefined>([])
  const [editModalSettings, setEditModalSettings] = useState<EditModalSettings>({
    isOpen: false,
  })

  useEffect(() => {
    let readingsCopy = [...readings]
    readingsCopy = sortReadingsByDate(readingsCopy)

    const mappedRows = mapScaleReadingsToTableRows(readingsCopy, false)
    setTableRows(mappedRows?.reverse())
  }, [readings])

  const Toolbar = () => <CustomScaleToolbar isMobile={isMobile} />

  if (!readings || readings.length < 0) return <></>

  return (
    <>
      <div style={isMobile ? { minHeight: 400, marginBottom: 50 } : { marginBottom: 50 }} className="dashboard-grid-container">
        {tableRows && readings && (
          <div style={{ width: '100%' }}>
            <DataGrid
              rows={tableRows}
              columns={[...scaleColumns, { field: 'readingTime', sortable: false }]}
              style={isMobile ? { height: 150 } : { height: tableRows.length < 15 ? 'auto' : 800 }}
              disableColumnMenu
              disableSelectionOnClick
              sortingOrder={['asc', 'desc']}
              autoHeight={tableRows.length < 15}
              onCellClick={(cell) => {
                if (cell.field === 'edit') {
                  setEditModalSettings({
                    ...editModalSettings,
                    isOpen: true,
                    scaleReading: readings.find((x) => x.patientReadingPId.toString() === cell.row.readingId),
                  })
                }
              }}
              components={{
                Pagination: () => (isMobile ? <></> : <CustomPagination />),
                Toolbar: Toolbar,
              }}
            />
            {isMobile ? <MobileScaleDataGrid tableRows={tableRows} /> : <></>}
          </div>
        )}
      </div>
    </>
  )
}
