import { sizes } from 'Styles/sizes'
import { FormikErrors } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { DemographicFormValues } from 'types/Appointment'
import { getErrorStyles } from 'utilities/getErrorStyle'

type Props = {
  fieldName: string
  label: string
  errors: FormikErrors<DemographicFormValues>
  attemptedSubmit: boolean
  value: string | undefined
  onChange: (value: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
}
export default function InputField({ fieldName, label, errors, attemptedSubmit, value, onChange }: Props) {
  return (
    <StyledInput
      type="text"
      onChange={onChange}
      style={{
        ...getErrorStyles(fieldName, errors, attemptedSubmit),
      }}
      value={value}
      placeholder={label}
    />
  )
}
const StyledInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 4px;
  border: 2px solid transparent;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 100%;
  @media ${sizes.laptop} {
    width: 45%;
  }
`
