import { IconNames } from './IconNames'

export type Recommendation = {
  recommendationPid: number
  patientId: number
  recommendationDate: string
  patientConditionId: number
  carePlanDescription: string
  patientHedisClinicalPerformanceStandardDetailPId: string
  hedisDescription: string
  categoryDescription: string
  dTPId: number
  hedisTypePId: number
  recommendationType: TypeDescription | null
  isCompleted: boolean
  isIgnored: boolean
  dismissed: boolean
  status: string
  recommendationText: string
  physicianText: string
  spanishText: string
  viewed: boolean
  relatedMedications: number[]
  recommendationTitle: string
  links: { label: string; url: string }[]
}

export type ArchiveDocument = {
  patientWebDocumentPId: number
  patientEducationResourcePid: number
  documentDate: string
  viewed: boolean
  documentType: string
  documentName: string
  documentLink: string
  title: string
  titleSpanish: string
  condition: string
  filePath: string
  filePathSpanish: string
  webAddress: string
  icon: IconNames
}

export enum TypeDescription {
  ExamsAndTests = 'Exams and Tests',
  Vaccinations = 'Vaccinations',
  Medication = 'Medication',
  Lifestyle = 'Lifestyle',
  Other = 'Pharmacist Recommendation',
  None = '',
}
