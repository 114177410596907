import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Spray 1.png'
import Image2 from '../../../../Assets/Images/STOP/Spray 2.png'
import Image3 from '../../../../Assets/Images/STOP/Spray 3.png'

export default function NRTNasalSpray() {
  return (
    <ProConScreen
      title="NRT Products: Nasal Spray"
      subtitle="A spray that delivers a solution into nostrils containing a small dose of nicotine that is absorbed through the nose"
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Controls sudden cravings and withdrawal symptoms',
        'Dose can be adjusted throughout the day',
        'Works faster than other NRT products',
        'Can be used in combination with other NRT products and medications',
      ]}
      cons={[
        'Available by prescription only',
        'Must be used repeatedly throughout the day to control cravings or withdrawal symptoms',
        'Visible by others',
        'Can cause nasal and throat irritation, rhinitis, sneezing, coughing, or watery eyes',
        'Not recommend for use with a nasal or sinus condition',
      ]}
    />
  )
}
