import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { getAccessToken } from 'reducers/authSlice'
import { getScaleReadings, getScaleReadingsSummary, loadScaleReadingSummary } from 'reducers/dashboardSlice'
import { setLoading } from 'reducers/tempSlice'
import { ChartReading } from 'types/ChartReading'
import { DateSetting, ScaleReading } from 'types/Dashboards'
import ContentCards from '../DashboardGlucosePage/components/ContentCards'
import { filterOptions } from '../constants'
import './DashboardScalePage.scss'
import GridCards from './components/GridCards/GridCards'
import LineChart from './components/LineChart'
import ReadingDataGrid from './components/ReadingDataGrid'

export default function DashboardDigitalScalePage() {
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Digital Scale')

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [readings, setReadings] = useState<ScaleReading[]>([])
  const [chartHeight, setChartHeight] = useState(300)
  const [showCustomFields, setShowCustomFields] = useState<boolean>()
  const [chartReadings, setChartReadings] = useState<ChartReading[]>()
  const [readingFilter, setReadingFilter] = useState<DateSetting>({
    label: 'Last 30 Days',
    value: 30,
  })
  const [dateRange, setDateRange] = useState<{ startDate: Date | undefined; endDate: Date | undefined }>({
    startDate: undefined,
    endDate: undefined,
  })

  const scaleReadings = useAppSelector(getScaleReadings)
  const scaleReadingsSummary = useAppSelector(getScaleReadingsSummary)

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(
      loadScaleReadingSummary({
        token: accessToken,
      }),
    ).then(() => dispatch(setLoading(false)))

    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  useEffect(() => {
    changeReadingFilter({
      label: 'Last 30 Days',
      value: 30,
    })
  }, [scaleReadings])

  useEffect(() => {
    if (readingFilter.label === 'All Readings') {
      analytics.postAnalyticsEvent({
        category: `reading_filter_dashboardDigitalScale_allReadings`,
        action: `reading_filter_dashboardDigitalScale_allReadings`,
        label: `Button`,
        screenName: 'DigitalScaleDashboard',
      })
    } else if (readingFilter.label === 'Custom') {
      analytics.postAnalyticsEvent({
        category: `reading_filter_dashboardDigitalScale_customReadings`,
        action: `reading_filter_dashboardDigitalScale_customReadings`,
        label: `Button`,
        screenName: 'DigitalScaleDashboard',
      })
    }
  }, [readingFilter])

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      setChartReadings(
        mapToChartReadings(
          scaleReadings.filter((reading) => {
            const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
            if (
              date1 >= new Date(dayjs(dateRange.startDate).format('YYYY-MM-DD')).getTime() &&
              date1 <= new Date(dayjs(dateRange.endDate).format('YYYY-MM-DD')).getTime()
            ) {
              return reading
            }
          }),
        ),
      )
      setReadings(
        scaleReadings.filter((reading) => {
          const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
          if (
            date1 >= new Date(dayjs(dateRange.startDate).format('YYYY-MM-DD')).getTime() &&
            date1 <= new Date(dayjs(dateRange.endDate).format('YYYY-MM-DD')).getTime()
          ) {
            return reading
          }
        }),
      )
    }
  }, [dateRange])

  const mapToChartReadings = (readings: ScaleReading[]) => {
    if (readings && readings.length > 0) {
      return readings.map((reading) => ({
        value: reading.value,
        date: `${dayjs(reading.readingDate).format('M/DD/YY')}\n${dayjs(reading.readingDate).format('h:mm:ss a')}`,
        type: 'high',
      }))
    }
  }

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setChartHeight(300)
      setIsMobile(true)
    } else {
      setChartHeight(300)
      setIsMobile(false)
    }
  }

  function changeReadingFilter(option) {
    setReadingFilter(option)
    if (option.value && option.value !== 0 && scaleReadings && scaleReadings.length > 0) {
      setShowCustomFields(false)
      setChartReadings(
        mapToChartReadings(
          scaleReadings.filter((reading) => {
            const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
            const date2 = new Date(dayjs().subtract(option.value, 'day').format('YYYY-MM-DD')).getTime()
            if (date1 >= date2) {
              return reading
            }
          }),
        ),
      )
      setReadings(
        scaleReadings.filter((reading) => {
          const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
          const date2 = new Date(dayjs().subtract(option.value, 'day').format('YYYY-MM-DD')).getTime()
          if (date1 >= date2) {
            return reading
          }
        }),
      )
      setDateRange({
        startDate: undefined,
        endDate: undefined,
      })
    } else if (option.value === 0) {
      setShowCustomFields(true)
    } else {
      setShowCustomFields(false)
      setChartReadings(mapToChartReadings(scaleReadings))
      setReadings(scaleReadings)
      setDateRange({
        startDate: undefined,
        endDate: undefined,
      })
    }
  }

  return (
    <>
      <div className="dashboard-page-container">
        {isMobile && <div style={{ minHeight: 20 }} />}

        <ResponsiveContainer
          className="dashboard-filter-row"
          styles={{
            alignItems: 'center',
            width: isMobile ? '100%' : 'auto',
            height: 'auto',
          }}
        >
          <Dropdown className="date-filter-select-container">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="date-filter-select">
              {readingFilter.label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {filterOptions.map((option) => (
                <Dropdown.Item onClick={() => changeReadingFilter(option)}>{option.label}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {showCustomFields && (
            <>
              <div
                style={{
                  marginLeft: isMobile ? 0 : 5,
                  minWidth: isMobile ? '100%' : 'none',
                  marginBottom: isMobile ? 10 : 0,
                  marginTop: isMobile ? 10 : 0,
                }}
              >
                <ReactDatePicker
                  selected={dateRange.startDate}
                  className="start-date-picker"
                  onChange={(date: Date) => {
                    setDateRange((prevState) => ({
                      ...prevState,
                      startDate: date,
                    }))
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  placeholderText="Start Date"
                  maxDate={new Date()}
                  isClearable={false}
                />
              </div>
              <div style={{ minWidth: 10 }} />
              <div style={{ minWidth: isMobile ? '100%' : 'none', marginBottom: isMobile ? 5 : 0 }}>
                <ReactDatePicker
                  selected={dateRange.endDate}
                  className="start-date-picker"
                  onChange={(date: Date) => {
                    setDateRange((prevState) => ({
                      ...prevState,
                      endDate: date,
                    }))
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  placeholderText="End Date"
                  maxDate={new Date()}
                />
              </div>
              <div style={{ minWidth: 10 }} />
            </>
          )}
        </ResponsiveContainer>
        <div className="scale-chart-container">
          <LineChart readings={readings} isMobile={isMobile} chartReadings={chartReadings} chartHeight={chartHeight} />
        </div>
        {chartReadings && chartReadings.length > 0 && (
          <GridCards
            scaleReadings={scaleReadings}
            filteredReadings={chartReadings}
            scaleReadingsSummary={scaleReadingsSummary}
            readingFilter={readingFilter}
          />
        )}
        {readings && readings.length > 0 && <ReadingDataGrid readings={readings} isMobile={isMobile} />}

        <div className="dashboard-resources-section">
          <ContentCards type="Digital Scale" />
        </div>
      </div>
    </>
  )
}
