import { Formik, FormikErrors, getIn } from 'formik'
import React from 'react'
import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import '../../PatientProfile.scss'
import './PasswordReset.scss'
import { StyledErrorText } from 'Styles/global'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { Form } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import * as Yup from 'yup'
import ContentHeader from '../ContentHeader'

interface PasswordResetFormValues {
  currentPassword: string | undefined
  newPassword: string | undefined
  confirmPassword: string | undefined
}

interface Props {
  isMobile: boolean
  backPressed: () => void
}

export default function PasswordReset({ isMobile, backPressed }: Props) {
  const theme = useTheme()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Profile')

  const updatePassword = (values: PasswordResetFormValues) => {
    console.log(values)
  }

  function getStyles(fieldName: string, errors: FormikErrors<PasswordResetFormValues>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(6, 'Must be at least 6 characters')
      .matches(/[A-Z]+/, 'One uppercase character required')
      .matches(/(?=.*?[0-9])/, 'One numeric value required')
      .matches(/(?=.*?[#?!@$%^&*-])/, 'One special character required'),
    confirmPassword: Yup.string()
      .required('Confirmation is required')
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match'),
  })

  return (
    <Formik
      initialValues={{
        currentPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={(values: PasswordResetFormValues) => updatePassword(values)}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => {
        return (
          <>
            {isMobile && (
              <Row styles={{ marginBottom: 10, cursor: 'pointer' }} onClick={backPressed}>
                <Icon name="chevron-left" color={theme.colors.primary1} styles={{ marginRight: 5 }} />
                <Typography color={theme.colors.primary1} font="bold">
                  Back
                </Typography>
              </Row>
            )}
            <ContentHeader title="Reset Password" icon="lock" color="#00677f" />
            <Form>
              <Form.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div className="profile-home-input-container">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Current Password:</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(value) => setFieldValue('currentPassword', value.currentTarget.value)}
                      value={values.currentPassword}
                      style={{
                        border: '2px solid transparent',
                        borderRadius: 4,
                        ...getStyles('currentPassword', errors),
                      }}
                      placeholder=""
                      className="password-reset-input"
                    />
                    {errors.currentPassword && (
                      <Row>
                        <StyledErrorText>{errors.currentPassword}</StyledErrorText>
                      </Row>
                    )}
                  </div>
                  <div className="password-spacer" />
                  <div className="profile-home-input-container">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>New Password:</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(value) => setFieldValue('newPassword', value.currentTarget.value)}
                      style={{
                        border: '2px solid transparent',
                        borderRadius: 4,
                        ...getStyles('newPassword', errors),
                      }}
                      value={values.newPassword}
                      placeholder=""
                      className="password-reset-input"
                    />
                    {errors.newPassword && (
                      <Row>
                        <StyledErrorText>{errors.newPassword}</StyledErrorText>
                      </Row>
                    )}
                  </div>
                  <div className="password-spacer" />
                  <div className="profile-home-input-container">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Confirm New Password:</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(value) => setFieldValue('confirmPassword', value.currentTarget.value)}
                      style={{
                        border: '2px solid transparent',
                        borderRadius: 4,
                        ...getStyles('confirmPassword', errors),
                      }}
                      value={values.confirmPassword}
                      placeholder=""
                      className="password-reset-input"
                    />
                    {errors.confirmPassword && (
                      <Row>
                        <StyledErrorText>{errors.confirmPassword}</StyledErrorText>
                      </Row>
                    )}
                  </div>
                </div>
              </Form.Group>

              <Button text="Save Changes" styles={{ marginTop: 10 }} onClick={handleSubmit} className="profile-save-button" />
            </Form>
          </>
        )
      }}
    </Formik>
  )
}
