import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import { StyledPageContainer } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../hooks/hooks'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { getProfile } from '../../reducers/profileSlice'
import { getActiveResourceSections } from '../../reducers/resourceSlice'
import HelpCard from './HelpCard'
import './ResourcesPage.scss'

export default function ResourcesPage() {
  const sections = useAppSelector(getActiveResourceSections)

  const profile = useAppSelector(getProfile)
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Resources')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const hasChallenges = profile?.hasChallenges

  return (
    <div>
      <StyledPageContainer
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Column styles={{ width: 1600 }}>
          <Row center>
            <StyledTitle>We're Here to Help!</StyledTitle>
          </Row>
          <StyledGrid>
            {sections?.map((section) => {
              if (section.sectionName === 'Challenges') {
                if (hasChallenges) {
                  return (
                    <HelpCard
                      title={section.sectionName}
                      subtitle={section.subTitle}
                      relative={true}
                      icon={section.icon}
                      link={`/${section.route}`}
                    />
                  )
                }
              } else if (!section.externalRoute) {
                return (
                  <HelpCard title={section.sectionName} subtitle={section.subTitle} relative={true} icon={section.icon} link={`/${section.route}`} />
                )
              } else {
                return (
                  <HelpCard
                    title={section.sectionName}
                    subtitle={section.subTitle}
                    relative={false}
                    icon={section.icon}
                    link={`${section.externalRoute}`}
                  />
                )
              }
            })}
          </StyledGrid>
        </Column>
      </StyledPageContainer>
    </div>
  )
}

const StyledTitle = styled.span`
  font-family: 'Raleway-Bold';
  text-align: center;
  margin-bottom: 15px;
  @media ${sizes.mobileL} {
    font-size: 8vw;
  }
  @media ${sizes.tablet} {
    font-size: 4vw;
  }
  @media ${sizes.desktop} {
    font-size: 3vw;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  justify-items: center;
`

const StyledButton = styled.button`
  width: 100%;
  background-color: lightBlue;
  fontsize: 20px;
  color: white;
`
