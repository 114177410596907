import { useState } from 'react'
import { useApi } from './useAPI'
/* eslint-disable react-hooks/exhaustive-deps */

export interface AnalyticsEventValues {
  category: string
  action: string
  label?: string
  screenName?: string
}

export default function useAnalytics(token: string) {
  const [error, setError] = useState<string | undefined>(undefined)

  const TriaApi = useApi(token)

  const BASE_URL = `api/analytics`

  const postAnalyticsEvent = async (values: AnalyticsEventValues, callback?: Function) => {
    const data = {
      EventName: 'Event',
      Application: 'Portal',
      Category: values.category,
      Action: values.action,
      Label: values.label,
      ScreenName: values.screenName,
    }

    const consentForm = TriaApi.post(`${BASE_URL}/event`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return consentForm
  }

  const postAnalyticsPageView = async (currentScreen: string) => {
    const data = {
      EventName: 'ScreenView',
      ScreenName: currentScreen,
      Application: 'Portal',
    }

    const consentForm = TriaApi.post(`${BASE_URL}/page`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch((err) => console.log(err))

    return consentForm
  }

  return {
    postAnalyticsEvent,
    postAnalyticsPageView,
  }
}
