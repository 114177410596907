import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function ProteinStep3({ isMobile, step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [stepImages, setStepImages] = useState<SectionInfo>()

  useEffect(() => {
    setInfoPage(true)
    window.scrollTo(0, 0)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
  }, [step])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : '0px 60px 0px 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '0px 0px' }}>
          <Typography variant="h1">{sectionInfo?.titles.find((title) => title.titleId === 11)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 7)?.paragraph ?? ''}{' '}
            <b>{sectionInfo?.titles.find((title) => title.titleId === 6)?.titleText ?? ''}</b>{' '}
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 8)?.paragraph ?? ''}{' '}
            <i>{sectionInfo?.titles.find((title) => title.titleId === 7)?.titleText ?? ''}</i>
          </p>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 9)?.paragraph ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h3">{sectionInfo?.titles.find((title) => title.titleId === 8)?.titleText ?? ''}</Typography>
        </Row>
        <li style={{ fontFamily: 'Raleway', textIndent: '1.5em', fontSize: 20 }}>
          {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 10)?.paragraph ?? ''}
        </li>
        <li style={{ fontFamily: 'Raleway', textIndent: '1.5em', fontSize: 20 }}>
          {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 11)?.paragraph ?? ''}
        </li>
      </Column>
      <Column styles={{ width: '100%' }}>
        <img
          src={sectionInfo?.images.find((image) => image.imageId === 4)?.url}
          alt="scale"
          style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto', border: '1px solid #000' }}
        />
      </Column>
    </ResponsiveContainer>
  )
}
