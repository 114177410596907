import React from 'react'
import styled, { useTheme } from 'styled-components'

type MarginType = boolean | number

interface MarginProps {
  all?: MarginType
  horizontal?: MarginType
  top?: MarginType
  right?: MarginType
  bottom?: MarginType
  left?: MarginType
  vertical?: MarginType
}

interface Props extends MarginProps {
  children?: React.ReactChild | React.ReactChild[]
  style?: React.CSSProperties
}

export default function Spacer(props: Props) {
  return <StyledDiv {...props}>{props.children}</StyledDiv>
}

const StyledDiv = styled.div<MarginProps>`
  ${(props) => {
    const theme = useTheme()
    let styles = ''

    const getMargin = (margin?: MarginType) => {
      if (typeof margin === 'number') {
        return `${margin}px`
      } else {
        return `${theme.margin.default}px`
      }
    }

    if (props.all !== undefined) styles += `margin: ${getMargin(props.all)};`
    if (props.top !== undefined) styles += `margin-top: ${getMargin(props.top)};`
    if (props.right !== undefined) styles += `margin-right: ${getMargin(props.right ?? props.horizontal)};`
    if (props.bottom !== undefined) styles += `margin-bottom: ${getMargin(props.bottom)};`
    if (props.left !== undefined) styles += `margin-left: ${getMargin(props.left ?? props.horizontal)};`

    return styles
  }};
`
