import { IconNames } from './IconNames'

export type Challenge = {
  patientChallengePId: number
  challengePId: number
  name: string
  numberOfCompletedSections: number
  numberOfSections: number
  sortOrder: number
  badgeEarned: boolean
  badgeName: string
  badgeIconUrl: string
  grayScaleBadgeIconUrl: string
  icon: IconNames
  challengeHexColor: string
  challengeSections: ChallengeSection[]
}

export type ChallengeSection = {
  patientChallengeSectionPId: number
  challengeSectionPId: number
  name: string
  subtitle: string
  difficulty: string
  sortOrder: number
  estimatedTime: number
  completed: boolean
  currentStep: number
  numberOfSteps: number
  imageUrl: string
  grayScaleImageUrl: string
}

export type SectionInfo = {
  sectionId: number
  titles: Title[]
  paragraphs: Paragraph[]
  questions?: Question[]
  images: Image[]
}

export type Title = {
  titleId: number
  titleText: string
  portalStep: number
}

export type Paragraph = {
  paragraphId: number
  paragraph: string
  portalStep: number
}

export type Question = {
  questionId: number
  question: string
  italicized?: string
  hint?: string
  multipleCorrect?: boolean
  portalStep: number
  answers: Answer[]
}

export type Answer = {
  answerId: number
  correctAnswer: boolean
  text: string
}

export type Image = {
  imageId: number
  portalStep: number
  url: string
}

export const ItemTypes = {
  CARD: 'card',
}
