import qs from 'querystring'
import { getProfile } from 'reducers/profileSlice'
import { AppointmentRequest } from 'types/Appointment'
import { useAppSelector } from './hooks'
import { useApi } from './useAPI'
import useProfile from './useProfile'

export default function useAppointments(token: string) {
  const TriaApi = useApi(token)
  const { updateEnrollmentStatus } = useProfile(token)
  const profile = useAppSelector(getProfile)

  const postAppointment = async (values: AppointmentRequest, callback?: Function) => {
    const data = qs.stringify({
      ImmediateAppointment: values.immediateAppointment,
      StartTime: values.startTime,
      EndTime: values.endTime,
      Pharmacist: values.pharmacist,
    })

    const response = TriaApi.post(`api/appointment`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    profile?.canReactivateWithFollowUp && updateEnrollmentStatus()

    return response
  }

  const postRescheduledAppointment = async (values: AppointmentRequest, callback?: Function) => {
    const data = qs.stringify({
      ImmediateAppointment: values.immediateAppointment,
      ScheduleId: values.scheduleId,
      StartTime: values.startTime,
      EndTime: values.endTime,
      Pharmacist: values.pharmacist,
    })

    const response = TriaApi.post(`api/appointment`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postInitialAppointment = async (values: AppointmentRequest, callback?: Function) => {
    const data = qs.stringify({
      EnrollmentFormGuid: values.enrollmentFormGuid,
      ImmediateAppointment: values.immediateAppointment,
      StartTime: values.startTime,
      EndTime: values.endTime,
      Pharmacist: values.pharmacist,
    })

    const response = TriaApi.post(`api/enrollment/appointment`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const getAppointments = async (callback?: Function) => {
    const response = TriaApi.get(`api/appointment`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const getInitialAppointments = async () => {
    const response = TriaApi.get(`api/enrollment/appointment`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  return {
    getAppointments,
    getInitialAppointments,
    postAppointment,
    postInitialAppointment,
    postRescheduledAppointment,
  }
}
