import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface TempState {
  requestedRoute: string | undefined
  loading: boolean
}

const initialState: TempState = {
  requestedRoute: undefined,
  loading: false,
}

export const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    setRequestedRoute(state, action: PayloadAction<string>) {
      state.requestedRoute = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    clearRequestedRoute(state) {
      state.requestedRoute = undefined
    },
  },
})

export const getRequestedRoute = (state: RootState) => state.temp.requestedRoute
export const getLoading = (state: RootState) => state.temp.loading

export const { setRequestedRoute, clearRequestedRoute, setLoading } = tempSlice.actions

export default tempSlice.reducer
