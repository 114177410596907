import { LifestyleFormValues } from '../Pages/AppointmentPage/components/LifestyleForm'
import { IntakeAllergy, IntakeMedication } from '../types/Medication'
import { useApi } from './useAPI'

interface Props {
  token: string | undefined
}

export enum IntakeHistoryType {
  Medical = 1,
  Surgical = 2,
}
export type HistoryOption = {
  selected: boolean
  option: string
}

export interface IntakeHistoryState {
  EnrollmentFormGuid?: string
  MedicalHistory: IntakeHistory
  SurgicalHistory: IntakeHistory
}

export interface IntakeHistory {
  HistoryType: IntakeHistoryType
  HistoryOptions: HistoryOption[]
  Notes: string
  Other?: HistoryOption
}

export interface PrimaryPhysicianState {
  hasPrimaryPhysician: boolean
  firstName: string
  lastName: string
  city: string
  state: string
  quit: boolean
}

export interface IntakeMedicationsState {
  medications: IntakeMedication[]
}

export interface IntakeAllergyState {
  allergies: IntakeAllergy[]
  other?: IntakeAllergy[]
}

export interface EnrollmentStatus {
  EnrollmentFormGuid?: string
  statusId: number
}

export default function usePatientIntake({ token }: Props) {
  const TriaApi = useApi(token)

  const getEnrollmentStatus = async () => {
    const result = TriaApi.get(`api/intake/enrollment-status`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return result
  }

  const postEnrollmentStatus = async (request) => {
    const data = {
      EnrollmentFormId: request.enrollmentFormGuid,
      statusId: request.statusId,
      direction: request.direction,
    }
    const result = TriaApi.post(`api/intake/enrollment-status`, data)
    return result
  }

  const getPhysicianInfo = async () => {
    const result = TriaApi.get(`api/intake/physician`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return result
  }

  const postPhysicianInfo = async (request: PrimaryPhysicianState) => {
    const result = TriaApi.post(`api/intake/physician`, {
      FirstName: request.firstName,
      LastName: request.lastName,
      City: request.city,
      State: request.state,
      HasPrimaryPhysician: request.hasPrimaryPhysician,
    })
    return result
  }

  const postLifestyleInfo = async (values: LifestyleFormValues) => {
    const { enrollmentFormGuid, responses, heightFeet, heightInches, weight } = values
    const data = {
      EnrollmentFormId: enrollmentFormGuid,
      HeightFeet: heightFeet,
      HeightInches: heightInches,
      Weight: weight,
      Responses: responses,
    }
    const result = TriaApi.post(`api/intake/lifestyle`, data)
    return result
  }

  const getMedicationInfo = async () => {
    const result = TriaApi.get(`api/intake/medication`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return result
  }

  const getMedicationAutoCompleteInfo = async (search: string) => {
    const result = TriaApi.get(`api/intake/medication/suggestions`, {
      params: { NameLike: search },

      headers: {
        'Content-Type': 'application/json',
      },
    })
    return result
  }

  const postMedicationInfo = async (request: IntakeMedication[]) => {
    const result = TriaApi.post(`api/intake/medication`, {
      Medications: request,
    })
    return result
  }

  const getAllergyInfo = async () => {
    const result = TriaApi.get(`api/intake/allergy`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return result
  }

  const getHistoryInfo = async () => {
    const result = TriaApi.get(`api/intake/history`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return result
  }

  const getLifeStyleInfo = async () => {
    const result = TriaApi.get(`api/intake/lifestyle`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return result
  }

  const postAllergyInfo = async (request: IntakeAllergyState) => {
    const result = TriaApi.post(`api/intake/allergy`, {
      Allergies: request.allergies,
      Other: request.other,
    })
    return result
  }

  const postHistoryInfo = async (request: IntakeHistoryState) => {
    const { EnrollmentFormGuid, MedicalHistory, SurgicalHistory } = request

    const data = {
      EnrollmentFormId: EnrollmentFormGuid,
      MedicalHistory: MedicalHistory,
      SurgicalHistory: SurgicalHistory,
    }
    const result = TriaApi.post(`api/intake/history`, data)
    return result
  }

  return {
    getEnrollmentStatus,
    getPhysicianInfo,
    postPhysicianInfo,
    getMedicationInfo,
    getMedicationAutoCompleteInfo,
    postMedicationInfo,
    getAllergyInfo,
    postAllergyInfo,
    postLifestyleInfo,
    postHistoryInfo,
    postEnrollmentStatus,
    getHistoryInfo,
    getLifeStyleInfo,
  }
}
