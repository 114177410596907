import { Theme, createStyles, makeStyles } from '@material-ui/core'
import { useGridSlotComponentProps } from '@mui/x-data-grid'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

interface Props {
  isMobile: boolean
}
export function CustomScaleToolbar({ isMobile }: Props) {
  const { apiRef } = useGridSlotComponentProps()

  const styles = useStyles()

  return (
    <ResponsiveContainer className={styles.container}>
      <ResponsiveContainer styles={isMobile ? { marginTop: 10 } : { display: 'flex', marginLeft: 'auto' }}>
        <Dropdown style={isMobile ? { marginTop: 10 } : {}}>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className={styles.toolbarButton}
            style={{
              backgroundColor: '#425563',
              color: 'white',
              border: '1px solid #425563',
            }}
          >
            Export
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {!isMobile && (
              <Dropdown.Item
                onClick={() =>
                  apiRef.current.exportDataAsPrint({
                    hideToolbar: true,
                    hideFooter: true,
                    fields: ['readingDate', 'readingTime', 'weight', 'change'],
                  })
                }
              >
                Print
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => apiRef.current.exportDataAsCsv()}>Export as CSV</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ResponsiveContainer>
    </ResponsiveContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    toolbarButton: {
      minWidth: 100,
      backgroundColor: '#F2F2F2',
      color: 'black',
      border: '1px solid #BDBDBD',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 5,
      },
    },
  }),
)
