import Typography from 'Components/Base/Typography'
import styled from 'styled-components'
import { sizes } from './sizes'

export const StyledPageContainer = styled.div`
  margin: 5% 2% 2% 2%;
  @media ${sizes.tablet} {
    margin: 5% 10% 5% 10%;
  }
`
export const StyledErrorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.status.bad};
  font-family: 'Raleway';
`
