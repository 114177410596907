import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import Quote1 from '../../../../Assets/Images/STOP/Quote 1.png'
import Quote2 from '../../../../Assets/Images/STOP/Quote 2.png'

export default function Quotes() {
  return (
    <StyledResponsiveContainer>
      <StyledColumn center>
        <Spacer bottom>
          <ScaledText>
            Millions of people have quit tobacco for good! The majority of people who have quit and stay quit report feeling better physically and
            mentally.
          </ScaledText>
        </Spacer>
        <ScaledText>
          Overcoming the obstacles can be challenging, but the feelings of accomplishment and reward after quitting can motivate you to stay tobacco
          free.
        </ScaledText>
      </StyledColumn>
      <ResponsiveContainer styles={{ width: '10%' }} />
      <StyledColumn styles={{ minWidth: '50%' }}>
        <Row styles={{ justifyContent: 'flex-start' }}>
          <StyledImage src={Quote1} alt={'altTag'} />
        </Row>
        <Row styles={{ justifyContent: 'flex-end' }}>
          <StyledImage src={Quote2} alt={'altTag'} />
        </Row>
      </StyledColumn>
    </StyledResponsiveContainer>
  )
}

const StyledColumn = styled(Column)`
  flex: 1;
  width: 100%;
  @media (${sizes.tablet}) {
    width: 45%;
    overflow: none;
  }
`
const StyledResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (${sizes.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 600px;
  @media (${sizes.tablet}) {
    width: 80%;
  }
`
