import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { ErrorMessage, Formik } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { getLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { AddressLine1, AddressLine2 } from 'tria-ui-react-lib/constants/ContactInfo.json'
import { Caregiver } from 'types/Caregiver'
import formatDateInput from 'utilities/formatDateInput'
import formatPhoneNumberInput from 'utilities/formatPhoneNumberInput'
import * as Yup from 'yup'

type Props = {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  onSubmitCaregiver: (caregiver: Caregiver) => void
  addCaregiverError?: string
}
export default function AddCaregiverModal({ modalOpen, setModalOpen, onSubmitCaregiver, addCaregiverError }: Props) {
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const loading = useAppSelector(getLoading)

  const initialValues: Caregiver = {
    name: undefined,
    email: undefined,
    cellPhone: undefined,
    birthDate: undefined,
    address: undefined,
    relationship: undefined,
    canReceiveHealthAlerts: false,
    appointmentReminders: false,
    disclosurePurpose: undefined,
    hasAccepted: false,
    signature: undefined,
    caregiverAccessPermissions: [],
    caregiverStatus: undefined,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    cellPhone: Yup.string().required('Cell phone is required').min(14, 'Must be 10 numbers'),
    birthDate: Yup.string()
      .required('Date of Birth is required')
      .matches(new RegExp(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.]((?:19|20)\d\d)/), 'Date is invalid'),
    address: Yup.string().required('Address is required'),
    relationship: Yup.string().required('Relationship is required'),
    canReceiveHealthAlerts: Yup.boolean().isTrue('Please check box'),
    signature: Yup.string().required('Signature is required'),
  })

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => onSubmitCaregiver(values)}>
      {({ values, setFieldValue, errors, submitForm, isValid }) => {
        return (
          <StyledCustomModal
            show={modalOpen}
            onHide={() => setModalOpen(false)}
            onRequestClose={() => setModalOpen(!modalOpen)}
            dialogClassName={'dialog-class'}
            fullscreen={false}
          >
            <StyledCustomModalHeader>
              <Modal.Title>Add Caregiver</Modal.Title>
              <div onClick={() => setModalOpen(false)} style={{ cursor: 'pointer' }}>
                <Icon name="times" color="white" />
              </div>
            </StyledCustomModalHeader>
            <StyledModalBody>
              <Row>
                <Typography font="bold" size={25}>
                  HIPAA Authorization Form
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={20} styles={{ marginBottom: 10 }}>
                  To Permit Disclosure of Protected Health Information
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={16} styles={{ marginBottom: 10 }}>
                  PURPOSE OF THIS FORM: This Authorization Form is to be used when an individual wishes to give another person access to his or her
                  health information. When completed, it will allow Tria Health to disclose your health information to, and receive it from, the
                  peron(s) stated on the form. Please provide all information requested below and submit this form.
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={20} styles={{ marginBottom: 10 }}>
                  <>
                    <span style={{ fontWeight: 'bold' }}>Section 1.</span> Please provide the name of the person or organization that you are
                    authorizing to receive your protected health information from Tria Health
                  </>
                </Typography>
              </Row>
              <Row
                styles={{
                  flexWrap: 'wrap',
                  marginBottom: 25,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Caregiver Name:</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    placeholder=""
                    className="profile-physician-input"
                  />
                  <StyledErrorText>
                    <ErrorMessage name="name" />
                  </StyledErrorText>
                </div>
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Date of Birth:</Form.Label>
                  <Form.Control
                    value={values.birthDate}
                    onChange={(e) => setFieldValue('birthDate', formatDateInput(e.target.value))}
                    placeholder="(MM/DD/YYYY)"
                    className="profile-physician-input"
                  />
                  <StyledErrorText>
                    <ErrorMessage name="birthDate" />
                  </StyledErrorText>
                </div>
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Cell Phone:</Form.Label>
                  <Form.Control
                    onChange={(e) => setFieldValue('cellPhone', formatPhoneNumberInput(e.target.value))}
                    value={values.cellPhone}
                    placeholder=""
                    max={9}
                    className="profile-physician-input"
                  />
                  <StyledErrorText>
                    <ErrorMessage name="cellPhone" />
                  </StyledErrorText>
                </div>
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Email:</Form.Label>
                  <Form.Control onChange={(e) => setFieldValue('email', e.target.value)} placeholder="" className="profile-physician-input" />
                  <StyledErrorText>
                    <ErrorMessage name="email" />
                  </StyledErrorText>
                </div>
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Address:</Form.Label>
                  <Form.Control onChange={(e) => setFieldValue('address', e.target.value)} placeholder="" className="profile-physician-input" />
                  <StyledErrorText>
                    <ErrorMessage name="address" />
                  </StyledErrorText>
                </div>
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Relationship:</Form.Label>
                  <Form.Control
                    onChange={(e) => setFieldValue('relationship', e.target.value)}
                    placeholder="(Spouse, Child, Parent, etc)"
                    className="profile-physician-input"
                  />
                  <StyledErrorText>
                    <ErrorMessage name="relationship" />
                  </StyledErrorText>
                </div>
              </Row>
              <Row>
                <Typography font="regular" size={20} styles={{ marginBottom: 5 }}>
                  <>
                    <span style={{ fontWeight: 'bold' }}>Section 2.</span> Information to be disclosed.
                  </>
                </Typography>
              </Row>
              <Row>
                <StyledErrorText>
                  <ErrorMessage name="canReceiveHealthAlerts" />
                </StyledErrorText>
              </Row>
              <Row styles={{ marginBottom: 10 }}>
                <Column styles={{ marginRight: 10 }}>
                  <Checkbox
                    checked={values.canReceiveHealthAlerts}
                    onClick={() => setFieldValue('canReceiveHealthAlerts', !values.canReceiveHealthAlerts)}
                  />
                </Column>
                <Typography font="regular" size={16}>
                  Please check the box to indicate your election to share your health information with the above-name caregiver via your pharmacist
                  consultations and electronic messaging. Patients may revise these preferences once a Caregiver profile has been created.
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={20}>
                  <>
                    <span style={{ fontWeight: 'bold' }}>Section 3.</span> Purpose of the disclosure.
                  </>
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={16} styles={{ marginBottom: 10 }}>
                  Tria Health understands the purpose of the requested disclosure of your health information to be 'at the request of the individual'
                  (ie. you). If the disclosure is only for the following specific purpose please indicate here:
                </Typography>
              </Row>
              <Row>
                <div className="profile-physician-item">
                  <Form.Label style={{ color: 'black', fontWeight: 'bold' }}>Purpose of the disclosure:</Form.Label>
                  <Form.Control
                    onChange={(e) => setFieldValue('disclosurePurpose', e.target.value)}
                    placeholder="(Optional)"
                    className="profile-physician-input"
                  />
                </div>
              </Row>
              <Row>
                <Typography font="regular" size={20}>
                  <>
                    <span style={{ fontWeight: 'bold' }}>Section 4.</span> Expiration and Revocation.
                  </>
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={16}>
                  <div>
                    Expiration: This authorization will expire the earlier of: any time you remove the person above as a caregiver authorized to
                    receive your health information from Tria Health or 60 days after you cease being a member of your employer’s health plan.
                    <br></br>
                  </div>
                </Typography>
              </Row>
              <Row styles={{ marginBottom: 10 }}>
                <Typography font="regular" size={16}>
                  Right to Revoke: I understand that I may revoke all or part of this authorization at any time by selecting to remove the person
                  above as an authorized care giver on my Caregiver Access page in the Tria Health portal.
                </Typography>
              </Row>
              <Row styles={{ marginBottom: 10 }}>
                <Typography font="regular" size={16}>
                  <>
                    <div>Tria Health</div>
                    <div>ATTN: HIPAA Privacy Officer & Customer Service</div>
                    <div>{AddressLine1}</div>
                    <div>{AddressLine2}</div>
                  </>
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={20} styles={{ marginBottom: 10 }}>
                  <>
                    <span style={{ fontWeight: 'bold' }}>Section 5.</span> Signature.
                  </>
                </Typography>
              </Row>
              <div className="profile-physician-item">
                <Form.Label
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    marginLeft: 5,
                  }}
                >
                  e-Signature:
                </Form.Label>
                <Form.Control
                  onChange={(e) => setFieldValue('signature', e.target.value)}
                  style={{ marginBottom: 5, marginLeft: 5 }}
                  placeholder=""
                  className="profile-physician-input"
                />
                <StyledErrorText>
                  <ErrorMessage name="signature" />
                </StyledErrorText>
              </div>
              <Row>
                <Typography font="regular" size={16} styles={{ marginBottom: 5 }}>
                  <>
                    {`I`}
                    {values.signature && (
                      <span style={{ fontWeight: 'bold' }}>
                        <>, {values.signature ?? 'Your Name'},</>
                      </span>
                    )}
                    {` have had full opportunity to read and
          consider the contents of this authorization, and I
          confirm that the contents are consistent with my
          direction to Tria Health. I understand that by signing
          this form, I am confirming my authorization that Tria
          Health may disclose to the person named in this form the
          health information described in this form. I also
          understand that Tria Health will not condition payment,
          enrollment or eligibility for benefits in Tria Health on
          the signing of this authorization`}
                  </>
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={16} styles={{ marginBottom: 5 }}>
                  I understand that once the information is disclosed pursuant to this authorization, it may no longer be protected by federal privacy
                  laws and could be re-disclosed by the person or entity that received it.
                </Typography>
              </Row>
              <Row>
                <Typography font="regular" size={16} styles={{ marginBottom: 10 }}>
                  I am entitled to keep and/or request a copy of this form for my records.
                </Typography>
              </Row>
              {attemptedSubmit && isValid === false && (
                <Row styles={{ justifyContent: 'space-between' }}>
                  <StyledErrorText>Please fix errors above</StyledErrorText>
                </Row>
              )}
              {addCaregiverError && (
                <Row styles={{ justifyContent: 'space-between' }}>
                  <StyledErrorText>{`${addCaregiverError}`}</StyledErrorText>
                </Row>
              )}

              <Row styles={{ justifyContent: 'space-between' }}>
                <Button
                  pageName={`profile_caregiver_add`}
                  text="Save"
                  loading={loading}
                  className="caregiver-add-button"
                  onClick={() => {
                    setAttemptedSubmit(true)
                    submitForm()
                  }}
                />
                <Button pageName={`profile_caregiver_add`} text="Cancel" onClick={() => setModalOpen(false)} className="caregiver-cancel-button" />
              </Row>
            </StyledModalBody>
          </StyledCustomModal>
        )
      }}
    </Formik>
  )
}
const StyledCustomModal = styled(Modal)`
  padding-left: 0px !important;
`

const StyledCustomModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.primary1};
  align-items: center;
  color: white;
`

const StyledModalBody = styled(Modal.Body)`
  min-height: min-content;
  @media ${sizes.tablet} {
    min-height: 100vh;
  }
`
