import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { PhoneNumber, PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'

export default function HelpInfo() {
  const theme = useTheme()
  return (
    <div>
      <StyledRow center>
        <StyledText color={theme.colors.primary1} font="bold" size={22}>
          Is all your information current?
        </StyledText>
      </StyledRow>
      <Row center styles={{ marginTop: 5, textAlign: 'center' }}>
        <StyledText color={theme.colors.black} font="semi-bold">
          Let us know if we need to make any changes:
        </StyledText>
      </Row>
      <Row center styles={{ textAlign: 'center' }}>
        <StyledText color={theme.colors.primary1} font="semi-bold">
          <>
            <a href={`tel:${PhoneNumber}`}>{PhoneNumberPeriodDelimited}</a>
            <span style={{ color: theme.colors.black }}> | </span>
            <a href="mailto:info@triahealth.com">info@triahealth.com</a>
          </>
        </StyledText>
      </Row>
    </div>
  )
}

const StyledRow = styled(Row)`
  margin-top: 50px;
`

const StyledText = styled(Typography)`
  text-align: center;
`
