import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import ListItem from 'Pages/STOPProgram/components/ListItem'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Obstacle1Group.png'
import BenefitsImage2 from '../../../../Assets/Images/STOP/Timing.png'

export default function Obstacle1() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Obstacle #1:</STOPHeader>
          <STOPHeader>It’s Not the Right Time to Quit</STOPHeader>
          <Spacer top>
            <ul>
              <ListItem>
                <ScaledText>
                  There is never a “right” time to quit tobacco. Anytime energy and focus can be dedicated to quitting is a good time to quit.
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  Those that engaged in a structured program with a health care provider have a high chance of successfully quitting [and staying
                  quit.
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  Health care professionals can offer assistance with strategies to quit, counseling sessions, medication therapy options, and
                  continued support throughout the entire quit process.
                </ScaledText>
              </ListItem>
            </ul>
          </Spacer>
        </>
      }
      image={BenefitsImage}
      mobileImage={BenefitsImage2}
      imageBorder={false}
      altTag="Man at a computer"
    />
  )
}
