/* eslint-disable react-hooks/exhaustive-deps */
import SidebarNav from 'Components/SidebarNav'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useCareplan from 'hooks/useCareplan'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { getMedications } from 'reducers/medicationsSlice'
import { getCareplan, markArchiveDocumentRead, markArchiveResourceRead, markDocumentRead, markResourceRead } from 'reducers/recommendationsSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Medication } from 'types/Medication'
import { SidebarMenuHeader, SidebarMenuItemComponents } from 'types/SidebarNav'
import { byteArrayToPDF } from 'utilities/byteArrayToPDF'
import DataGridComponent from './components/DataGrid'
import CareplanHomeScreen from './components/Home'
import MedicationListPage from './components/MedicationList'
import Recommendations from './components/Recommendations'

export default function CarePlanScreen() {
  const location = useLocation()

  const route = location.pathname

  const [toggled, setToggled] = useState(false)
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [dropValue, setDropValue] = useState('')

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const careplan = useAppSelector(getCareplan)
  const analytics = useAnalytics(accessToken!)

  const { getCareplanDocument, editCareplanDocument, editCareplanResource } = useCareplan(accessToken!)
  const medications: Medication[] = useAppSelector(getMedications)
  const activeMedications = medications.filter(
    (med) => med.medicationStatus === 'Active' || med.medicationStatus === 'Recommended' || med.medicationStatus === 'Recommended Stop',
  )
  const [filteredMedications, setFilteredMedications] = useState<Medication[]>([])

  useEffect(() => {
    dispatch(setLoading(true))
    if (activeMedications) {
      setFilteredMedications(
        activeMedications
          .sort(function (a, b) {
            if (a.recommendation < b.recommendation) return -1
            if (a.recommendation > b.recommendation) return 1
            return 0
          })
          .reverse(),
      )
      dispatch(setLoading(false))
    }
  }, [])

  const setMobileSetting = () => {
    if (window.innerWidth < 1100) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const itemClicked = (link: string) => {
    setToggled(false)
    navigate(link)
  }

  const getArchiveDocument = (fileName: string, documentId: string, documentLink: string) => {
    navigate('')
    analytics.postAnalyticsEvent({
      category: `download_${route}_document_`,
      action: `download_${route}_document_`,
      label: `download`,
      screenName: 'CarePlan',
    })
    dispatch(setLoading(true))
    if (documentLink) {
      if (!documentLink.match(/^https?:\/\//i)) {
        const url = 'http://' + documentLink
        window.open(url)
      } else {
        const url = documentLink
        window.open(url)
      }
      editCareplanDocument(documentId)
      dispatch(markArchiveDocumentRead(documentId))
      dispatch(markDocumentRead(documentId))
      dispatch(setLoading(false))
    } else {
      getCareplanDocument(documentId)
        .then((res) => {
          byteArrayToPDF(fileName, res.data)
        })
        .then(() => {
          dispatch(markArchiveDocumentRead(documentId))
          dispatch(markDocumentRead(documentId))
          dispatch(setLoading(false))
        })
        .catch(() => {
          dispatch(setLoading(false))
        })
    }
  }

  function onClickResource(filePath, patientEducationResourcePid) {
    dispatch(setLoading(true))
    window.open(filePath)
    editCareplanResource(patientEducationResourcePid).then(() => {
      dispatch(markResourceRead(patientEducationResourcePid))
      dispatch(markArchiveResourceRead(patientEducationResourcePid))
      dispatch(setLoading(false))
    })
  }

  function filterMeds(filter) {
    window.scrollTo(0, 0)
    setDropValue(filter)
    if (filter === 'Recommended') {
      setFilteredMedications(medications.filter((med) => med.medicationStatus === 'Recommended' && med.alternatives.length === 0))
    } else if (filter === 'Recommended Stop') {
      setFilteredMedications(medications.filter((med) => med.medicationStatus === 'Recommended Stop'))
    } else if (filter === 'savings') {
      setFilteredMedications(medications.filter((med) => med.medicationStatus === 'Active' && med.alternatives.length > 0))
    } else if (filter === 'all') {
      setFilteredMedications(
        activeMedications
          .sort(function (a, b) {
            if (a.recommendation < b.recommendation) return -1
            if (a.recommendation > b.recommendation) return 1
            return 0
          })
          .reverse(),
      )
    }
  }

  const sidebarMenuItems: SidebarMenuItemComponents[] = [
    { title: 'Care Plan Home', icon: 'home', route: '/careplan', displayText: 'Home' },
    {
      title: 'Recommendations',
      icon: 'recommendations',
      route: '/careplan/recommendations',
      displayText: 'Recommendations',
      badge: window.sessionStorage.getItem('visitedRecs') === 'true' ? 0 : careplan?.allRecommendations.filter((x) => x.viewed === false).length ?? 0,
    },
    {
      title: 'Medication List',
      icon: 'pillBottle',
      route: '/careplan/medications',
      displayText: 'Medication List',
    },
    {
      title: 'Resources',
      icon: 'fileLines',
      route: '/careplan/resources',
      displayText: 'Resources',
      badge: careplan?.pharmacistResources.filter((x) => x.viewed === false).length ?? 0,
    },
    {
      title: 'History',
      icon: 'history',
      route: '/careplan/history',
      displayText: 'History',
    },
  ]

  const sidebarMenuHeader: SidebarMenuHeader = { title: 'Care Plan', route: '/careplan' }

  const totalMedRecommendations = medications.filter(
    (med) => med.alternatives.length > 0 || med.medicationStatus === 'Recommended Stop' || med.medicationStatus === 'Recommended',
  ).length

  const medicationSavings = medications.filter((med) => med.alternatives.length > 0).length
  const medicationDiscontinueLength = medications.filter((med) => med.medicationStatus === 'Recommended Stop').length
  const medicationRecommendationsLength = medications.filter((med) => med.medicationStatus === 'Recommended').length

  return (
    <div className="profile-page-container">
      <div className="profile-content-container">
        <SidebarNav
          sideMenuCollapsed={sideMenuCollapsed}
          isMobile={isMobile}
          toggled={toggled}
          itemClicked={(link) => itemClicked(link)}
          onCollapseMenu={() => {
            isMobile ? setToggled(false) : setSideMenuCollapsed(!sideMenuCollapsed)
          }}
          sidebarMenuItems={sidebarMenuItems}
          sidebarMenuHeader={sidebarMenuHeader}
          height={'600px'}
        />

        <StyledCareplanContainer>
          {route === '/careplan' && (
            <CareplanHomeScreen
              totalMedRecommendations={totalMedRecommendations}
              filterMeds={(filter) => filterMeds(filter)}
              setDropValue={setDropValue}
              isMobile={isMobile}
              onGetDocument={(fileName, documentId, documentLink) => getArchiveDocument(fileName, documentId, documentLink)}
              sideMenuToggled={() => setToggled(true)}
            />
          )}
          {route === '/careplan/recommendations' && (
            <Recommendations
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              onGetDocument={(fileName, documentId, documentLink) => getArchiveDocument(fileName, documentId, documentLink)}
            />
          )}
          {route === '/careplan/medications' && (
            <MedicationListPage
              medicationRecommendationsLength={medicationRecommendationsLength}
              medicationSavings={medicationSavings}
              medicationDiscontinueLength={medicationDiscontinueLength}
              dropValue={dropValue}
              filterMeds={(value) => filterMeds(value)}
              filteredMedications={filteredMedications.length === 0 && dropValue === '' ? activeMedications : filteredMedications}
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              onGetDocument={(fileName, documentId, documentLink) => getArchiveDocument(fileName, documentId, documentLink)}
            />
          )}
          {route === '/careplan/resources' && (
            <DataGridComponent
              icon={'fileLines'}
              pageTitle={'Resources'}
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              onClickResource={(filePath, patientEducationResourcePid) => onClickResource(filePath, patientEducationResourcePid)}
              onGetDocument={(fileName, documentId, documentLink) => getArchiveDocument(fileName, documentId, documentLink)}
            />
          )}
          {route === '/careplan/history' && (
            <DataGridComponent
              icon={'history'}
              pageTitle={'History'}
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              onClickResource={(filePath, patientEducationResourcePid) => onClickResource(filePath, patientEducationResourcePid)}
              onGetDocument={(fileName, documentId, documentLink) => getArchiveDocument(fileName, documentId, documentLink)}
            />
          )}
        </StyledCareplanContainer>
      </div>
    </div>
  )
}

const StyledCareplanContainer = styled.div`
  background-color: $neutral-color-1;
  color: $neutral-color-1;
  width: 100%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;
`
