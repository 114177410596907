import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import './ContentCard.scss'

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  buttonText?: string
  buttonLink?: string
  buttonOnClick?: () => void
  className?: string
  backgroundImage?: string
  styles?: React.CSSProperties
}

export default function ContentCard({ children, className, backgroundImage, buttonOnClick, buttonLink, buttonText }: Props) {
  return (
    <StyledColumn className={className}>
      {backgroundImage && <StyledImage src={backgroundImage} alt="Download Tria Health Mobile App" />}
      {children}
      <StyledCardButton pageName="dashboardGlucose" text={buttonText ?? ''} onClick={buttonOnClick} />
    </StyledColumn>
  )
}

const StyledCardButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary1};
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`

const StyledColumn = styled(Column)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  margin-bottom: 25px;
  border: ${({ theme }) => `2px solid ${theme.colors.primary1}`};
  position: relative;
  justify-content: space-between;
  height: 360px;
  @media ${sizes.tablet} {
    width: 20vw;
    height: 20vw;
  }
`

const StyledImage = styled.img`
  height: 100%;
  top: 10px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
