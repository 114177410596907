/* Social Links */
export const PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=com.tria.health'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/tria-health'
export const FACEBOOK_URL = 'https://www.facebook.com/triahealth/'
export const TWITTER_URL = 'https://twitter.com/TriaHealth'
export const APPSTORE_URL = 'https://apps.apple.com/us/app/tria-health/id1541412621'

/* Website Links */
export const HOME_URL = 'https://triahealth.com'
export const PHARMACY_ADVOCATE_URL = 'https://triahealth.com/Solutions/PharmacyAdvocateProgram#pharmacyadvocate'
export const DIABETES_MANAGEMENT_URL = 'https://triahealth.com/Solutions/DiabetesManagement#diabetesmanagement'
export const RX_PLAN_URL = 'https://triahealth.com/Solutions/RxPlanProtectionSuite#rxprotectionsuite'
export const SUCCESS_STORIES_URL = 'https://www.triahealth.com/patientstories'
export const MEDIA_URL = 'https://www.triahealth.com/media'
export const FAQ_URL = 'https://www.triahealth.com/frequently-asked-questions'
export const BLOG_URL = 'https://triahealth.com/blog'
export const PATIENTS_URL = 'https://triahealth.com/patients'
export const CAREERS_URL = 'https://triahealth.com/careers'
export const PRIVACY_URL = 'https://triahealth.com/privacy'
export const COMMUNICATION_POLICY_URL = 'https://triahealth.com/communicationpolicy'

export const ABOUT_URL = 'https://triahealth.com/about'
export const KNOWLEDGE_CENTER_URL = 'https://www.triahealth.com/knowledge-center'
export const RESEARCH_URL = 'https://triahealth.com/Resources/Research'
export const INDIVIDUAL_SOLUTIONS_URL = 'https://triahealth.com/individuals'
export const EMPLOYER_SOLUTIONS_URL = 'https://triahealth.com/employers'
export const OUTCOMES_SOLUTIONS_URL = 'https://triahealth.com/outcomes'
export const HOW_IT_WORKS_URL = 'https://www.triahealth.com/how-it-works'
