import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { DateSetting, Device } from 'types/Dashboards'

type Props = {
  isMobile: boolean
  readingFilter: DateSetting
  selectedDevice: Device
  cgmFilterOptions: DateSetting[]
  filterOptions: DateSetting[]
  startDate: Date | undefined
  endDate: Date | undefined
  adminWelltrak?: string
  onSetAdminWelltrak: (value) => void
  onChangeAdminWelltrak: () => void
  showCustomFields?: boolean
  hasDashboardOverridePermission?: boolean
  onChangeReadingFilter: (option: DateSetting) => void
  onChangeStartDate: (date: Date) => void
  onChangeEndDate: (date: Date) => void
  onCreatePDF: () => void
}

export default function FilterRow({
  readingFilter,
  isMobile,
  selectedDevice,
  cgmFilterOptions,
  filterOptions,
  startDate,
  endDate,
  adminWelltrak,
  onSetAdminWelltrak,
  onChangeAdminWelltrak,
  hasDashboardOverridePermission,
  showCustomFields,
  onChangeStartDate,
  onChangeEndDate,
  onChangeReadingFilter,
  onCreatePDF,
}: Props) {
  return (
    <ResponsiveContainer styles={{ minWidth: isMobile ? '100%' : 'none' }}>
      <Dropdown className="date-filter-select-container">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="date-filter-select">
          {readingFilter.label}
        </Dropdown.Toggle>

        {selectedDevice.cgm ? (
          <Dropdown.Menu>
            {cgmFilterOptions.map((option) => (
              <Dropdown.Item onClick={() => onChangeReadingFilter(option)}>{option.label}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            {filterOptions.map((option) => (
              <Dropdown.Item onClick={() => onChangeReadingFilter(option)}>{option.label}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
      <Button text="Export Summary" onClick={onCreatePDF} styles={isMobile ? { marginTop: 10, marginBottom: 10 } : { marginLeft: 10 }}></Button>

      {showCustomFields && (
        <>
          <div style={{ marginLeft: isMobile ? 0 : 5, minWidth: isMobile ? '100%' : 'none' }}>
            <ReactDatePicker
              selected={startDate}
              className="start-date-picker"
              onChange={(date: Date) => onChangeStartDate(date)}
              showYearDropdown
              scrollableYearDropdown
              placeholderText="Start Date"
              maxDate={new Date()}
              isClearable={false}
            />
          </div>
          <div style={{ minWidth: 10 }} />
          <div style={{ minWidth: isMobile ? '100%' : 'none' }}>
            <ReactDatePicker
              selected={endDate}
              className="start-date-picker"
              onChange={(date: Date) => onChangeEndDate(date)}
              showYearDropdown
              scrollableYearDropdown
              placeholderText="End Date"
              maxDate={new Date()}
            />
          </div>
          <div style={{ minWidth: 10 }} />
        </>
      )}
      {hasDashboardOverridePermission && !isMobile && (
        <Row styles={{ alignItems: 'center', marginLeft: 10 }}>
          <Typography>Admin Welltrak: </Typography>
          <Form.Control
            onChange={(e) => onSetAdminWelltrak(e.target.value)}
            value={adminWelltrak}
            style={{
              width: 200,
              marginRight: 5,
              marginLeft: 10,
              marginBottom: 0,
            }}
            placeholder=""
          />
          <div className="dashboard-reset-button" style={{ height: 38 }} onClick={() => onChangeAdminWelltrak()}>
            <Icon name="arrow-right" color="white" />
          </div>
        </Row>
      )}
    </ResponsiveContainer>
  )
}
