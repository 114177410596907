import Icon from 'Components/Base/Icon'
import colors from 'Styles/colors'
import React from 'react'
import { IconNames } from 'types/IconNames'
import './ContentHeader.scss'

interface Props {
  title: string
  icon?: IconNames
  color?: string
  fontSize?: string
}
export default function ContentHeader({ title, icon, color = colors.neutral.black, fontSize }: Props) {
  return (
    <>
      <div className="header-row">
        {icon && <Icon size="2x" color={color} name={icon} styles={{ marginRight: 10 }} />}
        <text className="header-title" style={{ color: color, fontSize: fontSize }}>
          {title}
        </text>
      </div>
    </>
  )
}
