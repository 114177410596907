import Column from 'Components/Base/Column'
import { sizes } from 'Styles/sizes'
import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

type Props = {
  onNavigate: (url: string) => void
}
export default function MediaColumn({ onNavigate }: Props) {
  const CAREGIVER_IMG = 'https://triacentral.blob.core.windows.net/dev-storage/images/CareGiverAddOn.png'
  const HOW_TO_TEST_VIDEO = 'https://www.youtube.com/watch?v=8BLqwvssv6k'

  return (
    <StyledGlucoseColumn>
      <img src={CAREGIVER_IMG} onClick={() => onNavigate('/profile/access')} alt="Keep loved ones in the loop" className="add-caregiver-image" />
      <ReactPlayer url={HOW_TO_TEST_VIDEO} width={'100%'} controls={true} style={{ marginBottom: 15 }} />
    </StyledGlucoseColumn>
  )
}

const StyledGlucoseColumn = styled(Column)`
  width: 120%;
  margin: 0%;
  @media ${sizes.laptop} {
    margin: 0% 5% 0% 5%;
  }
`
