import SidebarNav from 'Components/SidebarNav'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useMessagingCenter from 'hooks/useMessagingCenter'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Category, HelpText, MessageType, PatientMessage, TriaMessage } from 'types/MessagingCenter'
import { SidebarMenuHeader, SidebarMenuItemComponents } from 'types/SidebarNav'
import MessagingArchiveScreen from './Archive'
import MessagingHomeScreen from './Home'
import MessagingInboxScreen from './Inbox'

export default function MessagingCenter() {
  const location = useLocation()

  const route = location.pathname

  const [toggled, setToggled] = useState(false)
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false)
  const [showMenu, setShowMenu] = useState(true)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [triaMessages, setTriaMessages] = useState<TriaMessage[]>([])
  const [patientMessages, setPatientMessages] = useState<PatientMessage[]>([])
  const [messageCategories, setMessageCategories] = useState<Category[]>([])
  const [archivedPatientMessages, setArchivedPatientMessages] = useState<PatientMessage[]>([])
  const [archivedTriaMessages, setArchivedTriaMessages] = useState<TriaMessage[]>([])
  const [newMessageBool, setNewMessageBool] = useState<boolean>(false)
  const [currentCategory, setCurrentCategory] = useState<Category | undefined>()
  const [currentTypes, setCurrentTypes] = useState<MessageType[]>([])
  const [currentHelpText, setCurrentHelpText] = useState<HelpText[]>([])
  const [currentType, setCurrentType] = useState<MessageType>()
  const [typeValue, setTypeValue] = useState<number>(0)
  const [categoryValue, setCategoryValue] = useState<number | undefined>()
  const [replyToTriaMessageId, setReplyToTriaMessageId] = useState<number>()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const { getPatientMessagingCategories, getPatientMessages, getTriaMessages } = useMessagingCenter(accessToken!)

  useEffect(() => {
    dispatch(setLoading(true))
    getPatientMessagingCategories().then((res) => {
      setMessageCategories(res.data.categories)
    })
    getPatientMessages().then((res) => {
      setPatientMessages(res.data.patientInitiatedMessages)
      setArchivedPatientMessages(res.data.patientInitiatedMessages.filter((r) => r.patientArchivedDate !== null || r.triaClosedDate !== null))
      dispatch(setLoading(false))
    })
    getTriaMessages().then((res) => {
      setTriaMessages(res.data.triaInitiatedMessages.filter((m) => m.archivedDate === null))
      setArchivedTriaMessages(res.data.triaInitiatedMessages.filter((r) => r.archivedDate !== null))
    })
  }, [])

  const setMobileSetting = () => {
    if (window.innerWidth < 900) {
      setIsMobile(true)
      setShowMobileMenu(true)
      setShowMenu(false)
    } else {
      setIsMobile(false)
      setShowMobileMenu(false)
      setShowMenu(true)
    }
  }
  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const itemClicked = (link: string) => {
    setToggled(false)
    navigate(link)
  }

  const sidebarMenuItems: SidebarMenuItemComponents[] = [
    {
      title: 'Home',
      icon: 'home',
      route: '/messaging',
      displayText: 'Home',
      badge: triaMessages?.filter((message) => !message.viewedDate && !message.archivedDate).length ?? 0,
    },
    {
      title: 'Inbox',
      icon: 'envelope',
      route: '/messaging/inbox',
      displayText: 'Inbox',
      badge:
        patientMessages?.filter((message) => message.hasNewMessage && message.patientArchivedDate === null && message.triaClosedDate === null)
          .length ?? 0,
    },
    {
      title: 'Archive',
      icon: 'archive',
      route: '/messaging/archive',
      displayText: 'Archive',
      badge:
        patientMessages?.filter((message) => message.hasNewMessage && (message.triaClosedDate !== null || message.patientArchivedDate !== null))
          .length ?? 0,
    },
  ]

  const sidebarMenuHeader: SidebarMenuHeader = { title: 'Messages', route: '/messaging' }

  return (
    <div className="profile-page-container">
      <div className="profile-content-container">
        <SidebarNav
          sideMenuCollapsed={sideMenuCollapsed}
          isMobile={isMobile}
          toggled={toggled}
          itemClicked={(link) => itemClicked(link)}
          onCollapseMenu={() => {
            isMobile ? setToggled(false) : setSideMenuCollapsed(!sideMenuCollapsed)
          }}
          sidebarMenuItems={sidebarMenuItems}
          sidebarMenuHeader={sidebarMenuHeader}
          height={'600px'}
        />

        <StyledCareplanContainer>
          {route === '/messaging' && (
            <MessagingHomeScreen
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              triaMessages={triaMessages}
              setNewMessageBool={(boolean) => setNewMessageBool(boolean)}
              setCurrentCategory={(cat) => setCurrentCategory(cat)}
              setCurrentType={(type) => setCurrentType(type)}
              messageCategories={messageCategories}
              setTypeValue={(typeValue) => setTypeValue(typeValue)}
              setCategoryValue={(categoryValue) => setCategoryValue(categoryValue)}
              setCurrentTypes={(types: MessageType[]) => setCurrentTypes(types)}
              setCurrentHelpText={(helpText) => setCurrentHelpText(helpText)}
              setTriaMessages={(messages: TriaMessage[]) => setTriaMessages(messages)}
              setReplyToTriaMessageId={(number: number | undefined) => setReplyToTriaMessageId(number)}
            />
          )}
          {route === '/messaging/inbox' && (
            <MessagingInboxScreen
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              patientMessages={patientMessages}
              setPatientMessages={(messages) => setPatientMessages(messages)}
              messageCategories={messageCategories}
              setMessageCategories={(cat) => setMessageCategories(cat)}
              newMessageBool={newMessageBool}
              setNewMessageBool={(boolean) => setNewMessageBool(boolean)}
              currentCategory={currentCategory}
              setCurrentCategory={(cat) => setCurrentCategory(cat)}
              currentTypes={currentTypes}
              setCurrentTypes={(types) => setCurrentTypes(types)}
              currentHelpText={currentHelpText}
              setCurrentHelpText={(helpText) => setCurrentHelpText(helpText)}
              setCurrentType={(type) => setCurrentType(type)}
              currentType={currentType}
              typeValue={typeValue}
              setTypeValue={(typeValue) => setTypeValue(typeValue)}
              categoryValue={categoryValue}
              setCategoryValue={(categoryValue) => setCategoryValue(categoryValue)}
              replyToTriaMessageId={replyToTriaMessageId}
              setReplyToTriaMessageId={(number: number | undefined) => setReplyToTriaMessageId(number)}
            />
          )}
          {route === '/messaging/archive' && (
            <MessagingArchiveScreen
              isMobile={isMobile}
              sideMenuToggled={() => setToggled(true)}
              archivedTriaMessages={archivedTriaMessages}
              archivedPatientMessages={archivedPatientMessages}
              messageCategories={messageCategories}
              setArchivedPatientMessages={(messages) => setArchivedPatientMessages(messages)}
              setArchivedTriaMessages={(messages) => setArchivedTriaMessages(messages)}
              setPatientMessages={(messages) => setPatientMessages(messages)}
            />
          )}
        </StyledCareplanContainer>
      </div>
    </div>
  )
}

const StyledCareplanContainer = styled.div`
  background-color: $neutral-color-1;
  color: $neutral-color-1;
  width: 100%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;
`
