import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import ListItem from 'Pages/STOPProgram/components/ListItem'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Routine.png'

export default function Obstacle4() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Obstacle #4:</STOPHeader>
          <STOPHeader>Tobacco is Part of the Routine</STOPHeader>
          <Spacer top>
            <ul>
              <ListItem>
                <ScaledText>
                  Each day is filled with routines that are associated with tobacco, such as taking breaks, drinking coffee, after a meal, or talking
                  on the phone. When quitting tobacco, new routines will be made without tobacco present. These routines will become habit and more
                  natural as time passes.
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  Identifying triggers and making a clear connection to that trigger and the tobacco use is vital to quitting. Triggers can be
                  associated with “when I feel this way or see this person, I have the urge to use tobacco" or "when I go to this place, I have the
                  urge to use tobacco.”
                </ScaledText>
              </ListItem>
              <ListItem>
                <ScaledText>
                  After identifying triggers, pause for 10 minutes and do not give into the urge, the urge to use tobacco usually will go away.
                  Waiting or prolonging the time to use tobacco can be enough distraction to dismiss the urge and avoid use.
                </ScaledText>
              </ListItem>
            </ul>
          </Spacer>
        </>
      }
      image={BenefitsImage}
      altTag="Gum and pills on a table"
    />
  )
}
