import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'

type Props = {
  content: JSX.Element
  image: string
  altTag: string
  imageBorder?: boolean
  mobileImage?: string
}

export default function BenefitScreen({ content, image, altTag, imageBorder = true, mobileImage }: Props) {
  return (
    <StyledResponsiveContainer>
      <StyledColumn>{content}</StyledColumn>
      <ResponsiveContainer styles={{ width: '10%' }} />
      <StyledColumn>
        <StyledImage src={image} alt={altTag} showBorder={imageBorder} mobileImage={mobileImage !== undefined} />
        {mobileImage && <StyledMobileImage src={mobileImage} alt={altTag} />}
      </StyledColumn>
    </StyledResponsiveContainer>
  )
}

const StyledColumn = styled(Column)`
  flex: 1;
  width: 100%;
  @media (${sizes.tablet}) {
    width: 45%;
    overflow: none;
  }
`
const StyledResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (${sizes.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const StyledImage = styled.img<{ showBorder?: boolean; mobileImage?: boolean }>`
  object-fit: cover;
  width: 100%;
  min-height: -webkit-fill-available;
  max-height: 200px;
  display: ${(props) => (props.mobileImage ? 'none' : 'auto')};

  border: ${(props) => (props.showBorder ? '1px solid black' : 'none')};
  @media (${sizes.tablet}) {
    max-height: 1000px;
    display: flex;
  }
`
const StyledMobileImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 200px;
  @media (${sizes.tablet}) {
    display: none;
  }
`
