import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Chantix 1.png'
import Image2 from '../../../../Assets/Images/STOP/Chantix 2.png'
import Image3 from '../../../../Assets/Images/STOP/Chantix 3.png'

export default function Medications2() {
  return (
    <ProConScreen
      title="Medications: Varenicline (Chantix)"
      subtitle="An FDA approved prescription medication that reduces cravings for nicotine and helps people quit smoking. It works by blocking the effects of nicotine in the brain which then decreases the pleasurable effects of smoking It does not contain nicotine but can be alongside NRT products."
      subtitle2="It is typically started 1 week prior to quit date and can be used for several months."
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Easy to administer tablet',
        'Can be use with NRT products',
        'Works differently than other quit-smoking medicines, so great option if other medications have failed',
        'Most effective quit aid when using a single medication',
      ]}
      cons={[
        'Available by prescription only',
        'Twice daily dosing',
        'Must be taken with food and water',
        'Potential Side Effects: nausea, difficulty sleeping, vivid or strange dreams, and changes in mood or behavior',
      ]}
    />
  )
}
