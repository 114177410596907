import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
}
export default function ErrorText({ text }: Props) {
  return <StyledErrorText text={text} />
}

const StyledErrorText = styled.text<Props>`
  min-height: 25px;
  color: ${({ theme }) => theme.colors.error};
`
