import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function ProteinStep2({ isMobile, step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()

  useEffect(() => {
    setInfoPage(true)
    window.scrollTo(0, 0)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
  }, [step])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h1">{sectionInfo?.titles.find((title) => title.titleId === 2)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 2)?.paragraph ?? ''}</Typography>
        </Row>
        <ResponsiveContainer>
          <Column styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
            <img src={sectionInfo?.images.find((image) => image.imageId === 2)?.url} alt="scale" style={{ width: isMobile ? '100%' : '396px' }} />
            <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px', width: isMobile ? '' : '396px' }}>
              <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
                {' '}
                {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 3)?.paragraph ?? ''}{' '}
                <i>{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</i>
              </p>
            </Row>
            <Column styles={{ margin: isMobile ? '20px auto ' : '20px 0px ', width: isMobile ? '' : '396px' }}>
              <Typography variant="h2">{sectionInfo?.titles.find((title) => title.titleId === 3)?.titleText ?? ''}</Typography>
              <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 4)?.paragraph ?? ''}</Typography>
            </Column>
          </Column>
          <Column styles={{ margin: isMobile ? '20px auto' : '14px 100px' }}>
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 3)?.url}
              alt="scale"
              style={{ width: isMobile ? '100%' : '396px', marginTop: 5 }}
            />
            <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px', width: isMobile ? '' : '396px' }}>
              <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
                {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 5)?.paragraph ?? ''}{' '}
                <i>{sectionInfo?.titles.find((title) => title.titleId === 5)?.titleText ?? ''}</i>
              </p>
            </Row>
            <Column styles={{ margin: isMobile ? '20px auto ' : '20px 0px', width: isMobile ? '' : '396px' }}>
              <Typography variant="h2">{sectionInfo?.titles.find((title) => title.titleId === 3)?.titleText ?? ''}</Typography>
              <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 6)?.paragraph ?? ''}</Typography>
            </Column>
          </Column>
        </ResponsiveContainer>
      </Column>
    </ResponsiveContainer>
  )
}
