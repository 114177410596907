import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import PageContainer from 'Components/PageContainer'
import ProgramScreen from 'Components/ProgramScreen'
import { sizes } from 'Styles/sizes'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Challenge, ChallengeSection } from 'types/Challenge'
import { reflectionQuestionList } from '../Components/reflectionQuestion'

interface Props {
  challenge?: Challenge
  sectionId: number
  setCurrentChallenge: (challenge: Challenge) => void
  setSectionId: (section: number) => void
  isMobile: boolean
  setStep: (step: number) => void
}
export default function SectionIntroPage({ challenge, setCurrentChallenge, setSectionId, isMobile, setStep }: Props) {
  const navigate = useNavigate()
  const token = useAppSelector(getAccessToken) ?? ''
  const { getChallenges } = useChallenges(token)
  const [section, setSection] = useState<ChallengeSection>()
  const [lightColor, setLightColor] = useState<boolean>(false)
  const [reflectionQuestionArray, setReflectionQuestionArray] = useState<{ section: number; question: string; tip?: string }[]>()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    setReflectionQuestionArray(
      reflectionQuestionList.filter(
        (q) => q.section === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
      ) ?? '',
    )
    getChallenges()
      .then((res) => {
        setSectionId(
          res.data.challenges
            .find((challenge) =>
              challenge.challengeSections.find(
                (section) =>
                  section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
              ),
            )
            .challengeSections.find(
              (section) =>
                section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
            ).challengeSectionPId,
        )
        setCurrentChallenge(
          res.data.challenges.find((challenge) =>
            challenge.challengeSections.find(
              (section) =>
                section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
            ),
          ),
        )
        setSection(
          res.data.challenges
            .find((challenge) =>
              challenge.challengeSections.find(
                (section) =>
                  section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
              ),
            )
            .challengeSections.find(
              (section) =>
                section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
            ),
        )
        hexIsLight(
          res.data.challenges.find((challenge) =>
            challenge.challengeSections.find(
              (section) =>
                section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
            ),
          ).challengeHexColor,
        )
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  function hexIsLight(color) {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substring(0, 0 + 2), 16)
    const c_g = parseInt(hex.substring(2, 2 + 2), 16)
    const c_b = parseInt(hex.substring(4, 4 + 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    return setLightColor(brightness > 155)
  }

  const onClickGetStarted = () => {
    section && setStep(section?.currentStep === 0 || section?.completed ? 1 : section?.currentStep)
    if (section?.numberOfSteps === section?.currentStep && reflectionQuestionArray && reflectionQuestionArray?.length > 0 && !section?.completed) {
      navigate(`/reflection/${section?.challengeSectionPId}`)
    } else {
      navigate(`/challenge/section/${section?.challengeSectionPId}/${section?.currentStep === 0 || section?.completed ? 1 : section?.currentStep}`)
    }
  }

  return section ? (
    <PageContainer styles={isMobile ? { border: '1px solid white', boxShadow: 'none' } : {}}>
      <ProgramScreen
        content={
          isMobile ? (
            <>
              <Column>
                <Row styles={{ margin: '10px auto 10px 0px' }} onClick={() => navigate(`/challenges/${challenge?.challengePId}`)}>
                  <Icon name="back" styles={{ marginRight: 10 }} size="xl" />
                  <Typography font="bold" styles={{ marginRight: 5 }}>
                    Exit
                  </Typography>
                </Row>
                <div
                  style={{
                    backgroundColor: challenge?.challengeHexColor,
                    color: lightColor ? '#000' : 'white',
                    borderRadius: 15,
                    padding: '3px 40px',
                    margin: '0px auto',
                    textAlign: 'center',
                  }}
                >
                  <Typography>{challenge?.name ?? ''}</Typography>
                </div>
                <StyledTitle>{section?.name}</StyledTitle>
                <Typography styles={{ textAlign: 'center' }}>{section?.subtitle ?? ''}</Typography>
                <img src={section.imageUrl} alt="section" />
                <Row styles={{ margin: '0px auto 20px auto' }}>
                  <Icon name={section?.difficulty.toLocaleLowerCase() === 'easy' ? 'gauge-easy' : 'gauge-average'} styles={{ marginRight: 20 }} />
                  <Typography font="bold" styles={{ marginRight: 5 }}>
                    Level of Difficulty:
                  </Typography>
                  <Typography>{`${section?.difficulty}`}</Typography>
                </Row>
                <Row styles={{ margin: '0px auto 0px auto' }}>
                  <Icon name="alarm-clock" styles={{ marginRight: 20 }} />
                  <Typography font="bold" styles={{ marginRight: 5 }}>
                    Estimated Time:{' '}
                  </Typography>
                  <Typography>{`${section?.estimatedTime} minutes`}</Typography>
                </Row>
              </Column>
            </>
          ) : (
            <>
              <Column styles={{ marginTop: '8%' }}>
                <div
                  style={{
                    backgroundColor: challenge?.challengeHexColor,
                    color: lightColor ? '#000' : 'white',
                    borderRadius: 15,
                    padding: '3px 40px',
                    marginRight: 'auto',
                    textAlign: 'center',
                  }}
                >
                  <Typography>{challenge?.name ?? ''}</Typography>
                </div>
                <StyledTitle>{section?.name}</StyledTitle>
                <Typography size={20} styles={{ marginBottom: '10%' }}>
                  {section?.subtitle ?? ''}
                </Typography>
                <Row styles={{ marginBottom: 20 }}>
                  <Icon
                    name={section?.difficulty.toLocaleLowerCase() === 'easy' ? 'gauge-easy' : 'gauge-average'}
                    styles={{ marginRight: 20, marginTop: 5 }}
                  />
                  <Typography font="bold" styles={{ marginRight: 5 }} size={20}>
                    Level of Difficulty:
                  </Typography>
                  <Typography size={20}>{`${section?.difficulty}`}</Typography>
                </Row>
                <Row>
                  <Icon name="alarm-clock" styles={{ marginRight: 20, marginTop: 5 }} />
                  <Typography font="bold" styles={{ marginRight: 5 }} size={20}>
                    Estimated Time:{' '}
                  </Typography>
                  <Typography size={20}>
                    {section.estimatedTime === 1 ? `${section?.estimatedTime} minute` : `${section?.estimatedTime} minutes`}
                  </Typography>
                </Row>
              </Column>
            </>
          )
        }
        image={isMobile ? '' : section?.imageUrl ?? ''}
        altTag="sectionImage"
      />

      <ResponsiveContainer>
        {!isMobile && (
          <StyledButton style={{ backgroundColor: '#425563' }} onClick={() => navigate(`/challenges/${challenge?.challengePId}`)}>
            Back
          </StyledButton>
        )}
        <StyledButton style={{ backgroundColor: '#7A9A01', marginTop: isMobile ? 30 : 10 }} onClick={() => onClickGetStarted()}>
          Get Started
        </StyledButton>
      </ResponsiveContainer>
    </PageContainer>
  ) : (
    <></>
  )
}

const StyledTitle = styled.span`
  font-family: 'Raleway-Bold';
  text-align: center;
  margin-top: 35px;
  margin-bottom: 55px;
  line-height: 75px;
  font-size: 6vw;
  @media ${sizes.mobileL} {
    font-size: 8vw;
  }
  @media ${sizes.tablet} {
    font-size: 4vw;
  }
  @media ${sizes.desktop} {
    font-size: 3vw;
  }
  @media only screen and (min-width: 1200px) {
    text-align: left;
  }
`

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-right: 20px;
    margin-bottom: 0px;
  }
`
