import Row from 'Components/Base/Row'
import Template3 from 'Pages/CTLChallenges/Components/Templates/Template3'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function SleepStep6({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])

  return (
    <Template3
      imgSrc={sectionInfo?.images.find((image) => image.imageId === 12)?.url ?? ''}
      isMobile={isMobile}
      title={sectionInfo?.titles.find((title) => title.titleId === 5)?.titleText ?? ''}
      textSection={
        <ol style={{ fontSize: 20, marginLeft: isMobile ? -20 : -25 }}>
          <Row styles={{ margin: isMobile ? '20px 0px' : '5px 0px' }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 26)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '5px 0px' }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 27)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '5px 0px' }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 28)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px 0px' : '5px 0px' }}>
            <li></li>
            <p style={{ marginRight: 5, fontFamily: 'Raleway', fontSize: 20 }}>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 29)?.paragraph ?? ''}
            </p>
          </Row>
        </ol>
      }
    />
  )
}
