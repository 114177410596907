import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Spacer from 'Components/Base/Spacer'
import Typography from 'Components/Base/Typography'
import ContentModal from 'Components/ContentModal'
import { sizes } from 'Styles/sizes'
import React, { useState } from 'react'
import styled from 'styled-components'
import STOPHeader from '../STOPHeader'
import ScaledText from '../ScaledText'
import ProductColumn from './ProductColumn'
import { TOBACCO_PRODUCTS, TobaccoProduct } from './tobaccoProducts'

export default function TobaccoProducts() {
  const [modalOpen, setModalOpen] = useState(false)
  const [currentProduct, setCurrentProduct] = useState<TobaccoProduct>()

  const viewProduct = (product: TobaccoProduct) => {
    setModalOpen(true)
    setCurrentProduct(product)
  }

  return (
    <div>
      <STOPHeader>The Different Types of Tobacco Products</STOPHeader>
      <ScaledText>Click any of the cards to learn more about the different types of tobacco products.</ScaledText>
      <Spacer top>
        <ResponsiveContainer styles={{ justifyContent: 'space-between' }}>
          <ProductColumn
            title="Inhaled Tobacco Products"
            products={TOBACCO_PRODUCTS.inhaled}
            styles={{ minWidth: '70%' }}
            onSetProduct={(product) => viewProduct(product)}
          />
          <StyledMobileSpacer top />
          <ProductColumn title="Smokeless Tobacco Products" products={TOBACCO_PRODUCTS.smokeless} onSetProduct={(product) => viewProduct(product)} />
        </ResponsiveContainer>
      </Spacer>
      <ContentModal isOpen={modalOpen} onToggle={(isOpen) => setModalOpen(isOpen)}>
        {currentProduct && (
          <>
            <Column>
              <img src={currentProduct.image} alt="" style={{ border: '1px solid black', marginBottom: 25 }} />
              <Column style={{ textAlign: 'center' }}>
                <Spacer bottom>
                  <ScaledText size={25} bold>
                    {currentProduct.name}
                  </ScaledText>
                </Spacer>
                <Typography>{currentProduct.description}</Typography>
              </Column>
            </Column>
          </>
        )}
      </ContentModal>
    </div>
  )
}

const StyledMobileSpacer = styled(Spacer)`
  display: block;
  @media (${sizes.tablet}) {
    display: none;
  }
`
