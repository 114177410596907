import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'

type Props = {
  children?: React.ReactChild | React.ReactChild[]
  height?: string
  width?: string
  borderRadius?: string
  onClick?: () => void
  styles?: React.CSSProperties
}

export default function Card({ children, height = '140px', width = '140px', borderRadius = '15px', onClick, styles }: Props) {
  return (
    <StyledCardContainer height={height} width={width} borderRadius={borderRadius} style={{ backgroundColor: 'white', ...styles }} onClick={onClick}>
      {children}
    </StyledCardContainer>
  )
}

const StyledCardContainer = styled.div<{ height: string; width: string; borderRadius: string }>`
  cursor: pointer;
  height: ${(props) => props.height};
  margin-bottom: 25px;
  width: 100%;
  border-radius: ${(props) => props.borderRadius};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  @media ${sizes.tablet} {
    width: ${(props) => props.width};
    margin-bottom: 0px;
  }
`
