import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Typography from 'Components/Base/Typography'
import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import useEnrollment from 'hooks/useEnrollment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { TobaccoFormValues } from 'types/Appointment'

interface Props {
  loading?: boolean
  guid?: string
  onBackPressed: () => void
  onError: () => void
  onSuccess: (isStop: boolean) => void
  errorMessage?: string
  hasStop: boolean
}
export default function ProgramForm({ onBackPressed, errorMessage, guid, onError, onSuccess, loading, hasStop }: Props) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { postAppointmentType } = useEnrollment()

  const [appointmentValues, setAppointmentValues] = useState<TobaccoFormValues>({
    enrollmentFormGuid: undefined,
    isSTOP: false,
  })
  const { isSTOP } = appointmentValues

  const postAppointmentTypeForm = (values: TobaccoFormValues) => {
    dispatch(setLoading(true))

    values.enrollmentFormGuid = guid
    postAppointmentType(values)
      .then(() => {
        onSuccess(values.isSTOP)
      })
      .catch(() => {
        onError()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  return (
    <StyledContainer>
      <h1 className="id-header">Appointment Type</h1>

      <StyledSubtitle>This will determine the main goals of your consultation. Please select one option below:</StyledSubtitle>

      <Column>
        <StyledCheckboxRow
          style={{
            marginBottom: 0,
          }}
          onClick={() =>
            setAppointmentValues({
              ...appointmentValues,
              isSTOP: false,
            })
          }
        >
          <Checkbox
            checked={!isSTOP}
            styles={{
              color: isSTOP ? theme.colors.primary1 : theme.colors.black,
              marginRight: 5,
            }}
            onClick={() =>
              setAppointmentValues({
                ...appointmentValues,
                isSTOP: false,
              })
            }
          />
          <StyledSectionTitle>
            <Typography font="bold">Pharmacy Advocate Program</Typography>
          </StyledSectionTitle>
        </StyledCheckboxRow>
        <StyledDescriptionText size={18}>
          The Pharmacy Advocate Program includes a comprehensive medication review, lifestyle and preventative care assessment. The goal of this
          appointment would be to review your current health and identify opportunities for improvement and/or savings.
        </StyledDescriptionText>
      </Column>

      {hasStop && (
        <Column
          styles={{
            marginBottom: 50,
          }}
        >
          <StyledCheckboxRow
            style={{
              marginBottom: 0,
            }}
            onClick={() =>
              setAppointmentValues({
                ...appointmentValues,
                isSTOP: true,
              })
            }
          >
            <Checkbox
              checked={isSTOP}
              styles={{
                color: isSTOP ? theme.colors.primary1 : theme.colors.black,
                marginRight: 5,
              }}
              onClick={() =>
                setAppointmentValues({
                  ...appointmentValues,
                  isSTOP: true,
                })
              }
            />
            <StyledSectionTitle>
              <Typography font="bold">STOP - Tobacco Cessation Program</Typography>
            </StyledSectionTitle>
          </StyledCheckboxRow>
          <StyledDescriptionText size={18}>
            Tria Health's S.T.O.P. Program is for members serious about improving their health and quitting tobacco. Tria’s pharmacists work
            one-on-one with you to develop a personalized quit plan that is customized to fit your lifestyle.
          </StyledDescriptionText>
        </Column>
      )}

      {errorMessage && <StyledErrorText styles={{ textAlign: 'center', marginBottom: 10 }}>{errorMessage}</StyledErrorText>}
      <StyledButtonContainer>
        <StyledButton pageName="appointment_program" text="Back" styles={{ backgroundColor: '#425563' }} onClick={onBackPressed} />
        <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
        <StyledButton pageName="appointment_program" text="Next" loading={loading} onClick={() => postAppointmentTypeForm(appointmentValues)} />
      </StyledButtonContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  @media ${sizes.tablet} {
    width: 80%;
    margin-top: 80px;
  }
`

const StyledSubtitle = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;
`

const StyledSectionTitle = styled.div`
  font-size: 18px;
  cursor: pointer;
  @media ${sizes.tablet} {
    font-size: 40px;
  }
`

const StyledDescriptionText = styled(Typography)`
  text-align: left;
  margin-left: 0px;
  @media ${sizes.tablet} {
    margin-left: 30px;
  }
`

const StyledCheckboxRow = styled.div`
  align-content: left;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: left;
  align-items: center;
  @media ${sizes.tablet} {
    justify-content: flex-start;
    align-content: center;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary1};
  margin-right: 20px;
  @media ${sizes.tablet} {
    width: 25%;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  flex-direction: column;
  @media ${sizes.tablet} {
    flex-direction: row;
  }
`
