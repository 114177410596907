import Logo1 from 'Assets/Images/Vector1.png'
import Logo2 from 'Assets/Images/Vector2.png'
import Logo3 from 'Assets/Images/Vector3.png'
import React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
  styles?: React.CSSProperties
  height?: number
  imageWidth?: number
}

export default function LoadingIndicator({ imageWidth, styles, height }: Props) {
  return (
    <div style={{ height: height ?? 'auto' }}>
      <StyledContainer style={{ ...styles }}>
        <div style={{ position: 'relative', width: imageWidth ?? 'auto' }}>
          <StyledImage1 src={Logo1} alt="Tria logo" />
          <StyledImage2 src={Logo2} alt="Tria logo" />
          <StyledImage3 src={Logo3} alt="Tria logo" />
        </div>
      </StyledContainer>
    </div>
  )
}

const StyledContainer = styled.div`
  margin-right: 40px;
`

const blinker = keyframes`
  50% {
    opacity: 0;
  }
`

const StyledImage = styled.img`
  animation: ${blinker} 3s linear infinite;
`

const StyledImage1 = styled(StyledImage)`
  margin-top: 10px;
  margin-bottom: 15px;
`

const StyledImage2 = styled(StyledImage)`
  position: absolute;
  left: 30px;
  animation-delay: 1s;
`
const StyledImage3 = styled(StyledImage)`
  position: absolute;
  left: 62px;
  bottom: 0px;
  animation-delay: 2s;
`
