import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import Template5 from 'Pages/CTLChallenges/Components/Templates/Template5'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function ExerciseStep2({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])

  return (
    <Template5
      isMobile={isMobile}
      textSection={
        <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
          <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
            <Typography variant="h1">{sectionInfo?.titles.find((title) => title.titleId === 2)?.titleText ?? ''}</Typography>
          </Row>
          <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
            <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
              <span style={{ fontWeight: 'bold' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 9)?.paragraph ?? ''} </span>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 10)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
            <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
              <span style={{ fontWeight: 'bold' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 11)?.paragraph ?? ''} </span>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 12)?.paragraph ?? ''}
            </p>
          </Row>
          <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
            <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
              <span style={{ fontWeight: 'bold' }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 13)?.paragraph ?? ''} </span>
              {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 14)?.paragraph ?? ''}
            </p>
          </Row>
        </Column>
      }
      imgSection={
        <Column styles={{ width: '100%' }}>
          <img
            src={sectionInfo?.images.find((image) => image.imageId === 6)?.url}
            alt="scale"
            style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
          />
          <img
            src={sectionInfo?.images.find((image) => image.imageId === 7)?.url}
            alt="scale"
            style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
          />
          <img
            src={sectionInfo?.images.find((image) => image.imageId === 8)?.url}
            alt="scale"
            style={{ width: isMobile ? '100%' : '190px', marginLeft: 'auto' }}
          />
        </Column>
      }
    />
  )
}
