import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from 'Components/Base/Checkbox'
import React from 'react'
import { TableData } from 'types/TableData'

export type ColumnDefinitionType<T extends keyof TableData> = {
  key: T
  header: string
}
type TableProps<T extends keyof TableData> = {
  data: Array<TableData>
  columns: Array<ColumnDefinitionType<T>>
  centerData?: boolean
}

export default function DataTable<T extends keyof TableData>({ data, columns, centerData = false }: TableProps<T>) {
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#00677f',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 15,
      maxWidth: 50,
    },
  }))(TableCell)

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow)

  return (
    <TableContainer component={Paper}>
      <Table className="table" aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((data) => (
              <StyledTableCell style={{ textAlign: 'center' }}>{data.header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <StyledTableRow>
                {columns.map((column) => {
                  const isBool = typeof row[column.key] === 'boolean'
                  return !isBool ? (
                    <StyledTableCell align={`${centerData ? 'center' : 'left'}`}>{`${row[column.key]}`}</StyledTableCell>
                  ) : (
                    <TableCell
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        borderBottom: 0,
                      }}
                    >
                      <Checkbox
                        styles={{
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                        checked={isBool}
                      />
                    </TableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
