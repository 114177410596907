import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import PageContainer from 'Components/PageContainer'
import { sizes } from 'Styles/sizes'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import React, { useEffect } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Challenge } from 'types/Challenge'

interface Props {
  isMobile: boolean
  currentChallenge?: Challenge
  setCurrentChallenge: (challenge: Challenge) => void
}
export default function CompletionPage({ isMobile, setCurrentChallenge, currentChallenge }: Props) {
  const navigate = useNavigate()
  const token = useAppSelector(getAccessToken) ?? ''
  const { challengeCompletion, getChallenges } = useChallenges(token)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    getChallenges()
      .then((res) => {
        const challenge = res.data.challenges.find((challenge) =>
          challenge.challengeSections.find(
            (section) =>
              section.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
          ),
        )
        setCurrentChallenge(challenge)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  return (
    <div>
      <PageContainer styles={isMobile ? { border: '1px solid white', boxShadow: 'none' } : {}}>
        <Row>
          {!isMobile && (
            <img
              src="https://triacentral.blob.core.windows.net/dev-storage/portal/Challenges/Challenge%20Complete.png"
              alt="stars"
              style={{ height: 394, width: 394, transform: 'rotate(-33deg) ', marginRight: 'auto' }}
            />
          )}
          <img
            src="https://triacentral.blob.core.windows.net/dev-storage/portal/Challenges/Challenge%20Complete.png"
            alt="stars"
            style={
              isMobile
                ? { height: 276, width: 276, margin: '0px auto auto auto' }
                : { height: 394, width: 394, transform: 'rotate(33deg) rotateY(180deg)' }
            }
          />
        </Row>
        <StyledTitle>Great Job!</StyledTitle>
        <Row center styles={{ margin: '20px 0px', textAlign: 'center' }}>
          <Typography>By completing challenges, you have the opportunity to learn more about how to keep yourself healthy and active.</Typography>
        </Row>
        <StyledButton
          style={{ backgroundColor: '#00677f', marginTop: '10px' }}
          onClick={() => navigate(`/challenges/${currentChallenge?.challengePId}`)}
        >
          See My Progress
        </StyledButton>
      </PageContainer>
    </div>
  )
}

const StyledTitle = styled.span`
  font-family: 'Raleway-Bold';
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10%;
  font-size: 8vw;
  @media ${sizes.mobileL} {
    font-size: 8vw;
  }
  @media ${sizes.tablet} {
    font-size: 4vw;
  }
  @media ${sizes.desktop} {
    font-size: 3vw;
  }
  @media only screen and (min-width: 600px) {
    margin-top: -10%;
  }
`

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin: 20px auto;
`
