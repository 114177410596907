import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Container from './Container'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
  setSelectedCorrectAnswer: (infoPage: boolean) => void
  setDragAndDrop: (c: boolean) => void
}

export default function DragAndDrop({ step, setInfoPage, setSelectedCorrectAnswer, setDragAndDrop }: Props) {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
    window.scrollTo(0, 0)
    setDragAndDrop(true)
    setInfoPage(false)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])
  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <p style={{ margin: '20px auto', font: 'Raleway', fontSize: 20, textAlign: 'center' }}>
        Drag & drop each goal into their <b>correct</b> SMART category:
      </p>
      <Container isMobile={isMobile} step={step} setInfoPage={setInfoPage} setSelectedCorrectAnswer={(c: boolean) => setSelectedCorrectAnswer(c)} />
    </DndProvider>
  )
}
