import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Typography from 'Components/Base/Typography'
import type { CSSProperties, FC } from 'react'
import React, { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from 'types/Challenge'

const style: CSSProperties = {
  border: '1px solid #828282',
  width: 200,
  height: 300,
  textAlign: 'center',
  backgroundColor: 'white',
  cursor: 'move',
}

export interface CardProps {
  id: string
  text: string
  targetId: number
  moveCard: (id: string, to: number) => void
  findCard: (id: string) => { index: number }
  isMobile: boolean
}

interface Item {
  id: string
  originalIndex: number
}

export const Card: FC<CardProps> = memo(function Card({ id, text, targetId, moveCard, findCard, isMobile }) {
  const originalIndex = findCard(id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveCard(droppedId, originalIndex)
        }
      },
    }),
    [id, originalIndex, moveCard],
  )

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id)
          moveCard(draggedId, overIndex)
        }
      },
    }),
    [findCard, moveCard],
  )

  const opacity = isDragging ? 0 : 1
  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        border: '1px solid #828282',
        borderRadius: 10,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
        width: isMobile ? 300 : 200,
        height: isMobile ? 130 : 300,
        textAlign: 'center',
        backgroundColor: 'white',
        cursor: 'move',
        marginRight: 10,
        opacity,
      }}
    >
      <Column>
        <Icon name="bars" styles={{ marginBottom: 'auto', marginTop: isMobile ? 0 : '15%' }} />
        <Typography styles={{ marginTop: isMobile ? 0 : '25%', height: 40, marginLeft: 40, marginRight: 40, fontSize: 24 }}>{text}</Typography>
        <Typography styles={{ marginTop: isMobile ? 23.5 : '58%' }}>{findCard(id).index === targetId ? 'Correct' : 'Incorrect'}</Typography>
        <div
          style={{
            backgroundColor: findCard(id).index === targetId ? '#7A9A01' : '#E40046',
            width: '100%',
            height: 20,
            borderRadius: '0px 0px 5px 5px',
          }}
        ></div>
      </Column>
    </div>
  )
})
