import Row from 'Components/Base/Row'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Deep Breath.png'

export default function QuittingBenefits() {
  return (
    <BenefitScreen
      altTag="Woman breathing in air"
      content={
        <>
          <p>
            <STOPHeader>There are Multiple Health Benefits of Quitting Tobacco</STOPHeader>
          </p>
          <p>
            <ScaledText size={20}>
              Quitting tobacco improves health regardless of age, number of years using tobacco, or amount of tobacco used a day.
            </ScaledText>
          </p>
          <p>
            <ScaledText size={20}>
              <span style={{ fontWeight: 'bold' }}>Smoking tobacco</span> has significantly negative impacts on the lungs and heart. Smoking increases
              the risk of heart disease 2 to 4 times compared to those who do not smoke. Lung cancer risk is increased by 25% for both men and women.
            </ScaledText>
          </p>
          <p>
            <ScaledText size={20}>
              <span style={{ fontWeight: 'bold' }}>Smokeless tobacco, or chewing tobacco</span>, is also associated with many health problems. Using
              smokeless tobacco can lead to nicotine addiction, mouth diseases, and increased risk of heart disease and stroke. For women that are
              pregnant, it can cause preterm delivery or still birth. Smokeless tobacco increases risk of cancer of the mouth, esophagus, and
              pancreas.
            </ScaledText>
          </p>
          <p style={{ marginBottom: 0 }}>
            <Row>
              <ScaledText size={20}>
                <span style={{ fontWeight: 'bold' }}>Exposure to secondhand smoke (SHS)</span> from burning tobacco products can cause multiple health
                conditions. It can lead to premature death among people who do not smoke. There is no safe level of exposure to secondhand smoke; even
                brief exposure can cause immediate harm.
              </ScaledText>
            </Row>
          </p>
        </>
      }
      image={BenefitsImage}
    />
  )
}
