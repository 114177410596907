import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import { Medication } from '../types/Medication'

export interface MedicationsState {
  medications: Medication[]
  status: 'idle' | 'loading' | 'failed'
}

const initialState: MedicationsState = {
  medications: [],
  status: 'idle',
}
export const loadMedications = createAsyncThunk('medications/load', async (token: string) => {
  const response: Promise<{ data: Medication[] }> = useApi(token).get('api/medications', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const medicationsSlice = createSlice({
  name: 'medications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadMedications.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadMedications.fulfilled, (state, action) => {
        state.status = 'idle'
        state.medications = action.payload.data
      })
      .addCase(loadMedications.rejected, (state) => {
        state.status = 'idle'
      })
  },
})

export const getMedications = (state: RootState) => state.root.medications.medications
export const getMedicationsStatus = (state: RootState) => state.root.medications.status

export default medicationsSlice.reducer
