import ChewingTobaccoImage from '../../../../Assets/Images/STOP/Chewing Tobacco.png'
import CigaretteImage from '../../../../Assets/Images/STOP/Cigarettes.png'
import CigarImage from '../../../../Assets/Images/STOP/Cigars.png'
import DissolvableTobaccoImage from '../../../../Assets/Images/STOP/Dissolvable Tobacco.png'
import ElectronicCigaretteImage from '../../../../Assets/Images/STOP/Electronic Cigarettes.png'
import HookahImage from '../../../../Assets/Images/STOP/Hookah.png'
import SnuffImage from '../../../../Assets/Images/STOP/Snuff.png'
import VapingImage from '../../../../Assets/Images/STOP/Vaping.png'

export type TobaccoProduct = {
  name: string
  image: string
  description: string
}
export const TOBACCO_PRODUCTS: { inhaled: TobaccoProduct[]; smokeless: TobaccoProduct[] } = {
  inhaled: [
    {
      name: 'Cigarettes',
      image: CigaretteImage,
      description:
        'Tube-shaped tobacco product that is inhaled into the lungs. It is wrapped in paper and made of tobacco leaves. Cigarettes contain nicotine and other carcinogens. Each cigarette contains between 8-30mg of nicotine.',
    },
    {
      name: 'Cigars',
      image: CigarImage,
      description:
        'A roll of tobacco wrapped in tobacco leaf or in a substance that contains tobacco. Cigars come as large cigars, cigarillos, and little cigars; each containing toxic or carcinogenic compounds. Cigars can contain as much nicotine as a full pack of cigarettes.',
    },
    {
      name: 'Vaping',
      image: VapingImage,
      description:
        'A battery-operated device that heats to a vapor when inhaled and contains nicotine, flavoring, and other chemicals. The amount of nicotine varies per device. While vaping is not a tobacco product, it is a nicotine containing product that still has negative effects on the body.',
    },
    {
      name: 'Electronic Cigarettes',
      image: ElectronicCigaretteImage,
      description:
        'A device that heats an aerosol when inhaled and contains nicotine, flavoring, and other chemicals. The amount of nicotine varies per e-cigarette and they typically contain propylene glycol and/or vegetable glycerin which can increase lung and airway irritation.',
    },
    {
      name: 'Hookah',
      image: HookahImage,
      description:
        'A water pipe that is made to smoke flavored tobacco. It contains nicotine and added carcinogens, and due to heat source, the inhaled smoke contains dangerous substances including carbon monoxide. In a single water pipe hookah session, users are exposed to up to 9 times the carbon monoxide and 1.7 times the nicotine of a single cigarette.',
    },
  ],
  smokeless: [
    {
      name: 'Chewing Tobacco',
      image: ChewingTobaccoImage,
      description:
        'A smokeless tobacco product in the form of leaves, pellets or “bits”, plugs, or twists made from cured tobacco leaves. It contains added flavors, nicotine, and other carcinogens. It is placed in the mouth, usually between the cheek and lower lip, and may be chewed.',
    },
    {
      name: 'Snuff',
      image: SnuffImage,
      description:
        'A smokeless tobacco product made of finely ground or shredded tobacco leaves. Moist snuff tobacco is placed in the mouth, between the cheek and gum or behind the upper or lower lip. Dry snuff tobacco is inhaled through the nose. It contains flavors, nicotine, and carcinogens.',
    },
    {
      name: 'Dissolvable Tobacco',
      image: DissolvableTobaccoImage,
      description:
        'A tobacco product that dissolves in the mouth without requiring spitting or discarding the product. They contain nicotine and carcinogens in the form of lozenges, strips, or sticks.',
    },
  ],
}
