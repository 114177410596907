import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { getProfile } from 'reducers/profileSlice'
import { getCareplan } from 'reducers/recommendationsSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { IconNames } from 'types/IconNames'
import { ArchiveDocument } from 'types/Recommendation'

interface Props {
  icon: IconNames
  pageTitle: string
  isMobile: boolean
  sideMenuToggled: () => void
  onClickResource: (filePath: string, patientEducationResourcePid: string) => void
  onGetDocument: (fileName: string, documentId: string, documentLink: string) => void
}

export default function DataGridComponent({ isMobile, sideMenuToggled, onGetDocument, pageTitle, icon, onClickResource }: Props) {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const careplan = useAppSelector(getCareplan)
  const profile = useAppSelector(getProfile)
  const [gridRows, setGridRows] = useState<ArchiveDocument[]>([])
  const [filteredRows, setFilteredRows] = useState<ArchiveDocument[]>([])

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle(pageTitle === 'Resources' ? 'Resources' : 'History')

  useEffect(() => {
    if (!careplan) {
      dispatch(setLoading(true))
    } else {
      dispatch(setLoading(false))
    }
    if (careplan && pageTitle === 'Resources') {
      setGridRows(
        careplan.pharmacistResources
          .slice()
          .sort((a, b) => Date.parse(a.documentDate) - Date.parse(b.documentDate))
          ?.reverse(),
      )
      setFilteredRows(
        careplan.pharmacistResources
          .slice()
          .sort((a, b) => Date.parse(a.documentDate) - Date.parse(b.documentDate))
          ?.reverse(),
      )
    } else if (careplan && pageTitle === 'History') {
      setGridRows(
        careplan.documentsArchive
          .slice()
          .sort((a, b) => Date.parse(a.documentDate) - Date.parse(b.documentDate))
          ?.reverse(),
      )
      setFilteredRows(
        careplan.documentsArchive
          .slice()
          .sort((a, b) => Date.parse(a.documentDate) - Date.parse(b.documentDate))
          ?.reverse(),
      )
    }
  }, [careplan, dispatch])

  function searchRows(value) {
    if (!value) {
      setFilteredRows(gridRows)
    } else {
      setFilteredRows(
        gridRows.filter((row) =>
          profile?.langPreference === 'ES' && row.titleSpanish
            ? (row.documentName && row.documentName.toLowerCase().includes(value.toLowerCase())) ||
              (row.titleSpanish && row.titleSpanish.toLowerCase().includes(value.toLowerCase())) ||
              (row.condition && row.condition.toLowerCase().includes(value.toLowerCase()))
            : (row.title && row.title.toLowerCase().includes(value.toLowerCase())) ||
              row.documentName.toLowerCase().includes(value.toLowerCase()) ||
              (row.condition && row.condition.toLowerCase().includes(value.toLowerCase())),
        ),
      )
    }
  }

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title={pageTitle} icon={icon} color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <StyledCardContainer>
        <StyleCard>
          <input
            onChange={(e) => searchRows(e.target.value)}
            placeholder="search"
            style={{
              margin: '5px 0px',
              border: '2px solid transparent',
              borderRadius: 4,
              backgroundColor: '#edfdff',
              boxShadow: 'inset 0px 1px 3px',
              height: 'fit-content',
              minHeight: 50,
              padding: '0px 32px 0px 15px ',
            }}
          />
          <Icon name={'search'} color={'grey'} styles={{ marginLeft: -30 }} />
          <Row styles={{ backgroundColor: theme.colors.primary1, padding: '20px 15px' }}>
            {!isMobile && (
              <Column>
                <Typography variant="h1" size={20} color={'white'}>
                  Date Added
                </Typography>
              </Column>
            )}
            <Column styles={isMobile ? {} : { position: 'absolute', marginLeft: '14vw' }}>
              <Typography variant="h1" size={20} color={'white'}>
                Title
              </Typography>
            </Column>
            {!isMobile && (
              <Column styles={{ position: 'absolute', marginLeft: '42.5vw' }}>
                <Typography variant="h1" size={20} color={'white'}>
                  Condition
                </Typography>
              </Column>
            )}
          </Row>
          <StyleDataCard>
            {filteredRows.map((row, index) => (
              <Row
                key={index}
                styles={
                  index % 2 !== 0
                    ? {
                        backgroundColor: '#ffffff',
                        padding: '20px 15px',
                        cursor: row.filePathSpanish || (row.webAddress && row.filePath) ? '' : 'pointer',
                      }
                    : {
                        backgroundColor: '#f2f2f2',
                        padding: '20px 15px',
                        cursor: row.filePathSpanish || (row.webAddress && row.filePath) ? '' : 'pointer',
                      }
                }
                onClick={() =>
                  row.filePathSpanish || (row.webAddress && row.filePath)
                    ? {}
                    : row.patientWebDocumentPId
                    ? onGetDocument(row.documentName ?? 'Care Plan Document', row.patientWebDocumentPId.toString(), row.documentLink ?? null)
                    : row.filePath
                    ? onClickResource(row.filePath, row.patientEducationResourcePid.toString())
                    : onClickResource(row.webAddress, row.patientEducationResourcePid.toString())
                }
              >
                {!isMobile && (
                  <Column styles={!row.viewed ? { fontWeight: 'bold' } : {}}>
                    <Typography size={15}>{`${dayjs(row.documentDate).format('M/D/YYYY')}`}</Typography>
                  </Column>
                )}

                <Column
                  styles={isMobile ? { marginLeft: '0', marginRight: 'auto' } : { position: 'absolute', marginLeft: '14vw', marginRight: '30vw' }}
                >
                  <Typography size={15} styles={!row.viewed ? { fontWeight: 'bold' } : {}}>
                    {row.titleSpanish && profile?.langPreference === 'ES' ? row.titleSpanish : row.documentName ? row.documentName : row.title}
                  </Typography>
                  {isMobile && <Typography size={15}>{`${dayjs(row.documentDate).format('M/D/YYYY')}`}</Typography>}
                </Column>

                {!isMobile && row.condition && (
                  <Column styles={{ position: 'absolute', marginLeft: '42.5vw' }}>
                    <Typography size={15} styles={!row.viewed ? { fontWeight: 'bold' } : {}}>
                      {row.condition}
                    </Typography>
                  </Column>
                )}

                {row.patientWebDocumentPId ? (
                  <div style={{ marginLeft: 'auto', marginRight: '0' }}>
                    {!row.documentLink ? (
                      <img
                        src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/English%20PDF%20Icon.svg"
                        alt="PDF"
                        style={{ height: 28, marginLeft: 10, cursor: 'pointer' }}
                      />
                    ) : (
                      <Icon name={'external-link'} color="#828282" size="xl" styles={{ marginRight: 5 }} />
                    )}
                  </div>
                ) : row.icon === 'file-pdf' ? (
                  <Row styles={{ marginLeft: 'auto', marginRight: '0' }}>
                    {row.filePath && (
                      <div
                        onClick={() =>
                          row.filePathSpanish || row.webAddress ? onClickResource(row.filePath, row.patientEducationResourcePid.toString()) : {}
                        }
                      >
                        <Row styles={{ alignItems: 'center' }}>
                          <img
                            src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/English%20PDF%20Icon.svg"
                            alt="PDF"
                            style={{ height: 28, marginLeft: 10, cursor: 'pointer' }}
                          />
                        </Row>
                      </div>
                    )}
                    {row.filePathSpanish && (
                      <div onClick={() => onClickResource(row.filePathSpanish, row.patientEducationResourcePid.toString())}>
                        <Row styles={{ alignItems: 'center' }}>
                          <img
                            src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Spanish%20PDF%20Icon.svg"
                            alt="PDF"
                            style={{ height: 28, marginLeft: 10, cursor: 'pointer' }}
                          />
                        </Row>
                      </div>
                    )}
                    {row.webAddress && (
                      <a href={row.webAddress.startsWith('http') ? row.webAddress : `https://${row.webAddress}`} rel="noreferrer" target="_blank">
                        <Row styles={{ alignItems: 'center' }}>
                          <Icon name="link" color="#828282" size="xl" styles={{ marginLeft: 10, cursor: 'pointer' }} />
                        </Row>
                      </a>
                    )}
                  </Row>
                ) : (
                  <Row styles={{ marginLeft: 'auto', marginRight: '0' }}>
                    <Row styles={{ alignItems: 'center' }}>
                      <Icon name={row.icon} color="#828282" size="xl" styles={{ marginRight: 5, cursor: 'pointer' }} />
                    </Row>
                  </Row>
                )}
              </Row>
            ))}
          </StyleDataCard>
        </StyleCard>
      </StyledCardContainer>
    </>
  )
}
const StyledCardContainer = styled.div`
  @media only screen and (min-width: 600px) {
    display: flex;
    flex-direction: row;
  }
`
const StyleCard = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  margin-top: 0%;
  position: relative;

  padding: 13px;
  @media only screen and (min-width: 600px) {
    height: 520px;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    height: 640px;
    width: 100%;
    margin: 0px;
    margin-top: 5%;
  }
`

const StyleDataCard = styled.div`
  position: relative;
  overflow-y: scroll;
  padding: 10px;
  @media only screen and (min-width: 600px) {
    height: 360px;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    height: 480px;
    margin: auto;
  }
`
