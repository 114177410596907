import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function BPStep4({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : '0px 60px 0px 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h1">{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '10px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 8)?.paragraph ?? ''}</p>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '10px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 9)?.paragraph ?? ''}{' '}
            <b>{sectionInfo?.titles.find((title) => title.titleId === 5)?.titleText ?? ''}</b>{' '}
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 10)?.paragraph ?? ''}
          </p>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '10px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 11)?.paragraph ?? ''}</p>
        </Row>
      </Column>
      <Column styles={{ width: '100%' }}>
        <img
          src={sectionInfo?.images.find((image) => image.imageId === 4)?.url}
          alt="scale"
          style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto' }}
        />
      </Column>
    </ResponsiveContainer>
  )
}
