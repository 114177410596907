import React from 'react'
import HighGlucosePage from '../Pages/HighGlucosePage'
import LowGlucosePage from '../Pages/LowGlucosePage'
import ResourceDetailPage from '../Pages/ResourceDetailPage'
import ResourcesPage from '../Pages/ResourcesPage'

const resourceRoutes = [
  { path: '/resources', component: <ResourcesPage /> },
  { path: '/resources/:resourceName', component: <ResourceDetailPage /> },
  {
    path: '/resources/diabetes/low_glucose',
    component: <LowGlucosePage />,
  },
  {
    path: '/resources/diabetes/high_glucose',
    component: <HighGlucosePage />,
  },
]

export default resourceRoutes
