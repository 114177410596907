import Column from 'Components/Base/Column'
import Typography from 'Components/Base/Typography'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from 'types/Challenge'
import { Card } from './Card'

export interface ContainerState {
  cards: any[]
}

const ITEMS = [
  {
    id: 1,
    text: 'I am more likely to bring my lunch if it’s ready to go.',
    targetId: 3,
  },
  {
    id: 2,
    text: 'I will bring my lunch to work to limit eating out.',
    targetId: 0,
  },
  {
    id: 3,
    text: 'For the next month, I will pack my lunch three times a week.',
    targetId: 4,
  },
  {
    id: 4,
    text: 'I will pack three lunches every week.',
    targetId: 1,
  },
  {
    id: 5,
    text: 'I can meal prep on Sundays to make this possible.',
    targetId: 2,
  },
]

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
  setSelectedCorrectAnswer: (infoPage: boolean) => void
}

export default function Container({ isMobile, step, setInfoPage, setSelectedCorrectAnswer }: Props) {
  const [cards, setCards] = useState(ITEMS)

  const findCard = useCallback(
    (id: string) => {
      const card = cards.filter((c) => `${c.id}` === id)[0] as {
        id: number
        text: string
        targetId: number
      }
      return {
        card,
        index: cards.indexOf(card),
      }
    },
    [cards],
  )

  const moveCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findCard(id)
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      )
    },
    [findCard, cards, setCards],
  )

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))

  const cardLabelArray: string[] = ['Specific:', 'Measurable:', 'Achievable:', 'Realistic:', 'Time-bound:']

  const correctOrder =
    findCard('1').index === 3 && findCard('2').index === 0 && findCard('3').index === 4 && findCard('4').index === 1 && findCard('5').index === 2
  useEffect(() => {
    setSelectedCorrectAnswer(correctOrder)
  }, [correctOrder])

  return (
    <div ref={drop} style={{ display: 'flex', flexDirection: !isMobile ? 'row' : 'column', margin: 'auto' }}>
      {cards.map((card, index) => (
        <Column>
          <Typography variant="h1" styles={{ margin: '0px auto' }}>
            {cardLabelArray[index]}
          </Typography>
          <Card
            key={card.id}
            id={`${card.id}`}
            targetId={card.targetId}
            text={card.text}
            moveCard={moveCard}
            findCard={findCard}
            isMobile={isMobile}
          />
        </Column>
      ))}
    </div>
  )
}
