import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import { useAppSelector } from 'hooks/hooks'
import React from 'react'
import Modal from 'react-modal'
import { getMultifactorReleaseDate } from 'reducers/multifactorSlice'
import styled from 'styled-components'
import Column from '../Base/Column'
import Row from '../Base/Row'
import Typography from '../Base/Typography'
import dayjs from 'dayjs'

interface Props {
  modalOpen: boolean
  toggleModal: (isOpen: boolean) => void
  isMobile: boolean
  setCheckingModal: (isOpen: boolean) => void
}
export default function MultiFactorModal({ modalOpen, toggleModal, isMobile, setCheckingModal }: Props) {
  const multiFactorReleaseDate = useAppSelector(getMultifactorReleaseDate)
  const customStyles = isMobile
    ? {
        content: {
          width: '85%',
          height: 'fit-content',
          overflow: 'none',
          top: '50%',
          left: '50%',
          right: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '20px',
        },
        overlay: { zIndex: 1000 },
      }
    : {
        content: {
          width: '500px',
          height: 'fit-content',
          overflow: 'none',
          top: '50%',
          left: '50%',
          right: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
          borderRadius: '20px',
        },
        overlay: { zIndex: 1000 },
      }

  const clickExternalLink = (link) => {
    toggleModal(!modalOpen)
    setCheckingModal(true)
    const width = isMobile ? window.outerWidth - 100 : window.outerWidth - 600
    const height = isMobile ? window.outerHeight - 100 : window.outerHeight - 200
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const title = `Multi-Factor Authentication`
    window.open(link, title, `width=${width},height=${height},left=${left},top=${top}`)
  }

  return (
    <StyledModalContainer style={{ display: modalOpen ? 'block' : 'none' }}>
      <Modal isOpen={modalOpen} onRequestClose={() => toggleModal(!modalOpen)} style={customStyles} ariaHideApp={false}>
        <StyledModalHeader>
          <Typography color="white" font="bold" size={28} styles={{ margin: 'auto', textAlign: 'center' }}>
            Important Security Update
          </Typography>
          <div onClick={() => toggleModal(!modalOpen)}>
            <Icon name="times" color="white" styles={{ alignmentBaseline: 'before-edge' }} />
          </div>
        </StyledModalHeader>
        <Column styles={{ marginBottom: 15, marginTop: isMobile ? 100 : '8vh', textAlign: 'center' }}>
          <Typography size={18}>{`Enable multi-factor authentication on your account by ${dayjs(multiFactorReleaseDate)
            .add(2, 'month')
            .format('MMMM D, YYYY')}.`}</Typography>

          <Row styles={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              text="Get Started"
              onClick={() => clickExternalLink(`https://medchatapp.com/widget/bLZCrHZAnkSVkQoS3dvulQ/chat`)}
              styles={{ height: 40 }}
              backgroundColor="#9f2842"
            />
          </Row>
        </Column>
      </Modal>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  height: 60px;
  width: 25%;
  position: fixed;
  border-radius: 40px;
  inset: 37% auto 40px 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

const StyledModalHeader = styled(Row)`
  background-color: #9f2842;
  position: absolute;
  margin-bottom: 40px;
  height: fit-content;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 20px;
  top: 0;
  left: 0;
  justify-content: space-between;
`
