import React from 'react'
import { VictoryLabel, VictoryTooltip } from 'victory'

export class CustomLabel extends React.Component {
  static defaultEvents = VictoryTooltip.defaultEvents
  render() {
    return (
      <g>
        <VictoryLabel {...this.props} />
        <VictoryTooltip
          {...this.props}
          x={200}
          y={215}
          text={`${this.props.data[this.props.index].type}`}
          orientation="top"
          pointerLength={0}
          //cornerRadius={50}
          width={100}
          height={100}
          style={{
            fill: 'black',
            fontSize: 20,
            fontFamily: 'Raleway-Semi-Bold',
          }}
          flyoutStyle={{
            fill: 'transparent',
            stroke: '',
          }}
        />
        <VictoryTooltip
          {...this.props}
          x={200}
          y={190}
          text={`${this.props.data[this.props.index].y}`}
          orientation="top"
          pointerLength={0}
          //cornerRadius={50}
          width={100}
          height={100}
          style={{
            fill: 'black',
            fontSize: 20,
            fontFamily: 'Raleway-Semi-Bold',
          }}
          flyoutStyle={{
            fill: 'transparent',
            stroke: '',
          }}
        />
        {this.props.data[this.props.index].percentage !== undefined && (
          <VictoryTooltip
            {...this.props}
            x={200}
            y={240}
            text={`${this.props.data[this.props.index].percentage}%`}
            orientation="top"
            pointerLength={0}
            //cornerRadius={50}
            width={100}
            height={100}
            style={{
              fill: 'black',
              fontSize: 20,
              fontFamily: 'Raleway-Semi-Bold',
            }}
            flyoutStyle={{
              fill: 'transparent',
              stroke: '',
            }}
          />
        )}
      </g>
    )
  }
}
