import Typography from 'Components/Base/Typography'
import { theme } from 'Styles/styledTheme'
import React from 'react'
import { useNavigate } from 'react-router'
import './FooterSection.scss'

type FooterLink = {
  url: string
  title: string
  relative?: boolean
  hidden?: boolean
}
interface Props {
  title: string
  text?: string
  className?: string
  links?: FooterLink[]
  styles?: React.CSSProperties
}
export default function FooterSection({ title, text, links, styles, className }: Props) {
  const navigate = useNavigate()
  links = links?.filter((x) => x.hidden !== true)
  const linkClicked = (link: FooterLink) => {
    window.scrollTo(0, 0)
    navigate(link.url)
  }

  return (
    <div className={`footer-section-container ${className}`} style={{ ...styles }}>
      <Typography color={theme.colors.white} font="bold" size={20}>
        {title}
      </Typography>
      <p className="section-content">
        {text && text}
        {links?.map((link) => {
          if (link.relative) {
            return (
              <div
                onClick={() => {
                  linkClicked(link)
                }}
                style={{ cursor: 'pointer', marginTop: 10 }}
              >
                <Typography size={13.5} styles={{ fontWeight: 550 }}>
                  {link.title}
                </Typography>
              </div>
            )
          } else {
            return (
              <a href={link.url} target={'_blank'} rel="noreferrer">
                <div style={{ cursor: 'pointer', marginTop: 10 }}>
                  <Typography size={13.5} styles={{ fontWeight: 550 }}>
                    {link.title}
                  </Typography>
                </div>
              </a>
            )
          }
        })}
      </p>
    </div>
  )
}
