import { Typography } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Spacer from 'Components/Base/Spacer'
import { useStopContext } from 'Pages/ProgramScreen'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import { IconNames } from 'types/IconNames'
import STOPHeader from '../components/STOPHeader'
import ScaledText from '../components/ScaledText'

export default function BenefitsChart() {
  const [hoveredIcons, setHoveredIcons] = React.useState<Set<IconNames>>(new Set())
  const { setIsContinueButtonEnabled } = useStopContext()

  const handleHover = (iconName: IconNames) => {
    setHoveredIcons((prevState) => {
      if (prevState.has(iconName)) {
        return prevState // return the current state if the icon is already hovered/clicked
      }
      const newSet = new Set(prevState)
      newSet.add(iconName)
      return newSet
    })
    console.log(hoveredIcons.size)
    if (hoveredIcons.size === 7) {
      setIsContinueButtonEnabled(true)
    }
  }

  return (
    <div>
      <Spacer bottom={50}>
        <Spacer bottom>
          <STOPHeader>How Soon After Quitting Tobacco Are Health Benefits Noticed? </STOPHeader>
        </Spacer>
        <ScaledText>Quitting tobacco can significantly reduce health risks. </ScaledText>
        <ScaledText bold>Click the buttons below to learn more about the specific benefits of quitting tobacco. </ScaledText>
      </Spacer>
      <StyledRow center>
        <BenefitIcon
          name="heartbeat"
          text="20 Minutes"
          tooltipText="Heart rate and blood pressure improve"
          onHover={handleHover}
          hovered={hoveredIcons.has('heartbeat')}
        />
        <BenefitIcon
          name="cloud-arrow-down"
          text="12 Hours"
          tooltipText="Carbon monoxide levels in the blood return to normal"
          onHover={handleHover}
          hovered={hoveredIcons.has('cloud-arrow-down')}
        />

        <BenefitIcon
          name="lung"
          text="3 Months"
          tooltipText="Blood circulation and lung function improve"
          onHover={handleHover}
          hovered={hoveredIcons.has('lung')}
        />
        <BenefitIcon
          name="cough"
          text="9 Months"
          tooltipText="Coughing decreases and breathing becomes easier"
          onHover={handleHover}
          hovered={hoveredIcons.has('cough')}
        />
        <BenefitIcon
          name="heart-half"
          text="1 Year"
          tooltipText="The risk of heart disease (heart attack and stroke) is cut in half"
          onHover={handleHover}
          hovered={hoveredIcons.has('heart-half')}
        />
        <BenefitIcon
          name="viruses"
          text="5 Years"
          tooltipText="The risk of cancers, including mouth, throat, esophagus, and bladder, is cut in half"
          onHover={handleHover}
          hovered={hoveredIcons.has('viruses')}
        />
        <BenefitIcon
          name="lung-virus"
          text="10 Years"
          tooltipText="The risk of pancreas and larynx cancer is decreased and the risk of dying from lung cancer is cut in half"
          onHover={handleHover}
          hovered={hoveredIcons.has('lung-virus')}
        />
        <BenefitIcon
          name="heart-circle-check"
          text="15 Years"
          tooltipText="The risk of heart disease is the same as someone who has never smoked"
          onHover={handleHover}
          hovered={hoveredIcons.has('heart-circle-check')}
        />
      </StyledRow>
    </div>
  )
}

interface BenefitIconProps extends React.ComponentPropsWithoutRef<'div'> {
  name: IconNames
  text: string
  tooltipText?: string
  onHover: (name: IconNames) => void
  hovered: boolean
}

const BenefitIcon = React.forwardRef<HTMLDivElement, BenefitIconProps>((props, ref) => {
  const { name, tooltipText, ...otherProps } = props

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: 12,
      border: '1px solid black',
    },
  }))

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit">{tooltipText}</Typography>
        </React.Fragment>
      }
    >
      <StyledIconContainer
        className="benefit-icon"
        ref={ref}
        {...otherProps}
        onMouseEnter={() => props.onHover(name)}
        onClick={() => props.onHover(name)}
      >
        <Icon color="white" size="3x" name={name} />
        <Typography style={{ marginLeft: 8, position: 'absolute', top: 100, fontWeight: 'bold' }}>{props.text}</Typography>
      </StyledIconContainer>
    </HtmlTooltip>
  )
})

const StyledRow = styled(ResponsiveContainer)`
  & > div:nth-child(even) {
    align-self: flex-end;
  }
  width: 235px;
  margin-left: auto;
  margin-right: auto;
  @media ${sizes.laptop} {
    height: 200px;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    & > div:nth-child(even) {
      align-self: flex-end;
    }
  }
`
const StyledIconContainer = styled.div<{ hovered?: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${(props) =>
    props.hovered
      ? 'radial-gradient(circle at 55% 55%, lightgray, lightgray 55%, #ffffff 95%)'
      : 'radial-gradient(circle at 55% 55%, #263746, #263746 55%, #ffffff 95%)'};
  margin-right: 20px;

  &:nth-child(odd):not(:last-child)::after,
  column:nth-child(odd) > &:not(:last-child)::after {
    content: '';
    position: absolute;
    width: calc(100% + 20px);
    height: 2px;
    background: black;
    left: 85%;
    bottom: 20%;
    transform: rotate(40deg);
    transform-origin: left bottom;
    z-index: -1;
  }

  &:nth-child(even):not(:last-child)::after,
  column:nth-child(even) > &:not(:last-child)::after {
    content: '';
    position: absolute;
    width: calc(100% + 20px);
    height: 2px;
    background: black;
    right: 75%;
    bottom: 20%;
    transform: rotate(-40deg);
    transform-origin: right bottom;
    z-index: -1;
  }
  @media ${sizes.laptop} {
    &:nth-child(even):not(:last-child)::after,
    column:nth-child(even) > &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: calc(100% + 20px);
      height: 2px;
      background: black;
      left: 75%;
      top: 20%;
      transform: rotate(-40deg);
      transform-origin: left top;
      z-index: -1;
    }
  }
`
