const colors = {
  primary: {
    blue: '#00677f',
    lightBlue: '#61dafb',
    lightGray: '#425563',
    darkGray: '#232d37',
    lightGreen: '#8bc93b',
    darkGreen: '#7a9a01',
    lightRed: '#e40046',
    darkRed: '#9b2743',
    yellow: '#eaaa00',
  },
  neutral: {
    white: '#ffffff',
    grey: '#bcc1c5',
    darkGrey: '#425563',
    black: '#000000',
  },
}

export default colors
