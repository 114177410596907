import React from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import Column from '../../Components/Base/Column'
import Row from '../../Components/Base/Row'
import Typography from '../../Components/Base/Typography'
import './HighGlucosePage.scss'

export default function HighGlucosePage() {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <div className="high-glucose-page-container" style={{ marginTop: 80 }}>
      <Column className="program-info">
        <Row styles={{ marginBottom: -10 }}>
          <Typography color={theme.colors.primary1} font="semi-bold" size={35}>
            High Blood Glucose
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography color={theme.colors.primary1} font="semi-bold" size={13}>
            Hyperglycemia
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography size={15}>
            INFORMATION PROVIDED ON THIS WEBSITE IS NOT INTENDED TO BE TREATED AS MEDICAL ADVICE AND SHOULD NOT UNDER ANY CIRCUMSTANCES BE USED AS A
            SUBSTITUTE FOR A PROFESSIONAL DIAGNOSIS OR PROFESSIONAL MEDICAL TREATMENT OR ADVICE.
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" size={25}>
            Hyperglycemia Overview:
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography size={15}>
            Several factors can contribute to hyperglycemia in people with diabetes, including food and physical activity choices, illness,
            nondiabetes medications, or skipping or not taking enough glucose-lowering medication.
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography size={15}>
            Hyperglycemia can become severe and lead to serious complications requiring emergency care, such as a diabetic coma. Persistent
            hyperglycemia, even if not severe, can lead to complications affecting your eyes, kidneys, nerves and heart.
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" size={25}>
            Hyperglycemia Symptoms:
          </Typography>
        </Row>
        <Row styles={{ marginBottom: 10 }}>
          <Typography size={15}>
            Hyperglycemia doesn't cause symptoms until glucose values are significantly elevated — usually above 180 to 200 milligrams per deciliter
            (mg/dL), or 10 to 11.1 millimoles per liter (mmol/L). Symptoms of hyperglycemia develop slowly over several days or weeks. The longer
            blood sugar levels stay high, the more serious the symptoms become. However, some people who've had type 2 diabetes for a long time may
            not show any symptoms despite elevated blood sugar levels.
          </Typography>
        </Row>

        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" size={15}>
            Early signs and symptoms
          </Typography>
        </Row>
        <Typography size={15}>- Frequent urination</Typography>
        <Typography size={15}>- Increased thirst</Typography>
        <Typography size={15}>- Blurred vision</Typography>
        <Typography size={15}>- Fatigue</Typography>
        <Typography size={15} styles={{ marginBottom: 10 }}>
          - Headache
        </Typography>

        <Row styles={{ marginBottom: 10 }}>
          <Typography font="semi-bold" size={15}>
            Later signs and symptoms
          </Typography>
        </Row>
        <Typography size={15}>- Fruity-smelling breath</Typography>
        <Typography size={15}>- Nausea and vomiting</Typography>
        <Typography size={15}>- Shortness of breath</Typography>
        <Typography size={15}>- Dry mouth</Typography>
        <Typography size={15}>- Weakness</Typography>
        <Typography size={15}>- Confusion</Typography>
        <Typography size={15}>- Coma</Typography>
        <Typography size={15}>- Abdominal pain</Typography>

        <Row styles={{ marginTop: 10 }}>
          <Typography size={15}>
            <>
              Resources provided by:{' '}
              <a href="https://www.mayoclinic.org/" target={'_blank'} rel="noreferrer">
                <span style={{ color: '#00677F', fontWeight: 'bold' }}>https://www.mayoclinic.org/</span>
              </a>
            </>
          </Typography>
        </Row>
      </Column>
      <Column className="glucose-video-column">
        <img
          src={'https://triacentral.blob.core.windows.net/dev-storage/images/CareGiverAddOn.png'}
          onClick={() => navigate('/profile/access')}
          alt="Keep your loved ones in the loop"
          className="add-caregiver-image"
        />
        <ReactPlayer url={'https://www.youtube.com/watch?v=fz7Fk3zrxTw'} width={'100%'} controls={true} style={{ marginBottom: 15 }} />

        <ReactPlayer url={'https://www.youtube.com/watch?v=JaLlSgPxeFc'} width={'100%'} controls={true} style={{ marginBottom: 15 }} />
      </Column>
    </div>
  )
}
