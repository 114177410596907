import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { Menu, ProSidebar, SidebarHeader, MenuItem as SidebarMenuItem } from 'react-pro-sidebar'
import styled, { useTheme } from 'styled-components'
import { SidebarMenuHeader, SidebarMenuItemComponents } from 'types/SidebarNav'

type Props = {
  sideMenuCollapsed: boolean
  isMobile: boolean
  toggled: boolean
  itemClicked: (link: string) => void
  onCollapseMenu: () => void
  sidebarMenuItems: SidebarMenuItemComponents[]
  sidebarMenuHeader: SidebarMenuHeader
  height: string
}

export default function SidebarNav({
  sideMenuCollapsed,
  isMobile,
  toggled,
  itemClicked,
  onCollapseMenu,
  sidebarMenuItems,
  sidebarMenuHeader,
  height,
}: Props) {
  const theme = useTheme()

  const clickExternalLink = (link) => {
    const width = isMobile ? window.outerWidth - 100 : window.outerWidth - 600
    const height = isMobile ? window.outerHeight - 100 : window.outerHeight - 200
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const title = `Log in`
    window.open(link, title, `width=${width},height=${height},left=${left},top=${top}`)
  }

  return (
    <ProSidebar collapsed={isMobile ? false : sideMenuCollapsed} toggled={toggled} style={{ zIndex: 10, height: height }}>
      <SidebarHeader style={{ justifyContent: sideMenuCollapsed ? 'center' : 'space-between' }}>
        <StyledHeader>
          {!sideMenuCollapsed && (
            <div
              onClick={() => {
                itemClicked(sidebarMenuHeader.route)
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography size={30} font="bold" color={theme.colors.white}>
                {sidebarMenuHeader.title}
              </Typography>
            </div>
          )}
          <div onClick={onCollapseMenu} style={{ cursor: 'pointer' }}>
            <Icon name={sideMenuCollapsed ? 'angle-right' : 'angle-left'} color={theme.colors.white} />
          </div>
        </StyledHeader>
      </SidebarHeader>
      <Menu iconShape="square">
        {sidebarMenuItems &&
          sidebarMenuItems.map((item, index) =>
            item.route ? (
              <SidebarMenuItem
                key={index}
                title={item.title}
                icon={<Icon name={item.icon} color={theme.colors.white} />}
                onClick={() => itemClicked(item.route!)}
              >
                <Row>
                  <Typography font="bold" color={theme.colors.white} styles={{ whiteSpace: 'pre-line' }}>
                    {item.displayText}
                  </Typography>
                  {item.badge && item.badge > 0 ? (
                    <StyledBadge>
                      <div style={{ marginBottom: 2 }}></div>
                    </StyledBadge>
                  ) : (
                    <></>
                  )}
                </Row>
              </SidebarMenuItem>
            ) : (
              <SidebarMenuItem title={item.title} icon={<Icon name={item.icon} color={theme.colors.white} />}>
                {isMobile ? (
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <Typography font="bold" color={theme.colors.white} styles={{ whiteSpace: 'pre-line' }}>
                      {item.displayText}
                    </Typography>
                  </a>
                ) : (
                  <div onClick={() => clickExternalLink(item.link)}>
                    <Typography font="bold" color={theme.colors.white} styles={{ whiteSpace: 'pre-line' }}>
                      {item.displayText}
                    </Typography>
                  </div>
                )}
              </SidebarMenuItem>
            ),
          )}
      </Menu>
    </ProSidebar>
  )
}

const StyledHeader = styled.div`
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.colors.white};
`

const StyledBadge = styled.div`
  height: 10px;
  min-width: 10px;
  width: fit-content;
  border-radius: 10px;
  background-color: #e40046;
  display: flex;
  align-items: center;
  justify-content: center;
`
