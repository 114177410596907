import Typography from 'Components/Base/Typography'
import React from 'react'
import { Form } from 'react-bootstrap'
import { DropdownOption } from 'types/Forms'

type Props = {
  question: string
  fieldName: string
  answers: DropdownOption[]
  value: string | number | undefined
  onChangeValue: (field: string, value: string | number | undefined) => void
}
export default function DropdownQuestion({ question, fieldName, answers, value, onChangeValue }: Props) {
  return (
    <div className="sc-form-row">
      <div className="sc-column">
        <Typography font="semi-bold">{question}</Typography>
      </div>
      <div className="sc-column">
        <Form.Control
          as="select"
          className="contact-dropdown-input"
          value={value}
          onChange={(value) => onChangeValue(fieldName, +value.currentTarget.value)}
        >
          {answers.map((answer) => (
            <option value={answer.value}>{answer.label}</option>
          ))}
        </Form.Control>
      </div>
    </div>
  )
}
