import qs from 'querystring'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import {
  BloodGlucoseReadingCGMSummary,
  BloodGlucoseReadingSummary,
  BloodPressureReadingSummary,
  PatientBloodPressureReading,
  PatientGlucoseReading,
} from '../types/Dashboards'
import { useApi } from './useAPI'

export default function usePatientReadings() {
  const TriaApi = useApi()

  const endDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
  const today = dayjs().format('YYYY-MM-DD')

  const getGlucoseReadingSummary = async (token?: string, daysBack?: number, start?: string, end?: string, overrideWelltrak?: string) => {
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    if (start && end) {
      const readingSummary: Promise<AxiosResponse<BloodGlucoseReadingSummary>> = TriaApi.get(
        `api/dashboards/bloodglucose/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${start}&endDate=${end}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return readingSummary
    }

    let startDate: string | undefined = undefined
    if (daysBack !== undefined) {
      startDate = dayjs()
        .subtract(daysBack ?? 90, 'day')
        .format('YYYY-MM-DD')
    }

    const readingSummary: Promise<AxiosResponse<BloodGlucoseReadingSummary>> = TriaApi.get(
      `api/dashboards/bloodglucose/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${startDate}&endDate=${today}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    return readingSummary
  }

  const updateGlucoseReading = async (token: string, reading: PatientGlucoseReading) => {
    const data = qs.stringify({
      PatientReadingPId: reading.patientReadingPId,
      InsulinDosage: reading.insulinDosage,
      MealType: reading.mealType,
      Notes: reading.notes,
      CarbCount: reading.carbCount,
      EventFlag: reading.eventFlag,
    })
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    const readingSummary: Promise<AxiosResponse<BloodGlucoseReadingSummary>> = TriaApi.post(`api/dashboards/glucose/readings/update`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return readingSummary
  }

  const getBloodPressureReadingSummary = async (token: string, daysBack?: number, start?: string, end?: string, overrideWelltrak?: string) => {
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`

    if (start && end) {
      const readingSummary: Promise<AxiosResponse<BloodPressureReadingSummary>> = TriaApi.get(
        `api/dashboards/bloodpressure/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${start}&endDate=${end}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return readingSummary
    }

    if (daysBack === undefined) {
      TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
      const readingSummary: Promise<AxiosResponse<BloodPressureReadingSummary>> = TriaApi.get(
        `api/dashboards/bloodpressure/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}?endDate=${endDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return readingSummary
    }

    const startDate = dayjs()
      .subtract(daysBack ?? 90, 'day')
      .format('YYYY-MM-DD')

    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    const readingSummary: Promise<AxiosResponse<BloodPressureReadingSummary>> = TriaApi.get(
      `api/dashboards/bloodpressure/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    return readingSummary
  }

  const updateBloodPressureReading = async (token: string, reading: PatientBloodPressureReading) => {
    const data = qs.stringify({
      PatientReadingPId: reading.patientReadingPId,
      Notes: reading.notes,
    })
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    const readingSummary: Promise<AxiosResponse<BloodPressureReadingSummary>> = TriaApi.post(`api/dashboards/bloodpressure/readings/update`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return readingSummary
  }

  const getBloodPressureConfig = async (token: string) => {
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    const readingSummary: Promise<AxiosResponse<number>> = TriaApi.get(`api/dashboards/bloodpressure/config`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return readingSummary
  }

  const getNotificationSettings = async (token: string) => {
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    const readingSummary: Promise<AxiosResponse<any>> = TriaApi.get(`api/dashboards/glucose/config`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return readingSummary
  }

  const updateNotificationSettings = async (token: string, limit: number) => {
    const data = qs.stringify({
      glucoseLimit: limit,
    })

    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    const readingSummary: Promise<AxiosResponse<number>> = TriaApi.post(`api/dashboards/glucose/config`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return readingSummary
  }

  const getGlucoseReadingCGMSummary = async (token?: string, daysBack?: number, start?: string, end?: string, overrideWelltrak?: string) => {
    TriaApi.defaults.headers['Authorization'] = `Bearer ${token}`
    if (start && end) {
      const readingSummary: Promise<AxiosResponse<BloodGlucoseReadingCGMSummary>> = TriaApi.get(
        `api/dashboards/bloodglucose/readings/cgm${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${start}&endDate=${end}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return readingSummary
    }

    let startDate: string | undefined = undefined
    if (daysBack !== undefined) {
      startDate = dayjs()
        .subtract(daysBack ?? 90, 'day')
        .format('YYYY-MM-DD')
      startDate = dayjs('7/8/2022')
        .subtract(daysBack ?? 90, 'day')
        .format('YYYY-MM-DD')
    }

    const readingSummary: Promise<AxiosResponse<BloodGlucoseReadingCGMSummary>> = TriaApi.get(
      `api/dashboards/bloodglucose/readings/cgm${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${startDate}&endDate=${today}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    return readingSummary
  }

  return {
    getGlucoseReadingSummary,
    getGlucoseReadingCGMSummary,
    getNotificationSettings,
    updateNotificationSettings,
    getBloodPressureConfig,
    getBloodPressureReadingSummary,
    updateBloodPressureReading,
    updateGlucoseReading,
  }
}
