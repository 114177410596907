
import React, { useEffect, useState } from 'react'
import { Paragraph, SectionInfo } from 'types/Challenge'
import Typography from 'Components/Base/Typography'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void

}
export default function CarbsStep2({ isMobile, step, setInfoPage }: Props) {

  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()

  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()


  useEffect(() => {
    setInfoPage(true)
    window.scrollTo(0, 0)
    setSectionInfo(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2])))
    setStepParagraphs(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]))?.paragraphs)
  }, [step])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : '0px 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant='h1'>{sectionInfo?.titles.find((title) => title.titleId === 4)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}><i>{sectionInfo?.titles.find((title) => title.titleId === 5)?.titleText ?? ''}</i> {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 4)?.paragraph ?? ''}</p>
        </Row>
        <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 5)?.paragraph ?? ''}</p>
      </Column>
      <Column styles={{ width: '100%', marginTop: 'auto' }}>
        <img src={sectionInfo?.images.find((image) => image.imageId === 2)?.url} alt='scale' style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto' }} />
      </Column>
    </ResponsiveContainer>
  )
}
