import { Theme, createStyles, makeStyles } from '@material-ui/core'
import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import usePatientReadings from 'hooks/usePatientReadings'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import { useTheme } from 'styled-components'
import { PatientBloodPressureReading } from 'types/Dashboards'

interface Props {
  modalOpen: boolean
  reading: PatientBloodPressureReading
  isMobile: boolean
  toggleModal: (isOpen: boolean) => void
  onReadingUpdated: (updatedReading: PatientBloodPressureReading) => void
}

type FormValues = {
  notes: string
}

export default function BloodPressureEditModal({ modalOpen, reading, isMobile, toggleModal, onReadingUpdated }: Props) {
  const styles = useStyles()
  const accessToken = useAppSelector(getAccessToken)
  const theme = useTheme()

  const { updateBloodPressureReading } = usePatientReadings()
  const onSubmit = (values: FormValues) => {
    const readingEdit = { ...reading }
    readingEdit.notes = values.notes
    accessToken &&
      updateBloodPressureReading(accessToken, readingEdit).then(() => {
        onReadingUpdated(readingEdit)
        toggleModal(false)
      })
  }

  const initialValues: FormValues = {
    notes: reading.notes,
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <div>
      <Modal show={modalOpen} onHide={() => toggleModal(false)} className={styles.custom} dialogClassName={styles.content1} fullscreen={false}>
        <Modal.Header className={styles.header}>
          <Modal.Title>{isMobile ? `Edit Reading` : `Edit Blood Pressure Reading Details`}</Modal.Title>
          <div onClick={() => toggleModal(false)} style={{ cursor: 'pointer' }}>
            <Icon name="times" color="white" />
          </div>
        </Modal.Header>
        {reading && (
          <Modal.Body className={styles.modalBody}>
            <div style={{ padding: 0 }}>
              <Row>
                <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ marginRight: 10 }}>
                  Reading Date:
                </Typography>
                <Typography color={theme.colors.black} size={20}>
                  {dayjs(reading.readingDate).format('M/DD/YYYY h:mm A')}
                </Typography>
              </Row>
              <Row>
                <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ marginRight: 10 }}>
                  Blood Pressure (mm Hg):
                </Typography>
                <Typography color={theme.colors.black} size={20}>
                  {`${reading.systolicValue}/${reading.diastolicValue}`}
                </Typography>
              </Row>
              <Row>
                <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ marginRight: 10 }}>
                  Heart Rate (bpm):
                </Typography>
                <Typography color={theme.colors.black} size={20}>
                  {reading.heartRateValue}
                </Typography>
              </Row>

              <Row className="edit-reading-row">
                <Typography color={theme.colors.black} font="semi-bold" size={20} styles={{ minWidth: 150 }}>
                  Notes:
                </Typography>
              </Row>
              <Row styles={{ alignItems: 'center', marginBottom: 10 }}>
                <textarea
                  style={{
                    border: '1px solid rgb(66, 85, 99)',
                    resize: 'none',
                  }}
                  onChange={(e) => formik.setFieldValue('notes', e.currentTarget.value)}
                  value={formik.values.notes}
                  className={'form-control'}
                  rows={3}
                ></textarea>
              </Row>
              <Row styles={{ justifyContent: 'space-between', marginTop: 25 }}>
                <Button
                  pageName="dashboardBloodPressure_EditModal"
                  styles={{
                    border: '0px',
                    backgroundColor: '#00677F',
                    borderRadius: 4,
                    padding: 10,
                    width: '49%',
                  }}
                  onClick={() => formik.handleSubmit()}
                  text={'Save'}
                />
                <Button
                  pageName="dashboardBloodPressure_EditModal"
                  styles={{
                    border: '0px',
                    backgroundColor: '#425563',
                    borderRadius: 4,
                    padding: 10,
                    width: '49%',
                  }}
                  onClick={() => toggleModal(false)}
                  text={'Cancel'}
                />
              </Row>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    custom: {
      paddingLeft: '0px !important',
    },
    content1: {
      minWidth: '40%',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 0,
      },
    },
    header: {
      backgroundColor: '#00677F',
      alignItems: 'center',
      color: 'white',
    },
    modalBody: {
      minHeight: 'min-content',
      [theme.breakpoints.down('sm')]: {
        minHeight: '100vh',
      },
    },
  }),
)
