import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import {
  BloodGlucoseReadingCGMSummary,
  BloodGlucoseReadingSummary,
  BloodPressureReadingSummary,
  PatientBloodPressureReading,
  PatientGlucoseReading,
  ScaleReading,
  ScaleReadingSummary,
} from '../types/Dashboards'

export interface DashboardState {
  glucoseReadings: PatientGlucoseReading[]
  aboveRangeReadings: PatientGlucoseReading[]
  criticalLowReadings: PatientGlucoseReading[]
  highReadings: PatientGlucoseReading[]
  inRangeReadings: PatientGlucoseReading[]
  lowReadings: PatientGlucoseReading[]
  averageTestsPerDay: number
  averageReading: number
  estimatedA1C: number
  glucoseCGMReadings: PatientGlucoseReading[]
  aboveRangeCGMReadings: PatientGlucoseReading[]
  criticalLowCGMReadings: PatientGlucoseReading[]
  highCGMReadings: PatientGlucoseReading[]
  inRangeCGMReadings: PatientGlucoseReading[]
  lowCGMReadings: PatientGlucoseReading[]
  averageCGMReading: number
  glucoseManagementIndicator: number
  glucoseVariability: number
  status: 'idle' | 'loading' | 'failed'
  readings: PatientBloodPressureReading[]
  bloodPressureAverageReadings: PatientBloodPressureReading[]
  aboveRangeBloodPressureReadings: PatientBloodPressureReading[]
  inRangeBloodPressureReadings: PatientBloodPressureReading[]
  averageHeartRate: number
  averageSystolicReading: number
  averageDiastolicReading: number
  highBloodPressureReadings: PatientBloodPressureReading[]
  lowBloodPressureReadings: PatientBloodPressureReading[]
  scaleReadings: ScaleReading[]
  bmi: number
  goalWeight: string
}

const initialState: DashboardState = {
  glucoseReadings: [],
  aboveRangeReadings: [],
  criticalLowReadings: [],
  highReadings: [],
  inRangeReadings: [],
  lowReadings: [],
  averageTestsPerDay: 0,
  averageReading: 0,
  estimatedA1C: 0,
  status: 'idle',
  glucoseCGMReadings: [],
  aboveRangeCGMReadings: [],
  criticalLowCGMReadings: [],
  highCGMReadings: [],
  inRangeCGMReadings: [],
  lowCGMReadings: [],
  averageCGMReading: 0,
  glucoseManagementIndicator: 0,
  glucoseVariability: 0,
  readings: [],
  highBloodPressureReadings: [],
  bloodPressureAverageReadings: [],
  aboveRangeBloodPressureReadings: [],
  inRangeBloodPressureReadings: [],
  lowBloodPressureReadings: [],
  averageHeartRate: 0,
  averageSystolicReading: 0,
  averageDiastolicReading: 0,
  scaleReadings: [],
  bmi: 0,
  goalWeight: '',
}

export interface GlucoseSummaryRequest {
  token?: string
  overrideWelltrak?: string
  daysBack?: number
}

export interface ScaleSummaryRequest {
  token?: string
}

export interface BloodGlucoseReadingCGMSummaryRequest {
  token?: string
  daysBack?: number

  startDate?: string
  endDate?: string
  overrideWelltrak?: string
}

export interface BloodPressureSummaryRequest {
  token?: string
  daysBack?: number
  overrideWelltrak?: string
}

const endDate = dayjs().add(1, 'day').format('YYYY-MM-DD')

export const loadGlucoseReadingSummary = createAsyncThunk('dashboards/glucose/summary', async (request: GlucoseSummaryRequest) => {
  const startDate = dayjs()
    .subtract(request.daysBack ?? 90, 'day')
    .format('YYYY-MM-DD')
  const overrideWelltrak = request.overrideWelltrak ? request.overrideWelltrak : ''
  const response: Promise<{ data: BloodGlucoseReadingSummary }> = useApi(request.token).get(
    `api/dashboards/bloodglucose/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return response
})

export const loadGlucoseReadingCGMSummary = createAsyncThunk(
  'dashboards/glucose/cgm/summary',
  async (request: BloodGlucoseReadingCGMSummaryRequest) => {
    const start = request.startDate
      ? request.startDate
      : dayjs()
          .subtract(request.daysBack ?? 14, 'day')
          .format('YYYY-MM-DD')
    const overrideWelltrak = request.overrideWelltrak ? request.overrideWelltrak : ''
    const end = request.endDate ? request.endDate : dayjs().format('YYYY-MM-DD')
    const response: Promise<{ data: BloodGlucoseReadingCGMSummary }> = useApi(request.token).get(
      `api/dashboards/bloodglucose/readings/cgm${overrideWelltrak ? `/${overrideWelltrak}` : ''}?startDate=${start}&endDate=${end}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response
  },
)

export const loadBloodPressureSummary = createAsyncThunk('dashboards/bloodpressure/summary', async (request: BloodPressureSummaryRequest) => {
  const startDate = dayjs()
    .subtract(request.daysBack ?? 90, 'day')
    .format('YYYY-MM-DD')
  const overrideWelltrak = request.overrideWelltrak ? request.overrideWelltrak : ''

  const endDate = dayjs()

  const response: Promise<{ data: BloodPressureReadingSummary }> = useApi(request.token).get(
    `api/dashboards/bloodpressure/readings${overrideWelltrak ? `/${overrideWelltrak}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return response
})

export const loadScaleReadingSummary = createAsyncThunk('dashboards/digitalscale/summary', async (request: ScaleSummaryRequest) => {
  const response: Promise<{ data: ScaleReadingSummary }> = useApi(request.token).get(`api/dashboards/digitalscale/readings`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response
})

export const dashboardSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadGlucoseReadingSummary.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadGlucoseReadingSummary.fulfilled, (state, action) => {
        state.status = 'idle'
        state.glucoseReadings = action.payload.data.readings
        state.aboveRangeReadings = action.payload.data.aboveRangeReadings
        state.criticalLowReadings = action.payload.data.criticalLowReadings
        state.highReadings = action.payload.data.highReadings
        state.inRangeReadings = action.payload.data.inRangeReadings
        state.lowReadings = action.payload.data.lowReadings
        state.estimatedA1C = action.payload.data.estimatedA1C
        state.averageReading = action.payload.data.averageReading
        state.averageTestsPerDay = action.payload.data.averageTestsPerDay
      })
      .addCase(loadGlucoseReadingSummary.rejected, (state) => {
        state.status = 'idle'
      })
      .addCase(loadGlucoseReadingCGMSummary.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadGlucoseReadingCGMSummary.fulfilled, (state, action) => {
        state.status = 'idle'
        state.glucoseCGMReadings = action.payload.data.readings
        state.aboveRangeCGMReadings = action.payload.data.aboveRangeReadings
        state.criticalLowCGMReadings = action.payload.data.criticalLowReadings
        state.highCGMReadings = action.payload.data.highReadings
        state.inRangeCGMReadings = action.payload.data.inRangeReadings
        state.lowCGMReadings = action.payload.data.lowReadings
        state.averageCGMReading = action.payload.data.averageReading
        state.glucoseManagementIndicator = action.payload.data.glucoseManagementIndicator
        state.glucoseVariability = action.payload.data.glucoseVariability
      })
      .addCase(loadGlucoseReadingCGMSummary.rejected, (state) => {
        state.status = 'idle'
      })
      .addCase(loadBloodPressureSummary.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadBloodPressureSummary.fulfilled, (state, action) => {
        state.status = 'idle'
        state.readings = action.payload.data.readings
        state.bloodPressureAverageReadings = action.payload.data.bloodPressureAverageReadings
        state.highBloodPressureReadings = action.payload.data.highReadings
        state.aboveRangeBloodPressureReadings = action.payload.data.aboveRangeReadings
        state.inRangeBloodPressureReadings = action.payload.data.inRangeReadings
        state.lowBloodPressureReadings = action.payload.data.lowReadings
        state.averageDiastolicReading = action.payload.data.averageDiastolicReading
        state.averageSystolicReading = action.payload.data.averageSystolicReading
        state.averageHeartRate = action.payload.data.averageHeartRate
      })
      .addCase(loadBloodPressureSummary.rejected, (state) => {
        state.status = 'idle'
      })
      .addCase(loadScaleReadingSummary.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadScaleReadingSummary.fulfilled, (state, action) => {
        state.status = 'idle'
        state.scaleReadings = action.payload.data.allReadings
        state.bmi = action.payload.data.bmi
        state.goalWeight = action.payload.data.goalWeight
      })
      .addCase(loadScaleReadingSummary.rejected, (state) => {
        state.status = 'idle'
      })
  },
})
export const getBloodPressureReadings = (state: RootState) => state.root.dashboards.readings
export const getBloodPressureReadingsSummary = (state: RootState) => state.root.dashboards
export const getGlucoseReadings = (state: RootState) => state.root.dashboards.glucoseReadings
export const getGlucoseSummary = (state: RootState) => state.root.dashboards
export const getGlucoseDashboardStatus = (state: RootState) => state.root.dashboards.status
export const getGlucoseCGMReadings = (state: RootState) => state.root.dashboards.glucoseCGMReadings
export const getScaleReadings = (state: RootState) => state.root.dashboards.scaleReadings
export const getScaleReadingsSummary = (state: RootState) => state.root.dashboards

export default dashboardSlice.reducer
