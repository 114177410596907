export interface Patient {
  welltrakPID: number
  firstName: string
  lastName: string
  patientId: number
  enrollmentStatus: string
  dob: string
  age: number
  pharmacistId: number
  pharmacistName: string
  heightFeet: number
  heightInches: number
  weight: number
  BMI: number
  insertDate: string
  savings: number
  waistCircumference: number
  rewardCardAmount: number
  zip: string
  timeZone: number
  habits: PatientHabits
  intakeStatus: IntakeStatus
  dashboards: string[]
  hasDashboardOverridePermission: boolean
  hasEditingPermission: boolean
  hasAutoReimbursement: boolean
  hasSTOP: boolean
  ctlShortcode: string
  hasRewardCard: boolean
  devices: ConnectedDevice[]
  hasCGMDevice: boolean
  bannerMessages: BannerMessage[]
  userId: string
  patientGuid: string
  twoFactorEnabled: boolean
  incentives: Incentives
  langPreference: string
  hasChallenges: boolean
  canReactivateWithFollowUp: boolean
}

export interface Incentives {
  active: boolean
  allowCardsOnContactType: boolean
  amount: number
  carrierPid: number
  carrierRewardCardPid: number
  description: string
  endDate: string
  insertBy: string
  insertDate: string
  invoiceClient: boolean
  numberOfCards: number
  numberOfMonths: number
  programPid: number
  rewardTypePid: number
  scheduleRewardCard: boolean
  startDate: string
  triggerRequired: boolean
  updateBy: string
  updateDate: string
  wtAdmCarrierRewardCardContactType: []
  wtAdmCarrierRewardCardSchedule: []
}

export interface PatientHabits {
  notesDate: string
  tobaccoUsage: string
  caffeineUsage: string
  alcoholUsage: string
  activityLevel: string
}

export enum DashboardType {
  Glucose = 'BloodGlucose',
}

export enum IntakeStatus {
  MissingEnrollment = -1,
  IntakeIncomplete = 0,
  NeedToScheduleInitial = 1,
  WaitingOnInitial = 2,
  Complete = 3,
}

export type ConnectedDevice = {
  deviceId: string
  isCgm: boolean
  deviceType: DeviceType
}

export enum DeviceType {
  'Glucometer',
}

export interface ProfileFormValues {
  name: string
  dateOfBirth: string
  heightFt: string
  heightIn: string
  weight: string
  tobaccoUsage: string
  caffeineUsage: string
  alcoholUsage: string
  activityLevel: string
}

export type BannerMessage = {
  message: string
  route: string
}

export enum EnrollmentStatus {
  Engaged = 'PIN',
  Active = 'AC',
  Inactive = 'IN',
}
