import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { ReactNode, useEffect } from 'react'

interface Props {
  isMobile: boolean | undefined
  textSection: ReactNode
  textSection2: ReactNode
  title: string
  img: string
  img2: string
}
export default function Template6({ isMobile, textSection, textSection2, img, img2, title }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px', width: isMobile ? '100%' : '45%' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h1">{title}</Typography>
        </Row>
        <img src={img} alt="scale" style={{ width: isMobile ? '100%' : '294px' }} />
        {textSection}
        {/* <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px', width: isMobile ? '100%' : '70%' }}>
          <p style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            <i>{sectionInfo?.titles.find((title) => title.titleId === 7)?.titleText ?? ''}</i>{' '}
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 6)?.paragraph ?? ''}
          </p>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px', width: isMobile ? '100%' : '70%' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 7)?.paragraph ?? ''}</Typography>
        </Row>
        <Column styles={{ margin: isMobile ? '20px auto ' : '20px 0px ', width: isMobile ? '100%' : '70%' }}>
          <Typography variant="h2">{sectionInfo?.titles.find((title) => title.titleId === 9)?.titleText ?? ''}</Typography>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 8)?.paragraph ?? ''}</Typography>
        </Column> */}
      </Column>
      <Column styles={{ margin: isMobile ? '20px auto' : '76px 40px', width: isMobile ? '100%' : '45%' }}>
        <img src={img2} alt="scale" style={{ width: isMobile ? '100%' : '294px' }} />
        {textSection2}
      </Column>
    </ResponsiveContainer>
  )
}
