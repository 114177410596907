import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import colors from 'Styles/colors'
import { StyledPageContainer } from 'Styles/global'
import { Formik } from 'formik'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePatientAssessment from 'hooks/usePatientAssessment'
import React, { useEffect, useRef, useState } from 'react'
import 'react-rangeslider/lib/index.css'
import { Params, useParams } from 'react-router-dom'
import ReactSlider from 'react-slider'
import { useTheme } from 'styled-components'
import { Rapid3FormValues } from 'types/Forms'
import ContentHeader from '../../PatientProfile/components/ContentHeader'
import CalculationRow from '../components/CalculationRow'
import ColumnHeader from '../components/ColumnHeader'
import ScoreContainer from '../components/ScoreContainer'
import SurveyRow from '../components/SurveyRow'
import { rapid3Questions } from '../contants'
import './Rapid3Form.scss'

export default function Rapid3Form() {
  const { guid }: Readonly<Params<string>> = useParams()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Rapid 3')

  const theme = useTheme()

  const [score, setScore] = useState<number>(0)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [gettingScore, setGettingScore] = useState(false)

  const scoreRef = useRef<null | HTMLDivElement>(null)
  const executeScoreScroll = () => scoreRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const { getRapid3Score } = usePatientAssessment()

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const initialValues: Rapid3FormValues = {
    dressYourself: 0,
    inOutBed: 0,
    liftCup: 0,
    walk: 0,
    washDry: 0,
    bendDown: 0,
    faucet: 0,
    inOutCar: 0,
    walkTwoMiles: 0,
    sports: 0,
    pain: 0,
    globalActivity: 0,
  }

  const submitRapid3Form = (values: Rapid3FormValues) => {
    setGettingScore(true)

    values.guid = guid

    executeScoreScroll()
    getRapid3Score(values, (response: any) => {
      setScore(response.total)
    }).then(() => setGettingScore(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => submitRapid3Form(values)}>
      {({ values, setFieldValue, submitForm }) => {
        return (
          <StyledPageContainer>
            <ContentHeader title="Rapid 3" color={colors.primary.blue} />
            <p className="survey-title">Please check the ONE best answer for your abilities at this time.</p>
            <div className="survey-banner">Over The Last Week were you able to:</div>
            {!isMobile && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <div></div>
                <Row styles={{ width: '68%', justifyContent: 'space-between' }}>
                  <ColumnHeader title="Without ANY Difficulty" />
                  <ColumnHeader title="With SOME Difficulty" />
                  <ColumnHeader title="With MUCH Difficulty" />
                  <ColumnHeader title="UNABLE To Do" />
                </Row>
              </Row>
            )}

            {rapid3Questions.map((row, index) => (
              <SurveyRow
                question={row.question}
                selectedOption={values[row.fieldValue] ?? 0}
                isMobile={isMobile}
                onSelectOption={(value) => {
                  setFieldValue(row.fieldValue, value)
                }}
              />
            ))}

            <Row className="survey-row" styles={{ justifyContent: 'space-between', width: '100%' }}>
              <div className="survey-question-column" style={{ width: '100%', marginBottom: 10 }}>
                <Typography font="semi-bold">
                  {`How much pain have you had because of your condition over the past week?
            Please indicate below how severe your pain has been, based on a scale from 1 to 10.`}
                </Typography>
              </div>
            </Row>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="slider-thumb"
              trackClassName="slider-track"
              min={0}
              max={10}
              value={values.pain}
              onChange={(value: number) => setFieldValue('pain', value)}
              renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
            />
            <Row
              className="survey-row"
              styles={{
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 25,
              }}
            >
              <div className="survey-question-column" style={{ width: '100%', marginBottom: 10 }}>
                <Typography font="semi-bold">
                  {`Now consider all the ways in which illness and health conditions may affect you at this time.
            Please indicate below how you are doing, based on a scale from 1 to 10.`}
                </Typography>
              </div>
            </Row>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="slider-thumb"
              trackClassName="slider-track"
              value={values.globalActivity}
              min={0}
              max={10}
              onChange={(value: number) => setFieldValue('globalActivity', value)}
              renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
            />
            <CalculationRow
              onSubmit={submitForm}
              gettingScore={gettingScore}
              scoreRef={scoreRef}
              score={score}
              onReset={() => {
                setFieldValue('dressYourself', 0)
                setFieldValue('inOutBed', 0)
                setFieldValue('liftCup', 0)
                setFieldValue('walk', 0)
                setFieldValue('washDry', 0)
                setFieldValue('bendDown', 0)
                setFieldValue('faucet', 0)
                setFieldValue('inOutCar', 0)
                setFieldValue('walkTwoMiles', 0)
                setFieldValue('sports', 0)
                setFieldValue('pain', 0)
                setFieldValue('globalActivity', 0)
                setScore(0)
              }}
            />

            {score !== 0 && (
              <ScoreContainer
                rows={[
                  {
                    color: theme.colors.forms.mild,
                    range: '< 1.0',
                    label: 'Remission',
                  },
                  {
                    color: theme.colors.forms.moderate,
                    range: '1.1 - 2.0',
                    label: 'Low',
                  },
                  {
                    color: theme.colors.forms.high,
                    range: '2.1 - 4.0',
                    label: 'Moderate',
                  },
                  {
                    color: theme.colors.forms.severe,
                    range: '> 4.1',
                    label: 'High',
                  },
                ]}
              />
            )}
          </StyledPageContainer>
        )
      }}
    </Formik>
  )
}
