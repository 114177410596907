import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import styled, { useTheme } from 'styled-components'

export default function Overview() {
  const theme = useTheme()

  return (
    <>
      <Row styles={{ marginBottom: -10 }}>
        <Typography color={theme.colors.primary1} font="semi-bold" size={35}>
          Low Blood Glucose
        </Typography>
      </Row>
      <StyledRow>
        <Typography color={theme.colors.primary1} font="semi-bold" size={13}>
          Hypoglycemia
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography size={15}>
          INFORMATION PROVIDED ON THIS WEBSITE IS NOT INTENDED TO BE TREATED AS MEDICAL ADVICE AND SHOULD NOT UNDER ANY CIRCUMSTANCES BE USED AS A
          SUBSTITUTE FOR A PROFESSIONAL DIAGNOSIS OR PROFESSIONAL MEDICAL TREATMENT OR ADVICE.
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography font="semi-bold" size={25}>
          Hypoglycemia Overview:
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography size={15}>
          Diabetic hypoglycemia occurs when someone with diabetes doesn't have enough sugar (glucose) in his or her blood. Glucose is the main source
          of fuel for the body and brain, so you can't function well if you don't have enough.
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography size={15}>
          Low blood sugar (hypoglycemia) is defined as a blood sugar level below 70 milligrams per deciliter (mg/dL), or 3.9 millimoles per liter
          (mmol/L).
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography size={15}>
          Pay attention to the early warning signs of hypoglycemia, and treat low blood sugar promptly. You can raise your blood sugar quickly by
          eating or drinking a simple sugar source, such as glucose tablets or fruit juice. Tell family and friends what symptoms to look for and what
          to do if you're not able to treat the condition yourself.
        </Typography>
      </StyledRow>
    </>
  )
}

const StyledRow = styled(Row)`
  margin-bottom: 10px;
`
