import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import Icon from 'Components/Base/Icon'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { IconNames } from 'types/IconNames'

type Props = {
  size: number
  color: string
  iconSize: SizeProp
  icon: IconNames
  iconColor?: string
  styles?: React.CSSProperties
}
export default function ProgressIcon({ size, color, iconSize, styles, iconColor, icon }: Props) {
  const theme = useTheme()

  return (
    <StyledIconContainer
      size={size}
      style={{
        ...styles,
        borderColor: iconColor,
        backgroundColor: color,
      }}
    >
      <Icon name={icon} color={iconColor ?? theme.colors.white} size={iconSize} />
    </StyledIconContainer>
  )
}

type DividerProps = {
  iconSize: number
  styles?: React.CSSProperties
}
export function ProgressIconDivider({ iconSize, styles }: DividerProps) {
  const theme = useTheme()
  return <div style={{ height: '2px', width: iconSize * 4, background: theme.colors.primary1, ...styles, zIndex: -10 }} />
}

const StyledIconContainer = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`
