import Typography from 'Components/Base/Typography'
import { useAppDispatch } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePharmacistPage from 'hooks/usePharmacistPage'
import React, { useEffect, useState } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'

export default function PharmacistLandingPage() {
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [funFact, setFunFact] = useState<string>()
  const [areasOfFocus, setAreasOfFocus] = useState<string>()
  const [image, setImage] = useState<string>()

  const { pharmacistId }: Readonly<Params<string>> = useParams()
  const { getPharmacistPageData } = usePharmacistPage()
  const { setDocumentTitle } = useDocumentTitle()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    async function getPharmacistData() {
      dispatch(setLoading(true))
      await getPharmacistPageData(pharmacistId!)
        .then((response) => {
          if (!response.data) {
            navigate('/error')
          }
          setFirstName(response.data.firstName)
          setLastName(response.data.lastName)
          setFunFact(response.data.funFact)
          setAreasOfFocus(response.data.areasOfFocus)
          setImage(response.data.imgURL)
          setDocumentTitle(`${response.data.firstName} ${response.data.lastName.split(',')[0]}`)
        })
        .catch((err) => {
          navigate('/error')
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }

    pharmacistId && getPharmacistData()
  }, [])

  const about =
    "Tria Health's pharmacists are highly trained and experienced professionals who are dedicated to providing the best possible care for their patients. They are knowledgeable in a wide range of disease categories and are committed to providing the most up-to-date information and treatments. Their expertise and commitment to patient care make them an invaluable asset to your healthcare team."

  const defKey = [
    { title: 'PharmD:', def: 'Doctorate of Pharmacy' },
    { title: 'AE-C:', def: 'Certified Asthma Educator' },
    { title: 'BCACP:', def: 'Board Certified Ambulatory Care Pharmacist' },
    { title: 'BCPS:', def: 'Board Certified Pharmacotherapy Specialist' },
    { title: 'CDCES:', def: 'Certified Diabetes Care and Education Specialist' },
    { title: 'CSOWM:', def: 'Certified Specialist in Obesity and Weight Management' },
    { title: 'CSP:', def: 'Certified Specialty Pharmacist' },
  ]

  return (
    <div>
      {image && (
        <StyledPharmPageContainer>
          <StyledInfoContainer>
            <Typography size={54} font="bold">{`${firstName} ${lastName}`}</Typography>
            <Typography size={24} font="bold" styles={{ marginTop: 30 }}>
              Area(s) of Focus
            </Typography>
            <Typography size={20}>{`${areasOfFocus}`}</Typography>
            <Typography size={24} font="bold" styles={{ marginTop: 30 }}>
              Fun Fact:
            </Typography>
            <Typography size={20}>{`\"${funFact}\"`}</Typography>
            <Typography size={24} font="bold" styles={{ marginTop: 30 }}>
              About Tria Health's Pharmacists:
            </Typography>
            <Typography size={20}>{about}</Typography>
          </StyledInfoContainer>
          <StyledRightSideOfPageContainer>
            <StyledPictureCard>
              <img src={image} alt="Pharmacist" style={{ borderRadius: '50%', width: 250, height: 250 }}></img>
              <Typography size={20} font="bold">{`${firstName} ${lastName}`}</Typography>
            </StyledPictureCard>
            <StyledDefinitionCard>
              <div>
                <Typography font="bold" size={20}>
                  Definition Key:
                </Typography>
                <ul style={{ fontFamily: 'Raleway', fontSize: 16, listStylePosition: 'outside' }}>
                  {defKey.map((def, index) => {
                    return (
                      <li style={{ marginBottom: 5 }} key={index}>
                        <u>{def.title}</u> {def.def}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </StyledDefinitionCard>
          </StyledRightSideOfPageContainer>
        </StyledPharmPageContainer>
      )}
    </div>
  )
}

const StyledPharmPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  @media only screen and (max-width: 1100px) {
    padding: 15px;
    flex-direction: column;
  }
`

const StyledInfoContainer = styled.div`
  margin-right: 40px;
  margin-left: auto;
  margin-top: 20px;
  @media only screen and (max-width: 1100px) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
`

const StyledRightSideOfPageContainer = styled.div`
  margin-left: auto;
  @media only screen and (max-width: 1100px) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
`

const StyledPictureCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  padding: 30px 20px;
  height: 400px;
  width: 320px;
  margin-top: 20px;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    margin-right: auto;
    margin-left: auto;
  }
`

const StyledDefinitionCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  padding: 20px;
  height: 430px;
  width: 320px;
  margin-top: 20px;
  @media only screen and (max-width: 1100px) {
    margin-right: auto;
    margin-left: auto;
  }
`
