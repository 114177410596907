import React from 'react'

export interface ColorStyles {
  primary: React.CSSProperties
  neutral1: React.CSSProperties

  neutral3: React.CSSProperties

  tertiary1: React.CSSProperties
}

export interface HeadingStyles {
  h1: React.CSSProperties
  h2: React.CSSProperties
  h3: React.CSSProperties
  h4: React.CSSProperties
  h5: React.CSSProperties
  h6: React.CSSProperties
}

export interface FontStyles {
  regular: React.CSSProperties
  'semi-bold': React.CSSProperties

  bold: React.CSSProperties
}
export default function useTypeStyles() {
  const textColors: ColorStyles = {
    primary: {
      color: '#00677F',
    },
    neutral1: { color: '#FFFFFF' },
    neutral3: { color: '#000000' },
    tertiary1: { color: '#E40046' },
  }

  const fontStyles: FontStyles = {
    regular: {
      fontFamily: 'Raleway',
    },
    'semi-bold': {
      fontFamily: 'Raleway-Semi-Bold',
    },
    bold: {
      fontFamily: 'Raleway-Bold',
    },
  }

  const headingStyles: HeadingStyles = {
    h1: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      fontSize: 24,
      color: '#E40046',
    },
    h2: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      fontSize: 20,
      color: '#00677F',
    },
    h3: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      fontSize: 18,
      color: '#E40046',
    },
    h4: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      fontSize: 16,
      color: '#E40046',
    },
    h5: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      fontSize: 15,
      color: 'black',
    },
    h6: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      fontSize: 14,
      color: 'black',
    },
  }

  const colors: ColorStyles = {
    ...textColors,
  }
  const fonts: FontStyles = {
    ...fontStyles,
  }
  const headings: HeadingStyles = {
    ...headingStyles,
  }

  return { colors, fonts, headings }
}
