import qs from 'querystring'
import { UpdateRequest } from '../reducers/recommendationsSlice'
import { useApi } from './useAPI'

export default function useCareplan(token: string) {
  const TriaApi = useApi(token)

  const getCareplanDocument = async (documentId: string) => {
    const document = TriaApi.get(`api/careplans/document/${documentId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return document
  }

  const editCareplanDocument = async (documentId: string) => {
    const document = TriaApi.get(`api/careplans/edit/document/${documentId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return document
  }

  const editCareplanResource = async (documentId: string) => {
    const document = TriaApi.get(`api/careplans/edit/resource/${documentId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return document
  }

  const updateRecommendation = async (recommendation: UpdateRequest, callback?: Function) => {
    const data = qs.stringify({
      recommendationId: recommendation.recommendation.recommendationId,
      completed: recommendation.recommendation.completed,
      ignore: recommendation.recommendation.ignored,
    })

    const response = TriaApi.post(`api/recommendations/update`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  return { getCareplanDocument, updateRecommendation, editCareplanDocument, editCareplanResource }
}
