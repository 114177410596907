import qs from 'querystring'
import { useApi } from './useAPI'

export default function useDashboard() {
  const TriaApi = useApi()

  const getBloodPressureDeviceStatus = async () => {
    const deviceStatus = TriaApi.get(`api/dashboards/bloodpressure/deviceStatus`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return deviceStatus
  }

  const updateGlucoseConfig = async (glucoseLimit: number) => {
    const data = qs.stringify({
      GlucoseLimit: glucoseLimit,
    })
    const updateConfig = TriaApi.post(`api/dashboards/glucose/config`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return updateConfig
  }

  const updateBloodPressureConfig = async (highSystolicLimit: number, highDiastolicLimit: number) => {
    const data = qs.stringify({
      HighSystolicLimit: highSystolicLimit,
      HighDiastolicLimit: highDiastolicLimit,
    })
    const updateConfig = TriaApi.post(`api/dashboards/bloodpressure/config`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return updateConfig
  }

  return { updateGlucoseConfig, updateBloodPressureConfig, getBloodPressureDeviceStatus }
}
