import Button from 'Components/Base/Button'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { useStopContext } from 'Pages/ProgramScreen'
import { STOPActivitySteps } from 'Pages/STOPProgram'
import React, { useEffect, useState } from 'react'

type Props = {
  activityId: number
  activityStep: number
  onSetActivityStep: (step: number) => void
  onSaveAndQuit: () => void
  continueEnabled?: boolean
}

export default function ActivityButtonRow({ activityId, activityStep, onSetActivityStep, onSaveAndQuit }: Props) {
  const activityStepCount = STOPActivitySteps[activityId].steps.length
  const isReflectionScreen = activityStep === activityStepCount - 2
  const isCompleteScreen = activityStep === activityStepCount - 1

  const TIMER_COUNT = 2

  const [secondsRemaining, setSecondsRemaining] = useState(TIMER_COUNT)
  const [skipTimer, setSkipTimer] = useState(false)
  const { isContinueButtonEnabled, setIsContinueButtonEnabled } = useStopContext()

  useEffect(() => {
    if (activityId === 0 && activityStep === 4) {
      setSkipTimer(true)
    } else {
      setSkipTimer(false)
    }
  }, [activityId, activityStep])

  useEffect(() => {
    if (skipTimer) {
      return
    }
    if (secondsRemaining > 0) {
      const timer = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1)
      }, 1000)
      return () => clearTimeout(timer)
    } else {
      setIsContinueButtonEnabled(true)
    }
  }, [secondsRemaining])

  const handleNextStep = () => {
    window.scrollTo(0, 0)
    if (activityStep < activityStepCount - 1 && isContinueButtonEnabled) {
      onSetActivityStep(activityStep + 1)
      setSecondsRemaining(TIMER_COUNT)
      setIsContinueButtonEnabled(false)
      setSkipTimer(false)
    }
  }

  if (isCompleteScreen || isReflectionScreen) return <></>

  return (
    <ResponsiveContainer center styles={{ alignItems: 'center' }}>
      <Button text="Save & Quit" styles={{ width: 170, backgroundColor: '#425563' }} onClick={onSaveAndQuit} />
      <ResponsiveContainer styles={{ height: 10, width: 15 }}></ResponsiveContainer>
      <Button
        text={isReflectionScreen ? 'Submit' : isContinueButtonEnabled || skipTimer ? 'Continue' : `Continue in ${secondsRemaining}`}
        styles={{ width: 170, backgroundColor: isContinueButtonEnabled ? '' : '#grey' }}
        onClick={handleNextStep}
        disabled={!isContinueButtonEnabled}
      />
    </ResponsiveContainer>
  )
}
