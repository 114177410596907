import ExamImage from 'Assets/Images/Portal_Exam.svg'
import MedImage from 'Assets/Images/Portal_Med_Rec.svg'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import RecommendationModal from 'Pages/CarePlanScreen/components/Recommendations/RecommendationModal'
import SectionHeader from 'Pages/CarePlanScreen/components/Recommendations/SectionHeader'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useCareplan from 'hooks/useCareplan'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import {
  UpdateRequest,
  getCareplan,
  updateExamRecommendation,
  updateLifestyleRecommendation,
  updateMedicationRecommendation,
  updatePharmacistRecommendation,
  updateVaccineRecommendation,
} from 'reducers/recommendationsSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { SectionType } from 'types/Careplan'
import { Recommendation, TypeDescription } from 'types/Recommendation'
import './CarePlanScreen.scss'

interface Props {
  isMobile: boolean
  sideMenuToggled: () => void
  onGetDocument?: (fileName: string, documentId: string, documentLink: string) => void
}

export default function Recommendations({ isMobile, sideMenuToggled, onGetDocument }: Props) {
  const theme = useTheme()

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Recomendations')

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const careplan = useAppSelector(getCareplan)
  const navigate = useNavigate()

  const { updateRecommendation } = useCareplan(accessToken!)
  const analytics = useAnalytics(accessToken!)

  const [recModalOpen, setRecModalOpen] = useState(false)
  const [examRecommendations, setExamRecommendations] = useState<Recommendation[]>([])
  const [medicationRecommendations, setMedicationRecommendations] = useState<Recommendation[]>([])
  const [vaccineRecommendations, setVaccineRecommendations] = useState<Recommendation[]>([])
  const [lifestyleRecommendations, setLifestyleRecommendations] = useState<Recommendation[] | undefined>([])
  const [pharmacistRecommendations, setPharmacistRecommendations] = useState<Recommendation[]>([])
  const [currentRecommendation, setCurrentRecommendation] = useState<Recommendation | undefined>()

  const toggleModal = (recommendation: Recommendation) => {
    analytics.postAnalyticsEvent({
      category: `click_recommendation_open_modal`,
      action: `click_recommendation_open_modal`,
      label: `Button`,
      screenName: 'Recommendations',
    })
    setCurrentRecommendation(recommendation)
    setRecModalOpen(true)
  }

  useEffect(() => {
    window.sessionStorage.setItem('visitedRecs', 'true')
    navigate('')
  }, [])

  const ignoreRecommendation = (item: Recommendation) => {
    dispatch(setLoading(true))

    const request: UpdateRequest = {
      token: accessToken ?? '',
      recommendation: {
        recommendationId: item.recommendationPid,
        completed: false,
        ignored: true,
      },
    }

    updateRecommendation(request).then(() => {
      updateRecommendationState(item.recommendationType, request)
    })
  }

  const completeRecommendation = (item: Recommendation) => {
    dispatch(setLoading(true))

    const request: UpdateRequest = {
      token: accessToken ?? '',
      recommendation: {
        recommendationId: item.recommendationPid,
        completed: true,
        ignored: false,
      },
    }

    updateRecommendation(request).then(() => {
      updateRecommendationState(item.recommendationType, request)
    })
  }

  const incompleteRecommendation = (item: Recommendation) => {
    dispatch(setLoading(true))

    const request: UpdateRequest = {
      token: accessToken ?? '',
      recommendation: {
        recommendationId: item.recommendationPid,
        completed: false,
        ignored: false,
      },
    }

    updateRecommendation(request).then(() => {
      updateRecommendationState(item.recommendationType, request)
    })
  }

  const updateRecommendationState = (type: TypeDescription | null, request: UpdateRequest) => {
    switch (type) {
      case TypeDescription.ExamsAndTests:
        dispatch(updateExamRecommendation(request))
        break
      case TypeDescription.Vaccinations:
        dispatch(updateVaccineRecommendation(request))
        break
      case TypeDescription.Medication:
        dispatch(updateMedicationRecommendation(request))
        break
      case TypeDescription.Lifestyle:
        dispatch(updateLifestyleRecommendation(request))
        break
      case TypeDescription.Other:
        dispatch(updatePharmacistRecommendation(request))
        break
      default:
        return
    }
    setRecModalOpen(false)
  }

  useEffect(() => {
    if (!careplan) {
      dispatch(setLoading(true))
    } else {
      dispatch(setLoading(false))
    }
    if (careplan) {
      setExamRecommendations(
        careplan.recommendationsExams
          .slice()
          .sort((a, b) => Date.parse(a.recommendationDate) - Date.parse(b.recommendationDate))
          ?.reverse(),
      )
      setMedicationRecommendations(
        careplan.recommendationsMedications
          .slice()
          .sort((a, b) => Date.parse(a.recommendationDate) - Date.parse(b.recommendationDate))
          ?.reverse(),
      )
      setPharmacistRecommendations(
        careplan.recommendationsOther
          .slice()
          .sort((a, b) => Date.parse(a.recommendationDate) - Date.parse(b.recommendationDate))
          ?.reverse(),
      )
      careplan.recommendationsLifestyle &&
        setLifestyleRecommendations(
          careplan.recommendationsLifestyle
            .slice()
            .sort((a, b) => Date.parse(a.recommendationDate) - Date.parse(b.recommendationDate))
            ?.reverse(),
        )
      setVaccineRecommendations(
        careplan.recommendationsVaccine
          .slice()
          .sort((a, b) => Date.parse(a.recommendationDate) - Date.parse(b.recommendationDate))
          ?.reverse(),
      )
    }
  }, [careplan])

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title="Recommendations" icon="recommendations" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <div className="careplan-container">
        <StyledSectionsContainer>
          {medicationRecommendations.length > 0 && (
            <SectionHeader
              title={`Medication Recommendations`}
              type={SectionType.Recommendation}
              image={MedImage}
              color={theme.colors.carePlan.medRecommendations}
              icon="bottle"
              items={medicationRecommendations}
              onDetailClick={(item) => toggleModal(item)}
            />
          )}
          {pharmacistRecommendations.length > 0 && (
            <SectionHeader
              title="Pharmacist Recommendations"
              type={SectionType.Recommendation}
              image={'https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Other.svg'}
              color={theme.colors.carePlan.pharmRecommendations}
              icon="syringe"
              items={pharmacistRecommendations}
              onDetailClick={(item) => toggleModal(item)}
            />
          )}
          {examRecommendations.length > 0 && (
            <SectionHeader
              title={`Exams & Tests`}
              type={SectionType.Recommendation}
              image={ExamImage}
              color={theme.colors.carePlan.exams}
              icon="vial"
              items={examRecommendations}
              onDetailClick={(item) => toggleModal(item)}
            />
          )}
          {vaccineRecommendations.length > 0 && (
            <SectionHeader
              title="Vaccines"
              type={SectionType.Recommendation}
              image={'https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Vaccine.svg'}
              color={theme.colors.carePlan.vaccinations}
              icon="syringe"
              items={vaccineRecommendations}
              onDetailClick={(item) => toggleModal(item)}
            />
          )}
          {/* LIFESTYLE */}
          {lifestyleRecommendations && lifestyleRecommendations.length > 0 && (
            <SectionHeader
              title="Lifestyle"
              type={SectionType.Recommendation}
              image={'https://triacentral.blob.core.windows.net/dev-storage/images/Portal_Lifestyle.svg'}
              color={theme.colors.carePlan.lifestyle}
              icon="salad"
              items={lifestyleRecommendations}
              onDetailClick={(item) => toggleModal(item)}
            />
          )}
        </StyledSectionsContainer>
        {currentRecommendation && (
          <RecommendationModal
            modalOpen={recModalOpen}
            recommendation={currentRecommendation}
            toggleModal={() => setRecModalOpen(!recModalOpen)}
            onIgnored={(rec) => ignoreRecommendation(rec)}
            onMarkedComplete={(rec) => completeRecommendation(rec)}
            onMarkedIncomplete={(rec) => incompleteRecommendation(rec)}
          />
        )}
      </div>
    </>
  )
}

const StyledSectionsContainer = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`
