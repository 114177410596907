import qs from 'querystring'
import { NewMessageValues, ReplyMessageValues } from 'types/MessagingCenter'
import { useApi } from './useAPI'

export default function useMessagingCenter(token: string) {
  const TriaApi = useApi(token)
  const archivePatientMessage = async (PIMessagePId: number, callback?: Function) => {
    const data = qs.stringify({
      PatientInitiatedMessagePId: PIMessagePId,
    })
    const score = TriaApi.post(`api/messagingCenter/patient/archive`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })
    return score
  }

  const viewPatientMessage = async (PIMessageHistoryPId: number, callback?: Function) => {
    const data = qs.stringify({
      PatientInitiatedMessageHistoryPId: PIMessageHistoryPId,
    })
    const score = TriaApi.post(`api/messagingCenter/patient/view`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })
    return score
  }

  const postPatientReply = async (values: ReplyMessageValues, callback?: Function) => {
    const formData = new FormData()
    values.documents.map((doc) => {
      formData.append('documents', doc)
    })
    values.patientInitiatedMessagePId && formData.append('PatientInitiatedMessagePId', values.patientInitiatedMessagePId.toString())
    values.messageBody && formData.append('MessageBody', values.messageBody.toString())
    values.messageBody && formData.append('MessageSource', 'Portal')

    const score = TriaApi.post(`api/messagingCenter/patient/reply`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((resp) => {
      return callback && callback(resp.data)
    })

    return score
  }

  const addPatientMessage = async (values: NewMessageValues, callback?: Function) => {
    const formData = new FormData()
    values.documents.map((doc) => {
      formData.append('documents', doc)
    })
    values.patientInitiatedMessageTypePId && formData.append('PatientInitiatedMessageTypePId', values.patientInitiatedMessageTypePId.toString())
    values.messageBody && formData.append('MessageBody', values.messageBody.toString())
    values.enrollmentStatus && formData.append('EnrollmentStatus', values.enrollmentStatus.toString())
    values.messageBody && formData.append('MessageSource', 'Portal')
    values.triaInitiatedMessagePId && formData.append('TriaInitiatedMessagePId', values.triaInitiatedMessagePId.toString())

    const score = TriaApi.post(`api/messagingCenter/patient/add`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((resp) => {
      return callback && callback(resp.data)
    })

    return score
  }

  const updateTriaMessage = async (TriaMessagePId: number, callback?: Function) => {
    const data = qs.stringify({
      TriaInitiatedMessagePId: TriaMessagePId,
    })
    const score = TriaApi.post(`api/messagingCenter/tria/update/${TriaMessagePId}`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })
    return score
  }

  const viewTriaMessage = async (TriaMessagePId: number, callback?: Function) => {
    const data = qs.stringify({
      TriaInitiatedMessagePId: TriaMessagePId,
    })
    const score = TriaApi.post(`api/messagingCenter/tria/view/${TriaMessagePId}`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })
    return score
  }

  const getPatientMessagingCategories = async () => {
    const categories = TriaApi.get(`api/messagingCenter/patient/category`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return categories
  }

  const getTriaMessages = async () => {
    const messages = TriaApi.get(`api/messagingCenter/tria`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return messages
  }

  const getPatientMessages = async () => {
    const messages = TriaApi.get(`api/messagingCenter/patient`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return messages
  }
  return {
    getPatientMessagingCategories,
    getPatientMessages,
    getTriaMessages,
    archivePatientMessage,
    viewPatientMessage,
    updateTriaMessage,
    postPatientReply,
    addPatientMessage,
    viewTriaMessage,
  }
}
