import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentModal from 'Components/ContentModal'
import React, { useEffect, useState } from 'react'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import { Paragraph, SectionInfo } from 'types/Challenge'

interface Props {
  isMobile: boolean
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function CarbsStep6({ isMobile, step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setInfoPage(true)
    window.scrollTo(0, 0)
    setSectionInfo(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      ),
    )
    setStepParagraphs(
      data.pageInfoBySection.sectionInfo.find(
        (sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
      )?.paragraphs,
    )
  }, [step])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant="h1">{sectionInfo?.titles.find((title) => title.titleId === 14)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 18)?.paragraph ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 19)?.paragraph ?? ''}</Typography>
        </Row>
        <Column styles={{ margin: isMobile ? '20px auto' : '0px 40px 20px' }}>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 21)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 20)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 22)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 23)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 24)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 25)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 26)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 27)?.paragraph ?? ''}
          </li>
          <li style={{ fontFamily: 'Raleway', fontSize: 20 }}>
            {stepParagraphs?.find((paragraph) => paragraph.paragraphId === 28)?.paragraph ?? ''}
          </li>
        </Column>
      </Column>
      <Column styles={{ width: '100%', margin: 'auto 0px' }}>
        {!isMobile && (
          <ContentModal
            isOpen={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
            children={<img src={sectionInfo?.images.find((image) => image.imageId === 7)?.url} />}
          />
        )}
        <Row styles={{ width: 347, marginLeft: 'auto', border: '1px solid #000' }}>
          <Row styles={{ marginLeft: isMobile ? 0 : -300 }} onClick={() => setIsOpen(!isOpen)}>
            {!isMobile && (
              <Icon
                name="magnifying-glass-plus"
                size="4x"
                color="#000"
                styles={{ float: 'right', position: 'relative', marginTop: 70, left: '50%', textAlign: 'center', width: 300, zIndex: 1000 }}
              />
            )}
            <img
              src={sectionInfo?.images.find((image) => image.imageId === 7)?.url}
              alt="scale"
              style={{ width: '345px', marginLeft: 'auto', border: '1px solid #000' }}
            />
          </Row>
        </Row>
      </Column>
    </ResponsiveContainer>
  )
}
