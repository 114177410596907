import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTheme } from 'styled-components'
import Icon from '../Icon'
import Row from '../Row'
import Typography from '../Typography'

interface Props {
  manualRoute?: string
}
export default function BackRow({ manualRoute }: Props) {
  const navigate = useNavigate()
  const theme = useTheme()

  const [isMobile, setIsMobile] = useState<boolean>(false)

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const backPressed = () => {
    window.scrollTo(0, 0)
    if (manualRoute) {
      navigate(manualRoute)
    } else {
      navigate(-1)
    }
  }
  return (
    <>
      {isMobile ? (
        <Row
          styles={
            window.location.pathname.includes('resources')
              ? { marginBottom: 10, cursor: 'pointer', marginTop: -60 }
              : { marginBottom: 10, cursor: 'pointer' }
          }
          onClick={backPressed}
        >
          <Icon name="chevron-left" color={theme.colors.primary1} styles={{ marginRight: 5 }} />
          <Typography color={theme.colors.primary1} font="bold">
            Back
          </Typography>
        </Row>
      ) : (
        <></>
      )}
    </>
  )
}
