import React from 'react'
import ScaledText from '../ScaledText'

interface Props {
  children: React.ReactChild
  size?: number
  styles?: React.CSSProperties
}
export default function STOPHeader({ children, size, styles }: Props) {
  return (
    <ScaledText bold size={size ?? 30} styles={{ ...styles }}>
      {children}
    </ScaledText>
  )
}
