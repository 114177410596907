import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import React, { ReactNode, useEffect } from 'react'

interface Props {
  isMobile: boolean | undefined
  textSection: ReactNode
  imgSection: ReactNode
}
export default function Template5({ isMobile, textSection, imgSection }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : '0px 0px' }}>{textSection}</Column>
      <Column styles={{ width: '100%', margin: 'auto 0px' }}>{imgSection}</Column>
    </ResponsiveContainer>
  )
}
