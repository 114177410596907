import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Link from 'Components/Base/Link'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import React from 'react'
import Modal from 'react-modal'
import styled, { useTheme } from 'styled-components'
import { Recommendation } from 'types/Recommendation'
import { formatPatientText } from 'utilities/formatPatientText'

interface Props {
  modalOpen: boolean
  recommendation: Recommendation
  toggleModal: (isOpen: boolean) => void
  onMarkedComplete: (rec: Recommendation) => void
  onMarkedIncomplete: (rec: Recommendation) => void
  onIgnored: (rec: Recommendation) => void
}
export default function RecommendationModal({ modalOpen, recommendation, toggleModal, onMarkedComplete, onMarkedIncomplete, onIgnored }: Props) {
  const theme = useTheme()

  const closeModal = () => {
    toggleModal(false)
  }

  const getButtonOptions = (recommendation: Recommendation) => {
    if (recommendation.isCompleted || recommendation.isIgnored) {
      return (
        <StyledButton
          pageName="recommendation"
          text="Mark Incomplete"
          onClick={() => onMarkedIncomplete(recommendation)}
          styles={{ backgroundColor: theme.colors.gray, marginRight: 10 }}
        />
      )
    }

    return (
      <>
        <StyledButton
          pageName="recommendation"
          text="Complete"
          onClick={() => onMarkedComplete(recommendation)}
          styles={{ backgroundColor: theme.colors.status.good, marginRight: 10 }}
        />
        <StyledButton
          pageName="recommendation"
          text="Ignore"
          onClick={() => onIgnored(recommendation)}
          styles={{ backgroundColor: theme.colors.status.bad }}
        />
      </>
    )
  }

  return (
    <StyledContent isOpen={modalOpen} onRequestClose={() => toggleModal(false)}>
      <StyledHeader>
        <Typography color={theme.colors.white} font="bold" size={20}>
          Recommendation
        </Typography>
        <div onClick={closeModal}>
          <Icon name="times" color="white" />
        </div>
      </StyledHeader>
      <Column styles={{ marginTop: 40 }}>
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
          <Typography>{recommendation.recommendationDate}</Typography>
          {formatPatientText(recommendation.recommendationText).overview?.startsWith('•') ? (
            <Typography variant="h3">{formatPatientText(recommendation.recommendationText).overview?.substring(1) ?? ''}</Typography>
          ) : (
            <Typography>{formatPatientText(recommendation.recommendationText).overview ?? ''}</Typography>
          )}
          {formatPatientText(recommendation.recommendationText).listItems?.map((item) =>
            item.startsWith('•') ? (
              <Typography variant="h3">{item.substring(1)}</Typography>
            ) : (
              <ul>
                {item.startsWith('-') ? (
                  <Row styles={{ listStylePosition: 'outside', paddingLeft: 20 }}>
                    <Typography styles={{ paddingRight: 5 }}>- </Typography>
                    <Typography> {item.substring(1)}</Typography>
                  </Row>
                ) : (
                  <li>{item}</li>
                )}
              </ul>
            ),
          )}
        </div>
        <div>
          {recommendation.links.length > 0 ? (
            <Typography color={theme.colors.black} font="bold" size={20}>
              Click below for more information:
            </Typography>
          ) : (
            <div></div>
          )}
          {recommendation.links.length > 0 ? (
            recommendation.links.map((link) => (
              <Row>
                <StyledLink>
                  <a href={link.url} target="_blank" rel="noreferrer">
                    {link.label}
                  </a>
                </StyledLink>
              </Row>
            ))
          ) : (
            <div></div>
          )}
        </div>
        <div style={{ marginTop: 20 }}>{getButtonOptions(recommendation)}</div>
      </Column>
    </StyledContent>
  )
}

const StyledContent = styled(Modal)`
  position: absolute;
  width: 40%;
  height: fit-content;
  top: 40%;
  left: 50%;
  border-radius: 8px;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -30%);
  border: 1px solid rgb(204, 204, 204);
  background: ${({ theme }) => theme.colors.white};
  padding: 20px;
  margin-left: 5px;
  @media (max-width: ${sizes.tablet}) {
    width: 90%;
  }
`
const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px;
  border-radius: 4px;
  width: auto;
`

const StyledHeader = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary1};
  position: absolute;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  align-items: center;
  top: 0px;
  left: 0px;
  justify-content: space-between;
`
const StyledLink = styled(Link)`
  color: blue;
  text-decoration: underline;
`
