import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

type Props = {
  isOpen: boolean
  onToggle: (isOpen: boolean) => void
  width?: string | number
  children?: React.ReactChild | React.ReactChild[]
}
export default function ContentModal({ isOpen, onToggle, width, children }: Props) {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const customStyles = {
    content: {
      width: width ?? isMobile ? '90%' : 'fit-content',
      height: 'fit-content',
      overflow: 'none',
      top: '50%',
      left: '50%',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <StyledModalContainer style={{ display: isOpen ? 'block' : 'none' }}>
      <Modal isOpen={isOpen} onRequestClose={() => onToggle(!isOpen)} style={customStyles} ariaHideApp={false}>
        {children}
      </Modal>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  height: 60px;
  width: 25%;
  z-index: 1000;
  position: fixed;
  inset: 37% auto 40px 50%;
  transform: translate(-50%, -50%);
`
