import dayjs from 'dayjs'

export const sortReadingsByDate = (readings) => {
  return readings.sort((a, b) =>
    a.readingDate && b.readingDate && a.readingDate < b.readingDate ? -1 : a.readingDate && b.readingDate && a.readingDate > b.readingDate ? 1 : 0,
  )
}

export const filterReadingsByDate = (readings, startDate, endDate) => {
  const start = new Date(dayjs(startDate).format('YYYY-MM-DD')).getTime()
  const end = new Date(dayjs(endDate).format('YYYY-MM-DD')).getTime()

  return readings.filter((reading) => {
    const date = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
    return date >= start && date <= end
  })
}
