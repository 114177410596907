import { useApi } from './useAPI'

export default function useChooseToLose() {
  const TriaApi = useApi()

  const getCTLData = async (clientCode: string) => {
    const clientPageData = TriaApi.get(`api/chooseToLose/${clientCode}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return clientPageData
  }

  return { getCTLData }
}
