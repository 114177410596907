import Column from 'Components/Base/Column'
import { sizes } from 'Styles/sizes'
import React from 'react'
import styled from 'styled-components'
import './LowGlucosePage.scss'
import Overview from './Overview'
import Symptoms from './Symptoms'
import VideoResources from './VideoResources'

export default function LowGlucosePage() {
  return (
    <StyledContainer>
      <Column>
        <Overview />
        <Symptoms />
      </Column>
      <StyledVideoColumn>
        <VideoResources />
      </StyledVideoColumn>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  margin: 0% 5% 0% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  @media (${sizes.tablet}) {
    margin: 2% 0% 0% 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
const StyledVideoColumn = styled(Column)`
  width: 100%;
  margin: 0%;
  @media (${sizes.tablet}) {
    width: 35%;
    margin: 0% 5% 0% 5%;
  }
`
