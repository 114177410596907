import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import { sizes } from 'Styles/sizes'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Challenge } from 'types/Challenge'
import ContentHeader from '../ContentHeader'

interface Props {
  isMobile: boolean
  backPressed: () => void
  sideMenuToggled: () => void
}

export default function Challenges({ isMobile, backPressed, sideMenuToggled }: Props) {
  const [challenges, setChallenges] = useState<Challenge[]>()

  const token = useAppSelector(getAccessToken) ?? ''
  const { getChallenges } = useChallenges(token)
  const { setDocumentTitle } = useDocumentTitle()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  setDocumentTitle('Challenges')

  useEffect(() => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    getChallenges()
      .then((res) => {
        setChallenges(res.data.challenges)
      })
      .then(() => dispatch(setLoading(false)))
  }, [])

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }} onClick={backPressed}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}
        <ContentHeader title="Challenges" icon="badge" color="#00677f" />
        {isMobile && <div></div>}
      </Row>
      <StyledGrid>
        {challenges?.map((challenge) => (
          <StyledCard
            key={challenge.patientChallengePId}
            style={{ backgroundColor: '#FFF' }}
            onClick={() => navigate(`/challenges/${challenge.challengePId}`)}
          >
            <Icon name="external-link" color="#000" styles={{ marginLeft: 'auto', marginRight: 10, marginTop: -25 }} size="xl" />
            <img
              src={challenge.badgeEarned ? challenge.badgeIconUrl : challenge.grayScaleBadgeIconUrl}
              alt="section"
              style={{ height: 120, marginBottom: 20 }}
            />
            <h4 style={{ color: '#000', fontFamily: 'Raleway-Bold' }}>{challenge.badgeName}</h4>
            <p style={{ color: 'black', fontFamily: 'Raleway', margin: '0px auto' }}>
              {challenge.challengeSections.filter((section) => section.completed).length} of {challenge.numberOfSections}
            </p>
            <p style={{ color: 'black', fontFamily: 'Raleway', margin: '0px auto' }}>Challenges Complete</p>
          </StyledCard>
        ))}
      </StyledGrid>
      <div style={isMobile ? { margin: '0px auto', width: 232.66 } : {}}>
        <StyledButton style={{ backgroundColor: '#00677f', marginTop: 80 }} onClick={() => navigate('/challenges')}>
          View My Challenges
        </StyledButton>
      </div>
    </>
  )
}

const StyledCard = styled.div`
  height: 300px;
  width: 250px;
  border: 1px solid #828282;
  align-items: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`

const StyledGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 1rem;
  justify-items: center;
  align-items: center;
  @media ${sizes.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(270px, 0.2fr));
    justify-items: left;
  }
`

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-right: 20px;
    margin-bottom: 0px;
  }
`
