import Column from 'Components/Base/Column'
import { useStopContext } from 'Pages/ProgramScreen'
import { STOPActivitySteps } from 'Pages/STOPProgram'
import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import usePrograms from 'hooks/usePrograms'
import React from 'react'
import { useDispatch } from 'react-redux'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { STOPActivity } from 'types/STOP'
import ActivityButtonRow from '../ActivityButtonRow'
import ActivityNavRow from '../ActivityNavRow'

export type ActivityProps = {
  activityId: STOPActivity
  isComplete: boolean
  onSaveAndQuit: () => void
  accessDate?: string
  continueEnabled?: boolean
}

export default function Activity({ activityId, onSaveAndQuit }: ActivityProps) {
  const dispatch = useDispatch()
  const token = useAppSelector(getAccessToken)
  const analytics = useAnalytics(token!)

  const { postSTOPStatus } = usePrograms(token!)
  const { currentActivityStep, setCurrentActivityStep } = useStopContext()

  const getStepValue = (activityId, stepIndex) => {
    const activityDetails = STOPActivitySteps.find((activity) => activity.key === activityId)
    if (activityDetails && activityDetails.steps[stepIndex]) {
      return activityDetails.steps[stepIndex].value
    }
    return null
  }

  const saveActivityStep = () => {
    const stepValue = getStepValue(activityId, currentActivityStep)
    if (stepValue !== null) {
      dispatch(setLoading(true))
      postSTOPStatus(activityId, stepValue)
        .then(() => {
          analytics.postAnalyticsEvent({
            category: `click_button_stop`,
            action: `click_button_save_progress`,
            label: `Button`,
            screenName: 'StopProgram',
          })
          onSaveAndQuit()
          window.scrollTo(0, 0)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        <ActivityNavRow activityStep={currentActivityStep} activityId={activityId} onSetActivityStep={(step) => setCurrentActivityStep(step)} />

        <StyledResponsiveContainer>{STOPActivitySteps[activityId].steps[currentActivityStep].component}</StyledResponsiveContainer>
        <ActivityButtonRow
          activityId={activityId}
          activityStep={currentActivityStep}
          onSetActivityStep={(step) => setCurrentActivityStep(step)}
          onSaveAndQuit={saveActivityStep}
        />
      </StyledInnerContainer>
    </StyledOuterContainer>
  )
}

const StyledOuterContainer = styled.div`
  min-height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
`
const StyledInnerContainer = styled(Column)`
  width: 95%;
  margin-bottom: 50;
  margin: 10px 0px;
  padding: 5px 5px;
  align-items: center;
  @media (${sizes.tablet}) {
    width: 90%;
    justify-content: center;
    padding: 25px 50px;
    margin: 50px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #e0e0e0;
  }
`
const StyledResponsiveContainer = styled.div`
  flex: 1;
  margin: 25px 0px;
  width: 100%;
  justify-content: flex-start;
  @media (${sizes.tablet}) {
    width: 90%;
    justify-content: center;
  }
`
