import React from 'react'
import { useTheme } from 'styled-components'
import { CGMMetadata, ReadingCounts } from 'types/Dashboards'
import { VictoryPie } from 'victory'
import { CustomLabel } from './CustomLabel'

interface Props {
  readingCounts?: ReadingCounts
  cgmMetadata?: CGMMetadata
  selectedDevice
  pieChartRef
}
export default function PieChart({ readingCounts, cgmMetadata, selectedDevice, pieChartRef }: Props) {
  const theme = useTheme()

  return (
    <div ref={pieChartRef}>
      <VictoryPie
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => {
                return [
                  {
                    target: 'data',
                    mutation: () => {
                      return {
                        style: { fill: theme.colors.primary1, width: 30 },
                      }
                    },
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: true, labelRadius: 50 }),
                  },
                ]
              },
              onMouseOut: () => {
                return [
                  {
                    target: 'data',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    mutation: () => {},
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: false }),
                  },
                ]
              },
            },
          },
        ]}
        padding={{ top: 20, bottom: 20 }}
        data={[
          {
            x: '',
            y: readingCounts?.high,
            type: 'High',
            percentage:
              selectedDevice.cgm && cgmMetadata ? cgmMetadata?.percentHighReadings : Math.round((readingCounts!.high / readingCounts!.total) * 100),
          },
          {
            x: '',
            y: readingCounts?.aboveRange,
            type: 'Above Range',
            percentage:
              selectedDevice.cgm && cgmMetadata
                ? cgmMetadata?.percentAboveRangeReadings
                : Math.round((readingCounts!.aboveRange / readingCounts!.total) * 100),
          },
          {
            x: '',
            y: readingCounts?.inRange,
            type: 'In Range',
            percentage:
              selectedDevice.cgm && cgmMetadata
                ? cgmMetadata?.percentInRangeReadings
                : Math.round((readingCounts!.inRange / readingCounts!.total) * 100),
          },
          {
            x: '',
            y: readingCounts?.low,
            type: 'Low',
            percentage:
              selectedDevice.cgm && cgmMetadata ? cgmMetadata?.percentLowReadings : Math.round((readingCounts!.low / readingCounts!.total) * 100),
          },
          {
            x: '',
            y: readingCounts?.criticalLow,
            type: 'Critical Low',
            percentage:
              selectedDevice.cgm && cgmMetadata
                ? cgmMetadata?.percentCriticalLowReadings
                : Math.round((readingCounts!.criticalLow / readingCounts!.total) * 100),
          },
        ]}
        labels={({ datum }) => (datum.y / readingCounts!.total < 0.05 ? '' : datum.y)}
        labelComponent={<CustomLabel />}
        labelRadius={100}
        innerRadius={70}
        style={{
          parent: { touchAction: 'manipulation' },
          labels: { fontSize: 30, fill: 'white' },
        }}
        colorScale={[
          theme.colors.charts.dangerousHigh,
          theme.colors.charts.high,
          theme.colors.status.good,
          theme.colors.charts.low,
          theme.colors.charts.dangerousLow,
        ]}
      />
    </div>
  )
}
