import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import { Allergy } from '../types/Allergy'

export interface AllergiesState {
  patientAllergies: Allergy[] | undefined
  status: 'idle' | 'loading' | 'failed'
}

const initialState: AllergiesState = {
  patientAllergies: [],
  status: 'idle',
}

export const loadAllergies = createAsyncThunk('allergies/load', async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(token).get('api/allergies', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const allergiesSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAllergies.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadAllergies.fulfilled, (state, action) => {
        state.status = 'idle'
        state.patientAllergies = action.payload.data
      })
      .addCase(loadAllergies.rejected, (state) => {
        state.status = 'idle'
      })
  },
})

export const getAllergies = (state: RootState) => state.root.allergies.patientAllergies
export const getAllergiesStatus = (state: RootState) => state.root.allergies.status

export default allergiesSlice.reducer
