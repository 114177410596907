import { FormikErrors, getIn } from 'formik'

export function getErrorStyles(fieldName: string, errors: FormikErrors<any>, attemptedSubmit: boolean) {
  if (getIn(errors, fieldName) && attemptedSubmit) {
    return {
      border: '2px solid red',
      boxShadow: 'none',
    }
  }
}
