import logo from 'Assets/Images/Tria Logo_Primary.png'
import Column from 'Components/Base/Column'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePatientReadings from 'hooks/usePatientReadings'
import useProfile from 'hooks/useProfile'
import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Image } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import {
  getGlucoseCGMReadings,
  getGlucoseReadings,
  getGlucoseSummary,
  loadGlucoseReadingCGMSummary,
  loadGlucoseReadingSummary,
} from 'reducers/dashboardSlice'
import { getProfile } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { ChartReading } from 'types/ChartReading'
import {
  CGMMetadata,
  DashboardType,
  DateSetting,
  Device,
  GlucoseReadingMetadata,
  PatientGlucoseReading,
  RangeSetting,
  ReadingCounts,
} from 'types/Dashboards'
import { useScreenshot } from 'use-react-screenshot'
import PieChart from '../components/PieChart'
import PieChartKeyRange from '../components/PieChartKeyRange'
import { cgmFilterOptions, filterOptions } from '../constants'
import './DashboardPage.scss'
import { calculateCgmMetadata, calculateReadingCounts } from './calculateCounts'
import CGMCards from './components/CGMCards'
import ContentCards from './components/ContentCards'
import FilterRow from './components/FilterRow'
import GlucoseWhatsNewModal from './components/GlucoseWhatsNewModal'
import GridCards from './components/GridCards'
import LineChart from './components/LineChart'
import ReadingDataGrid from './components/ReadingDataGrid'
import TextSettingsCard from './components/TextSettingsCard'
import { sortReadingsByDate } from './dateFunctions'
import { filterChartByType } from './filterByChartType'
import { generatePDF } from './generatePDF'

const devices: Device[] = [
  { label: 'Non-CGM Readings', value: 0, cgm: false },
  { label: 'CGM Readings', value: 1, cgm: true },
]

export default function DashboardGlucosePage() {
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Blood Glucose')

  const theme = useTheme()
  const accessToken = useAppSelector(getAccessToken)
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const { getWelltrakFromGuid } = useProfile(accessToken!)
  const analytics = useAnalytics(accessToken!)

  const { getNotificationSettings } = usePatientReadings()

  const profile = useAppSelector(getProfile)
  const glucoseReadings = useAppSelector(getGlucoseReadings)
  const glucoseReadingsSummary = useAppSelector(getGlucoseSummary)
  const glucoseCGMReadings = useAppSelector(getGlucoseCGMReadings)
  const hasCGMDevice = profile?.devices.find((x: any) => x.isCgm)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [maximumReading, setMaximumReading] = useState<number | undefined>()
  const [readings, setReadings] = useState<PatientGlucoseReading[]>(glucoseCGMReadings ? glucoseCGMReadings : glucoseReadings)
  const [chartReadings, setChartReadings] = useState<ChartReading[]>()
  const [chartHeight, setChartHeight] = useState(300)
  const [minDate, setMinDate] = useState<string>('')
  const [maxDate, setMaxDate] = useState<string>('')
  const [selectedDevice, setSelectedDevice] = useState<Device>(
    hasCGMDevice
      ? {
          label: 'CGM Readings',
          value: 1,
          cgm: true,
        }
      : {
          label: 'Non-CGM Readings',
          value: 0,
          cgm: false,
        },
  )
  const [adminWelltrak, setAdminWelltrak] = useState<string>()
  const [chartReadingType, setChartReadingType] = useState<string>('All Reading Types')

  const [readingFilter, setReadingFilter] = useState<DateSetting>({
    label: 'Last 90 Days',
    value: 90,
  })
  const [prevReadingFilter, setPrevReadingFilter] = useState<DateSetting>({
    label: 'Last 90 Days',
    value: 90,
  })
  const [showCustomFields, setShowCustomFields] = useState<boolean>()
  const [cgmMetadata, setCgmMetadata] = useState<CGMMetadata>()

  const [dateRange, setDateRange] = useState<{ startDate: Date | undefined; endDate: Date | undefined }>({
    startDate: undefined,
    endDate: undefined,
  })

  const [readingCounts, setReadingCounts] = useState<ReadingCounts>(
    glucoseCGMReadings
      ? {
          high: glucoseCGMReadings.filter((reading) => reading.patientReadingLevel === 4).length ?? 0,
          aboveRange: glucoseCGMReadings.filter((reading) => reading.patientReadingLevel === 3).length ?? 0,
          inRange: glucoseCGMReadings.filter((reading) => reading.patientReadingLevel === 2).length ?? 0,
          low: glucoseCGMReadings.filter((reading) => reading.patientReadingLevel === 1).length ?? 0,
          criticalLow: glucoseCGMReadings.filter((reading) => reading.patientReadingLevel === 0).length ?? 0,
          total: glucoseCGMReadings.length ?? 0,
        }
      : {
          high: glucoseReadingsSummary.highReadings?.length ?? 0,
          aboveRange: glucoseReadingsSummary.aboveRangeReadings?.length ?? 0,
          inRange: glucoseReadingsSummary.inRangeReadings?.length ?? 0,
          low: glucoseReadingsSummary.lowReadings?.length ?? 0,
          criticalLow: glucoseReadingsSummary.criticalLowReadings?.length ?? 0,
          total: glucoseReadings?.length ?? 0,
        },
  )
  const [readingMetadata, setReadingMetadata] = useState<GlucoseReadingMetadata>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const [rangeError, setRangeError] = useState<boolean>(false)
  const [readingsChartImage, takeScreenshot] = useScreenshot()
  const [pieChartImage, takeScreenshot2] = useScreenshot()

  const readingsChartRef = useRef<HTMLDivElement | null>(null)
  const pieChartRef = useRef<HTMLDivElement | null>(null)

  const tableRef = useRef<null | HTMLDivElement>(null)
  const executeTableScroll = () => {
    /* This doesn't work every time without calling twice */
    tableRef.current!.scrollIntoView({ behavior: 'smooth', block: 'center' })
    tableRef.current!.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEffect(() => {
    const id = searchParams.get('id')
    const ID = searchParams.get('ID')
    if (id !== null) {
      getWelltrakFromGuid(id).then((res: any) => {
        setAdminWelltrak(res.data)
      })
    }
    if (ID !== null) {
      getWelltrakFromGuid(ID).then((res: any) => {
        setAdminWelltrak(res.data)
      })
    }

    if (!selectedDevice.cgm) {
      setReadingFilter({
        label: 'Last 90 Days',
        value: 90,
      })
    }

    setMobileSetting()

    window.addEventListener('resize', () => {
      setMobileSetting()
    })
    if (accessToken) {
      getNotificationSettings(accessToken).then((res) => {
        setMaximumReading(res.data.glucoseLimit)
      })
    }
  }, [])

  useEffect(() => {
    if (hasCGMDevice) {
      setSelectedDevice({ label: 'CGM Readings', value: 1, cgm: true })
      setReadingFilter({
        label: 'Last 14 Days',
        value: 14,
      })
    }
  }, [hasCGMDevice])

  const loadCGMReadings = () => {
    dispatch(
      loadGlucoseReadingCGMSummary({
        token: accessToken,
        overrideWelltrak: adminWelltrak,
      }),
    )
  }

  const loadReadings = () => {
    dispatch(
      loadGlucoseReadingSummary({
        token: accessToken,
        overrideWelltrak: adminWelltrak,
      }),
    )
  }

  const mapToChartReadings = (readings: PatientGlucoseReading[]) => {
    return readings.map((reading) => ({
      value: reading.value,
      date: `${dayjs(reading.readingDate).format('M/DD/YY')}\n${dayjs(reading.readingDate).format('h:mm:ss a')}`,
      type: reading.eventType,
    }))
  }

  useEffect(() => {
    if (!dateRange.startDate && !dateRange.endDate) {
      loadCGMReadings()
    }
    loadReadings()
  }, [adminWelltrak])

  useEffect(() => {
    if (prevReadingFilter.value === 0 && selectedDevice.cgm) {
      dispatch(
        loadGlucoseReadingCGMSummary({
          token: accessToken,
          overrideWelltrak: adminWelltrak,
        }),
      ).then(() => {
        setReadingFilter(readingFilter)
        getReadings(readingFilter)
      })
    }
    if (readings) {
      if (selectedDevice.cgm === true) {
        setCgmMetadata(calculateCgmMetadata(readings))
      } else {
        const chartReadings = mapToChartReadings(readings)
        setChartReadings(chartReadings.reverse())
        setChartReadingType('All Reading Types')
      }
      setReadingCounts(calculateReadingCounts(readings))
    }
    if (readingFilter.label === 'All Readings') {
      analytics.postAnalyticsEvent({
        category: `reading_filter_dashboardBloodGlucose_allReadings`,
        action: `reading_filter_dashboardBloodGlucose_allReadings`,
        label: `Button`,
        screenName: 'BloodGlucoseDashboard',
      })
    } else if (readingFilter.label === 'Custom') {
      analytics.postAnalyticsEvent({
        category: `reading_filter_dashboardBloodGlucose_customReadings`,
        action: `reading_filter_dashboardBloodGlucose_customReadings`,
        label: `Button`,
        screenName: 'BloodGlucoseDashboard',
      })
    }

    /* RESET TABLE OPTIONS */

    /* setStartTime(undefined)
    setEndTime(undefined)
    setRangeNumber(undefined)
    setRange({ high: undefined, low: undefined, label: 'All' })
    setReadingType('All Reading Types') */
  }, [readingFilter, dateRange, adminWelltrak])

  useEffect(() => {
    if (chartReadings && readings) {
      let readingsCopy = [...readings]

      readingsCopy = sortReadingsByDate(readingsCopy)
      updateChartReadings(readingsCopy)
    }
  }, [chartReadingType])

  useEffect(() => {
    //const tableRows = mapReadingsToTableRows(readings, isMobile)

    /* setItemRows(tableRows)
    setTableRows(tableRows) */
    if (readings && readings.length > 0) {
      if (glucoseReadingsSummary) {
        if (selectedDevice.cgm === true) {
          if (dateRange.startDate && dateRange.endDate) {
            setCgmMetadata(calculateCgmMetadata(readings))
            setReadingCounts(calculateReadingCounts(readings))
          }
        } else {
          setReadingCounts(calculateReadingCounts(readings))
          const chartReadings = mapToChartReadings(readings)
          setChartReadings(chartReadings.reverse())

          const avg = chartReadings.reduce((a, v) => (a = a + v.value), 0) / chartReadings.length
          setReadingMetadata({
            averageReading: Math.round(chartReadings.reduce((a, v) => (a = a + v.value), 0) / chartReadings.length),
            estimatedA1C: (avg + 46.7) / 28.7,
            firstReadingDate: dayjs(chartReadings[0]?.date),
            lastReadingDate: dayjs(chartReadings[chartReadings.length - 1]?.date),
            averageTestsPerDay:
              dateRange.startDate && dateRange.endDate
                ? chartReadings.length / dayjs(dateRange.endDate).diff(dayjs(dateRange.startDate), 'day')
                : readingFilter.value
                ? chartReadings.length / readingFilter.value
                : chartReadings.length / dayjs(chartReadings[chartReadings.length - 1]?.date).diff(dayjs(chartReadings[0]?.date), 'day'),
          })

          if (chartReadings.length > 0) {
            setMinDate(chartReadings[0].date)
            setMaxDate(chartReadings[chartReadings.length - 1]?.date)
          }
        }
      }
    }
  }, [readings, dateRange, adminWelltrak])

  useEffect(() => {
    if (selectedDevice.cgm) {
      dispatch(
        loadGlucoseReadingCGMSummary({
          token: accessToken,
          overrideWelltrak: adminWelltrak,
        }),
      ).then(() => {
        setReadingFilter({
          label: 'Last 14 Days',
          value: 14,
        })
        getReadings({
          label: 'Last 14 Days',
          value: 14,
        })
      })
    } else {
      setReadingFilter({
        label: 'Last 90 Days',
        value: 90,
      })
      getReadings({
        label: 'Last 90 Days',
        value: 90,
      })
    }
  }, [selectedDevice])

  useEffect(() => {
    if (selectedDevice.cgm) {
      if (dateRange.startDate && dateRange.endDate) {
        dispatch(
          loadGlucoseReadingCGMSummary({
            token: accessToken,
            overrideWelltrak: adminWelltrak ? adminWelltrak : '',
            startDate: dayjs(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(dateRange.endDate).format('YYYY-MM-DD'),
          }),
        )
      }
    } else {
      if (dateRange.startDate && dateRange.endDate) {
        getReadings(readingFilter)
      }
    }
  }, [dateRange, adminWelltrak])

  useEffect(() => {
    getReadings(readingFilter)
  }, [glucoseCGMReadings])

  function changeReadingFilter(option) {
    setPrevReadingFilter(readingFilter)
    if (selectedDevice.cgm && readingFilter.value === 0 && option.value !== 0) {
      dispatch(
        loadGlucoseReadingCGMSummary({
          token: accessToken,
          overrideWelltrak: adminWelltrak,
        }),
      ).then(() => {
        setReadingFilter(option)
        getReadings(option)
      })
    } else {
      setReadingFilter(option)
      getReadings(option)
    }
  }

  function changeAdminWelltrak() {
    setReadings([])
    /* Is this necessary? */
    /* setStartDate(startDate)
    setEndDate(endDate) */
    getReadings(readingFilter)
  }

  const updateChartReadings = (readings) => {
    const chartReadings = mapToChartReadings(readings)
    const chartReadingsByType = filterChartByType(chartReadings, chartReadingType)

    if (chartReadingsByType.length > 0) {
      setMinDate(chartReadingsByType[0].date)
      setMaxDate(chartReadingsByType[chartReadingsByType.length - 1]?.date)
    }
    setChartReadings(chartReadingsByType)
  }

  const handleRangeError = (rangeDiff) => {
    if ((rangeDiff > 14 || rangeDiff < 0) && selectedDevice.cgm) {
      setRangeError(true)
      return true
    } else if (rangeDiff < 0 && !selectedDevice.cgm) {
      setRangeError(true)
    } else {
      setRangeError(false)
      return false
    }
  }

  const handleCustomFields = (filter) => {
    if (filter && filter.value === 0) {
      setShowCustomFields(true)
    } else {
      setDateRange({
        startDate: undefined,
        endDate: undefined,
      })
      setShowCustomFields(false)
    }
  }

  /* Main reading reset filter function */
  const getReadings = (filter: DateSetting) => {
    handleCustomFields(filter)

    const rangeDiff = dayjs(dateRange.endDate).diff(dayjs(dateRange.startDate), 'day')
    if (handleRangeError(rangeDiff)) return

    if (selectedDevice.cgm === true) {
      const readingsResponse: PatientGlucoseReading[] = glucoseCGMReadings

      if (filter.value === 0 && dateRange.startDate && dateRange.endDate) {
        setReadings(readingsResponse)
      } else {
        if (readingsResponse) {
          setReadings(
            readingsResponse.filter((reading) => {
              const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
              const date2 = new Date(dayjs().subtract(filter.value!, 'day').format('YYYY-MM-DD')).getTime()
              if (date1 >= date2) {
                return reading
              }
            }),
          )
        }
      }
    } else {
      if (glucoseReadings) {
        setReadings(glucoseReadings)
        const readingsResponse: PatientGlucoseReading[] = glucoseReadings

        if (!filter.value && filter.value !== 0) {
          setReadings(readingsResponse)
        } else if (filter?.value === 0 && dateRange.startDate === undefined) {
          return
        } else if (filter.label === 'Custom') {
          setReadings(
            readingsResponse.filter((reading) => {
              const date1 = new Date(dayjs(dateRange.endDate).format('YYYY-MM-DD')).getTime()
              const date2 = new Date(dayjs(dateRange.startDate).format('YYYY-MM-DD')).getTime()
              const date3 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
              if (date3 >= date2 && date3 <= date1) {
                return reading
              }
            }),
          )
        } else {
          if (readingsResponse && readingsResponse.length > 0) {
            setReadings(
              readingsResponse.filter((reading) => {
                const date1 = new Date(dayjs(reading.readingDate).format('YYYY-MM-DD')).getTime()
                const date2 = new Date(dayjs().subtract(filter.value!, 'day').format('YYYY-MM-DD')).getTime()
                if (date1 >= date2) {
                  return reading
                }
              }),
            )

            let readingsCopy = [...readingsResponse]
            readingsCopy = sortReadingsByDate(readingsCopy)
            //updateTableRows(readingsCopy)

            updateChartReadings(readingsCopy)
          }
        }
      } else {
        setChartReadings([])
      }
    }
  }
  /* MISC Functions */
  const changeDevice = (option: Device) => {
    setSelectedDevice(option)

    if (option.cgm) {
      readings && readings.length > 0 && setReadingCounts(calculateReadingCounts(readings))
    }
  }
  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setChartHeight(800)
      setIsMobile(true)
    } else {
      setChartHeight(300)
      setIsMobile(false)
    }
  }

  /* PDF Functions */
  const getImage = () => {
    readingsChartRef.current && takeScreenshot(readingsChartRef.current)
    pieChartRef.current && takeScreenshot2(pieChartRef.current)
  }

  useEffect(() => {
    if (pieChartImage) {
      exportCharts()
    }
  }, [pieChartImage])

  const exportCharts = () => {
    generatePDF(readingMetadata, readingCounts, readingsChartImage, pieChartImage, logo, theme)
    dispatch(setLoading(false))
  }

  const createPDF = () => {
    dispatch(setLoading(true))
    getImage()
  }

  return (
    <>
      <div className="dashboard-page-container">
        {isMobile && <div style={{ minHeight: 20 }} />}
        {rangeError && (
          <ResponsiveContainer
            styles={{
              alignItems: 'center',
              width: isMobile ? '100%' : 'auto',
              height: 'auto',
              justifyContent: 'space-between',
            }}
          >
            <Typography styles={{ color: '#b2062e' }}>Invalid date range</Typography>
          </ResponsiveContainer>
        )}

        <ResponsiveContainer
          className="dashboard-filter-row"
          styles={{
            alignItems: 'center',
            width: isMobile ? '100%' : 'auto',
            height: 'auto',
            justifyContent: 'space-between',
          }}
        >
          <FilterRow
            cgmFilterOptions={cgmFilterOptions}
            filterOptions={filterOptions}
            hasDashboardOverridePermission={profile?.hasDashboardOverridePermission}
            isMobile={isMobile}
            readingFilter={readingFilter}
            selectedDevice={selectedDevice}
            showCustomFields={showCustomFields}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            adminWelltrak={adminWelltrak}
            onSetAdminWelltrak={(value) => setAdminWelltrak(value)}
            onChangeAdminWelltrak={changeAdminWelltrak}
            onChangeStartDate={(date) => {
              setDateRange((prevState) => ({
                ...prevState,
                startDate: date,
              }))
            }}
            onChangeEndDate={(date) => {
              setDateRange((prevState) => ({
                ...prevState,
                endDate: date,
              }))
            }}
            onChangeReadingFilter={(option) => changeReadingFilter(option)}
            onCreatePDF={createPDF}
          />

          {(profile?.hasDashboardOverridePermission || hasCGMDevice) && (
            <Dropdown className="date-filter-select-container">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="date-filter-select">
                {selectedDevice.label}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {devices.map((option) => (
                  <Dropdown.Item onClick={() => changeDevice(option)}>{option.label}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </ResponsiveContainer>
        {selectedDevice.cgm && (
          <ResponsiveContainer styles={{ justifyContent: 'end', marginBottom: 10 }}>
            <Image
              style={{ objectFit: 'contain' }}
              src={`https://triacentral.blob.core.windows.net/dev-storage/logos/dexcom/dexcom_logo.png`}
              height={15}
            />
          </ResponsiveContainer>
        )}
        <div className="charts-container">
          <StyledPieChartCard>
            <Column>
              {readings && readings.length > 0 ? (
                <PieChart pieChartRef={pieChartRef} readingCounts={readingCounts} cgmMetadata={cgmMetadata} selectedDevice={selectedDevice} />
              ) : (
                <StyledNoDataContainer>
                  <Row center>
                    <Typography font="semi-bold">No Data</Typography>
                  </Row>
                </StyledNoDataContainer>
              )}
            </Column>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Row>
                <Typography variant="h4" color={theme.colors.black}>
                  {`Blood Glucose Ranges (mg/dL):`}
                </Typography>
              </Row>
              <PieChartKeyRange title="High" range=">250" color="#EAAA00" rotation={0} />
              <PieChartKeyRange title="Above-Range" range="250-180" color="#FED141" rotation={45} />
              <PieChartKeyRange title="In-Range" range="180-70" color="#7A9A01" rotation={90} />
              <PieChartKeyRange title="Low" range="70-55" color="#E40046" rotation={135} />
              <PieChartKeyRange title="Dangerously Low" range="<54" color="#9B2743" rotation={180} />
            </div>
          </StyledPieChartCard>
          {selectedDevice.value === 0 ? (
            <LineChart
              onChartReadingTypeChange={(type) => setChartReadingType(type)}
              chartReadingType={chartReadingType}
              readings={readings}
              isMobile={isMobile}
              chartReadings={chartReadings}
              minDate={minDate}
              maxDate={maxDate}
              chartHeight={chartHeight}
              readingChartRef={readingsChartRef}
            />
          ) : (
            cgmMetadata && (
              <CGMCards
                setTableRange={(range: RangeSetting) => {
                  /* setRange(range)
                  setRangeNumber(rangeNumber) */
                  executeTableScroll()
                }}
                cgmMetadata={cgmMetadata}
              />
            )
          )}
        </div>
        {readings && readings.length > 0 && readingMetadata && !selectedDevice.cgm && (
          <GridCards
            metaData={readingMetadata}
            readingCounts={readingCounts}
            selectedDevice={selectedDevice}
            setTableRange={(range: RangeSetting) => {
              /* setRange(range)
              setRangeNumber(rangeNumber) */
              executeTableScroll()
            }}
          />
        )}
        {readings && readings.length > 0 && (
          <ReadingDataGrid
            accessToken={accessToken}
            adminWelltrak={adminWelltrak}
            isMobile={isMobile}
            readings={readings}
            setReadings={(readings: PatientGlucoseReading[]) => setReadings(readings)}
            selectedDevice={selectedDevice}
            tableRef={tableRef}
          />
        )}

        <div className="dashboard-resources-section">
          {!selectedDevice.cgm && <TextSettingsCard maximumReading={maximumReading} type={DashboardType.Glucose} />}
          <ContentCards type="Diabetes" onToggleModal={(isOpen) => setModalOpen(isOpen)} />
        </div>
      </div>
      {modalOpen && <GlucoseWhatsNewModal modalOpen={modalOpen} isCGM={selectedDevice.cgm} toggleModal={(isOpen) => setModalOpen(isOpen)} />}
    </>
  )
}

const StyledPieChartCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  padding-bottom: 15px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  @media ${sizes.tablet} {
    width: 28%;
    margin-bottom: 0px;
  }
`

const StyledNoDataContainer = styled.div`
  min-height: 300px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`
