export enum STOPActivity {
  HealthBenefits,
  OvercomingObstacles,
  MedicationOptions,
}
export type STOPActivityStepComponent = JSX.Element | (() => JSX.Element)

export type STOPActivityStepValue = {
  component: STOPActivityStepComponent
  value: number
}

export type STOPActivityStep = {
  key: STOPActivity
  title: string
  steps: STOPActivityStepValue[]
}
export type ProgramActivity = {
  firstName: string
  lastName: string
  activityId: number
  accessDate: string
  lastActivityStep: number
  completedDate?: string
}

enum HealthBenefitScreens {
  Introduction = 1,
  HealthBenefits,
  TypesOfTobacco,
  QuittingBenefits,
  BenefitsChart,
  BodyImpacts,
  HealthConditions1,
  HealthConditions2,
  HealthConditions3,
  ActivityReflection,
  ActivityComplete,
}

enum OvercomingObstaclesScreens {
  OvercomingObstacles = 12,
  ChooseToQuit,
  Obstacle1,
  Obstacle2,
  Obstacle3,
  Obstacle4,
  Quotes,
  ActivityReflection,
  ActivityComplete,
}

enum MedicationOptionsScreens {
  MedicationOptions = 21,
  MedicationHelp,
  QuittingWithoutNRT,
  NRTGum,
  NRTLozenge,
  NRTPatch,
  NRTNasalSpray,
  NRTInhaler,
  Medications1,
  Medications2,
  MedicationsSummary,
  ActivityReflection,
  ActivityComplete,
}

export interface PatientActivityValues {
  patientActivityPId: number
  welltrakPId: number
  activityPId: number
  availableDate: string
  startDate?: string
  lastActivityStepPId?: number
  completedDate?: string
}
