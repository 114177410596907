import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import LoadingIndicator from 'Components/LoadingIndicator'
import { useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React from 'react'
import { getPhysicians } from 'reducers/physiciansSlice'
import { useTheme } from 'styled-components'
import { TableData } from 'types/TableData'
import formatPhoneNumber from 'utilities/formatPhoneNumber'
import ContentHeader from '../ContentHeader'
import DataTable, { ColumnDefinitionType } from '../DataTable'
import HelpInfo from '../HelpInfo'
import './Physicians.scss'

interface Props {
  isMobile: boolean
  backPressed: () => void
  sideMenuToggled: () => void
}

export default function Physicians({ isMobile, backPressed, sideMenuToggled }: Props) {
  const physicians = useAppSelector(getPhysicians)
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Physicians')
  const theme = useTheme()

  const data = physicians
    ? physicians.map((physician) => ({
        name: `${physician.physicianDetail.firstName} ${physician.physicianDetail.lastName}`,
        phone: formatPhoneNumber({
          phoneNumber: physician.physicianDetail.pracPhone,
        }),
        primary: physician.physicianDetail.isPrimary,
      }))
    : []

  const columns: ColumnDefinitionType<keyof TableData>[] = [
    {
      key: 'name',
      header: 'Physician Name',
    },
    {
      key: 'phone',
      header: 'Office Phone',
    },
    {
      key: 'primary',
      header: 'Primary',
    },
  ]

  return physicians ? (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color={theme.colors.primary1} styles={{ marginBottom: 20 }} />
          </div>
        )}
        <ContentHeader title="Physicians" icon="doctor" color={theme.colors.primary1} />
        {isMobile && <div></div>}
      </Row>
      <DataTable columns={columns} data={data} centerData />
      <HelpInfo />
    </>
  ) : (
    <LoadingIndicator
      styles={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 400,
      }}
      imageWidth={150}
    />
  )
}
