import Card from 'Components/Base/Card'
import Column from 'Components/Base/Column'
import Typography from 'Components/Base/Typography'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  image: string
  title: string
  text: JSX.Element
  link: string
}

export default function WelcomeCard({ image, title, text, link }: Props) {
  const navigate = useNavigate()
  return (
    <Card height={'300px'} width="18%" onClick={() => navigate(link)}>
      <Column center styles={{ alignItems: 'center', height: '100%', textAlign: 'center', padding: 10 }}>
        <img src={image} alt="Man writing on calendar" height={125} />
        <Typography styles={{ fontWeight: 'bold' }}>{title}</Typography>
        {text}
      </Column>
    </Card>
  )
}
