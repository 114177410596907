import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useApi } from '../hooks/useAPI'
import { RootState } from '../store'
import { Appointment } from '../types/Appointment'

export interface AppointmentState {
  appointments: Appointment[]
  lastAppointmentDate: string
  status: 'idle' | 'loading' | 'failed'
}

const initialState: AppointmentState = {
  appointments: [],
  lastAppointmentDate: '',
  status: 'idle',
}

interface AppointmentPayload {
  appointment: {
    startTime: Appointment['startTime']
    endTime: Appointment['endTime']
    pharmacistId: Appointment['pharmacistId']
  }
  immediateAppointment: boolean
}

export const loadAppointments = createAsyncThunk('appointments/load', async (params: { token: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(params.token).get(`api/appointment/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const loadInitialAppointments = createAsyncThunk('appointments/initial/load', async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi().get(`api/enrollment/appointment`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const createAppointment = createAsyncThunk(
  'appointments/create',
  async (params: { token: string; payload: AppointmentPayload; callback?: () => void }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: Promise<any> = useApi(params.token)
      .post('api/appointments/schedule', params.payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        params.callback?.()
      })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log('This time slot is no longer available, please select another time slot.')
        }
        console.warn(err)
      })

    return response
  },
)

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAppointments.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadAppointments.fulfilled, (state, action) => {
        state.status = 'idle'
        state.appointments = action.payload.data.data
      })
      .addCase(loadAppointments.rejected, (state) => {
        state.status = 'idle'
      })
      .addCase(loadInitialAppointments.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadInitialAppointments.fulfilled, (state, action) => {
        state.status = 'idle'
        state.appointments = action.payload.data
      })
      .addCase(loadInitialAppointments.rejected, (state) => {
        state.status = 'idle'
      })
  },
})

export const getAppointments = (state: RootState) => state.root.appointment.appointments

export default appointmentSlice.reducer
