import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session' // defaults to localStorage for web
import errors from './reducers/errorSlice'
import rootReducer from './reducers/root'
import temp from './reducers/tempSlice'

const client = axios.create({
  baseURL: 'http://localhost:56847/',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  responseType: 'json',
})

const httpMiddleware = axiosMiddleware(client)

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: {
    root: persistedReducer,
    error: errors,
    temp: temp,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(httpMiddleware),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
