/* eslint-disable react-hooks/exhaustive-deps */

export default function useDocumentTitle(defaultOnUnmount = false) {
  /*  const defaultTitle = useRef(document.title)

  useEffect(
    () => () => {
      if (!defaultOnUnmount) {
        document.title = `${defaultTitle.current}`
      }
    },
    [],
  ) */
  const setDocumentTitle = async (title: string) => {
    document.title = `${title} | Tria Health`
  }

  return { setDocumentTitle }
}
