import Spacer from 'Components/Base/Spacer'
import BenefitScreen from 'Pages/STOPProgram/components/BenefitScreen'
import STOPHeader from 'Pages/STOPProgram/components/STOPHeader'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import React from 'react'
import BenefitsImage from '../../../../Assets/Images/STOP/Overcoming the obstacles.png'

export default function OvercomingObstacles() {
  return (
    <BenefitScreen
      content={
        <>
          <STOPHeader>Introduction to Activity 2:</STOPHeader>
          <STOPHeader>Overcoming the Obstacles</STOPHeader>
          <div style={{ marginTop: 25 }}>
            <ScaledText size={25}>
              During this activity we will review how to overcome the most common obstacles of quitting tobacco. Whether you use inhaled tobacco or
              smokeless tobacco, these techniques discussed can be useful to help you become free from tobacco.
            </ScaledText>
            <Spacer top>
              <ScaledText size={25}>
                If at any time during the modules you desire to speak with one of Tria's pharmacists about quitting tobacco, please reach out to Tria
                Health to schedule a time.
              </ScaledText>
            </Spacer>
          </div>
        </>
      }
      image={BenefitsImage}
      altTag="Man at a computer"
    />
  )
}
