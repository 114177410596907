import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ScrollButton from 'Components/ScrollButton'
import { StyledPageContainer } from 'Styles/global'
import { AxiosResponse } from 'axios'
import { useAppSelector } from 'hooks/hooks'
import useChooseToLose from 'hooks/useChooseToLose'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { getLoading, setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { CTLDetails } from 'types/CTL'
import './ChooseToLosePage.scss'
import CTLAccordion from './components/CTLAccordion'
import CTLProgramInfo from './components/CTLProgramInfo'

export default function ChooseToLosePage() {
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle(`Choose to Lose`)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useAppSelector(getLoading)

  const { client }: Readonly<Params<string>> = useParams()
  const { getCTLData } = useChooseToLose()
  const clientCode = client?.toUpperCase() ?? ''

  const [expandedSection, setExpandedSection] = useState<number | undefined>()
  const [programDetails, setProgramDetails] = useState<CTLDetails>()
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(false)
  const [calculatedBMI, setCalculatedBMI] = useState<string | undefined>()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const toggleSection = (sectionId: number) => {
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }

  const navigateToErrorPage = () => {
    navigate('/404')
  }

  useEffect(() => {
    dispatch(setLoading(true))

    getCTLData(clientCode)
      .then((res: AxiosResponse<CTLDetails>) => {
        if (res.status === 204) {
          navigateToErrorPage()
        }
        setProgramDetails(res.data)
        if (res.data.customProgramOptIn !== '' || !res.data.programStarted) {
          setApplyButtonDisabled(true)
        }
      })
      .catch((err) => {
        navigateToErrorPage()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  return (
    <Container>
      {!loading && (
        <>
          <CTLProgramInfo
            programDetails={programDetails}
            clientCode={clientCode}
            applyButtonDisabled={applyButtonDisabled}
            onSetApplyButtonDisabled={(value) => setApplyButtonDisabled(value)}
            isMobile={isMobile}
          />

          <hr style={{ marginTop: 50 }} />
          <Row>
            <Typography size={25} styles={{ marginBottom: 10 }} font="semi-bold">
              More Information
            </Typography>
          </Row>
          <CTLAccordion expandedSection={expandedSection} onToggle={(value) => toggleSection(value)} />
          <ScrollButton />
        </>
      )}
    </Container>
  )
}

const Container = styled(StyledPageContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
`
