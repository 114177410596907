import Card from 'Components/Base/Card'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import ConsentModal from 'Components/ConsentModal'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import { AxiosResponse } from 'axios'
import useAppointments from 'hooks/useAppointments'
import useCommunication from 'hooks/useCommunication'
import useMessagingCenter from 'hooks/useMessagingCenter'
import usePatientIntake from 'hooks/usePatientIntake'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { getCareplan } from 'reducers/recommendationsSlice'
import { getLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { AppointmentResponse } from 'types/Appointment'
import AppointmentsImage from '../../../Assets/Images/Calendar-26.svg'
import CarePlanImage from '../../../Assets/Images/Care Plan-24.png'
import CTLImage from '../../../Assets/Images/Exercise & Pills 1.png'
import DeviceImage from '../../../Assets/Images/Hand Holding Cell Phone 1.png'
import AppImage from '../../../Assets/Images/Hands Holding Phone 1.png'
import IntakeImage from '../../../Assets/Images/Intake Form.png'
import MessagesImage from '../../../Assets/Images/Messaging-25.png'
import ConsultationImage from '../../../Assets/Images/Pharmacist Call.png'
import ReimbursementImage from '../../../Assets/Images/Reimbursement-27 1.png'
import SecurePhoneImage from '../../../Assets/Images/SecurePhone.png'
import Button from '../../../Components/Base/Button'
import Row from '../../../Components/Base/Row'
import Typography from '../../../Components/Base/Typography'
import { APPSTORE_URL, PLAYSTORE_URL } from '../../../constants'
import { useAppSelector } from '../../../hooks/hooks'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import useReimbursement from '../../../hooks/useReimbursement'
import { getProfile } from '../../../reducers/profileSlice'
import { IntakeStatus } from '../../../types/Profile'
import WelcomeCard from './WelcomeCard'
import './WelcomeView.scss'

interface Props {
  forceMultiFactor: boolean | undefined
  isMobile: boolean
}

export default function WelcomeView({ forceMultiFactor, isMobile }: Props) {
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Home')

  const navigate = useNavigate()

  const loading = useAppSelector(getLoading)
  const profile = useAppSelector(getProfile)
  const token = useAppSelector(getAccessToken)
  const careplan = useAppSelector(getCareplan)

  const { getAppointments } = useAppointments(token!)
  const { getCommunication } = useCommunication(token!)
  const { getAllReimbursement } = useReimbursement(token!)
  const { getPatientMessages, getTriaMessages } = useMessagingCenter(token!)
  const { getEnrollmentStatus } = usePatientIntake({
    token: token,
  })

  const [modalOpen, setModalOpen] = useState(false)
  const [reimbursementCount, setReimbursementCount] = useState(0)
  const [appointment, setAppointment] = useState<AppointmentResponse>()
  const [unreadTriaMessagesCount, setUnreadTriaMessagesCount] = useState<number>(0)
  const [unreadPatientMessagesCount, setUnreadPatientMessagesCount] = useState<number>(0)
  const [enrollmentStatus, setEnrollmentStatus] = useState<number>(0)
  const enrollmentStatusPastPrivacy = [12, 14, 10, 9, 8, 7, 6, 2]

  useEffect(() => {
    getEnrollmentStatus().then((res) => {
      setEnrollmentStatus(res.data.statusId)
    })
    getAppointments().then((res: AxiosResponse<AppointmentResponse>) => {
      setAppointment(res.data)
    })
    getCommunication().then((res: AxiosResponse<any>) => {
      setModalOpen(!res.data.hasSignedConsent)
    })
    getAllReimbursement().then((res) => {
      let openReimbursements = 0
      res.data.map((reimbursement) => {
        const lastStatus = reimbursement.statuses[reimbursement.statuses.length - 1]?.status?.toLowerCase()
        if (lastStatus === 'processing' || lastStatus === 'submitted' || reimbursement.statuses.length === 0) {
          openReimbursements++
        }
      })
      setReimbursementCount(openReimbursements)
    })
    getPatientMessages().then((res) => {
      setUnreadPatientMessagesCount(res.data.patientInitiatedMessages?.filter((message) => message.hasNewMessage).length ?? 0)
    })
    getTriaMessages().then((res) => {
      setUnreadTriaMessagesCount(res.data.triaInitiatedMessages?.filter((message) => !message.viewedDate && !message.archivedDate).length ?? 0)
    })
  }, [])

  const clickExternalLink = (link) => {
    const width = isMobile ? window.outerWidth - 100 : window.outerWidth - 600
    const height = isMobile ? window.outerHeight - 100 : window.outerHeight - 200
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const title = `Multi-Factor Authentication`
    window.open(link, title, `width=${width},height=${height},left=${left},top=${top}`)
  }

  const createCards = () => {
    const cards: any[] = []

    if (profile?.ctlShortcode && profile.ctlShortcode !== '') {
      cards.push(
        <Card height={'fit-content'} width="100%" onClick={() => navigate(`/ctl-${profile.ctlShortcode}`)} styles={{ marginBottom: 10 }}>
          <Row center styles={{ alignItems: 'center', height: '100%', textAlign: 'left', padding: 10 }}>
            <StyledCardImage height={200} src={CTLImage} alt="Man writing on calendar" style={{ marginRight: 25 }} />
            <Column>
              <Typography size={25} styles={{ fontWeight: 'bold' }}>
                Tria’s Weight Loss Program
              </Typography>
              <Typography>Choose to Lose sets you up for weight loss success with a designated health coach.</Typography>
              <Typography styles={{ textDecoration: 'underline' }} color="#00677F">
                Click here to learn more
              </Typography>
            </Column>
          </Row>
        </Card>,
      )
    }

    cards.push(
      <Card
        height={'fit-content'}
        width="100%"
        styles={{ marginBottom: 10 }}
        onClick={() => window.open(`${process.env.REACT_APP_AUTH_URL}/Identity/Account/Manage/SecurePhone`, '_blank', 'noopener,noreferrer')}
      >
        <Row center styles={{ alignItems: 'center', height: '100%', textAlign: 'left', padding: 10 }}>
          <StyledCardImage height={200} src={SecurePhoneImage} alt="Man writing on calendar" style={{ marginRight: 25, width: 200 }} />
          <Column>
            <Typography size={25} styles={{ fontWeight: 'bold' }}>
              Set up Your Recovery Phone
            </Typography>
            <Typography>Your recovery phone is used to reach you in case you accidentally get locked out.</Typography>
            <Typography styles={{ textDecoration: 'underline' }} color="#00677F">
              Click here to set your recovery phone
            </Typography>
          </Column>
        </Row>
      </Card>,
    )

    if (profile && profile.devices.length > 0) {
      cards.push(
        <Card height={'fit-content'} width="100%" onClick={() => navigate(`/resources/mobileapp`)} styles={{ marginBottom: 20 }}>
          <Row center styles={{ alignItems: 'center', height: '100%', textAlign: 'left', padding: 10 }}>
            <StyledCardImage height={200} src={DeviceImage} alt="Man writing on calendar" style={{ marginRight: 25 }} />
            <Column>
              <Typography size={25} styles={{ fontWeight: 'bold' }}>
                New Device Connectivity
              </Typography>
              <Typography>Do you have a Dexcom CGM or an Accu-Chek Guide Me? Connect your device directly with Tria Health’s App.</Typography>
              <Typography styles={{ textDecoration: 'underline' }} color="#00677F">
                View Connectivity Guides
              </Typography>
            </Column>
          </Row>
        </Card>,
      )
    }

    return cards
  }
  const getProfileContent = () => {
    switch (profile?.intakeStatus) {
      case IntakeStatus.Complete:
        if (!forceMultiFactor) {
          return (
            <Column style={{ width: '100%' }}>
              <StyledCardContainer>
                <WelcomeCard
                  image={CarePlanImage}
                  title="Care Plan"
                  text={
                    <Typography>
                      You have{' '}
                      <span style={{ fontWeight: 'bold', color: 'red' }}>
                        {careplan?.allRecommendations.filter((x) => x.isCompleted === false && x.isIgnored === false).length ?? 0}
                      </span>{' '}
                      open recommendations within your care plan.
                    </Typography>
                  }
                  link="/careplan"
                />
                <WelcomeCard
                  image={MessagesImage}
                  title="Messages"
                  text={
                    <Typography>
                      You have{' '}
                      <span style={{ fontWeight: 'bold', color: unreadTriaMessagesCount + unreadPatientMessagesCount > 0 ? 'red' : 'black' }}>
                        {unreadPatientMessagesCount + unreadTriaMessagesCount}
                      </span>{' '}
                      unread messages in your inbox.
                    </Typography>
                  }
                  link="/messaging"
                />
                <WelcomeCard
                  image={AppointmentsImage}
                  title="Appointments"
                  text={
                    appointment?.scheduledAppointment !== undefined && appointment?.scheduledAppointment.length !== 0 ? (
                      <Typography>{`You have an appointment on ${appointment.scheduledAppointment}`}</Typography>
                    ) : appointment?.canSchedule ? (
                      <Typography>You are due for a follow up, click here to get scheduled.</Typography>
                    ) : (
                      <Typography>You are not due for a follow up appointment at this time.</Typography>
                    )
                  }
                  link="/appointment"
                />
                <WelcomeCard
                  image={ReimbursementImage}
                  title="Reimbursement"
                  text={
                    <Typography>
                      You have <span style={{ fontWeight: 'bold', color: reimbursementCount > 0 ? 'red' : 'black' }}>{reimbursementCount}</span> open
                      reimbursement requests.
                    </Typography>
                  }
                  link={reimbursementCount > 0 ? '/reimbursements' : '/reimbursement'}
                />
              </StyledCardContainer>
              <StyledProgramContainer styles={{ justifyContent: 'space-around', marginTop: 50 }}>
                <StyledCardWrapper>
                  <Card height={'fit-content'} styles={{ cursor: 'auto' }} width="100%">
                    <Row styles={{ alignItems: 'center', height: '100%', textAlign: 'left', padding: 10 }}>
                      <StyledCardImage height={200} src={AppImage} alt="Man writing on calendar" style={{ marginRight: 25, width: 200 }} />
                      <Column>
                        <Typography size={25} styles={{ fontWeight: 'bold' }}>
                          Download Our Mobile App!
                        </Typography>
                        <Typography>Access Tria Health on-the-go! You’ll be able to view your care plan, medication list and more! </Typography>
                        <Row>
                          <Icon
                            name={'play'}
                            link={PLAYSTORE_URL}
                            className="social-icon"
                            color="#ffffff"
                            size="1x"
                            styles={{
                              cursor: 'pointer',
                              backgroundColor: '#000000',
                              borderRadius: 5,
                              padding: 3.5,
                              marginTop: 1,
                            }}
                          />
                          <Icon
                            name={'ios'}
                            link={APPSTORE_URL}
                            className="social-icon"
                            color="#000000"
                            size="xl"
                            styles={{
                              cursor: 'pointer',
                              marginLeft: 15,
                              backgroundColor: '#ffffff',
                            }}
                          />
                        </Row>
                      </Column>
                    </Row>
                  </Card>
                </StyledCardWrapper>
                <StyledCardWrapper>
                  <Carousel showArrows={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={6000}>
                    {createCards()}
                  </Carousel>
                </StyledCardWrapper>
              </StyledProgramContainer>
            </Column>
          )
        } else {
          return (
            <StyledCardContainer styles={{ width: '91%', height: 'fit-content' }}>
              <Card width="100%" height="fit-content">
                <ResponsiveContainer styles={{ padding: 25, height: '100%', position: 'relative' }}>
                  <StyledCardHeader>
                    <Typography size={32} styles={{ margin: 'auto', fontWeight: 700, fontSize: 32 }}>
                      Action Required
                    </Typography>
                  </StyledCardHeader>
                  <StyledColumn center>
                    <ScaledText size={24} styles={{ margin: '80px auto 25px', textAlign: 'center', width: '80%' }}>
                      To ensure the security of your personal healthcare information, multi-factor authentication (MFA) is now required to access your
                      account.
                    </ScaledText>
                    <ScaledText size={24} styles={{ marginBottom: 25, textAlign: 'center' }}>
                      Please enable MFA to proceed.
                    </ScaledText>
                    <StyledMultifactorButton
                      pageName="MultiFactor"
                      text="Enable Multi-Factor Authentication"
                      backgroundColor="#9F2842"
                      onClick={() => clickExternalLink(`https://medchatapp.com/widget/bLZCrHZAnkSVkQoS3dvulQ/chat`)}
                    />
                    <ScaledText size={24} styles={{ marginBottom: 25, marginTop: 25, textAlign: 'center' }}>
                      <b>Need Help?</b>
                      <div>
                        <u>Click here</u> for detailed instructions.
                      </div>
                    </ScaledText>
                  </StyledColumn>
                </ResponsiveContainer>
              </Card>
            </StyledCardContainer>
          )
        }

      case IntakeStatus.IntakeIncomplete:
      default:
        return (
          <StyledCardContainer styles={{ width: '91%' }}>
            <Card width="100%" height="fit-content">
              <ResponsiveContainer styles={{ padding: 25, height: '100%', position: 'relative' }}>
                <StyledIcon name="circleExclamation" size={'3x'} color="#9B2743" />
                <StyledColumn center>
                  <ScaledText size={30} styles={{ marginBottom: 25 }}>
                    Your patient portal will become unlocked after you complete your first consultation with a Tria Health Pharmacist.
                  </ScaledText>
                  <ScaledText size={30} styles={{ marginBottom: 25 }}>
                    You must complete your patient intake form prior to your appointment on:
                  </ScaledText>
                  <div
                    style={
                      appointment?.scheduledAppointment
                        ? {
                            border: '2px solid transparent',
                            borderRadius: 4,
                            backgroundColor: '#edfdff',
                            boxShadow: 'inset 0px 1px 3px',
                            minHeight: 40,
                            fontSize: 20,
                            width: 'fit-content',
                            marginBottom: '25px',
                            padding: '10px 10px',
                          }
                        : { display: 'none' }
                    }
                  >
                    <text>{appointment?.scheduledAppointment}</text>
                  </div>
                  <StyledScheduleButton
                    pageName="IntakeIncomplete"
                    text="My Patient Intake Form"
                    backgroundColor="#9B2743"
                    leftIcon="circleExclamation"
                    styledWidth={300}
                    onClick={() => navigate('/patientintake')}
                  />
                  <ScaledText size={20} bold styles={{ marginBottom: 25, color: '#9B2743', marginTop: 15 }}>
                    If we do not receive your intake form before your initial appointment, we may have to reschedule due to time constraints.
                  </ScaledText>
                </StyledColumn>
                <StyledColumn center style={{ position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <StyledImage src={IntakeImage} alt="Woman at a computer" height={'100%'} width={'100%'} style={{ maxHeight: 500 }} />
                </StyledColumn>
              </ResponsiveContainer>
            </Card>
          </StyledCardContainer>
        )

      case IntakeStatus.MissingEnrollment:
        return (
          <StyledCardContainer styles={{ width: '91%' }}>
            <Card width="100%" height="fit-content">
              <ResponsiveContainer styles={{ padding: 25, height: '100%' }}>
                <StyledColumn center>
                  <ScaledText size={30} styles={{ marginBottom: 25 }}>
                    Your patient portal will become unlocked after you complete your first consultation with a Tria Health Pharmacist.
                  </ScaledText>
                  <ScaledText size={30} styles={{ marginBottom: 25 }}>
                    Please schedule your initial appointment and complete your patient intake form.
                  </ScaledText>
                  <StyledScheduleButton
                    pageName="MissingEnrollment"
                    text="Schedule Today"
                    onClick={() => navigate('/appointment')}
                    styledWidth={200}
                  />
                </StyledColumn>

                <StyledColumn center style={{ position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <StyledImage src={AppointmentsImage} alt="Calendar with date selected" height={'100%'} width={'100%'} style={{ maxHeight: 500 }} />
                </StyledColumn>
              </ResponsiveContainer>
            </Card>
          </StyledCardContainer>
        )

      case IntakeStatus.NeedToScheduleInitial:
        return (
          <StyledCardContainer styles={{ width: '91%' }}>
            <Card width="100%" height="fit-content">
              <ResponsiveContainer styles={{ padding: 25, height: '100%' }}>
                <Column center>
                  <ScaledText size={30} styles={{ marginBottom: 25 }}>
                    Your patient portal will become unlocked after you complete your first consultation with a Tria Health Pharmacist.
                  </ScaledText>
                  <StyledScheduleButton
                    pageName="NeedToScheduleInitial"
                    text="Schedule Today"
                    onClick={() => navigate('/appointment')}
                    styledWidth={200}
                  />
                </Column>
                <Column center style={{ position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <StyledImage src={AppointmentsImage} alt="Man writing on calendar" height={'100%'} width={'100%'} style={{ maxHeight: 500 }} />
                </Column>
              </ResponsiveContainer>
            </Card>
          </StyledCardContainer>
        )

      case IntakeStatus.WaitingOnInitial:
        return (
          <StyledCardContainer styles={{ width: '91%' }}>
            <Card width="100%" height="fit-content">
              <ResponsiveContainer styles={{ padding: 25, height: '100%' }}>
                <Column center>
                  <ScaledText size={30} styles={{ marginBottom: 25 }}>
                    Your patient portal will become unlocked after you complete your first consultation with a Tria Health Pharmacist.
                  </ScaledText>
                  <div
                    style={
                      appointment?.scheduledAppointment
                        ? {
                            border: '2px solid transparent',
                            borderRadius: 4,
                            backgroundColor: '#edfdff',
                            boxShadow: 'inset 0px 1px 3px',
                            minHeight: 40,
                            fontSize: 20,
                            width: 'fit-content',
                            marginBottom: '25px',
                            padding: '10px 10px',
                          }
                        : { display: 'none' }
                    }
                  >
                    <text>{appointment?.scheduledAppointment}</text>
                  </div>
                  <div style={{ fontWeight: 'bold', marginTop: '5%', marginBottom: '3%' }}>
                    <text style={{ fontWeight: 'bold', fontSize: 20 }}>Need to make a Change?</text>
                    <br></br>
                    <Button pageName="appointment" text="Change/Reschedule" onClick={() => navigate('/appointment?reschedule=true')}></Button>
                  </div>
                </Column>
                <Column center style={{ position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <StyledImage src={ConsultationImage} alt="Man writing on calendar" />
                </Column>
              </ResponsiveContainer>
            </Card>
          </StyledCardContainer>
        )
    }
  }

  return (
    <StyledPageContainer styles={{ minHeight: '100vh' }}>
      {!enrollmentStatusPastPrivacy.includes(enrollmentStatus) && <ConsentModal modalOpen={modalOpen} toggleModal={(bool) => setModalOpen(bool)} />}
      {!loading && profile?.intakeStatus !== undefined && getProfileContent()}
    </StyledPageContainer>
  )
}

const StyledIcon = styled(Icon)`
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  @media ${sizes.tablet} {
    display: block;
  }
`

const StyledColumn = styled(Column)`
  width: 100%;
  @media ${sizes.tablet} {
    width: 500%;
  }
`

const StyledPageContainer = styled(Row)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
`
const StyledImage = styled.img`
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  object-fit: contain;
`

const StyledCardImage = styled.img`
  display: none !important;
  @media ${sizes.tablet} {
    display: flex !important;
  }
`

const StyledScheduleButton = styled(Button)<{ styledWidth: number }>`
  width: 100%;
  @media ${sizes.tablet} {
    width: ${({ styledWidth }) => `${styledWidth}px`};
  }
  align-items: center;
`

const StyledMultifactorButton = styled(Button)`
  width: 60%;
  margin: 0px auto;
  font-size: 32;
`

const StyledCardContainer = styled(ResponsiveContainer)`
  justify-content: space-evenly;
  margin-top: -40px;
  margin-left: 15px;
  margin-right: 15px;
  @media ${sizes.tablet} {
    margin-left: 50px;
    margin-right: 50px;
  }
`

const StyledCardHeader = styled(ResponsiveContainer)`
  background-color: #9f2842;
  position: absolute;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px 20px;
  top: 0;
  left: 0;
  color: #ffffff;
`

const StyledProgramContainer = styled(ResponsiveContainer)`
  margin-left: 10px;
  margin-right: 10px;
  @media ${sizes.tablet} {
    margin-left: 50px;
    margin-right: 50px;
  }
`

const StyledCardWrapper = styled.div`
  width: 100%;
  @media ${sizes.tablet} {
    width: 40%;
  }
`
