import { CustomTheme, createStyles, makeStyles } from '@material-ui/core'
import Icon from 'Components/Base/Icon'
import Typography from 'Components/Base/Typography'
import React from 'react'
import Tooltip from 'react-tooltip'
import './GridCard.scss'

interface Props {
  title: string
  subtitle: string
  color: string
  bannerTitle?: string
  bannerTitleColor?: string
  title2?: string
  styles?: React.CSSProperties
  className?: string
  bottomLabel?: string
  linkAction?: () => void
}
export default function GoalCard({
  title,
  subtitle,
  color,
  bannerTitle,
  bannerTitleColor,
  title2,
  styles,
  className,
  bottomLabel,
  linkAction,
}: Props) {
  const classStyles = useStyles()

  return (
    <div className={`${className} grid-card-container`} style={{ ...styles }}>
      <div className={classStyles.cardColumn}>
        <span className={classStyles.title}>{title}</span>
        <div style={{ fontSize: 15, fontFamily: 'Raleway' }}>{subtitle}</div>
        {title2 && <text style={{ fontSize: 15, fontFamily: 'Raleway' }}>{title2}</text>}
      </div>
      {bannerTitle && (
        <div
          style={{
            backgroundColor: color,
            position: 'absolute',
            bottom: 0,
            height: 25,
            width: '100%',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography color={bannerTitleColor}>{bannerTitle}</Typography>
        </div>
      )}
      {bottomLabel && (
        <div
          style={{
            backgroundColor: color,
            position: 'absolute',
            bottom: 0,
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>{bottomLabel}</Typography>
        </div>
      )}
      {linkAction && (
        <div onClick={linkAction} className={classStyles.searchIcon} data-tip={`See ${bannerTitle} readings`}>
          <Icon name="search" color="white" size="1x" />
        </div>
      )}
      <Tooltip className={classStyles.tooltip} />
    </div>
  )
}

const useStyles = makeStyles<CustomTheme>((theme) =>
  createStyles({
    title: {
      fontSize: '4vw',
      lineHeight: 1,
      fontFamily: 'Raleway-Semi-Bold',
      [theme.breakpoints.down(900)]: {
        fontSize: '4vw',
      },
      [theme.breakpoints.down(469)]: {
        fontSize: '10vw',
      },
    },
    searchIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: 10,
      right: 10,
      backgroundColor: 'black',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 28,
      height: 28,
      [theme.breakpoints.down(900)]: {
        top: 5,
        right: 5,
      },
    },
    tooltip: { backgroundColor: 'blue' },
    cardColumn: {
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(469)]: {
        top: '38%',
      },
    },
  }),
)
