
import React, { useEffect, useState } from 'react'
import { Paragraph, SectionInfo } from 'types/Challenge'
import Typography from 'Components/Base/Typography'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'
import data from 'tria-ui-react-lib/challenges/stepInfo.json'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'

interface Props {
  step: number
  setInfoPage: (infoPage: boolean) => void
}
export default function FatStep5({ step, setInfoPage }: Props) {
  const [sectionInfo, setSectionInfo] = useState<SectionInfo>()
  const [stepParagraphs, setStepParagraphs] = useState<Paragraph[]>()
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {

    window.scrollTo(0, 0)
    setInfoPage(true)
    setSectionInfo(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2])))
    setStepParagraphs(data.pageInfoBySection.sectionInfo.find((sectionInfo) => sectionInfo.sectionId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]))?.paragraphs)
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [step])


  return (
    <ResponsiveContainer>
      <Column styles={{ margin: isMobile ? '20px auto' : 'auto 0px' }}>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography variant='h1'>{sectionInfo?.titles.find((title) => title.titleId === 14)?.titleText ?? ''}</Typography>
        </Row>
        <Row styles={{ margin: isMobile ? '20px auto' : '20px 0px' }}>
          <Typography size={20}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 23)?.paragraph ?? ''}</Typography>
        </Row>
        <Column styles={{ margin: isMobile ? '20px auto' : '20px 20px' }}>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 25)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 26)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 27)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 28)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 29)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 30)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 31)?.paragraph ?? ''}</li>
          <li style={{ fontFamily: 'Raleway', marginBottom: 10, fontSize: 20 }}>{stepParagraphs?.find((paragraph) => paragraph.paragraphId === 32)?.paragraph ?? ''}</li>
        </Column>
      </Column>
      <Column styles={{ width: '100%', marginTop: isMobile ? '0px' : 'auto', marginBottom: isMobile ? '0px' : 'auto' }}>
        <img src={sectionInfo?.images.find((image) => image.imageId === 5)?.url} alt='scale' style={{ width: isMobile ? '100%' : '454px', marginLeft: 'auto' }} />
      </Column>
    </ResponsiveContainer>
  )
}

