import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import ScaledText from 'Pages/STOPProgram/components/ScaledText'
import { sizes } from 'Styles/sizes'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { STOPActivity } from 'types/STOP'

type Props = {
  activityId: STOPActivity
}

export default function PDFContainer({ activityId }: Props) {
  let linkRow

  switch (activityId) {
    case STOPActivity.HealthBenefits:
      linkRow = (
        <PDFRow
          activityId={STOPActivity.HealthBenefits}
          link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf"
        />
      )
      break
    case STOPActivity.OvercomingObstacles:
      linkRow = (
        <PDFRow
          activityId={STOPActivity.OvercomingObstacles}
          link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%202_Overcoming%20the%20Obstacles.pdf"
        />
      )

      break
    case STOPActivity.MedicationOptions:
      linkRow = (
        <>
          <PDFRow
            activityId={STOPActivity.HealthBenefits}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf"
          />
          <PDFRow
            activityId={STOPActivity.OvercomingObstacles}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%202_Overcoming%20the%20Obstacles.pdf"
          />
          <PDFRow
            activityId={STOPActivity.MedicationOptions}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%203_Medication%20Options%20&%20Benefits.pdf"
          />
        </>
      )

      break
    default:
      linkRow = (
        <Row styles={{ justifyContent: 'space-evenly' }}>
          <PDFRow
            activityId={STOPActivity.HealthBenefits}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf"
          />
        </Row>
      )
  }

  return (
    <StyledPDFContainer styles={{ justifyContent: 'space-between' }}>
      {activityId === STOPActivity.MedicationOptions ? (
        <Row center>
          <ScaledText bold>Activity Library:</ScaledText>
        </Row>
      ) : (
        <Spacer bottom>
          <ScaledText bold>Download a PDF of Today’s Activity</ScaledText>
          <ScaledText>You can also find a copy within your portal care plan</ScaledText>
        </Spacer>
      )}
      {linkRow}
      <ScaledText size={15}>Includes all citations from within the activity</ScaledText>
    </StyledPDFContainer>
  )
}

export function PDFActivitiesRow() {
  return (
    <Column styles={{ width: '100%' }}>
      <ResponsiveContainer styles={{ width: '100%', justifyContent: 'space-between' }}>
        <StyledPDFRow>
          <Row center>
            <ScaledText styles={{ fontWeight: 'bold' }} bold size={20}>
              Activity #1
            </ScaledText>
          </Row>
          <PDFRow
            hideTitle
            activityId={STOPActivity.HealthBenefits}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf"
          />
        </StyledPDFRow>
        <StyledPDFRow>
          <Row center>
            <ScaledText styles={{ fontWeight: 'bold' }} bold size={20}>
              Activity #2
            </ScaledText>
          </Row>
          <PDFRow
            hideTitle
            activityId={STOPActivity.HealthBenefits}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%202_Overcoming%20the%20Obstacles.pdf"
          />
        </StyledPDFRow>
        <StyledPDFRow>
          <Row center>
            <ScaledText styles={{ fontWeight: 'bold' }} bold size={20}>
              Activity #3
            </ScaledText>
          </Row>
          <PDFRow
            hideTitle
            activityId={STOPActivity.HealthBenefits}
            link="https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%203_Medication%20Options%20&%20Benefits.pdf"
          />
        </StyledPDFRow>
      </ResponsiveContainer>
      <Spacer bottom>
        <ScaledText size={15} styles={{ marginBottom: 10 }}>
          You can also find a copies within your portal care plan
        </ScaledText>
        <ScaledText size={15}>Includes all citations from within the activity</ScaledText>
      </Spacer>
    </Column>
  )
}

export function PDFLibrary() {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <ResponsiveContainer styles={{ width: '100%', justifyContent: 'space-between' }}>
      <Column>
        <ScaledText size={35} bold>
          Are You Ready to Quit Tobacco?
        </ScaledText>
        <StyledSubtitle>
          <ScaledText size={25}>Talk to a Tria Health pharmacist today and get started on your customized quit plan.</ScaledText>
        </StyledSubtitle>
        <StyledRow>
          <Button
            text="Get Scheduled!"
            styles={{ backgroundColor: theme.colors.charts.inRange }}
            onClick={() => navigate('/messaging/inbox?newMessage=true')}
          />
        </StyledRow>
      </Column>
      <StyledPDFContainer styles={{ justifyContent: 'space-between' }}>
        <Row center>
          <ScaledText bold>Activity Library:</ScaledText>
        </Row>
        <Spacer bottom>
          <Row>
            <ScaledText styles={{ textDecoration: 'underline', marginRight: 25, fontWeight: 'bold' }}>Activity #1:</ScaledText>
            <a
              href={'https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf'}
              target="_blank"
              rel="noreferrer"
            >
              <Row styles={{ alignItems: 'center', cursor: 'pointer', marginBottom: 15, marginRight: 25 }}>
                <Icon name="file-pdf" styles={{ marginRight: 10 }} /> <ScaledText>English</ScaledText>
              </Row>
            </a>
          </Row>
          <Row>
            <ScaledText styles={{ textDecoration: 'underline', marginRight: 25, fontWeight: 'bold' }}>Activity #2:</ScaledText>
            <a
              href={'https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf'}
              target="_blank"
              rel="noreferrer"
            >
              <Row styles={{ alignItems: 'center', cursor: 'pointer', marginBottom: 15, marginRight: 25 }}>
                <Icon name="file-pdf" styles={{ marginRight: 10 }} /> <ScaledText>English</ScaledText>
              </Row>
            </a>
          </Row>
          <Row>
            <ScaledText styles={{ textDecoration: 'underline', marginRight: 25, fontWeight: 'bold' }}>Activity #3:</ScaledText>
            <a
              href={'https://triacentral.blob.core.windows.net/dev-storage/STOP/STOP_Activity%201_Health%20Benefits%20of%20Quitting.pdf'}
              target="_blank"
              rel="noreferrer"
            >
              <Row styles={{ alignItems: 'center', cursor: 'pointer', marginBottom: 15, marginRight: 25 }}>
                <Icon name="file-pdf" styles={{ marginRight: 10 }} /> <ScaledText>English</ScaledText>
              </Row>
            </a>
          </Row>
        </Spacer>
      </StyledPDFContainer>
    </ResponsiveContainer>
  )
}

function PDFRow({ link, activityId, hideTitle = false }: { link: string; activityId: STOPActivity; hideTitle?: boolean }) {
  return (
    <ResponsiveContainer styles={{ justifyContent: 'space-evenly' }}>
      {!hideTitle && <ScaledText styles={{ textDecoration: 'underline', fontWeight: 'bold' }}>{`Activity #${activityId + 1}`}</ScaledText>}
      <Column>
        <a href={link} target="_blank" rel="noreferrer">
          <Row styles={{ alignItems: 'center', cursor: 'pointer', marginBottom: 15 }}>
            <Icon name="file-pdf" styles={{ marginRight: 10 }} /> <ScaledText>English</ScaledText>
          </Row>
        </a>
      </Column>
    </ResponsiveContainer>
  )
}

const StyledColumn = styled(Column)`
  width: 100%;
  @media (${sizes.tablet}) {
    width: 45%;
  }
`

const StyledRow = styled(Row)`
  margin-top: 10px;
  margin-bottom: 25px;
  justify-content: center;
  @media (${sizes.tablet}) {
    justify-content: flex-start;
  }
`

const StyledSubtitle = styled.div`
  width: 100%;
  @media (${sizes.tablet}) {
    width: 55%;
  }
`

const StyledPDFContainer = styled(StyledColumn)`
  border: 1px solid black;
  padding: 15px;
  margin-bottom: 25px;
  width: 100%;
  @media (${sizes.tablet}) {
    width: 45%;
  }
`

const StyledPDFRow = styled(StyledPDFContainer)`
  justify-content: space-between;
  width: 100%;
  @media (${sizes.tablet}) {
    width: 30%;
  }
`
