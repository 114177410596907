import seq from 'seq-logging'

interface LogProps {
  url: string
  error: string
  method?: string
}
export const useLogger = () => {
  const env = process.env.REACT_APP_ENV_NAME

  const logger = new seq.Logger({
    serverUrl: process.env.REACT_APP_LOGGING_URL,
    onError: (err) => console.log(err),
  })

  const logError = ({ url, error, method }: LogProps) => {
    logger.emit({
      timestamp: new Date(),
      level: 'Error',
      messageTemplate: `${method ? method.toUpperCase() : ''} ${url}`,
      properties: {
        ApplicationName: 'Patient Portal',
        Url: `${url}`,
        Error: error,
        EnvironmentName: env,
      },
    })
    logger.close()
  }

  const logWarning = ({ url, error, method }: LogProps) => {
    logger.emit({
      timestamp: new Date(),
      level: 'Warning',
      messageTemplate: `${method ? method.toUpperCase() : ''} ${url}`,
      properties: {
        ApplicationName: 'Patient Portal',
        Url: `${url}`,
        Error: error,
        EnvironmentName: env,
      },
    })
    logger.close()
  }

  return { logError, logWarning }
}
