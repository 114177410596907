import qs from 'querystring'
import { AUASIFormValues, GAD7FormValues, HBIFormValues, PHQ9FormValues, Rapid3FormValues, SCCAIFormValues } from 'types/Forms'
import { useApi } from './useAPI'

export default function usePatientAssessment() {
  const TriaApi = useApi()

  const getSCCAIScore = async (values: SCCAIFormValues, callback?: Function) => {
    const data = qs.stringify({
      DayBM: values.dayBM === '' || !values.dayBM ? 0 : values.dayBM,
      NightBM: values.nightBM === '' || !values.nightBM ? 0 : values.nightBM,
      Urgency: values.urgency === '' || !values.urgency ? 0 : values.urgency,
      AmtBlood: values.amtBlood === '' || !values.amtBlood ? 0 : values.amtBlood,
      Wellbeing: values.wellbeing === '' || !values.wellbeing ? 0 : values.wellbeing,
      JointProblems: values.jointProblems,
      EyeProblems: values.eyeProblems,
      ErythemaNodosum: values.erythemaNodosum,
      PyodermaGrangrenosum: values.pyodermaGrangrenosum,
      GUID: values.guid,
    })

    const score = TriaApi.post(`api/assessment/sccai`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return score
  }

  const getAUASIScore = async (values: AUASIFormValues, callback?: Function) => {
    const data = qs.stringify({
      NotEmpty: values.notEmpty === '' || !values.notEmpty ? 0 : values.notEmpty,
      Less2Hours: values.less2Hours === '' || !values.less2Hours ? 0 : values.less2Hours,
      StopStart: values.stopStart === '' || !values.stopStart ? 0 : values.stopStart,
      Postpone: values.postpone === '' || !values.postpone ? 0 : values.postpone,
      WeakStream: values.weakStream === '' || !values.weakStream ? 0 : values.weakStream,
      Strain: values.strain === '' || !values.strain ? 0 : values.strain,
      NightTimes: values.nightTimes === '' || !values.nightTimes ? 0 : values.nightTimes,
      Guid: values.guid,
    })

    const score = TriaApi.post(`api/assessment/auasi`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return score
  }

  const getGAD7Score = async (values: GAD7FormValues, callback?: Function) => {
    const data = qs.stringify({
      FeelNervous: values.feelNervous === '' || !values.feelNervous ? 0 : values.feelNervous,
      ControlWorry: values.controlWorry === '' || !values.controlWorry ? 0 : values.controlWorry,
      WorryTooMuch: values.worryTooMuch === '' || !values.worryTooMuch ? 0 : values.worryTooMuch,
      TroubleRelaxing: values.troubleRelaxing === '' || !values.troubleRelaxing ? 0 : values.troubleRelaxing,
      Restless: values.restless === '' || !values.restless ? 0 : values.restless,
      EasilyAnnoyed: values.easilyAnnoyed === '' || !values.easilyAnnoyed ? 0 : values.easilyAnnoyed,
      FeelAfraid: values.feelAfraid === '' || !values.feelAfraid ? 0 : values.feelAfraid,
      Difficulty: values.difficulty === '' || !values.difficulty ? 0 : values.difficulty,
      Guid: values.guid,
    })

    const score = TriaApi.post(`api/assessment/gad7`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return score
  }

  const getPHQ9Score = async (values: PHQ9FormValues, callback?: Function) => {
    const data = qs.stringify({
      LittleInterest: values.littleInterest === '' || !values.littleInterest ? 0 : values.littleInterest,
      FeelingDown: values.feelingDown === '' || !values.feelingDown ? 0 : values.feelingDown,
      Sleep: values.sleep === '' || !values.sleep ? 0 : values.sleep,
      Energy: values.energy === '' || !values.energy ? 0 : values.energy,
      Appetite: values.appetite === '' || !values.appetite ? 0 : values.appetite,
      Failure: values.failure,
      Concentration: values.concentration,
      MovingSpeaking: values.movingSpeaking,
      SelfHarm: values.selfHarm,
      Guid: values.guid,
    })

    const score = TriaApi.post(`api/assessment/phq9`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return score
  }

  const getHBIScore = async (values: HBIFormValues, callback?: Function) => {
    const data = qs.stringify({
      Wellbeing: values.wellbeing === '' || !values.wellbeing ? 0 : values.wellbeing,
      AbdominalPain: values.abdominalPain === '' || !values.abdominalPain ? 0 : values.abdominalPain,
      LiquidBM: values.liquidBM,
      AbdominalMass: values.abdominalMass === '' || !values.abdominalMass ? 0 : values.abdominalMass,
      Arthralgia: values.arthralgia,
      Uveitis: values.uveitis,
      ErythemaNodosum: values.erythemaNodosum,
      AphthousUlcer: values.aphthousUlcer,
      PyodermaGangrenosum: values.pyodermaGangrenosum,
      AnalFissure: values.analFissure,
      NewFistula: values.newFistula,
      Abscess: values.abscess,
      Guid: values.guid,
    })

    const score = TriaApi.post(`api/assessment/hbi`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return score
  }

  const getRapid3Score = async (values: Rapid3FormValues, callback?: Function) => {
    const data = qs.stringify({
      DressYourself: values.dressYourself === '' || !values.dressYourself ? 0 : values.dressYourself,
      InOutBed: values.inOutBed === '' || !values.inOutBed ? 0 : values.inOutBed,
      LiftCup: values.liftCup === '' || !values.liftCup ? 0 : values.liftCup,
      Walk: values.walk === '' || !values.walk ? 0 : values.walk,
      WashDry: values.washDry === '' || !values.washDry ? 0 : values.washDry,
      BendDown: values.bendDown === '' || !values.bendDown ? 0 : values.bendDown,
      Faucet: values.faucet === '' || !values.faucet ? 0 : values.faucet,
      InOutCar: values.inOutCar === '' || !values.inOutCar ? 0 : values.inOutCar,
      WalkTwoMiles: values.walkTwoMiles === '' || !values.walkTwoMiles ? 0 : values.walkTwoMiles,
      Sports: values.sports === '' || !values.sports ? 0 : values.sports,
      Pain: values.pain,
      GlobalActivity: values.globalActivity,
      Guid: values.guid,
    })

    const score = TriaApi.post(`api/assessment/rapid3`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return score
  }

  return {
    getSCCAIScore,
    getAUASIScore,
    getGAD7Score,
    getPHQ9Score,
    getHBIScore,
    getRapid3Score,
  }
}
