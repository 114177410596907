import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Spacer from 'Components/Base/Spacer'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import styled from 'styled-components'
import ScaledText from '../../ScaledText'
import { TobaccoProduct } from '../tobaccoProducts'

type Props = {
  title: string
  products: TobaccoProduct[]
  onSetProduct: (product: TobaccoProduct) => void
  styles?: React.CSSProperties
}
export default function ProductColumn({ title, products, onSetProduct, styles }: Props) {
  const [expandedSection, setExpandedSection] = useState<number | undefined>()
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const toggleSection = (sectionId: number) => {
    analytics.postAnalyticsEvent({
      category: `click_button_tobacco_accordion_${sectionId + 1}`,
      action: `click_button_tobacco_accordion_${sectionId + 1}`,
      label: `Button`,
      screenName: 'StopProgram',
    })
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }

  return (
    <StyledColumn styles={{ ...styles, alignItems: 'center' }}>
      <StyledTitle>{title}</StyledTitle>
      <StyledProductDiv>
        {products.map((product) => (
          <StyledProduct productLength={products.length} onClick={() => onSetProduct(product)}>
            <StyledImage src={product.image} alt="" />
            <ScaledText>{product.name}</ScaledText>
          </StyledProduct>
        ))}
      </StyledProductDiv>
      <StyledAccordion>
        {products.map((product, index) => (
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={`${index}`} onClick={() => toggleSection(index)} style={{ alignItems: 'center' }}>
              <Row styles={{ alignItems: 'center', fontWeight: 'bold' }}>{product.name}</Row>
              <Icon name={expandedSection === index ? 'chevron-up' : 'chevron-down'} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${index}`}>
              <Card.Body>
                <Spacer bottom>
                  <Row>
                    <img src={product.image} alt="" style={{ width: '100%', border: '1px solid black' }} />
                  </Row>
                </Spacer>
                {product.description}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </StyledAccordion>
    </StyledColumn>
  )
}

const StyledTitle = styled(Typography)`
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: bold;
  @media (${sizes.tablet}) {
    margin-bottom: 25px;
  }
`

const StyledAccordion = styled(Accordion)`
  margin-top: 15px;
  border-radius: 10px;
  z-index: 50px;
  position: relative;
  width: 100%;
  @media (${sizes.tablet}) {
    display: none;
  }
`

const StyledColumn = styled(Column)`
  flex-wrap: wrap;
`

const StyledProductDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: none;
  @media (${sizes.tablet}) {
    display: flex;
  }
`
const StyledProduct = styled(Column)<{ productLength: number }>`
  align-items: center;
  margin-right: ${({ productLength }) => `${productLength > 3 ? 50 : 0}px`};
  margin-bottom: 15px;
  cursor: pointer;
`

const StyledImage = styled.img`
  height: 200px;
  object-fit: cover;
  border: 1px solid;
`
