import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import ContentHeader from 'Pages/PatientProfile/components/ContentHeader'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useMessagingCenter from 'hooks/useMessagingCenter'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { getProfile } from 'reducers/profileSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { Category, HelpText, MessageType, PatientMessage } from 'types/MessagingCenter'
import CurrentMessage from './CurrentMessage'
import NewMessage from './NewMessage'
import ScheduleAFollowUp from './ScheduleAFollowUp'

interface Props {
  isMobile: boolean
  sideMenuToggled: () => void
  patientMessages: PatientMessage[]
  setPatientMessages: (messages: PatientMessage[]) => void
  messageCategories: Category[]
  setMessageCategories: (cat: Category[]) => void
  newMessageBool: boolean
  setNewMessageBool: (boolean) => void
  currentTypes: MessageType[]
  setCurrentTypes: (types: MessageType[]) => void

  currentCategory: Category | undefined
  setCurrentCategory: (cat: Category | undefined) => void
  currentHelpText: HelpText[]
  setCurrentHelpText: (helpText: HelpText[]) => void
  currentType: MessageType | undefined
  setCurrentType: (type: MessageType) => void
  typeValue: number
  setTypeValue: (type: number) => void
  categoryValue: number | undefined
  setCategoryValue: (type: number) => void
  setReplyToTriaMessageId: (number: number | undefined) => void
  replyToTriaMessageId: number | undefined
}

export default function MessagingInboxScreen({
  isMobile,
  sideMenuToggled,
  patientMessages,
  messageCategories,
  newMessageBool,
  setNewMessageBool,
  currentTypes,
  setCurrentTypes,
  currentCategory,
  setCurrentCategory,
  currentHelpText,
  setCurrentHelpText,
  setMessageCategories,
  setPatientMessages,
  currentType,
  setCurrentType,
  typeValue,
  setTypeValue,
  categoryValue,
  setCategoryValue,
  replyToTriaMessageId,
  setReplyToTriaMessageId,
}: Props) {
  const [currentMessage, setCurrentMessage] = useState<PatientMessage>(
    patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[0],
  )
  const [nextMessage, setNextMessage] = useState<PatientMessage>(
    patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[1],
  )
  const [filteredMessages, setFilteredMessages] = useState<PatientMessage[]>(
    patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null),
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const [engagedBool, setEngagedBool] = useState<boolean>(true)
  const [afterSend, setAfterSend] = useState<boolean>(false)
  const [replyBool, setReplyBool] = useState<boolean>(false)
  const [refreshMessagesBool, setRefreshMessagesBool] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [attachmentDropOpen, setAttachmentDropOpen] = useState(true)
  const [postErrorBool, setPostErrorBool] = useState<boolean>(false)

  const [defaultCategory, setDefaultCategory] = useState<number>()
  const [defaultType, setDefaultType] = useState<number>()

  const navigate = useNavigate()
  const profile = useAppSelector(getProfile)

  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Messaging Inbox')

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const { viewPatientMessage, archivePatientMessage, getPatientMessages, getPatientMessagingCategories } = useMessagingCenter(accessToken!)
  const theme = useTheme()

  const token = useAppSelector(getAccessToken)
  const analytics = useAnalytics(token!)

  useEffect(() => {
    dispatch(setLoading(true))
    getPatientMessagingCategories().then((res) => {
      setMessageCategories(res.data.categories)
    })
  }, [])

  useEffect(() => {
    if (searchParams.get('newMessage') === 'true') {
      setDefaultCategory(4)
      setDefaultType(0)
      handleNewMessageClick()
    }
  }, [])

  useEffect(() => {
    dispatch(setLoading(true))
    if (profile?.enrollmentStatus === 'IN') {
      setEngagedBool(false)
    }
    getPatientMessages().then((res) => {
      setPatientMessages(res.data.patientInitiatedMessages)
      setCurrentMessage(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[0])
      setNextMessage(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[1])
      setFilteredMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null))
      dispatch(setLoading(false))
    })
  }, [])

  const selectMessage = (message: PatientMessage) => {
    setAfterSend(false)
    setReplyBool(false)
    setNewMessageBool(false)
    setCurrentMessage(message)
    setPostErrorBool(false)
    if (currentMessage.hasNewMessage) {
      currentMessage.conversations.map((history) =>
        viewPatientMessage(history.patientInitiatedMessageHistoryPId)
          .then(() => {
            getPatientMessages().then((res) => {
              refreshMessagesUponView(res)
            })
          })
          .finally(() => scrollDown()),
      )
    }
    if (message.hasNewMessage) {
      message.conversations.map((history) =>
        viewPatientMessage(history.patientInitiatedMessageHistoryPId)
          .then(() => {
            getPatientMessages().then((res) => {
              refreshMessagesUponView(res)
            })
          })
          .finally(() => scrollDown()),
      )
    }
    navigate('')
  }
  const refreshMessagesUponView = (res) => {
    setPatientMessages(res.data.patientInitiatedMessages)
    if (searchValue === '') {
      setFilteredMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null))
    } else {
      setFilteredMessages(
        res.data.patientInitiatedMessages.filter(
          (m) =>
            messageCategories
              .find((cat) => cat.patientInitiatedMessageCategoryPId === m.patientInitiatedMessageCategoryPId)
              ?.categoryName.toLowerCase()
              .includes(searchValue.toLowerCase()) && m.patientArchivedDate === null,
        ),
      )
    }
  }

  const filterMessage = (search) => {
    setSearchValue(search)
    if (search === '') {
      setFilteredMessages(patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null))
    } else {
      setFilteredMessages(
        patientMessages.filter(
          (m) =>
            (messageCategories
              .find((cat) => cat.patientInitiatedMessageCategoryPId === m.patientInitiatedMessageCategoryPId)
              ?.categoryName.toLowerCase()
              .includes(search.toLowerCase()) ||
              m.conversations.find((message) => message.body.toLowerCase().includes(search.toLowerCase()))) &&
            m.patientArchivedDate === null &&
            m.triaClosedDate === null,
        ),
      )
    }
  }

  const handleNewMessageClick = () => {
    setReplyToTriaMessageId(undefined)
    setNewMessageBool(!newMessageBool)
    setPostErrorBool(false)
    if (!newMessageBool) {
      analytics.postAnalyticsPageView(`/messaging/inbox/newMessage`)
    }
    setSearchValue('')
    setFilteredMessages(patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null))
    setCategoryValue(-1)
    setCurrentHelpText([])
    setReplyBool(false)
  }

  const refreshMessagesUponSend = (archiveReplyOrSend) => {
    setPostErrorBool(false)
    dispatch(setLoading(true))
    getPatientMessages()
      .then((res) => {
        setPatientMessages(res.data.patientInitiatedMessages)
        setFilteredMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null))
        setNextMessage(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[1])

        if (archiveReplyOrSend === 1) {
          setCurrentMessage(
            res.data.patientInitiatedMessages.find((message) => message.patientInitiatedMessagePId === currentMessage.patientInitiatedMessagePId),
          )
        } else if (archiveReplyOrSend === 2) {
          setCurrentMessage(res.data.patientInitiatedMessages[0])
        }
      })
      .then(() => {
        dispatch(setLoading(false))
        setRefreshMessagesBool(!refreshMessagesBool)
      })
  }

  const handleArchiveClick = (messageId) => {
    archivePatientMessage(messageId)
      .then(() => refreshMessagesUponSend(0))
      .finally(() => {
        setCurrentMessage(nextMessage)
        if (
          filteredMessages.length !==
          filteredMessages.findIndex((message) => message.patientInitiatedMessagePId === currentMessage.patientInitiatedMessagePId) + 1
        ) {
          selectMessage(
            filteredMessages[filteredMessages.findIndex((message) => message.patientInitiatedMessagePId === nextMessage.patientInitiatedMessagePId)],
          )
        } else {
          getPatientMessages()
            .then((res) => {
              setPatientMessages(res.data.patientInitiatedMessages)
              setFilteredMessages(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null))
              setNextMessage(res.data.patientInitiatedMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[1])
            })
            .then(() => {
              setCurrentMessage(patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[0])
              selectMessage(patientMessages.filter((m) => m.patientArchivedDate === null && m.triaClosedDate === null)[0])
              setSearchValue('')
            })
        }
      })
  }

  useCallback(() => {
    scrollDown()
  }, [replyBool])

  const conversation = useRef<null | HTMLDivElement>(null)

  const scrollDown = () => {
    conversation.current && (conversation.current.scrollTop = conversation.current.scrollHeight)
  }

  return (
    <>
      <Row styles={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile && (
          <div onClick={sideMenuToggled} style={{ cursor: 'pointer' }}>
            <Icon name="bars" color="#00677F" styles={{ marginBottom: 20 }} />
          </div>
        )}

        <ContentHeader title="Inbox" icon="envelope" color={theme.colors.primary1} />
        {!isMobile && (
          <Button text="New Message" backgroundColor={theme.colors.charts.inRange} rightIcon="plus" onClick={() => handleNewMessageClick()} />
        )}
        {isMobile && <div></div>}
      </Row>
      <StyledSearchBar>
        <input
          value={searchValue}
          onChange={(e) => filterMessage(e.target.value)}
          placeholder="Search"
          style={{ width: '100%', height: 40, border: '1px solid #BDBDBD' }}
        />
        <Icon name={'search'} color={'grey'} styles={{ marginLeft: -30, marginTop: 10 }} />
      </StyledSearchBar>
      <StyledCardContainer>
        <StyledMessageList>
          <div style={{ marginTop: 10 }}>
            {filteredMessages.length > 0 &&
              currentMessage &&
              filteredMessages.map(
                (row, index) =>
                  row && (
                    <div
                      key={index}
                      style={
                        index ===
                        filteredMessages.findIndex((message) => message.patientInitiatedMessagePId === currentMessage?.patientInitiatedMessagePId)
                          ? {
                              backgroundColor: 'rgba(0, 103, 127, 0.26)',
                              width: '93%',
                              margin: 'auto',
                              padding: '10px',
                            }
                          : index % 2 !== 0
                          ? {
                              backgroundColor: '#ffffff',
                              width: '93%',
                              margin: 'auto',
                              padding: '10px',
                            }
                          : {
                              backgroundColor: '#f2f2f2',
                              width: '93%',
                              margin: 'auto',
                              padding: '10px',
                            }
                      }
                      className="careplan-detail-document-item"
                    >
                      <Row
                        styles={{
                          width: '99%',
                          margin: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => selectMessage(row)}
                      >
                        <Row>
                          <Column>
                            <Row
                              styles={{
                                textAlign: 'left',
                                width: '100%',
                              }}
                            >
                              <Typography variant="h3" size={15}>{`${
                                messageCategories.find((cat) => cat.patientInitiatedMessageCategoryPId === row?.patientInitiatedMessageCategoryPId)
                                  ?.categoryName
                              }`}</Typography>
                            </Row>
                            {/* this is the most recent messages first thirteen characters sliced with ellipses after */}
                            <Row
                              styles={{
                                textAlign: 'left',
                                width: '100%',
                              }}
                            >
                              {row.conversations && (
                                <Typography>{`${row?.conversations[row.conversations.length - 1]?.body.slice(0, 13)}${
                                  row?.conversations[row.conversations.length - 1]?.body.length > 13 ? '...' : ''
                                }`}</Typography>
                              )}
                            </Row>
                          </Column>
                        </Row>
                        <Column styles={{ marginLeft: 'auto', marginTop: 4 }}>
                          {row.hasNewMessage && (
                            <StyledBadge>
                              <div style={{ marginBottom: 2 }}></div>
                            </StyledBadge>
                          )}
                          <Typography styles={{ marginRight: 10 }}>{`${dayjs(row?.conversations[row.conversations.length - 1].messageDate).format(
                            'M/D/YYYY',
                          )}`}</Typography>
                        </Column>
                      </Row>
                    </div>
                  ),
              )}
          </div>
        </StyledMessageList>
      </StyledCardContainer>
      {isMobile && (
        <Row styles={{ justifyContent: 'space-between' }}>
          <div></div>
          <Button
            text="New Message"
            backgroundColor={theme.colors.charts.inRange}
            rightIcon="plus"
            onClick={() => handleNewMessageClick()}
            styles={{ marginTop: 25 }}
          />
        </Row>
      )}
      {!newMessageBool && !afterSend && patientMessages.length > 0 ? (
        <CurrentMessage
          isMobile={isMobile}
          currentMessage={currentMessage}
          messageCategories={messageCategories}
          refreshMessagesUponSend={(num: number) => refreshMessagesUponSend(num)}
          handleArchiveClick={(PIMessageId: number) => handleArchiveClick(PIMessageId)}
          replyBool={replyBool}
          setReplyBool={(bool: boolean) => setReplyBool(bool)}
          attachmentDropOpen={attachmentDropOpen}
          setAttachmentDropOpen={(bool: boolean) => setAttachmentDropOpen(bool)}
          postErrorBool={postErrorBool}
          setPostErrorBool={(bool: boolean) => setPostErrorBool(bool)}
        />
      ) : (
        !newMessageBool &&
        !afterSend && (
          <div
            style={
              isMobile
                ? { marginLeft: 'auto', marginRight: 'auto', width: '95%', marginTop: 0, textAlign: 'center' }
                : { marginLeft: 'auto', marginRight: '8%', width: '45%', marginTop: -400, textAlign: 'center' }
            }
          >
            <Typography font="bold" styles={{ marginTop: 20, marginBottom: 20 }}>
              Hello! If you have any questions or concerns about your medications or overall health, you can send a message to Tria Health right here
              on our portal.
            </Typography>
            <Typography font="bold" styles={{ marginTop: 20, marginBottom: 20 }}>
              Our team of healthcare experts is always happy to help and provide personalized guidance to help you achieve your health goals. Don't
              hesitate to reach out to us anytime, we're here to support you!
            </Typography>
          </div>
        )
      )}
      {!engagedBool && afterSend && <ScheduleAFollowUp />}
      {newMessageBool && !afterSend && (
        <NewMessage
          isMobile={isMobile}
          messageCategories={messageCategories}
          currentType={currentType}
          currentTypes={currentTypes}
          defaultCategory={defaultCategory}
          defaultType={defaultType}
          setCurrentTypes={(types: MessageType[]) => setCurrentTypes(types)}
          currentCategory={currentCategory}
          setCurrentCategory={(category: Category | undefined) => setCurrentCategory(category)}
          currentHelpText={currentHelpText}
          setCurrentHelpText={(helpText: HelpText[]) => setCurrentHelpText(helpText)}
          typeValue={typeValue}
          setTypeValue={(value: number) => setTypeValue(value)}
          categoryValue={categoryValue}
          setCategoryValue={(value: number) => setCategoryValue(value)}
          setCurrentType={(type: MessageType) => setCurrentType(type)}
          setNewMessageBool={(bool: boolean) => setNewMessageBool(bool)}
          refreshMessagesUponSend={(num: number) => refreshMessagesUponSend(num)}
          newMessageBool={newMessageBool}
          setAfterSend={(bool: boolean) => setAfterSend(bool)}
          engagedBool={engagedBool}
          enrollmentStatus={profile?.enrollmentStatus}
          postErrorBool={postErrorBool}
          setPostErrorBool={(bool: boolean) => setPostErrorBool(bool)}
          replyToTriaMessageId={replyToTriaMessageId}
        />
      )}
    </>
  )
}
const StyledCardContainer = styled.div`
  @media only screen and (min-width: 1000px) {
    display: flex;
    flex-direction: row;
  }
`
const StyledSearchBar = styled.div`
  position: relative;
  display: flex;
  @media only screen and (min-width: 1000px) {
    height: 40px;
    width: 34%;
  }

  @media only screen and (max-width: 1000px) {
    height: 40px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`
const StyledMessageList = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  overflow-y: scroll;
  @media only screen and (min-width: 1000px) {
    height: 480px;
    width: 34%;
  }

  @media only screen and (max-width: 1000px) {
    height: 270px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
  }
`

const StyledBadge = styled.div`
  height: 10px;
  min-width: 10px;
  width: fit-content;
  margin-left: auto;
  border-radius: 10px;
  background-color: #e40046;
  display: flex;
  align-items: center;
  justify-content: center;
`
