import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import { StyledPageContainer } from 'Styles/global'
import React from 'react'
import { PhoneNumber, PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import HelpImage from '../../Assets/Images/Portal_Question.svg'
import BackRow from '../../Components/Base/BackRow'
import Typography from '../../Components/Base/Typography'
import './NotFound.scss'

type Props = {
  isMobile: boolean
}
export default function NotFound({ isMobile }: Props) {
  return (
    <StyledPageContainer>
      <ResponsiveContainer
        styles={{
          justifyContent: 'flex-end',
        }}
      >
        <BackRow />
        <img src={HelpImage} className="not-found-image" alt="Man scratching head with question mark" />

        <div className="not-found-form" style={{ textAlign: 'center' }}>
          <h1 className="not-found-header-text" style={{ marginBottom: 10 }}>
            Page Not Found
          </h1>
          <div className="not-found-subtitle" style={{ marginBottom: 5 }}>
            <Typography>Can't find what you're looking for?</Typography>
          </div>
          <div className="not-found-subtitle">
            <Typography>
              <>
                {`Please contact the Tria Health Help Desk ${isMobile ? 'by tapping the number below' : ' '} for additional help: `}
                <span onClick={isMobile ? () => window.open(`tel:${PhoneNumber}`) : undefined} style={{ fontWeight: 'bold' }}>
                  {PhoneNumberPeriodDelimited}
                </span>
              </>
            </Typography>
          </div>
          <div className="register-button-container"></div>
        </div>
      </ResponsiveContainer>
    </StyledPageContainer>
  )
}
