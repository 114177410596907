import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Icon from 'Components/Base/Icon'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import React, { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { getAccessToken } from 'reducers/authSlice'
import { resendInvitation } from 'reducers/profileSlice'
import styled, { useTheme } from 'styled-components'
import { Caregiver, CaregiverStatus } from 'types/Caregiver'
import formatPhoneNumber from 'utilities/formatPhoneNumber'

type Props = {
  caregiver: Caregiver
  caregivers: Caregiver[]
  onToggleDeleteModal: (caregiver: Caregiver) => void
  setCaregiverChangeBool
  setCaregivers
  setCaregiverChangesSaved
}
export default function CaregiverDetail({
  caregiver,
  caregivers,
  onToggleDeleteModal,
  setCaregiverChangeBool,
  setCaregivers,
  setCaregiverChangesSaved,
}: Props) {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const token = useAppSelector(getAccessToken)

  const [resending, setResending] = useState(false)
  const [resendMessage, setResendMessage] = useState('')
  const [invitationResent, setInvitationResent] = useState(false)

  const resendInvite = (caregiver: Caregiver) => {
    if (token && caregiver.patientCaregiverPId) {
      setResending(true)

      dispatch(
        resendInvitation({
          patientCaregiverPId: caregiver.patientCaregiverPId,
          token: token,
        }),
      ).then((res: any) => {
        if (res.error) {
          setResendMessage('Error')
          setInvitationResent(true)
          setResending(false)
        } else {
          setResendMessage('Invitation sent!')
          setInvitationResent(true)
          setResending(false)
        }
      })
    }
  }
  const StyledTableCell = withStyles(() => ({
    head: {
      backgroundColor: theme.colors.primary1,
      color: theme.colors.white,
      fontFamily: 'Raleway-Semi-Bold',
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell)

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.action.hover,
    },
  }))(TableRow)

  function handlePermissionGranted(caregiver, index) {
    const y = caregivers.map((x) => {
      if (
        x.patientCaregiverPId === caregiver.patientCaregiverPId &&
        x.caregiverAccessPermissions[index] === caregiver.caregiverAccessPermissions[index]
      ) {
        const temp = Object.assign(JSON.parse(JSON.stringify(x)))
        temp.caregiverAccessPermissions[index].permissionGranted = !temp.caregiverAccessPermissions[index].permissionGranted
        return temp
      } else {
        return x
      }
    })

    setCaregivers(y)
    setCaregiverChangeBool(true)
    setCaregiverChangesSaved(false)
  }

  return (
    <TableContainer component={Paper} style={{ marginBottom: 25 }}>
      <Table className="table" aria-label="customized table" style={{ border: '1px solid black' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>{`${caregiver.name}`}</StyledTableCell>
            <StyledTableCell colSpan={1} align="right">
              <span style={{ cursor: 'pointer' }} onClick={() => onToggleDeleteModal(caregiver)}>
                Remove <Icon name="trash" styles={{ marginRight: 10, marginLeft: 10 }} />
              </span>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell
              colSpan={1}
              align="left"
              style={{
                border: '1px solid black',
                fontFamily: 'Raleway-Semi-Bold',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  marginRight: 5,
                  fontFamily: 'Raleway-Bold',
                }}
              >
                Email:
              </span>
              {caregiver.email}
            </StyledTableCell>
            <StyledTableCell align="left" colSpan={2} style={{ borderBottom: '1px solid black' }}>
              <span style={{ fontWeight: 'bold', marginRight: 5 }}>Mobile:</span>
              {formatPhoneNumber({
                phoneNumber: caregiver.cellPhone,
              })}
            </StyledTableCell>
          </StyledTableRow>
          {caregiver.caregiverStatus !== CaregiverStatus.Accepted ? (
            <StyledTableRow>
              <StyledTableCell
                align="left"
                colSpan={1}
                style={{
                  verticalAlign: 'middle',
                  paddingTop: 0,
                  paddingBottom: 0,
                  borderBottom: '1px solid black',
                  fontFamily: 'Raleway-Bold',
                }}
              >
                {caregiver.caregiverStatus === CaregiverStatus.Declined && 'Caregiver Declined'}
                {caregiver.caregiverStatus === CaregiverStatus.Awaiting && 'Waiting for Caregiver to Accept Invitation'}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                colSpan={2}
                style={{
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  paddingTop: 0,
                  borderBottom: '1px solid black',
                  paddingBottom: 0,
                  height: 45,
                  fontFamily: 'Raleway-Bold',
                  color: theme.colors.status.bad,
                  textDecoration: 'underline',
                }}
              >
                {resending ? (
                  <BeatLoader size={10} color={theme.colors.primary1} />
                ) : invitationResent ? (
                  <span style={{ cursor: 'pointer' }} onClick={() => resendInvite(caregiver)}>
                    {resendMessage}
                  </span>
                ) : (
                  <span style={{ cursor: 'pointer' }} onClick={() => resendInvite(caregiver)}>
                    Click Here to Resend
                  </span>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            <></>
          )}
          {caregiver.caregiverAccessPermissions.map((permission, index) => (
            <StyledTableRow style={{ alignContent: 'right' }}>
              <StyledTableCell
                align="left"
                colSpan={2}
                style={{
                  verticalAlign: 'middle',
                  textAlign: 'left',
                  paddingTop: 0,
                  borderBottom: '1px solid black',
                  paddingBottom: 0,
                  height: 40,
                  fontFamily: 'Raleway-Bold',
                }}
              >
                {permission.displayText}
              </StyledTableCell>
              <StyledTableCell
                align="right"
                colSpan={0}
                style={{
                  textAlign: 'right',
                  alignContent: 'right',
                  verticalAlign: 'middle',
                  paddingTop: 0,
                  paddingBottom: 0,
                  borderBottom: '1px solid black',
                  fontFamily: 'Raleway-Bold',
                }}
              >
                <StyledCheckbox type="checkbox" checked={permission.permissionGranted} onChange={() => handlePermissionGranted(caregiver, index)} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const StyledCheckbox = styled.input`
  width: 25px;
  height: 25px;
  vertical-align: middle;
  accent-color: #00677f;
`
