import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import PageContainer from 'Components/PageContainer'
import ProgramScreen from 'Components/ProgramScreen'
import { sizes } from 'Styles/sizes'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useChallenges from 'hooks/useChallenges'
import React, { useEffect, useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { Challenge, ChallengeSection } from 'types/Challenge'
import ProgressBar from '../Components/ProgressBar/ProgressBar'
import { reflectionQuestionList } from '../Components/reflectionQuestion'
import MultiSelect from '../QuestionPage/MultiSelect'
import SingleSelect from '../QuestionPage/SingleSelect'
import BPStep1 from '../Sections/BalancedPlate/InfoPages/BPStep1'
import BPStep2 from '../Sections/BalancedPlate/InfoPages/BPStep2'
import BPStep3 from '../Sections/BalancedPlate/InfoPages/BPStep3'
import BPStep4 from '../Sections/BalancedPlate/InfoPages/BPStep4'
import BPStep7 from '../Sections/BalancedPlate/InfoPages/BPStep7'
import ExerciseStep1 from '../Sections/BenefitsOfExercise/InfoPages/ExerciseStep1'
import ExerciseStep2 from '../Sections/BenefitsOfExercise/InfoPages/ExerciseStep2'
import ExerciseStep3 from '../Sections/BenefitsOfExercise/InfoPages/ExerciseStep3'
import ExerciseStep4 from '../Sections/BenefitsOfExercise/InfoPages/ExerciseStep4'
import ExerciseStep5 from '../Sections/BenefitsOfExercise/InfoPages/ExerciseStep5'
import CarbsStep1 from '../Sections/Carbs/InfoPages/CarbsStep1'
import CarbsStep2 from '../Sections/Carbs/InfoPages/CarbsStep2'
import CarbsStep3 from '../Sections/Carbs/InfoPages/CarbsStep3'
import CarbsStep4 from '../Sections/Carbs/InfoPages/CarbsStep4'
import CarbsStep5 from '../Sections/Carbs/InfoPages/CarbsStep5'
import CarbsStep6 from '../Sections/Carbs/InfoPages/CarbsStep6'
import CarbsStep7 from '../Sections/Carbs/InfoPages/CarbsStep7'
import WeightLossStep1 from '../Sections/ExerciseForWeightLoss/WeightLossStep1'
import WeightLossStep2 from '../Sections/ExerciseForWeightLoss/WeightLossStep2'
import WeightLossStep3 from '../Sections/ExerciseForWeightLoss/WeightLossStep3'
import WeightLossStep4 from '../Sections/ExerciseForWeightLoss/WeightLossStep4'
import WeightLossStep5 from '../Sections/ExerciseForWeightLoss/WeightLossStep5'
import WeightLossStep6 from '../Sections/ExerciseForWeightLoss/WeightLossStep6'
import WeightLossStep7 from '../Sections/ExerciseForWeightLoss/WeightLossStep7'
import FatStep1 from '../Sections/Fats/InfoPages/FatStep1'
import FatStep2 from '../Sections/Fats/InfoPages/FatStep2'
import FatStep3 from '../Sections/Fats/InfoPages/FatStep3'
import FatStep4 from '../Sections/Fats/InfoPages/FatStep4'
import FatStep5 from '../Sections/Fats/InfoPages/FatStep5'
import FLStep1 from '../Sections/FoodLayering/InfoPages/FLStep1'
import FLStep2 from '../Sections/FoodLayering/InfoPages/FLStep2'
import FLStep3 from '../Sections/FoodLayering/InfoPages/FLStep3'
import FLStep4 from '../Sections/FoodLayering/InfoPages/FLStep4'
import FQStep1 from '../Sections/FoodQuality/InfoPages/FQStep1'
import FQStep10 from '../Sections/FoodQuality/InfoPages/FQStep10'
import FQStep2 from '../Sections/FoodQuality/InfoPages/FQStep2'
import FQStep3 from '../Sections/FoodQuality/InfoPages/FQStep3'
import FQStep4 from '../Sections/FoodQuality/InfoPages/FQStep4'
import FQStep5 from '../Sections/FoodQuality/InfoPages/FQStep5'
import FQStep6 from '../Sections/FoodQuality/InfoPages/FQStep6'
import FQStep7 from '../Sections/FoodQuality/InfoPages/FQStep7'
import FQStep8 from '../Sections/FoodQuality/InfoPages/FQStep8'
import FQStep9 from '../Sections/FoodQuality/InfoPages/FQStep9'
import HAYGStep1 from '../Sections/HowToAchieveYourGoals/InfoPages/HAYGstep1'
import HAYGStep2 from '../Sections/HowToAchieveYourGoals/InfoPages/HAYGstep2'
import HAYGStep3 from '../Sections/HowToAchieveYourGoals/InfoPages/HAYGstep3'
import HAYGStep4 from '../Sections/HowToAchieveYourGoals/InfoPages/HAYGstep4'
import HAYGStep5 from '../Sections/HowToAchieveYourGoals/InfoPages/HAYGstep5'
import DragAndDrop from '../Sections/HowToSetAGoal/DragAndDrop'
import HSGStep1 from '../Sections/HowToSetAGoal/InfoPages/HSGstep1'
import HSGStep2 from '../Sections/HowToSetAGoal/InfoPages/HSGstep2'
import HSGStep3 from '../Sections/HowToSetAGoal/InfoPages/HSGstep3'
import HydrationStep1 from '../Sections/Hydration/InfoPages/HydrationStep1'
import HydrationStep2 from '../Sections/Hydration/InfoPages/HydrationStep2'
import HydrationStep3 from '../Sections/Hydration/InfoPages/HydrationStep3'
import HydrationStep4 from '../Sections/Hydration/InfoPages/HydrationStep4'
import HydrationStep5 from '../Sections/Hydration/InfoPages/HydrationStep5'
import HydrationStep7 from '../Sections/Hydration/InfoPages/HydrationStep7'
import MicroStep1 from '../Sections/Micronutrients/InfoPages/MicroStep1'
import MicroStep2 from '../Sections/Micronutrients/InfoPages/MicroStep2'
import MicroStep3 from '../Sections/Micronutrients/InfoPages/MicroStep3'
import MicroStep4 from '../Sections/Micronutrients/InfoPages/MicroStep4'
import MicroStep5 from '../Sections/Micronutrients/InfoPages/MicroStep5'
import MicroStep6 from '../Sections/Micronutrients/InfoPages/MicroStep6'
import MicroStep7 from '../Sections/Micronutrients/InfoPages/MicroStep7'
import MicroStep8 from '../Sections/Micronutrients/InfoPages/MicroStep8'
import OVPStep1 from '../Sections/OutcomesVProcess/InfoPages/OVPstep1'
import OVPStep2 from '../Sections/OutcomesVProcess/InfoPages/OVPstep2'
import ProteinStep1 from '../Sections/Protein/InfoPages/ProteinStep1'
import ProteinStep2 from '../Sections/Protein/InfoPages/ProteinStep2'
import ProteinStep3 from '../Sections/Protein/InfoPages/ProteinStep3'
import ProteinStep4 from '../Sections/Protein/InfoPages/ProteinStep4'
import ProteinStep5 from '../Sections/Protein/InfoPages/ProteinStep5'
import SleepStep1 from '../Sections/Sleep/InfoPages/SleepStep1'
import SleepStep2 from '../Sections/Sleep/InfoPages/SleepStep2'
import SleepStep3 from '../Sections/Sleep/InfoPages/SleepStep3'
import SleepStep4 from '../Sections/Sleep/InfoPages/SleepStep4'
import SleepStep5 from '../Sections/Sleep/InfoPages/SleepStep5'
import SleepStep6 from '../Sections/Sleep/InfoPages/SleepStep6'
import SleepStep7 from '../Sections/Sleep/InfoPages/SleepStep7'
import TrafficStep1 from '../Sections/Traffic/InfoPages/TrafficStep1'
import TrafficStep2 from '../Sections/Traffic/InfoPages/TrafficStep2'
import TrafficStep3 from '../Sections/Traffic/InfoPages/TrafficStep3'
import TrafficStep4 from '../Sections/Traffic/InfoPages/TrafficStep4'
import TrafficStep5 from '../Sections/Traffic/InfoPages/TrafficStep5'

interface Props {
  isMobile: boolean
  currentChallenge?: Challenge
  setCurrentChallenge: (challenge: Challenge) => void
  step: number
  setStep: (step: number) => void
}
export default function InfoPage({ isMobile, setCurrentChallenge, currentChallenge, setStep, step }: Props) {
  const navigate = useNavigate()
  const token = useAppSelector(getAccessToken) ?? ''
  const { getChallenges, changeSectionStep, challengeCompletion } = useChallenges(token)
  const [section, setSection] = useState<ChallengeSection>()
  const [reflectionQuestionArray, setReflectionQuestionArray] = useState<{ section: number; question: string; tip?: string }[]>()
  const [stepPercent, setStepPercent] = useState<number>(1)
  const [infoPage, setInfoPage] = useState<boolean>(true)
  const [content, setContent] = useState(<></>)
  const [selectedCorrectAnswer, setSelectedCorrectAnswer] = useState<boolean>()
  const [answerClicked, setAnswerClicked] = useState<number>()
  const [answerClickedArray, setAnswerClickedArray] = useState<number[]>([])
  const [multipleCorrectAnswers, setMultipleCorrectAnswers] = useState<boolean>(false)
  const [answersSubmitted, setAnswersSubmitted] = useState<boolean>(false)
  const [dragAndDrop, setDragAndDrop] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    getChallenges().then((res) => {
      setCurrentChallenge(
        res.data.challenges.find((challenge) =>
          challenge.challengeSections.find(
            (section) =>
              section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
          ),
        ),
      )
      setSection(
        res.data.challenges
          .find((challenge) =>
            challenge.challengeSections.find(
              (section) =>
                section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
            ),
          )
          .challengeSections.find(
            (section) =>
              section!.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
          ),
      )
      setReflectionQuestionArray(
        reflectionQuestionList.filter(
          (q) => q.section === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]),
        ) ?? '',
      )
      setStepPercent(
        (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]) /
          res.data.challenges
            .find((challenge) =>
              challenge.challengeSections.find(
                (section) =>
                  section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 2],
              ),
            )
            .challengeSections.find(
              (section) =>
                section!.challengeSectionPId.toString() === window.location.pathname.split('/')[window.location.pathname.split('/').length - 2],
            ).numberOfSteps) *
          100,
      )
      dispatch(setLoading(false))
    })
    setMultipleCorrectAnswers(false)
    setAnswerClicked(undefined)
    setSelectedCorrectAnswer(undefined)
    setAnswerClickedArray([])
    setAnswersSubmitted(false)
  }, [step])

  useEffect(() => {
    const setContentFunction = () => {
      if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 1) {
        switch (step) {
          case 1:
            return setContent(<OVPStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<OVPStep2 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 4:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 2) {
        switch (step) {
          case 1:
            return setContent(<HSGStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<HSGStep2 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<HSGStep3 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(
              <DragAndDrop
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean) => setSelectedCorrectAnswer(c)}
                setDragAndDrop={(c: boolean) => setDragAndDrop(c)}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 3) {
        switch (step) {
          case 1:
            return setContent(<HAYGStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<HAYGStep2 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<HAYGStep3 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<HAYGStep4 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<HAYGStep5 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 4) {
        switch (step) {
          case 1:
            return setContent(<CarbsStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<CarbsStep2 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<CarbsStep3 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<CarbsStep4 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<CarbsStep5 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(<CarbsStep6 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 7:
            return setContent(<CarbsStep7 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 8:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 9:
            return setContent(
              <MultiSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClickedArray={(c: number[]) => setAnswerClickedArray(c)}
                answerClickedArray={answerClickedArray}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
                setMultipleCorrectAnswers={setMultipleCorrectAnswers}
                answersSubmitted={answersSubmitted}
                setAnswersSubmitted={(c: boolean) => setAnswersSubmitted(c)}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 5) {
        switch (step) {
          case 1:
            return setContent(<ProteinStep1 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<ProteinStep2 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<ProteinStep3 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<ProteinStep4 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<ProteinStep5 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 6) {
        switch (step) {
          case 1:
            return setContent(<FatStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<FatStep2 isMobile={isMobile} step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<FatStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<FatStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<FatStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 7:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 7) {
        switch (step) {
          case 1:
            return setContent(<MicroStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<MicroStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<MicroStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<MicroStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<MicroStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(<MicroStep6 step={step} setInfoPage={setInfoPage} />)
          case 7:
            return setContent(<MicroStep7 step={step} setInfoPage={setInfoPage} />)
          case 8:
            return setContent(<MicroStep8 step={step} setInfoPage={setInfoPage} />)
          case 9:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 10:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 8) {
        switch (step) {
          case 1:
            return setContent(<HydrationStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<HydrationStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<HydrationStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<HydrationStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<HydrationStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(<HydrationStep7 step={step} setInfoPage={setInfoPage} />)
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 9) {
        switch (step) {
          case 1:
            return setContent(<FQStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<FQStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<FQStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<FQStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<FQStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(<FQStep6 step={step} setInfoPage={setInfoPage} />)
          case 7:
            return setContent(<FQStep7 step={step} setInfoPage={setInfoPage} />)
          case 8:
            return setContent(<FQStep8 step={step} setInfoPage={setInfoPage} />)
          case 9:
            return setContent(<FQStep9 step={step} setInfoPage={setInfoPage} />)
          case 10:
            return setContent(<FQStep10 step={step} setInfoPage={setInfoPage} />)
          case 11:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 10) {
        switch (step) {
          case 1:
            return setContent(<TrafficStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<TrafficStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<TrafficStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<TrafficStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<TrafficStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(
              <MultiSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClickedArray={(c: number[]) => setAnswerClickedArray(c)}
                answerClickedArray={answerClickedArray}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
                setMultipleCorrectAnswers={(c: boolean) => setMultipleCorrectAnswers(c)}
                answersSubmitted={answersSubmitted}
                setAnswersSubmitted={(c: boolean) => setAnswersSubmitted(c)}
              />,
            )
          case 7:
            return setContent(
              <MultiSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClickedArray={(c: number[]) => setAnswerClickedArray(c)}
                answerClickedArray={answerClickedArray}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
                setMultipleCorrectAnswers={(c: boolean) => setMultipleCorrectAnswers(c)}
                answersSubmitted={answersSubmitted}
                setAnswersSubmitted={(c: boolean) => setAnswersSubmitted(c)}
              />,
            )
          case 8:
            return setContent(
              <MultiSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClickedArray={(c: number[]) => setAnswerClickedArray(c)}
                answerClickedArray={answerClickedArray}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
                setMultipleCorrectAnswers={(c: boolean) => setMultipleCorrectAnswers(c)}
                answersSubmitted={answersSubmitted}
                setAnswersSubmitted={(c: boolean) => setAnswersSubmitted(c)}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 11) {
        switch (step) {
          case 1:
            return setContent(<BPStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<BPStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<BPStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<BPStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 6:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 7:
            return setContent(<BPStep7 step={step} setInfoPage={setInfoPage} />)
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 12) {
        switch (step) {
          case 1:
            return setContent(<FLStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<FLStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<FLStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<FLStep4 step={step} setInfoPage={setInfoPage} />)
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 13) {
        switch (step) {
          case 1:
            return setContent(<ExerciseStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<ExerciseStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<ExerciseStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<ExerciseStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<ExerciseStep5 step={step} setInfoPage={setInfoPage} />)
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 14) {
        switch (step) {
          case 1:
            return setContent(<WeightLossStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<WeightLossStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<WeightLossStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<WeightLossStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<WeightLossStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(<WeightLossStep6 step={step} setInfoPage={setInfoPage} />)
          case 7:
            return setContent(<WeightLossStep7 step={step} setInfoPage={setInfoPage} />)
          case 8:
            return setContent(
              <MultiSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClickedArray={(c: number[]) => setAnswerClickedArray(c)}
                answerClickedArray={answerClickedArray}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
                setMultipleCorrectAnswers={(c: boolean) => setMultipleCorrectAnswers(c)}
                answersSubmitted={answersSubmitted}
                setAnswersSubmitted={(c: boolean) => setAnswersSubmitted(c)}
              />,
            )
          case 9:
            return setContent(
              <MultiSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClickedArray={(c: number[]) => setAnswerClickedArray(c)}
                answerClickedArray={answerClickedArray}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
                setMultipleCorrectAnswers={(c: boolean) => setMultipleCorrectAnswers(c)}
                answersSubmitted={answersSubmitted}
                setAnswersSubmitted={(c: boolean) => setAnswersSubmitted(c)}
              />,
            )
          default:
            return <></>
        }
      } else if (parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]) === 15) {
        switch (step) {
          case 1:
            return setContent(<SleepStep1 step={step} setInfoPage={setInfoPage} />)
          case 2:
            return setContent(<SleepStep2 step={step} setInfoPage={setInfoPage} />)
          case 3:
            return setContent(<SleepStep3 step={step} setInfoPage={setInfoPage} />)
          case 4:
            return setContent(<SleepStep4 step={step} setInfoPage={setInfoPage} />)
          case 5:
            return setContent(<SleepStep5 step={step} setInfoPage={setInfoPage} />)
          case 6:
            return setContent(<SleepStep6 step={step} setInfoPage={setInfoPage} />)
          case 7:
            return setContent(<SleepStep7 step={step} setInfoPage={setInfoPage} />)
          case 8:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          case 9:
            return setContent(
              <SingleSelect
                selectedCorrectAnswer={selectedCorrectAnswer}
                isMobile={isMobile}
                step={step}
                setInfoPage={setInfoPage}
                setSelectedCorrectAnswer={(c: boolean | undefined) => setSelectedCorrectAnswer(c)}
                setAnswerClicked={(c: number | undefined) => setAnswerClicked(c)}
                answerClicked={answerClicked}
              />,
            )
          default:
            return <></>
        }
      }
    }
    setContentFunction()
  }, [step, answerClicked, answerClickedArray, answersSubmitted])

  const handleNextStep = () => {
    setAnswersSubmitted(false)
    setSelectedCorrectAnswer(undefined)
    setStep(step + 1)

    if (step + 1 === section?.numberOfSteps && reflectionQuestionArray && reflectionQuestionArray?.length > 0) {
      changeSectionStep(parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]), step + 1).then(() =>
        navigate(`/reflection/${section?.challengeSectionPId}`),
      )
    } else {
      if (step === section?.numberOfSteps) {
        dispatch(setLoading(true))
        changeSectionStep(parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]), step + 1)
          .then(() => navigate(`/challenge/complete/${section?.challengeSectionPId}`))
          .finally(() =>
            getChallenges()
              .then((res) => {
                const challenge = res.data.challenges.find((challenge) =>
                  challenge.challengeSections.find(
                    (section) =>
                      section?.challengeSectionPId === parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]),
                  ),
                )
                setCurrentChallenge(challenge)
                if (challenge.numberOfCompletedSections === challenge.numberOfSections && !challenge.badgeEarned) {
                  challenge && challengeCompletion(challenge.challengePId)
                }
              })
              .finally(() => {
                dispatch(setLoading(false))
              }),
          )
      } else {
        changeSectionStep(parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]), step + 1).then(() =>
          navigate(`/challenge/section/${section?.challengeSectionPId}/${step + 1}`),
        )
      }
    }
  }

  const handleBackClick = () => {
    setAnswersSubmitted(false)
    setStep(step - 1)
    if (step === 1) {
      navigate(`/challenge/intro/${section?.challengeSectionPId}`)
    } else {
      navigate(`/challenge/section/${section?.challengeSectionPId}/${step - 1}`)
    }
  }

  const handleSubmitClick = () => {
    setAnswersSubmitted(true)
    if (step === 9 && section?.challengeSectionPId === 4) {
      setSelectedCorrectAnswer(
        answerClickedArray.includes(1) && answerClickedArray.includes(2) && answerClickedArray.includes(3) && answerClickedArray.includes(4),
      )
    } else if (step === 6 && section?.challengeSectionPId === 10) {
      setSelectedCorrectAnswer(
        !answerClickedArray.includes(1) && answerClickedArray.includes(2) && !answerClickedArray.includes(3) && answerClickedArray.includes(4),
      )
    } else if (step === 7 && section?.challengeSectionPId === 10) {
      setSelectedCorrectAnswer(
        answerClickedArray.includes(1) && !answerClickedArray.includes(2) && !answerClickedArray.includes(3) && !answerClickedArray.includes(4),
      )
    } else if (step === 8 && section?.challengeSectionPId === 10) {
      setSelectedCorrectAnswer(
        !answerClickedArray.includes(1) && !answerClickedArray.includes(2) && answerClickedArray.includes(3) && !answerClickedArray.includes(4),
      )
    } else if (step === 8 && section?.challengeSectionPId === 14) {
      setSelectedCorrectAnswer(
        !answerClickedArray.includes(1) && answerClickedArray.includes(2) && answerClickedArray.includes(3) && !answerClickedArray.includes(4),
      )
    } else if (step === 9 && section?.challengeSectionPId === 14) {
      setSelectedCorrectAnswer(
        !answerClickedArray.includes(1) && !answerClickedArray.includes(2) && answerClickedArray.includes(3) && answerClickedArray.includes(4),
      )
    }
  }

  return (
    <>
      {infoPage ? (
        <div>
          <PageContainer styles={isMobile ? { border: '1px solid white', boxShadow: 'none' } : {}}>
            <Row>
              <Column styles={{ margin: '10px auto' }}>
                <ProgressBar stepPercent={stepPercent} />
                <Typography styles={{ margin: '10px auto' }} font="bold">
                  {section?.name ?? ''}
                </Typography>
              </Column>
            </Row>
            <ProgramScreen content={content} />
            <ResponsiveContainer>
              {!isMobile && (
                <StyledButton style={{ backgroundColor: '#425563', marginRight: 10 }} onClick={() => handleBackClick()}>
                  Back
                </StyledButton>
              )}
              <StyledButton
                style={{ backgroundColor: '#00677F', marginTop: isMobile ? 30 : 10, marginLeft: isMobile ? 0 : 10 }}
                onClick={() => handleNextStep()}
              >
                Next
              </StyledButton>
              {isMobile && (
                <StyledButton style={{ backgroundColor: '#425563' }} onClick={() => handleBackClick()}>
                  Back
                </StyledButton>
              )}
            </ResponsiveContainer>
          </PageContainer>
        </div>
      ) : (
        <div>
          <PageContainer styles={isMobile ? { border: '1px solid white', boxShadow: 'none' } : {}}>
            <Row>
              <Column styles={{ margin: '0px auto' }}>
                <ProgressBar stepPercent={stepPercent} />
                <Typography styles={{ margin: '10px auto 30px' }} font="bold">
                  {section?.name ?? ''}
                </Typography>
              </Column>
            </Row>
            <Row styles={{ margin: '10px auto' }}>
              <Icon name="question" styles={{ marginTop: 6 }} />
              <Typography styles={{ margin: '0px 10px' }} font="bold" size={24}>
                Knowledge Check
              </Typography>
              <Icon name="question" styles={{ marginTop: 6 }} />
            </Row>
            <ProgramScreen content={content} />
            <>
              <>
                {multipleCorrectAnswers && !answersSubmitted && (
                  <StyledButton style={{ backgroundColor: '#7A9A01', margin: '20px auto' }} onClick={() => handleSubmitClick()}>
                    Submit
                  </StyledButton>
                )}
              </>
              {multipleCorrectAnswers && selectedCorrectAnswer === true && (
                <>
                  <Typography color="#7A9A01" font="bold" styles={{ margin: '50px auto 0px', textAlign: 'center' }}>
                    That's Right!
                  </Typography>
                </>
              )}
            </>
            <ResponsiveContainer styles={{ margin: '90px auto 0px' }}>
              {!isMobile && (
                <StyledButton style={{ backgroundColor: '#425563', marginRight: 10 }} onClick={() => handleBackClick()}>
                  Back
                </StyledButton>
              )}
              <StyledButton
                style={
                  selectedCorrectAnswer
                    ? { backgroundColor: '#00677F', marginTop: isMobile ? 30 : 10, marginLeft: isMobile ? 0 : 10 }
                    : { backgroundColor: '#BDBDBD', marginTop: isMobile ? 30 : 10, pointerEvents: 'none' }
                }
                onClick={() => handleNextStep()}
              >
                Next
              </StyledButton>
              {isMobile && (
                <StyledButton style={{ backgroundColor: '#425563' }} onClick={() => handleBackClick()}>
                  Back
                </StyledButton>
              )}
            </ResponsiveContainer>
          </PageContainer>
        </div>
      )}
    </>
  )
}

const StyledButton = styled(BootstrapButton)`
  font-family: 'Raleway-Semi-Bold';
  padding: 10px 40px;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media ${sizes.tablet} {
    margin-bottom: 0px;
  }
`
