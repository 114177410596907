export const reflectionQuestionList = [
  { section: 2, question: 'What is one SMART goal you would like to make for yourself?' },
  { section: 3, question: 'Which strategy would you like to start using?' },
  {
    section: 4,
    question: "How many servings of vegetables do you consume daily? What's a new vegetable you could try next time you grocery shop?",
  },
  { section: 5, question: 'What are your main sources of protein each week?' },
  { section: 8, question: 'How much plain water do you think you consume each day?' },
  { section: 8, question: 'What is one way you could increase your water intake throughout the day?' },
  {
    section: 9,
    tip: 'This could be focusing on one specific meal or finding healthier snack alternatives.',
    question: 'What is one way you could cut back on the amount of processed food in your diet?',
  },
  { section: 12, question: 'How do you plan to incorporate food layering and healthy swaps into your meals?' },
  { section: 13, question: 'What is one way you can increase movement in your daily life?' },
  { section: 14, question: "What is an exercise you're curious learning more about?" },
  { section: 15, question: "Are there any habits you'd like to change to enhance your sleep quality?" },
]
