import BackRow from 'Components/Base/BackRow'
import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import PageTitle from 'Components/Base/PageTitle/PageTitle'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { getActiveResourceSections, getResourceSubSections, getResources } from 'reducers/resourceSlice'
import styled, { useTheme } from 'styled-components'
import { ResourceSubSection } from 'types/Resource'
import { ResourceLink } from 'types/ResourceLink'
import MediaColumn from './MediaColumn'
import './ResourceDetailPage.scss'
import ResourceLinkItem from './ResourceLink/index'

export default function ResourceDetailPage() {
  const { resourceName }: any = useParams()
  const { setDocumentTitle } = useDocumentTitle()

  const theme = useTheme()
  const navigate = useNavigate()

  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const resources = useAppSelector(getResources)
  const resourceSections = useAppSelector(getActiveResourceSections)
  const resourceSubSections = useAppSelector(getResourceSubSections)

  const [resourceLinks, setResourceLinks] = useState<ResourceLink[]>([])
  const [subSections, setSubSections] = useState<ResourceSubSection[]>([])
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [showSpanish, setShowSpanish] = useState<boolean>(false)

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const resourceSection = resourceSections?.find((x) => x.route.substring(10, x.route.length) === resourceName)

  const pageSubSections = resourceSubSections?.filter((x) => x.sectionParentId === resourceSection?.sectionId)
  // const resourceLinks = resources?.filter(
  //   (x) =>
  //     x.sectionId === resourceSection?.sectionId && x.active,
  // )

  setDocumentTitle(`${resourceSection?.sectionName ?? 'Resources'}`)

  useEffect(() => {
    setResourceLinks(resources!.filter((x) => x.sectionId === resourceSection?.sectionId && x.active))
    const subSections: ResourceSubSection[] = []

    const subSectionCopy = pageSubSections?.map((x) => {
      const sectionLinks = resources?.filter((y) => y.sectionId === x.sectionId && y.active)
      const copy = Object.assign({}, x, {
        ...x,
        resourceLinks: sectionLinks,
      })
      subSections.push(copy)
    })
    subSectionCopy && setSubSections(subSections)
  }, [])

  const backClicked = () => {
    navigate(-1)
  }

  const handleResourceClick = (link: ResourceLink) => {
    analytics.postAnalyticsEvent({
      category: `click_resource_${link.title}`,
      action: `click_resource_${link.title}`,
      label: `Button`,
      screenName: 'ResourceDetail',
    })
  }

  useEffect(() => {
    if (showSpanish) {
      setResourceLinks(resources!.filter((x) => x.sectionId === resourceSection?.sectionId && x.active && x.titleSpanish))
      const subSections: ResourceSubSection[] = []

      const subSectionCopy = pageSubSections?.map((x) => {
        const sectionLinks = resources?.filter((y) => y.sectionId === x.sectionId && y.titleSpanish && y.active)
        const copy = Object.assign({}, x, {
          ...x,
          resourceLinks: sectionLinks,
        })
        subSections.push(copy)
      })
      subSectionCopy && setSubSections(subSections)
    } else {
      setResourceLinks(resources!.filter((x) => x.sectionId === resourceSection?.sectionId && x.active))
      const subSections: ResourceSubSection[] = []

      const subSectionCopy = pageSubSections?.map((x) => {
        const sectionLinks = resources?.filter((y) => y.sectionId === x.sectionId && y.active)
        const copy = Object.assign({}, x, {
          ...x,
          resourceLinks: sectionLinks,
        })
        subSections.push(copy)
      })
      subSectionCopy && setSubSections(subSections)
    }
  }, [showSpanish])

  return (
    <StyledPageContainer>
      <BackRow />
      <div className={'text-container'}>
        <div>
          <Row>
            <PageTitle
              title={showSpanish && resourceSection?.sectionNameSpanish ? resourceSection?.sectionNameSpanish : resourceSection?.sectionName ?? ''}
              styles={{ marginBottom: 40 }}
              bold
            />
            <Row styles={{ marginLeft: 'auto' }}>
              <div onClick={() => setShowSpanish(!showSpanish)} style={{ cursor: 'pointer', marginRight: 5, textDecoration: 'underline' }}>
                <Typography>{showSpanish ? 'View English Resources' : 'Ver Recursos en Español'}</Typography>
              </div>
            </Row>
            {/* <Row styles={{marginLeft: 'auto'}}><div onClick={()=>setShowSpanish(false)} style={{cursor: 'pointer', marginRight: 5}}><Typography >English</Typography></div>{' | '}<div onClick={()=>setShowSpanish(true)} style={{cursor: 'pointer', marginLeft: 5}}><Typography>Español</Typography></div></Row> */}
          </Row>

          {resourceLinks &&
            resourceLinks.map((link) => (
              <>
                {!showSpanish && (
                  <Row onClick={() => handleResourceClick(link)}>
                    <Icon name={link.icon} styles={{ marginRight: 10 }} color={theme.colors.primary1} />
                    <ResourceLinkItem
                      title={link.title}
                      url={link.filePath ? link.filePath : link.webAddress}
                      spanishUrl={link.filePathSpanish ? link.filePathSpanish : undefined}
                      titleSpanish={link.titleSpanish ? link.titleSpanish : undefined}
                    />
                  </Row>
                )}
                {showSpanish && (
                  <Row onClick={() => handleResourceClick(link)}>
                    <Icon name={link.icon} styles={{ marginRight: 10 }} color={theme.colors.primary1} />
                    <ResourceLinkItem title={link.titleSpanish} url={link.filePathSpanish} />
                  </Row>
                )}
              </>
            ))}
        </div>
        <div style={resourceSection?.sectionName === 'Blood Pressure' ? { display: 'flex', flexDirection: 'row' } : {}}>
          <div
            style={
              resourceSection?.sectionName === 'Blood Pressure'
                ? { display: 'flex', flexDirection: 'column', width: '80%' }
                : { display: 'flex', flexWrap: 'wrap' }
            }
          >
            {resourceSection?.sectionName === 'Blood Pressure' && (
              <Row className="bp-disclaimer">
                <Typography>
                  INFORMATION PROVIDED ON THIS WEBSITE IS NOT INTENDED TO BE TREATED AS MEDICAL ADVICE AND SHOULD NOT UNDER ANY CIRCUMSTANCES BE USED
                  AS A SUBSTITUTE FOR A PROFESSIONAL DIAGNOSIS OR PROFESSIONAL MEDICAL TREATMENT OR ADVICE.
                </Typography>
              </Row>
            )}
            {subSections &&
              subSections.length > 0 &&
              subSections.map((section) => {
                if (section.resourceLinks.length > 0) {
                  return (
                    <StyledColumn>
                      <>
                        <h3
                          className="section-title"
                          style={{
                            fontFamily: 'Raleway-Bold',
                            marginBottom: 30,
                            marginTop: 10,
                          }}
                        >
                          {showSpanish && section?.sectionNameSpanish ? section?.sectionNameSpanish : section?.sectionName}
                        </h3>
                        {section.resourceLinks?.map((link) => (
                          <>
                            {!showSpanish && (
                              <Row onClick={() => handleResourceClick(link)}>
                                <Icon name={link.icon} styles={{ marginRight: 10 }} color={theme.colors.primary1} />
                                <ResourceLinkItem
                                  title={link.title}
                                  url={link.filePath ? link.filePath : link.webAddress}
                                  spanishUrl={link.filePathSpanish ? link.filePathSpanish : undefined}
                                  titleSpanish={link.titleSpanish ? link.titleSpanish : undefined}
                                />
                              </Row>
                            )}
                            {showSpanish && (
                              <Row onClick={() => handleResourceClick(link)}>
                                <Icon name={link.icon} styles={{ marginRight: 10 }} color={theme.colors.primary1} />
                                <ResourceLinkItem title={link.titleSpanish} url={link.filePathSpanish} />
                              </Row>
                            )}
                          </>
                        ))}
                      </>
                    </StyledColumn>
                  )
                }
              })}
            {resourceSection?.sectionName === 'Blood Pressure' && isMobile && (
              <ResponsiveContainer>
                <MediaColumn onNavigate={(url) => navigate(url)} />
              </ResponsiveContainer>
            )}
          </div>
          {resourceSection?.sectionName === 'Blood Pressure' && !isMobile && (
            <StyledBPColumn>
              <MediaColumn onNavigate={(url) => navigate(url)} />
            </StyledBPColumn>
          )}
        </div>
      </div>
      <StyledBackButton pageName={`resources_${resourceSection?.sectionName}`} onClick={() => backClicked()} text={'Back'} />
    </StyledPageContainer>
  )
}

const StyledBackButton = styled(Button)`
  display: none;
  width: 150px;
  background-color: ${({ theme }) => theme.colors.darkGray};
  @media ${sizes.laptop} {
    display: block;
  }
`
const StyledPageContainer = styled.div`
  margin: 80px 6% 30% 6%;
  padding-left: 0px;
  @media (min-width: ${sizes.tablet}) {
    padding-left: 100px;
    padding-top: 50px;
    margin-bottom: 100px;
    margin-top: 0px;
  }
`

const StyledColumn = styled(Column)`
  max-width: 430px;
  margin-right: 0px;
  margin-top: 30px;
  @media ${sizes.laptop} {
    margin-right: 70px;
  }
`

const StyledBPColumn = styled(Column)`
  width: 400px;
  margin-right: 20px;
  @media (min-width: ${sizes.tablet}) {
    width: 30px;
  }
`
