import { useGridSlotComponentProps } from '@mui/x-data-grid'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import TimePicker from 'react-time-picker'
import { getAccessToken } from 'reducers/authSlice'
import styled from 'styled-components'

interface Props {
  setReadingType: (type: ReadingTypeKeys) => void
  readingType: ReadingTypeKeys | undefined
  onFilterRangeValue: (show: ShowKeys, range: ReadingKeys, rangeNumber: number) => void
  show: ShowKeys
  range: ReadingKeys
  rangeNumber?: number
  setShow: (show: ShowKeys) => void
  setRange: (range: ReadingKeys) => void
  setRangeNumber
  isMobile: boolean
  exportToPdf
  setStartTime: (type: string) => void
  setEndTime: (type: string) => void
  startTime: string | undefined
  endTime: string | undefined
}

export type ShowKeys = 'All' | 'Systolic' | 'Diastolic' | 'Pulse'
export type ReadingKeys = 'All' | 'Above' | 'Below'
export type ReadingTypeKeys = 'All Types' | 'Highs' | 'Above Range' | 'In Range' | 'Lows'

export function CustomBloodPressureToolbar({
  setReadingType,
  readingType,
  onFilterRangeValue,
  show,
  range,
  setShow,
  setRange,
  setRangeNumber,
  rangeNumber,
  isMobile,
  exportToPdf,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
}: Props) {
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const { apiRef } = useGridSlotComponentProps()
  const [tempRange, setTempRange] = useState<number | undefined>(rangeNumber)

  const filterReadings = (number: number) => {
    setTempRange(number)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      tempRange && setRangeNumber(tempRange)
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [tempRange])

  const handleExportClick = () => {
    analytics.postAnalyticsEvent({
      category: `click_button_dashboardGlucose_export`,
      action: `click_button_dashboardGlucose_export`,
      label: `Button`,
      screenName: 'BloodPressureDashboard',
    })
  }

  return (
    <StyledResponsiveContainer>
      <ResponsiveContainer>
        <Dropdown>
          <StyledToggle variant="secondary" id="dropdown-basic">
            {show ?? 'Show All'}
          </StyledToggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setShow('All')}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => setShow('Systolic')}>Systolic</Dropdown.Item>
            <Dropdown.Item onClick={() => setShow('Diastolic')}>Diastolic</Dropdown.Item>
            <Dropdown.Item onClick={() => setShow('Pulse')}>Pulse</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <StyledSpacer />
        {show !== 'All' && show !== undefined && (
          <Dropdown style={isMobile && (range === 'All' || range === undefined || show === undefined) ? { marginTop: 15 } : {}}>
            <StyledToggle variant="secondary" id="dropdown-basic">
              {range ?? 'Readings'}
            </StyledToggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setRangeNumber(undefined)
                  setRange('All')
                }}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setRangeNumber(undefined)
                  setRange('Above')
                }}
              >
                Above
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setRangeNumber(undefined)
                  setRange('Below')
                }}
              >
                Below
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {range !== 'All' && range !== undefined && show !== 'All' && show !== undefined && (
          <StyledFormInput
            onChange={(e) => {
              filterReadings(+e.currentTarget.value)
            }}
            value={tempRange}
          />
        )}
        <StyledSpacer />

        <Dropdown
          style={
            isMobile && (range === 'All' || range === undefined || show === 'All' || show === undefined) ? { marginTop: 15, marginBottom: 15 } : {}
          }
        >
          <StyledToggle variant="secondary" id="dropdown-basic">
            {readingType ?? 'All Types'}
          </StyledToggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setReadingType('All Types')}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => setReadingType('Highs')}>Highs</Dropdown.Item>
            <Dropdown.Item onClick={() => setReadingType('Above Range')}>Above Range</Dropdown.Item>
            <Dropdown.Item onClick={() => setReadingType('In Range')}>In Range</Dropdown.Item>
            <Dropdown.Item onClick={() => setReadingType('Lows')}>Lows</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ResponsiveContainer>
      {isMobile ? (
        <>
          <Typography styles={{ marginTop: 5 }}>Start Time:</Typography>
          <TimePicker
            disableClock={true}
            format={'hh:mm a'}
            value={startTime}
            onChange={(value) => setStartTime(value ? value.concat('', ':00') : value)}
          />
          <Typography styles={{ marginTop: 5 }}>End Time:</Typography>
          <TimePicker
            disableClock={true}
            format={'hh:mm a'}
            value={endTime}
            onChange={(value) => setEndTime(value ? value.concat('', ':00') : value)}
          />
        </>
      ) : (
        <>
          <Row styles={{ height: 38 }}>
            <Typography styles={{ marginTop: 10, marginRight: 10 }}>Start Time:</Typography>
            <TimePicker
              disableClock={true}
              format={'hh:mm a'}
              value={startTime}
              onChange={(value) => setStartTime(value ? value.concat('', ':00') : value)}
            />
          </Row>
          <Row styles={{ height: 38 }}>
            <Typography styles={{ marginTop: 10, marginRight: 10 }}>End Time:</Typography>
            <TimePicker
              disableClock={true}
              format={'hh:mm a'}
              value={endTime}
              onChange={(value) => setEndTime(value ? value.concat('', ':00') : value)}
            />
          </Row>
        </>
      )}
      <Dropdown style={isMobile ? { marginBottom: 55, marginTop: 10 } : {}} onClick={() => handleExportClick()}>
        <StyledExportToggle variant="secondary" id="dropdown-basic">
          Export
        </StyledExportToggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              apiRef.current.exportDataAsPrint({
                hideToolbar: true,
                hideFooter: true,
                fields: ['readingDate', 'systolicValue', 'diastolicValue', 'patientreadingLevel', 'heartRate'],
              })
            }
          >
            Print
          </Dropdown.Item>
          <Dropdown.Item onClick={() => apiRef.current.exportDataAsCsv()}>Export as CSV</Dropdown.Item>
          <Dropdown.Item onClick={() => exportToPdf()}>Export as PDF</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </StyledResponsiveContainer>
  )
}

const StyledToggle = styled(Dropdown.Toggle)`
  min-width: 100%;
  background-color: #f2f2f2;
  color: ${({ theme }) => theme.colors.black};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  @media ${sizes.laptopL} {
    width: 200px;
    margin-bottom: 5px;
  }
`

const StyledExportToggle = styled(StyledToggle)`
  background-color: ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.darkGray}`};
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-bottom: 15px;
  justify-content: space-between;
`

const StyledFormInput = styled(Form.Control)`
  margin-left: 0px;
  margin-top: 5px;
  min-height: 0px;
  max-height: 38px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 5px;
  background-color: #edfdff;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  border: 0px;
  @media ${sizes.tablet} {
    width: 125px;
    margin-left: 5px;
    margin-top: 0px;
  }
`

const StyledSpacer = styled.div`
  width: 0px;
  @media ${sizes.tablet} {
    width: 5px;
  }
`
