import Column from 'Components/Base/Column'
import PageTitle from 'Components/Base/PageTitle/PageTitle'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Typography from 'Components/Base/Typography'
import { StyledPageContainer } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import React from 'react'
import Iframe from 'react-iframe'
import styled from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'

export default function AccuChekPage() {
  const ACCUCHEK_EMBED_LINK = 'https://prezi.com/view/yWQ37hzLiMz2bdAqidKU/embed'
  const ACCUCHEK_SUPPORT_LINK = 'https://www.accu-chek.com/meters/aviva-connect-meter/support'

  return (
    <StyledPageContainer>
      <PageTitle title="Accu-Chek Guide" mobileOnly />
      <ResponsiveContainer styles={{ justifyContent: 'space-between' }}>
        <StyledColumn>
          <Iframe url={ACCUCHEK_EMBED_LINK} width="100%" height="350px" allowFullScreen id="myId" className="accuchek-frame" position="relative" />
        </StyledColumn>

        <StyledColumn>
          <StyledSubTitle>Get your phone and your meter ready!</StyledSubTitle>
          <Typography styles={{ marginBottom: 10 }}>This guide will help walk you through the setup process step by step.</Typography>
          <Typography>If you have any questions related to connecting with Tria Health,</Typography>
          <Typography styles={{ marginBottom: 10 }}>
            <>
              please contact us at {PhoneNumberPeriodDelimited} or <a href="mailto:info@triahealth.com">info@triahealth.com</a>
            </>
          </Typography>
          <Typography>If you have any questions related to setting up your device,</Typography>
          <Typography>
            <>please visit: </>
          </Typography>
          <Typography>
            <StyledLinkText target={'_blank'} href={ACCUCHEK_SUPPORT_LINK}>
              {ACCUCHEK_SUPPORT_LINK}
            </StyledLinkText>
          </Typography>
        </StyledColumn>
      </ResponsiveContainer>
    </StyledPageContainer>
  )
}

const StyledColumn = styled(Column)`
  width: 100%;
  margin-bottom: 20px;
  @media ${sizes.laptop} {
    width: 48%;
  }
`
const StyledLinkText = styled.a`
  color: ${({ theme }) => theme.colors.primary1} !important;
  font-weight: bold;
  font-size: 15px;
`
const StyledSubTitle = styled(Typography)`
  font-size: 8vw;
  margin-bottom: 10px;
  line-height: 1;
  text-align: center;
  @media ${sizes.tablet} {
    font-size: 4.5vw;
  }
  @media ${sizes.laptop} {
    font-size: 2.5vw;
  }
`
