import qs from 'querystring'
import { EnrollmentStatus } from 'types/Profile'
import { useApi } from './useAPI'

export default function useProfile(token: string) {
  const TriaApi = useApi(token)

  const getWelltrakFromGuid = async (guid: string) => {
    const response = TriaApi.get(`api/profiles/welltrak`, {
      params: { guid: guid },
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const updateEnrollmentStatus = async () => {
    const data = qs.stringify({
      EnrollmentStatus: EnrollmentStatus.Engaged,
      UpdatedBy: 'PatientPortal',
    })

    const response = TriaApi.post('api/profiles/EnrollmentStatus', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  return {
    getWelltrakFromGuid,
    updateEnrollmentStatus,
  }
}
