import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Patch 1.png'
import Image2 from '../../../../Assets/Images/STOP/Patch 2.png'
import Image3 from '../../../../Assets/Images/STOP/Patch 3.png'

export default function NRTPatch() {
  return (
    <ProConScreen
      title="NRT Products: Patch"
      subtitle="A small, sticky bandage-like patch that slowly releases nicotine which is absorbed through the skin."
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Available OTC in multiple strengths',
        'Easy to apply, once daily dosing',
        'Controls craving and withdrawal symptoms up to 24 hours',
        'Can be used in combination with other NRT products and medications',
      ]}
      cons={[
        'Does not aid in sudden cravings/withdrawal symptoms',
        'Can cause skin irritation where patch is applied, difficulty sleeping, or vivid dreams',
      ]}
    />
  )
}
