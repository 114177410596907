import React from 'react'
import { useNavigate } from 'react-router'
import { useTheme } from 'styled-components'
import Icon from '../../../../Components/Base/Icon'
import { IconNames } from '../../../../types/IconNames'
import './HelpCard.scss'
import { ChallengeSection, Challenge } from 'types/Challenge'
import useChallenges from 'hooks/useChallenges'
import { useAppSelector, useAppDispatch } from 'hooks/hooks'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'


interface Props {
  title: string
  link: string
  icon: IconNames
  relative?: boolean
  subtitle?: string
  sections?: ChallengeSection[]
  patientChallengePId: number
  challengePId: number
  challenges: Challenge[]
  setSections: (sections: ChallengeSection[]) => void
  setChallenges: (challenges: Challenge[]) => void
  setCurrentChallenge: (challenge: Challenge) => void
  isMobile: boolean
}
export default function HelpCard({ title, icon, link, relative, subtitle, sections, patientChallengePId, challengePId, setSections, setChallenges, setCurrentChallenge, challenges, isMobile }: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const token = useAppSelector(getAccessToken) ?? ''
  const { addPatientSections, getChallenges } = useChallenges(token)
  const dispatch = useAppDispatch()

  const cardClick = (link: string) => {
      window.scrollTo(0, 0)
      dispatch(setLoading(true))
      setCurrentChallenge(challenges.find(ch => ch.patientChallengePId === patientChallengePId)!)

      if(!sections || !(sections.length > 0)){
        addPatientSections(challengePId, patientChallengePId).then(()=>
        getChallenges()
          .then((res) => {
            setChallenges(res.data.challenges)
            setSections(res.data.challenges.find(ch => ch.patientChallengePId === patientChallengePId).challengeSections)
            dispatch(setLoading(false))
          })).finally(()=>navigate(link))
      }else{
      (sections && sections.length > 0) && setSections(sections)
      navigate(link)
      dispatch(setLoading(false))
      }
  }

  return (
    <div onClick={() => cardClick(`/challenges/${challengePId}`)} className={'resource-card'} style={isMobile ? {marginLeft: 'auto'} : {}}>
      <Icon name={icon} color={theme.colors.primary1} size={'3x'} styles={{ marginBottom: 10 }} />
      <h4 style={{ color: theme.colors.primary1, fontFamily: 'Raleway-Bold' }}>{title}</h4>
      {subtitle && (
        <h6 style={{ color: theme.colors.primary1, fontFamily: 'Raleway-Bold' }}>{subtitle}</h6>
      )}
    </div>
  )
}
