import qs from 'querystring'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { useApi } from '../hooks/useAPI'
import { CommunicationFormValues } from '../hooks/useCommunication'
import { RootState } from '../store'
import { Caregiver } from '../types/Caregiver'
import { HabitOptions } from '../types/HabitOptions'
import { Patient } from '../types/Profile'

export type CaregiverRequest = {
  caregiver: Caregiver
  token: string
}
export type DeleteCaregiverRequest = {
  patientCaregiverPId: number | undefined
  token: string
}
export type UpdateCaregiverPermissionsRequest = {
  caregivers: Caregiver[] | undefined
  token: string
}
export type ResendCaregiverRequest = {
  patientCaregiverPId: number
  token: string
}
export interface ProfileState {
  userProfile: Patient | undefined
  caregivers: Caregiver[] | undefined
  communicationPreferences: CommunicationFormValues
  habitOptions: HabitOptions | undefined
  status: 'idle' | 'loading' | 'failed'
  errorMessage?: string
}

const initialState: ProfileState = {
  userProfile: undefined,
  caregivers: undefined,
  communicationPreferences: {
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    emailOptIn: false,
    hasSignedConsent: false,
    textOptIns: [],
    signature: '',
  },
  habitOptions: undefined,
  status: 'idle',
}

export const loadProfile = createAsyncThunk('profile/load', async (token: string) => {
  const response: Promise<AxiosResponse<Patient>> = useApi(token).get('api/profiles', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const loadCommunicationPreferences = createAsyncThunk('profile/communication', async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(token).get('api/communication', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const loadCaregivers = createAsyncThunk('profile/caregivers/load', async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(token).get('api/caregiver/all', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const loadHabitOptions = createAsyncThunk('profile/habits/load', async (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(token).get('api/profiles/habits', {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const addCaregiver = createAsyncThunk('profile/caregivers/add', async (request: CaregiverRequest) => {
  const data = qs.stringify({
    Name: request.caregiver.name,
    Email: request.caregiver.email,
    CellPhone: request.caregiver.cellPhone,
    CanReceiveHealthAlerts: request.caregiver.canReceiveHealthAlerts,
    BirthDate: request.caregiver.birthDate,
    Address: request.caregiver.address,
    Relationship: request.caregiver.relationship,
    Esignature: request.caregiver.signature,
    DisclosurePurpose: request.caregiver.disclosurePurpose,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(request.token).post('api/caregiver/add', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return response
})

export const deleteCaregiver = createAsyncThunk('profile/caregivers/delete', async (request: DeleteCaregiverRequest) => {
  const data = qs.stringify({
    patientCaregiverPId: request.patientCaregiverPId,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(request.token).post('api/caregiver/delete', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return response
})

export const updateCaregiverPermissions = createAsyncThunk('profile/caregivers/update', async (request: UpdateCaregiverPermissionsRequest) => {
  const data = JSON.stringify(request.caregivers)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(request.token).post('api/caregiver/update', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response
})

export const resendInvitation = createAsyncThunk('profile/caregivers/resend', async (request: ResendCaregiverRequest) => {
  const data = qs.stringify({
    patientCaregiverPId: request.patientCaregiverPId,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: Promise<any> = useApi(request.token).post('api/caregiver/resend', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return response
})

export const profileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reduxLogout: (state) => {
      state.userProfile = undefined
      state.caregivers = undefined
      state.communicationPreferences = initialState.communicationPreferences
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProfile.pending, (state) => {
        state.status = 'loading'
        state.errorMessage = undefined
      })
      .addCase(loadProfile.fulfilled, (state, action) => {
        state.status = 'idle'
        state.userProfile = action.payload.data
        state.errorMessage = undefined
      })
      .addCase(loadProfile.rejected, (state) => {
        state.status = 'failed'
        state.errorMessage = 'There was an error. Please try again.'
      })
      .addCase(loadCommunicationPreferences.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadCommunicationPreferences.fulfilled, (state, action) => {
        state.status = 'idle'
        state.communicationPreferences = action.payload.data
      })
      .addCase(loadCommunicationPreferences.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(loadCaregivers.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadCaregivers.fulfilled, (state, action) => {
        state.status = 'idle'
        state.caregivers = action.payload.data.caregivers
      })
      .addCase(loadCaregivers.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(loadHabitOptions.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadHabitOptions.fulfilled, (state, action) => {
        state.status = 'idle'
        state.habitOptions = action.payload.data
      })
      .addCase(loadHabitOptions.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const getProfile = (state: RootState) => state.root.profile.userProfile
export const getCaregivers = (state: RootState) => state.root.profile.caregivers
export const getHabitOptions = (state: RootState) => state.root.profile.habitOptions

export const getProfileStatus = (state: RootState) => state.root.profile.status
export const getErrorMessage = (state: RootState) => state.root.profile.errorMessage

export const getCommunicationPreferences = (state: RootState) => state.root.profile.communicationPreferences

export default profileSlice.reducer
