import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  styles?: React.CSSProperties
  className?: string
  center?: boolean
}
export default function ResponsiveContainer({ children, styles, className, center = false }: Props) {
  if (center) styles = { ...styles, justifyContent: 'center' }

  return (
    <StyledContainer style={{ ...styles }} className={className}>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`
