import { sizes } from 'Styles/sizes'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import styled from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import Icon from '../../../Components/Base/Icon'
import '../ReimbursementPage.scss'

export default function InstructionsAccordion() {
  const accessToken = useAppSelector(getAccessToken)
  const analytics = useAnalytics(accessToken!)

  const [expandedSection, setExpandedSection] = useState<number | undefined>()

  const toggleSection = (sectionId: number) => {
    analytics.postAnalyticsEvent({
      category: `click_button_reimbursement_accordion_${sectionId + 1}`,
      action: `click_button_ctl_accordion_${sectionId + 1}`,
      label: `Button`,
      screenName: 'Reimbursement',
    })
    if (expandedSection === sectionId) {
      setExpandedSection(undefined)
    } else {
      setExpandedSection(sectionId)
    }
  }

  return (
    <StyledAccordion>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toggleSection(0)}>
          Overview <Icon name={expandedSection === 0 ? 'minus' : 'plus'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              All information must be provided in order to accurately process your claim(s). Incomplete or illegible information will result in form
              being returned or payment delays.
            </p>{' '}
            <p
              style={{
                fontFamily: 'Raleway-Bold',
                textAlign: 'center',
                fontSize: 14,
              }}
            >
              PLEASE ALLOW TWO - FOUR (2-4) WEEKS FOR PROCESSING
            </p>{' '}
            <p>
              Being able to submit manual claims to Tria Health for potential reimbursement is part of your prescription benefit package. In
              situations where the pharmacy cannot or will not process your Tria Health incentive, we are happy to process those claims manually based
              on the guidelines of your plan design.
            </p>{' '}
            <p
              style={{
                fontFamily: 'Raleway-Bold',
                textAlign: 'left',
              }}
            >
              The amount of reimbursement received may be less than the member paid at the pharmacy based on a number of variables including plan
              design, deductibles, co-payments, and discounted price of drug.
            </p>{' '}
            <p>
              Reimbursements cannot be processed if the prescription fill date is greater than 12 months from the time the reimbursement is submitted.
            </p>{' '}
            <p
              style={{
                fontFamily: 'Raleway-Bold',
                textAlign: 'left',
              }}
            >
              If you need assistance completing this form or have questions regarding this reimbursement process, a Tria Health Member Advocate can be
              reached at {PhoneNumberPeriodDelimited}.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toggleSection(1)}>
          Member Information
          <Icon name={expandedSection === 1 ? 'minus' : 'plus'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              <span style={{ fontWeight: 'bold' }}>Member Name:</span> Enter the person for whom the prescription was written. This is either the
              cardholder, the spouse of the cardholder, or a dependent of the cardholder.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Date of Birth:</span> Enter the birth date of person for whom the prescription was written.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Tria ID Number:</span> Enter the member Identification Number assigned to you by Tria Health.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Address: </span> Enter permanent mailing address.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Contact info:</span> Provide e-mail address and daytime phone number.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => toggleSection(2)}>
          Prescription Information
          <Icon name={expandedSection === 2 ? 'minus' : 'plus'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              Please indicate the total number of individual prescriptions you are submitting for reimbursement. This number should be the same number
              of attached receipts and/or line items on a printout.
            </p>
            <p>
              Most Pharmacies supply a receipt for each individual prescription which includes the required information. If you have lost a receipt,
              or have multiple claims, the Pharmacy can supply you with a printout of prescriptions for a given time period. Either the receipt or the
              printout will be sufficient if it provides the following information:
            </p>
            <ul>
              <li>The name, address, and phone number of the pharmacy.</li>
              <li>The date the prescription was filled.</li>
              <li>The number assigned to the prescription by the Pharmacy (prescription number).</li>
              <li>The National Drug Code (NDC), which identifies the drug product dispensed.</li>
              <li>The name and strength of the drug dispensed.</li>
              <li>The quantity of the product dispensed.</li>
              <li>The number of days the dispensed quantity is expected to last.</li>
              <li>The dollar amount the member paid the Pharmacy for the prescription.</li>
            </ul>
            <p style={{ fontWeight: 'bold' }}>Cash register receipts do NOT have the information required to process a claim.</p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => toggleSection(3)}>
          Member Signature and Date
          <Icon name={expandedSection === 3 ? 'minus' : 'plus'} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            The completed form must be signed by the member (person who received the prescriptions) unless the member is a minor (17 or younger) or is
            incapable of signing the form. In cases where the member is a legal dependent of the cardholder, the cardholder should sign the form. By
            signing the form, the member is certifying the information submitted is correct.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)`
  border-radius: 10px;
  z-index: 50px;
  position: relative;
  width: 100%;
  @media (max-width: ${sizes.tablet}) {
  }
`
