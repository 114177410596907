import ProConScreen from 'Pages/STOPProgram/components/ProConScreen'
import React from 'react'
import Image1 from '../../../../Assets/Images/STOP/Pill 1.png'
import Image2 from '../../../../Assets/Images/STOP/Pill 2.png'
import Image3 from '../../../../Assets/Images/STOP/Pill 3.png'

export default function Medications1() {
  return (
    <ProConScreen
      title="Medications: Bupropion SR (sustained release) (Zyban, Wellbutrin SR)"
      subtitle="Bupropion SR is an FDA approved prescription medication for quitting smoking. It decreases tobacco cravings and withdrawal symptoms. It does not contain nicotine but can be alongside NRT products. Bupropion SR is generally started 1-2 weeks before quitting and taken for up to 12 weeks, longer if needed."
      images={[Image1, Image2, Image3]}
      mobileImage={Image1}
      pros={[
        'Easy to administer tablet',
        'Can be use with NRT products',
        'Can reduce weight gain tobacco users might experience after quitting',
        'Beneficial in patients with depression',
      ]}
      cons={[
        'Available by prescription only',
        'Twice daily dosing',
        'Not recommended with history of seizures or eating disorders',
        'Potential side effects: dry mouth, headache, difficulty sleeping, changes in mood or behavior',
      ]}
    />
  )
}
