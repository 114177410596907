import { useApi } from './useAPI'

export interface ConsentFormValues {
  welltrak: number | undefined
  privacyPolicy: boolean
  communicationPolicy: boolean
  textOptIn: boolean
  signature: string
  guid?: string
}

export default function useConsent() {
  const TriaApi = useApi()

  const getConsentForm = async (id: string, callback?: Function) => {
    const consentForm = TriaApi.get(`api/consent`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: { id: id },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return consentForm
  }

  const postConsentForm = async (values: ConsentFormValues, callback?: Function) => {
    const data = {
      WellTrakPId: values.welltrak,
      Esignature: values.signature,
      AgreedToPrivacy: values.privacyPolicy,
      AgreedToCommunication: values.communicationPolicy,
      SmsOptIn: values.textOptIn,
    }

    const consentForm = TriaApi.post(`api/consent/${values.guid}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => {
      callback && callback(resp.data)
    })

    return consentForm
  }

  return {
    getConsentForm,
    postConsentForm,
  }
}
