import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import styled, { useTheme } from 'styled-components'
import { ReimbursementSummaryResponse } from 'types/Reimbursement'
import useReimbursement from '../../hooks/useReimbursement'

export default function Reimbursements() {
  const [reimbursements, setReimbursements] = useState<ReimbursementSummaryResponse[]>([])
  const [filteredReimbursements, setFilteredReimbursements] = useState<ReimbursementSummaryResponse[]>([])
  const [currentReimbursement, setCurrentReimbursement] = useState<ReimbursementSummaryResponse>()
  const [sideMenuToggled, setSideMenuToggled] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [showOverview, setShowOverview] = useState<boolean>(false)
  const token = useAppSelector(getAccessToken) ?? ''
  const { getAllReimbursement } = useReimbursement(token)
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { setDocumentTitle } = useDocumentTitle()
  setDocumentTitle('Reimbursements')

  useEffect(() => {
    dispatch(setLoading(true))
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
    getAllReimbursement()
      .then((res) => {
        setReimbursements(res.data)
        setFilteredReimbursements(res.data)
        setCurrentReimbursement(res.data[0])
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const selectReimbursement = (reimbursement) => {
    if (reimbursement.reimbursementPId !== currentReimbursement?.reimbursementPId) {
      setCurrentReimbursement(reimbursement)
      setShowOverview(false)
    }
    isMobile && setSideMenuToggled(false)
  }

  const colorForStatus = (reimbursement) => {
    if (
      reimbursement.statuses &&
      reimbursement.statuses[0] &&
      reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === 'complete'
    ) {
      return '#7A9A01'
    } else if (
      reimbursement.statuses &&
      reimbursement.statuses[0] &&
      reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === 'declined'
    ) {
      return '#E80036'
    } else {
      return '#000000'
    }
  }

  const filterByStatus = (value) => {
    if (value === 'all') {
      reimbursements.length > 0 && setCurrentReimbursement(reimbursements[0])
      setFilteredReimbursements(reimbursements)
    } else if (value === 'submitted') {
      setCurrentReimbursement(
        reimbursements.filter(
          (reimbursement) =>
            (reimbursement.statuses && !reimbursement.statuses[0]) ||
            reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === value,
        )[0],
      )
      setFilteredReimbursements(
        reimbursements.filter(
          (reimbursement) =>
            (reimbursement.statuses && !reimbursement.statuses[0]) ||
            reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === value,
        ),
      )
    } else {
      setCurrentReimbursement(
        reimbursements.filter(
          (reimbursement) =>
            reimbursement.statuses &&
            reimbursement.statuses[0] &&
            reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === value,
        )[0],
      )
      setFilteredReimbursements(
        reimbursements.filter(
          (reimbursement) =>
            reimbursement.statuses &&
            reimbursement.statuses[0] &&
            reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === value,
        ),
      )
    }
  }

  return (
    <>
      <div style={isMobile ? { display: sideMenuToggled ? 'block' : 'none' } : { display: 'block' }}>
        <Column
          styles={
            isMobile
              ? { display: sideMenuToggled ? 'block' : 'none', overflowY: 'scroll', height: '100vh' }
              : { height: '100vh', border: '1px solid grey', width: '25%', marginLeft: 40, float: 'left', overflowY: 'scroll' }
          }
        >
          <Typography variant="h1" font="bold" size={30} styles={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 30 }}>
            My Reimbursements
          </Typography>
          <StyledDropdown onChange={(e) => filterByStatus(e.target.value)}>
            <option value={'all'}>All</option>
            <option value={'submitted'}>Submitted</option>
            <option value={'processing'}>Processing</option>
            <option value={'complete'}>Complete</option>
            <option value={'declined'}>Declined</option>
          </StyledDropdown>
          {filteredReimbursements.map((reimbursement, index) => (
            <Row
              key={index}
              onClick={() => selectReimbursement(reimbursement)}
              styles={
                reimbursement.reimbursementPId === currentReimbursement?.reimbursementPId
                  ? { border: '1px solid #bdbdbd', padding: 10, cursor: 'pointer', color: 'white', backgroundColor: theme.colors.primary1 }
                  : { border: '1px solid #bdbdbd', padding: 10, cursor: 'pointer' }
              }
            >
              <Column>
                <Typography font="bold">{`Ref #${reimbursement.reimbursementPId}`}</Typography>
                <Typography
                  color={reimbursement.reimbursementPId === currentReimbursement?.reimbursementPId ? '#fff' : colorForStatus(reimbursement)}
                >{`${
                  reimbursement.statuses.length > 0 ? reimbursement.statuses[reimbursement.statuses.length - 1].status : 'Submitted'
                }`}</Typography>
              </Column>
              <Column styles={{ marginLeft: 'auto' }}>
                <Typography>{dayjs(reimbursement.reimbursementDate).format('ddd, MMM DD YYYY')}</Typography>
                {reimbursement.statuses.length > 0 &&
                  reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === 'complete' && (
                    <Typography styles={{ marginLeft: 'auto' }}>{`$ ${reimbursement.totalReimbursementAmount}`}</Typography>
                  )}
                {reimbursement.statuses.length > 0 &&
                  reimbursement.statuses[reimbursement.statuses.length - 1].status.toLowerCase() === 'declined' && (
                    <Typography styles={{ marginLeft: 'auto' }}>$0</Typography>
                  )}
              </Column>
            </Row>
          ))}
        </Column>
      </div>
      <div style={{ display: sideMenuToggled && isMobile ? 'none' : 'block' }}>
        <Column
          styles={
            isMobile
              ? { width: '98%', marginLeft: 'auto', marginRight: 'auto', marginTop: 10 }
              : { width: '73%', marginLeft: '25%', float: 'right', marginTop: '-90vh' }
          }
        >
          <div
            style={
              isMobile
                ? { display: 'flex', flexDirection: 'column', textAlign: 'center' }
                : { display: 'flex', flexDirection: 'row', marginBottom: 40 }
            }
          >
            {isMobile && !sideMenuToggled && (
              <div
                onClick={() => setSideMenuToggled(!sideMenuToggled)}
                style={{ zIndex: 1000, cursor: 'pointer', color: theme.colors.primary1, marginRight: 'auto' }}
              >
                <Icon name="chevron-left" color={theme.colors.primary1} styles={{ marginTop: 15, marginLeft: 15 }} />
                Back
              </div>
            )}
            <Typography styles={isMobile ? { marginTop: -25 } : { marginLeft: 40, marginTop: 5 }} size={isMobile ? 20 : 40}>
              {isMobile ? 'Reimbursement' : 'Reimbursement Details'}
            </Typography>
            {isMobile && <Typography size={20}>Details</Typography>}
            <Button
              pageName="reimbursements"
              text="Reimbursement Form"
              rightIcon="external-link"
              onClick={() => navigate('/reimbursement')}
              styles={isMobile ? { marginLeft: 'auto', marginRight: 'auto' } : { marginLeft: 'auto', marginRight: 20, height: 45, marginTop: 7 }}
            />
          </div>
          {currentReimbursement && (
            <StyledCurrentReimbursementCardContainer>
              <StyledReimbursementContainer>
                <div style={{ border: `1px solid ${theme.colors.primary1}`, margin: 'auto', marginTop: 'auto', height: '100%' }}>
                  <div style={{ height: 'fit-content', padding: 10, margin: 'auto', backgroundColor: theme.colors.primary1, top: 0 }}>
                    <Row>
                      <Typography color="white" size={20} variant="h3">
                        {`Ref #${currentReimbursement?.reimbursementPId} | ${
                          currentReimbursement?.statuses && currentReimbursement?.statuses.length > 0
                            ? currentReimbursement.statuses[currentReimbursement.statuses.length - 1].status
                            : 'Submitted'
                        }`}
                      </Typography>

                      {currentReimbursement && (
                        <Typography color="white" size={18} variant="h3" styles={{ marginLeft: 'auto' }}>
                          {`${dayjs(currentReimbursement?.reimbursementDate).format('ddd, MMM DD YYYY')}`}
                        </Typography>
                      )}
                    </Row>
                  </div>

                  <Row styles={{ marginBottom: 20 }}>
                    <div
                      onClick={() => setShowOverview(false)}
                      style={{
                        padding: '10px',
                        border: '1px solid #bdbdbd',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        width: 100,
                        textAlign: 'center',
                        borderRadius: 5,
                        marginRight: 2,
                        cursor: 'pointer',
                        backgroundColor: showOverview ? 'white' : 'lightgrey',
                      }}
                    >
                      <Typography>Status</Typography>
                    </div>
                    <div
                      onClick={() => setShowOverview(true)}
                      style={{
                        padding: '10px',
                        border: '1px solid #bdbdbd',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        width: 100,
                        textAlign: 'center',
                        borderRadius: 5,
                        cursor: 'pointer',
                        backgroundColor: showOverview ? 'lightgrey' : 'white',
                      }}
                    >
                      <Typography>Overview</Typography>
                    </div>
                  </Row>
                  {showOverview ? (
                    <div style={{ marginLeft: 25, marginTop: 20, marginBottom: 20 }}>
                      <Column>
                        <Row>
                          <div>
                            <Typography font="bold">Total number of individual prescription claims</Typography>
                            <Typography font="bold">you have submitted for reimbursement:</Typography>
                          </div>
                          <div style={{ border: '1px solid black', marginLeft: 20, height: 50, width: 50, textAlign: 'center', marginRight: 20 }}>
                            <Typography size={30}>{`${currentReimbursement?.numberClaims}`}</Typography>
                          </div>
                        </Row>
                        <Typography font="bold" styles={{ marginTop: 25 }}>
                          Prescriptions
                        </Typography>
                        <Typography>These are the medications that qualified for reimbursement:</Typography>
                        {currentReimbursement.medications.length > 0 &&
                          currentReimbursement.medications.map((medication, index) => (
                            <Row key={index} styles={{ marginLeft: 15 }}>
                              <li></li>
                              <Typography>{medication}</Typography>
                            </Row>
                          ))}
                        {currentReimbursement.medications.length > 0 && (
                          <Typography font="bold" styles={{ marginTop: 10 }}>{`Total: $${currentReimbursement.totalReimbursementAmount}`}</Typography>
                        )}
                        <Typography font="bold" styles={{ marginTop: 20 }}>
                          Tria Health Notes:
                        </Typography>
                        {currentReimbursement?.notesText && <Typography>{currentReimbursement.notesText}</Typography>}
                      </Column>
                    </div>
                  ) : (
                    <div style={{ marginBottom: 20 }}>
                      {currentReimbursement?.statuses &&
                        currentReimbursement?.statuses.length > 0 &&
                        currentReimbursement.statuses.map((status, index) => (
                          <div key={index} style={{ marginLeft: 25, marginTop: 20 }}>
                            <Typography font="bold">{`${status.status} - ${dayjs(status.statusDate).format('ddd, MMM DD YYYY')}`}</Typography>
                            <Typography>{status.reason}</Typography>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </StyledReimbursementContainer>
            </StyledCurrentReimbursementCardContainer>
          )}
        </Column>
      </div>
    </>
  )
}

const StyledCurrentReimbursementCardContainer = styled.div`
  @media only screen and (min-width: 1200px) {
    flex-direction: column;
    margin-left: 25%;
    margin-right: 20px;
    margin-top: 80px;
  }
`

const StyledReimbursementContainer = styled.div`
  scroll-behavior: smooth;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #bdbdbd;
  position: relative;
  margin-top: -100px;
  padding: 10px;
  margin-bottom: 80px;
  @media only screen and (min-width: 1200px) {
    height: fit-content;
    width: 130%;
    margin-left: -30%;
  }

  @media only screen and (max-width: 1200px) {
    height: fit-content;
    width: 100%;
    margin-top: 5%;
  }
`

const StyledDropdown = styled.select`
  border-radius: 5px;
  text-align: left;
  box-shadow: inset 0px 1px 3px gray;
  min-height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 25px;
  width: 30%;
`
