import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import './OptionCard.scss'
import { useTheme } from 'styled-components'

export interface ButtonOption {
  title: string
  id: number
}

interface Props {
  title: string
  options: ButtonOption[]
  setOptionChanged: (option: ButtonOption) => void
  optionSelected?: number
}
export default function OptionCard({ title, options, optionSelected, setOptionChanged }: Props) {
  const theme = useTheme()

  return (
    <div
      style={{
        border: `.5px solid ${theme.colors.darkGray}`,
        padding: '20px',
        marginBottom: '25px',
      }}
    >
      <Row styles={{ marginBottom: '20px' }}>
        <Typography font="semi-bold">{title}</Typography>
      </Row>
      <div className="option-button-group" style={{ justifyContent: 'space-around' }}>
        {options.map((option) => (
          <div
            onClick={() => setOptionChanged(option)}
            className={`option-button  ${optionSelected === option.id ? 'option-button-selected' : 'unselected'}`}
          >
            <Typography className={` ${optionSelected === option.id ? 'selected' : 'unselected'}`} font="semi-bold">
              {option.title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}
